import styled from 'styled-components';

import {color} from 'utils/theme';

import {SRow} from 'ui/Layout/styles';

export const SRelatedTrades = styled(SRow)`
  background-color: ${color('blue900')};
  padding: 12px;

  table {
    width: 100%;

    thead tr {
      background-color: ${color('blue900')};
      text-transform: uppercase;
    }
    tbody tr {
      background-color: ${color('blue650')};
      color: ${color('white')};
      cursor: pointer;
    }
    tbody tr:nth-of-type(2n) {
      background-color: ${color('blue900')};
    }
    td {
      padding: 6px;
    }
  }
`;
