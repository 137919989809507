import {useAuth} from 'contexts/auth-context';
import {useState} from 'react';
import {showToast} from 'utils/ToastUtils';

import {constructApiUrl, logoUrl, showLogoOnLoginPage} from 'constants/app_defaults';

import LoginFiller from './LoginFiller';

import './Login.scss';
import {SubmitInput} from './styles';
import {AxiosError} from 'axios';
import {useLocalStorage} from 'react-use';

import {STORAGE_KEY} from 'containers/ResizeWindowPopup';

const LoginPage = () => {
  const [, , clearResizeIgnoring] = useLocalStorage(STORAGE_KEY);
  const [form, setFormValue] = useState({
    email: '',
    password: '',
  });

  const updateValue = (field: keyof typeof form, value: string) =>
    setFormValue(values => ({...values, [field]: value}));

  const auth = useAuth();

  const handleSubmit = () => {
    void auth
      .login(form)
      .then(code => {
        if (code === 200) {
          clearResizeIgnoring();
          return;
        }
      })
      .catch(e => {
        const error = e as AxiosError;
        if (error.response?.status === undefined || error.response?.status >= 500) {
          showToast('Service currently unavailable.', 'error');
          return;
        }
        if (error.response?.status === 401) {
          showToast('Authorization unsuccessful, please check email and password.', 'error');
          return;
        }
        showToast('Unexpected error. Please contact the administrator.', 'error');
      });
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit();
  };

  return (
    <div className='login-page'>
      <LoginFiller />

      <div className='login-form-container'>
        {showLogoOnLoginPage && <img className='login-form-logo' src={logoUrl} />}
        <form
          action={constructApiUrl('/v2/login')}
          method='POST'
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className='login-input-field'>
            <i className='la la-user field-icon' />
            <input
              type='email'
              className='login-input la la-user'
              data-testid='email-input'
              name='email'
              placeholder='Email'
              onChange={e => updateValue('email', e.target.value)}
              value={form.email}
              onKeyUp={handleKeyUp}
            />
          </div>
          <div className='login-input-field'>
            <i className='la la-lock field-icon' />
            <input
              type='password'
              className='login-input'
              data-testid='password-input'
              name='password'
              placeholder='Password'
              value={form.password}
              onChange={e => updateValue('password', e.target.value)}
              onKeyUp={handleKeyUp}
            />
          </div>
          <div className='login-input-field'>
            <SubmitInput data-testid='submit-input' type='submit' value='sign in' />
          </div>
        </form>
        <div className='login-input-field'>
          <hr />
          <a href={constructApiUrl(`/v2/saml-login?redirectUrl=${window.location.origin}`)}>Sign in using SAML SSO</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
