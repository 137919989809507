import {Routes, Route, Navigate} from 'react-router-dom';
import LoginPage from 'pages/LoginPage';

const OpenRoutes = () => (
  <Routes>
    <Route path='/login' element={<LoginPage />} />
    <Route path='*' element={<Navigate to='/login' />} />
  </Routes>
);

export default OpenRoutes;
