import {find, isEqual} from 'lodash/fp';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {Asset} from 'types/api';
import {displayAsset} from 'utils/AssetUtils';

import {useRealTimeCapacityQuery} from 'api/hooks/useRealTimeCapacityQuery';

import {createFlasher} from 'utils/AnimationUtils';

import {MANAGE_CAPACITY} from 'constants/paths';

import SelectField from 'ui/SelectField';
import FieldTitle from 'ui/FieldTitle';

type Props = {
  asset: Asset;
  comparedAsset: Asset | undefined;
};

const SRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

const SFieldTitle = styled(FieldTitle)`
  font-size: 12px;
`;

const SSelectField = styled(SelectField)`
  min-width: 150px;
  margin-right: 20px;
` as typeof SelectField;

const CapacitySelector = ({asset, comparedAsset}: Props) => {
  const navigate = useNavigate();
  const {balances} = useRealTimeCapacityQuery({withEquivalent: false});
  const {trigger} = createFlasher('capacity-one');

  const items = balances.map(balance => ({
    label: displayAsset(balance.asset),
    value: balance.asset,
  }));
  const selectedAsset = find(({value}) => isEqual(value, asset), items);
  const selectedComparedAsset = comparedAsset && find(({value}) => isEqual(value, comparedAsset), items);

  const swapAssets = () => {
    if (!comparedAsset) {
      return;
    }
    navigate(MANAGE_CAPACITY.buildAbsolute({asset: comparedAsset, comparedAsset: asset}));
  };

  return (
    <SRoot>
      <SFieldTitle>Capacity 1</SFieldTitle>

      <SSelectField
        data-testid='capacityCurrencySelector'
        items={items}
        value={selectedAsset}
        onChange={item => {
          if (item.value === selectedComparedAsset?.value) {
            swapAssets();
          } else {
            navigate(MANAGE_CAPACITY.buildAbsolute({asset: item.value, ...(comparedAsset && {comparedAsset})}));
          }
          if (item.value !== selectedAsset?.value) trigger();
        }}
      />

      <SFieldTitle>Capacity 2</SFieldTitle>

      <SSelectField
        data-testid='comparedCapacityCurrencySelector'
        items={[
          {label: 'None', value: undefined},
          // Capacity 1 can't be unselected and swap would be a noop, so filter it out
          ...(!selectedComparedAsset ? items.filter(item => item.value !== selectedAsset?.value) : items),
        ]}
        value={selectedComparedAsset}
        onChange={item => {
          if (item.value === selectedAsset?.value) {
            swapAssets();
          } else {
            navigate(MANAGE_CAPACITY.buildAbsolute({asset, comparedAsset: item.value}));
          }
        }}
      />
    </SRoot>
  );
};

export default CapacitySelector;
