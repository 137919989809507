import {useIntl} from 'react-intl';

import {useFormContext} from 'react-hook-form';

import {useAvailableCounterpartiesQuery} from 'api/hooks/useAvailableCounterpartiesQuery';

import {useEffect} from 'react';

import {fieldTitles} from 'constants/messages/labels';
import FieldTitle from 'ui/FieldTitle';
import {FormSelectField} from 'ui/SelectField';
import {Field, FormRow, Label} from 'containers/RFQForm/styles';
import {filterCounterparties} from 'containers/RFQForm/utils';

export const startingList = [
  {
    value: '',
    label: '---',
  },
];

interface Props {
  disabled?: boolean;
  flashIds: string[];
}

export default function CounterpartiesRows({disabled, flashIds}: Props) {
  const {formatMessage} = useIntl();
  const {watch} = useFormContext();
  const [counterparty1, counterparty2, counterparty3] = watch(['counterparty1', 'counterparty2', 'counterparty3']);
  const {partiesAsItems: counterparties, refetch} = useAvailableCounterpartiesQuery();

  useEffect(() => {
    void refetch();
  }, [refetch]);

  return (
    <>
      <FormRow>
        <Label>
          <FieldTitle>{`${formatMessage(fieldTitles.counterparty)} 1`}</FieldTitle>
        </Label>
        <Field>
          <FormSelectField
            data-testid='counterparty1-selector'
            flashKey={flashIds[0]}
            name='counterparty1'
            items={[...startingList, ...filterCounterparties(counterparties, [counterparty2, counterparty3])]}
            disabled={disabled}
            highlight
          />
        </Field>
      </FormRow>
      <FormRow>
        <Label>
          <FieldTitle>{`${formatMessage(fieldTitles.counterparty)} 2`}</FieldTitle>
        </Label>
        <Field>
          <FormSelectField
            data-testid='counterparty2-selector'
            name='counterparty2'
            items={[...startingList, ...filterCounterparties(counterparties, [counterparty1, counterparty3])]}
            disabled={disabled}
          />
        </Field>
      </FormRow>
      <FormRow>
        <Label>
          <FieldTitle>{`${formatMessage(fieldTitles.counterparty)} 3`}</FieldTitle>
        </Label>
        <Field>
          <FormSelectField
            data-testid='counterparty3-selector'
            name='counterparty3'
            items={[...startingList, ...filterCounterparties(counterparties, [counterparty1, counterparty2])]}
            disabled={disabled}
          />
        </Field>
      </FormRow>
    </>
  );
}
