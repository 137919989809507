import {useState} from 'react';

import {useQuery} from 'utils/browserUtils';

import {useUser} from 'contexts/auth-context';
import {getAssetFromQuery} from 'utils/AssetUtils';

import useAvailableAssetsQuery from 'api/hooks/useAvailableAssetsQuery';

import {Role, Scope} from 'constants/permission-maps';

import SpaceFiller from 'ui/SpaceFiller';

import {Item} from 'ui/SelectField';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';
import BoardHeader from 'components/BoardHeader';

import {ChartWrapper, Container} from './styles';
import CapacityChart from './components/CapacityChart';
import CapacitySelector from './components/CapacitySelector';
import CapacityTypeSelector from './components/CapacityTypeSelector';
import {CapacityAggregationType} from './types';

const CapacityManagementPage = () => {
  const {data: assetsWithTimezones = [], isLoading: loading} = useAvailableAssetsQuery();
  const query = useQuery();

  const asset = getAssetFromQuery(query, 'asset');
  const comparedAsset = getAssetFromQuery(query, 'comparedAsset');

  const {
    user: {role},
  } = useUser();
  const canViewChart = hasPermission({
    permissions: getRolePermissions(role),
    scopes: [Scope.ViewCapacityChart],
  });
  const [capacityType, setCapacityType] = useState<CapacityAggregationType>(
    role === Role.CapacityManager ? 'total' : 'available'
  );

  const handleCapacityTypeChange = ({value}: Item<CapacityAggregationType>) => {
    setCapacityType(value);
  };
  const isChartVisible = canViewChart && !loading && assetsWithTimezones.length > 0;

  if (!asset) {
    return null;
  }

  return (
    <Container data-testid='capacityManagementScreen'>
      <BoardHeader title='Capacity Management'>
        {canViewChart && <CapacityTypeSelector selectedType={capacityType} onChange={handleCapacityTypeChange} />}
        <SpaceFiller />
        {canViewChart && <CapacitySelector asset={asset} comparedAsset={comparedAsset} />}
      </BoardHeader>

      <ChartWrapper>
        {isChartVisible && (
          <CapacityChart
            capacityType={capacityType}
            mainAsset={asset}
            comparedAsset={comparedAsset}
            assetsWithTimezones={assetsWithTimezones}
          />
        )}
      </ChartWrapper>
    </Container>
  );
};

export default CapacityManagementPage;
