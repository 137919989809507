import React, {JSXElementConstructor} from 'react';

import {useAuth} from 'contexts/auth-context';

import ResizeWindowPopup from 'containers/ResizeWindowPopup';
import GlobalDialogs from 'components/GlobalDialogs';

import FullPageSpinner from './components/Loader/FullPageSpinner';
import OpenRoutes from './routes/openRoutes';

const App = ({routes}: {routes: JSXElementConstructor<unknown>}) => {
  const auth = useAuth();
  const ProtectedRoutes = routes;
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {auth.data?.id ?
        <>
          <ProtectedRoutes />
          <ResizeWindowPopup />
        </>
      : <OpenRoutes />}
      <GlobalDialogs />
    </React.Suspense>
  );
};

export default App;
