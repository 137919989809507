import {PropsWithChildren, useCallback, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {noop} from 'lodash/fp';

import {Props} from './types';
import {SOverlay, SCloseBtn, SHeader, SContent, SPopupContainer} from './styles';

const EXIT_KEY: string = 'Escape';

const Popup = ({
  closeButtonType = 'default',
  className,
  children,
  width,
  height,
  styles,
  headerStyles,
  onClose = noop,
  title,
  variant,
}: PropsWithChildren<Props>) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === EXIT_KEY) {
        onClose();
      }
    },
    [onClose]
  );

  // Exit by clicking escape
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  // Exit by clicking outside
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handler, true);

    return () => {
      document.removeEventListener('mousedown', handler, true);
    };
  }, [onClose]);

  function getCloseButtonIcon() {
    switch (closeButtonType) {
      case 'arrow':
        return 'la la-arrow-left';
      default:
        return 'la la-close';
    }
  }
  const closeButtonIcon = getCloseButtonIcon();

  const popup = (
    <SOverlay data-testid='popup-overlay'>
      <SPopupContainer
        data-testid='popup-dialog'
        role='dialog'
        ref={modalRef}
        width={width}
        height={height}
        className={className}
        style={styles}
        variant={variant}
      >
        <SHeader style={headerStyles} data-testid={'popup-title'}>
          {title}

          <SCloseBtn data-testid='close-popup-button' onClick={onClose}>
            <i className={closeButtonIcon} />
          </SCloseBtn>
        </SHeader>

        <SContent id='content'>{children}</SContent>
      </SPopupContainer>
    </SOverlay>
  );

  return createPortal(popup, document.body);
};

export default Popup;
