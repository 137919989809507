import styled from 'styled-components';

export const FooterLabel = styled.p`
  text-align: center;
  color: ${p => p.theme.colors.blue50};
  margin: 1em 10px 1em;
`;

export const InfoParagraph = styled.p<{margin?: string}>`
  text-align: left;
  color: ${p => p.theme.colors.blue50};
  margin: ${p => p.margin ?? '3em 10px 1em'};
  line-height: 1.5rem;
`;

export const SButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
