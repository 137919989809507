import {useIntl} from 'react-intl';
import {useFormContext} from 'react-hook-form';

import {
  To,
  calculateBaseAmount,
  calculateSecondAmount,
  convertQuantityToAmount,
  currencySymbol,
  exactSecondAmount,
} from 'utils/AmountUtils';

import {Amount} from 'types/api';

import {useCallback} from 'react';

import {useSetupFlash} from 'utils/AnimationUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {orderFormLimits} from 'constants/orderForm';
import {fieldTitles} from 'constants/messages/labels';
import FieldTitle from 'ui/FieldTitle';
import {FormAmountField} from 'ui/AmountField';
import {OrderFormValues} from 'containers/OrderForm/hooks/useOrderForm';
import {FormRow, Label, Field} from 'containers/RFQForm/styles';
import {REPO_HAIRCUT} from 'containers/OrderForm/types';
import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';

interface Props {
  isRepo: boolean;
  flashLabelId: string;
  flashFieldId: string;
  triggerFlash: () => void;
  disabled?: boolean;
}

export default function SecondAmountRow({isRepo, flashLabelId, flashFieldId}: Props) {
  const {formatMessage} = useIntl();
  const {isFlashing} = useSetupFlash(flashFieldId);

  const methods = useFormContext<OrderFormValues>();
  const [assetPair, secondAmount, side, requestType] = methods.watch([
    'assetPair',
    'secondAmount',
    'side',
    'requestType',
  ]);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);

  const updateBaseAmount = useCallback(
    (newSecondAmount: number) => {
      if (!requestType) return;
      const newBaseAmount: number | undefined =
        newSecondAmount && calculateBaseAmount(side, requestType, newSecondAmount);
      methods.setValue('baseAmount', newBaseAmount, {shouldValidate: true});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestType, side]
  );

  const getOrderAmount = (): Amount | undefined => {
    if (side === 'BuySell' && secondAmount) {
      return convertQuantityToAmount(secondAmount, To.Store, assetPair.second, true, inMillions);
    }
  };

  const {show: showAvailableCapacityWarning, message} = useAvailableCapacityWarning('Order', getOrderAmount());
  const secondAmountFieldWarning = side === 'BuySell' && showAvailableCapacityWarning;

  const minSecondAmountPossible = (() => {
    if (isRepo) return exactSecondAmount(orderFormLimits.amount.min, 1.0 / (1 - REPO_HAIRCUT));
    if (requestType) return calculateSecondAmount(side, requestType, orderFormLimits.amount.min);
  })();

  const maxSecondAmountPossible = (() => {
    if (isRepo) return exactSecondAmount(orderFormLimits.amount.max, 1.0 / (1 - REPO_HAIRCUT));
    if (requestType) return calculateSecondAmount(side, requestType, orderFormLimits.amount.max);
  })();

  return (
    <FormRow>
      <Label>
        <FieldTitle key={1} flashKey={flashLabelId}>
          {formatMessage(
            isRepo ? fieldTitles.securitiesAmt
            : side === 'BuySell' ? fieldTitles.maxSecondAmt
            : fieldTitles.minSecondAmt
          )}
        </FieldTitle>
      </Label>
      <Field>
        <FormAmountField
          name='secondAmount'
          onChange={updateBaseAmount}
          data-testid='second-amt-field'
          min={minSecondAmountPossible}
          max={maxSecondAmountPossible}
          unit={inMillions ? 'm' : undefined}
          decimals={orderFormLimits.secondAmount.dec}
          prefix={currencySymbol(assetPair.second.currency)}
          tooltip={message}
          tooltipPosition='left'
          flash={isFlashing}
          warning={secondAmountFieldWarning}
          invalid={!showAvailableCapacityWarning}
          highlight
        />
      </Field>
    </FormRow>
  );
}
