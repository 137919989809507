import {useQuery} from '@tanstack/react-query';
import {getMarketHistory} from 'api/api';
import {AssetPair} from 'types/api';
import {ReferenceData} from 'types/layout';

interface UseMarketHistoryArgs {
  assetPair: AssetPair;
  referenceData: ReferenceData;
  since?: number;
}

export default function useMarketHistoryQuery({assetPair, referenceData, since}: UseMarketHistoryArgs) {
  return useQuery({
    queryKey: ['MarketHistory', referenceData, assetPair, since],
    queryFn: () => getMarketHistory(assetPair, referenceData, since),
  });
}
