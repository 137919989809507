import {useState, useCallback, useMemo, useEffect} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {isEqual} from 'lodash/fp';
import {useFormContext} from 'react-hook-form';

import {MarketDay, AssetPair} from 'types/api';
import {getUpcomingMarketDays} from 'utils/DayjsUtils';

import useClobMarketDaysQuery from 'api/hooks/useClobMarketDaysQuery';

import {isTradeSizeInMillions} from 'utils/MarketUtils';

import {orderFormLimits} from 'constants/orderForm';

import {OrderFormValues} from './useOrderForm';

export const useMarketDayDescription = () => {
  const {data: markets} = useClobMarketDaysQuery();
  const methods = useFormContext<OrderFormValues>();
  const [selectedPair] = methods.watch(['assetPair']);
  const getMarketDaysForPair = useCallback(
    (assetPair: AssetPair) => markets?.filter(day => isEqual(assetPair, day.assetPair)),
    [markets]
  );
  const filteredMarketDays = useMemo(
    () => getMarketDaysForPair(selectedPair),
    [getMarketDaysForPair, JSON.stringify(selectedPair)]
  );

  const [upcomingDay, setUpcomingDay] = useState<MarketDay>();
  const [isMarketClosed, setIsMarkedClosed] = useState<boolean>();

  useEffect(() => {
    const minTradeSize = upcomingDay?.minTradeSize ?? orderFormLimits.amount.min;
    const maxTradeSize = upcomingDay?.maxTradeSize ?? orderFormLimits.amount.max;

    methods.setValue('baseAmountMin', minTradeSize);
    methods.setValue('baseAmountMax', maxTradeSize);
  }, [JSON.stringify(upcomingDay)]);

  // Based on filtered day, calculate dates
  useEffect(() => {
    if (!filteredMarketDays) return;
    const [marketDay] = getUpcomingMarketDays(filteredMarketDays);
    if (!marketDay) return;

    setUpcomingDay(marketDay);
    const marketCloseTime = dayjs(marketDay.endsAt);
    const maturityDateTime = dayjs(marketDay.maturesAt);
    methods.setValue('marketCloseTime', marketCloseTime);
    methods.setValue('maturityDateTime', maturityDateTime);
    if (
      !methods.formState.dirtyFields.validUntil ||
      marketCloseTime.isBefore(methods.formState.dirtyFields.validUntil as Dayjs)
    )
      methods.setValue('validUntil', marketCloseTime);
    setIsMarkedClosed(getMarketState(marketCloseTime, maturityDateTime));
  }, [JSON.stringify(filteredMarketDays)]);

  return {marketDay: upcomingDay, isMarketClosed, inMillions: upcomingDay ? isTradeSizeInMillions(upcomingDay) : true};
};

const getMarketState = (marketCloseTime: Dayjs, marketMaturityTime: Dayjs) => {
  const currentTime = dayjs();
  const isTimePastClosing = marketCloseTime.isBefore(currentTime);
  const isTimePastMaturity = marketMaturityTime.isBefore(currentTime);
  return isTimePastClosing || isTimePastMaturity;
};
