import {useEffect} from 'react';

import {useCurrentPath} from 'utils/hooks/useCurrentPath';
import {layoutPathToPageTitle} from 'utils/browserUtils';

import useNotificationsQuery from 'api/hooks/useNotificationsQuery';
import useNotificationCenterModal from 'pages/NotificationCenterPage/hooks/useNotificationCenterModal';
import {parseReadableNotifications} from 'pages/NotificationCenterPage/utils';

import useQuoteToastNotifications from 'pages/NotificationCenterPage/hooks/useQuoteToastNotifications';

import {faviconNotificationsConfig} from 'constants/notifications_defaults';
import NotificationIcon from 'components/HeaderBar/components/NotificationIcon';

import {FavIconCounter} from 'components/HeaderBar/FavIconCounter';

import './NotificationsButton.scss';
import {SNotificationButton} from './styles';

export interface NotificationsButtonProps {
  path?: string;
}

const NotificationsButton = ({path}: NotificationsButtonProps) => {
  const layoutPath = useCurrentPath();
  useQuoteToastNotifications();
  const favIconCounter = new FavIconCounter(faviconNotificationsConfig, 0);
  const {notificationsData} = useNotificationsQuery();
  const unseenNotificationsCount = parseReadableNotifications(notificationsData).filter(
    notification => notification.isUnseen
  ).length;

  const {show, toggleNotificationCenter} = useNotificationCenterModal({
    path,
  });

  useEffect(() => {
    const titleWithNotifications = unseenNotificationsCount ? `(${unseenNotificationsCount}) Finteum` : 'Finteum';
    document.title = `${titleWithNotifications}${layoutPath ? ' - ' + layoutPathToPageTitle(layoutPath) : ''}`;
    favIconCounter.changeFavicon(unseenNotificationsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unseenNotificationsCount, layoutPath]);

  return (
    <SNotificationButton onClick={toggleNotificationCenter} data-testid='notification-center-button'>
      <NotificationIcon active={show} notificationCount={unseenNotificationsCount} />
    </SNotificationButton>
  );
};

export default NotificationsButton;
