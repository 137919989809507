import {PropsWithChildren, ReactElement} from 'react';

import {useUser} from 'contexts/auth-context';

import {Scope} from 'constants/permission-maps';

import {hasPermission, getRolePermissions} from './utils';

export interface PermissionGateProps {
  scopes: Scope[];
  placeholder?: ReactElement;
}

const PermissionsGate = ({
  children,
  scopes = [],
  placeholder,
}: PropsWithChildren<PermissionGateProps>): ReactElement => {
  const userWithMeta = useUser();
  const permissions = getRolePermissions(userWithMeta.user?.role);

  const permissionGranted = hasPermission({permissions, scopes});

  if (!permissionGranted) {
    return placeholder || <></>;
  }

  return <>{children}</>;
};

export default PermissionsGate;
