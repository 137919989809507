import styled from 'styled-components';

export const NotificationPopupContainer = styled.div`
  border: 4px solid ${p => p.theme.colors.gray900};
  padding: 8px 0 8px 8px;
  background-color: ${p => p.theme.colors.gray500};
  width: 890px;
  overflow: hidden;
`;

export const NotificationPopupHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`;

export const NotificationPopupTableContainer = styled.div`
  max-height: 520px;
  overflow-y: auto;
`;

export const OpenButtonWrapper = styled.span`
  color: ${p => p.theme.colors.gray200};
  &:hover {
    color: ${p => p.theme.colors.yellow300};
  }
`;

export const OpenButton = styled.button`
  cursor: pointer;
  background: none;
  padding: 8px;
  margin-left: 16px;
  border: none;
`;

export const SNotificationButton = styled.div`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: transparent;
`;
