import styled from 'styled-components';
import {color} from 'utils/theme';

export const SSpotRateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  height: 3rem;

  .spot-rate {
    font-weight: bold;
    padding: 10px 10px;
  }
  .spot-rate--middle {
    font-size: 3rem;
  }

  .spot-rate--error {
    color: ${color('red400')};
  }
`;
