import styled from 'styled-components';
import {color} from 'utils/theme';

import Button from 'ui/Button';

export const DateRow = styled.div`
  display: inline-flex;
  gap: 10px;
  * {
    color: ${color('white')};
  }

  *:last-child {
    color: ${color('blue50')};
  }
`;

export const HeaderRow = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${({theme}) => theme.constants.sm}) {
    justify-content: flex-end;
  }
`;

export const NotificationCenterButton = styled(Button)`
  font-size: 0.8rem;
  padding: 0.4rem 1rem;
`;
