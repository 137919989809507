import styled from 'styled-components';

export const SubsetSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  span:first-child {
    font-size: 12px;
  }

  span > div {
    width: 100px;
  }
`;
