import {ButtonHTMLAttributes, PropsWithChildren} from 'react';

import {ComponentsProps} from './types';
import {StyledButton, LoadingIconContainer, LoadingIcon} from './styles';

type ButtonProps = ComponentsProps & ButtonHTMLAttributes<HTMLButtonElement>;
const Button = ({type = 'button', ...props}: PropsWithChildren<ButtonProps>) => {
  const isClickable = !props.disabled && !props.loading;
  return (
    <StyledButton
      data-testid={props['data-testid']}
      style={props.style}
      onClick={isClickable ? props.onClick : undefined}
      buttonStyle={props.buttonStyle}
      disabled={props.disabled}
      width={props.width}
      className={props.className}
      type={type}
    >
      {props.loading && (
        <LoadingIconContainer>
          <LoadingIcon className='la la-spinner' />
        </LoadingIconContainer>
      )}
      {props.children}
    </StyledButton>
  );
};

export default Button;
