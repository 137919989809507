import styled from 'styled-components';

export const AccordionMenuItem = styled.span<{active?: boolean}>`
  cursor: pointer;
  text-align: center;
  font-size: ${({active}) => (active ? '14px' : '12px')};
  min-height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  user-select: none;
  color: ${({active, theme}) => (active ? 'white' : theme.colors.blue50)};
  padding: 0.5em 2em;

  &:hover {
    color: white;
  }

  &:nth-child(even) {
    background-color: ${({theme}) => theme.colors.blue900};
  }
`;

export const PageContent = styled.div<{isBlotterExpanded?: boolean}>`
  flex: 1;
  display: ${({isBlotterExpanded}) => (isBlotterExpanded ? 'none' : 'block')};

  & .container {
    margin: 0;
    width: 100%;
    max-width: unset;
  }
`;
