import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {color} from 'utils/theme';

export const TradeEventsContainer = styled.div`
  padding: 2em 1em;
  max-height: 70vh;
  overflow: auto;
`;

export const Table = styled.table`
  width: 100%;
  text-transform: uppercase;
`;

export const HeaderRow = styled.tr`
  background-color: ${color('blue900')};
`;

export const Row = styled.tr<{index: number}>`
  background-color: ${ifProp((p: {index: number}) => p.index % 2, color('blue900'), color('blue650'))};
`;

export const Cell = styled.td`
  padding: 10px;
`;

export const HeaderCell = styled(Cell)`
  color: ${color('blue150')};
`;
