import styled from 'styled-components';

import Table from 'ui/Table';

export const Title = styled.p`
  text-align: center;
  color: ${p => p.theme.colors.blue50};
  margin: 1.5em auto 1em;
  max-width: 300px;
`;

export const Label = styled.span`
  text-align: left;
`;

export const StyledTable = styled(Table)`
  text-transform: uppercase;

  @media (min-width: 768px) {
    .row {
      padding: 0.8em;
    }
  }
`;

export const CountdownCell = styled.div`
  color: ${p => p.theme.colors.white};
  text-transform: uppercase;
  font-size: 1.2em;

  .seconds {
    font-size: 2em;
  }
`;

export const CountdownContainer = styled.div`
  color: ${p => p.theme.colors.white};

  .seconds {
    font-size: 2em;
  }
`;

export const Cell = styled.div`
  color: ${p => p.theme.colors.white};
`;

export const ButtonsContainer = styled.div`
  text-align: center;
  margin: 2em auto;
  max-width: 80%;
  @media (min-width: 768px) {
    width: 300px;
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Bottom = styled.div`
  text-align: center;
  margin: 1em 0;
`;

export const BottomForButtons = styled(Bottom)`
  display: flex;
  justify-content: space-evenly;
`;

export const Warning = styled.div`
  min-height: 40px;
`;
