import React from 'react';

import {Props} from './types';
import RequestAndQuotes from './RequestAndQuotes';
import ExpiredRequest from '../RFQRequestReceived/PreviousRequestSent';

const RFQRequestSent: React.FC<Props> = (props: Props) =>
  props.expired ?
    <ExpiredRequest requestData={props.requestData} />
  : <RequestAndQuotes requestData={props.requestData} showForwardPoints={props.showForwardPoints} />;

export default RFQRequestSent;
