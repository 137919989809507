import styled from 'styled-components';

import {ContainerProps, LabelProps, DotProps} from './types';

export const Container = styled.div<ContainerProps>`
  ${(props: ContainerProps) => props.disabled && 'opacity: 0.5;'}
`;

export const Label = styled.div<LabelProps>`
  display: inline-block;
  cursor: pointer;
  color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.blue50)};
  font-size: 12px;
  text-transform: uppercase;
`;

export const Line = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 0.6rem;
  margin: 0 12px;
  background: ${p => p.theme.colors.gray470};
  border-radius: 6px;
`;

export const Dot = styled.div<DotProps>`
  position: absolute;
  cursor: pointer;
  height: 16px;
  width: 16px;
  background: ${p => p.theme.colors.green500};
  box-shadow: 0 0 6px ${p => p.theme.colors.green500};
  border-radius: 50%;
  transition: 0.5s all;
  top: -0.2rem;
  left: ${(props: DotProps) => (props.position === 'left' ? '-8px' : 'calc(100% - 8px)')};
`;
