import {Navigate, useRoutes} from 'react-router-dom';
import AnalyticsPage from 'pages/AnalyticsPage';

import {MainPage} from 'pages/MainPage';

import NotificationCenterPage from 'pages/NotificationCenterPage';

import {MANAGE_CAPACITY, ANALYTICS, CHART_VIEW} from 'constants/paths';

import {History} from 'containers/History';

import {BalancesView} from 'containers/BalancesView/BalancesView';
import {ClobOverview} from 'containers/ClobOverview/ClobOverview';
import {RFQBoard} from 'containers/RFQBoard';

import CapacityManagement from 'containers/CapacityManagement';

import ForOfor from '../pages/404';

// TODO: dynamic import

const ProtectedRoutes = () => {
  // A route object has the same properties as a <Route>
  // element. The `children` is just an array of child routes.
  const routes = useRoutes([
    {index: true, element: <Navigate to='/rfq' />},
    {path: '/saml-login', element: <Navigate to='/rfq' />},
    {path: '/login', element: <Navigate to='/rfq' />},

    {path: '/notifications', element: <NotificationCenterPage />},
    // Main pages
    {
      path: '/',
      element: <MainPage />,
      children: [
        {path: 'clob', element: <ClobOverview />},
        {path: 'rfq', element: <RFQBoard />},
        {path: 'capacity', element: <BalancesView />},
        {path: MANAGE_CAPACITY.absolute, element: <CapacityManagement />},
        {path: CHART_VIEW.absolute, element: <History />},
      ],
    },
    {path: ANALYTICS.absolute, element: <AnalyticsPage />},
    {path: '/404', element: <ForOfor />},
    {path: '*', element: <ForOfor />},
  ]);

  return routes;
};

export default ProtectedRoutes;
