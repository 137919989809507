import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQueryClient, useQuery} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import dayjs from 'dayjs';

import {Notification} from 'types/api';
import {createNotificationsEventSource, getNotifications} from 'api/api';
import {useAvailableCounterpartiesQuery} from 'api/hooks/useAvailableCounterpartiesQuery';

import {showToast} from 'utils/ToastUtils';
import {useDebouncedValue} from 'utils/hooks/useDebouncedValue';
import {displayMessage} from 'pages/NotificationCenterPage/utils';

import {NotificationData} from 'pages/NotificationCenterPage/types';
import {createNotificationToast} from 'utils/NotificationUtils';
import useNotificationsReadDataStore from 'stores/useNotificationsReadDataStore';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

let notificationsEventSource: ReturnType<typeof createNotificationsEventSource>;

const QUERY_KEY = ['Notifications'];

interface Data {
  messages: Notification[];
  lastEventId: string;
  lastNotificationTimestamp: number;
  notificationHistoryLoaded: true;
}

export default function useNotificationsQuery(): {
  error: Event | undefined;
  notificationsData: NotificationData[];
  markAllAsRead(): void;
  isLoading: boolean;
} {
  const client = useQueryClient();
  const [error, setError] = useState<Event | undefined>();
  const {shortNamesMap: shortNames, refetch: refetchCounterparties} = useAvailableCounterpartiesQuery();
  const {lastReadTime, updateLastReadTimeWithStorage, lastNotificationCenterOpenedTime} =
    useNotificationsReadDataStore();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  // Notification is considered unread if it appeared after user marked all notifications as read
  const isUnread = useCallback(
    (notification: Notification) => dayjs(notification.timestamp).unix() > lastReadTime,
    [lastReadTime]
  );

  // Notification is considered unseen if it appeared after notification center opened and it's unread
  // flash (for 10 seconds) only new, not rendered before notifications.
  const isUnseen = useCallback(
    (notification: Notification): boolean =>
      isUnread(notification) && dayjs(notification.timestamp).unix() > lastNotificationCenterOpenedTime,
    [isUnread, lastNotificationCenterOpenedTime]
  );

  const {data, isLoading} = useQuery({
    queryKey: QUERY_KEY,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: () =>
      getNotifications()
        .then((response: AxiosResponse<Notification[]>) => {
          if (response.data.length > 0) {
            const latestNotification: Notification = response.data[response.data.length - 1];
            const timestampHeader = response.headers['x-last-event-id'];

            return {
              messages: response.data,
              lastEventId: timestampHeader,
              lastNotificationTimestamp: dayjs(latestNotification.timestamp).valueOf(),
              notificationHistoryLoaded: true,
            };
          }

          return {
            messages: [],
            lastEventId: '',
            lastNotificationTimestamp: 0,
            notificationHistoryLoaded: false,
          };
        })
        .catch((response: AxiosError) => {
          showToast('Could not reach server for notification data.', 'error');
          console.error(response);
        }),
  });

  const debouncedMessages = useDebouncedValue(data?.messages, 300);

  const notificationsData = useMemo(() => {
    if (!debouncedMessages) return [];
    const receivedNotifications = debouncedMessages.map((notification: Notification) => {
      const inMillions =
        'assetPair' in notification.payload ? shouldDisplayInMillions(notification.payload.assetPair) : true;

      return {
        type: notification.notificationType,
        createdAt: notification.timestamp,
        message: displayMessage(shortNames, notification, inMillions),
        isUnread: isUnread(notification),
        isUnseen: isUnseen(notification),
        payload: notification.payload,
        sortable: false,
        aggregateId: notification.aggregateId,
      };
    }) as NotificationData[];

    return receivedNotifications;
  }, [JSON.stringify(debouncedMessages), JSON.stringify(shortNames), isUnread, isUnseen, shouldDisplayInMillions]);

  // saves last read timestamp to the local storage
  const markAllAsRead = () => {
    const mostRecentNotificationTime = dayjs().unix();

    // Save read notification timestamp both locally and in storage, so other tabs can reach it
    updateLastReadTimeWithStorage(mostRecentNotificationTime);
  };

  useEffect(() => {
    void refetchCounterparties();
  }, [refetchCounterparties]);

  // store received notifications
  const onNotificationMessage = useCallback(
    (message: MessageEvent<Notification>) => {
      try {
        const notification = message.data;
        // We don't want to show toasts for old notifications
        if (isUnread(notification)) {
          createNotificationToast(notification);
        }

        client.setQueryData(QUERY_KEY, (oldData: Data) => {
          return {
            ...oldData,
            lastNotificationTimestamp: dayjs(notification.timestamp).valueOf(),
            messages: [...oldData?.messages, notification],
          };
        });
      } catch (e) {
        console.error(e);
      }
    },
    [client, createNotificationToast, isUnread]
  );

  useEffect(() => {
    // only reach for notification updates once we get previous history
    if (!data?.notificationHistoryLoaded || notificationsEventSource) {
      return;
    }

    notificationsEventSource = createNotificationsEventSource({
      lastEventId: data?.lastEventId,
      onmessage: onNotificationMessage,
      onerror: setError, // TODO think of reconnect
    });

    // We don't cleanup on unmount because this hook is used in
    // header bar which is used on every page to avoid extra requests
  }, [data?.lastEventId, data?.notificationHistoryLoaded, onNotificationMessage]);

  return {
    notificationsData: notificationsData || [],
    markAllAsRead,
    error,
    isLoading,
  };
}
