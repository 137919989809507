export enum Unit {
  BP = 'bp',
  PTS = 'pts',
}
export enum ShorthandSide {
  BuySell = 'B/S',
  SellBuy = 'S/B',
}
export enum RepoFullLengthSide {
  BuySell = 'Sell',
  SellBuy = 'Buy',
}
export enum RepoShorthandSide {
  BuySell = 'S',
  SellBuy = 'B',
}
