import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  system,
  ResponsiveValue,
  compose,
  typography,
  TypographyProps,
  position,
  PositionProps,
} from '@techstack/styled-system';

interface Props extends FlexboxProps, LayoutProps, SpaceProps, TypographyProps, PositionProps {
  gap?: ResponsiveValue<number | string>;
}

const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
});

const Box = styled.div<Props>`
  ${compose(layout, flexbox, space, gap, typography, position)}
`;

export default Box;
