import {MouseEvent} from 'react';

import {RequesteeAndQuote} from 'types/api';

import {getQuoteStatusText} from 'utils/RFQUtils';

import Button from 'ui/Button';

export interface AcceptRejectOrStatusProps {
  requesteeAndQuote: RequesteeAndQuote;
  canTrade: boolean;
  onAccept: (requesteeAndQuote: RequesteeAndQuote) => void;
  onReject: (requesteeAndQuote: RequesteeAndQuote) => void;
}

// Show accept button, or waiting/expired text
export const AcceptRejectOrStatus = ({requesteeAndQuote, canTrade, onAccept, onReject}: AcceptRejectOrStatusProps) => {
  const onClickAccept = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onAccept(requesteeAndQuote);
  };
  const onClickReject = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onReject(requesteeAndQuote);
  };
  if (requesteeAndQuote.stage.name === 'QuoteIssued' && !requesteeAndQuote.expired) {
    return (
      <>
        <Button data-testid='accept-button' disabled={!canTrade} onClick={canTrade ? onClickAccept : undefined}>
          Accept
        </Button>
        <Button
          data-testid='reject-button'
          disabled={!canTrade}
          onClick={canTrade ? onClickReject : undefined}
          buttonStyle='grey'
        >
          Reject
        </Button>
      </>
    );
  }
  return <span>{getQuoteStatusText(true, requesteeAndQuote.expired, requesteeAndQuote.stage.name)}</span>;
};
