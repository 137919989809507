import styled from 'styled-components';

import Box from 'ui/Box';
import {StyledParagraph} from 'containers/HelpPopup/styles';

export const Section = styled(Box).attrs({display: 'flex', flexDirection: 'column', gap: '4px'})``;

export const SVerticalDiv = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 2px;
`;

export const SCommitHash = styled(StyledParagraph)`
  text-transform: none;
`;
