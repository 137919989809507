/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useEffect, useMemo} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {AssetPair, RequestType, Side} from 'types/api';
import {CounterParty, MarketType} from 'types/orderForm';

import {isValidDecimal} from 'utils/utils';
import {currencyPairToAssetPair, isAssetPairEqual, isFxPair, isRepoPair} from 'utils/AssetUtils';
import {
  admissibleForwardPointRange,
  calculateSecondAmount,
  calculateSecondAmountLimit,
  currencySymbol,
} from 'utils/AmountUtils';

import {UseFormReturn, useForm} from 'react-hook-form';

import dayjs, {Dayjs} from 'dayjs';
import {useIntl} from 'react-intl';

import {validateDateTime} from 'utils/DayjsUtils';

import {eurGbpPair} from '__mocks__/api';

import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';

import {ReferenceData} from 'types/layout';
import {Rate} from 'types/rfq';

import useClobOrderDataStore from 'stores/useClobOrderDataStore';

import {formErrorTooltips} from 'constants/messages/labels';
import {orderFormLimits} from 'constants/orderForm';

import {ExchangeRate} from 'containers/RFQForm/types';

import {REPO_HAIRCUT} from '../types';

export const FORM_DEFAULT_VALUES = {
  minSecondAmount: orderFormLimits.secondAmount.min,
  maxSecondAmount: orderFormLimits.secondAmount.max,
  assetPair: eurGbpPair,
  side: 'SellBuy' as Side,
  marketType: MarketType.Limit,
  spotRangeBigFig: 1.0,
  validUntil: dayjs(),
  isNonAggressorOnly: false,
  counterparty: CounterParty.ThirdParty,
};

export default function useOrderForm() {
  const {formatMessage} = useIntl();
  const {exchangeRates} = useRealTimeExchangeRatesQuery();
  const {sideBarData, removeSideBarData} = useClobOrderDataStore();
  const AMOUNT_GENERIC_ERROR = formatMessage(formErrorTooltips.amountGenericError);
  const BIGFIG_ERROR = formatMessage(formErrorTooltips.bigFigError);
  const DATE_TIME_ERROR = formatMessage(formErrorTooltips.datetimeError);
  const IMP_YIELD_ERROR = formatMessage(formErrorTooltips.impYieldError);

  const schema = useMemo(
    () =>
      yup.object({
        // Context
        marketCloseTime: yup.mixed<Dayjs>(),
        maturityDateTime: yup.mixed<Dayjs>(),
        requestType: yup.mixed<RequestType>(),
        minSecondAmount: yup.number(),
        maxSecondAmount: yup.number(),
        baseAmountMin: yup.number(),
        baseAmountMax: yup.number(),
        // Form
        assetPair: yup.mixed<AssetPair>().required("Asset pair wasn't loaded. Please try again later."),
        side: yup.mixed<Side>().required(),
        marketType: yup.mixed<MarketType>().required(),
        baseAmount: yup
          .number()
          .typeError(AMOUNT_GENERIC_ERROR)
          .required(AMOUNT_GENERIC_ERROR)
          .test('is-positive', 'Please enter a positive amount', value => value > 0)
          .test('is-decimal', `Please enter up to ${orderFormLimits.amount.dec} digit after the decimal point`, value =>
            isValidDecimal(value, orderFormLimits.amount.dec)
          )
          .test('min-max', '', function (value) {
            const {baseAmountMin, baseAmountMax, assetPair} = this.parent;

            const isMillions = baseAmountMin >= 1_000_000 && baseAmountMax >= 1_000_000;

            const adjustedMinLimit = isMillions ? baseAmountMin / 1_000_000 : baseAmountMin;
            const adjustedMaxLimit = isMillions ? baseAmountMax / 1_000_000 : baseAmountMax;

            if (value < adjustedMinLimit || value >= adjustedMaxLimit) {
              const unit = isMillions ? 'm' : '';
              const currencySign = currencySymbol(assetPair.base.currency);
              return this.createError({
                message: `Please enter a number between ${currencySign}${adjustedMinLimit}${unit} and ${currencySign}${
                  adjustedMaxLimit - 1
                }${unit}`,
              });
            }

            return true;
          }),
        secondAmount: yup
          .number()
          .typeError(AMOUNT_GENERIC_ERROR)
          .required(AMOUNT_GENERIC_ERROR)
          .min(yup.ref('minSecondAmount') as unknown as number, AMOUNT_GENERIC_ERROR)
          .max(yup.ref('maxSecondAmount') as unknown as number, AMOUNT_GENERIC_ERROR)
          .test(
            'is-decimal',
            `Please enter up to ${orderFormLimits.secondAmount.dec} digit after the decimal point`,
            value => isValidDecimal(value, orderFormLimits.secondAmount.dec)
          ),
        // https://github.com/jquense/yup/issues/2000
        // it's not possible to access this.options.context using arrow function
        rate: yup.mixed<Rate>().test('is-valid', function (rate, ctx) {
          if (ctx.parent.marketType === MarketType.Market) return true;
          if (rate === undefined || rate.value === undefined)
            return new yup.ValidationError('Please enter Imp Yield or Fwd Pts', undefined, 'rate');

          if (rate.referenceData === ReferenceData.ImpliedYield) {
            if (typeof rate.value !== 'number') return new yup.ValidationError(IMP_YIELD_ERROR, undefined, 'rate');
            if (rate.value < orderFormLimits.rate.min || rate.value > orderFormLimits.rate.max)
              return new yup.ValidationError(IMP_YIELD_ERROR, undefined, 'rate');
            if (!isValidDecimal(rate.value, orderFormLimits.rate.dec))
              return new yup.ValidationError(
                `Please enter up to ${orderFormLimits.rate.dec} digit after the decimal point`,
                undefined,
                'rate'
              );

            return true;
          }

          if (rate.referenceData === ReferenceData.ForwardPoints) {
            if (typeof rate.value !== 'number')
              return new yup.ValidationError('Fwd Pts has to be number', undefined, 'rate');
            if (!ctx.parent.exchangeRate)
              return new yup.ValidationError(
                `We cannot fetch exchange rate, please refresh page and try again`,
                undefined,
                'rate'
              );

            const validationRules = admissibleForwardPointRange(
              orderFormLimits.rate.min,
              orderFormLimits.rate.max,
              dayjs(),
              dayjs(ctx.parent.maturityDateTime),
              ctx.parent.exchangeRate.value
            );

            if (!isValidDecimal(rate.value, validationRules.dec))
              return new yup.ValidationError(
                `Please enter up to ${validationRules.dec} digit after the decimal point`,
                undefined,
                'rate'
              );
            if (rate.value < validationRules.min || rate.value > validationRules.max)
              return new yup.ValidationError(
                `Please enter a number between ${validationRules.min}pts and ${validationRules.max}pts`,
                undefined,
                'rate'
              );

            return true;
          }
        }),
        spotRangeBigFig: yup
          .number()
          .typeError(BIGFIG_ERROR)
          .min(orderFormLimits.spotRangeBigFig.min, BIGFIG_ERROR)
          .max(orderFormLimits.spotRangeBigFig.max, BIGFIG_ERROR)
          .test('is-required', BIGFIG_ERROR, (value, ctx) => {
            const isFXSwap: boolean = isFxPair(ctx.parent.assetPair, false);
            return !isFXSwap || !!value;
          })
          .test(
            'is-valid',
            `Please enter up to ${orderFormLimits.spotRangeBigFig.dec} digit after the decimal point`,
            value => !!value && isValidDecimal(value, orderFormLimits.spotRangeBigFig.dec)
          ),
        validUntil: yup
          .mixed<Dayjs>()
          .required('Please enter valid until')
          .typeError(DATE_TIME_ERROR)
          .test('is-future', 'Valid until should be in the future', value => value.isAfter(dayjs()))
          .test('is-valid', 'Valid until should be before Market close time', (dateTime, ctx) =>
            validateDateTime({
              dateTime,
              futureDateTime: dayjs(ctx.parent.marketCloseTime),
            })
          ),
        isNonAggressorOnly: yup.boolean().required(),
        exchangeRate: yup.mixed<ExchangeRate>(),
        counterparty: yup.mixed<CounterParty>().required(),
      }),
    []
  );

  const methods = useForm({
    defaultValues: {...FORM_DEFAULT_VALUES},
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [spotRangeBigFig, assetPair, side, requestType] = methods.watch([
    'spotRangeBigFig',
    'assetPair',
    'side',
    'requestType',
  ]);

  const isRepo = isRepoPair(assetPair, false);
  const spotRate =
    isRepo ?
      1.0 / REPO_HAIRCUT
    : exchangeRates.find(er => assetPair && isAssetPairEqual(currencyPairToAssetPair(er.pair), assetPair))?.rate.value;

  useEffect(() => {
    if (!spotRate) return;
    const newRequestType: RequestType =
      isRepo ?
        {type: 'RepoRequestType'}
      : {
          type: 'FXSwapRequestType',
          initialFXRate: spotRate,
          spotRange: spotRangeBigFig ? spotRangeBigFig / 100 : 0.01,
        };
    methods.setValue('requestType', newRequestType);
  }, [isRepo, methods, spotRangeBigFig, spotRate]);

  useEffect(() => {
    const option = {shouldValidate: true};
    if (sideBarData && requestType) {
      methods?.setValue('side', sideBarData.side);
      if (sideBarData.interestRate)
        methods?.setValue(
          'rate',
          {
            value: sideBarData.interestRate,
            referenceData: ReferenceData.ImpliedYield,
          },
          option
        );
      if (sideBarData.forwardPoints)
        methods?.setValue(
          'rate',
          {
            value: sideBarData.forwardPoints,
            referenceData: ReferenceData.ForwardPoints,
          },
          option
        );
      methods?.setValue('baseAmount', sideBarData.baseAmount, option);
      // Because we are creating counter-order, we also need an opposite "side" for second amount
      const newSecondAmount = calculateSecondAmount(sideBarData.side, requestType, sideBarData.baseAmount);
      methods?.setValue('secondAmount', newSecondAmount, option);
    }
    methods?.setValue('marketType', MarketType.Limit);
    methods?.setValue('isNonAggressorOnly', false);
    removeSideBarData();
  }, [methods, JSON.stringify(sideBarData)]);

  useEffect(() => {
    if (!requestType) return;
    methods.setValue(
      'minSecondAmount',
      calculateSecondAmountLimit(isRepo, orderFormLimits.secondAmount.min, side, requestType)
    );
    methods.setValue(
      'maxSecondAmount',
      calculateSecondAmountLimit(isRepo, orderFormLimits.secondAmount.max, side, requestType)
    );
  }, [isRepo, methods, JSON.stringify(requestType), side]);

  useEffect(() => {
    methods.setValue(
      'exchangeRate',
      isRepo ?
        {
          value: 1.0 / (1.0 - REPO_HAIRCUT),
          timestamp: dayjs().valueOf(),
        }
      : exchangeRates.find(er => assetPair && isAssetPairEqual(currencyPairToAssetPair(er.pair), assetPair))?.rate
    );
  }, [JSON.stringify(assetPair), isRepo, JSON.stringify(exchangeRates), methods]);

  return {methods, schema};
}

export type OrderFormValues = yup.InferType<ReturnType<typeof useOrderForm>['schema']>;
export type OrderFormMethods = UseFormReturn<OrderFormValues>;
