import {assetCode, isAssetEqual} from 'utils/AssetUtils';

import useStateAssets from 'utils/hooks/useStateAssets';

import {useRealTimeCapacityQuery} from 'api/hooks/useRealTimeCapacityQuery';

import {To, convertQuantity} from 'utils/AmountUtils';

import {Scope} from 'constants/permission-maps';
import PermissionsGate from 'components/PermissionGate';
import MenuList from 'components/HeaderBar/MenuList';
import Balance from 'components/HeaderBar/Balance';

export default function BalanceContainer() {
  const {baseAsset, secondAsset} = useStateAssets();
  const {balances} = useRealTimeCapacityQuery({withEquivalent: false});
  const requestBalance = balances.find(b => isAssetEqual(b.asset, baseAsset))?.total;
  const secondBalance = balances.find(b => isAssetEqual(b.asset, secondAsset))?.total;

  return (
    <PermissionsGate scopes={[Scope.ViewCapacity]}>
      <>
        <MenuList.Item key={0} id={assetCode(baseAsset) + '-balance'} className='user-balance'>
          <Balance currency={assetCode(baseAsset)}>
            {requestBalance !== undefined ? convertQuantity(requestBalance.amount.quantity, To.View) : '---'}m
          </Balance>
        </MenuList.Item>

        <MenuList.Item key={1} id={assetCode(secondAsset) + '-balance'} className='user-balance user-term-balance'>
          <Balance currency={assetCode(secondAsset)}>
            {secondBalance !== undefined ? convertQuantity(secondBalance.amount.quantity, To.View) : '---'}m
          </Balance>
        </MenuList.Item>
      </>
    </PermissionsGate>
  );
}
