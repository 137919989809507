import styled from 'styled-components';

import {ContainerProps, ListItemProps} from './types';

export const SContainer = styled.div<ContainerProps>`
  position: relative;
  z-index: 100;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.colors.gray500)};
  width: ${props => (props.width ? props.width : 'auto')};
  max-height: ${props => (props.maxHeight ? props.maxHeight : '240px')};
  align-items: center;
`;

export const SWrapper = styled.div<{maxHeight?: string}>`
  display: flex;
  max-height: ${props => (props.maxHeight ? props.maxHeight : '160px')};
`;

export const SScrollableList = styled.ul`
  min-width: 40px;
  max-height: 100%;
  flex-grow: 1;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SListItem = styled.li<ListItemProps>`
  display: block;
  margin: 0.4em 0.6em;
  font-size: 14px;
  color: ${props => {
    if (props.selected) {
      return props.accentColor ? props.accentColor : props.theme.colors.green500;
    } else {
      return props.textColor ? props.textColor : props.theme.colors.white;
    }
  }};
  ${props => props.disabled && 'opacity: 0.7;'}
  ${props =>
    !props.disabled &&
    `cursor: pointer;
      &:hover {
          color: ${props.accentColor ? props.accentColor : props.theme.colors.green500};
          text-shadow: 0 0 8px ${props.accentColor ? props.accentColor : props.theme.colors.green500};
      }`}
`;

export const SListItemContainer = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
`;
