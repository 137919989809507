import {CSSProperties} from 'react';

import styled from 'styled-components';

import {theme} from 'styled-tools';

import Box from 'ui/Box';

import {PageContent} from './components/AnalyticsSideBar/styles';

export const AnalyticsPageContainer = styled.div`
  display: grid;
  grid-template-columns: 16.6% 83.3%;
  width: 100vw;
`;

export const AnalyticsPage = styled(Box)`
  @media (max-height: ${theme('constants.md')}) {
    overflow-y: auto;
  }

  @media (max-width: ${theme('constants.sm')}) {
    height: 100%;
  }
`;

export const AnaylticsPageContent = styled(PageContent)`
  flex: 1;
`;

export const SAnalyticsContainer = styled.div`
  overflow: hidden;
  height: 100%;
  padding: 0 15px;
`;

export const getAnalyticsBoardHeaderStyles = (): CSSProperties => ({
  margin: 0,
});

export const getAnalyticsSwitcherStyles = (): CSSProperties => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
