import styled from 'styled-components';
import {ifProp} from 'styled-tools';

import {color} from 'utils/theme';

import {FiltersToggleProps} from './types';

export const Container = styled.div`
  position: relative;
`;

export const FiltersToggle = styled.div<FiltersToggleProps>`
  position: relative;
  z-index: 101;
  cursor: pointer;

  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;

  border-radius: 10px;
  border: 2px solid ${ifProp('open', color('white'), 'inherit')};
  border: 2px solid ${ifProp('filtersApplied', color('green500'), 'inherit')};

  .la.la-filter {
    color: ${ifProp('open', color('white'), color('blue50'))};
    color: ${ifProp('filtersApplied', color('green500'), color('blue50'))};
  }
`;

export const FiltersContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 350px;
  max-height: 250px;
  overflow-y: scroll;
  font-size: 12px;

  padding: 1rem;
  z-index: 100;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: start;

  background-color: ${color('gray500')};
  color: ${color('blue50')};

  b {
    align-self: center;
    justify-self: center;
    margin: 0.5rem 0rem;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 1rem;
  margin-bottom: 0.5rem;
  color: ${color('white')};

  [role='checkbox'] {
    width: auto;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
`;
