import {useState} from 'react';

import dayjs from 'dayjs';
import { AxiosError} from 'axios';

import {createRfqRequest} from 'api/api';
import {aggregateEntities} from 'utils/utils';
import { showErrorToast} from 'utils/ToastUtils';
import {convertQuantityToAmount, To} from 'utils/AmountUtils';
import {Asset, ErrorResponse, FXSwapRequest, CreateRFQRequest, RepoRequest} from 'types/api';
import {isRepoPair} from 'utils/AssetUtils';
import {ReferenceData} from 'types/layout';
import {convertLegDateTimeToTimeInput, getRfqAmounts} from 'utils/RFQUtils';

import {confirmationTabs} from 'constants/tabs';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import Button from 'ui/Button';
import Loader from 'ui/Loader';

import useSelectedMarketDay from 'containers/RFQForm/hooks/useSelectedMarketDay';
import {ConfirmationProps} from 'containers/RFQForm/types';
import {ConfirmationContainer, SButtonsContainer} from 'containers/RFQForm/styles';
import {FormType, Economics} from 'components/Economics/Economics';

import Details from '../Details';

export const Confirmation: React.FC<ConfirmationProps> = (props: ConfirmationProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(confirmationTabs[0]);
  const {inMillions} = useSelectedMarketDay();

  const {
    pair,
    initialFXRate,
    nearLeg,
    farLeg,
    counterparty1,
    counterparty2,
    counterparty3,
    tradedCurrency,
    nearLegInstant,
    farLegInstant,
  } = props.rfqForm;
  if (!props.rfqForm.tradedAmount || !pair || !initialFXRate || !farLeg || !farLegInstant) {
    return <></>;
  }

  const isRepo = isRepoPair(pair);

  const baseAsset: Asset = pair.base;
  const secondAsset: Asset = pair.second;
  const tradedAsset: Asset = pair.base.currency === tradedCurrency ? pair.base : pair.second;
  const tradedAmount = convertQuantityToAmount(props.rfqForm.tradedAmount, To.Store, tradedAsset, true, inMillions);

  const counterParties: string[] = aggregateEntities(counterparty1, counterparty2, counterparty3);
  const validUntil = dayjs().add(props.rfqForm.validFor, 'minutes');

  function onSubmit(): void {
    if (!baseAsset || !secondAsset || !initialFXRate || !farLeg) {
      return;
    }
    setLoading(true);

    const typeData: FXSwapRequest | RepoRequest =
      isRepo ?
        {
          type: 'RepoRequestType',
        }
      : {
          type: 'FXSwapRequestType',
          initialFXRate,
          spotRange: props.rfqForm.spotRangeBigFig / 100,
        };
    const sendInterestRate = isRepo || props.rfqForm.suggestedRate?.referenceData === ReferenceData.ImpliedYield;

    const postData: CreateRFQRequest = {
      assetPair: pair,
      requestorSide: props.rfqForm.side,
      requestees: counterParties,
      validUntil: validUntil.toISOString(),
      nearLegTime: nearLeg && convertLegDateTimeToTimeInput(nearLeg),
      maturityTime: convertLegDateTimeToTimeInput(farLeg),
      tradedAmount,
      requestType: typeData,
      suggestedInterestRate: sendInterestRate ? props.rfqForm.suggestedRate?.value : undefined,
      suggestedForwardPoints: !sendInterestRate ? props.rfqForm.suggestedRate?.value : undefined,
    };

    createRfqRequest(postData)
      .then(() => {
        props.onSuccess?.();
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        showErrorToast(err);
        setLoading(false);
      });
  }

  const rfqAmounts = getRfqAmounts({
    pair,
    spotRate: initialFXRate,
    spotRangeBigFig: props.rfqForm.spotRangeBigFig,
    tradedAmount: props.rfqForm.tradedAmount,
    tradedCurrency: props.rfqForm.tradedCurrency,
    side: props.rfqForm.side,
    inMillions,
  });

  return (
    <ConfirmationContainer>
      <Tabs tabs={confirmationTabs} value={tab} onChange={setTab} />
      {tab.value === 'details' && <Details rfqForm={props.rfqForm} />}
      {tab.value === 'economics' && props.rfqForm.pair && (
        <Economics
          formType={FormType.RFQ}
          tradedAmount={props.rfqForm.tradedAmount}
          tradedAsset={tradedAsset}
          counterAsset={pair.second}
          side={props.rfqForm.side}
          spotRange={props.rfqForm.spotRangeBigFig}
          spotRate={initialFXRate}
          nearLeg={nearLegInstant}
          maturity={farLegInstant}
          rate={props.rfqForm.suggestedRate}
          rfqAmounts={rfqAmounts}
          assetPair={pair}
        />
      )}
      <SButtonsContainer>
        <Button onClick={props.onEdit} disabled={loading} width='45%' buttonStyle='grey'>
          EDIT
        </Button>
        <Button onClick={onSubmit} disabled={loading} width='45%' data-testid='confirm-rfq-creation'>
          Confirm
        </Button>
      </SButtonsContainer>
      {loading && <Loader />}
    </ConfirmationContainer>
  );
};

export default Confirmation;
