import styled, {css} from 'styled-components';
import {ifProp} from 'styled-tools';
import {color} from 'utils/theme';

export const NavItem = styled.div<{isActive: boolean}>`
  height: 30px;
  color: ${color('gray300')};
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid ${color('gray450')};
  text-transform: uppercase;
  padding: 0 20px;
  text-decoration: none;
  &:hover {
    color: ${color('green400')};
    cursor: pointer;
    text-shadow: 0 0 9px rgba(${color('green500')}, 0.7);
  }
  .icon {
    font-size: 1rem;
    font-weight: bold;
  }
  .arrow-down {
    font-size: 1rem;
  }

  ${ifProp(
    'isActive',
    css`
      color: ${color('green400')};
      .icon,
      .arrow-down {
        color: inherit;
      }
    `
  )}
`;

export const Options = styled.ul`
  display: flex;
  align-items: center;
  margin: 20px 0;
  border-left: 2px solid ${color('gray450')};
  a {
    text-decoration: none;
  }
`;
