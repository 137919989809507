import {Controller, ControllerProps} from 'react-hook-form';
import {isEqual} from 'lodash';
import {Currency} from 'types/api';
import {currencySymbol} from 'utils/AmountUtils';

import SelectField, {Item} from 'ui/SelectField';

import AmountField, {FormAmountField} from '.';
import {AmountFieldProps} from './types';
import {Prefix} from './styles';

type AmountWithCurrencyFieldProps = {
  currencies: Currency[];
  selectedCurrency: Currency;
  onCurrencyChange: (newCurrency: Item<Currency>) => void;
} & Omit<AmountFieldProps, 'prefix' | 'inputPadding'>;

export const AmountWithCurrencyField = ({
  currencies,
  selectedCurrency,
  onCurrencyChange,
  ...props
}: Omit<AmountFieldProps, 'prefix' | 'inputPadding'> & AmountWithCurrencyFieldProps) => (
  <AmountField
    prefix={
      <Prefix>
        {currencies.length > 1 ?
          <SelectField
            items={currencies.map(currency => ({label: currency, value: currency}))}
            value={{label: currencySymbol(selectedCurrency), value: selectedCurrency}}
            onChange={onCurrencyChange}
            disabled={props.disabled}
            simpleLabel
          />
        : currencySymbol(selectedCurrency)}
      </Prefix>
    }
    inputPadding={currencies.length > 1 ? '10px 40px' : undefined}
    {...props}
  />
);

export const FormAmountWithCurrencyField = ({
  name,
  control,
  currencies,
  onCurrencyChange,
  highlight,
  ...props
}: Omit<AmountWithCurrencyFieldProps, 'value' | 'onChange' | 'selectedCurrency'> & Omit<ControllerProps, 'render'>) => (
  <FormAmountField
    name={name}
    control={control}
    highlight={highlight}
    prefix={
      <Prefix>
        <Controller
          control={control}
          name={`${name}Currency`}
          render={({field: {value, onChange}}) => {
            const selectedCurrency = currencies.find(currency => isEqual(currency, value)) || currencies[0];
            return (
              <SelectField
                data-testid='currency-dropdown'
                items={currencies.map(currency => ({label: currency, value: currency}))}
                value={{label: currencySymbol(selectedCurrency), value: selectedCurrency}}
                onChange={item => {
                  onCurrencyChange(item);
                  onChange(item.value);
                }}
                disabled={props.disabled || currencies.length === 1}
                simpleLabel
              />
            );
          }}
        />
      </Prefix>
    }
    inputPadding={currencies.length > 1 ? '10px 40px' : undefined}
    {...props}
  />
);
