import React from 'react';

import {RadioSwitcherProps} from './types';
import {Container, Label, Line, Dot} from './styles';

const RadioSwitcher = <T = string,>(props: RadioSwitcherProps<T>) => {
  const dotPosition: 'left' | 'right' = props.value === props.leftValue ? 'left' : 'right';

  const onClickLabel = (newValue: T) => () => {
    if (props.onChange && !props.disabled) {
      props.onChange(newValue);
    }
  };

  function onToggle(): void {
    const newValue = props.value === props.leftValue ? props.rightValue : props.leftValue;

    if (props.onChange && !props.disabled) {
      props.onChange(newValue);
    }
  }

  return (
    <Container disabled={props.disabled} className={props.className} style={props.styles} data-testid='radio-switcher'>
      <Label
        data-testid='left-value-switcher'
        className={props.value === props.leftValue ? 'active' : 'inactive'}
        active={props.value === props.leftValue}
        onClick={onClickLabel(props.leftValue)}
      >
        {(props.leftLabel ?? props.leftValue) as React.ReactNode}
      </Label>
      <Line onClick={onToggle} data-testid='radio-switcher-controller'>
        <Dot position={dotPosition} />
      </Line>
      <Label
        data-testid='right-value-switcher'
        className={props.value === props.rightValue ? 'active' : 'inactive'}
        active={props.value === props.rightValue}
        onClick={onClickLabel(props.rightValue)}
      >
        {(props.rightLabel ?? props.rightValue) as React.ReactNode}
      </Label>
    </Container>
  );
};

export default RadioSwitcher;
