import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {color} from 'utils/theme';

interface InterestLabelProps {
  isPaying: boolean;
}

export const InterestLabel = styled.span<InterestLabelProps>`
  display: block;
  text-transform: uppercase;
  text-align: left;
  font-size: 0.6875rem;
  color: ${ifProp('isPaying', color('red400'), color('green500'))};
`;
