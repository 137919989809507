import {useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import dayjs from 'dayjs';
import {useIntl} from 'react-intl';

import {RFQRequest} from 'types/api';

import {displayForwardPoints, displayInterestRate, displayParty} from 'utils/utils';
import {showToast} from 'utils/ToastUtils';

import {displayFullLeg} from 'utils/DayjsUtils';

import {displayAmountWithCode, displaySpotRangeBigfig} from 'utils/AmountUtils';

import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';

import {
  getInterestRateForComputation,
  getCounterAsset,
  getSpotRangeBigFigForComputation,
  getRfqAmounts,
} from 'utils/RFQUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {ReferenceData} from 'types/layout';

import useReferenceDataStore from 'stores/useReferenceDataStore';


import {fieldTitles} from 'constants/messages/labels';
import colors from 'constants/colors';
import Countdown from 'ui/Countdown';
import Button from 'ui/Button';
import BestRate from 'ui/BestRate';
import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';
import FormParagraph from 'ui/FormParagraph';
import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';
import {LabelWithTooltip} from 'components/LabelWithTooltip/LabelWithTooltip';

import AvailableCapacityWarning from 'components/AvailableCapacityWarning/AvailableCapacityWarning';

import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';

import {
  Top,
  Bottom,
  CountdownContainer,
  WarningRow,
  FormRow,
  FormContainer,
  FormCell,
} from 'components/popups/CreateQuote/styles';

import QuoteTradedAmountField from '../QuoteTradedAmountField';
import CounterQuoteTradedAmountField from '../CounterQuoteTradedAmountField';
import InterestOrForwardField from '../InterestOrForwardField';
import ValidForField from '../ValidForField';

interface Props {
  quoteFormRequest: RFQRequest;
  dismissForm: () => void;
  onQuoteSubmit: (values: CreateQuoteFormValues) => void;
  handleCountdownTimeUp: () => void;
  isQuotingDisabled: boolean;
}

export const CreateQuoteForm = ({
  isQuotingDisabled,
  handleCountdownTimeUp,
  dismissForm,
  quoteFormRequest,
  onQuoteSubmit,
}: Props) => {
  const {referenceData} = useReferenceDataStore();

  const {
    requesteeSide,
    validUntil,
    requestor,
    baseAsset,
    secondAsset,
    tradedAmount,
    nearLegTime,
    maturityTime: farLegTime,
    requestType,
    requesteesAndQuotes,
    suggestedInterestRate,
    suggestedForwardPoints,
    nearLegTimeInput,
    maturityTimeInput,
  } = quoteFormRequest;

  const [isImpliedYieldFieldTouched, setIsImpliedYieldFieldTouched] = useState(false);
  const initialFXRate = getInterestRateForComputation(requestType);
  const spotRangeBigFig = getSpotRangeBigFigForComputation(requestType);
  const assetPair = useMemo(() => ({base: baseAsset, second: secondAsset}), [baseAsset, secondAsset]);
  const tradedAsset = tradedAmount.asset;
  const tradedCurrency = tradedAsset.currency;
  const counterAsset = getCounterAsset({baseAsset, secondAsset, tradedAsset});
  const counterCurrency = counterAsset.currency;
  const isRepo: boolean = isRepoPair(assetPair);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);
  const methods = useFormContext<CreateQuoteFormValues>();
  const [quoteTradedAmount, rate, exchangeRate] = methods.watch(['quoteTradedAmount', 'rate', 'exchangeRate']);

  // FIXME(kpieniazek): To be fixed up for 1.2.x
  // const {marketSnapshot, isOnlyPartialFill} = useSingleMarketSnapshotQuery(
  //   assetPair,
  //   requesteeSide,
  //   convertQuantity(quoteTradedAmount, To.Store, true, inMillions ? 1_000_000_00 : 1_00)
  // );

  const {formatMessage} = useIntl();

  const getEarmarkAmount = () => {
    const {sellingRfqAmount: sellingRange} = getRfqAmounts({
      pair: assetPair,
      side: requesteeSide,
      spotRangeBigFig,
      spotRate: initialFXRate,
      tradedAmount: quoteTradedAmount ?? 0,
      tradedCurrency,
      inMillions,
    });

    return {
      asset: sellingRange.asset,
      quantity: Math.max(...(sellingRange.quantity.filter(Boolean) as number[])),
    };
  };

  const {
    show: showAvailableCapacityWarning,
    message,
    tooltipMessage,
  } = useAvailableCapacityWarning('Quote', getEarmarkAmount());

  // Default values to the quote requested
  useEffect(() => {
    if (quoteTradedAmount === undefined) {
      methods.setValue('quoteTradedAmount', tradedAmount.quantity);
    }

    // Invert the implied yield if necessary
    if (rate?.referenceData === ReferenceData.ImpliedYield && !isImpliedYieldFieldTouched) {
      const newValue =
        rate?.value && (requesteeSide === 'BuySell' ? Math.abs(rate?.value) * -1 : Math.abs(rate?.value));
      methods.setValue('rate', {
        value: newValue,
        referenceData: ReferenceData.ImpliedYield,
      });
    }
  }, []);

  const lastTradedTooltip = 'The last CLOB traded price, size and time for this swap pair, regardless of side.';

  const clobPricingTooltip =
    'The weighted rate your counterparty could achieve if the quoted amount was filled using live orders on the CLOB. The size will turn yellow if there is not enough liquidity on the CLOB to fill the quoted amount, instead displaying the maximum amount available.';

  return (
    <FormContainer
      data-testid='create-quote-form'
      onSubmit={methods.handleSubmit(
        values => {
          onQuoteSubmit(values);
        },
        () => {
          showToast('Please verify the form data and try again.', 'error');
        }
      )}
    >
      <Top>
        <p>You have received a request for quote:</p>
      </Top>
      <FormRow>
        <FieldTitle>{formatMessage(fieldTitles.validFor)}</FieldTitle>
        <CountdownContainer className='countdown'>
          <FieldValue>
            <Countdown onTimeUp={handleCountdownTimeUp} timeTarget={dayjs(validUntil)} />
          </FieldValue>
        </CountdownContainer>
      </FormRow>
      <FormRow>
        <FieldTitle>{formatMessage(fieldTitles.counterparty)}</FieldTitle>
        <FieldValue uppercase={false}>
          <span data-testid='counterparty'>{displayParty(requestor)}</span>
        </FieldValue>
      </FormRow>
      <FormRow>
        <FieldTitle>{formatMessage(fieldTitles.yourSide)}</FieldTitle>
        <FieldValue>
          <span data-testid='your-side'>{displayAssetPairWithSide(assetPair, requesteeSide, true)}</span>
        </FieldValue>
      </FormRow>
      <FormRow>
        <FieldTitle>{formatMessage(fieldTitles.tradedAmt)}</FieldTitle>
        <FieldValue>
          <span data-testid='request-amount'>{displayAmountWithCode(tradedAmount, false, inMillions)}</span>
        </FieldValue>
      </FormRow>
      <FormRow>
        <FieldTitle>{isRepo ? formatMessage(fieldTitles.onLeg) : formatMessage(fieldTitles.nearLeg)}</FieldTitle>
        <FieldValue color={nearLegTime ? colors.yellow350 : undefined}>
          <span data-testid='near-leg'>{displayFullLeg(nearLegTime, nearLegTimeInput?.transactionDate)}</span>
        </FieldValue>
      </FormRow>
      <FormRow>
        <FieldTitle>{isRepo ? formatMessage(fieldTitles.offLeg) : formatMessage(fieldTitles.farLeg)}</FieldTitle>
        <FieldValue>
          <span data-testid='far-leg'>{displayFullLeg(farLegTime, maturityTimeInput?.transactionDate)}</span>
        </FieldValue>
      </FormRow>
      {!isRepo && (
        <FormRow>
          <FieldTitle>{formatMessage(fieldTitles.initialRate)}</FieldTitle>
          <FieldValue>
            <span data-testid='initial-rate'>{initialFXRate.toFixed(6)}</span>
          </FieldValue>
        </FormRow>
      )}
      {!isRepo && (
        <FormRow>
          <FieldTitle>{formatMessage(fieldTitles.spotRange)}</FieldTitle>
          <FieldValue>
            <span data-testid='spot-range'>{displaySpotRangeBigfig(spotRangeBigFig)} bigfig</span>
          </FieldValue>
        </FormRow>
      )}
      <FormRow>
        <FieldTitle>
          {isRepo ?
            formatMessage(fieldTitles.suggestedRate)
          : referenceData === ReferenceData.ImpliedYield ?
            formatMessage(fieldTitles.suggestedImpYield)
          : formatMessage(fieldTitles.suggestedFwdPts)}
        </FieldTitle>
        <FieldValue>
          <span data-testid='suggested-implied-yield'>
            {isRepo || referenceData === ReferenceData.ImpliedYield ?
              displayInterestRate(suggestedInterestRate)
            : displayForwardPoints(suggestedForwardPoints)}
          </span>
        </FieldValue>
      </FormRow>
      <FormRow>
        <FieldTitle>{formatMessage(fieldTitles.entity)}</FieldTitle>
        <FieldValue uppercase={false}>
          <span data-testid='entity'>{displayParty(requesteesAndQuotes[0].requestee)}</span>
        </FieldValue>
      </FormRow>
      <ValidForField />
      <QuoteTradedAmountField
        assetPair={assetPair}
        initialFXRate={initialFXRate}
        isRepo={isRepo}
        requesteeSide={requesteeSide}
        spotRangeBigFig={spotRangeBigFig}
        tradedAmount={tradedAmount}
      />
      <CounterQuoteTradedAmountField
        assetPair={assetPair}
        initialFXRate={initialFXRate}
        isRepo={isRepo}
        requesteeSide={requesteeSide}
        spotRangeBigFig={spotRangeBigFig}
        tradedAmount={tradedAmount}
        counterCurrency={counterCurrency}
        secondAsset={secondAsset}
      />
      <FormRow>
        <FormCell>
          <LabelWithTooltip tooltipPosition='left' tooltip={lastTradedTooltip}>
            <FieldTitle>{formatMessage(fieldTitles.lastTraded)}</FieldTitle>
          </LabelWithTooltip>
        </FormCell>
        <FormCell>
          <FieldValue>
            <span data-testid='last-traded'>--</span>
          </FieldValue>
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell>
          <LabelWithTooltip tooltipPosition='left' tooltip={clobPricingTooltip}>
            <FieldTitle>{formatMessage(fieldTitles.clobPricing)}</FieldTitle>
          </LabelWithTooltip>
        </FormCell>
        <FormCell>
          <FieldValue>
            <span data-testid='clob-pricing'>
              <BestRate marketSnapshot={undefined} side={requesteeSide} isOnlyPartialFill={undefined} />
            </span>
          </FieldValue>
        </FormCell>
      </FormRow>
      <InterestOrForwardField
        isRepo={isRepo}
        setIsImpliedYieldFieldTouched={setIsImpliedYieldFieldTouched}
        highlightEmpty
        quoteFormRequest={quoteFormRequest}
      />
      <WarningRow>
        {isQuotingDisabled && (
          <FormParagraph
            message='This RFQ Request has expired'
            tooltipMessage='This RFQ Request has expired'
            variant='error'
          />
        )}
        {!isQuotingDisabled && showAvailableCapacityWarning && (
          <AvailableCapacityWarning show message={message} tooltipMessage={tooltipMessage} />
        )}
        {!exchangeRate && (
          <FormParagraph
            message='Exchange rate for selected asset pair is temporarily unavailable'
            tooltipMessage='Exchange rate for selected asset pair is temporarily unavailable'
            variant='error'
          />
        )}
      </WarningRow>
      <Bottom>
        <Button data-testid='dismiss-quote-btn' buttonStyle='grey' onClick={dismissForm}>
          Dismiss
        </Button>
        <Button data-testid='create-quote-btn' type='submit' disabled={isQuotingDisabled || !exchangeRate}>
          Quote
        </Button>
      </Bottom>
    </FormContainer>
  );
};
