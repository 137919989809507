import {Link} from 'react-router-dom';

import {SContainer} from 'ui/Layout/styles';

import {Row, StyledTableProps} from './types';
import {StyledRow, Cell, StyledLinkDiv} from './styles';

const Table = ({rows = [], elements = [], className, links, ...props}: StyledTableProps) => {
  const spanWidth = Array.isArray(rows[0]) ? rows[0].length : 1;
  const cellWidth: number = rows[0] ? 100 / spanWidth : 100;

  const createStyledRow = (row: Row, index: number) => (
    <StyledRow className='row' key={index}>
      {row.map((cell: string, i: number) => (
        <Cell key={i} width={cellWidth}>
          {cell}
        </Cell>
      ))}
    </StyledRow>
  );

  const createStyledLink = (row: Row, url: string, index: number) => (
    <StyledLinkDiv key={index}>
      <Link to={url} style={{textDecoration: 'none'}}>
        {createStyledRow(row, index)}
      </Link>
    </StyledLinkDiv>
  );

  return (
    <SContainer className={className} data-testid={props['data-testid']}>
      {elements.map(element => element)}
      {rows?.map((row, index) => {
        const url = links?.at(index);
        return url ? createStyledLink(row, url, index) : createStyledRow(row, index);
      })}
    </SContainer>
  );
};

export default Table;
