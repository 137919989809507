import {FC} from 'react';

import {Order} from 'types/api';
import {actualSpotRange, displayInterestRate, map, SpotRange, getRFC2253ValueByKey} from 'utils/utils';
import {displayAmountWithCode, displaySpotRange, payingOrReceivingInterest} from 'utils/AmountUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {cancelMessage} from 'types/blotter';
import {displayAssetPairFrom, displaySide, isRepoPair} from 'utils/AssetUtils';
import {useIntl} from 'react-intl';
import {displayDate} from 'utils/DayjsUtils';

import {fieldTitles, orderStatuses} from 'constants/messages/labels';

import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';
import {SCol, SContainer, SRow} from 'ui/Layout/styles';

export interface DetailsTableProps {
  order: Order;
}

const EmptyCell = () => (
  <>
    <SCol width={{md: 1 / 4}} className='details-popup__label' />
    <SCol width={{md: 1 / 4}} className='details-popup__value' />
  </>
);

const DetailsTable: FC<DetailsTableProps> = (props: DetailsTableProps) => {
  const {formatMessage} = useIntl();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({
    base: props.order.data.baseAsset,
    second: props.order.data.secondAsset,
  });
  const statusReasonMessage: string = map(props.order.statusReason, reason => cancelMessage[reason]) || '';

  const spotRangeAct: SpotRange = actualSpotRange(props.order.data.initialRate, props.order.data.spotRange);

  const assetPair = {
    base: props.order.data.baseAsset,
    second: props.order.data.secondAsset,
  };

  const isRepo = isRepoPair(assetPair);

  return (
    <SRow className='details-popup__table'>
      <SCol width={1}>
        <SContainer>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.uniqueOrderId)}</FieldTitle>
            </SCol>
            <SCol width={{md: 3 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-id-data'>{props.order.id}</FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.status)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-status-data'>
                {formatMessage(orderStatuses[props.order.status])}
              </FieldValue>
            </SCol>
            {statusReasonMessage && (
              <>
                <SCol width={{md: 1 / 4}} className='details-popup__label'>
                  <FieldTitle>{formatMessage(fieldTitles.statusReason)}</FieldTitle>
                </SCol>
                <SCol width={{md: 1 / 4}} className='details-popup__value'>
                  <FieldValue data-testid='order-reason-data'>{statusReasonMessage}</FieldValue>
                </SCol>
              </>
            )}
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.market)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-asset-pair-data'>{displayAssetPairFrom(props.order.data)}</FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(isRepo ? fieldTitles.rate : fieldTitles.impYield)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-interest-rate-data'>
                {displayInterestRate(props.order.data.interestRate)}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.side)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-side-data'>{displaySide(props.order.data.side, isRepo, false)}</FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{isRepo ? '' : formatMessage(fieldTitles.fwdPts)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-rate-data'>
                {isRepo ? '' : `${props.order.currentForwardPoints || props.order.initialForwardPoints} pts`}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.orderType)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-market-type-data'>{props.order.data.marketType}</FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.creationDate)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-created-at-date-data'>
                {displayDate(props.order.createdAt, 'DD MMM YYYY')}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.amount)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-base-amount-data'>
                {displayAmountWithCode(props.order.data.baseAmount, false, inMillions)}
              </FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.creationAt)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-created-at-time-data'>
                {displayDate(props.order.createdAt, 'HH:mm:ss')}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.filled)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-filled-data'>
                {displayAmountWithCode(props.order.lifted, false, inMillions)}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.earmark)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-earmark-data'>
                {displayAmountWithCode(props.order.remainingEarmark, false, inMillions)}
              </FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.executedAt)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-executed-at-time-data'>
                {props.order.executedAt ? displayDate(props.order.executedAt, 'HH:mm:ss') : '-'}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.initialRate)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-initial-rate-data'>{props.order.data.initialRate}</FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.validUntil)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-valid-until-data'>
                {displayDate(props.order.data.validUntil, 'HH:mm:ss')}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{isRepo ? '' : formatMessage(fieldTitles.spotRange)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-spot-range-data'>
                {isRepo ? '' : `${displaySpotRange(props.order.data.spotRange)} bigfig`}
              </FieldValue>
            </SCol>
            {props.order.resumedAt && (
              <SCol width={{md: 1 / 4}} className='details-popup__label'>
                <FieldTitle>{formatMessage(fieldTitles.resumedAt)}</FieldTitle>
              </SCol>
            )}
            {props.order.resumedAt && (
              <SCol width={{md: 1 / 4}} className='details-popup__value'>
                <FieldValue data-testid='order-resumed-at-data'>
                  {displayDate(props.order.resumedAt, 'HH:mm:ss')}
                </FieldValue>
              </SCol>
            )}
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{isRepo ? '' : formatMessage(fieldTitles.spotRangeAct)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-spot-range-act-data'>
                {isRepo ? '' : `${spotRangeAct.min.toFixed(4)}-${spotRangeAct.max.toFixed(4)}`}
              </FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>
                {isRepo ? formatMessage(fieldTitles.offLegDate) : formatMessage(fieldTitles.farLegDate)}
              </FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-far-leg-date-data'>
                {displayDate(props.order.maturity, 'DD MMM YYYY')}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.nonAggressorOnly)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-non-aggressor-data'>
                {props.order.data.isNonAggressor ? 'Yes' : 'No'}
              </FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>
                {isRepo ? formatMessage(fieldTitles.offLegTime) : formatMessage(fieldTitles.farLegTime)}
              </FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-far-leg-time-data'>
                {displayDate(props.order.maturity, 'HH:mm:ss')}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.interestSide)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-interest-side-data'>
                {getRFC2253ValueByKey(props.order.createdBy, 'O') +
                  ((
                    payingOrReceivingInterest(props.order.data.side, props.order.data.interestRate as number) ===
                    'Paying interest'
                  ) ?
                    ' Pays'
                  : ' Receives')}
              </FieldValue>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.entity)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='order-entity-data' uppercase={false}>
                {getRFC2253ValueByKey(props.order.createdBy, 'O')}
              </FieldValue>
            </SCol>
          </SRow>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.traderInfo)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trader-email-value' uppercase={false}>
                {props.order.data.userId}
              </FieldValue>
            </SCol>
            <EmptyCell />
          </SRow>
        </SContainer>
      </SCol>
    </SRow>
  );
};

export default DetailsTable;
