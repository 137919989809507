import {Amount, Currency, Side, Party, Asset, RequestType} from 'types/api';
import {Dayjs} from 'dayjs';
import {ExchangeRate} from 'types/exchangeRate';

import {Rate} from 'types/rfq';

import {ReferenceData} from 'types/layout';

import {NumLimit} from 'constants/orderForm';

import {REPO_HAIRCUT} from 'containers/OrderForm/types';

import {isRepoPair} from './AssetUtils';
import {intlImperative} from './IntlUtils';
import {displayParty} from './utils';

export enum To {
  View = 'view',
  Store = 'store',
}

/**
 * Convert Quantity function used to convert amount quantities between view and store formats.
 * (values represents as 1m (by default) but stored as 1 000 000 00 in cents.)
 */
export const convertQuantity = (
  inQuantity: number,
  operation: To,
  fixed: boolean = true,
  unit: number = 1_000_000_00
): number => {
  switch (operation) {
    case To.View: {
      const outAmount = inQuantity / unit;
      return fixed ? Math.round(outAmount * 100) / 100 : (outAmount * 100) / 100;
    }

    case To.Store: {
      const outAmount = inQuantity * unit;
      return fixed ? Math.round(outAmount * 100) / 100 : (outAmount * 100) / 100;
    }

    default:
      return inQuantity;
  }
};

/**
 * toAmount function used to map quantity to amount while converting between view and store formats.
 * (values represents as 1m but stored as 1 000 000 00 in cents.)
 */
export const convertQuantityToAmount = (
  oldQuantity: number,
  operation: To,
  asset: Asset,
  fixed: boolean = true,
  inMillions: boolean
): Amount => {
  const quantity = convertQuantity(oldQuantity, operation, fixed, inMillions ? 1_000_000_00 : 1_00);

  return {
    asset,
    quantity,
  };
};

export const makeAmount = (quantity: number, currency: Currency): Amount => ({
  quantity,
  asset: {
    type: 'Cash',
    currency,
  },
});

export const convertExchangeAmount: (a: Amount, exchangeRate: ExchangeRate) => Amount = (
  a: Amount,
  exchangeRate: ExchangeRate
) => ({
  quantity: a.quantity * exchangeRate.rate.value,
  asset: {currency: exchangeRate.pair.second, type: 'Cash'},
});

export const addAmount = (firstAmount: Amount, secondAmount: Amount): Amount => ({
  ...firstAmount,
  quantity: firstAmount.quantity + secondAmount.quantity,
});

export const subtractAmount = (firstAmount: Amount, secondAmount: Amount): Amount => ({
  ...firstAmount,
  quantity: firstAmount.quantity - secondAmount.quantity,
});

function displayFullCurrencyQuantity(quantity: number, currency: Currency): string {
  return intlImperative.formatNumber(quantity, {style: 'currency', currency});
}

export function displayAmount(amount: Amount, inMillions: boolean): number {
  return convertQuantity(amount.quantity, To.View, true, inMillions ? 1_000_000_00 : 1_00);
}

export function displayAmountWithUnit(amount: Amount, inMillions: boolean, sep: string = ''): string {
  return (
    displayFullCurrencyQuantity(displayAmount(amount, inMillions), amount.asset.currency) +
    sep +
    (inMillions ? 'm' : '')
  );
}

export function displayExactAmountWithUnit(amount: Amount) {
  return intlImperative.formatNumber(convertQuantity(amount.quantity, To.View, true, 1_00), {
    style: 'currency',
    currency: amount.asset.currency,
  });
}

export function currencySymbol(code: string): string {
  switch (code) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    case 'SGD':
      return 'S$';
    case 'CAD':
      return 'C$';
    default:
      return '';
  }
}

export function displayAmountWithCode(amount: Amount, withAssetType: boolean = false, inMillions: boolean): string {
  return (
    (amount.quantity < 0 ? '-' : '') +
    displayAmountWithUnit({...amount, quantity: Math.abs(amount.quantity)}, inMillions) +
    (withAssetType && amount.asset.type === 'Securities' ? ' Sec' : '')
  );
}

export function displayRangeWithCode(first: Amount, second: Amount, inMillions: boolean): string {
  return displayAmountWithUnit(first, inMillions) + ' - ' + displayAmountWithUnit(second, inMillions);
}

/**
 * Function to convert amount in cents to amount in dollars.
 *
 * @param amount [number] - amount in cents to be converted to amount in dollars
 */
export const centsToDollars: (amount: number) => number = (amount: number): number => amount / 100;

/**
 * Calculate the earmark amount based on baseAmount, fxRate, spotRange and also according to the side of the trade
 *
 * @param baseAmount [Amount] - Base amount of the trade, with currency and quantity values
 * @param secondCurrency [string] - Second currency of the trade so we can return the approriate [Amount] object
 * @param fxRate [number] - Exchange rate to calculate the maximum possible amount to be exchanged
 * @param spotRange [number] - Maximum change on spot rate that the trader accepts in bigfigs
 * @param side [Side] - Side of the trade so we can calculate the amount to earmark with the correct currency
 */
export const earmarkAmount = (
  baseAmount: Amount,
  secondCurrency: Currency,
  fxRate: number,
  spotRange: number,
  side: Side
): Amount => {
  if (side === 'BuySell') {
    return {
      asset: {type: 'Cash', currency: secondCurrency},
      quantity: Math.floor(baseAmount.quantity * (fxRate + spotRange / 100)),
    };
  }
  return baseAmount;
};

export const exactSecondAmount = (baseAmount: number, spotRate: number) => roundNumber(baseAmount * spotRate, 2);

/**
 * Calculate min and max second amount
 */
export const minSecondAmount: (baseAmount: number, spotRate: number, bigFig: number) => number = (
  baseAmount: number,
  spotRate: number,
  bigFig: number
) => roundDown(baseAmount * (spotRate - bigFig / 100), 2);

/**
 * Calculate min or max border for second amount
 */
export const calculateSecondAmountLimit = (
  isRepo: boolean,
  limit: number,
  side: Side,
  requestType: RequestType
): number =>
  isRepo ? exactSecondAmount(limit, 1.0 / (1 - REPO_HAIRCUT)) : calculateSecondAmount(side, requestType, limit);

export const maxSecondAmount: (baseAmount: number, spotRate: number, bigFig: number) => number = (
  baseAmount: number,
  spotRate: number,
  bigFig: number
) => roundUp(baseAmount * (spotRate + bigFig / 100), 2);

export function calculateSecondAmount(side: Side, requestType: RequestType, baseAmount: number): number {
  if (requestType.type === 'FXSwapRequestType') {
    return side === 'BuySell' ?
        maxSecondAmount(baseAmount, requestType.initialFXRate, requestType.spotRange * 100)
      : minSecondAmount(baseAmount, requestType.initialFXRate, requestType.spotRange * 100);
  } else {
    return exactSecondAmount(baseAmount, 1.0 / 0.9);
  }
}

export const expectedRangeOfAmountExchanged: (
  baseAmount: number,
  exchangeRate: number,
  spotRangeBigFig: number
) => [number, number] = (baseAmount: number, exchangeRate: number, margin: number): [number, number] => {
  const minValue = minSecondAmount(baseAmount, exchangeRate, margin);
  const maxValue = maxSecondAmount(baseAmount, exchangeRate, margin);
  return [minValue, maxValue];
};

// Calculate base amount from second (max/min/exact) amount
const baseAmountFromSecond = (
  secondAmountValue: number,
  exchangeRate: number,
  spotRange: number,
  roundingF: (n: number, p: number) => number
) => roundingF(secondAmountValue / (exchangeRate + spotRange), 2);

/**
 * Calculate base amount for given max/min/exact second amount.
 *
 * @param side
 * @param requestType
 * @param secondAmount Can be either max second amount, min second amount, or exact second amount
 * depending on side and request type
 * @returns
 */
export function calculateBaseAmount(side: Side, requestType: RequestType, secondAmount: number): number {
  if (requestType.type === 'FXSwapRequestType') {
    return side === 'BuySell' ?
        baseAmountFromSecond(secondAmount, requestType.initialFXRate, requestType.spotRange, roundDown)
      : baseAmountFromSecond(secondAmount, requestType.initialFXRate, -1.0 * requestType.spotRange, roundUp);
  } else {
    return baseAmountFromSecond(secondAmount, 1.0 / 0.9, 0, roundNumber);
  }
}

// Round numbers to any number of decimal cases
export function roundNumber(num: number, places: number): number {
  const multiplier: number = Math.pow(10, places);
  return Math.round(num * multiplier) / multiplier;
}

export function roundUp(num: number, places: number): number {
  const multiplier: number = Math.pow(10, places);
  return Math.ceil(num * multiplier) / multiplier;
}

export function roundDown(num: number, places: number): number {
  const multiplier: number = Math.pow(10, places);
  return Math.floor(num * multiplier) / multiplier;
}

type InterestResultText = 'Paying interest' | 'Receiving interest';

// Text that should display paying or receiving interest
export function payingOrReceivingInterest(side: Side, impliedYield: number): InterestResultText {
  if ((side === 'SellBuy' && impliedYield >= 0) || (side === 'BuySell' && impliedYield < 0)) {
    return 'Receiving interest';
  } else {
    return 'Paying interest';
  }
}

/**
 * Creates an order form label explaining whether the given party is paying or receiving interest.
 *
 * @param side [Side] - Side of the trade
 * @param nonAggressorOnly [boolean]
 * @param value [number] - Implied yield or forward points
 * @param party [Party]
 */
export function orderPaymentLabel(side: Side, nonAggressorOnly: boolean, value: number, party: Party): string {
  const partyName = displayParty(party);

  if (side === 'SellBuy') {
    if (nonAggressorOnly) {
      return value >= 0 ? `${partyName} receives` : `${partyName} pays`;
    } else {
      return value >= 0 ? `Min. ${partyName} receives` : `Max. ${partyName} pays`;
    }
  } else {
    if (nonAggressorOnly) {
      return value >= 0 ? `${partyName} pays` : `${partyName} receives`;
    } else {
      return value >= 0 ? `Max. ${partyName} pays` : `Min. ${partyName} receives`;
    }
  }
}

export function displaySpotRangeBigfig(bigfig: number): string {
  return '+/- ' + bigfig.toString();
}

/**
 * displaySpotRange
 *
 * @param spotRange [number] - This number should be the actual spotRange and NOT the spotRange in bigfigs
 */
export function displaySpotRange(spotRange: number): string {
  return '+/- ' + (spotRange * 100).toFixed(2);
}

/**
 * Amount the trader is buying on a certain order/trade
 *
 * @param baseAmount [Amount]
 * @param secondCurrency [string]
 * @param fxRate [number]
 * @param spotRange [number in BigFigs]
 * @param side [Side]
 * @returns [Amount[]] with 1 or 2 values
 */
export function amountBuying(
  baseAmount: Amount,
  secondAsset: Asset,
  fxRate: number,
  spotRange: number,
  side: Side
): Amount[] {
  if (side === 'BuySell') {
    return [baseAmount];
  }

  // if Repo
  if (secondAsset.type === 'Securities') {
    return [
      {
        asset: secondAsset,
        quantity: exactSecondAmount(baseAmount.quantity, fxRate),
      },
    ];
  }

  // if FX Swap
  const minSecond: Amount = {
    asset: secondAsset,
    quantity: minSecondAmount(baseAmount.quantity, fxRate, spotRange),
  };
  const maxSecond: Amount = {
    asset: secondAsset,
    quantity: maxSecondAmount(baseAmount.quantity, fxRate, spotRange),
  };
  return [minSecond, maxSecond];
}

/**
 * Amount the trader is selling on a certain order/trade
 *
 * @param baseAmount [Amount]
 * @param secondCurrency [string]
 * @param fxRate [number]
 * @param spotRange [number in BigFigs]
 * @param side [Side]
 * @returns [Amount[]] with 1 or 2 values
 */
export function amountSelling(
  baseAmount: Amount,
  secondAsset: Asset,
  fxRate: number,
  spotRange: number,
  side: Side
): Amount[] {
  if (side === 'SellBuy') {
    return [baseAmount];
  }

  // if Repo
  if (isRepoPair({base: baseAmount.asset, second: secondAsset})) {
    return [
      {
        asset: secondAsset,
        quantity: exactSecondAmount(baseAmount.quantity, fxRate),
      },
    ];
  }

  const minSecond: Amount = {
    asset: secondAsset,
    quantity: minSecondAmount(baseAmount.quantity, fxRate, spotRange),
  };
  const maxSecond: Amount = {
    asset: secondAsset,
    quantity: maxSecondAmount(baseAmount.quantity, fxRate, spotRange),
  };
  return [minSecond, maxSecond];
}

export function displayMultipleAmounts(amounts: Amount[], inMillions: boolean): string {
  const amountStrings: string[] = amounts.reduce(
    (accumulator: string[], amount: Amount) => [...accumulator, displayAmountWithCode(amount, true, inMillions)],
    []
  );
  return amountStrings.join(' - ');
}

/**
 * Display smaller amounts, of less than 100K
 *
 * @param amount [Amount]
 */
export function displaySmallAmount(amount?: Amount, prefix: string = '', emptyValue: string = '--'): string {
  if (!amount) {
    return emptyValue;
  }

  const quantity: number = centsToDollars(amount.quantity);
  return `${prefix}${displayFullCurrencyQuantity(quantity, amount.asset.currency)}`;
}

interface ValidateAmountParemeters {
  value?: number;
  min?: number;
  max?: number;
  decimals?: number;
  optional?: boolean;
  canBeZero?: boolean;
}

interface ValidationDetails {
  validNumber: boolean;
  validRange: boolean;
  validDecimals: boolean;
  validZero: boolean;
}

export interface ValidationResult {
  valid: boolean;
  details?: ValidationDetails;
}

/**
 * Count number of decimals places in given number
 *
 * @param value [number] number to count decimal places on
 */
export const countDecimals: (value: number) => number = (value: number): number => {
  if (Math.floor(value) === value) {
    return 0;
  }
  return value.toString().split('.')[1].length || 0;
};

/**
 * validates an amount value against min value, max value and by also counting its decimals
 * if provided value is undefined and optional parameter is set to true, value considered valid
 *
 * @param { value?[number], min?[number], max?[number], decimals?[number] }
 * @returns [ValidationResult]
 */
export const validateAmount = ({
  value,
  min,
  max,
  decimals,
  optional = false,
  canBeZero = true,
}: ValidateAmountParemeters): ValidationResult => {
  if (value === undefined) {
    return {valid: optional};
  }

  const validNumber = !isNaN(value);
  // Fallback to true if no min, no max or no decimals are provided
  const isBiggerThanMin = min !== undefined ? value >= min : true;
  const isLessThanMax = max !== undefined ? value <= max : true;
  const validDecimals = decimals !== undefined ? countDecimals(value) <= decimals : true;
  const validZero = canBeZero ? true : value !== 0;

  return {
    valid: validNumber && isBiggerThanMin && isLessThanMax && validDecimals && validZero,
    details: {
      validNumber,
      validRange: isBiggerThanMin && isLessThanMax,
      validDecimals,
      validZero,
    },
  };
};

// note: we're deliberately dismissing leap years, seconds, etc.
const secondsInYear = 365 * 24 * 60 * 60;

/**
 * Calculates forward points corresponding to the given implied yield, maturity, and fx rate.
 *
 * @param impliedYield [number] - Implied yield used to calculate the forward points.
 * @param currentTime [Dayjs] - Current time used in the calculations.
 * @param maturity [Dayjs] - Maturity of the corresponding trade.
 * @param fxRate [number] - Exchange rate used to calculate the forward points.
 */
export const toForwardPoints = (impliedYield: number, currentTime: Dayjs, maturity: Dayjs, fxRate: number): number => {
  const difference = maturity.diff(currentTime, 'seconds');
  if (difference < 0) {
    throw new Error(`Current time is past maturity (currentTime: ${currentTime}, maturity: ${maturity}).`);
  }

  const fwdPrice = fxRate * Math.pow(1.0 + impliedYield * 0.0001, difference / secondsInYear);
  const diff = (fwdPrice - fxRate) * 10000;
  return diff;
};

/**
 * Rounds the given forward points to up to three decimal points.
 */
export const roundForwardPoints = (forwardPoints: number) =>
  forwardPoints < 0 ? roundUp(forwardPoints, 3) : roundDown(forwardPoints, 3);

/**
 * Calculates implied yield corresponding to the given forward points, maturity, and fx rate.
 *
 * @param fwdPoints [number] - Forward points used to calculate the implied yield.
 * @param currentTime [Dayjs] - Current time used in the calculations.
 * @param maturity [Dayjs] - Maturity of the corresponding trade.
 * @param fxRate [number] - Exchange rate used to calculate the implied yield.
 */
export const toImpliedYield = (fwdPoints: number, currentTime: Dayjs, maturity: Dayjs, fxRate: number): number => {
  const difference = maturity.diff(currentTime, 'seconds');
  if (difference < 0) {
    throw new Error(`Current time is past maturity (currentTime: ${currentTime}, maturity: ${maturity}).`);
  }

  const fwdPrice = fwdPoints / 10000 + fxRate;
  return Math.round((Math.pow(fwdPrice / fxRate, secondsInYear / difference) - 1.0) * 10000);
};

export const rateToImpliedYield = (
  nearleg: Dayjs,
  maturity?: Dayjs,
  rate?: Rate,
  spotRate?: number
): number | undefined => {
  if (!rate || !spotRate || !maturity) return;

  return rate.referenceData === ReferenceData.ForwardPoints ?
      toImpliedYield(rate.value, nearleg, maturity, spotRate)
    : rate.value;
};

export const rateToForwardPoints = (
  nearleg: Dayjs,
  maturity?: Dayjs,
  rate?: Rate,
  spotRate?: number
): number | undefined => {
  if (!rate || !spotRate || !maturity) return;

  return rate.referenceData === ReferenceData.ImpliedYield ?
      toForwardPoints(rate.value, nearleg, maturity, spotRate)
    : rate.value;
};

/**
 * Calculates the admissible forward point range.
 *
 * @param minImpliedYield [number] - Implied yield used to calculate the minimum admissible range.
 * @param maxImpliedYield [number] - Implied yield used to calculate the maximum admissible range.
 * @param currentTime [Dayjs] - Current time used in the calculations.
 * @param maturity [Dayjs] - Maturity of the corresponding trade.
 * @param fxRate [number] - Exchange rate used to calculate the admissible range.
 * @param fxRange [number] - Maximum change of fx rate that the trader accepts in bigfigs.
 */
export const admissibleForwardPointRange = (
  minImpliedYield: number,
  maxImpliedYield: number,
  currentTime: Dayjs,
  maturity: Dayjs,
  fxRate: number
): NumLimit => {
  const calculateFwsPoints = (impliedYield: number, rate: number) =>
    toForwardPoints(impliedYield, currentTime, maturity, rate);

  return {
    min: roundForwardPoints(calculateFwsPoints(minImpliedYield, fxRate)),
    max: roundForwardPoints(calculateFwsPoints(maxImpliedYield, fxRate)),
    dec: 3,
  };
};

/**
 * Calculates new amount from base points.
 * Note that we keep the same amount format ("view" | "store").
 *
 * @param amount [Amount]
 * @param bp [number]
 * @returns [Amount]
 */
export const basePointsToAmount = (amount: Amount, bp: number): Amount => ({
  ...amount,
  quantity: amount.quantity * bp * 0.0001,
});

/**
 * Create tooltips for amount fields based on the validation result and form limits
 *
 * @param validationResult [ValidationResult] - Result from validateAmount()
 * @param amountLimits [NumLimit] - min, max, dec values for this field
 * @param currency? [string] - Currency symbol to show in front of the number
 * @param unit? [string] - Unit to show after the number like "m" or "min"
 * @param capacityWarning? [boolean] - whether the warning on negative available capacity is shown
 * @returns [string] - Message that should show on the tooltip when the field is invalid
 */
export const tooltipsForAmountField = (
  validationResult: ValidationResult,
  amountLimits: NumLimit,
  currency?: string,
  unit?: string,
  capacityWarning?: boolean
) => {
  if (validationResult.details && !validationResult.details?.validZero) {
    return 'Please enter a non-zero number';
  }

  if (validationResult.details && !validationResult.details?.validRange) {
    return (
      `Please enter a number between ${currency || ''}${amountLimits.min}${unit || ''} and ` +
      `${currency || ''}${amountLimits.max}${unit || ''}`
    );
  }
  if (validationResult.details && !validationResult.details?.validDecimals) {
    const digitForm: string = amountLimits.dec > 1 ? 'digits' : 'digit';
    return `Please enter up to ${amountLimits.dec} ${digitForm} after the decimal point`;
  }

  if (!validationResult.valid) {
    return 'Please enter a valid number';
  }

  if (capacityWarning) {
    return 'This amount could lead to negative Available Capacity in earmarked currency.';
  }
};

export const getErrorTextForInvalidRange = (amountLimits: NumLimit, currency?: string, unit?: string) =>
  `Please enter a number between ${currency || ''}${amountLimits.min}${unit || ''} and ` +
  `${currency || ''}${amountLimits.max}${unit || ''}`;
