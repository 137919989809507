import {useEffect, useState} from 'react';

import {LayoutPath} from 'types/layout';

import {useNavigate} from 'react-router';

import {MarketType, OrderFormState} from 'types/orderForm';

import dayjs from 'dayjs';

import {FormProvider} from 'react-hook-form';

import useStateAssets from 'utils/hooks/useStateAssets';

import {Scope} from 'constants/permission-maps';
import {Container} from 'containers/RFQForm/styles';
import PermissionsGate from 'components/PermissionGate';

import {OrderForm} from './OrderForm';
import Confirmation from './Confirmation';
import useOrderForm, {FORM_DEFAULT_VALUES, OrderFormValues} from './hooks/useOrderForm';

const parseValuesToOrderForm = (values: OrderFormValues): OrderFormState | undefined => {
  if (!values) return;
  return {
    side: values.side || 'SellBuy',
    marketType: values.marketType || MarketType.Limit,
    isNonAggressorOnly: values.isNonAggressorOnly || false,
    baseAmount: values.baseAmount,
    secondAmount: values.secondAmount,
    initialRate: values.exchangeRate?.value,
    rate: values.rate,
    spotRangeBigFig: values.spotRangeBigFig,
    validUntil: values.validUntil.toString(),
    marketCloseTime: values.marketCloseTime?.toString() || dayjs().toString(),
    maturity: values.maturityDateTime?.toString() || dayjs().toString(),
    counterparty: values.counterparty,
    assetPair: values.assetPair,
  } as OrderFormState;
};

export const OrderFormContainer = () => {
  const navigate = useNavigate();
  const [orderForm, setOrderForm] = useState<OrderFormState>();
  const {methods} = useOrderForm();
  const {baseAsset, secondAsset} = useStateAssets();

  useEffect(() => {
    try {
      methods.setValue('assetPair', {
        base: baseAsset,
        second: secondAsset,
      });
    } catch (error) {
      navigate(LayoutPath.ClobView);
    }
  }, [baseAsset.currency, secondAsset.currency]);

  return (
    <FormProvider {...methods}>
      <PermissionsGate scopes={[Scope.CanTrade]}>
        <Container shouldHideForm={!!orderForm}>
          {orderForm && (
            <Confirmation
              onSuccess={() => {
                methods.reset({
                  ...FORM_DEFAULT_VALUES,
                  requestType: methods.getValues().requestType,
                  exchangeRate: methods.getValues().exchangeRate,
                  marketCloseTime: methods.getValues().marketCloseTime,
                  maturityDateTime: methods.getValues().maturityDateTime,
                  validUntil: methods.getValues().marketCloseTime,
                  assetPair: {
                    base: baseAsset,
                    second: secondAsset,
                  },
                  rate: undefined,
                });
                setOrderForm(undefined);
              }}
              onEdit={() => setOrderForm(undefined)}
              orderForm={orderForm}
            />
          )}
          <OrderForm
            onSubmit={values => {
              setOrderForm(parseValuesToOrderForm(values));
            }}
          />
        </Container>
      </PermissionsGate>
    </FormProvider>
  );
};
