import React from 'react';

interface Props {
  show: boolean;
}

export const loader: React.FunctionComponent<Props> = props => (
  <div className={props.show ? 'order-form-loader show' : 'order-form-loader'}>
    <div className='loader-item'>
      <div />
      <div />
      <div />
    </div>
  </div>
);
