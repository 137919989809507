export const faviconNotificationsConfig = {
  radius: 10,
  backgroundColor: '#DB0101',
  fontColor: '#FFF',
  fontFamily: 'LatoBold, sans-serif', //'Arial',
  fontWeight: 'bold',
  url: `${window.location.origin}/favicon.ico`,
  position: 'top-right',
  show: true,
};
