import {Trade, LegType, CashflowBody, Amount} from 'types/api';

export type CashFlowLegType = 'nearLeg' | 'farLeg';

export type CheckboxProps = {
  trade: Trade;
  legType: CashFlowLegType;
  flagAsCheckboxType: FlagAs;
  cashflows: CashflowBody[];
  legCashflow: Amount;
};

export enum FlagAs {
  Sent = 'Sent',
  Received = 'Received',
  Nonreceipt = 'Nonreceipt',
}

export interface LateFeeButtonProps {
  trade: Trade;
  legType: LegType;
  disabled?: boolean;
}

export type ReviewAcceptedFeeButtonProps = LateFeeButtonProps & {
  type: 'requestor' | 'recipient';
};
