import React from 'react';

import {RFQRequestReceived} from 'components/RFQRequestReceived';

import {ListItem} from '../../styles';
import {ReceivedRequestListItemProps} from '../../types';

const ReceivedRequestListItem = ({request, showComparison}: ReceivedRequestListItemProps) => {
  const onKeyDown = (e: React.KeyboardEvent) => {
    // handle space and return buttons
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      showComparison();
    }
  };
  return (
    <ListItem role='button' aria-pressed='false' tabIndex={0} onClick={showComparison} onKeyDown={onKeyDown}>
      <RFQRequestReceived requestData={request} />
    </ListItem>
  );
};

export default ReceivedRequestListItem;
