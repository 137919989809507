import dayjs from 'dayjs';
import {MarketDay, RFQMarketConfig} from 'types/api';

import {ensureFutureDate} from 'utils/DayjsUtils';

import {ValidTimesRange} from 'ui/TimePicker/types';

type LegInfo = ValidTimesRange;

interface UseLegsConfigArgs {
  selectedMarket?: RFQMarketConfig;
  selectedLegsMarketDay?: MarketDay;
}

export default function useLegsConfig({selectedMarket, selectedLegsMarketDay}: UseLegsConfigArgs) {
  const getLegInfo = (marketDay: MarketDay | undefined) => {
    if (!marketDay || !selectedMarket) return undefined;
    return {
      start: dayjs(marketDay.startsAt),
      end: dayjs(marketDay.endsAt),
    };
  };

  const legInfo: LegInfo | undefined = getLegInfo(selectedLegsMarketDay);
  const openingTimes: ValidTimesRange = {
    start: legInfo?.start && ensureFutureDate(legInfo.start),
    end: legInfo?.end,
  };

  return {
    farLegInfo: legInfo,
    nearLegInfo: legInfo,
    farLegOpeningTimes: openingTimes,
    nearLegOpeningTimes: openingTimes,
  };
}
