import React, {PropsWithChildren} from 'react';
import StackTrace from 'stacktrace-js';

import finteumLogoUrl from 'assets/images/finteum-logo.svg';

import Button from 'ui/Button';

import {Wrapper} from './styles';

export default class ErrorBoundary extends React.Component<
  PropsWithChildren<{}>,
  {hasError: boolean; errorMessage?: string; errorStacktrace?: string}
> {
  constructor(props: PropsWithChildren<{}>) {
    super(props);
    this.state = {hasError: false, errorMessage: '', errorStacktrace: ''};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error: Error) {
    void StackTrace.fromError(error)
      .then(frames => {
        this.setState({
          errorMessage: String(error),
          errorStacktrace: JSON.stringify(frames),
        });
      })
      .catch();
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <img src={finteumLogoUrl} width='265px' />
          <h1>An unexpected error has occured, please consider below steps</h1>
          {this.state.errorMessage && (
            <>
              <code>
                {this.state.errorMessage}
                <br />
                <br />
                {this.state.errorStacktrace}
                <br />
                <br />
                commitHash: {process.env.REACT_APP_COMMIT_SHORT_SHA}, commitDate:{' '}
                {process.env.REACT_APP_COMMIT_TIMESTAMP}, deploymentDate: {process.env.REACT_APP_JOB_STARTED_AT},{' '}
                {navigator.userAgent}
              </code>
              <h1>Copy text above and send it to us on support@finteum.com</h1>
            </>
          )}
          <Button onClick={() => window.location.reload()}>Refresh Page</Button>
          <Button onClick={() => window.location.replace('/login')}>Navigate to home page</Button>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}
