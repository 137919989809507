import {useIntl} from 'react-intl';

import {RFQMarketConfig} from 'types/api';

import {formatCounterAmountRange, getCounterAmount, getRFQAmountFieldLabel} from 'utils/RFQUtils';

import {currencySymbol} from 'utils/AmountUtils';

import {useFormContext} from 'react-hook-form';

import {pairToItem} from 'utils/AssetUtils';

import FieldTitle from 'ui/FieldTitle';
import {FakeAmountField} from 'ui/AmountField/FakeAmountField';
import {Field, FormRow, Label} from 'containers/RFQForm/styles';
import {RFQFormValues} from 'containers/RFQForm/hooks/useRFQForm';
import useSelectedMarketDay from 'containers/RFQForm/hooks/useSelectedMarketDay';

interface Props {
  rfqYouSellLabelFlashId: string;
  rfqYouBuyLabelFlashId: string;
  isRepo: boolean;
  selectedMarket?: RFQMarketConfig;
}

export default function CounterAmountRow({
  rfqYouBuyLabelFlashId,
  rfqYouSellLabelFlashId,
  isRepo,
  selectedMarket,
}: Props) {
  const {formatMessage} = useIntl();
  const methods = useFormContext<RFQFormValues>();
  const {inMillions} = useSelectedMarketDay();
  const {
    currentPair: pair,
    tradedAmountCurrency: tradedCurrency,
    tradedAmount,
    bigfig: spotRangeBigFig,
    side,
    exchangeRate,
  } = methods.watch();
  const currentPair = pair && pairToItem(pair);

  const counterAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'counter',
    assetPair: currentPair?.value,
    isRepo,
    tradedCurrency,
    side,
  });

  const counterCurrency =
    selectedMarket && !isRepo ?
      [selectedMarket.assetPair.base.currency, selectedMarket.assetPair.second.currency].filter(
        currency => currency !== tradedCurrency
      )[0]
    : tradedCurrency;

  const counterAmountRange =
    exchangeRate ?
      getCounterAmount({
        assetPair: pair,
        tradedCurrency,
        tradedAmount,
        isRepo,
        exchangeRate: exchangeRate.value,
        spotRangeBigFig: spotRangeBigFig || 1,
        inMillions,
      })
    : null;

  return (
    <FormRow key='counterAmount'>
      <Label>
        <FieldTitle
          flashKey={
            counterAmountFieldLabel.id === 'youSell' ? rfqYouSellLabelFlashId
            : counterAmountFieldLabel.id === 'youBuy' ?
              rfqYouBuyLabelFlashId
            : undefined
          }
        >
          {formatMessage(counterAmountFieldLabel)}
        </FieldTitle>
      </Label>
      <Field>
        <FakeAmountField
          data-testid='counterAmount'
          name='counterAmount'
          prefix={currencySymbol(counterCurrency)}
          unit={inMillions ? 'm' : undefined}
          value={formatCounterAmountRange(counterAmountRange, inMillions)}
          alwaysShowTooltip={isRepo}
          tooltipPosition='left'
          required
          disabled
        />
      </Field>
    </FormRow>
  );
}
