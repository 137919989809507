import dayjs, {Dayjs} from 'dayjs';
import {
  AggregatedLimit,
  Amount,
  Asset,
  AssetPair,
  AssetWithTimezone,
  Balance,
  EarlyMaturityRequest,
  LegType,
  MarketDay,
  Order,
  OrderStatus,
  Party,
  RFQMarketConfig,
  RFQQuote,
  RFQQuoteWithRequest,
  Notification,
  RFQRequest,
  RequesteeAndQuote,
  StatusReason,
  Trade,
  TradeStatus,
  TransactionDate,
  UserWithMeta,
  CashflowBody,
  FlagType,
  CapacityAdjustment,
  FXSwapRequest,
  TradeAnnul,
  TradeUnwindRequest,
  TradeUnwindRequestReason,
  MTFExecutionDetails,
  TradeRelatedLateSettlementFee,
  SentEarlyMaturityRequestRejectedPayload,
  OrderAggregate,
  ReceivedTradeUnwindProposalCancelledPayload,
  ReceivedQuotePayload,
  CapacityAdjustmentScheduledPayload,
  OrderMatchedPayload,
  ReceivedLateFeeRequestPayload,
  MtMLimitBreachedPayload,
  TradeEvent,
  TradeRelatedOrder,
} from 'types/api';
import {ReferenceData} from 'types/layout';
import {ExchangeRate} from 'types/exchangeRate';
import {makeAmount} from 'utils/AmountUtils';

import {getLegDateTimeInput, getLegTimeInput} from 'utils/RFQUtils';
import {RequestFormState} from 'types/rfqForm';
import {CounterParty, MarketType, OrderFormState} from 'types/orderForm';

import {CreditRiskData} from 'types/analytics';

import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import {NotificationData} from 'pages/NotificationCenterPage/types';

import {Role} from 'constants/permission-maps';

export const gbpAsset: Asset = {
  currency: 'GBP',
  type: 'Cash',
};

export const usdAsset: Asset = {
  currency: 'USD',
  type: 'Cash',
};

export const eurAsset: Asset = {
  currency: 'EUR',
  type: 'Cash',
};

export const usdRepoAsset: Asset = {
  type: 'Cash',
  currency: 'USD',
  holder: 'BNYM',
};

export const usdSecuritiesAsset: Asset = {
  type: 'Securities',
  currency: 'USD',
  holder: 'BNYM',
};

export const eurRepoAsset: Asset = {
  type: 'Cash',
  currency: 'EUR',
  holder: 'EB',
};

export const eurSecuritiesAsset: Asset = {
  type: 'Securities',
  currency: 'EUR',
  holder: 'EB',
};

export const gbpUsdPair: AssetPair = {
  base: gbpAsset,
  second: usdAsset,
};

export const eurGbpPair: AssetPair = {
  base: eurAsset,
  second: gbpAsset,
};

export const eurUsdPair: AssetPair = {
  base: eurAsset,
  second: usdAsset,
};

export const usdRepoPair: AssetPair = {
  base: usdRepoAsset,
  second: usdSecuritiesAsset,
};

export const eurRepoPair: AssetPair = {
  base: eurRepoAsset,
  second: eurSecuritiesAsset,
};

export const nearLegTime = '2020-07-22T21:00:00.000Z';

export const maturityTime = '2020-07-22T22:00:00.000Z';

export const baseAmount1: Amount = {
  asset: gbpAsset,
  quantity: 500000000,
};

export const secondAmount1: Amount = {
  asset: usdAsset,
  quantity: 630000000,
};

export const mockPendingOrder: Order = {
  id: '6e58ddc9-020c-49d3-94aa-524c6ae5c2d1',
  data: {
    side: 'BuySell',
    marketType: 'Limit',
    baseAmount: baseAmount1,
    counterparty: 'ThirdParty',
    interestRate: 50,
    validUntil: '2019-04-02T15:45:00Z',
    baseAsset: gbpAsset,
    secondAsset: usdAsset,
    initialRate: 1.2345,
    isNonAggressor: false,
    spotRange: 0.01,
    userId: 'user-id',
  },
  createdBy: 'O=MileBank, L=London, C=GB',
  unmatchedAmount: baseAmount1,
  status: OrderStatus.Pending,
  statusReason: null,
  createdAt: '2019-04-02T11:30:40.647Z',
  initialForwardPoints: 0.002,
  resumedAt: null,
  currentForwardPoints: null,
  isActive: true,
  isCancellable: true,
  lastUpdatedAt: '2019-04-02T11:30:40.647Z',
  lifted: {
    quantity: 0,
    asset: gbpAsset,
  },
  maturity: '2019-04-02T18:00:00.000Z',
  remainingEarmark: baseAmount1,
  executedAt: null,
};
export const mockLiveOrder: Order = {
  ...mockPendingOrder,
  id: 'mocked-live-order',
  status: OrderStatus.Live,
};
export const mockPausedOrder: Order = {
  ...mockPendingOrder,
  id: 'mocked-paused-order',
  status: OrderStatus.Paused,
};
export const mockInactiveOrder: Order = {
  ...mockPausedOrder,
  isActive: false,
};
export const mockActiveRepoOrder: Order = {
  ...mockPausedOrder,
  data: {
    ...mockPausedOrder.data,
    baseAsset: eurRepoPair.base,
    secondAsset: eurRepoPair.second,
  },
};

export const party1: Party = {
  legalName: 'O=XYZ, L=London, C=GB',
  shortName: null,
};

export const party2: Party = {
  legalName: 'O=ABC, L=London, C=GB',
  shortName: null,
};

export const mileBank: Party = {
  legalName: 'O=MileBank, L=London, C=GB',
  shortName: null,
};

export const inchBank: Party = {
  legalName: 'O=InchBank, L=Frankfurt, C=DE',
  shortName: null,
};

export const bullBank: Party = {
  legalName: 'O=BullBank, L=Frankfurt, C=DE',
  shortName: null,
};

export const bearBank: Party = {
  legalName: 'O=BearBank, L=Frankfurt, C=DE',
  shortName: null,
};

export const sentEarlyMaturityRequest: EarlyMaturityRequest = {
  canBeAccepted: false,
  counterparty: 'O=InchBank, L=Frankfurt, C=DE',
  createdAt: '2023-08-07T12:23:02.367Z',
  requestor: 'O=MileBank, L=London, C=GB',
  status: 'Proposed',
};

export const sentEarlyMaturityRequestAccepted: EarlyMaturityRequest = {
  canBeAccepted: false,
  counterparty: 'O=InchBank, L=Frankfurt, C=DE',
  createdAt: '2023-08-07T12:23:02.367Z',
  requestor: 'O=MileBank, L=London, C=GB',
  status: 'Accepted',
};

export const receivedEarlyMaturityRequest: EarlyMaturityRequest = {
  ...sentEarlyMaturityRequest,
  canBeAccepted: true,
};

export const receivedEarlyMaturityRequestAccepted: EarlyMaturityRequest = {
  ...sentEarlyMaturityRequest,
  canBeAccepted: true,
};

export const mockUpcomingTrade: Trade = {
  baseAmount: baseAmount1,
  counterparty: party1.legalName,
  createdAt: '2020-07-22T18:12:53.406Z',
  id: 'tradeabc123',
  interestRate: 50,
  entity: party2.legalName,
  expectedTradeDuration: 300,
  interestAmount: {
    asset: gbpAsset,
    quantity: 5000,
  },
  ourInterestAmount: {
    asset: gbpAsset,
    quantity: 5000,
  },
  isAggressor: false,
  side: 'BuySell',
  status: TradeStatus.Upcoming,
  secondAmount: secondAmount1,
  tradeType: 'FxSwapTradeType',
  actualTradeDuration: undefined,
  earlyMaturityRequest: undefined,
  forwardPoints: undefined,
  forwardPointsString: undefined,
  relatedOrder: undefined,
  relatedLateSettlementFees: [],
  spotRate: 1.1204,
  statusReason: undefined,
  executionDetails: null,
  nearLeg: {
    id: 'tradeabc123leg1',
    scheduledTime: nearLegTime,
    actualSettlementTime: null,
    cashFlowIn: baseAmount1,
    cashFlowOut: secondAmount1,
  },
  farLeg: {
    id: 'tradeabc123leg2',
    scheduledTime: maturityTime,
    actualSettlementTime: null,
    cashFlowIn: secondAmount1,
    cashFlowOut: baseAmount1,
  },
  userId: 'user-id',
};

export const mockUpcomingRepoTrade: Trade = {
  id: '2b3318d2-f288-4043-bba3-3f7fb164a9c6',
  tradeType: 'RepoTradeType',
  createdAt: nearLegTime,
  relatedLateSettlementFees: [],
  side: 'SellBuy',
  status: TradeStatus.Upcoming,
  baseAmount: {
    quantity: 100000000,
    asset: eurRepoAsset,
  },
  secondAmount: {
    quantity: 111111111,
    asset: eurSecuritiesAsset,
  },
  entity: 'O=MileBank, L=London, C=GB',
  counterparty: 'O=InchBank, L=Frankfurt, C=DE',
  isAggressor: true,
  interestRate: -10,

  interestAmount: {
    quantity: -74,
    asset: eurRepoAsset,
  },
  ourInterestAmount: {
    quantity: -74,
    asset: eurRepoAsset,
  },
  expectedTradeDuration: 23314005,

  executionDetails: null,
  nearLeg: {
    id: '85de26c8-4794-4a44-9cbb-366454740d24',
    actualSettlementTime: nearLegTime,
    scheduledTime: nearLegTime,
    cashFlowIn: {
      quantity: 111111111,
      asset: eurSecuritiesAsset,
    },
    cashFlowOut: {
      quantity: 100000000,
      asset: eurRepoAsset,
    },
  },
  farLeg: {
    id: '6809b2d4-a221-4845-8b8e-90eb95ebcd3b',
    actualSettlementTime: null,
    scheduledTime: maturityTime,
    cashFlowIn: {
      quantity: 99999926,
      asset: eurRepoAsset,
    },
    cashFlowOut: {
      quantity: 111111111,
      asset: eurSecuritiesAsset,
    },
  },
  userId: 'user-id',
};

export const mockOutstandingRepoTrade: Trade = {
  ...mockUpcomingRepoTrade,
  status: TradeStatus.Outstanding,
  relatedLateSettlementFees: [
    {
      id: 'repo-fee',
      createdAt: '2020-08-05T17:02:10.225Z',
      recipient: 'O=TestBank, L=London, C=GB',
      requestor: 'O=TestBank2, L=London, C=GB',
      settlementReceivedTimestamp: '2020-08-05T17:02:10.225Z',
      status: 'Pending',
      legType: LegType.NearLeg,
      fee: {points: 0.000045, amountView: {quantity: 16087841, asset: {type: 'Cash', currency: 'EUR'}}},
    },
  ],
};

export const mockProcessingTrade: Trade = {
  ...mockUpcomingRepoTrade,
  status: TradeStatus.Processing,
  relatedLateSettlementFees: [],
};

export const mockOutstandingTrade: Trade = {
  actualTradeDuration: 18769223,
  baseAmount: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
  counterparty: 'O=TestBank, L=London, C=GB',
  createdAt: '2020-08-05T17:02:10.978Z',
  entity: mileBank.legalName,
  expectedTradeDuration: 18769022,
  forwardPoints: 0.039,
  forwardPointsString: '0.0039006914764',
  id: 'trade-abc125',
  interestAmount: {quantity: 1488, asset: {currency: 'GBP', type: 'Cash'}},
  ourInterestAmount: {quantity: 1488, asset: {currency: 'GBP', type: 'Cash'}},
  interestRate: 50,
  isAggressor: true,
  relatedOrder: {
    baseAmount: {
      asset: {currency: 'GBP', type: 'Cash'},
      quantity: 500000000,
    },
    createdAt: '2020-08-05T17:02:10.225Z',
    filled: {
      asset: {currency: 'GBP', type: 'Cash'},
      quantity: 500000000,
    },
    id: 'abc123',
    impliedYield: 50,
    side: 'SellBuy',
  },
  relatedLateSettlementFees: [],
  side: 'SellBuy',
  spotRate: 1.201,
  status: TradeStatus.Outstanding,
  statusReason: StatusReason.MaturityTimeReached,
  secondAmount: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
  tradeType: 'FxSwapTradeType',
  executionDetails: null,
  nearLeg: {
    id: 'trade-abc125leg1',
    scheduledTime: '2020-07-22T15:00:00.000Z',
    actualSettlementTime: dayjs(nearLegTime).add(300, 'milliseconds').toISOString(),
    cashFlowIn: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
    cashFlowOut: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
  },
  farLeg: {
    id: 'trade-abc125leg2',
    scheduledTime: '2020-07-22T22:00:00.000Z',
    actualSettlementTime: '2020-07-22T22:15:00.000Z',
    cashFlowIn: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
    cashFlowOut: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
  },
  userId: 'user-id',
};

export const mockTradeOnTimezoneBorder: Trade = {
  ...mockOutstandingTrade,
  createdAt: '2024-03-28T21:00:00.000Z',
  nearLeg: {
    id: 'trade-abc125leg1',
    scheduledTime: '2024-03-28T21:00:00.000Z',
    actualSettlementTime: dayjs('2024-03-28T21:00:00.000Z').add(300, 'milliseconds').toISOString(),
    cashFlowIn: {quantity: 120000000, asset: {currency: 'USD', type: 'Cash'}},
    cashFlowOut: {quantity: 100000000, asset: {currency: 'GBP', type: 'Cash'}},
  },
  farLeg: {
    id: 'trade-abc125leg2',
    scheduledTime: '2024-03-28T23:00:00.000Z',
    actualSettlementTime: '2024-03-28T23:00:00.000Z',
    cashFlowIn: {quantity: 100000000, asset: {currency: 'GBP', type: 'Cash'}},
    cashFlowOut: {quantity: 120000000, asset: {currency: 'USD', type: 'Cash'}},
  },
};

export const pendingLateFee: TradeRelatedLateSettlementFee = {
  id: 'test123',
  createdAt: '2020-08-05T17:02:10.225Z',
  recipient: 'O=TestBank, L=London, C=GB',
  requestor: 'O=TestBank2, L=London, C=GB',
  settlementReceivedTimestamp: '2020-08-05T17:02:10.225Z',
  status: 'Pending',
  legType: LegType.NearLeg,
  fee: {points: 0.000045, amountView: {quantity: 16087841, asset: {type: 'Cash', currency: 'USD'}}},
};

export const mockMaturedTrade: Trade = {
  actualTradeDuration: 18769223,
  baseAmount: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
  counterparty: 'O=TestBank, L=London, C=GB',
  createdAt: '2020-08-05T17:02:10.978Z',
  entity: mileBank.legalName,
  expectedTradeDuration: 18769022,
  forwardPoints: 0.039,
  forwardPointsString: '0.0039006914764',
  id: 'trade-abc123',
  interestAmount: {quantity: 1488, asset: {currency: 'GBP', type: 'Cash'}},
  ourInterestAmount: {quantity: 1488, asset: {currency: 'GBP', type: 'Cash'}},
  interestRate: 50,
  isAggressor: true,
  relatedOrder: {
    baseAmount: {
      asset: {currency: 'GBP', type: 'Cash'},
      quantity: 500000000,
    },
    createdAt: '2020-08-05T17:02:10.225Z',
    filled: {
      asset: {currency: 'GBP', type: 'Cash'},
      quantity: 500000000,
    },
    id: 'abc123',
    impliedYield: 50,
    side: 'SellBuy',
  },
  relatedLateSettlementFees: [pendingLateFee],
  side: 'SellBuy',
  spotRate: 1.201,
  status: TradeStatus.Matured,
  statusReason: StatusReason.MaturityTimeReached,
  secondAmount: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
  tradeType: 'FxSwapTradeType',
  executionDetails: null,
  nearLeg: {
    id: 'trade-abc123leg1',
    scheduledTime: '2020-08-05T20:15:00Z',
    actualSettlementTime: '2020-08-05T20:15:20Z',
    cashFlowIn: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
    cashFlowOut: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
  },
  farLeg: {
    id: 'trade-abc123leg2',
    scheduledTime: '2020-08-05T22:15:00Z',
    actualSettlementTime: '2020-08-05T22:16:00.00Z',
    cashFlowIn: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
    cashFlowOut: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
  },
  userId: 'user-id',
};

export const mockEarlyMaturityRequestedTrade: Trade = {
  ...mockOutstandingTrade,
  earlyMaturityRequest: sentEarlyMaturityRequest,
};

export const mockEarlyMaturityAcceptedTrade: Trade = {
  ...mockOutstandingTrade,
  earlyMaturityRequest: sentEarlyMaturityRequestAccepted,
};

export const mockMaturedTradeWithPendingFeeOnNearLeg = {
  ...mockMaturedTrade,
  relatedLateSettlementFees: [{...pendingLateFee, requestor: 'O=MileBank, L=London, C=GB'}],
};

export const mockMaturedTradeWithPendingFeeOnFarLeg = {
  ...mockMaturedTrade,
  relatedLateSettlementFees: [{...pendingLateFee, legType: LegType.FarLeg, requestor: 'O=MileBank, L=London, C=GB'}],
};

export const mockAcceptedLateFee: TradeRelatedLateSettlementFee = {
  id: 'test123',
  createdAt: '2020-08-06T17:02:10.225Z',
  recipient: 'O=InchBank, L=London, C=GB',
  requestor: 'O=MileBank, L=London, C=GB',
  settlementReceivedTimestamp: '2020-08-06T17:02:10.225Z',
  status: 'Accepted',
  legType: LegType.NearLeg,
  fee: {points: 0.000045, amountView: {quantity: 16087841, asset: {type: 'Cash', currency: 'USD'}}},
};

export const mockAcceptedLateFeeSwapped: TradeRelatedLateSettlementFee = {
  id: 'test321',
  createdAt: '2020-08-06T18:02:10.225Z',
  requestor: 'O=InchBank, L=London, C=GB',
  recipient: 'O=MileBank, L=London, C=GB',
  settlementReceivedTimestamp: '2020-08-06T18:02:10.225Z',
  status: 'Accepted',
  legType: LegType.FarLeg,
  fee: {points: 0.000045, amountView: {quantity: 53126201, asset: {type: 'Cash', currency: 'EUR'}}},
};

export const mockMaturedTradeWithAcceptedLateFeeRequest: Trade = {
  actualTradeDuration: 18769223,
  baseAmount: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
  counterparty: 'O=InchBank, L=London, C=GB',
  createdAt: '2020-08-05T17:02:10.978Z',
  entity: mileBank.legalName,
  expectedTradeDuration: 18769022,
  forwardPoints: 0.039,
  forwardPointsString: '0.0039006914764',
  id: 'trade-abc123',
  interestAmount: {quantity: 1488, asset: {currency: 'GBP', type: 'Cash'}},
  ourInterestAmount: {quantity: 1488, asset: {currency: 'GBP', type: 'Cash'}},
  interestRate: 50,
  isAggressor: true,
  relatedOrder: {
    baseAmount: {
      asset: {currency: 'GBP', type: 'Cash'},
      quantity: 500000000,
    },
    createdAt: '2020-08-05T17:02:10.225Z',
    filled: {
      asset: {currency: 'GBP', type: 'Cash'},
      quantity: 500000000,
    },
    id: 'abc123',
    impliedYield: 50,
    side: 'SellBuy',
  },
  relatedLateSettlementFees: [mockAcceptedLateFee],
  side: 'SellBuy',
  spotRate: 1.201,
  status: TradeStatus.Matured,
  statusReason: StatusReason.MaturityTimeReached,
  secondAmount: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
  tradeType: 'FxSwapTradeType',
  executionDetails: null,
  nearLeg: {
    id: 'trade-abc123leg1',
    scheduledTime: nearLegTime,
    actualSettlementTime: dayjs(nearLegTime).add(300, 'milliseconds').toISOString(),
    cashFlowIn: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
    cashFlowOut: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
  },
  farLeg: {
    id: 'trade-abc123leg2',
    scheduledTime: '2020-08-05T22:15:00Z',
    actualSettlementTime: '2020-08-05T22:15:00.201Z',
    cashFlowIn: {quantity: 500000000, asset: {currency: 'GBP', type: 'Cash'}},
    cashFlowOut: {quantity: 656383000, asset: {currency: 'USD', type: 'Cash'}},
  },
  userId: 'user-id',
};

export const mockMaturedTradeWithAcceptedLateFeeOnFarLeg: Trade = {
  ...mockMaturedTradeWithAcceptedLateFeeRequest,
  relatedLateSettlementFees: [{...mockAcceptedLateFee, legType: LegType.FarLeg}],
};
export const mockMaturedTradeWithAcceptedLateFeeSwapped: Trade = {
  ...mockMaturedTradeWithAcceptedLateFeeRequest,
  relatedLateSettlementFees: [
    {
      ...mockAcceptedLateFeeSwapped,
      legType: LegType.NearLeg,
    },
  ],
};
export const mockMaturedTradeWithAcceptedLateFeeSwappedOnFarLeg: Trade = {
  ...mockMaturedTradeWithAcceptedLateFeeRequest,
  relatedLateSettlementFees: [mockAcceptedLateFeeSwapped],
};

export const mockUserWithMeta = (role: Role = Role.Trader) =>
  ({
    id: '1',
    user: {
      firstName: 'Jane',
      lastName: 'Smith',
      role,
    },
    legalEntities: [{legalName: 'O=MileBank, L=London, C=GB', shortName: 'Mile'}],
  }) as UserWithMeta;

export const mockLegalEntities = [
  {legalName: 'O=MileBank, L=London, C=GB', shortName: 'Mile'},
  {legalName: 'O=InchBank, L=Frankfurt, C=DE', shortName: 'Inch'},
];

export const makeMarketDay = (
  pair: AssetPair,
  openTime: string | Dayjs,
  closeTime: string | Dayjs,
  maturesAt: string | Dayjs,
  minTradeSize: number = 1000000,
  maxTradeSize: number = 500000000
): MarketDay => ({
  assetPair: pair,
  startsAt: dayjs(openTime).toISOString(),
  endsAt: dayjs(closeTime).toISOString(),
  maturesAt: dayjs(maturesAt).toISOString(),
  minTradeSize,
  maxTradeSize,
});

export const makeRFQMarketConfig = (assetPair: AssetPair, zoneId: string = 'Europe/London'): RFQMarketConfig => ({
  assetPair,
  zoneId,
});

export const mockExchangeRates1 = [
  {
    pair: {
      base: 'GBP',
      second: 'USD',
    },
    rate: {
      value: 1.241218,
      timestamp: 1682084103,
      currencyPair: {
        base: 'GBP',
        second: 'USD',
      },
    },
  },
  {
    pair: {
      base: 'GBP',
      second: 'EUR',
    },
    rate: {
      value: 1.129819,
      timestamp: 1682084103,
      currencyPair: {
        base: 'GBP',
        second: 'EUR',
      },
    },
  },
  {
    pair: {
      base: 'USD',
      second: 'GBP',
    },
    rate: {
      value: 0.80551,
      timestamp: 1682083924,
      currencyPair: {
        base: 'USD',
        second: 'GBP',
      },
    },
  },
  {
    pair: {
      base: 'USD',
      second: 'EUR',
    },
    rate: {
      value: 0.91049,
      timestamp: 1682083924,
      currencyPair: {
        base: 'USD',
        second: 'EUR',
      },
    },
  },
  {
    pair: {
      base: 'EUR',
      second: 'GBP',
    },
    rate: {
      value: 0.885097,
      timestamp: 1682084103,
      currencyPair: {
        base: 'EUR',
        second: 'GBP',
      },
    },
  },
  {
    pair: {
      base: 'EUR',
      second: 'USD',
    },
    rate: {
      value: 1.098599,
      timestamp: 1682084103,
      currencyPair: {
        base: 'EUR',
        second: 'USD',
      },
    },
  },
] as ExchangeRate[];

export const mockMarketConfigs = [
  makeRFQMarketConfig(gbpUsdPair),
  makeRFQMarketConfig(eurGbpPair),
  makeRFQMarketConfig(eurUsdPair),
  makeRFQMarketConfig(usdRepoPair),
  makeRFQMarketConfig(eurRepoPair),
] as RFQMarketConfig[];

export const mockAssets = [
  {
    asset: usdAsset,
    timezoneLabeled: {
      id: 'America/New_York',
      label: 'NYC',
    },
  },
  {
    asset: gbpAsset,
    timezoneLabeled: {
      id: 'Europe/London',
      label: 'LDN',
    },
  },
  {
    asset: eurAsset,
    timezoneLabeled: {
      id: 'Europe/Berlin',
      label: 'FRA',
    },
  },
  {
    asset: usdRepoAsset,
    timezoneLabeled: {
      id: 'America/New_York',
      label: 'NYC',
    },
  },
  {
    asset: usdSecuritiesAsset,
    timezoneLabeled: {
      id: 'America/New_York',
      label: 'NYC',
    },
  },
] as AssetWithTimezone[];

export const mockMarketDays = [
  {
    assetPair: eurUsdPair,
    startsAt: '2024-01-09T06:30:00Z',
    endsAt: '2024-01-09T23:30:00Z',
  },
  {
    assetPair: eurUsdPair,
    startsAt: '2024-01-10T06:30:00Z',
    endsAt: '2024-01-10T23:30:00Z',
  },
  {
    assetPair: eurGbpPair,
    startsAt: '2024-01-09T06:30:00Z',
    endsAt: '2024-01-09T23:30:00Z',
  },
  {
    assetPair: eurGbpPair,
    startsAt: '2024-01-10T06:30:00Z',
    endsAt: '2024-01-10T23:30:00Z',
  },
  {
    assetPair: eurRepoPair,
    startsAt: '2024-01-09T06:30:00Z',
    endsAt: '2024-01-09T22:30:00Z',
  },
  {
    assetPair: eurRepoPair,
    startsAt: '2024-01-10T06:30:00Z',
    endsAt: '2024-01-10T22:30:00Z',
  },
  {
    assetPair: gbpUsdPair,
    startsAt: '2024-01-09T06:30:00Z',
    endsAt: '2024-01-09T23:30:00Z',
  },
  {
    assetPair: gbpUsdPair,
    startsAt: '2024-01-10T06:30:00Z',
    endsAt: '2024-01-10T23:30:00Z',
  },
  {
    assetPair: usdRepoPair,
    startsAt: '2024-01-09T06:30:00Z',
    endsAt: '2024-01-09T22:30:00Z',
  },
  {
    assetPair: usdRepoPair,
    startsAt: '2024-01-10T06:30:00Z',
    endsAt: '2024-01-10T22:30:00Z',
  },
] as MarketDay[];

export const aggregatedLimits1: AggregatedLimit[] = [
  {
    counterparty: 'O=InchBank, L=Frankfurt, C=DE',
    settlementRiskLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 100000000,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      total: {
        quantity: 300000000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
    mtmLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 1000000,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      total: {
        quantity: 300000000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
    clobMatchesLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 1000000,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      total: {
        quantity: 1000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
  },
  {
    counterparty: 'O=BullBank, L=Vitória, C=BR',
    settlementRiskLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 10000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      total: {
        quantity: 500000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
    mtmLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 10000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      total: {
        quantity: 300000000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
    clobMatchesLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 0,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      total: {
        quantity: 0,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
  },
  {
    counterparty: 'O=BearBank, L=Kraków, C=PL',
    settlementRiskLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 1000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      total: {
        quantity: 6000000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
    mtmLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 100000000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      total: {
        quantity: 300000000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
    clobMatchesLimit: {
      exposure: {
        quantity: 0,
        asset: {
          currency: 'USD',
          type: 'Cash',
        },
      },
      earmark: {
        quantity: 1000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      total: {
        quantity: 500000000,
        asset: {
          type: 'Cash',
          currency: 'USD',
        },
      },
      timestamp: 0,
    },
  },
];

export const mockCreditRiskData: CreditRiskData[] = [
  {
    counterparty: 'O=BearBank, L=Kraków, C=PL',
    mtm: {
      counterparty: 'O=BearBank, L=Kraków, C=PL',
      exposure: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      limit: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      remaining: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      timestamp: '0',
    },
    settlementVersusLimit: {
      // counterparty: 'O=BearBank, L=Kraków, C=PL',
      exposure: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      limit: {quantity: 500000000, asset: {type: 'Cash', currency: 'USD'}},
      remaining: {quantity: 500000000, asset: {type: 'Cash', currency: 'USD'}},
      earmarked: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
    },
    settlementRisks: [
      {
        bought: {quantity: 0, asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'}},
        sold: {quantity: 0, asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'}},
        sent: '--',
        received: '--',
        asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Securities', currency: 'EUR', holder: 'EB'}},
        sold: {quantity: 0, asset: {type: 'Securities', currency: 'EUR', holder: 'EB'}},
        sent: '--',
        received: '--',
        asset: {type: 'Securities', currency: 'EUR', holder: 'EB'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'EUR', holder: 'EB'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'EUR', holder: 'EB'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'EUR', holder: 'EB'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'CAD'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'CAD'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'CAD'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'EUR'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'EUR'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'EUR'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'GBP'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'GBP'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'GBP'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'USD'},
      },
    ],
  },
  {
    counterparty: 'O=BullBank, L=Vitória, C=BR',
    mtm: {
      counterparty: 'O=BullBank, L=Vitória, C=BR',
      exposure: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      limit: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      remaining: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      timestamp: '0',
    },
    settlementVersusLimit: {
      // counterparty: 'O=BullBank, L=Vitória, C=BR',
      exposure: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      limit: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      remaining: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      earmarked: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
    },
    settlementRisks: [
      {
        bought: {quantity: 0, asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'}},
        sold: {quantity: 0, asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'}},
        sent: '--',
        received: '--',
        asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Securities', currency: 'EUR', holder: 'EB'}},
        sold: {quantity: 0, asset: {type: 'Securities', currency: 'EUR', holder: 'EB'}},
        sent: '--',
        received: '--',
        asset: {type: 'Securities', currency: 'EUR', holder: 'EB'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'EUR', holder: 'EB'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'EUR', holder: 'EB'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'EUR', holder: 'EB'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'CAD'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'CAD'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'CAD'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'EUR'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'EUR'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'EUR'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'GBP'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'GBP'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'GBP'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'USD'},
      },
    ],
  },
  {
    counterparty: 'O=InchBank, L=Frankfurt, C=DE',
    mtm: {
      counterparty: 'O=InchBank, L=Frankfurt, C=DE',
      exposure: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      limit: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      remaining: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      timestamp: '0',
    },
    settlementVersusLimit: {
      // counterparty: 'O=InchBank, L=Frankfurt, C=DE',
      exposure: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      limit: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      remaining: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      earmarked: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
      sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
    },
    settlementRisks: [
      {
        bought: {quantity: 0, asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'}},
        sold: {quantity: 0, asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'}},
        sent: '--',
        received: '--',
        asset: {type: 'Securities', currency: 'USD', holder: 'BNYM'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'USD', holder: 'BNYM'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Securities', currency: 'EUR', holder: 'EB'}},
        sold: {quantity: 0, asset: {type: 'Securities', currency: 'EUR', holder: 'EB'}},
        sent: '--',
        received: '--',
        asset: {type: 'Securities', currency: 'EUR', holder: 'EB'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'EUR', holder: 'EB'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'EUR', holder: 'EB'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'EUR', holder: 'EB'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'CAD'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'CAD'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'CAD'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'EUR'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'EUR'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'EUR'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'GBP'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'GBP'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'GBP'},
      },
      {
        bought: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
        sold: {quantity: 0, asset: {type: 'Cash', currency: 'USD'}},
        sent: '--',
        received: '--',
        asset: {type: 'Cash', currency: 'USD'},
      },
    ],
  },
];

export const mockSideBarData: OrderAggregate = {
  unmatchedAmount: {
    quantity: 12300000000,
    asset: {
      type: 'Cash',
      currency: 'EUR',
    },
  },
  cumulativeAmount: {
    quantity: 12300000000,
    asset: {
      type: 'Cash',
      currency: 'EUR',
    },
  },
  impliedYield: 3,
  forwardPoints: 0.003,
  ourUnmatchedAmount: {
    quantity: 12300000000,
    asset: {
      type: 'Cash',
      currency: 'EUR',
    },
  },
};

export const mockQuote: RFQQuote = {
  id: 'abcdef-123',
  quoter: 'O=InchBank, L=Frankfurt, C=DE',
  interestRate: 50,
  forwardPoints: 0.2,
  tradedAmount: {
    quantity: 400000000,
    asset: {type: 'Cash', currency: 'GBP'},
  },
  createdAt: '2021-02-01T17:15:24.366Z',
  validUntil: '2021-02-01T17:16:24.366Z',
  userId: 'user-id',
};

export const mockRequesteeAndQuote: RequesteeAndQuote = {
  id: 'abc',
  expired: false,
  quote: mockQuote,
  requestee: inchBank,
  stage: {name: 'QuoteIssued', reasons: []},
};
export const mockRequesteeAndQuote2: RequesteeAndQuote = {
  id: 'abcd',
  expired: false,
  quote: mockQuote,
  requestee: inchBank,
  stage: {name: 'Started', reasons: []},
};

export const mockRequesteeAndQuote3: RequesteeAndQuote = {
  id: 'abcde',
  expired: false,
  quote: mockQuote,
  requestee: inchBank,
  stage: {name: 'RequestRejected', reasons: ['AmountTooSmall', 'DoesNotSuitToBuy']},
};

export const mockRequestType = {
  type: 'FXSwapRequestType',
  initialFXRate: 1.3,
  spotRange: 0.07,
} as FXSwapRequest;

export const mockRFQRequest: RFQRequest = {
  tradedAmount: {
    quantity: 500000000,
    asset: {type: 'Cash', currency: 'GBP'},
  },
  baseAsset: {type: 'Cash', currency: 'GBP'},
  createdAt: '2021-01-28T11:39:00.000Z',
  id: 'abc-123',
  isReceived: false,
  requestType: mockRequestType,
  nearLegTimeInput: getLegTimeInput(dayjs(), 'T+0'),
  maturityTime: '2021-01-28T15:00:00.000Z',
  maturityTimeInput: getLegTimeInput(dayjs('2021-01-28T15:00:00.000Z'), 'T+0'),
  requesteeSide: 'BuySell',
  requesteesAndQuotes: [mockRequesteeAndQuote],
  requestor: {
    legalName: 'O=MileBank, L=London, C=GB',
    shortName: null,
  },
  requestorSide: 'SellBuy',
  secondAsset: {type: 'Cash', currency: 'USD'},
  validUntil: '2021-01-28T11:41:00.000Z',
  suggestedInterestRate: 50,
  suggestedForwardPoints: 0.2,
  status: 'Waiting',
  userId: 'user-id',
};

const nearLegTime2 = '2021-01-28T07:00:00.000Z';
const nearLegTimeInput = getLegTimeInput(dayjs(nearLegTime2), 'T+1');
export const mockRFQRequestTPlusOne = set(
  set(
    set(set(cloneDeep(mockRFQRequest), 'id', '123'), 'nearLegTime', nearLegTime2),
    'nearLegTimeInput',
    nearLegTimeInput
  ),
  'maturityTimeInput.transactionDate',
  TransactionDate.TPlusOne
);

export const mockSingleQuoteWithRequest: RFQQuoteWithRequest = {
  requestId: 'abc-123',
  tradedAmount: {
    quantity: 500000000,
    asset: {type: 'Cash', currency: 'GBP'},
  },
  baseAsset: {type: 'Cash', currency: 'GBP'},
  requestType: {
    type: 'FXSwapRequestType',
    initialFXRate: 1.3,
    spotRange: 0.07,
  },
  maturityTime: '2021-01-28T15:00:00.000Z',
  maturityTimeInput: {
    transactionDate: TransactionDate.TPlusZero,
    localTime: '00:00:00.000Z',
    zoneId: 'Europe/London',
  },
  requesteeSide: 'BuySell',
  requestor: 'O=MileBank, L=London, C=GB',
  requestorSide: 'SellBuy',
  secondAsset: {type: 'Cash', currency: 'USD'},
  expired: false,
  quote: mockQuote,
  requestee: 'O=InchBank, L=Frankfurt, C=DE',
  stage: {name: 'Started', reasons: []},
  isReceived: true,
};

export const mockSingleQuoteSentWithRequest: RFQQuoteWithRequest = {
  requestId: 'abc-123',
  tradedAmount: {
    quantity: 500000000,
    asset: {type: 'Cash', currency: 'GBP'},
  },
  baseAsset: {type: 'Cash', currency: 'GBP'},
  requestType: {
    type: 'FXSwapRequestType',
    initialFXRate: 1.3,
    spotRange: 0.07,
  },
  maturityTime: '2021-01-28T15:00:00.000Z',
  maturityTimeInput: {
    transactionDate: TransactionDate.TPlusZero,
    localTime: '00:00:00.000Z',
    zoneId: 'Europe/London',
  },
  requesteeSide: 'BuySell',
  requestor: 'O=MileBank, L=London, C=GB',
  requestorSide: 'SellBuy',
  secondAsset: {type: 'Cash', currency: 'USD'},
  expired: false,
  quote: mockQuote,
  requestee: 'O=InchBank, L=Frankfurt, C=DE',
  stage: {name: 'QuoteIssued', reasons: []},
  isReceived: false,
};

export const mockSingleQuoteWithRequestRepo: RFQQuoteWithRequest = {
  requestId: 'b900cab8-f770-4c73-801d-11dcaff83bf3',
  requestor: 'O=MileBank, L=London, C=GB',
  requestee: 'O=BullBank, L=Frankfurt, C=DE',
  baseAsset: usdRepoAsset,
  secondAsset: usdSecuritiesAsset,
  requestorSide: 'SellBuy',
  requesteeSide: 'BuySell',
  tradedAmount: {
    quantity: 100000000,
    asset: usdRepoAsset,
  },
  nearLegTime: '2023-12-07T18:00:00Z',
  nearLegTimeInput: {
    transactionDate: TransactionDate.TPlusZero,
    localTime: '18:00:00',
    zoneId: 'Europe/London',
  },
  maturityTime: '2023-12-07T22:30:00Z',
  maturityTimeInput: {
    transactionDate: TransactionDate.TPlusZero,
    localTime: '22:30:00',
    zoneId: 'Europe/London',
  },
  requestType: {
    type: 'RepoRequestType',
  },
  quote: {
    id: 'ad78d800-0a4a-4cbe-b82a-57eb9c166d8b',
    quoter: 'O=InchBank, L=Frankfurt, C=DE',
    interestRate: -10,
    forwardPoints: undefined,
    tradedAmount: {
      quantity: 100000000,
      asset: usdRepoAsset,
    },
    createdAt: '2023-12-07T12:46:56.800Z',
    validUntil: '2023-12-07T13:16:56.746Z',
    userId: 'user-id',
  },
  isReceived: true,
  expired: false,
  stage: {
    name: 'QuoteIssued',
    reasons: [],
  },
};

export const makeBalance = (asset: Asset, available: number, earmark: number, total: number): Balance => ({
  asset,
  available: makeAmount(available, asset.currency),
  earmark: makeAmount(earmark, asset.currency),
  total: makeAmount(total, asset.currency),
});

export const mockBalances: Balance[] = [
  makeBalance(usdAsset, 5000000000, 0, 5000000000),
  makeBalance(gbpAsset, -5000000000, 0, -5000000000),
  makeBalance(eurAsset, 5000000000, 0, 5000000000),
];

export const mockEarmarkBalances: Balance[] = [
  makeBalance(usdAsset, 5000000000, 3000000000, 0),
  makeBalance(gbpAsset, -5000000000, -3000000000, 0),
  makeBalance(eurAsset, 5000000000, 3000000000, 0),
];

export const mockBalancesLessThenMil: Balance[] = [
  makeBalance(usdAsset, 13500, 13500, 0),
  makeBalance(gbpAsset, -70000, -70000, 0),
  makeBalance(eurAsset, 54000, 54000, 0),
];

export const cashFlowBody: CashflowBody[] = [
  {
    flagType: FlagType.NearLegReceived,
    flagValue: true,
    markedTimestamp: '2024-01-31T15:03:53.153Z',
  },
  {
    flagType: FlagType.NearLegSent,
    flagValue: true,
    markedTimestamp: '2024-01-31T15:03:45.859Z',
  },
  {
    flagType: FlagType.NearLegNonreceipt,
    flagValue: false,
    markedTimestamp: null,
  },
  {
    flagType: FlagType.FarLegReceived,
    flagValue: false,
    markedTimestamp: null,
  },
  {
    flagType: FlagType.FarLegSent,
    flagValue: false,
    markedTimestamp: null,
  },
  {
    flagType: FlagType.FarLegNonreceipt,
    flagValue: false,
    markedTimestamp: null,
  },
];

export const mockMarketHistory = {
  assetPair: {base: {type: 'Cash', currency: 'GBP'}, second: {type: 'Cash', currency: 'USD'}},
  items: [
    {
      startTime: '2021-05-14T00:00:00Z',
      duration: 600000,
      volume: 200,
    },
    {
      startTime: '2021-05-14T10:00:00Z',
      duration: 600000,
      volume: 400,
    },
    {
      startTime: '2021-05-14T20:00:00Z',
      duration: 600000,
      volume: 300,
    },
  ],
  lastTraded: undefined,
};

export const mockMarketSnapshotGbpUsd = {
  assetPair: {base: {type: 'Cash', currency: 'GBP'}, second: {type: 'Cash', currency: 'USD'}},
  spotRate: {value: 1, timestamp: 0, currencyPair: {base: 'GBP', second: 'USD'}},
  bestBuySellRate: {depth: 100000000, interestRate: 10, forwardPoints: 0.1},
  bestSellBuyRate: undefined,
};

export const mockMarketSnapshotGbpUsd2 = {
  assetPair: {base: {type: 'Cash', currency: 'GBP'}, second: {type: 'Cash', currency: 'USD'}},
  spotRate: {value: 1, timestamp: 0, currencyPair: {base: 'GBP', second: 'USD'}},
  bestBuySellRate: {depth: 500000000, interestRate: 10, forwardPoints: 0.1},
  bestSellBuyRate: undefined,
};

export const mockMarketSnapshotEurUsd = {
  assetPair: {base: {type: 'Cash', currency: 'EUR'}, second: {type: 'Cash', currency: 'USD'}},
  spotRate: {value: 1, timestamp: 0, currencyPair: {base: 'GBP', second: 'USD'}},
  bestBuySellRate: {depth: 500000000, interestRate: 10, forwardPoints: 0.1},
  bestSellBuyRate: undefined,
};

export const mockTradeRelatedOrder: TradeRelatedOrder = {
  baseAmount: {
    asset: {currency: 'GBP', type: 'Cash'},
    quantity: 500000000,
  },
  createdAt: '2020-08-05T17:02:10.225Z',
  filled: {
    asset: {currency: 'GBP', type: 'Cash'},
    quantity: 500000000,
  },
  id: 'abc123',
  impliedYield: 50,
  side: 'BuySell',
};

export const mockSmallTradeRelatedOrder: TradeRelatedOrder = {
  baseAmount: {quantity: 41231, asset: {currency: 'USD', type: 'Cash'}},
  createdAt: '2020-08-05T17:02:10.225Z',
  filled: {quantity: 41231, asset: {currency: 'USD', type: 'Cash'}},
  id: 'abc123',
  impliedYield: 50,
  side: 'BuySell',
};

export const mockOrderBook = {
  sellBuy: [
    {
      unmatchedAmount: {
        quantity: 2000000000,
        asset: gbpAsset,
      },
      cumulativeAmount: {
        quantity: 4200000000,
        asset: gbpAsset,
      },
      impliedYield: 87,
      forwardPoints: 0.1395,
      ourUnmatchedAmount: {
        quantity: 300000000,
        asset: gbpAsset,
      },
    },
  ],
  buySell: [
    {
      unmatchedAmount: {
        quantity: 2000000000,
        asset: gbpAsset,
      },
      cumulativeAmount: {
        quantity: 4200000000,
        asset: gbpAsset,
      },
      impliedYield: 30,
      forwardPoints: 0.1,
      ourUnmatchedAmount: {
        quantity: 0,
        asset: gbpAsset,
      },
    },
  ],
};

export const mockOrderBook2 = {
  sellBuy: [
    {
      unmatchedAmount: {
        quantity: 1000000000,
        asset: gbpAsset,
      },
      cumulativeAmount: {
        quantity: 4000000000,
        asset: gbpAsset,
      },
      impliedYield: 87,
      forwardPoints: 0.1395,
      ourUnmatchedAmount: {
        quantity: 300000000,
        asset: gbpAsset,
      },
    },
  ],
  buySell: [
    {
      unmatchedAmount: {
        quantity: 2000000000,
        asset: gbpAsset,
      },
      cumulativeAmount: {
        quantity: 4200000000,
        asset: gbpAsset,
      },
      impliedYield: 30,
      forwardPoints: 0.1,
      ourUnmatchedAmount: {
        quantity: 0,
        asset: gbpAsset,
      },
    },
  ],
};

export const mockCapacityAdjustment = {
  amount: {
    quantity: 100000,
    asset: gbpAsset,
  },
  createdAt: '2024-02-07T11:44:14.081Z',
  issuerId: '123',
  from: 'Mile',
  to: 'Inch',
};

export const mockTradeAnnul: TradeAnnul = {
  canBeAccepted: true,
  requestor: 'O=MileBank, L=London, C=GB',
  recipient: 'O=InchBank, L=Frankfurt, C=DE',
  createdAt: '2023-11-27T10:08:52.817Z',
  changedAt: '',
  status: 'Proposed',
  reasons: ['MarketConditions', 'ExpectedSettlementIssues'],
  tradeId: mockUpcomingTrade.id,
};

export const mockTradeUnwind: TradeUnwindRequest = {
  canBeAccepted: true,
  requestor: 'O=MileBank, L=London, C=GB',
  recipient: 'O=InchBank, L=Frankfurt, C=DE',
  createdAt: '2023-11-27T10:08:52.817Z',
  changedAt: '',
  status: 'Proposed',
  reason: TradeUnwindRequestReason.NotSent,
  tradeId: mockUpcomingTrade.id,
};

export const mockCancelledTradeUnwind: TradeUnwindRequest = {
  canBeAccepted: false,
  requestor: 'O=MileBank, L=London, C=GB',
  recipient: 'O=InchBank, L=Frankfurt, C=DE',
  createdAt: '2024-03-19T12:15:56.504Z',
  status: 'Cancelled',
  reason: TradeUnwindRequestReason.NotSent,
  tradeId: mockUpcomingTrade.id,
};

export const mockTradeEvent: TradeEvent = {
  id: 'trade-event',
  date: dayjs('2024-03-19T12:15:56.504Z'),
  event: 'Early Maturity Requested',
  leg: LegType.FarLeg,
  amount: {
    quantity: 100000,
    asset: gbpAsset,
  },
};

export const mockCapacityAdjustments: CapacityAdjustment[] = [
  {
    amount: baseAmount1,
    issuerId: '00000000-0000-0000-0000-000000000000',
    createdAt: '2024-02-06T16:00:00.000Z',
    from: '2024-02-06T23:30:00Z',
    to: '2024-02-06T23:45:00Z',
  },
  {
    amount: secondAmount1,
    issuerId: '00000000-0000-0000-0000-000000000000',
    createdAt: '2024-02-06T16:00:00.000Z',
    from: '2024-02-06T23:30:00Z',
    to: '2024-02-06T23:45:00Z',
  },
];

export const rfqFormState = {
  side: 'SellBuy',
  pair: gbpUsdPair,
  counterparty1: 'O=Inch Bank,L=London,C=GB',
  counterparty2: '',
  counterparty3: '',
  tradedAmount: 5,
  tradedCurrency: 'GBP',
  spotRangeBigFig: 1,
  nearLeg: getLegDateTimeInput('Mon Oct 26 2020 15:00:48 GMT+0100', 'T+0'),
  farLeg: getLegDateTimeInput('Mon Oct 26 2020 16:00:48 GMT+0100', 'T+0'),
  nearLegInstant: dayjs('Mon Oct 26 2020 15:00:48 GMT+0100'),
  farLegInstant: dayjs('Mon Oct 26 2020 16:00:48 GMT+0100'),
  validFor: 2,
  pending: false,
  initialFXRate: 1.2,
  suggestedRate: {
    value: 1.2345,
    referenceData: ReferenceData.ImpliedYield,
  },
} satisfies RequestFormState;

export const orderFormState = {
  baseAmount: 5,
  counterparty: CounterParty.ThirdParty,
  rate: {value: 50, referenceData: ReferenceData.ImpliedYield},
  initialRate: 1,
  isNonAggressorOnly: false,
  marketCloseTime: dayjs.tz('2020-11-17 00:00:00', 'Europe/Warsaw').toISOString(),
  marketType: MarketType.Limit,
  maturity: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  side: 'SellBuy',
  spotRangeBigFig: 1,
  secondAmount: 6.42,
  validUntil: dayjs('Thu Sep 17 2020 00:00:00 GMT+0200').toISOString(),
  assetPair: gbpUsdPair,
} satisfies OrderFormState;

export const mockSentEarlyMaturityRequestAccepted = {
  notificationType: 'SentEarlyMaturityRequestAccepted',
  timestamp: '2020-09-17T10:00:00.000Z',
  severity: 'Info',
  owner: 'O=MileBank, L=London, C=GB',
  payload: {
    assetPair: gbpUsdPair,
    ourSide: 'BuySell',
    otherParty: 'O=InchBank, L=Frankfurt, C=DE',
  },
  aggregateId: '000-000',
};

export const mockSentRequest: Notification = {
  owner: 'owner',
  notificationType: 'RequestSent',
  payload: {
    requestId: 'xyz',
    assetPair: {
      base: {currency: 'GBP', type: 'Cash'},
      second: {currency: 'USD', type: 'Cash'},
    },
    quoter: 'O=MileBank',
    ourSide: 'BuySell',
  },
  severity: 'Info',
  timestamp: dayjs.tz('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockReceivedRequest: Notification = {
  owner: 'owner',
  notificationType: 'ReceivedRequest',
  payload: {
    requestId: 'xyz',
    assetPair: {
      base: {currency: 'GBP', type: 'Cash'},
      second: {currency: 'USD', type: 'Cash'},
    },
    requestor: 'O=MileBank',
    ourSide: 'BuySell',
    requestValidUntil: dayjs('Thu Sep 17 2020 00:16:00 GMT+0200').toISOString(),
    requestCreatedAt: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  },
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockSentEarlyMaturityRequestRejected: Notification<'SentEarlyMaturityRequestRejected'> = {
  owner: 'owner',
  notificationType: 'SentEarlyMaturityRequestRejected',
  payload: {
    assetPair: {
      base: {currency: 'GBP', type: 'Cash'},
      second: {currency: 'USD', type: 'Cash'},
    },
    ourSide: 'BuySell',
    otherParty: 'O=MileBank',
  } as SentEarlyMaturityRequestRejectedPayload,
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockReceivedTradeUnwindProposalCancelled: Notification<'ReceivedTradeUnwindProposalCancelled'> = {
  owner: 'owner',
  notificationType: 'ReceivedTradeUnwindProposalCancelled',
  payload: {
    assetPair: {
      base: {currency: 'GBP', type: 'Cash'},
      second: {currency: 'USD', type: 'Cash'},
    },
    ourSide: 'BuySell',
    otherParty: 'O=MileBank',
    amount: {
      quantity: 937500,
      asset: gbpAsset,
    },
  } as ReceivedTradeUnwindProposalCancelledPayload,
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockReceivedQuote: Notification<'ReceivedQuote'> = {
  owner: 'owner',
  notificationType: 'ReceivedQuote',
  payload: {
    assetPair: {
      base: {currency: 'GBP', type: 'Cash'},
      second: {currency: 'USD', type: 'Cash'},
    },
    quoteId: '123',
    requestId: 'request-id',
    ourSide: 'BuySell',
    quoter: 'O=MileBank',
    quoteValidUntil: '2020-09-17T10:00:00.000Z',
  } as ReceivedQuotePayload,
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockCapacityAdjustmentScheduled: Notification<'CapacityAdjustmentScheduled'> = {
  owner: 'owner',
  notificationType: 'CapacityAdjustmentScheduled',
  payload: {
    amount: {
      quantity: 5320000500,
      asset: usdAsset,
    },
    from: '2020-09-17T13:35:00.000Z',
    to: '2020-09-17T22:15:00.000Z',
    userId: 'user-id',
    isASAP: true,
  } as CapacityAdjustmentScheduledPayload,
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockOrderMatched: Notification<'OrderMatched'> = {
  owner: 'owner',
  notificationType: 'OrderMatched',
  payload: {
    amount: {
      quantity: 5320000500,
      asset: usdAsset,
    },
    assetPair: {
      base: {currency: 'GBP', type: 'Cash'},
      second: {currency: 'USD', type: 'Cash'},
    },
    unmatchedAmount: {
      quantity: 130000000,
      asset: usdAsset,
    },
    side: 'SellBuy',
  } as OrderMatchedPayload,
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockReceivedLateFeeRequest: Notification<'ReceivedLateFeeRequest'> = {
  owner: 'owner',
  notificationType: 'ReceivedLateFeeRequest',
  payload: {
    requestId: 'xyz',
    assetPair: {
      base: {currency: 'GBP', type: 'Cash'},
      second: {currency: 'USD', type: 'Cash'},
    },
    amount: {
      quantity: 5320000500,
      asset: usdAsset,
    },
    requestor: 'O=MileBank',
    ourSide: 'BuySell',
    lateFeeId: 'lateFeeId',
    status: 'Accepted',
    legId: 'legId',
    nearLegTime: '2020-09-17T22:15:00.000Z',
  } as ReceivedLateFeeRequestPayload,
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockMtMLimitBreached: Notification<'MtMLimitBreached'> = {
  owner: 'owner',
  notificationType: 'MtMLimitBreached',
  payload: {
    counterparty: 'O=MileBank',
    exposure: {
      quantity: 6320000500,
      asset: usdAsset,
    },
    limit: {
      quantity: 5320000500,
      asset: usdAsset,
    },
    timestamp: 1231231231231,
  } as MtMLimitBreachedPayload,
  severity: 'ResponseRequested',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockNotificationData: NotificationData = {
  type: 'SentEarlyMaturityRequestRejected',
  isUnread: true,
  isUnseen: true,
  createdAt: mockSentEarlyMaturityRequestRejected.timestamp,
  message: 'Mile: GBP/USD - B/S - Early Mat. Req. Rejected',
  payload: mockSentEarlyMaturityRequestRejected.payload,
  sortable: false,
  aggregateId: 'aggregateId',
};

export const mockNotificationDataRequest: NotificationData = {
  type: 'ReceivedRequest',
  isUnread: true,
  isUnseen: true,
  createdAt: mockSentEarlyMaturityRequestRejected.timestamp,
  message: 'Received Request notification',
  payload: {
    requestId: 'requestId',
    assetPair: gbpUsdPair,
    requestor: '',
    ourSide: 'BuySell',
    requestValidUntil: dayjs('Mon Jan 1 2024 22:00:00 GMT+0200').toISOString(),
    requestCreatedAt: dayjs('Mon Jan 1 2024 22:00:00 GMT+0200').toISOString(),
  },
  sortable: false,
  aggregateId: 'aggregateId',
};

export const mockNotificationDataQuote: NotificationData = {
  type: 'ReceivedQuote',
  isUnread: true,
  isUnseen: true,
  createdAt: mockSentEarlyMaturityRequestRejected.timestamp,
  message: 'Received Quote notification',
  payload: {
    quoteId: 'quoteId',
    requestId: 'requestId',
    assetPair: gbpUsdPair,
    quoter: '',
    ourSide: 'BuySell',
    quoteValidUntil: dayjs('Mon Jan 1 2024 22:00:00 GMT+0200').toISOString(),
  },
  sortable: false,
  aggregateId: 'aggregateId',
};

export const mockNotificationDataLateFee: NotificationData = {
  type: 'ReceivedLateFeeRequest',
  isUnread: true,
  isUnseen: true,
  createdAt: mockSentEarlyMaturityRequestRejected.timestamp,
  message: 'Received Late fee notification',
  payload: {
    assetPair: gbpUsdPair,
    requestor: '',
    amount: {
      quantity: 937500,
      asset: gbpAsset,
    },
    status: 'status',
    lateFeeId: 'lateFeeId',
    legId: 'legId',
  },
  sortable: false,
  aggregateId: 'aggregateId',
};

export const mockNotificationDataMtMLimitBreached: NotificationData = {
  type: 'MtMLimitBreached',
  isUnread: true,
  isUnseen: true,
  createdAt: mockSentEarlyMaturityRequestRejected.timestamp,
  message: 'MtM Limit Breached',
  payload: {
    counterparty: 'O=MileBank',
    exposure: {
      quantity: 900000,
      asset: gbpAsset,
    },
    limit: {
      quantity: 500000,
      asset: gbpAsset,
    },
    timestamp: dayjs('Mon Jan 1 2024 22:00:00 GMT+0200').valueOf(),
  },
  sortable: false,
  aggregateId: 'aggregateId',
};

export const mockTradeEconomics = {
  netProfitAndLoss: {
    quantity: 938754,
    asset: gbpAsset,
  },
  liquidityBufferSaving: {
    amount: {
      quantity: 937500,
      asset: gbpAsset,
    },
    note: 'Assumes 75% effectiveness of this trade to avoid an intraday peak, not considering other trades executed, and 25bp cost of carry, or 0 for non-aggressors',
  },
  interest: {
    amount: {
      quantity: 2704,
      asset: gbpAsset,
    },
    note: 'Assumes immediate matching',
  },
  settlementCost: {
    amount: {
      quantity: 200,
      asset: gbpAsset,
    },
    note: '0.2000000 per million for the near leg and for the far leg',
  },
  finteumFee: {
    amount: {
      quantity: 1250,
      asset: gbpAsset,
    },
    note: '2.5000000 per million, or 0 for non-aggressors',
  },
};

export const mockExecutionDetails: MTFExecutionDetails = {
  mtfName: 'TEST MTF',
  executedAt: '2037-10-12T13:00:00Z',
  nearLeg: {
    uti: 'nearleg.uti',
    utiNamespace: 'namespace',
  },
  farLeg: {
    uti: 'farLeg.uti',
    utiNamespace: 'namespace',
  },
  brokerTradeReference: '000000',
  reportTrackingNumber: '0000000000000000000',
  mic: 'TEST MIC',
  confirmedAt: '2037-10-13T05:40:00Z',
};

export const mockShortNames = new Map([
  ['O=InchBank, L=Frankfurt, C=DE', 'InchBank'],
  ['O=MileBank, L=London, C=GB', 'MileBank'],
]);

export const mockSentRequestCancelledEarly: Notification<'SentRequestCancelledEarly'> = {
  payload: {
    requestId: 'abc-123',
    assetPair: gbpUsdPair,
    quoter: 'O=InchBank, L=Frankfurt, C=DE',
    ourSide: 'BuySell',
    reason: 'NotEnoughCounterpartyLimit',
  },
  owner: 'owner',
  notificationType: 'SentRequestCancelledEarly',
  severity: 'Info',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};

export const mockSettlementRiskLimitBreached: Notification<'SettlementRiskLimitBreached'> = {
  payload: {
    quoteId: 'string',
    requestId: 'string',
    requestor: 'string',
    assetPair: gbpUsdPair,
    ourSide: 'BuySell',
    remainingLimit: {
      quantity: 2704,
      asset: gbpAsset,
    },
    requiredLimit: {
      quantity: 2704,
      asset: gbpAsset,
    },
  },
  owner: 'owner',
  notificationType: 'SettlementRiskLimitBreached',
  severity: 'Info',
  timestamp: dayjs('Thu Sep 17 2020 00:15:00 GMT+0200').toISOString(),
  aggregateId: 'aggregateId',
};
