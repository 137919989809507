import {uniqueId} from 'lodash';

import {useIntl} from 'react-intl';

import useCashFlowFlagsQuery from 'api/hooks/useCashFlowFlagsQuery';

import {displayExactAmountWithUnit} from 'utils/AmountUtils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';
import {displayAssetTitle, isRepoPair} from 'utils/AssetUtils';
import {getCashflowByAsset, convertOutgoingCashflowSign} from 'utils/TradeUtils';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {fieldTitles} from 'constants/messages/labels';

import {CashFlowLegType, FlagAs} from './types';

import {Cell, CurrencyCell, HeaderCell, HeaderRow, Row, Table, TableWrapper} from './styles';

import RequestedLateFeesRow from './RequestedLateFeesRow';
import AgreedLateFeesRow from './AgreedLateFeesRow';
import FlagAsCheckboxes from './FlagAsCheckboxes';

type CashFlowTabProps = {
  tradeId: string;
};

export const CashFlowTab = ({tradeId}: CashFlowTabProps) => {
  const {selectTradeById} = useRealTimeTradesQuery();
  const trade = selectTradeById(tradeId);
  const {data: cashflows} = useCashFlowFlagsQuery(tradeId);
  const {formatMessage} = useIntl();

  if (!trade || !cashflows) return null;

  const baseAsset = trade.baseAmount.asset;
  const secondAsset = trade.secondAmount.asset;

  const isRepo = isRepoPair({base: baseAsset, second: secondAsset});

  const generateFlagAsCheckboxes = (legType: CashFlowLegType, flagAsCheckboxType: FlagAs) => (
    <FlagAsCheckboxes
      trade={trade}
      legType={legType}
      flagAsCheckboxType={flagAsCheckboxType}
      cashflows={cashflows}
      legCashflow={getCashflowByAsset(convertOutgoingCashflowSign(trade[legType]), secondAsset)}
    />
  );

  const tableData = {
    headers: [
      isRepo ? formatMessage(fieldTitles.onLeg) : formatMessage(fieldTitles.nearLeg),
      displayAssetTitle(baseAsset),
      displayAssetTitle(secondAsset),
      isRepo ? formatMessage(fieldTitles.offLeg) : formatMessage(fieldTitles.farLeg),
      displayAssetTitle(baseAsset),
      displayAssetTitle(secondAsset),
    ],
    rows: [
      'Trade',
      'Flag as sent',
      'Flag as received',
      'Flag as non-receipt',
      'Requested late fees',
      'Agreed late fees',
    ] as const,
    Trade: (legType: CashFlowLegType) => (
      <>
        <Cell data-testid={`${legType}-trade-time`}>
          {displayDate(trade[legType].actualSettlementTime ?? trade[legType].scheduledTime, DateFormat.DateTime)}
        </Cell>
        <CurrencyCell>
          {displayExactAmountWithUnit(getCashflowByAsset(convertOutgoingCashflowSign(trade[legType]), baseAsset))}
        </CurrencyCell>
        <CurrencyCell>
          {displayExactAmountWithUnit(getCashflowByAsset(convertOutgoingCashflowSign(trade[legType]), secondAsset))}
        </CurrencyCell>
      </>
    ),
    'Flag as sent': (legType: CashFlowLegType) => generateFlagAsCheckboxes(legType, FlagAs.Sent),
    'Flag as received': (legType: CashFlowLegType) => generateFlagAsCheckboxes(legType, FlagAs.Received),
    'Flag as non-receipt': (legType: CashFlowLegType) => generateFlagAsCheckboxes(legType, FlagAs.Nonreceipt),
    'Requested late fees': (legType: CashFlowLegType) => <RequestedLateFeesRow trade={trade} legType={legType} />,
    'Agreed late fees': (legType: CashFlowLegType) => <AgreedLateFeesRow trade={trade} legType={legType} />,
  } as const;

  return (
    <TableWrapper>
      <Table>
        <thead>
          <HeaderRow>
            <HeaderCell />
            {tableData.headers.map(header => (
              <Cell key={uniqueId()}>{header}</Cell>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {tableData.rows.map((rowLabel, rowIndex) => (
            <Row key={rowLabel} index={rowIndex}>
              <HeaderCell>{rowLabel}</HeaderCell>
              {tableData[rowLabel]('nearLeg')}
              {tableData[rowLabel]('farLeg')}
            </Row>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};
