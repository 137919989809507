import {useState} from 'react';
import dayjs from 'dayjs';
import {AxiosError, AxiosResponse} from 'axios';

import {Amount, ErrorResponse, RFQQuoteForm, RFQRequest} from 'types/api';
import {createRfqQuote} from 'api/api';
import {showToast, showErrorToast} from 'utils/ToastUtils';
import {convertQuantityToAmount, To} from 'utils/AmountUtils';
import {
  getInterestRateForComputation,
  getRequestValidUntil,
  getRfqAmounts,
  getSpotRangeBigFigForComputation,
} from 'utils/RFQUtils';
import {ReferenceData} from 'types/layout';
import {isRepoPair} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {confirmationTabs} from 'constants/tabs';
import Button from 'ui/Button';
import Loader from 'ui/Loader';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import FormParagraph from 'ui/FormParagraph';
import {getQuoteFormMessage, getQuoteFormMessageType} from 'components/popups/CreateQuote/utils';
import {FormType, Economics} from 'components/Economics/Economics';
import {SButtonsContainer} from 'components/popups/CreateQuote/styles';

import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';

import ConfirmationDetails from '../ConfirmationDetails';

interface Props {
  quoteFormRequest: RFQRequest;
  onEdit: (quoteFormRequest: RFQRequest) => void;
  handleCountdownTimeUp: () => void;
  isQuotingDisabled: boolean;
  onSubmit: () => void;
  quoteFormState: CreateQuoteFormValues;
}

export const Confirmation = ({
  isQuotingDisabled,
  handleCountdownTimeUp,
  quoteFormRequest,
  quoteFormState,
  onSubmit,
  onEdit,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(confirmationTabs[0]);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({
    base: quoteFormRequest.baseAsset,
    second: quoteFormRequest.secondAsset,
  });

  if (quoteFormRequest.tradedAmount.quantity === undefined) {
    return <></>;
  }

  const quoteTradedAmount: Amount = convertQuantityToAmount(
    quoteFormState.quoteTradedAmount,
    To.Store,
    quoteFormRequest.tradedAmount.asset,
    true,
    inMillions
  );

  function onConfirm(): void {
    const isRepo = isRepoPair({base: quoteFormRequest.baseAsset, second: quoteFormRequest.secondAsset});

    setLoading(true);

    const sendInterestRate = isRepo || quoteFormState?.rate?.referenceData === ReferenceData.ImpliedYield;

    const validUntil = dayjs().add(quoteFormState?.validFor, 'minutes');
    const newQuote: RFQQuoteForm = {
      tradedAmount: quoteTradedAmount,
      interestRate: sendInterestRate ? quoteFormState?.rate?.value : undefined,
      forwardPoints: !sendInterestRate ? quoteFormState?.rate?.value : undefined,
      validUntil: validUntil.toISOString(),
    };

    createRfqQuote(quoteFormRequest.id, newQuote)
      .then((response: AxiosResponse) => {
        const message = getQuoteFormMessage(response.status);
        const type = getQuoteFormMessageType(response.status);

        setLoading(false);
        showToast(message, type);
        onSubmit();
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        showErrorToast(err);
        setLoading(false);
      });
  }

  const rfqAmounts = getRfqAmounts({
    pair: {base: quoteFormRequest.baseAsset, second: quoteFormRequest.secondAsset},
    spotRate: getInterestRateForComputation(quoteFormRequest.requestType),
    spotRangeBigFig: getSpotRangeBigFigForComputation(quoteFormRequest.requestType),
    tradedAmount: quoteFormState.quoteTradedAmount,
    tradedCurrency: quoteFormRequest.tradedAmount.asset.currency,
    side: quoteFormRequest.requesteeSide,
    inMillions,
  });

  return (
    <>
      <Tabs tabs={confirmationTabs} value={tab} onChange={setTab} />
      {tab.value === 'details' && (
        <ConfirmationDetails
          quoteFormState={quoteFormState}
          handleCountdownTimeUp={handleCountdownTimeUp}
          quoteFormRequest={quoteFormRequest}
        />
      )}
      {tab.value === 'economics' && (
        <Economics
          formType={FormType.Quote}
          tradedAmount={quoteFormState.quoteTradedAmount}
          tradedAsset={quoteFormRequest.baseAsset}
          counterAsset={quoteFormRequest.secondAsset}
          side={quoteFormRequest.requesteeSide}
          spotRange={getSpotRangeBigFigForComputation(quoteFormRequest.requestType)}
          spotRate={getInterestRateForComputation(quoteFormRequest.requestType)}
          isNonAggressorOnly
          nearLeg={quoteFormRequest.nearLegTime ? dayjs(quoteFormRequest.nearLegTime) : dayjs()}
          maturity={dayjs(quoteFormRequest.maturityTime)}
          rate={quoteFormState?.rate}
          validUntil={dayjs(getRequestValidUntil(quoteFormRequest))}
          rfqAmounts={rfqAmounts}
          handleCountdownTimeUp={handleCountdownTimeUp}
          assetPair={{base: quoteFormRequest.baseAsset, second: quoteFormRequest.secondAsset}}
        />
      )}
      {isQuotingDisabled && (
        <FormParagraph
          message='This RFQ Request has expired'
          tooltipMessage='This RFQ Request has expired'
          variant='error'
        />
      )}
      <SButtonsContainer>
        <Button
          width='45%'
          buttonStyle='grey'
          data-testid='edit-rfq-button'
          onClick={() => onEdit(quoteFormRequest)}
          disabled={isQuotingDisabled}
        >
          Edit
        </Button>
        <Button width='45%' onClick={onConfirm} data-testid='confirm-rfq-button' disabled={isQuotingDisabled}>
          Confirm
        </Button>
      </SButtonsContainer>
      {loading && <Loader />}
    </>
  );
};
