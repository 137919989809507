import styled from 'styled-components';

import {color} from 'utils/theme';

import {SContainer, SRow} from 'ui/Layout/styles';

export const SActiveOrders = styled(SContainer)`
  background-color: ${color('blue900')};
  height: inherit;
  position: relative;
`;

export const SHeader = styled(SRow)`
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 52px;
  border-bottom: ${color('gray470')} 1px solid;
`;

export const SHeaderButtons = styled(SRow)`
  justify-content: space-evenly;
  gap: 10px;
  padding: 10px 5px;
  text-transform: uppercase;
  border-bottom: ${color('gray470')} 1px solid;
`;

export const SActiveOrdersContainer = styled(SContainer)`
  max-height: calc(100% - 100px);
  overflow-y: auto;
  padding: 0 10px;
`;
