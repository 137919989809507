import {useMemo} from 'react';
import {displayParty, getImplYldMessage, getSelectedEntityShortName} from 'utils/utils';
import {useUser} from 'contexts/auth-context';

import {Asset, Party, Side} from 'types/api';

import useEconomicsQuery from 'api/hooks/useEconomicsQuery';

import {
  To,
  convertQuantityToAmount,
  displaySmallAmount,
  rateToForwardPoints,
  rateToImpliedYield,
} from 'utils/AmountUtils';

import {Rate} from 'types/rfq';
import dayjs, {Dayjs} from 'dayjs';

import {useFormContext} from 'react-hook-form';

import ImplYldMessage from 'ui/Message/ImplYldMessage';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import {RFQFormValues} from 'containers/RFQForm/hooks/useRFQForm';
import {useMarketDayDescription} from 'containers/OrderForm/hooks/useMarketDayDescription';
import useSelectedMarketDay from 'containers/RFQForm/hooks/useSelectedMarketDay';
import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';

export interface ExpectedInterestMessageProps {
  side: Side;
  rate?: Rate;
  startTime?: 'ASAP' | Dayjs;
  maturityTime?: Dayjs;
  spotRange?: number;
  tradedAmount: number;
  tradedAsset: Asset;
  baseAsset: Asset;
  spotRate?: number;
  debounceTime?: number;
}

export default function ExpectedInterestMessage({
  side,
  rate,
  startTime,
  maturityTime,
  spotRange,
  tradedAmount,
  tradedAsset,
  spotRate,
  baseAsset,
  debounceTime = 600,
}: ExpectedInterestMessageProps) {
  const user = useUser();
  const methods = useFormContext<RFQFormValues | CreateQuoteFormValues>();
  const {inMillions: inMillionsByOrderForm} = useMarketDayDescription();
  const {inMillions: inMillionsByRFQForm} = useSelectedMarketDay();
  const inMillions = inMillionsByOrderForm && inMillionsByRFQForm;

  const userEntities = useMemo(
    () =>
      user.legalEntities.map((legalEntity: Party) => ({
        label: displayParty(legalEntity),
        value: legalEntity.legalName,
      })),
    [user]
  );

  const validStartTime = dayjs(startTime).isValid() ? dayjs(startTime) : dayjs();
  const impliedYield = rateToImpliedYield(validStartTime, maturityTime, rate, spotRate);
  const forwardPoints = rateToForwardPoints(validStartTime, maturityTime, rate, spotRate);
  const {data} = useEconomicsQuery(
    {
      tradedAmount: convertQuantityToAmount(tradedAmount, To.Store, tradedAsset, true, inMillions),
      baseAsset,
      side,
      startTime: validStartTime,
      maturityTime,
      spotRange: (spotRange || 0) * 0.01,
      spotRate,
      impliedYield,
      forwardPoints,
    },
    debounceTime
  );

  const message = getImplYldMessage(getSelectedEntityShortName(user, userEntities[0].value), side, Number(rate?.value));

  if (methods.formState.errors.rate || !message.text) return null;

  return (
    <ImplYldMessage pays={message.pays}>
      <TooltipWrapper message={data?.interest?.note || null}>
        {message.text} {!!data?.interest?.amount && displaySmallAmount(data?.interest?.amount)}
      </TooltipWrapper>
    </ImplYldMessage>
  );
}
