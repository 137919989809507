import {PropsWithChildren, ReactNode} from 'react';
import {CSSProperties} from 'styled-components';

import {Container} from './styles';

export interface ImplYldMessageProps {
  pays?: boolean;
  text?: ReactNode;
  noSpacing?: boolean;
  styles?: CSSProperties;
}

const ImplYldMessage = ({text, pays, noSpacing, styles, children}: PropsWithChildren<ImplYldMessageProps>) => {
  if (!text && !children) return null;
  return (
    <Container id='impl-yld-warning-message' style={styles} pays={pays} {...{noSpacing}}>
      {' '}
      {text}
      {children}
    </Container>
  );
};

export default ImplYldMessage;
