import styled from 'styled-components';

import {css} from '@techstack/styled-system';

import {ifProp} from 'styled-tools';

import {color} from 'utils/theme';

import {ContainerProps} from './types';

export const SOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  background-color: rgba(${p => p.theme.colors.rgbBlack}, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SPopupContainer = styled.div<ContainerProps>`
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: ${color('blue900')};
  border: 11px solid ${color('gray500')};
  position: relative;
  width: ${ifProp('width', p => p.width, '70vw')};
  height: ${ifProp('height', p => p.height)};
  border-radius: 15px;

  ${ifProp(
    {variant: 'condensed'},
    css`
      max-width: 90vw;
      width: 480px;
      padding: 1.5rem 1rem;
    `
  )}
`;

export const SHeader = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'title close';
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: 600;
  padding: 12px 12px 14px 24px;
  font-size: 1.1rem;
  line-height: 2rem;
  color: ${p => p.theme.colors.white};
  min-width: 220px;
  text-transform: uppercase;
`;

export const SEmptyPopupFiller = styled.div`
  height: 470px;
  width: 100%;
`;

export const SCloseBtn = styled.span`
  grid-area: close;
  color: ${p => p.theme.colors.white};
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
  text-align: center;

  i {
    border: 1px solid ${p => p.theme.colors.white};
  }
`;

export const SContent = styled.div``;
