import {useState, useRef, useEffect} from 'react';

import {useSetupFlash} from 'utils/AnimationUtils';

import {Controller, ControllerProps} from 'react-hook-form';

import {isEqual} from 'lodash';

import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

import {SelectFieldProps, Item, LabelColorState} from './types';

import {Container, Label, Icon, List, ListItem} from './styles';

const SelectField = <T,>(props: SelectFieldProps<T>) => {
  const {
    style,
    className,
    placeholder = 'Select an option',
    disabled,
    invalid,
    warning,
    required,
    onChange,
    value,
    items,
    simpleLabel,
    flashKey,
    tooltip,
    tooltipPosition = 'left',
    highlight,
  } = props;
  const {isFlashing} = useSetupFlash(flashKey);
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Touched state
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(this: Document, e: MouseEvent): void {
      if (!containerRef.current?.contains(e.target as Node)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const onSelect = (itemValue: Item<T>) => () => {
    if (!touched) {
      setTouched(true);
    }
    if (onChange) {
      onChange(itemValue);
    }
    setOpen(false);
  };

  function onClickLabel() {
    if (!disabled) {
      setOpen(!open);
    }
  }

  const borderColor: LabelColorState | undefined = (() => {
    if (invalid) return 'invalid';
    if (warning) return 'warning';
    if (highlight || (required && !touched)) return 'highlightRequired';
    return;
  })();

  return (
    <TooltipWrapper message={tooltip} position={tooltipPosition} disabled={disabled || (!invalid && !warning)}>
      <Container
        data-testid={props['data-testid']}
        style={style}
        className={className}
        ref={containerRef}
        disabled={disabled}
      >
        {simpleLabel ?
          <span onClick={disabled ? () => void 0 : onClickLabel}>
            <span>{value ? value.label : placeholder}</span> <Icon static={simpleLabel} className='la la-angle-down' />
          </span>
        : <Label
            disabled={disabled}
            onClick={onClickLabel}
            open={open}
            data-testid='selectfield-label'
            flash={isFlashing}
            borderColor={borderColor}
          >
            {value ? value.label : placeholder}
            <Icon className='la la-angle-down' />
          </Label>
        }

        <List open={open} data-testid='selectfield-list'>
          {items.map((item, index) =>
            item ?
              <ListItem data-testid='selectfield-item' key={index} onClick={onSelect(item)}>
                {item.label}
              </ListItem>
            : <ListItem key={index}>―</ListItem>
          )}
        </List>
      </Container>
    </TooltipWrapper>
  );
};

export const FormSelectField = <T,>({
  name,
  control,
  items,
  highlight,
  ...props
}: Omit<SelectFieldProps<T>, 'value'> & Omit<ControllerProps, 'render'>) => (
  <Controller
    name={name}
    control={control}
    render={({field: {onChange, value}, fieldState: {error}}) => {
      const foundItem = items.find(i => isEqual(value, i?.value));
      return (
        <SelectField
          {...props}
          items={items}
          value={foundItem || undefined}
          onChange={item => onChange(item.value)}
          invalid={!!error}
          tooltip={error?.message}
          highlight={highlight && !value}
        />
      );
    }}
  />
);

export default SelectField;

export type {Item} from './types';
