import {faviconNotificationsConfig} from 'constants/notifications_defaults';

export class FavIconCounter {
  private config: typeof faviconNotificationsConfig;
  private notificationsCount: number;

  constructor(config: typeof faviconNotificationsConfig, notificationsCount: number) {
    this.config = config;
    this.notificationsCount = notificationsCount;
  }

  public changeFavicon(notifications: number): void {
    this.notificationsCount = notifications;
    if (!this.config.show || this.notificationsCount === 0) {
      this.drawOriginalFavicon();
      return;
    }
    const favicon = new Image();
    favicon.crossOrigin = 'anonymous';

    favicon.onload = () => {
      const oldLink = document.querySelector("link[rel~='icon']");
      const newLink = document.createElement('link');
      newLink.rel = 'icon';

      const canvas = document.createElement('canvas');
      canvas.width = favicon.width;
      canvas.height = favicon.height;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(favicon, 0, 0);

        const [POS_X, POS_Y] = this.getPosition(this.config.position, canvas);

        // === Draw the circle ===
        this.drawCircle(ctx, POS_X, POS_Y);

        newLink.href = canvas.toDataURL();
        if (oldLink) {
          document.head.removeChild(oldLink);
        }
        document.head.appendChild(newLink);
      }
    };
    favicon.src = this.config.url;
  }

  private drawOriginalFavicon(): void {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (link) {
      link.href = this.config.url;
    }
  }

  private getPosition(positionLabel: string, canvas: HTMLCanvasElement): [number, number] {
    let positionX = 0;
    let positionY = 0;
    switch (positionLabel) {
      case 'top-left':
        positionX = canvas.width / 2 - (canvas.width - this.config.radius * 2) / 2;
        positionY = canvas.height / 2 - (canvas.height - this.config.radius * 2) / 2;
        break;
      case 'top-right':
        positionX = canvas.width / 2 + (canvas.width - this.config.radius - 2) / 2;
        positionY = canvas.height / 2 - (canvas.height - this.config.radius - 2) / 2;
        break;
      case 'bottom-right':
        positionX = canvas.width / 2 + (canvas.width - this.config.radius * 2) / 2;
        positionY = canvas.height / 2 + (canvas.height - this.config.radius * 2) / 2;
        if (this.notificationsCount > 10) {
          positionX -= 3;
        }
        break;
      case 'bottom-left':
        positionX = canvas.width / 2 - (canvas.width - this.config.radius * 2) / 2;
        positionY = canvas.height / 2 + (canvas.height - this.config.radius * 2) / 2;
        break;
      case 'center':
        positionX = canvas.width / 2;
        positionY = canvas.height / 2;
        break;
      default:
        positionX = canvas.width / 2;
        positionY = canvas.height / 2;
        break;
    }

    return [positionX, positionY];
  }

  private drawCircle(ctx: CanvasRenderingContext2D, positionX: number, positionY: number): void {
    ctx.beginPath();
    ctx.arc(positionX, positionY, 5, 0, 2 * Math.PI, false);
    ctx.fillStyle = this.config.backgroundColor;
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
  }
}
