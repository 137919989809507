import {useState} from 'react';
import {useIntl} from 'react-intl';

import {Trade} from 'types/api';

import {showToast} from 'utils/ToastUtils';

import {withdrawTradeAnnulProposal} from 'api/api';

import {getRFC2253ValueByKey} from 'utils/utils';

import {displayAmountWithCode} from 'utils/AmountUtils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import TableRow from 'utils/TableUtils';

import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {fieldTitles} from 'constants/messages/labels';

import Button from 'ui/Button';
import Popup from 'ui/Popup';

import {Bottom, StyledTable} from 'containers/RFQPopup/styles';

import AnnulTradeReasonsText from 'components/AnnulTradePopup/AnnulTradeReasons';

import {FooterLabel, SButtonsContainer} from './styles';

type WithdrawTradeAnnulProposalPopupProps = {
  tradeId: Trade['id'];
};

export const WithdrawTradeAnnulProposalPopup = ({tradeId}: WithdrawTradeAnnulProposalPopupProps) => {
  const {formatMessage} = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const {goBack} = useModal();
  const {selectTradeById} = useRealTimeTradesQuery();

  const trade = selectTradeById(tradeId);
  if (!trade) return null;
  const inMillions = shouldDisplayInMillions({base: trade.baseAmount.asset, second: trade.secondAmount.asset});

  const handleClose = () => goBack();

  const onWithdraw = () => {
    setIsLoading(true);

    withdrawTradeAnnulProposal(tradeId)
      .then(() => {
        showToast('Trade annul proposal withdrawn', 'success');
        handleClose();
      })
      .catch((e: Error) => {
        showToast('Failed to withdraw trade annul proposal', 'error');
        console.error(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const counterpartyRow = (
    <TableRow key='counterparty' title={formatMessage(fieldTitles.counterparty)}>
      {getRFC2253ValueByKey(trade.counterparty, 'O')}
    </TableRow>
  );

  const paymentAmountRow = (
    <TableRow key='scheduledPayment' title='Scheduled payment'>
      {displayAmountWithCode(trade.baseAmount, false, inMillions)}
    </TableRow>
  );

  const receiptAmountRow = (
    <TableRow key='scheduledRecepit' title='Scheduled receipt'>
      {displayAmountWithCode(trade.secondAmount, false, inMillions)}
    </TableRow>
  );

  const openingTransferRow = (
    <TableRow key='scheduledOTransfer' title='Scheduled opening transfer'>
      {displayDate(trade.nearLeg.scheduledTime, 'DD MMM YY HH:mm:ss' as DateFormat)}
    </TableRow>
  );

  const closingTransferRow = (
    <TableRow key='scheduledCTransfer' title='Scheduled closing transfer'>
      {displayDate(trade.farLeg.scheduledTime, 'DD MMM YY HH:mm:ss' as DateFormat)}
    </TableRow>
  );

  const tradeAnnulReasons = (
    <TableRow key='annulReason' title='Annul Reason(s) Given'>
      <AnnulTradeReasonsText trade={trade} />
    </TableRow>
  );

  const rows = [
    counterpartyRow,
    paymentAmountRow,
    receiptAmountRow,
    openingTransferRow,
    closingTransferRow,
    tradeAnnulReasons,
  ];
  return (
    <Popup
      title={formatMessage(fieldTitles.withdrawTradeAnnulProposalWarningPopUpTitle)}
      onClose={handleClose}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable elements={rows} />
      <Bottom>
        <SButtonsContainer>
          <Button data-testid='withdraw-button' buttonStyle='primary' onClick={onWithdraw} disabled={isLoading}>
            Withdraw Trade Annul Proposal
          </Button>
        </SButtonsContainer>
        <FooterLabel>{formatMessage(fieldTitles.withdrawTradeAnnulProposalWarningPopUpFooter)}</FooterLabel>
      </Bottom>
    </Popup>
  );
};
