import dayjs, {Dayjs} from 'dayjs';
import {Asset, AssetPair, Side} from 'types/api';
import {
  To,
  amountBuying,
  amountSelling,
  displayMultipleAmounts,
  displaySmallAmount,
  convertQuantityToAmount,
  rateToImpliedYield,
  rateToForwardPoints,
} from 'utils/AmountUtils';
import {useIntl} from 'react-intl';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';
import {ConfirmationStepLabels as QuoteLabels} from 'types/quoteForm';
import {ConfirmationStepLabels as RFQLabels} from 'types/rfqForm';
import {ConfirmationStepLabels as OrderLabels, MarketType} from 'types/orderForm';
import {RfqAmount, displayRfqAmount} from 'utils/RFQUtils';
import useEconomicsQuery from 'api/hooks/useEconomicsQuery';

import {Rate} from 'types/rfq';

import {request} from 'constants/messages/rfq';

import colors from 'constants/colors';
import Table from 'ui/Table';
import {Row} from 'ui/Table/types';
import Loader from 'ui/Loader';
import Countdown from 'ui/Countdown';
import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';

import {CountdownContainer, TabContent, TabHeader, TableLabel, TableValue, TableNumber} from './styles';
import {LabelWithTooltip} from '../LabelWithTooltip/LabelWithTooltip';
import {LabelWithTooltipStyles} from '../LabelWithTooltip/types';

export enum FormType {
  Order = 'Order',
  RFQ = 'RFQ',
  Quote = 'Quote',
  QuoteAcceptance = 'QuoteAcceptance',
}

export interface EconomicsProps {
  formType: FormType;
  tradedAmount: number;
  tradedAsset: Asset;
  counterAsset: Asset;
  marketType?: MarketType;
  side: Side;
  spotRange: number;
  spotRate?: number;
  isNonAggressorOnly?: boolean;
  nearLeg?: Dayjs;
  maturity: Dayjs;
  assetPair: AssetPair;
  rate?: Rate;
  validUntil?: Dayjs;
  rfqAmounts?: Record<'tradedRfqAmount' | 'counterRfqAmount' | 'sellingRfqAmount' | 'buyingRfqAmount', RfqAmount>;
  handleCountdownTimeUp?: () => void;
}

// TODO: Split it into separate component for each form type
export const Economics = (props: EconomicsProps) => {
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(props.assetPair);
  const isOrder = props.formType === FormType.Order;
  const isRFQ = props.formType === FormType.RFQ;
  const isQuote = props.formType === FormType.Quote;
  const isRepo = isRepoPair({base: props.tradedAsset, second: props.counterAsset});
  const isQuoteAcceptance = props.formType === FormType.QuoteAcceptance;
  const isLimitOrder = isOrder && props.marketType === MarketType.Limit;
  const isInterestCalculated = isLimitOrder || isQuote || isQuoteAcceptance;

  const {formatMessage} = useIntl();

  const spotRate = isRepo ? 1.0 / 0.9 : props.spotRate;
  const tradedAmount = convertQuantityToAmount(props.tradedAmount, To.Store, props.tradedAsset, true, inMillions);

  const validStartTime = dayjs(props.nearLeg).isValid() ? dayjs(props.nearLeg) : dayjs();
  const impliedYield = rateToImpliedYield(validStartTime, props.maturity, props.rate, spotRate);
  const forwardPoints = rateToForwardPoints(validStartTime, props.maturity, props.rate, spotRate);
  const {data, isLoading} = useEconomicsQuery({
    spotRate,
    spotRange: props.spotRange * 0.01,
    startTime: validStartTime,
    maturityTime: props.maturity,
    tradedAmount,
    impliedYield,
    side: props.side,
    nonAggressorOnly: !!props.isNonAggressorOnly,
    baseAsset: props.assetPair.base,
    forwardPoints,
  });

  function getLabels() {
    switch (props.formType) {
      case FormType.Quote:
        return QuoteLabels;
      case FormType.RFQ:
        return RFQLabels;
      default:
        return OrderLabels;
    }
  }

  const labels = getLabels();
  const emptyRow: Row = [];

  function getType(): string {
    switch (props.formType) {
      case FormType.Quote:
        return displayAssetPairWithSide({base: props.tradedAsset, second: props.counterAsset}, props.side, true);
      case FormType.RFQ:
      case FormType.QuoteAcceptance:
        return displayAssetPairWithSide(props.assetPair, props.side);
      default:
        return props.marketType ? `${props.marketType} ${props.side}` : `${props.side}`;
    }
  }

  const typeRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.type)}</FieldTitle>
    </TableLabel>,
    <TableValue key={1} data-testid='economics-your-side'>
      <FieldValue>{getType()}</FieldValue>
    </TableValue>,
  ];

  const youBuyRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>You buy</FieldTitle>
    </TableLabel>,
    <TableValue key={0} data-testid='economics-you-buy'>
      <FieldValue>
        {props.rfqAmounts ?
          displayRfqAmount(props.rfqAmounts.buyingRfqAmount, inMillions)
        : spotRate &&
          displayMultipleAmounts(
            amountBuying(tradedAmount, props.counterAsset, spotRate, props.spotRange, props.side),
            inMillions
          )
        }
      </FieldValue>
    </TableValue>,
  ];

  const youSellRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>You sell</FieldTitle>
    </TableLabel>,
    <TableValue key={1} data-testid='economics-you-sell'>
      <FieldValue>
        {props.rfqAmounts ?
          displayRfqAmount(props.rfqAmounts.sellingRfqAmount, inMillions)
        : spotRate &&
          displayMultipleAmounts(
            amountSelling(tradedAmount, props.counterAsset, spotRate, props.spotRange, props.side),
            inMillions
          )
        }
      </FieldValue>
    </TableValue>,
  ];

  const nonAggressorValue =
    props.isNonAggressorOnly ? request.isNonAggressorOnlyNegativeYes : labels.isNonAggressorOnlyNegative;

  const nonAggressorRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.isNonAggressor)}</FieldTitle>
    </TableLabel>,
    <TableValue key={1} data-testid='economics-non-aggr'>
      <FieldValue>{formatMessage(nonAggressorValue)}</FieldValue>
    </TableValue>,
  ];

  const liqSavingTooltipText = data?.liquidityBufferSaving?.note;
  const liqSavingValue = displaySmallAmount(data?.liquidityBufferSaving?.amount);

  const customStyles: LabelWithTooltipStyles = {
    color: colors.blue50,
  };

  const liqSavingRow: Row = [
    <LabelWithTooltip key={0} tooltip={liqSavingTooltipText} tooltipPosition='left' styles={customStyles}>
      <FieldTitle>Liq. Buffer Saving</FieldTitle>
    </LabelWithTooltip>,
    <TableNumber key={1} data-testid='liq-buffer-saving'>
      <FieldValue>{liqSavingValue}</FieldValue>
    </TableNumber>,
  ];

  function getInterestTooltipText(): string {
    if (isInterestCalculated) {
      return data?.interest?.note || '';
    }

    return 'Undetermined for market order';
  }

  const interestAmountValue =
    isInterestCalculated || (isRFQ && props.rate) ? displaySmallAmount(data?.interest?.amount) : '--';

  const interestAmountRow: Row = [
    <LabelWithTooltip key={0} tooltip={getInterestTooltipText()} tooltipPosition='left' styles={customStyles}>
      <FieldTitle>{isRFQ ? 'Interest (Suggested)' : 'Interest'}</FieldTitle>
    </LabelWithTooltip>,
    <TableNumber key={1} data-testid='interest-amount'>
      <FieldValue>{interestAmountValue}</FieldValue>
    </TableNumber>,
  ];

  const settlementCostTooltipText = data?.settlementCost?.note;
  const settlementCostValue = displaySmallAmount(data?.settlementCost?.amount, '-');
  const settlementCostRow: Row = [
    <LabelWithTooltip key={0} tooltip={settlementCostTooltipText} tooltipPosition='left' styles={customStyles}>
      <FieldTitle>Settlement cost</FieldTitle>
    </LabelWithTooltip>,
    <TableNumber key={1} data-testid='settlement-cost'>
      <FieldValue>{settlementCostValue}</FieldValue>
    </TableNumber>,
  ];

  const finteumFeeTooltipText = data?.finteumFee?.note;
  const finteumFeeValue = displaySmallAmount(data?.finteumFee?.amount, '-');
  const finteumFeeRow: Row = [
    <LabelWithTooltip key={0} tooltip={finteumFeeTooltipText} tooltipPosition='left' styles={customStyles}>
      <FieldTitle>Finteum fee</FieldTitle>
    </LabelWithTooltip>,
    <TableNumber key={1} data-testid='finteum-fee'>
      <FieldValue>{finteumFeeValue}</FieldValue>
    </TableNumber>,
  ];

  const netPLValue = displaySmallAmount(data?.netProfitAndLoss);
  const netPLRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>Net P&amp;L</FieldTitle>
    </TableLabel>,
    <TableNumber key={1} data-testid='net-profit-loss'>
      <FieldValue>{netPLValue}</FieldValue>
    </TableNumber>,
  ];

  const tableRows: Row[] = [
    typeRow,
    ...(props.side === 'SellBuy' ? [youSellRow, youBuyRow] : [youBuyRow, youSellRow]),
    nonAggressorRow,
    emptyRow,
    liqSavingRow,
    interestAmountRow,
    settlementCostRow,
    finteumFeeRow,
    emptyRow,
    netPLRow,
  ];

  if (isQuote && props.validUntil) {
    const remainingRow: Row = [
      <TableLabel key={0}>Remaining</TableLabel>,
      <CountdownContainer key={2}>
        <Countdown
          onTimeUp={() => {
            if (props.handleCountdownTimeUp) props.handleCountdownTimeUp();
          }}
          timeTarget={props.validUntil}
        />
      </CountdownContainer>,
    ];

    tableRows.unshift(remainingRow);
  }

  return (
    <TabContent>
      {isLoading && <Loader />}
      <TabHeader>{formatMessage(labels.economicsTabHeader)}</TabHeader>
      <Table rows={tableRows} />
    </TabContent>
  );
};
