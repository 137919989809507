import {ReferenceData} from 'types/layout';

import {displayAmountWithUnit} from 'utils/AmountUtils';
import {AssetPair, Order} from 'types/api';

import {isRepoPair, displaySide, displayAssetPair} from 'utils/AssetUtils';

import {displayInterestRate, displayForwardPoints} from 'utils/utils';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {SCol, SRow} from 'ui/Layout/styles';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import Icon from 'containers/Blotter/components/StatusIcon';

import {SOrderItem} from './styles';

interface ActiveOrderProps {
  order: Order;
  referenceData: ReferenceData;
}
export const ActiveOrder = ({order, referenceData}: ActiveOrderProps) => {
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const isRepo = isRepoPair({base: order.data.baseAsset, second: order.data.secondAsset});
  const forwardPoints: number = order.initialForwardPoints;
  const assetPair: AssetPair = {
    base: order.data.baseAsset,
    second: order.data.secondAsset,
  };
  const inMillions = shouldDisplayInMillions(assetPair);

  if (order.isActive) {
    return (
      <SOrderItem key={order.id} data-testid='order-row'>
        <SCol width={[1 / 12]} minW={16}>
          <Icon status={order.status} />
        </SCol>

        <SCol fontSize='14px' lineHeight='24px' width={[3 / 12]} pl={2}>
          <p>{displayAssetPair(assetPair)}</p>
          <p>{displaySide(order.data.side, isRepo, true)}</p>
        </SCol>

        <SRow width={[2 / 3]} alignItems='center' fontSize='14px'>
          <SRow pl={1} width={[5 / 6]}>
            <TooltipWrapper message='Remaining amount / total order amount' position='left'>
              <p>
                {displayAmountWithUnit(order.unmatchedAmount, inMillions)} /{' '}
                {displayAmountWithUnit(order.data.baseAmount, inMillions)}
              </p>
            </TooltipWrapper>
          </SRow>

          <SRow width={[1 / 6]}>
            {isRepo || referenceData === ReferenceData.ImpliedYield ?
              displayInterestRate(order.data.interestRate)
            : displayForwardPoints(forwardPoints)}
          </SRow>
        </SRow>
      </SOrderItem>
    );
  }
  return null;
};
