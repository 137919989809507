import {Balance, BalanceWithEquivalent} from 'types/api';
import {map} from 'utils/utils';
import {convertExchangeAmount} from 'utils/AmountUtils';
import {reverseExchangeRate} from 'utils/AssetUtils';
import {unifiedEventStream} from 'api/unifiedEventStream';
import {ExchangeRate} from 'types/exchangeRate';

import {QueryClient} from '@tanstack/react-query';

import {BalanceState} from 'types/balance';

import {useRealTimeExchangeRatesQuery} from './useRealTimeExchangeRatesQuery';
import {getAllCapacities} from '../api';
import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['Capacity'];

export const getEquivalentBalance = (balance: Balance, exchangeRate?: ExchangeRate): BalanceWithEquivalent => ({
  asset: balance.asset,
  available: {
    amount: balance.available,
    equivalentAmount: map(exchangeRate, r => convertExchangeAmount(balance.available, r)),
  },
  earmark: {
    amount: balance.earmark,
    equivalentAmount: map(exchangeRate, r => convertExchangeAmount(balance.earmark, r)),
  },
  total: {
    amount: balance.total,
    equivalentAmount: map(exchangeRate, r => convertExchangeAmount(balance.total, r)),
  },
});

function subscribeCapacity(client: QueryClient, lastEventId?: string) {
  const handler = (balancesUpdate: Balance[]) => {
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) return;
      return {...data, balances: balancesUpdate};
    });
    return {type: ''}; // Necessary to match type, to remove after rework of unifiedEventStream
  };

  return unifiedEventStream.subscribe('capacity', handler, {lastEventId: lastEventId || '0,0'});
}

interface Props {
  withEquivalent: boolean;
}

interface Data {
  balances: BalanceState;
}

export const useRealTimeCapacityQuery = ({withEquivalent}: Props) => {
  const {data} = useSubscriptionQuery<Data>(
    {
      queryKey: QUERY_KEY,
      queryFn: async () => {
        const response = await getAllCapacities();
        return {data: {balances: response.data || []}, lastEventId: response.headers['x-last-event-id']};
      },
    },
    subscribeCapacity
  );
  const {exchangeRates} = useRealTimeExchangeRatesQuery();

  const balances = data?.balances || [];

  const usdExchangeRates = exchangeRates.flatMap(er =>
    er.pair.second === 'USD' ? [er]
    : er.pair.base === 'USD' ? [reverseExchangeRate(er)]
    : []
  );

  const equivalentBalances: BalanceWithEquivalent[] =
    balances?.map(b => {
      const rate = withEquivalent ? usdExchangeRates.find(er => er.pair.base === b.asset.currency) : undefined;
      return getEquivalentBalance(b, rate);
    }) || [];

  return {balances: equivalentBalances, balancesList: balances || []};
};
