import styled, {keyframes} from 'styled-components';
import chroma from 'chroma-js';

import {ButtonProps} from './types';

export const StyledButton = styled.button<ButtonProps>`
  position: relative;
  font-family: LatoBold, sans-serif;
  text-align: center;
  border-radius: 25px;
  font-size: 0.875rem;
  border: none;
  text-transform: uppercase;
  padding: 0.6rem 2rem;
  white-space: nowrap;
  ${props => {
    let backgroundColor: string;
    let color: string = props.theme.colors.blue650;
    let border: string = 'transparent';

    switch (props.buttonStyle) {
      case 'secondary':
        backgroundColor = props.theme.colors.red500;
        break;
      case 'warning':
        backgroundColor = props.theme.colors.yellow500;
        break;
      case 'grey':
        backgroundColor = props.theme.colors.gray500;
        color = props.theme.colors.white;
        break;
      case 'transparent':
        backgroundColor = 'transparent';
        color = props.theme.colors.white;
        border = `2px solid ${props.theme.colors.white}`;
        break;
      default:
        backgroundColor = props.theme.colors.green500;
        break;
    }

    return `
                background-color: ${backgroundColor};
                color: ${color};
                border: ${border};
            `;
  }};
  ${(props: ButtonProps) => props.disabled && 'opacity: 0.5;'}
  ${(props: ButtonProps) => props.width !== undefined && `width: ${props.width};`}

        ${props => {
    let backgroundColor: string;
    let boxShadowColor: string;

    switch (props.buttonStyle) {
      case 'secondary':
        backgroundColor = props.theme.colors.red400;
        boxShadowColor = `rgba(${props.theme.colors.rgbRed400}, 0.7)`;
        break;
      case 'warning':
        backgroundColor = props.theme.colors.yellow500;
        boxShadowColor = props.theme.colors.yellow300;
        break;
      case 'grey':
        backgroundColor = props.theme.colors.gray500;
        boxShadowColor = props.theme.colors.gray500;
        break;
      case 'transparent':
        backgroundColor = 'transparent';
        boxShadowColor = props.theme.colors.white;
        break;
      default:
        backgroundColor = props.theme.colors.green400;
        boxShadowColor = `rgba(${props.theme.colors.rgbGreen500}, 0.7)`;
        break;
    }

    return (
      !props.disabled &&
      `&:hover {
                cursor: pointer;
                background-color: ${backgroundColor};
                box-shadow: 0 0 9px 0 ${boxShadowColor};
            }`
    );
  }}
`;

export const LoadingIconContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 25px;
  background: ${props => chroma(props.theme.colors.white).alpha(0.8).css()};
`;

const IconAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const LoadingIcon = styled.i`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  animation: ${IconAnimation} linear 0.8s infinite;
`;
