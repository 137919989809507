import {useIntl} from 'react-intl';

import {Currency, RFQMarketConfig} from 'types/api';

import {getRFQAmountFieldLabel} from 'utils/RFQUtils';

import {useFormContext} from 'react-hook-form';

import {pairToItem} from 'utils/AssetUtils';

import {orderFormLimits} from 'constants/orderForm';
import {FormAmountWithCurrencyField} from 'ui/AmountField/AmountWithCurrencyField';
import FieldTitle from 'ui/FieldTitle';
import {Field, FormRow, Label} from 'containers/RFQForm/styles';

import {RFQFormValues} from 'containers/RFQForm/hooks/useRFQForm';
import useSelectedMarketDay from 'containers/RFQForm/hooks/useSelectedMarketDay';

interface Props {
  isRepo: boolean;
  rfqYouSellLabelFlashId: string;
  rfqYouBuyLabelFlashId: string;
  flashYouBuySellLabels: () => void;
  availableCapacityWarning?: string;
  disabled?: boolean;
  selectedMarket?: RFQMarketConfig;
}

export default function TradedAmountRow({
  isRepo,
  rfqYouBuyLabelFlashId,
  rfqYouSellLabelFlashId,
  flashYouBuySellLabels,
  availableCapacityWarning,
  disabled,
  selectedMarket,
}: Props) {
  const {formatMessage} = useIntl();
  const methods = useFormContext<RFQFormValues>();
  const {inMillions} = useSelectedMarketDay();
  const [tradedCurrency, pair, side, tradedAmountMin, tradedAmountMax] = methods.watch([
    'tradedAmountCurrency',
    'currentPair',
    'side',
    'tradedAmountMin',
    'tradedAmountMax',
  ]);
  const currentPair = pair && pairToItem(pair);
  const tradedAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'traded',
    assetPair: currentPair?.value,
    isRepo,
    tradedCurrency,
    side,
  });

  return (
    <FormRow key='tradedAmount'>
      <Label>
        <FieldTitle
          data-testid='rfq-traded-amount-label'
          flashKey={
            (tradedAmountFieldLabel.id === 'youSell' && rfqYouSellLabelFlashId) ||
            (tradedAmountFieldLabel.id === 'youBuy' && rfqYouBuyLabelFlashId) ||
            undefined
          }
        >
          {formatMessage(tradedAmountFieldLabel)}
        </FieldTitle>
      </Label>
      <Field>
        <FormAmountWithCurrencyField
          name='tradedAmount'
          data-testid='tradedAmount'
          unit={inMillions ? 'm' : undefined}
          min={tradedAmountMin}
          max={tradedAmountMax}
          decimals={orderFormLimits.amount.dec}
          invalid={!availableCapacityWarning}
          warning={!!availableCapacityWarning}
          tooltipPosition='left'
          tooltip={availableCapacityWarning}
          required
          disabled={disabled || !selectedMarket}
          currencies={
            selectedMarket && !isRepo ?
              [selectedMarket.assetPair.base.currency, selectedMarket.assetPair.second.currency]
            : [tradedCurrency as Currency]
          }
          onCurrencyChange={() => {
            flashYouBuySellLabels();
          }}
          highlight
        />
      </Field>
    </FormRow>
  );
}
