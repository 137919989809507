import {useIntl} from 'react-intl';
import {useFormContext} from 'react-hook-form';

import {
  To,
  ValidationResult,
  convertQuantity,
  currencySymbol,
  exactSecondAmount,
  tooltipsForAmountField,
  validateAmount,
} from 'utils/AmountUtils';
import {formatCounterAmountRange, getCounterAmount, getRFQAmountFieldLabel, getRfqAmounts} from 'utils/RFQUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {Amount, Asset, AssetPair, Currency, Side} from 'types/api';

import {orderFormLimits} from 'constants/orderForm';
import FieldTitle from 'ui/FieldTitle';
import {FakeAmountField} from 'ui/AmountField/FakeAmountField';
import {repoSecuritiesMessage} from 'containers/RFQForm/types';

import {FormCell, FormRow} from 'components/popups/CreateQuote/styles';
import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';
import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';

interface Props {
  isRepo: boolean;
  assetPair: AssetPair;
  requesteeSide: Side;
  initialFXRate: number;
  spotRangeBigFig: number;
  tradedAmount: Amount;
  counterCurrency: Currency;
  secondAsset: Asset;
}

function CounterQuoteTradedAmountField({
  isRepo,
  assetPair,
  requesteeSide,
  initialFXRate,
  spotRangeBigFig,
  tradedAmount,
  counterCurrency,
  secondAsset,
}: Props) {
  const {formatMessage} = useIntl();
  const methods = useFormContext<CreateQuoteFormValues>();
  const [quoteTradedAmount] = methods.watch(['quoteTradedAmount']);
  const maxTradedAmount = convertQuantity(tradedAmount.quantity, To.View);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);

  const getEarmarkAmount = () => {
    const {sellingRfqAmount: sellingRange} = getRfqAmounts({
      pair: assetPair,
      side: requesteeSide,
      spotRangeBigFig,
      spotRate: initialFXRate,
      tradedAmount: quoteTradedAmount ?? 0,
      tradedCurrency: tradedAmount.asset.currency,
      inMillions,
    });

    return {
      asset: sellingRange.asset,
      quantity: Math.max(...(sellingRange.quantity.filter(Boolean) as number[])),
    };
  };

  const counterAmountRange = getCounterAmount({
    tradedAmount: quoteTradedAmount,
    tradedCurrency: tradedAmount.asset.currency,
    assetPair,
    exchangeRate: initialFXRate,
    isRepo,
    spotRangeBigFig,
    inMillions,
  });

  const getCounterAmountViewQty = (tradedAmt: number, alwaysExact: boolean, isRepoTrade: boolean) =>
    convertQuantity(
      getCounterAmount({
        tradedAmount: tradedAmt,
        alwaysExact,
        isRepo: isRepoTrade,
        tradedCurrency: tradedAmount.asset.currency,
        assetPair,
        exchangeRate: initialFXRate,
        spotRangeBigFig,
        inMillions,
      })?.[0] ?? 0,
      To.View,
      true,
      inMillions ? 1_000_000_00 : 1_00
    );

  const minCounterAmountPossible: number =
    isRepo ?
      exactSecondAmount(orderFormLimits.amount.min, initialFXRate)
    : getCounterAmountViewQty(orderFormLimits.amount.min, true, false);
  const maxCounterAmountPossible: number =
    isRepo ?
      exactSecondAmount(maxTradedAmount, initialFXRate)
    : getCounterAmountViewQty(orderFormLimits.amount.max, true, false);

  const counterAmtExact = getCounterAmountViewQty(quoteTradedAmount ?? 0, true, isRepo);

  const counterAmountValidation: ValidationResult = validateAmount({
    value: counterAmtExact,
    min: minCounterAmountPossible,
    max: maxCounterAmountPossible,
    decimals: orderFormLimits.amount.dec,
  });

  const {show: showAvailableCapacityWarning} = useAvailableCapacityWarning('Quote', getEarmarkAmount());

  const counterAmountFieldWarning = requesteeSide === 'BuySell' && showAvailableCapacityWarning;

  const counterAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'counter',
    assetPair,
    isRepo,
    tradedCurrency: tradedAmount.asset.currency,
    side: requesteeSide,
  });

  const getCounterAmountTooltipMessage = () => {
    const repoMessage = isRepo ? repoSecuritiesMessage : '';

    if (counterAmountValidation.valid) return repoMessage;

    return (
      tooltipsForAmountField(
        counterAmountValidation,
        {
          dec: orderFormLimits.amount.dec,
          min: minCounterAmountPossible,
          max: maxCounterAmountPossible,
        },
        currencySymbol(secondAsset.currency),
        'm'
      ) +
      '. ' +
      repoMessage
    );
  };

  return (
    <FormRow>
      <FormCell>
        <FieldTitle>{formatMessage(counterAmountFieldLabel)}</FieldTitle>
      </FormCell>
      <FormCell>
        <FakeAmountField
          name='counterQuoteTradedAmount'
          value={formatCounterAmountRange(counterAmountRange, inMillions)}
          prefix={currencySymbol(counterCurrency)}
          unit={inMillions ? 'm' : undefined}
          invalid={!counterAmountFieldWarning && !counterAmountValidation.valid}
          warning={counterAmountFieldWarning}
          alwaysShowTooltip={isRepo}
          tooltipPosition='left'
          tooltip={getCounterAmountTooltipMessage()}
        />
      </FormCell>
    </FormRow>
  );
}

export default CounterQuoteTradedAmountField;
