import {useCallback, useMemo} from 'react';

import {RFQRequest, RFQRequestStatusText} from 'types/api';
import {useIntl} from 'react-intl';

import {useUser} from 'contexts/auth-context';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {useModal} from 'contexts/modal-context';

import {isRFQRequestQuotesCancelled} from 'utils/RFQUtils';

import GridTable, {GridTableVariant} from 'ui/GridTable';

import RFQOverviewPopup from 'components/popups/RFQOverviewPopup';

import {getBlotterRequestsTableColumns} from './columns';

const RequestsTable = () => {
  const {formatMessage} = useIntl();
  const user = useUser();
  const {rfqRequestsSent, rfqRequestsReceived} = useRealTimeRequestsQuery();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const {openModal} = useModal();

  const onRowClick = useCallback((requestDetails: RFQRequest): void => {
    openModal({modal: RFQOverviewPopup, props: {requestId: requestDetails.id, defaultView: 'details'}});
  }, []);

  const allRequests = useMemo(
    () =>
      [...rfqRequestsSent, ...rfqRequestsReceived].map(request => {
        if (isRFQRequestQuotesCancelled(request))
          return {
            ...request,
            status: 'QuoteCancelled' as RFQRequestStatusText,
          };

        return request;
      }),
    [rfqRequestsSent, rfqRequestsReceived]
  );

  const columns = useMemo(
    () => getBlotterRequestsTableColumns(formatMessage, user, shouldDisplayInMillions),
    [formatMessage, user, shouldDisplayInMillions]
  );

  const getRowId = useCallback((request: RFQRequest) => request.id, []);

  return (
    <div data-testid='requests-table' className='table-container'>
      <GridTable
        defaultSorted={[{id: 'createdAt', desc: true}]}
        columns={columns}
        data={allRequests}
        variant={GridTableVariant.Minimal}
        onRowClick={onRowClick}
        sortable
        getRowId={getRowId}
      />
    </div>
  );
};

export default RequestsTable;
