import {AxiosError} from 'axios';
import dayjs from 'dayjs';

import {rejectRfqRequest} from 'api/api';
import {ErrorResponse, RFQRequestRejectionReason} from 'types/api';
import {displayParty} from 'utils/utils';
import {showToast, showErrorToast} from 'utils/ToastUtils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayFullLeg} from 'utils/DayjsUtils';
import {getInitialFXRate, getReasonText, displaySpotRangeForRequest} from 'utils/RFQUtils';

import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';
import {useIntl} from 'react-intl';

import useRejectConfirmationReasons from 'utils/hooks/useRejectConfirmationReasons';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {REJECTION_REASONS} from 'constants/rfqForm';
import {fieldTitles} from 'constants/messages/labels';

import {Row} from 'ui/Table/types';
import Button from 'ui/Button';
import Countdown from 'ui/Countdown';
import Loader from 'ui/Loader';
import Popup from 'ui/Popup';
import FieldTitle from 'ui/FieldTitle';
import {CheckBox} from 'ui/CheckBox';

import {StyledTable, Title, CountdownContainer, Line, Cell, Bottom} from 'containers/RFQPopup/styles';
import {LabelWithTooltip} from 'components/LabelWithTooltip/LabelWithTooltip';

interface Props {
  requestId: string;
}

export const RejectRequestConfirmation = ({requestId}: Props) => {
  const {loading, rejectionReasons, setLoading, handleCheckbox} =
    useRejectConfirmationReasons<RFQRequestRejectionReason>();
  const {selectRfqById} = useRealTimeRequestsQuery();
  const rfqRequest = selectRfqById(requestId);
  const {closeModal} = useModal();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  // i18n
  const {formatMessage} = useIntl();

  if (!rfqRequest) {
    return (
      <Popup onClose={closeModal} width='360px'>
        Please select a request to show.
      </Popup>
    );
  }

  const {
    id,
    validUntil,
    requestor,
    baseAsset,
    tradedAmount,
    secondAsset,
    requesteeSide,
    maturityTime: farLegTime,
    requestType,
    nearLegTimeInput,
    nearLegTime,
  } = rfqRequest;
  const isRepo = isRepoPair({base: baseAsset, second: secondAsset});
  const inMillions = shouldDisplayInMillions({base: baseAsset, second: secondAsset});

  const onConfirm = () => {
    setLoading(true);
    rejectRfqRequest(id, rejectionReasons)
      .then(() => {
        showToast('Request for Quote successfully rejected.', 'success');
        setLoading(false);
        closeModal();
      })
      .catch((response: AxiosError<ErrorResponse>) => {
        showErrorToast(response);
        setLoading(false);
        closeModal();
      });
  };

  // Rows for table
  const validFor: Row = [
    <FieldTitle key={1}>{formatMessage(fieldTitles.validFor)}</FieldTitle>,
    <CountdownContainer key={2}>
      <Countdown timeTarget={dayjs(validUntil)} />
    </CountdownContainer>,
  ];

  const counterparty: Row = [
    <FieldTitle key={1}>{formatMessage(fieldTitles.counterparty)}</FieldTitle>,
    <Cell key={0} data-testid='counterparty'>
      {displayParty(requestor)}
    </Cell>,
  ];

  const yourSide: Row = [
    <FieldTitle key={1}>{formatMessage(fieldTitles.yourSide)}</FieldTitle>,
    <Cell key={0} data-testid='your-side'>
      {displayAssetPairWithSide({base: baseAsset, second: secondAsset}, requesteeSide, true)}
    </Cell>,
  ];

  const tradedAmountRow: Row = [
    <FieldTitle key={1}>{formatMessage(fieldTitles.tradedAmt)}</FieldTitle>,
    <Cell key={0} data-testid='traded-amount'>
      {displayAmountWithCode(tradedAmount, false, inMillions)}
    </Cell>,
  ];

  const nearLeg: Row = [
    <FieldTitle key={1}>{isRepo ? formatMessage(fieldTitles.onLeg) : formatMessage(fieldTitles.nearLeg)}</FieldTitle>,
    <Cell key={0} data-testid='near-leg'>
      {displayFullLeg(nearLegTime, nearLegTimeInput?.transactionDate)}
    </Cell>,
  ];

  const farLeg: Row = [
    <FieldTitle key={1}>{isRepo ? formatMessage(fieldTitles.offLeg) : formatMessage(fieldTitles.farLeg)}</FieldTitle>,
    <Cell key={0}>
      <span key={2} data-testid='far-leg'>
        {displayFullLeg(farLegTime, nearLegTimeInput?.transactionDate)}
      </span>
    </Cell>,
  ];

  const initialRate: Row = [
    <FieldTitle key={1}>{formatMessage(fieldTitles.initialRate)}</FieldTitle>,
    <Cell key={0} data-testid='initial-rate'>
      {getInitialFXRate(requestType) ?? '--'}
    </Cell>,
  ];

  const spotRangeRow: Row = [
    <FieldTitle key={1}>{formatMessage(fieldTitles.spotRange)}</FieldTitle>,
    <Cell key={0} data-testid='spot-range'>
      {displaySpotRangeForRequest(requestType)}
    </Cell>,
  ];

  const entity: Row = [
    <FieldTitle key={1}>{formatMessage(fieldTitles.entity)}</FieldTitle>,
    <Cell key={0} data-testid='entity'>
      {displayParty(requestor)}
    </Cell>,
  ];
  // Reasons
  const reasonsTitle: Row = [
    <LabelWithTooltip
      key='rejection-reasons-title'
      tooltipPosition='right'
      tooltip='Your counterparty will receive this information'
    >
      <FieldTitle>Send reasons (optional):</FieldTitle>
    </LabelWithTooltip>,
  ];
  // Map reasons
  const reasons = Object.keys(REJECTION_REASONS).map(reason => [
    <Line key={reason}>
      {getReasonText(requesteeSide, reason as RFQRequestRejectionReason, baseAsset, secondAsset)}
      <CheckBox
        data-testid='rejection-reason-checkbox'
        checked={rejectionReasons.includes(reason as RFQRequestRejectionReason)}
        value={reason}
        onChange={event => handleCheckbox(event.target.value as RFQRequestRejectionReason)}
      />
    </Line>,
  ]);
  const rows: Row[] = [
    validFor,
    counterparty,
    yourSide,
    tradedAmountRow,
    nearLeg,
    farLeg,
    initialRate,
    spotRangeRow,
    entity,
    reasonsTitle,
    ...reasons,
  ];

  return (
    <Popup title='Reject RFQ' onClose={closeModal} width='360px' height='auto'>
      {loading ?
        <Loader />
      : undefined}
      <Title>Are you sure you want to reject the following request for quote?</Title>
      <StyledTable rows={rows} />
      <Bottom>
        <Button buttonStyle='grey' onClick={onConfirm}>
          Confirm rejection
        </Button>
      </Bottom>
    </Popup>
  );
};
