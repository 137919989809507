import {RFQRequestRejectionReason, RFQQuoteRejectionReason} from 'types/api';

export const REJECTION_REASONS: {[key in RFQRequestRejectionReason]: string} = {
  DoesNotSuitToSell: 'DOESN’T SUIT TO SELL',
  DoesNotSuitToBuy: 'DOESN’T SUIT TO BUY',
  FarLegTooEarly: 'FAR LEG TOO EARLY',
  FarLegTooLate: 'FAR LEG TOO LATE',
  AmountTooSmall: 'AMOUNT TOO SMALL',
  TryBackLater: 'TRY BACK LATER',
};

export const QUOTE_REJECTION_REASONS: {[key in RFQQuoteRejectionReason]: string} = {
  PricingUnsuitable: 'PRICING UNSUITABLE',
  TradeAlreadyCompleted: 'TRADE ALREADY COMPLETED',
  QuotedSizeTooSmall: 'QUOTED SIZE TOO SMALL',
};
