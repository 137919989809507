import React from 'react';

import {ColumnStyled} from '../../styles';
import LatestRequestsReceived from '../LatestRequestsReceived';
import PreviousRequestsReceived from '../PreviousRequestsReceived';
import QuoteProvided from '../QuoteProvided';

const RightSide: React.FC = () => (
  <ColumnStyled>
    <LatestRequestsReceived />
    <QuoteProvided />
    <PreviousRequestsReceived />
  </ColumnStyled>
);

export default RightSide;
