import {useCallback} from 'react';

import {sortBy} from 'lodash/fp';
import {RFQQuoteWithRequest} from 'types/api';
import {unifiedEventStream} from 'api/unifiedEventStream';

import {QueryClient} from '@tanstack/react-query';
import unionWith from 'lodash/unionWith';

import {getQuotesWithRequests} from '../api';
import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['RealTimeQuotes'];

interface Data {
  quotes: RFQQuoteWithRequest[];
  lastEventId: string;
}

function subscribeForQuotes(client: QueryClient, lastEventId?: string) {
  const handler = (quote: RFQQuoteWithRequest) => {
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) {
        return;
      }
      return {...data, quotes: unionWith([quote], data.quotes, (a, b) => a.quote.id === b.quote.id)};
    });
    return {type: ''};
  };

  return unifiedEventStream.subscribe('quote', handler, {lastEventId: lastEventId || '0,0'});
}

export const useRealTimeQuotesQuery = () => {
  const {data} = useSubscriptionQuery(
    {
      queryKey: QUERY_KEY,
      queryFn: () =>
        getQuotesWithRequests().then(response => {
          const quotes = sortBy(req => req.quote.createdAt, response.data);
          const lastEventId = response.headers['x-last-event-id'];
          return {data: {quotes}, lastEventId};
        }),
    },
    subscribeForQuotes
  );

  const selectQuoteWithRequestByQuoteId = useCallback(
    (quoteId: string) => data?.quotes.find(quote => quote.quote.id === quoteId),
    [data?.quotes]
  );
  const selectQuoteWithRequestByRequestId = useCallback(
    (requestId: string) => data?.quotes.find(quote => quote.requestId === requestId),
    [data?.quotes]
  );

  return {
    quotes: data?.quotes || [],
    selectQuoteWithRequestByQuoteId,
    selectQuoteWithRequestByRequestId,
  };
};
