import FormParagraph from 'ui/FormParagraph';

export interface AvailableCapacityWarningProps {
  show: boolean;
  message?: string;
  tooltipMessage?: string;
}

const AvailableCapacityWarning = ({show = false, message, tooltipMessage}: AvailableCapacityWarningProps) => {
  if (!show || !message || !tooltipMessage) {
    return null;
  }

  return <FormParagraph message={message} tooltipMessage={tooltipMessage} variant='warning' />;
};

export default AvailableCapacityWarning;
