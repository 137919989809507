import {HTMLAttributes, PropsWithChildren} from 'react';

import {Container} from './styles';

interface FieldTitleProps extends HTMLAttributes<HTMLSpanElement> {
  uppercase?: boolean;
  color?: string;
}
const FieldValue = ({uppercase = true, children, ...rest}: PropsWithChildren<FieldTitleProps>) => (
  <Container uppercase={uppercase} {...rest}>
    {children}
  </Container>
);

export default FieldValue;
