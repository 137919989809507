import {useState, useEffect} from 'react';

import dayjs from 'dayjs';
import {useIntl} from 'react-intl';
import {MarketType} from 'types/orderForm';
import {displayParty} from 'utils/utils';
import {showToast} from 'utils/ToastUtils';
import {To, convertQuantityToAmount} from 'utils/AmountUtils';
import {ceilToInterval, truncateTimePrecision} from 'utils/DayjsUtils';
import {Side, Party, Amount} from 'types/api';
import {isFxPair, isRepoPair} from 'utils/AssetUtils';

import {useFormContext} from 'react-hook-form';

import {createFlasher} from 'utils/AnimationUtils';

import useStateAssets from 'utils/hooks/useStateAssets';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useUser} from 'contexts/auth-context';
import useReferenceDataStore from 'stores/useReferenceDataStore';

import {fieldTitles} from 'constants/messages/labels';
import {orderFormLimits} from 'constants/orderForm';

import {fxSwapTabs, repoTabs} from 'constants/tabs';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import {CheckboxSwitcher, RadioSwitcher} from 'ui/Switcher';
import {FormAmountField} from 'ui/AmountField';
import TimeField from 'ui/TimeField';
import SelectField from 'ui/SelectField';
import Button from 'ui/Button';

import FieldTitle from 'ui/FieldTitle';
import {ValidTimesRange} from 'ui/TimePicker/types';
import Loader from 'ui/Loader';
import FormLock from 'ui/FormLock';
import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';
import AvailableCapacityWarning from 'components/AvailableCapacityWarning/AvailableCapacityWarning';

import {Label, NonAggLabel, Field, MarketClosedContainer, resetButtonStyles, SFormButtonsContainer} from './styles';

import {FormProps} from './types';

import {FormContainer, FormRow, RequestButtonTooltip as OrderButtonTooltip} from '../RFQForm/styles';
import {FORM_DEFAULT_VALUES, OrderFormValues} from './hooks/useOrderForm';
import InterestLabelRow from './components/TooltipRows/InterestLabelRow';
import ValidUntilRow from './components/ValidUntilRow';
import {useMarketDayDescription} from './hooks/useMarketDayDescription';
import SecondAmountRow from './components/SecondAmountRow';
import BaseAmountRow from './components/BaseAmountRow';
import OrderFormRateRow from './components/OrderFormRateRow';

const startingList = [
  {
    value: '',
    label: '---',
  },
];

const {id: baseAmountFlashId, trigger: triggerBaseAmountFlash} = createFlasher('baseAmountFlash');
const {id: secondAmountFlashId, trigger: triggerSecondAmountFlash} = createFlasher('secondAmountFlashId');
const {id: interestFwdPointsFlashId} = createFlasher('interestFwdPointsFlashId');
const {id: baseFieldFlashId} = createFlasher('baseFieldFlashId');
const {id: secondFieldFlashId} = createFlasher('secondFieldFlashId');

export const OrderForm = ({disabled, onSubmit: formSubmit}: FormProps) => {
  const methods = useFormContext<OrderFormValues>();
  const {isMarketClosed, marketDay} = useMarketDayDescription();
  const {referenceData} = useReferenceDataStore();

  const {
    assetPair,
    baseAmount,
    secondAmount,
    marketType,
    isNonAggressorOnly,
    side,
    counterparty,
    maturityDateTime,
    marketCloseTime,
    exchangeRate,
  } = methods.watch();
  const isLoading = isMarketClosed === undefined;

  const {baseAsset, secondAsset} = useStateAssets();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);
  const isRepo = isRepoPair(assetPair);
  const isFXSwap: boolean = isFxPair(assetPair, true);

  const user = useUser();
  const [initialEntity, setInitialEntity] = useState<Party | undefined>(undefined);
  const [isImpliedYieldFieldTouched, setIsImpliedYieldFieldTouched] = useState(false);

  // Entities
  const [userEntities, setUserEntities] = useState(startingList);
  const entitySelected =
    userEntities.find(userEntity => userEntity.value === initialEntity?.legalName) ?? startingList[0];

  const {formatMessage} = useIntl();

  const currentTime = dayjs();
  const isMarket = marketType === MarketType.Market;

  const orderAmount = ((): Amount | undefined => {
    if (side === 'BuySell' && secondAmount) {
      if (!secondAsset) return;
      return convertQuantityToAmount(secondAmount, To.Store, secondAsset, true, inMillions);
    }
    if (side === 'SellBuy' && baseAmount) {
      if (!baseAsset) return;
      return convertQuantityToAmount(baseAmount, To.Store, baseAsset, true, inMillions);
    }
  })();

  const {
    show: showAvailableCapacityWarning,
    message,
    tooltipMessage,
  } = useAvailableCapacityWarning('Order', orderAmount);

  const tabs = isRepo ? repoTabs : fxSwapTabs;
  const currentTab: Tab = tabs.find((value: Tab) => value.value === side) || tabs[0];

  const validTimesRange: ValidTimesRange = {
    start: ceilToInterval(truncateTimePrecision(currentTime), 15),
    end: dayjs(marketCloseTime),
  };

  const onReset = () => {
    const marketCloseTime = dayjs(marketDay?.endsAt);

    methods.reset({
      ...FORM_DEFAULT_VALUES,
      side: methods.getValues().side,
      assetPair: methods.getValues().assetPair,
      requestType: methods.getValues().requestType,
      exchangeRate: methods.getValues().exchangeRate,
      rate: {value: undefined, referenceData},
      maturityDateTime: methods.getValues().maturityDateTime,
      validUntil: marketCloseTime,
      marketCloseTime,
    });
    setIsImpliedYieldFieldTouched(false);
  };

  useEffect(() => {
    const newEntity = user.legalEntities[0];
    setUserEntities(
      user.legalEntities.map((legalEntity: Party) => ({
        label: displayParty(legalEntity),
        value: legalEntity.legalName,
      }))
    );
    setInitialEntity(newEntity);
  }, [JSON.stringify(user.legalEntities)]);

  // Callbacks for fields
  const onChangeTab = (newTab: Tab): void => {
    triggerSecondAmountFlash();
    methods.setValue('side', newTab.value as Side);
  };

  const onChangeMarketType = (newValue: string): void => {
    methods.setValue('marketType', newValue as MarketType);
  };

  const onChangeNonAgg = (checked: boolean): void => {
    methods.setValue('isNonAggressorOnly', checked);
  };

  const requestTooltipMessage =
    currentTab.value === 'SellBuy' ?
      'Buy (Reverse Repo) = Buy Securities, Lend Cash'
    : 'Sell (Repo) = Sell Securities, Borrow Cash';

  const isAssetTooltip = baseAsset?.holder === 'BNYM' && baseAsset.currency === 'USD' && baseAsset.type === 'Cash';

  if (isMarketClosed) {
    return <MarketClosed />;
  }

  return (
    <FormContainer
      data-testid='form-container'
      onSubmit={methods.handleSubmit(
        values => {
          formSubmit(values);
        },
        () => {
          showToast('Please verify the form data and try again.', 'error');
        }
      )}
    >
      {isLoading && <Loader />}
      {!isLoading && !exchangeRate && (
        <FormLock>
          <p>Exchange rate for selected asset pair is temporarily unavailable</p>
        </FormLock>
      )}
      <div>
        <Tabs tabs={tabs} value={currentTab} onChange={onChangeTab} data-testid='orderFormTabs' />
        <FormRow>
          <Label>Type</Label>
          <Field>
            <RadioSwitcher
              leftLabel='LIMIT'
              leftValue='Limit'
              rightLabel='MARKET'
              rightValue='Market'
              value={marketType}
              onChange={onChangeMarketType}
              disabled={disabled}
            />
          </Field>
        </FormRow>
        <BaseAmountRow
          isRepo={isRepo}
          disabled={disabled}
          flashLabelId={baseAmountFlashId}
          flashFieldId={baseFieldFlashId}
          triggerFlash={triggerBaseAmountFlash}
        />
        <SecondAmountRow
          isRepo={isRepo}
          disabled={disabled}
          flashLabelId={secondAmountFlashId}
          flashFieldId={secondFieldFlashId}
          triggerFlash={triggerSecondAmountFlash}
        />
        <OrderFormRateRow
          disabled={disabled || isMarket}
          isRepo={isRepo}
          flashId={interestFwdPointsFlashId}
          highlightEmpty={!isImpliedYieldFieldTouched}
          setIsImpliedYieldFieldTouched={setIsImpliedYieldFieldTouched}
        />
        <InterestLabelRow initialEntity={initialEntity} />
        {isFXSwap && (
          <FormRow>
            <Label>
              <FieldTitle>{formatMessage(fieldTitles.spotRange)}</FieldTitle>
            </Label>
            <Field>
              <FormAmountField
                name='spotRangeBigFig'
                unit='bigfig'
                tooltipPosition='left'
                prefix='+/-'
                disabled={disabled}
                min={orderFormLimits.spotRangeBigFig.min}
                max={orderFormLimits.spotRangeBigFig.max}
              />
            </Field>
          </FormRow>
        )}
        <ValidUntilRow validTimesRange={validTimesRange} />
        <FormRow>
          <Label>
            <FieldTitle>{formatMessage(fieldTitles.entity)}</FieldTitle>
          </Label>
          <Field>
            <SelectField items={userEntities} value={entitySelected} disabled />
          </Field>
        </FormRow>
        <FormRow>
          <Label>{formatMessage(fieldTitles.counterparty)}</Label>
          <Field>
            <SelectField
              items={[
                {
                  label: counterparty.toUpperCase(),
                  value: counterparty,
                },
              ]}
              value={{
                label: counterparty.toUpperCase(),
                value: counterparty,
              }}
              disabled
            />
          </Field>
        </FormRow>
        <FormRow>
          <Label>{formatMessage(fieldTitles.maturity)}</Label>
          <Field>
            <TimeField
              prefix='T+0' // Currently it's always T+0
              value={dayjs(maturityDateTime)}
              disabled
              validTimesRange={validTimesRange}
            />
          </Field>
        </FormRow>
        <FormRow data-testid='non-aggressor-only-switcher'>
          <CheckboxSwitcher
            label={<NonAggLabel>{formatMessage(fieldTitles.nonAggressorOnly)}</NonAggLabel>}
            checked={isNonAggressorOnly}
            onChange={onChangeNonAgg}
            showValueLabel
            disabled={disabled}
          />
        </FormRow>
        <FormRow minHeight={40}>
          <AvailableCapacityWarning
            show={showAvailableCapacityWarning}
            message={message}
            tooltipMessage={tooltipMessage}
          />
        </FormRow>
        <SFormButtonsContainer>
          <OrderButtonTooltip disabled={disabled || !isAssetTooltip || isLoading} message={requestTooltipMessage}>
            <Button
              data-testid='order-button'
              buttonStyle={side === 'BuySell' ? 'primary' : 'secondary'}
              disabled={disabled || isLoading || !exchangeRate}
              width='100%'
              type='submit'
            >
              {currentTab.label}
            </Button>
          </OrderButtonTooltip>

          <Button data-testid='reset-form-button' buttonStyle='grey' style={resetButtonStyles} onClick={onReset}>
            Reset
          </Button>
        </SFormButtonsContainer>
      </div>
    </FormContainer>
  );
};

export const MarketClosed = () => <MarketClosedContainer>Market is currently closed.</MarketClosedContainer>;
