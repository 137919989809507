import {useCallback, useEffect, useState} from 'react';

import dayjs, {Dayjs} from 'dayjs';

import {Duration} from 'dayjs/plugin/duration';

export type CountdownProps = {
  timeStart?: Dayjs;
  timeTarget: Dayjs;
  showNegativeTime?: boolean;
  forceExpired?: boolean;
  onTimeUp?: () => void;
};

const Countdown = (props: CountdownProps) => {
  const {timeTarget, timeStart, showNegativeTime, forceExpired, onTimeUp} = props;

  const [timeLeft, setTimeLeft] = useState<Duration>();
  const [countdownMultiplier, setCountdownMultiplier] = useState<-1 | 1>(-1);

  const shouldShowNegativeTime = showNegativeTime ?? false;
  const getStartTime = useCallback(() => timeStart ?? dayjs(), [timeStart]);

  useEffect(() => {
    if (shouldShowNegativeTime) {
      const startTime = getStartTime();

      setCountdownMultiplier(startTime.diff(timeTarget) > 0 ? 1 : -1);
    }
  }, [shouldShowNegativeTime, timeTarget.toISOString(), getStartTime]);

  const updateTimeLeft = useCallback((): void => {
    const difference: number = getStartTime().diff(timeTarget);
    const duration = dayjs.duration(difference);

    setTimeLeft(duration);
  }, [getStartTime, timeTarget]);

  function formatDuration(duration: Duration): [string, string, string] {
    const hours: number = Math.trunc(duration.as('hours')) * countdownMultiplier;
    const minutes: number = duration.get('minutes') * countdownMultiplier;
    const seconds: number = duration.get('seconds') * countdownMultiplier;

    return (
      shouldShowNegativeTime ?
        [hours, minutes, seconds]
      : [Math.max(hours, 0), Math.max(minutes, 0), Math.max(seconds, 0)]).map(withLeadingZero) as [
      string,
      string,
      string,
    ];
  }

  useEffect(() => {
    updateTimeLeft();
    const interval = window.setInterval(updateTimeLeft, 1000);
    return () => clearInterval(interval);
  }, [timeStart?.toISOString(), timeTarget.toISOString(), updateTimeLeft]);

  useEffect(() => {
    if (timeLeft?.get('hours') === 0 && timeLeft?.get('minutes') === 0 && timeLeft?.get('seconds') === 0 && onTimeUp) {
      onTimeUp();
    }
  }, [timeLeft?.toISOString(), onTimeUp]);

  if (forceExpired) {
    return (
      <span>
        <span className='hours'>00</span>:<span className='minutes'>00</span>:<span className='seconds'>00</span>
      </span>
    );
  }

  return (
    <span data-testid='countdown-timeleft'>
      {timeLeft ?
        <>
          {getStartTime().isAfter(props.timeTarget) && shouldShowNegativeTime ? '-' : ''}
          <span className='hours'>{formatDuration(timeLeft)[0]}</span>:
          <span className='minutes'>{formatDuration(timeLeft)[1]}</span>:
          <span className='seconds'>{formatDuration(timeLeft)[2]}</span>
        </>
      : ''}
    </span>
  );
};

function withLeadingZero(value: number): string {
  return value < 10 ? `0${value}` : value.toString();
}

export default Countdown;
