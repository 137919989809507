export interface NumLimit {
  min: number;
  max: number;
  dec: number;
}
export interface TimeLimit {
  min: number;
  max: number;
}

interface FormLimits {
  amount: NumLimit;
  rate: NumLimit;
  validUntilHours: TimeLimit;
  spotRangeBigFig: NumLimit;
  secondAmount: NumLimit;
}

const marketOpenHour: number = 9;

const marketCloseHour: number = 16;

export const forwardPointsDecimals = 3;

export const orderFormLimits: FormLimits = {
  amount: {
    min: 1,
    max: 4999,
    dec: 2,
  },
  rate: {
    min: -999,
    max: 999,
    dec: 1,
  },
  validUntilHours: {
    min: marketOpenHour,
    max: marketCloseHour,
  },
  spotRangeBigFig: {
    min: 0.1,
    max: 9.9,
    dec: 1,
  },
  secondAmount: {
    min: 1,
    max: 4999,
    dec: 2,
  },
};
