import React, {useState, useEffect} from 'react';
import {Controller, ControllerProps} from 'react-hook-form';

import {roundNumber} from 'utils/AmountUtils';

import {containsLeadingZeroes, hasDecimalPointWithoutLeadingZero} from 'utils/utils';

import {AmountFieldProps, InputColorState} from './types';
import TooltipWrapper from '../Tooltip/TooltipWrapper';
import {Container, Prefix, Input, ArrowsContainer, ArrowBtn, Unit} from './styles';

const AmountField: React.FC<AmountFieldProps> = (props: AmountFieldProps) => {
  const {
    name,
    decimals = 2,
    tooltip = 'Please verify this field',
    alwaysShowTooltip = false,
    tooltipPosition,
    warning,
    invalid,
    optional,
    required,
    value,
    disabled,
    inputPadding,
    forceValidated,
    highlightEmpty,
  } = props;

  const initialInputValue = props.value !== undefined ? props.value.toString() : '';
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  // Touched state
  const [touched, setTouched] = useState<boolean>(false);
  // Which value to show
  const valueToShow: string =
    disabled && props.disabledLabel ? props.disabledLabel
    : isValidInput(inputValue) ? inputValue
    : '';

  function isValidInput(input: string): boolean {
    if (containsLeadingZeroes(input)) {
      return false;
    }
    // Let users add minus and plus and dot sign to type new numbers
    if (input === '-' || input === '+' || input === '.') {
      return true;
    }
    // Show valid numbers
    if (!isNaN(Number(input)) || hasDecimalPointWithoutLeadingZero(input)) {
      return true;
    }
    // Don't show anything else
    return false;
  }

  function maybeFlipTouched() {
    // Flip touched flag
    if (!touched) {
      setTouched(true);
    }
  }

  function setFormattedInputValue(input: string) {
    if (hasDecimalPointWithoutLeadingZero(input)) {
      const sign = ['+', '-'].includes(input[0]) ? input[0] : '';
      const newInput = `${sign}0${sign ? input.slice(1) : input}`;

      setInputValue(newInput);
    } else {
      setInputValue(input);
    }
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!isValidInput(e.currentTarget.value)) {
      return; // noop
    }

    setFormattedInputValue(e.currentTarget.value);
    maybeFlipTouched();

    if (props.onChange && !props.disabled && optional) {
      const newValue: number | undefined =
        e.currentTarget.value === '' ? undefined
        : hasDecimalPointWithoutLeadingZero(e.currentTarget.value) ?
          Number(`0${/\.$/.test(e.currentTarget.value) ? '' : e.currentTarget.value}`)
        : isNaN(Number(e.currentTarget.value)) ? undefined
        : Number(e.currentTarget.value);
      const propsOnChange = props.onChange as (newValue: number | undefined) => void;
      propsOnChange(newValue);
    }
    if (props.onChange && !props.disabled && !optional) {
      const newValue: number = isNaN(Number(e.currentTarget.value)) ? 0 : Number(e.currentTarget.value);
      const propsOnChange = props.onChange as (newValue: number) => void;
      propsOnChange(newValue);
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    const key: string = e.key;
    if (key === 'ArrowUp') {
      onArrowUp();
    }
    if (key === 'ArrowDown') {
      onArrowDown();
    }
  }

  function onArrowUp(): void {
    if (!props.disabled && props.onChange) {
      const step: number = props.step || 1;
      let newValue: number = roundNumber((props.value || 0) + step, decimals);
      if (props.max && props.max < newValue) {
        newValue = props.max;
      }
      setInputValue(newValue.toString());
      props.onChange(newValue);
      maybeFlipTouched();
    }
  }

  function onArrowDown(): void {
    if (!props.disabled && props.onChange) {
      const step: number = props.step || 1;
      let newValue: number = roundNumber((props.value || 0) - step, decimals);
      if (props.min && props.min > newValue) {
        newValue = props.min;
      }
      setInputValue(newValue.toString());
      props.onChange(newValue);
      maybeFlipTouched();
    }
  }

  useEffect(() => {
    if (value !== Number(inputValue) && !isNaN(Number(inputValue))) {
      if (typeof value === 'number') {
        setInputValue(value.toString());
      } else {
        setInputValue((value || '').toString());
      }
    }
    // Keeping this to pass the unit tests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Input is considered untouched if there is no value set and it has not been changed by the user.
  const isUntouched = !touched && props.value === undefined && !forceValidated;
  const inputColor: InputColorState = (() => {
    if (!isUntouched && invalid) return 'invalid';
    if (warning) return 'warning';
    if (!isUntouched && props.highlight) return 'highlight';
    if (highlightEmpty || (!props.disabled && isUntouched && required)) return 'highlightRequired';
    return 'regular';
  })();

  return (
    <TooltipWrapper
      message={tooltip}
      position={tooltipPosition}
      disabled={disabled || (!alwaysShowTooltip && !invalid && !warning)}
      data-testid={props['data-testid']}
    >
      <Container disabled={disabled} width={props.width}>
        {props.prefix && typeof props.prefix === 'string' ?
          <Prefix>{props.prefix}</Prefix>
        : props.prefix}
        <Input
          name={name}
          disabled={disabled}
          type='text'
          value={valueToShow}
          placeholder='--'
          onChange={onChange}
          min={props.min}
          max={props.max}
          data-testid='amount-input'
          onKeyDown={onKeyDown}
          flash={props.flash}
          inputColor={inputColor}
          inputPadding={inputPadding}
        />
        {props.unit && <Unit>{props.unit}</Unit>}
        <ArrowsContainer>
          <ArrowBtn onClick={onArrowUp} disabled={props.disabled}>
            <i className='la la-angle-up' data-testid='amount-field-arrow-up' />
          </ArrowBtn>
          <ArrowBtn onClick={onArrowDown} disabled={props.disabled}>
            <i className='la la-angle-down' data-testid='amount-field-arrow-down' />
          </ArrowBtn>
        </ArrowsContainer>
      </Container>
    </TooltipWrapper>
  );
};

export const FormAmountField = ({
  name,
  control,
  highlight,
  onChange,
  ...props
}: Omit<ControllerProps, 'render'> & Omit<AmountFieldProps, 'value'>) => {
  return (
    <Controller
      render={({field: {onChange: nativeOnChange, value}, fieldState: {error}}) => (
        <AmountField
          value={value}
          onChange={v => {
            nativeOnChange(v);
            if (onChange) onChange(v);
          }}
          {...props}
          tooltip={error?.message || props.tooltip}
          invalid={!!error}
          forceValidated
          highlightEmpty={highlight && !value}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default AmountField;
