import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'assets/dayjs/plugin/timezone'; // until dayjs is updated
import 'dayjs/plugin/timezone'; // for type declaration import
import durationPlugin from 'dayjs/plugin/duration';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(durationPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(advancedFormatPlugin);
dayjs.extend(isSameOrAfter);

// Window object/env injection doesn't work for imports of imports in Jest, so needs a hack for tests
export const appTimezone = window.REACT_APP_TIMEZONE ?? (process.env.NODE_ENV === 'test' ? 'UTC' : 'Europe/Brussels');

dayjs.tz.setDefault(appTimezone);

// There is a bug regarding timezone display:
// https://github.com/iamkun/dayjs/issues/1154
export const appTzShortName = new Intl.DateTimeFormat('en-GB', {
  timeZone: appTimezone,
  timeZoneName: 'short',
})
  .format(Date.now())
  .split(' ')[1];
