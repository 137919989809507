import {isRepoPair} from 'utils/AssetUtils';
import {useUser} from 'contexts/auth-context';
import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import FieldValue from 'ui/FieldValue';
import FieldTitle from 'ui/FieldTitle';

import {SCol, SContainer, SRow} from 'ui/Layout/styles';
import useRequestTableRows from 'components/popups/RFQOverviewPopup/components/RFQDetails/hooks/useRequestTableRows';

interface Props {
  requestId: string;
}

const RequestDetailsTable = ({requestId}: Props) => {
  const {selectRfqById} = useRealTimeRequestsQuery();
  const request = selectRfqById(requestId);
  const {legalEntities} = useUser();
  const {getRequestDetailsRows} = useRequestTableRows(legalEntities);

  if (!request) {
    return null;
  }

  // Define if is repo.
  const isRepo: boolean = isRepoPair({
    base: request.baseAsset,
    second: request.secondAsset,
  });

  const detailsTableRows = getRequestDetailsRows(request, isRepo);

  return (
    <section className='table'>
      <SRow className='details-popup__table'>
        <SCol width={1}>
          <SContainer>
            {Array.from(detailsTableRows.entries()).map(([rowKey, [left, right]]) => {
              return (
                <SRow key={rowKey}>
                  <SCol width={1 / 4} className='details-popup__label'>
                    <FieldTitle data-testid='field-value'>{left.title}</FieldTitle>
                  </SCol>
                  <SCol width={1 / 4} className='details-popup__value'>
                    <FieldValue data-testid='field-value' uppercase={left.uppercase}>
                      {left.value}
                    </FieldValue>
                  </SCol>
                  <SCol width={1 / 4} className='details-popup__label'>
                    <FieldTitle data-testid='field-value'>{right?.title}</FieldTitle>
                  </SCol>
                  <SCol width={1 / 4} className='details-popup__value'>
                    <FieldValue data-testid='field-value' uppercase={right?.uppercase}>
                      {right?.value}
                    </FieldValue>
                  </SCol>
                </SRow>
              );
            })}
          </SContainer>
        </SCol>
      </SRow>
    </section>
  );
};

export default RequestDetailsTable;
