import {useQuery, useQueryClient} from '@tanstack/react-query';
import {EquivCurrency} from 'types/layout';

const QUERY_KEY = ['EquivCurrency'];

export default function useEquivCurrencyStore() {
  const {data} = useQuery({queryKey: QUERY_KEY, initialData: EquivCurrency.OrigCcy});
  const client = useQueryClient();
  const setEquivCurrency = (equivCurr: EquivCurrency) => {
    return client.setQueryData(QUERY_KEY, equivCurr);
  };
  return [data, setEquivCurrency] as const;
}
