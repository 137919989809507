import {useCallback, useEffect, useMemo, useState} from 'react';

import {useIntl} from 'react-intl';
import {AxiosError} from 'axios';
import dayjs, {Dayjs} from 'dayjs';
import {Asset, CapacityUpdateRequest, ErrorResponse} from 'types/api';
import {showToast, showErrorToast} from 'utils/ToastUtils';
import {validateDateTime, applyLegOffset, ceilToInterval, calculateDayOffset} from 'utils/DayjsUtils';
import {
  convertQuantity,
  convertQuantityToAmount,
  currencySymbol,
  To,
  validateAmount,
  ValidationResult,
  tooltipsForAmountField,
} from 'utils/AmountUtils';
import {patchCapacity} from 'api/capacity';

import {displayAsset} from 'utils/AssetUtils';

import {useUser} from 'contexts/auth-context';

import {createFlasher, useSetupFlash} from 'utils/AnimationUtils';

import useAvailableAssetsQuery from 'api/hooks/useAvailableAssetsQuery';

import useCapacityManagerStore from 'stores/useCapacityManagerStore';

import {Scope} from 'constants/permission-maps';

import {orderFormLimits} from 'constants/orderForm';
import {fieldTitles} from 'constants/messages/labels';

import Table from 'ui/Table';
import {Row} from 'ui/Table/types';
import AmountField from 'ui/AmountField';
import Button from 'ui/Button';
import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';
import SelectField from 'ui/SelectField';
import {Item} from 'ui/SelectField/types';
import TimeField from 'ui/TimeField';
import {PrefixOption} from 'ui/TimeField/types';
import {Leg, legOptions} from 'containers/RFQForm/types';
import CapacityAdjustmentPopup from 'containers/CapacityForm/components/CapacityAdjustmentPopup';
import {AdjustedCapacity} from 'containers/CapacityForm/components/CapacityAdjustmentPopup/types';

import {SFormButtonsContainer, resetButtonStyles} from 'containers/OrderForm/styles';
import PermissionsGate from 'components/PermissionGate';
import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {Container, Top} from './styles';
import {getCapacityMessage, getCapacityMessageType} from './utils';

interface Props {
  className?: string;
  capacity?: Asset;
}

const {id: currencyFlashId} = createFlasher('capacityFlashId');
const {id: fromFlashId, trigger: fromFieldFlash} = createFlasher('fromFieldFlash');
const {id: toFlashId, trigger: toFieldFlash} = createFlasher('toFieldFlash');
const {id: amountFlashId, trigger: amountFieldFlash} = createFlasher('amountFieldFlash');

function triggerFlashes() {
  fromFieldFlash();
  toFieldFlash();
  amountFieldFlash();
}

// TODO: Refactor Capacity Form
export const CapacityForm = ({capacity: initialCapacity}: Props) => {
  const [capacity, setCapacity] = useState<Asset | undefined>(initialCapacity);
  const [amount, setAmount] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fromTime, setFromTime] = useState<Dayjs | undefined>(undefined);
  const [fromLeg, setFromLeg] = useState<Leg>('T+0');
  const [toLeg, setToLeg] = useState<Leg>('T+0');
  const [adjustedCapacity, setAdjustedCapacity] = useState<AdjustedCapacity | undefined>(undefined);
  const {user} = useUser();
  const disabled = !hasPermission({
    permissions: getRolePermissions(user.role),
    scopes: [Scope.AdjustCapacity],
  });
  const {data: assetsData, isLoading, error} = useAvailableAssetsQuery();

  const asapTime = ceilToInterval(dayjs(), 15);
  const startOfThisDay = dayjs().tz().startOf('day');
  const startOfNextDay = startOfThisDay.add(1, 'day');
  const fromTimeEndOfRange = dayjs().set('hour', 23).set('minutes', 45).set('seconds', 0);

  const validFromTimeRange = {
    start: fromLeg === 'T+1' ? startOfThisDay : asapTime,
    end: fromTimeEndOfRange,
  };
  const validToTimeRange = {
    start: toLeg === 'T+1' ? startOfThisDay.set('minutes', 15) : asapTime,
    end: startOfNextDay,
  };

  const [toTime, setToTime] = useState<Dayjs>(validToTimeRange.end);

  const fromTimeActualValue = applyLegOffset(fromTime, fromLeg);
  const toTimeActualValue = applyLegOffset(toTime, toLeg);
  const {capacityData, removeCapcityData} = useCapacityManagerStore();

  useEffect(() => {
    if (error) {
      showErrorToast(error);
    }
  }, [error?.message]);

  // TODO: Refactor Capacity Adjustment UNDO logic.
  useEffect(() => {
    if (capacityData) {
      const capacityAdjustmentToUndo = {
        asset: capacityData.asset,
        amount: capacityData.amount,
        from: capacityData.fromDate,
        to: capacityData.toDate,
      };
      // Checking that from is after current time (T+0 evening or T+1 time)
      if (dayjs(capacityAdjustmentToUndo.from).isAfter(dayjs())) {
        const newFromLeg = calculateDayOffset(capacityAdjustmentToUndo.from);
        // We dont want to apply leg twice. Watch [fromTimeActualValue]
        const newFromTime = applyLegOffset(dayjs(capacityAdjustmentToUndo.from), newFromLeg, false);
        setFromTime(newFromTime);
        setFromLeg(newFromLeg);
      } else {
        setFromTime(undefined);
        setFromLeg('T+0');
      }
      const formattedAdjustmentToUndo = dayjs(capacityAdjustmentToUndo.to).toISOString();
      const newToLeg =
        formattedAdjustmentToUndo === startOfNextDay.toISOString() ?
          'T+0'
        : calculateDayOffset(capacityAdjustmentToUndo.to);
      // We dont want to apply leg twice. Watch [toTimeActualValue]
      const newToTime = applyLegOffset(dayjs(capacityAdjustmentToUndo.to), newToLeg, false);
      setToTime(newToTime);
      setToLeg(newToLeg);

      setAmount(convertQuantity(capacityAdjustmentToUndo.amount * -1, To.View));
      setCapacity(capacityAdjustmentToUndo.asset);
      triggerFlashes();
    }
    removeCapcityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(capacityData)]);

  const availableAssets = useMemo(() => (assetsData || []).map(({asset}) => asset), [assetsData]);

  const handleFromLegChange = ({value}: PrefixOption) => {
    setFromLeg(value as Leg);
    if (toLeg === 'T+1') {
      return;
    } else if (value === 'T+1') {
      toFieldFlash();
      setToLeg(value);
    }
  };

  const handleToLegChange = ({value}: PrefixOption) => setToLeg(value as Leg);

  const isFromAndToLegValid = fromLeg === toLeg;

  const isFromDateValid =
    fromTime === undefined ?
      fromLeg !== 'T+1'
    : validateDateTime({
        dateTime: fromTimeActualValue,
        shouldBeInTheFuture: true,
        futureDateTime: toTimeActualValue,
      });

  const isToDateValid = validateDateTime({
    dateTime: toTimeActualValue,
    shouldBeInTheFuture: true,
    pastDateTime: fromTimeActualValue,
  });

  const amountLimits = {
    value: amount,
    min: -orderFormLimits.amount.max,
    max: orderFormLimits.amount.max,
    decimals: 2,
    canBeZero: false,
  };
  const amountValidation: ValidationResult = validateAmount(amountLimits);

  const isFormDataInvalid =
    !isFromDateValid ||
    !isToDateValid ||
    !amountValidation.valid ||
    !isFromAndToLegValid ||
    capacity === undefined ||
    amount === undefined;

  const openAdjustmentPopup = () => {
    if (capacity && amount) {
      setAdjustedCapacity({asset: capacity, amount, fromTime, toTime, fromLeg, toLeg});
    }
  };

  const closeAdjustmentPopup = () => setAdjustedCapacity(undefined);

  const onAdjust = () => {
    if (isFormDataInvalid) {
      showToast('Please verify the form data and try again.', 'error');
      return;
    }
    setLoading(true);

    const request: CapacityUpdateRequest = {
      from: fromTime ? fromTimeActualValue.toISOString() : undefined,
      to: toTimeActualValue.toISOString(),
      incrementalAmount: convertQuantityToAmount(amount, To.Store, capacity, undefined, true),
    };
    patchCapacity(request)
      .then(response => {
        const status = response.status;
        showToast(getCapacityMessage(status), getCapacityMessageType(status));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        showErrorToast(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAmountChange = useCallback((newValue: number) => {
    setAmount(newValue);
  }, []);

  const onCapacityChange = useCallback((newValue: Item<Asset>) => {
    setCapacity(newValue.value);
  }, []);

  const onReset = useCallback(() => {
    setCapacity(initialCapacity);
    setAmount(undefined);
    setFromTime(toLeg === 'T+0' ? undefined : validFromTimeRange.start);
    setToTime(validToTimeRange.end);
    setAdjustedCapacity(undefined);
  }, [initialCapacity, toLeg, validFromTimeRange.start, validToTimeRange.end]);

  const intl = useIntl();
  const formatMessage = intl.formatMessage.bind(intl);

  const preparedAssets = availableAssets.map((asset: Asset) => ({
    value: asset,
    label: displayAsset(asset),
  }));
  const currentAsset = capacity ? {value: capacity, label: displayAsset(capacity)} : undefined;
  const {isFlashing: isAmountFlashing} = useSetupFlash(amountFlashId);

  const capacityRow: Row = [
    <FieldTitle key={0}>{formatMessage(fieldTitles.capacity)}</FieldTitle>,
    <FieldValue key={1}>
      <SelectField
        data-testid='capacity-form-currency'
        disabled={disabled}
        items={preparedAssets}
        value={currentAsset}
        onChange={onCapacityChange}
        required
        flashKey={currencyFlashId}
      />
    </FieldValue>,
  ];

  const fromRow: Row = [
    <FieldTitle key={2}>{formatMessage(fieldTitles.from)}</FieldTitle>,
    <TimeField
      data-testid='capacity-form-from-time'
      key={3}
      disabled={disabled}
      flashKey={fromFlashId}
      validTimesRange={validFromTimeRange}
      onChange={setFromTime}
      value={fromTime ? dayjs(fromTime) : 'ASAP'}
      placeholder={fromTime === undefined ? 'ASAP' : undefined}
      prefix={fromLeg}
      prefixOptions={legOptions}
      onPrefixChange={handleFromLegChange}
      invalid={!isFromDateValid}
      tooltip={!fromTime && fromLeg === 'T+1' ? 'Please select a specific time during T+1' : ''}
      showASAPOption
      onASAPClicked={() => setFromTime(undefined)}
    />,
  ];

  const toRow: Row = [
    <FieldTitle key={4}>{formatMessage(fieldTitles.to)}</FieldTitle>,
    <TimeField
      data-testid='capacity-form-to-time'
      key={5}
      disabled={disabled}
      flashKey={toFlashId}
      validTimesRange={validToTimeRange}
      onChange={setToTime}
      value={dayjs(toTime)}
      prefix={toLeg}
      prefixOptions={legOptions}
      onPrefixChange={handleToLegChange}
      invalid={!isToDateValid || !isFromAndToLegValid}
      tooltip={isFromAndToLegValid ? '' : 'It is only possible to adjust capacity within the same business day'}
    />,
  ];

  const symbol = capacity === undefined ? undefined : currencySymbol(capacity.currency);
  const tooltip = tooltipsForAmountField(amountValidation, {...amountLimits, dec: amountLimits.decimals}, symbol, 'm');
  const incrementalAmountRow: Row = [
    <FieldTitle key={2}>{formatMessage(fieldTitles.incrementalAmount)}</FieldTitle>,
    <AmountField
      data-testid='capacity-form-amount'
      disabled={disabled}
      key={3}
      value={amount}
      prefix={symbol}
      min={amountLimits.min}
      max={amountLimits.max}
      decimals={amountLimits.decimals}
      flash={isAmountFlashing}
      unit='m'
      onChange={onAmountChange}
      invalid={!amountValidation.valid}
      tooltip={tooltip}
      required
    />,
  ];

  // Merging all rows
  const rows = [capacityRow, fromRow, toRow, incrementalAmountRow];
  const isAdjustingDisabled = loading || isLoading || disabled;

  return (
    <>
      {adjustedCapacity && (
        <CapacityAdjustmentPopup
          adjustedCapacity={adjustedCapacity}
          onAdjust={onAdjust}
          onClose={closeAdjustmentPopup}
        />
      )}
      <PermissionsGate scopes={[Scope.AdjustCapacity]}>
        <Container disabled={disabled}>
          <Top>
            <p>Adjust capacity</p>
          </Top>
          <Table rows={rows} />
          <SFormButtonsContainer>
            <Button
              buttonStyle='primary'
              data-testid='adjust-button'
              onClick={openAdjustmentPopup}
              disabled={isAdjustingDisabled}
              style={{flexGrow: 7}}
              loading={loading}
            >
              Adjust
            </Button>
            <Button data-testid='reset-form-button' buttonStyle='grey' style={resetButtonStyles} onClick={onReset}>
              Reset
            </Button>
          </SFormButtonsContainer>
        </Container>
      </PermissionsGate>
    </>
  );
};
