import {CellContext, createColumnHelper} from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import {CreditRiskData, SettlementRisk} from 'types/analytics';
import {Amount, Currency} from 'types/api';
import {ExchangeRate} from 'types/exchangeRate';
import {EquivCurrency} from 'types/layout';
import {convertExchangeAmount, displayAmountWithCode} from 'utils/AmountUtils';

const columnHelper = createColumnHelper<CreditRiskData>();

const formatSettlementRiskValue = (
  amount: Amount,
  currency: Currency,
  equivCurrency: EquivCurrency,
  exchangeRates: ExchangeRate[]
) => {
  // Checks if the equivCurrency is not the original of the amount and the currency is not USD
  // we should convert the amount to USD
  if (equivCurrency === EquivCurrency.UsdEquiv && currency !== 'USD') {
    const settlementRiskExchangeToUSDRate = exchangeRates.find(
      exchangeRate => exchangeRate.pair.base === currency && exchangeRate.pair.second === 'USD'
    );

    return displayAmountWithCode(
      settlementRiskExchangeToUSDRate ? convertExchangeAmount(amount, settlementRiskExchangeToUSDRate) : amount,
      false,
      true
    );
  }

  return displayAmountWithCode(amount, false, true);
};

export const getSettlementRiskVersusLimitColumns = () => ({
  header: 'Settlement Risk vs Limit (USDe)',
  columns: [
    columnHelper.accessor('settlementVersusLimit.bought', {
      cell: info => {
        const cellValue = info.getValue();
        return !cellValue || cellValue === '--' ? '--' : displayAmountWithCode(cellValue, false, true);
      },
      header: 'Bought',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
    columnHelper.accessor('settlementVersusLimit.sold', {
      cell: info => {
        const cellValue = info.getValue();
        return !cellValue || cellValue === '--' ? '--' : displayAmountWithCode(cellValue, false, true);
      },
      header: 'Sold',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
    columnHelper.accessor('settlementVersusLimit.exposure', {
      cell: info => displayAmountWithCode(info.getValue(), false, true),
      header: 'Exposure - Net Sent/Received',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
    columnHelper.accessor('settlementVersusLimit.limit', {
      cell: info => displayAmountWithCode(info.getValue(), false, true),
      header: 'Limit',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
    columnHelper.accessor('settlementVersusLimit.remaining', {
      cell: info => displayAmountWithCode(info.getValue(), false, true),
      header: 'Remaining Limit',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
    columnHelper.accessor('settlementVersusLimit.earmarked', {
      cell: info => displayAmountWithCode(info.getValue(), false, true),
      header: 'Remaining, Earmarked',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
  ],
});

export const getMtmVersusLimitColumns = () => ({
  header: 'MtM vs Limit (USDe)',
  columns: [
    columnHelper.accessor('mtm.exposure', {
      cell: info => displayAmountWithCode(info.getValue(), false, true),
      header: 'Exposure, 15min Reval',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
    columnHelper.accessor('mtm.limit', {
      cell: info => displayAmountWithCode(info.getValue(), false, true),
      header: 'Limit',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
    columnHelper.accessor('mtm.remaining', {
      cell: info => displayAmountWithCode(info.getValue(), false, true),
      header: 'Remaining',
      meta: {
        align: 'right',
        alignItems: 'end',
      },
    }),
  ],
});

const SETTLEMENT_RISK_KEYS = ['bought', 'received', 'sold', 'sent'] as const;

export const getSettlementRisksPerCurrencies = (
  currencies: Currency[],
  equivCurrency: EquivCurrency,
  exchangeRates: ExchangeRate[]
) => {
  const settlementRiskCellRenderer =
    (key: (typeof SETTLEMENT_RISK_KEYS)[number], currency: Currency) =>
    (info: CellContext<CreditRiskData, SettlementRisk[]>) => {
      const settlementRisk = info.getValue();
      const rightCurrency = settlementRisk.filter(
        item => !('holder' in item.asset) && currency === item.asset.currency
      )[0];
      const amount = rightCurrency?.[key];
      if (!rightCurrency || amount === '--') return '--';
      return formatSettlementRiskValue(amount, currency, equivCurrency, exchangeRates);
    };
  return currencies.map(currency => ({
    header: `${currency} Settlement Risk`,
    columns: SETTLEMENT_RISK_KEYS.map(header =>
      columnHelper.accessor('settlementRisks', {
        cell: settlementRiskCellRenderer(header, currency),
        header: capitalize(header),
        id: `${currency}_${header}`,
        meta: {
          align: 'right',
          alignItems: 'end',
        },
      })
    ),
  }));
};
