import {useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {AxiosError, AxiosResponse} from 'axios';

import {useUser} from 'contexts/auth-context';

import {getFeesCSVFile} from 'api/api';
import {ErrorResponse} from 'types/api';

import {showErrorToast} from 'utils/ToastUtils';
import {downloadFile} from 'utils/utils';
import {roundToInterval} from 'utils/DayjsUtils';

import {SAnalyticsContainer} from 'pages/AnalyticsPage/styles';

import {Scope} from 'constants/permission-maps';

import Button from 'ui/Button';
import DateTimeField from 'ui/DateTimeField';
import FieldTitle from 'ui/FieldTitle';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';
import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import {SFormField, SFormItem, SFormLabel, SFormLayout} from './styles';

export type TransactionFeesReportsProps = {
  date?: Dayjs | string;
};

const TransactionFeesReports = ({date}: TransactionFeesReportsProps) => {
  const userWithMeta = useUser();
  const permissions = getRolePermissions(userWithMeta.user?.role);
  const [reportDate, setReportDate] = useState<Dayjs>(roundToInterval(dayjs(date).subtract(1, 'month')));

  const isFormDisabled = !hasPermission({permissions, scopes: [Scope.ViewAnalytics]});

  const downloadReport = () => {
    const startDate = reportDate.startOf('month');
    const endDate = reportDate.endOf('month');

    getFeesCSVFile(startDate, endDate)
      .then((response: AxiosResponse) => downloadFile(response))
      .catch((error: AxiosError<ErrorResponse>) => {
        showErrorToast(error);
      });
  };

  return (
    <SAnalyticsContainer>
      <BoardHeader title='Finteum Transaction Fees' />
      <SFormLayout>
        <SFormItem>
          <SFormLabel>
            <FieldTitle>For transactions completed during the calendar month:</FieldTitle>
          </SFormLabel>
          <SFormField>
            <DateTimeField
              disabled={isFormDisabled}
              value={reportDate}
              onChange={date => date && setReportDate(date)}
              required
              showMonthYearPicker
              maxDate={dayjs().subtract(1, 'month')}
            />
          </SFormField>
        </SFormItem>
        <Button
          data-testid='download-report-btn'
          buttonStyle='primary'
          disabled={isFormDisabled}
          onClick={() => downloadReport()}
        >
          Download CSV Report
        </Button>
      </SFormLayout>
    </SAnalyticsContainer>
  );
};

export default TransactionFeesReports;
