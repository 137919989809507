import useNotificationsQuery from 'api/hooks/useNotificationsQuery';

import {ReactComponent as OpenIcon} from 'assets/icons/open.svg';

import Modal from 'ui/Modal';
import ConnectedNotificationTable from 'components/NotificationCenter';
import {
  NotificationPopupContainer,
  NotificationPopupHeader,
  NotificationPopupTableContainer,
  OpenButton,
  OpenButtonWrapper,
} from 'components/HeaderBar/styles';
import {NotificationCenterButton} from 'components/NotificationCenter/styles';

import {SCloseBtn} from './styles';

interface Props extends Pick<ReturnType<typeof useNotificationsQuery>, 'markAllAsRead'> {
  closeCallback: () => void;
  handleNotificationClick: () => void;
}

export default function NotificationCenterModal({closeCallback, handleNotificationClick, markAllAsRead}: Props) {
  return (
    <Modal show closeCallback={closeCallback} className='notification-center-modal'>
      <NotificationPopupContainer>
        <NotificationPopupHeader>
          <NotificationCenterButton
            data-testid='notification-mark-all-as-read-button'
            buttonStyle='transparent'
            onClick={markAllAsRead}
          >
            Mark all as read
          </NotificationCenterButton>
          <OpenButton
            data-testid='notification-open-window-button'
            className='btn-clear'
            onClick={handleNotificationClick}
          >
            <OpenButtonWrapper>
              <OpenIcon width={24} height={24} />
            </OpenButtonWrapper>
          </OpenButton>
          <SCloseBtn data-testid='close-popup-button' onClick={closeCallback}>
            <i className={'la la-close'} />
          </SCloseBtn>
        </NotificationPopupHeader>
        <NotificationPopupTableContainer>
          <ConnectedNotificationTable />
        </NotificationPopupTableContainer>
      </NotificationPopupContainer>
    </Modal>
  );
}
