import {PropsWithChildren, useEffect, useRef} from 'react';

import ReactDOM from 'react-dom';

import {ModalClose, ModalContainer, ModalContentCard} from './styles';

export interface ModalProps {
  show: boolean;
  withCloseBtn?: boolean;
  showOverlay?: boolean;
  className?: string;
  closeCallback(): void;
}

const Modal = ({
  show,
  withCloseBtn = false,
  children,
  showOverlay = false,
  className = '',
  closeCallback,
}: PropsWithChildren<ModalProps>) => {
  const containerRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Focus on open
  useEffect(() => {
    if (show && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [show]);

  // Close on Escape
  useEffect(() => {
    const handleKey = ({key}: KeyboardEvent) => {
      if (key === 'Escape') {
        closeCallback();
      }
    };
    document.addEventListener('keydown', handleKey);

    return () => document.removeEventListener('keydown', handleKey);
  }, [closeCallback]);

  // Close modal on backdrop click
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (e.target === containerRef.current) {
        closeCallback();
      }
    };
    // set listener
    window.addEventListener('click', handleClick);
    // cleanup on unmount
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [closeCallback]);

  if (!show || typeof window === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalContainer
      showOverlay={showOverlay}
      role='dialog'
      aria-modal='true'
      aria-label='Modal window.'
      id={'backdrop'}
      ref={containerRef}
      data-testid='modal-backdrop'
    >
      <ModalContentCard className={className} data-testid='modal-content-card'>
        {withCloseBtn && (
          <ModalClose ref={buttonRef} onClick={closeCallback} data-testid='modal-close-button'>
            x
          </ModalClose>
        )}
        {children}
      </ModalContentCard>
    </ModalContainer>,
    document.body
  );
};

export default Modal;
