import {color} from 'utils/theme';

import styled from 'styled-components';

import {SContainer, SRow} from 'ui/Layout/styles';

export const SBalancesView = styled(SContainer)`
  background-color: ${color('blue680')};
`;
export const SBalancesContent = styled(SRow)`
  padding: 1rem;
  flex-wrap: wrap;
`;
