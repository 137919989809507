export interface SignOutProps {
  signOutCallback(): void;
}
const SignOut = ({signOutCallback}: SignOutProps) => (
  <span className='logout-button' data-testid='logout-button' onClick={signOutCallback}>
    <i className='la la-sign-out icon' />
  </span>
);

export default SignOut;
