import {useEffect} from 'react';

import {useLocation} from 'react-router';
import {useWindowSize, useLocalStorage} from 'react-use';

import {useModal} from 'contexts/modal-context';

import {MIN_WINDOW_HEIGHT, MIN_WINDOW_WIDTH} from 'constants/layout';

import Button from 'ui/Button';
import Popup from 'ui/Popup';

import {StyledParagraph} from './styles';

export const STORAGE_KEY = 'hideResizePopup';

export const ResizePopup = ({handleIgnore}: {handleIgnore: () => void}) => (
  <Popup variant='condensed' onClose={handleIgnore}>
    <StyledParagraph>Please resize your window or decrease the zoom for a better user experience</StyledParagraph>
    <StyledParagraph>This message will disappear automatically when you resize to the preferred size</StyledParagraph>

    <Button buttonStyle='grey' onClick={handleIgnore}>
      Ignore
    </Button>
  </Popup>
);

const ResizeWindowPopup = () => {
  const location = useLocation();
  const {width, height} = useWindowSize();
  const [isPopupIgnored, setPopupIgnored] = useLocalStorage(STORAGE_KEY);
  const {openModal, closeModal} = useModal();

  const handleIgnore = () => {
    setPopupIgnored('true');
    closeModal();
  };

  // If popup ignored or closed - we hide popup. Otherwise we look at screen size
  useEffect(() => {
    const windowHasWrongSize = width < MIN_WINDOW_WIDTH || height < MIN_WINDOW_HEIGHT;

    if (location.pathname !== '/notifications' && !isPopupIgnored && windowHasWrongSize) {
      openModal({modal: ResizePopup, props: {handleIgnore}});
    } else {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, isPopupIgnored]);

  return null;
};

export default ResizeWindowPopup;
