import {useIntl} from 'react-intl';
import {useFormContext} from 'react-hook-form';

import dayjs, {Dayjs} from 'dayjs';

import {displayDate} from 'utils/DayjsUtils';

import {appTzShortName} from 'utils/setupDayjs';

import {fieldTitles} from 'constants/messages/labels';
import FieldTitle from 'ui/FieldTitle';
import {ValidTimesRange} from 'ui/TimePicker/types';
import {FormTimeField} from 'ui/TimeField';
import {OrderFormValues} from 'containers/OrderForm/hooks/useOrderForm';
import {FormRow, Label, Field} from 'containers/RFQForm/styles';

interface Props {
  validTimesRange: ValidTimesRange;
}

const validUntilTooltip = (marketCloseTime: Dayjs) =>
  `Order cannot be valid past the market close time at ${displayDate(marketCloseTime, 'HH:mm')} ${appTzShortName}`;

export default function ValidUntilRow({validTimesRange}: Props) {
  const {formatMessage} = useIntl();
  const methods = useFormContext<OrderFormValues>();
  const [marketCloseTime] = methods.watch(['marketCloseTime']);

  return (
    <FormRow>
      <Label>
        <FieldTitle>{formatMessage(fieldTitles.validUntil)}</FieldTitle>
      </Label>
      <Field>
        <FormTimeField
          name='validUntil'
          data-testid='orderform-validuntil'
          prefix='T+0' // Currently it's always T+0
          validTimesRange={validTimesRange}
          tooltipPosition='left'
          tooltip={validUntilTooltip(dayjs(marketCloseTime))}
        />
      </Field>
    </FormRow>
  );
}
