import {CSSProperties} from 'react';
import {EquivCurrency} from 'types/layout';

import {useRealTimeCapacityQuery} from 'api/hooks/useRealTimeCapacityQuery';

import {useRealTimeCapacityProjectionsQuery} from 'api/hooks/useRealTimeCapacityProjectionsQuery';

import useEquivCurrencyStore from 'stores/useEquivCurrencyStore';

import {Scope} from 'constants/permission-maps';

import PermissionsGate from 'components/PermissionGate';

import {Container, StyledSwitcher, BalancesTable, StyledTable, BalancesTitle, TableScroll} from './styles';
import {useCapacityRows} from './hooks/useCapacityRows';

type Props = {
  styles?: CSSProperties;
};

export const BalancesList = ({styles}: Props) => {
  const [equivCurrency, setEquivCurrency] = useEquivCurrencyStore();
  const {balances} = useRealTimeCapacityQuery({withEquivalent: true});
  const capacityProjections = useRealTimeCapacityProjectionsQuery();
  const {capacityRows, capacityLinks, earmarkRows} = useCapacityRows(balances, capacityProjections);

  function onChangeEquivCurrency(newValue: EquivCurrency): void {
    setEquivCurrency(newValue);
  }

  return (
    <PermissionsGate scopes={[Scope.ViewCapacity]}>
      <Container data-testid='balances-table' style={styles}>
        <StyledSwitcher
          leftLabel='USD EQUIV'
          leftValue={EquivCurrency.UsdEquiv}
          rightLabel='ORIG CCY'
          rightValue={EquivCurrency.OrigCcy}
          onChange={onChangeEquivCurrency}
          value={equivCurrency}
        />
        <BalancesTable data-testid='available-capacity-table'>
          <BalancesTitle>Available capacity</BalancesTitle>
          <TableScroll>
            <StyledTable rows={capacityRows} links={capacityLinks} data-testid='available-capacity-table' />
          </TableScroll>
        </BalancesTable>
        <BalancesTable data-testid='earmarked-capacity-table'>
          <BalancesTitle>Earmarked Capacity</BalancesTitle>
          <TableScroll>
            <StyledTable rows={earmarkRows} data-testid='earmarked-capacity-table' />
          </TableScroll>
        </BalancesTable>
      </Container>
    </PermissionsGate>
  );
};
