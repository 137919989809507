import {useCallback} from 'react';

import {AssetPair} from 'types/api';

import {isEqual} from 'lodash';

import useRFQMarketsQuery from './useRFQMarketsQuery';

export const useRealTimeMarketDaysQuery = () => {
  const {marketDays, isLoading} = useRFQMarketsQuery();
  const getMarketDaysForPair = useCallback(
    (assetPair: AssetPair) => (marketDays || []).filter(day => isEqual(assetPair, day.assetPair)),
    [marketDays]
  );

  return {
    marketDays,
    isLoading,
    getMarketDaysForPair,
  };
};
