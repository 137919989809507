import dayjs from 'dayjs';

import {appTimezone} from 'utils/setupDayjs';

export const fxApiUrl: string = process.env.REACT_APP_NODE_API_URL ? process.env.REACT_APP_NODE_API_URL : '/api/';

// https://raw.githubusercontent.com/axios/axios/b3be36585884ba1e237fdd0eacf55f678aefc396/lib/helpers/combineURLs.js
const combineURLs = (baseURL: string, relativeURL: string) =>
  relativeURL ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '') : baseURL;

export const constructApiUrl = (path: string) => combineURLs(fxApiUrl, path);

const globalLogo = window.REACT_APP_BANK_LOGO;
const environmentLogo = process.env.REACT_APP_BANK_LOGO;
const defaultLogo = '/img/mile-bank-logo.svg';

export const logoUrl: string = globalLogo || environmentLogo || defaultLogo;

const globalShowLogoOnLoginPage: boolean =
  typeof window.REACT_APP_SHOW_LOGO_ON_LOGIN_PAGE !== 'undefined' &&
  window.REACT_APP_SHOW_LOGO_ON_LOGIN_PAGE === 'true';
const environmentShowLogoOnLoginPage: boolean =
  typeof process.env.REACT_APP_SHOW_LOGO_ON_LOGIN_PAGE !== 'undefined' &&
  process.env.REACT_APP_SHOW_LOGO_ON_LOGIN_PAGE === 'true';

export const showLogoOnLoginPage: boolean = globalShowLogoOnLoginPage || environmentShowLogoOnLoginPage;

const appOffset = dayjs().tz(appTimezone).utcOffset() / 60;
export const timezoneOffset: number = appOffset;

export const globalDisableReferenceDataSwitch: boolean =
  typeof window.REACT_APP_DISABLE_REFERENCE_DATA_SWITCH !== 'undefined' &&
  window.REACT_APP_DISABLE_REFERENCE_DATA_SWITCH === 'true';
