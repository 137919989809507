import {useFormContext} from 'react-hook-form';

import {orderPaymentLabel} from 'utils/AmountUtils';

import {Party} from 'types/api';

import {OrderFormValues} from 'containers/OrderForm/hooks/useOrderForm';
import {Label, Field, FormRow} from 'containers/RFQForm/styles';

import {InterestLabel} from './styles';

interface Props {
  initialEntity?: Party;
}

export default function InterestLabelRow({initialEntity}: Props) {
  const methods = useFormContext<OrderFormValues>();
  const [isNonAggressorOnly, side, rate] = methods.watch(['isNonAggressorOnly', 'side', 'rate']);

  // Check what order payment label should we display
  const orderPaymentLabelText: string =
    initialEntity ? orderPaymentLabel(side, isNonAggressorOnly, rate?.value || 0, initialEntity) : '-- pays';

  const isPayingInterest: boolean = orderPaymentLabelText.includes('pays');

  return (
    <FormRow>
      <Label />
      <Field>
        <InterestLabel data-testid='order-payment-label' isPaying={isPayingInterest}>
          {orderPaymentLabelText}
        </InterestLabel>
      </Field>
    </FormRow>
  );
}
