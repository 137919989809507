import {Notification} from 'types/api';

import {DateFormat, displayDate} from './DayjsUtils';
import {showToast} from './ToastUtils';

// Handle toast message when receiving new notification
export const createNotificationToast = (notification: Notification): void => {
  const notificationType = notification.notificationType;

  if (notificationType === 'RequestSent') {
    showToast('RFQ Request Sent.', 'success');
  } else if (notificationType === 'ReceivedEarlyMaturityRequest') {
    showToast('Early Maturity Request has been received.', 'warning');
  } else if (notificationType === 'SentEarlyMaturityRequestAccepted') {
    const payload = notification.payload;

    const message: string = `
                Early maturity request for trade
                ${payload.ourSide}
                placed at ${displayDate(notification.timestamp, 'DD MMM YYYY HH:mm:ss' as DateFormat)}
                has been accepted.
            `;

    showToast(message, 'success');
  } else if (notificationType === 'ReceivedEarlyMaturityRequestCancelled') {
    const payload = notification.payload;

    const message: string = `
                Early maturity for trade
                ${payload.ourSide}
                placed at ${displayDate(notification.timestamp, 'DD MMM YYYY HH:mm:ss' as DateFormat)}
                has been withdrawn.
            `;

    showToast(message, 'warning');
  } else if (notificationType === 'ExecutionDetailsMismatched') {
    showToast("Execution details don't match", 'warning');
  } else if (notificationType === 'SentRequestCancelledEarly') {
    showToast('Request cancelled due to risk limits, see notification', 'error');
  } else if (notificationType === 'SettlementRiskLimitBreached') {
    const p = notification.payload;
    const isError = p.remainingLimit.quantity === 0;
    showToast(
      isError ? 'Quote cancelled, not enough settlement risk' : 'Quote exceeds available risk limits',
      isError ? 'error' : 'warning'
    );
  }
};
