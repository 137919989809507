import styled from 'styled-components';

import {ParagraphProps} from './types';

export const Paragraph = styled.p<ParagraphProps>`
  line-height: 1.5;
  font-size: small;
  text-align: center;
  ${({theme, variant}) => {
    switch (variant) {
      case 'warning':
        return `color: ${theme.colors.yellow300}`;
      case 'error':
        return `color: ${theme.colors.red500}`;
      default:
        return `color: initial`;
    }
  }}
`;
