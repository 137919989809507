import {NavLink} from 'react-router-dom';

import {shouldHideRfqTab} from 'utils/utils';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {Scope} from 'constants/permission-maps';

import {ANALYTICS} from 'constants/paths';

import NotificationBubble from 'ui/Badge/components/NotificationBubble';

import {SCol} from 'ui/Layout/styles';

import PermissionsGate from 'components/PermissionGate';

import {NavItem, Options} from './styles';

const Nav = () => {
  const hideRfqTab = shouldHideRfqTab();
  const {requestsRequiringAction} = useRealTimeRequestsQuery();

  return (
    <SCol width={[1, 2 / 3, 5 / 12]}>
      <Options>
        <PermissionsGate scopes={[Scope.ViewClob]}>
          <NavLink data-testid='clob-header-btn' to='/clob'>
            {({isActive}) => <NavItem isActive={isActive}>CLOB</NavItem>}
          </NavLink>
        </PermissionsGate>
        {!hideRfqTab && (
          <PermissionsGate scopes={[Scope.ViewRfq]}>
            <NavLink data-testid='rfq-header-btn' to='/rfq'>
              {({isActive}) => (
                <NavItem isActive={isActive}>
                  RFQ
                  {requestsRequiringAction > 0 && <NotificationBubble num={requestsRequiringAction} />}
                </NavItem>
              )}
            </NavLink>
          </PermissionsGate>
        )}
        <PermissionsGate scopes={[Scope.ViewCapacity]}>
          <NavLink data-testid='capacity-header-btn' to='/capacity'>
            {({isActive}) => <NavItem isActive={isActive}>Capacity</NavItem>}
          </NavLink>
        </PermissionsGate>
        <PermissionsGate scopes={[Scope.ViewAnalytics]}>
          <NavLink data-testid='analytics-header-btn' to={ANALYTICS.absolute}>
            {({isActive}) => <NavItem isActive={isActive}>Analytics</NavItem>}
          </NavLink>
        </PermissionsGate>
      </Options>
    </SCol>
  );
};

export default Nav;
