import {useQuery, useQueryClient} from '@tanstack/react-query';
import {RateToShow} from 'types/layout';

const QUERY_KEY = ['BestRate'];

export default function useBestRateStore() {
  const {data} = useQuery({
    queryKey: QUERY_KEY,
    initialData: {
      rateToShow: RateToShow.BestRate,
      rateAmount: 20,
    },
  });
  const client = useQueryClient();
  const setRateToShow = (rateToShow: RateToShow) => {
    return client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, rateToShow}));
  };
  const setRateAmount = (rateAmount: number) => {
    return client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, rateAmount}));
  };
  return {
    rateToShow: data.rateToShow,
    rateAmount: data.rateAmount,
    setRateToShow,
    setRateAmount,
  };
}
