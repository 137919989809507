import {LegType, Trade} from 'types/api';
import {displayAmountWithCode} from 'utils/AmountUtils';

import {LowerCaseSpan} from './styles';

export const LATE_FEE_RATE = 0.1;
export const displayLateFeeBreakdown = (settlementDeadline: number): string =>
  `>${settlementDeadline} min (not o/n) = ${LATE_FEE_RATE} bp`;

export type YouReceivedOrYouPaidLateValueType = {
  trade: Trade;
  legType: LegType;
  paidOrReceived: 'paid' | 'received';
  isRepo?: boolean;
  inMillions: boolean;
};

export const getLateSettlementAmount = ({
  trade,
  legType,
  paidOrReceived,
}: Omit<YouReceivedOrYouPaidLateValueType, 'inMillions'>) => {
  const legAmountsFor = {
    [LegType.NearLeg]: {
      paid: trade.nearLeg.cashFlowOut,
      received: trade.nearLeg.cashFlowIn,
    },
    [LegType.FarLeg]: {
      paid: trade.farLeg.cashFlowOut,
      received: trade.farLeg.cashFlowIn,
    },
  };
  return legAmountsFor[legType][paidOrReceived];
};

export const YouReceivedOrYouPaidLateValue = ({
  trade,
  legType,
  paidOrReceived,
  inMillions,
}: YouReceivedOrYouPaidLateValueType) => {
  const amount = getLateSettlementAmount({trade, legType, paidOrReceived});
  return <LowerCaseSpan>{displayAmountWithCode(amount, true, inMillions)}</LowerCaseSpan>;
};
