import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 16px;
  h1 {
    font-size: 32px;
  }
  code {
    background-color: #585f6b;
    padding: 16px;
    border-radius: 4px;
    color: white;
    max-width: 1024px;
  }
`;
