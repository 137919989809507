import {useEffect, useState} from 'react';

import dayjs, {Dayjs} from 'dayjs';
import {useIntl} from 'react-intl';
import {combineDateAndTimeFromTwoDayjsObjects, displayDate, displayDuration, validateDateTime} from 'utils/DayjsUtils';
import {getTradeSettlementTimeWithDeadLine} from 'utils/TradeUtils';
import {showToast} from 'utils/ToastUtils';

import {AxiosResponse} from 'axios';

import {displaySmallAmount} from 'utils/AmountUtils';

import {
  requestTradeLateSettlementFeeAmounts,
  requestTradeLateSettlementFee,
  withdrawTradeLateSettlementFee,
} from 'api/api';

import {LegType, RequestTradeLateSettlementFeeAmountsBody, LateFeeAmount} from 'types/api';

import TableRow from 'utils/TableUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {DetailsTabType} from 'types/layout';

import {fieldTitles} from 'constants/messages/labels';

import Popup from 'ui/Popup';

import DateTimeField from 'ui/DateTimeField';
import TimeField from 'ui/TimeField';
import {Bottom, StyledTable} from 'containers/RFQPopup/styles';

import {ActionButton, ConfirmationState} from 'components/ConfirmationButtons/types';
import ConfirmationButtons from 'components/ConfirmationButtons/ConfirmationButtons';

import TradePopup from 'components/popups/TradePopup';

import {RequestLateSettlementFeePopupProps} from '../types';
import {getLateSettlementAmount, YouReceivedOrYouPaidLateValue} from '../utils';


export const RequestLateSettlementFeePopup = ({tradeId, legType, lateFee}: RequestLateSettlementFeePopupProps) => {
  const {formatMessage} = useIntl();
  const currentTime = dayjs();
  const {openModal} = useModal();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const [dateTimeReceived, setDateTimeReceived] = useState<Dayjs>(currentTime.tz());
  const [timeFieldChanged, setTimeFieldChanged] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {selectTradeById} = useRealTimeTradesQuery();
  const trade = selectTradeById(tradeId);
  const [lateFeeAndAmount, setLateFeeAndAmount] = useState<LateFeeAmount>({
    points: 0,
    amountView: {
      quantity: 0,
      asset: {
        type: 'Cash',
        currency: 'USD',
      },
    },
  });

  useEffect(() => {
    if (!trade) return;
    const getNewLateFeeRateAndAmount = async () => {
      const requestBody: RequestTradeLateSettlementFeeAmountsBody = {
        settlementReceivedTimestamp: dateTimeReceived.toISOString(),
        legType,
        side: trade.side,
        baseAmount: trade.baseAmount,
        secondAmount: trade.secondAmount,
        nearLeg: trade.nearLeg,
        farLeg: trade.farLeg,
      };

      const {data} = await requestTradeLateSettlementFeeAmounts(requestBody);

      setLateFeeAndAmount(data);
    };

    if (isAbleToRequestPenalty) {
      void getNewLateFeeRateAndAmount();
    }
  }, [dateTimeReceived.toISOString(), timeFieldChanged, legType, JSON.stringify(trade)]);

  const goBackToCashFlowsTab = () => {
    openModal({
      modal: TradePopup,
      props: {
        modelId: tradeId,
        tradePopupTab: {value: DetailsTabType.CashFlows, label: DetailsTabType.CashFlows},
      },
    });
  };

  const handleAction = (action: () => Promise<AxiosResponse>, successMessage: string, errorMessage: string) => {
    setIsLoading(true);
    action()
      .then(() => {
        showToast(successMessage, 'success');
        goBackToCashFlowsTab();
      })
      .catch((e: any) => {
        console.error(e);
        showToast(errorMessage, 'warning');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onPropose = () => {
    handleAction(
      () => requestTradeLateSettlementFee(tradeId, dateTimeReceived, legType),
      'Late settlement fee has been requested.',
      'There was an error when requesting late settlement fee.'
    );
  };

  const onWithdraw = () => {
    handleAction(
      () => {
        if (!lateFee) return Promise.reject('Late fee is not available.');
        return withdrawTradeLateSettlementFee(tradeId, lateFee.id);
      },
      'Late settlement fee has been withdrawn.',
      'There was an error when withdrawing late settlement fee.'
    );
  };

  if (!trade) return null;

  const inMillions = shouldDisplayInMillions({base: trade.baseAmount.asset, second: trade.secondAmount.asset});
  const legTypeCamelCase = legType === LegType.NearLeg ? 'nearLeg' : 'farLeg';

  const settlementTimeWithDeadline = getTradeSettlementTimeWithDeadLine(
    trade,
    legType,
    0,
    'minutes',
    !trade[legTypeCamelCase].actualSettlementTime
  );

  const isTimeValid = validateDateTime({
    dateTime: dateTimeReceived,
    shouldBeInTheFuture: false,
    shouldBeInThePast: true,
    pastDateTime: settlementTimeWithDeadline,
  });

  const canRequestLateFee = (): boolean =>
    !isLoading && timeFieldChanged && isTimeValid && !currentTime.isAfter(settlementTimeWithDeadline.add(15, 'days'));

  const isAbleToRequestPenalty = canRequestLateFee();

  const proposeBtn: ActionButton[] = [
    {
      label: 'Propose Late Fee',
      onClick: onPropose,
      disabled: !isAbleToRequestPenalty,
      buttonStyle: 'warning',
    },
  ];
  const withdrawBtn: ActionButton[] = [
    {
      label: 'Withdraw',
      onClick: onWithdraw,
      disabled: isLoading,
      buttonStyle: 'secondary',
    },
  ];

  const onConfirmationChange = (state: ConfirmationState) => {
    setIsDisabled(state !== 'confirmation');
  };

  const onDateReceivedChange = (newDate: Dayjs) => {
    setDateTimeReceived(combineDateAndTimeFromTwoDayjsObjects(newDate, dateTimeReceived));
    if (newDate.isSame(currentTime, 'day')) {
      setDateTimeReceived(combineDateAndTimeFromTwoDayjsObjects(newDate, currentTime));
    }
    setTimeFieldChanged(true);
  };

  const onTimeReceivedChange = (newTime: Dayjs) => {
    setDateTimeReceived(combineDateAndTimeFromTwoDayjsObjects(dateTimeReceived, newTime));
    setTimeFieldChanged(true);
  };
  const delayedLegRow = (
    <TableRow key='delayedLeg' title={formatMessage(fieldTitles.delayedLeg)}>
      {legType === LegType.NearLeg ? 'Near leg' : 'Far leg'}
    </TableRow>
  );

  const settlementDeadlineRow = (
    <TableRow
      key='settlementDeadline'
      rightTestId='settlement-deadline'
      title={formatMessage(fieldTitles.settlementDeadline)}
    >
      {displayDate(settlementTimeWithDeadline, 'DD MMM YYYY HH:mm:ss')}
    </TableRow>
  );

  // As per FX-2716, the penalty date is valid only within 14 days from current date (exclusive)
  // returns a boolean to check if the date shown is eligible to be selected or should be disabled.
  const filterDate = (date: Dayjs): boolean => {
    if (date.isSameOrBefore(currentTime, 'date')) {
      if (date.isBefore(settlementTimeWithDeadline, 'date')) {
        return false;
      }
      return true;
    }
    return false;
  };

  const getDateTimeRows = () => {
    if (lateFee) {
      const settlementTime = dayjs(lateFee.settlementReceivedTimestamp);
      const dateReceivedRow = (
        <TableRow key='dateReceived' title={formatMessage(fieldTitles.dateReceived)}>
          {displayDate(settlementTime, 'DD MMM YYYY')}
        </TableRow>
      );

      const timeReceivedRow = (
        <TableRow key='timeReceived' title={formatMessage(fieldTitles.timeReceived)}>
          {displayDate(settlementTime, 'HH:mm:ss')}
        </TableRow>
      );
      return [dateReceivedRow, timeReceivedRow];
    } else {
      const getValidTimesRange = () => {
        return {
          start:
            settlementTimeWithDeadline.isAfter(dateTimeReceived.startOf('day')) ? settlementTimeWithDeadline : (
              dateTimeReceived.startOf('day')
            ),
          end: dayjs().isBefore(dateTimeReceived.endOf('day')) ? dayjs().tz() : dateTimeReceived.endOf('day'),
        };
      };
      const dateReceivedRow = (
        <TableRow key='dateReveived' title={formatMessage(fieldTitles.dateReceived)}>
          <DateTimeField
            disabled={!isDisabled}
            value={dateTimeReceived}
            onChange={value => value && onDateReceivedChange(value)}
            showTimeSelect={false}
            filterDate={date => filterDate(dayjs(date))}
          />
        </TableRow>
      );

      const timeReceivedFieldWarning = !timeFieldChanged;
      const timeReceivedRow = (
        <TableRow key='timeReceived' title={formatMessage(fieldTitles.timeReceived)}>
          <TimeField
            key={5}
            prefix=' '
            disabled={!isDisabled}
            onChange={onTimeReceivedChange}
            data-testid='time-received-input'
            validTimesRange={getValidTimesRange()}
            invalid={!isTimeValid}
            warning={timeReceivedFieldWarning}
            value={dateTimeReceived}
            placeholder={timeReceivedFieldWarning ? ' ' : undefined}
            step={1}
            timeFieldTooltipPosition='right'
          />
        </TableRow>
      );
      return [dateReceivedRow, timeReceivedRow];
    }
  };

  const delayRow = (
    <TableRow key='delay' title={formatMessage(fieldTitles.delay)}>
      {isTimeValid && timeFieldChanged ? displayDuration(settlementTimeWithDeadline, dateTimeReceived) : '--'}
    </TableRow>
  );

  const isRepoPaidAmount =
    getLateSettlementAmount({trade, legType, paidOrReceived: 'paid'}).asset.type === 'Securities';

  const youPaidRow = (
    <TableRow
      key='youPaid'
      title={formatMessage(isRepoPaidAmount ? fieldTitles.youSent : fieldTitles.youPaid)}
      leftTestId='you-paid-label'
      rightTestId='you-paid-value'
    >
      <YouReceivedOrYouPaidLateValue trade={trade} legType={legType} paidOrReceived={'paid'} inMillions={inMillions} />
    </TableRow>
  );

  const youShouldHaveReceivedRow = (
    <TableRow
      key='youShouldHaveReceived'
      title={formatMessage(fieldTitles.youShouldHaveReceived)}
      leftTestId='you-should-receive-label'
      rightTestId='you-should-receive-value'
    >
      <YouReceivedOrYouPaidLateValue
        trade={trade}
        legType={legType}
        paidOrReceived={'received'}
        inMillions={inMillions}
      />
    </TableRow>
  );

  const lateFeeRateRow = (
    <TableRow
      key='lateFeeRate'
      title={formatMessage(fieldTitles.lateFeeRate)}
    >{`${lateFeeAndAmount.points} bp`}</TableRow>
  );

  const lateFeeAmountRow = (
    <TableRow key='lateFeeAmount' rightTestId='late-fee-amount' title={formatMessage(fieldTitles.lateFeeAmount)}>
      {displaySmallAmount(lateFeeAndAmount.amountView)}
    </TableRow>
  );

  const rows = [
    delayedLegRow,
    youPaidRow,
    youShouldHaveReceivedRow,
    settlementDeadlineRow,
    ...getDateTimeRows(),
    delayRow,
    lateFeeRateRow,
    lateFeeAmountRow,
  ];

  // This is commented out because we need to implement FX-3051 for detailed late fee breakdown
  // until then, we will not display this row but will keep it here for future reference
  // const lateFeeBreakdownRow: Row = getRow(formatMessage(fieldTitles.lateFeeBreakdown), 0);

  const popupTitle = formatMessage(lateFee ? fieldTitles.reviewLateFeeHeader : fieldTitles.requestLateFeeHeader);
  return (
    <Popup title={popupTitle} onClose={goBackToCashFlowsTab} width='500px' closeButtonType='arrow'>
      <StyledTable className='late-fee-popup' elements={rows} />
      <Bottom>
        <ConfirmationButtons onChange={onConfirmationChange} actions={lateFee ? withdrawBtn : proposeBtn} />
      </Bottom>
    </Popup>
  );
};
