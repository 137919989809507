import {
  ComponentProps,
  JSXElementConstructor,
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';

type Component<P> = {modal: JSXElementConstructor<P>; props: ComponentProps<JSXElementConstructor<P>>};

export interface ModalContextValues {
  isOpen: boolean;
  openModal: <P extends {}>(componentToOpen: Component<P>) => void;
  closeModal: () => void;
  hideModal: () => void;
  goBack: () => void;
}

export const ModalContext = createContext<ModalContextValues>({
  isOpen: false,
  openModal: () => {
    /*empty*/
  },
  closeModal: () => {
    /*empty*/
  },
  hideModal: () => {
    /*empty*/
  },
  goBack: () => {
    /* empty */
  },
});

export function useModal() {
  return useContext(ModalContext);
}

export default function ModalContextProvider({children}: PropsWithChildren<{}>) {
  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState<Component<any>>();
  const lastComponent = useRef<Component<any> | null>(null);

  const openModal = <P extends {}>(componentToOpen?: Component<P>) => {
    setIsOpen(true);
    if (componentToOpen) {
      if (component) lastComponent.current = component;
      setComponent(componentToOpen);
    }
  };

  const goBack = () => {
    if (lastComponent.current) {
      setIsOpen(true);
      setComponent(lastComponent.current);
      lastComponent.current = null;
    } else {
      setIsOpen(false);
      setComponent(undefined);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setComponent(undefined);
    lastComponent.current = null;
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const Modal = component?.modal;

  return (
    <ModalContext.Provider value={{isOpen, openModal, closeModal, hideModal, goBack}}>
      {children}
      {isOpen && Modal && <Modal {...component?.props} />}
    </ModalContext.Provider>
  );
}
