import styled from 'styled-components';

export const ParaWrapper = styled.div`
  padding: 0.8em;

  p {
    margin-top: 20px;
  }

  p:first-child {
    margin-top: 0;
  }
`;

export const HelperParagraph = styled.p`
  text-align: left;
  color: ${p => p.theme.colors.blue50};
  line-height: 1.5rem;
`;
