import {useIntl} from 'react-intl';
import {Amount, RequesteeAndQuote, RFQQuoteRejectionReason, RFQRequest, RFQRequestRejectionReason} from 'types/api';
import {
  displayAmountWithCode,
  displayAmountWithUnit,
  displaySpotRangeBigfig,
  earmarkAmount,
  payingOrReceivingInterest,
} from 'utils/AmountUtils';
import {
  getInitialFXRate,
  getInterestRateForComputation,
  getReasonText,
  getSpotRangeBigFigForComputation,
  getQuoteStatusText,
  counterAmountToString,
  isFXSwapRequestType,
} from 'utils/RFQUtils';
import {actualSpotRange, displayForwardPoints, displayInterestRate, displayParty} from 'utils/utils';
import {displayLegPrefixAndDateTime, displayNearLegAsAsap, displayDate} from 'utils/DayjsUtils';

import {displaySide} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {fieldTitles} from 'constants/messages/labels';

interface DetailsTableColPair {
  title: string;
  value: string;
  uppercase?: boolean;
}

type DetailsTableRow = [DetailsTableColPair, DetailsTableColPair?];

export const useQuoteTableRows = () => {
  const {formatMessage} = useIntl();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const getQuoteDetailsRows = (
    request: RFQRequest,
    isRepo: boolean,
    requesteeAndQuote?: RequesteeAndQuote
  ): DetailsTableRow[] => {
    const inMillions = shouldDisplayInMillions({base: request.baseAsset, second: request.secondAsset});

    if (!requesteeAndQuote || !requesteeAndQuote.quote) {
      return [];
    }

    const quote = requesteeAndQuote.quote;

    // If request is received than quote is sent.
    const isQuoteReceived = !request.isReceived;

    const earmarkAmt: Amount = earmarkAmount(
      quote.tradedAmount,
      request.secondAsset.currency,
      getInterestRateForComputation(request.requestType),
      getSpotRangeBigFigForComputation(request.requestType),
      request.requesteeSide
    );

    const side = !request.isReceived ? request.requestorSide : request.requesteeSide;

    const spotRangeAct =
      isFXSwapRequestType(request.requestType) ?
        actualSpotRange(getInterestRateForComputation(request.requestType), request.requestType.spotRange)
      : null;

    // Get rejection reasons for the quote.
    const rejectionReasons = requesteeAndQuote?.stage.reasons || [];

    return [
      // Quote id | status
      [
        {
          title: formatMessage(fieldTitles.uniqueQuoteId),
          value: quote.id,
        },
        {
          title: formatMessage(fieldTitles.status),
          value: getQuoteStatusText(request.isReceived, requesteeAndQuote.expired, requesteeAndQuote.stage.name),
        },
      ],
      // Your side | imp. Yield ? Rate
      [
        {
          title: formatMessage(fieldTitles.yourSide),
          value: displaySide(side, isRepo),
        },
        {
          title: isRepo ? formatMessage(fieldTitles.rate) : formatMessage(fieldTitles.impYield),
          value: displayInterestRate(quote.interestRate),
        },
      ],
      // Sent / received | fwdPts
      [
        {
          title: formatMessage(fieldTitles.sentReceived),
          value: isQuoteReceived ? 'RECEIVED' : 'SENT',
        },
        {
          title: isRepo ? '' : formatMessage(fieldTitles.fwdPts), // empty for Repo
          value: isRepo ? '' : displayForwardPoints(quote.forwardPoints), // empty for Repo
        },
      ],
      // Counterparty | interest side
      [
        {
          title: formatMessage(fieldTitles.counterparty),
          value: isQuoteReceived ? displayParty(requesteeAndQuote.requestee) : displayParty(request.requestor),
        },
        {
          title: formatMessage(fieldTitles.interestSide),
          value: payingOrReceivingInterest(side, quote.interestRate),
        },
      ],
      // Traded amount | Created date
      [
        {
          title: formatMessage(fieldTitles.tradedAmt),
          value: displayAmountWithUnit(quote.tradedAmount, inMillions),
        },
        {
          title: formatMessage(fieldTitles.creationDate),
          value: displayDate(quote.createdAt, 'DD MMM YYYY'),
        },
      ],
      // Counter amount | created time
      [
        {
          title: formatMessage(isRepo ? fieldTitles.securitiesAmt : fieldTitles.counterAmt),
          value:
            request ?
              counterAmountToString(
                quote.tradedAmount,
                request.requestType,
                request.baseAsset,
                request.secondAsset,
                isRepo,
                inMillions
              )
            : '',
        },
        {
          title: formatMessage(fieldTitles.quoteCreatedAt),
          value: displayDate(quote.createdAt, 'HH:mm:ss'),
        },
      ],
      //  earmark ? | near leg date
      [
        {
          title: isQuoteReceived ? '' : formatMessage(fieldTitles.earmark), // Only for SENT quotes
          value: isQuoteReceived ? '' : displayAmountWithCode(earmarkAmt, false, inMillions), // Only for SENT quotes
        },
        {
          title: isRepo ? formatMessage(fieldTitles.onLegDate) : formatMessage(fieldTitles.nearLegDate),
          value: request ? displayDate(request.nearLegTime ?? request.createdAt, 'DD MMM YYYY') : '',
        },
      ],
      // InitialXR | Far Leg Time
      [
        {
          // For repo show haircut
          // For FX-swap show initial rate
          title: isRepo ? formatMessage(fieldTitles.haircut) : formatMessage(fieldTitles.initialRate),
          value: isRepo ? 'Assumed 10%' : getInitialFXRate(request.requestType)?.toString() ?? '--',
        },
        {
          title: isRepo ? formatMessage(fieldTitles.onLegTime) : formatMessage(fieldTitles.nearLegTime),
          value:
            request.nearLegTime ?
              displayLegPrefixAndDateTime(request.nearLegTime, request.nearLegTimeInput?.transactionDate, 'HH:mm')
            : displayNearLegAsAsap(),
        },
      ],
      // Spot range | Far Let Date
      [
        {
          // Empty for repo
          title: isRepo ? '' : formatMessage(fieldTitles.spotRange),
          value:
            isRepo || !request || !('spotRange' in request.requestType) ?
              ''
            : displaySpotRangeBigfig(request.requestType.spotRange),
        },
        {
          title: isRepo ? formatMessage(fieldTitles.offLegDate) : formatMessage(fieldTitles.farLegDate),
          value: displayDate(request.maturityTime, 'DD MMM YYYY'),
        },
      ],
      // Spot range ACT | Far Let Time
      [
        {
          // Empty for repo
          title: isRepo ? '' : formatMessage(fieldTitles.spotRangeAct),
          value: isRepo || !spotRangeAct ? '' : `${spotRangeAct.min.toFixed(4)} - ${spotRangeAct.max.toFixed(4)}`,
        },
        {
          title: isRepo ? formatMessage(fieldTitles.offLegTime) : formatMessage(fieldTitles.farLegTime),
          value: displayLegPrefixAndDateTime(request.maturityTime, request.maturityTimeInput.transactionDate, 'HH:mm'),
        },
      ],
      // Entity | Rejection reasons
      [
        {
          title: formatMessage(fieldTitles.entity),
          value: isQuoteReceived ? displayParty(request.requestor) : displayParty(requesteeAndQuote.requestee),
        },
        {
          title: formatMessage(fieldTitles.rejectionReason),
          value:
            rejectionReasons
              .map((reason: RFQRequestRejectionReason | RFQQuoteRejectionReason) =>
                getReasonText(request.requesteeSide, reason, request.baseAsset, request.secondAsset)
              )
              .join('\n') || 'N/A',
        },
      ],
      [
        {
          title: formatMessage(fieldTitles.traderInfo),
          value: quote.userId,
          uppercase: false,
        },
      ],
    ];
  };

  return {getQuoteDetailsRows};
};
