import React from 'react';

import {isRFQRequestExpired, rfqRequestHasQuote} from 'utils/RFQUtils';

import {ComponentProps} from './types';
import RequestReceived from './LatestRequestReceived';
import RequestExpired from './PreviousRequestReceived';
import QuoteProvided from './QuoteProvided';

export const RFQRequestReceived: React.FC<ComponentProps> = ({requestData}: ComponentProps) => {
  const isExpired: boolean = isRFQRequestExpired(requestData);
  const hasQuote: boolean = rfqRequestHasQuote(requestData);

  if (isExpired) {
    return <RequestExpired requestData={requestData} />;
  } else {
    return hasQuote ? <QuoteProvided requestData={requestData} /> : <RequestReceived requestData={requestData} />;
  }
};
