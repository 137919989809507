import {useCallback} from 'react';

import {Trade, TradeAnnul, TradeUnwindRequest} from 'types/api';
import {unifiedEventStream} from 'api/unifiedEventStream';

import {findTradesWithActions} from 'utils/TradeUtils';
import {useUser} from 'contexts/auth-context';

import {QueryClient} from '@tanstack/react-query';
import unionBy from 'lodash/unionBy';
import cloneDeep from 'lodash/cloneDeep';

import {sortBlotterByCreatedAt} from 'utils/BlotterUtils';

import {getTradesData} from '../api';
import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['RealTimeTrades'];

interface Data {
  trades: Trade[];
  lastEventId: string;
}

function subscribeTrades(client: QueryClient, lastEventId?: string) {
  const handler = (trade: Trade) => {
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) {
        return;
      }
      return {...data, trades: unionBy([trade], data.trades, 'id').sort(sortBlotterByCreatedAt)};
    });
    return {type: ''};
  };

  void subscribeTradeAnnuls(client, lastEventId || '0,0');
  void subscribeTradeUnwinds(client, lastEventId || '0,0');
  return unifiedEventStream.subscribe('trade', handler, {lastEventId: '0,0'});
}

function subscribeTradeAnnuls(client: QueryClient, lastTradeEventId: string) {
  const handler = (tradeAnnul: TradeAnnul) => {
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) {
        return;
      }
      const newData = cloneDeep(data);
      newData.trades = newData.trades.map(trade => {
        if (trade.id === tradeAnnul.tradeId) {
          return {...trade, tradeAnnul};
        }
        return trade;
      });
      return newData;
    });
    return {type: ''};
  };

  return unifiedEventStream.subscribe('trade-annul-proposal', handler, {lastEventId: lastTradeEventId});
}

// TODO(kpieniazek): We might want to consider unified interface + common method for trade-related updates
// once we work on moving Early Maturity Requests to a separate state (https://finteum.atlassian.net/browse/FX-2965)
function subscribeTradeUnwinds(client: QueryClient, lastTradeEventId: string) {
  const handler = (tradeUnwind: TradeUnwindRequest) => {
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) {
        return;
      }
      const newData = cloneDeep(data);
      newData.trades = newData.trades.map(trade => {
        if (trade.id === tradeUnwind.tradeId) {
          return {...trade, tradeUnwind};
        }
        return trade;
      });
      return newData;
    });
    return {type: ''};
  };

  return unifiedEventStream.subscribe('trade-unwind-proposal', handler, {lastEventId: lastTradeEventId});
}

export const useRealTimeTradesQuery = () => {
  const user = useUser();
  const {data} = useSubscriptionQuery(
    {
      queryKey: QUERY_KEY,
      queryFn: () =>
        getTradesData().then(response => {
          const trades = [...response.data].reverse();
          const lastEventId = response.headers['x-last-event-id'];
          return {data: {trades}, lastEventId};
        }),
    },
    subscribeTrades
  );
  const trades = data?.trades || [];
  const tradesRequiringAction = findTradesWithActions(trades, user);

  const selectTradeById = useCallback((id: string) => data?.trades.find(trade => trade.id === id), [data]);

  return {
    trades,
    tradesRequiringAction,
    selectTradeById,
  };
};
