import styled from 'styled-components';
import {BlotterPosition} from 'types/layout';

export const getContainerStyles = (blotterPosition: BlotterPosition) => ({
  height: `calc(100% - ${blotterPosition === BlotterPosition.Minified ? 36 : 220}px)`,
  display: blotterPosition === BlotterPosition.Expanded ? 'none' : 'flex',
});

export const SOutletWrapper = styled.div`
  height: inherit;
`;
