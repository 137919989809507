import dayjs from 'dayjs';
import {IntlShape} from 'react-intl';
import {createColumnHelper} from '@tanstack/react-table';
import {CapacityAdjustment} from 'types/api';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayAsset} from 'utils/AssetUtils';
import {displayDate} from 'utils/DayjsUtils';

import useCapacityManagerStore from 'stores/useCapacityManagerStore';

import {Link} from 'react-router-dom';

import {Scope} from 'constants/permission-maps';

import {fieldTitles} from 'constants/messages/labels';
import {MANAGE_CAPACITY} from 'constants/paths';
import {ColumnDef} from 'ui/GridTable';
import FieldTitle from 'ui/FieldTitle';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import {DateContainer} from 'containers/Blotter/styles';
import PermissionsGate from 'components/PermissionGate';

const columnHelper = createColumnHelper<CapacityAdjustment>();

export const getCapacityAdjustmentsTableColumns = (
  formatMessage: IntlShape['formatMessage']
): ColumnDef<CapacityAdjustment>[] => [
  {
    accessorKey: 'createdAt',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle data-testid='capacity-date-and-time-header'>{formatMessage(fieldTitles.dateAndTime)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer data-id={info.row.original.issuerId}>
        <span>{displayDate(info.row.original.createdAt, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.createdAt, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 13.1,
    },
    sortingFn: 'datetime',
    sortDescFirst: true,
  },
  {
    accessorKey: 'amount.asset',
    enableSorting: false,
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle data-testid='capacity-asset-header'>{formatMessage(fieldTitles.asset)}</FieldTitle>
      </span>
    ),
    cell: info => <span data-testid='capacity-asset-value'>{displayAsset(info.row.original.amount.asset)}</span>,
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 6.9,
    },
  },
  {
    accessorKey: 'from',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle data-testid='capacity-from-header'>{formatMessage(fieldTitles.from)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer data-testid='capacity-from-value'>
        <span>{displayDate(info.row.original.from, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.from, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: 'datetime',
    sortDescFirst: true,
  },
  {
    accessorKey: 'to',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle data-testid='capacity-to-header'>{formatMessage(fieldTitles.to)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer data-testid='capacity-to-value'>
        <span>{displayDate(info.row.original.to, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.to, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: 'datetime',
    sortDescFirst: true,
  },
  {
    accessorKey: 'amount.quantity',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle data-testid='capacity-increment-header'>{formatMessage(fieldTitles.increment)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span data-testid='capacity-increment-value'>{displayAmountWithCode(info.row.original.amount, false, true)}</span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: 'alphanumeric',
  },
  // Workaround to align columns similar to other blotter tables
  columnHelper.display({
    id: 'undoAdjustment',
    header: '',
    cell: info => {
      const {amount, from, to} = info.row.original;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const {setCapacityData} = useCapacityManagerStore();
      const canUndoAdjustment = dayjs(info.row.original.to).isSameOrAfter(new Date());

      const onClick = () =>
        setCapacityData({
          asset: amount.asset,
          amount: amount.quantity,
          fromDate: from,
          toDate: to,
        });

      if (!canUndoAdjustment) return null;

      return (
        <PermissionsGate scopes={[Scope.AdjustCapacity]}>
          <Link
            data-testid='capacity-undo-btn'
            to={MANAGE_CAPACITY.buildAbsolute({asset: amount.asset})}
            onClick={onClick}
          >
            <TooltipWrapper
              message='Undo capacity adjustment'
              position='top'
              maxWidth={500}
              styles={{display: 'block', padding: '0.5rem 1rem'}}
            >
              <i className='la la-undo-alt' />
            </TooltipWrapper>
          </Link>
        </PermissionsGate>
      );
    },
    meta: {
      align: 'left',
      alignItems: 'start',
      size: 10,
    },
  }),
  ...[1, 2, 3, 4]
    .map(n => n.toString())
    .map(id =>
      columnHelper.display({
        id,
        meta: {
          size: 10,
        },
      })
    ),
];
