import {useState} from 'react';
import {FormProvider} from 'react-hook-form';

import {RequestFormState} from 'types/rfqForm';
import {getLeg, getLegDateTimeInput, prepareRFQLegsForRequest} from 'utils/RFQUtils';

import {Scope} from 'constants/permission-maps';

import PermissionsGate from 'components/PermissionGate';

import RFQForm from './components/RFQForm';
import Confirmation from './components/Confirmation';
import {RFQFormProps} from './types';
import {Container} from './styles';
import useRFQForm, {FORM_DEFAULT_VALUES, RFQFormValues} from './hooks/useRFQForm';

export const parseValuesToRfqForm = (values: RFQFormValues): RequestFormState | undefined => {
  if (!values) return;
  const [nearLeg, farLeg] = prepareRFQLegsForRequest(values.nearleg, values.farleg);
  return {
    pair: values.currentPair,
    counterparty1: values.counterparty1 || '',
    counterparty2: values.counterparty2 || '',
    counterparty3: values.counterparty3 || '',
    tradedAmount: values.tradedAmount,
    tradedCurrency: values.tradedAmountCurrency,
    spotRangeBigFig: values.bigfig || 1,
    validFor: values.validFor,
    pending: false,
    suggestedRate: values.rate,
    initialFXRate: values.exchangeRate?.value,
    side: values.side,
    nearLeg: nearLeg ? getLegDateTimeInput(nearLeg, getLeg(values.nearlegTransactionDate)) : undefined,
    farLeg: getLegDateTimeInput(farLeg, getLeg(values.farlegTransactionDate)),
    nearLegInstant: nearLeg,
    farLegInstant: farLeg,
  };
};

export const RFQFormContainer = ({disabled = false}: RFQFormProps) => {
  const [rfqForm, setRfqFrom] = useState<RequestFormState>();
  const {methods} = useRFQForm();

  return (
    <PermissionsGate scopes={[Scope.CanTrade]}>
      <FormProvider {...methods}>
        <Container shouldHideForm={!!rfqForm}>
          {rfqForm && (
            <Confirmation
              onSuccess={() => {
                methods.reset({
                  ...FORM_DEFAULT_VALUES,
                  exchangeRate: methods.getValues().exchangeRate,
                });
                setRfqFrom(undefined);
              }}
              onEdit={() => setRfqFrom(undefined)}
              rfqForm={rfqForm}
            />
          )}
          <RFQForm
            disabled={disabled}
            onSubmit={values => {
              setRfqFrom(parseValuesToRfqForm(values));
            }}
          />
        </Container>
      </FormProvider>
    </PermissionsGate>
  );
};
