import {useIntl} from 'react-intl';

import {getRFC2253ValueByKey} from 'utils/utils';
import {showToast} from 'utils/ToastUtils';

import {acceptTradeUnwind, rejectTradeUnwind} from 'api/api';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {addMillisTimeToDate, DateFormat, displayDate} from 'utils/DayjsUtils';

import {AxiosError} from 'axios';

import TableRow from 'utils/TableUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {fieldTitles} from 'constants/messages/labels';

import Popup from 'ui/Popup';

import {Bottom, StyledTable} from 'containers/RFQPopup/styles';

import {FooterLabel} from 'components/popups/TradePopup/components/ReviewUnwindTradePopup/styles';
import {UnwindTradePopupProps} from 'components/popups/TradePopup/components/UnwindTradePopup/types';

import {unwindReasonToItem} from 'components/popups/TradePopup/components/UnwindTradePopup';
import {ActionButton} from 'components/ConfirmationButtons/types';
import ConfirmationButtons from 'components/ConfirmationButtons/ConfirmationButtons';

export const ReviewUnwindTradePopup = ({tradeId}: UnwindTradePopupProps) => {
  const {formatMessage} = useIntl();
  const {goBack} = useModal();
  const {selectTradeById} = useRealTimeTradesQuery();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const handleClose = () => goBack();

  const onRejectUnwind = () => {
    rejectTradeUnwind(tradeId)
      .then(() => {
        showToast('Unwind request rejected', 'success');
        handleClose();
      })
      .catch((e: AxiosError) => {
        showToast('Failed to reject unwind trade', 'error');
        console.error(e.message);
      });
  };

  const onAcceptUnwind = () => {
    acceptTradeUnwind(tradeId)
      .then(() => {
        showToast('Unwind request accepted', 'success');
        handleClose();
      })
      .catch((e: AxiosError) => {
        showToast('Failed to accept unwind trade', 'error');
        console.error(e.message);
      });
  };

  const trade = selectTradeById(tradeId);
  if (!trade) return null;
  const inMillions = shouldDisplayInMillions({base: trade.baseAmount.asset, second: trade.secondAmount.asset});

  const counterpartyRow = (
    <TableRow key='counterparty' title={formatMessage(fieldTitles.counterparty)}>
      {getRFC2253ValueByKey(trade.counterparty, 'O')}
    </TableRow>
  );

  const paymentAmountRow = (
    <TableRow key='scheduledPayment' title='Scheduled payment'>
      {displayAmountWithCode(trade.baseAmount, false, inMillions)}
    </TableRow>
  );
  const receiptAmountRow = (
    <TableRow key='scheduledRecepit' title='Scheduled receipt'>
      {displayAmountWithCode(trade.secondAmount, false, inMillions)}
    </TableRow>
  );
  const openingTransferRow = (
    <TableRow key='scheduledOTransfer' title='Scheduled opening transfer'>
      {displayDate(trade.nearLeg.scheduledTime, 'DD MMM YY HH:mm:ss' as DateFormat)}
    </TableRow>
  );
  const openingDeadlineRow = (
    <TableRow key='scheduledODeadline' title='Scheduled opening deadline'>
      {displayDate(
        addMillisTimeToDate(trade.nearLeg.scheduledTime, trade.expectedTradeDuration),
        'DD MMM YY HH:mm:ss' as DateFormat
      )}
    </TableRow>
  );
  const closingTransferRow = (
    <TableRow key='scheduledCTransfer' title='Scheduled closing transfer'>
      {displayDate(trade.farLeg.scheduledTime, 'DD MMM YY HH:mm:ss' as DateFormat)}
    </TableRow>
  );
  const unwindReasonRow = (
    <TableRow key='unwindReason' title='Unwind Reason Given'>
      {unwindReasonToItem(trade.tradeUnwind?.reason)?.label}
    </TableRow>
  );

  const tableRows = [
    counterpartyRow,
    paymentAmountRow,
    receiptAmountRow,
    openingTransferRow,
    openingDeadlineRow,
    closingTransferRow,
    unwindReasonRow,
  ];

  const actions: ActionButton[] = [
    {
      label: 'Reject',
      onClick: onRejectUnwind,
      buttonStyle: 'secondary',
      testId: 'reject',
    },
    {
      label: 'Accept',
      onClick: onAcceptUnwind,
      buttonStyle: 'primary',
      testId: 'accept',
    },
  ];

  return (
    <Popup
      title={formatMessage(fieldTitles.reviewUnwindPopUpHeader)}
      onClose={handleClose}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable className='late-fee-popup' elements={tableRows} />
      <Bottom>
        <FooterLabel textAlign='left'>{formatMessage(fieldTitles.reviewUnwindPopUpFooter)}</FooterLabel>
        <FooterLabel textAlign='left'>{formatMessage(fieldTitles.reviewUnwindPopUpFooter2)}</FooterLabel>
        <ConfirmationButtons actions={actions} withConfirmationMessage={false} />
      </Bottom>
    </Popup>
  );
};
