import {useQuery} from '@tanstack/react-query';

import {calculateTradeEconomics} from 'api/api';
import dayjs from 'dayjs';

import {Amount, Asset, Side} from 'types/api';

export interface UseEconomicsQueryArgs {
  tradedAmount: Amount;
  spotRate: number | undefined;
  side: Side | undefined;
  startTime: dayjs.Dayjs;
  maturityTime: dayjs.Dayjs | undefined;
  spotRange: number | undefined;
  impliedYield: number | undefined;
  forwardPoints: number | undefined;
  baseAsset: Asset;
  nonAggressorOnly?: boolean; // TODO(Zbi): Why would it ever be undefined?
}

export default function useEconomicsQuery(
  {
    tradedAmount,
    baseAsset,
    spotRange,
    spotRate,
    startTime,
    maturityTime,
    impliedYield,
    forwardPoints,
    nonAggressorOnly = false,
    side,
  }: UseEconomicsQueryArgs,
  debounceTime: number = 0
) {
  const enabled = !!spotRate && !!side && !!maturityTime && !!tradedAmount.quantity;
  const nearLegKey = startTime.format('ddd DD MMM HH:mm z');
  return useQuery({
    enabled,
    queryKey: [
      'Economics',
      spotRate,
      side,
      nearLegKey,
      maturityTime,
      spotRange,
      impliedYield,
      forwardPoints,
      nonAggressorOnly,
      JSON.stringify(tradedAmount),
      JSON.stringify(baseAsset),
    ],
    queryFn: async () => {
      if (!enabled) return null;

      await new Promise(res => setTimeout(() => res(true), debounceTime));

      return await calculateTradeEconomics({
        nonAggressorOnly,
        startTime: startTime.toISOString(),
        maturityTime: dayjs(maturityTime).toISOString(),
        impliedYield,
        forwardPoints,
        baseAsset,
        tradedAmount,
        assumedExchangeRate: spotRate,
        spotRange: spotRange ?? 0,
        side,
      }).then(response => response.data);
    },
    refetchInterval: 10000,
  });
}
