import {CSSProperties, FC} from 'react';

export const YellowCircleIcon: FC<{style?: CSSProperties}> = ({style}) => (
  <svg
    style={{
      color: 'rgb(255, 221, 0)',
      ...style,
    }}
    xmlns='http://www.w3.org/2000/svg'
    width='10'
    height='10'
    fill='currentColor'
    className='bi bi-circle-fill'
    viewBox='0 0 16 16'
  >
    <circle cx='8' cy='8' r='8' />
  </svg>
);
