import React from 'react';

import {OrderAggregate, Side} from 'types/api';
import {ReferenceData} from 'types/layout';
import {convertQuantity, displayAmountWithUnit, To} from 'utils/AmountUtils';
import {displayForwardPoints, displayInterestRate} from 'utils/utils';

import useReferenceDataStore from 'stores/useReferenceDataStore';
import useStateAssets from 'utils/hooks/useStateAssets';

import {createFlasher} from 'utils/AnimationUtils';

import useClobOrderDataStore from 'stores/useClobOrderDataStore';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

import {SCol} from 'ui/Layout/styles';
import {Marker, MarkerValue} from 'containers/ClobSideBar/styles';
import {YellowCircleIcon} from 'components/IconComponents/YellowCircleIcon';

import {SSidebarOrder, SOrderRate} from './styles';

export interface ClobSideBarRowProps {
  side: Side;
  agg: OrderAggregate;
  isRepo: boolean;
}

const {trigger: baseFieldFlash} = createFlasher('baseFieldFlashId');
const {trigger: secondFieldFlash} = createFlasher('secondFieldFlashId');
const {trigger: interestFwdPointsFlash} = createFlasher('interestFwdPointsFlashId');

export const ClobSideBarRow: React.FC<ClobSideBarRowProps> = ({agg, side, isRepo}: ClobSideBarRowProps) => {
  const {referenceData} = useReferenceDataStore();
  const {setSideBarData} = useClobOrderDataStore();
  const {baseAsset, secondAsset} = useStateAssets();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base: baseAsset, second: secondAsset});

  const getOrderFormImpliedYield = () => ({interestRate: agg.impliedYield});

  const updateOrderForm = () => {
    const oppositeSide = side === 'BuySell' ? 'SellBuy' : 'BuySell';
    const impYield = getOrderFormImpliedYield();
    const newBaseAmount = convertQuantity(
      agg.cumulativeAmount.quantity,
      To.View,
      true,
      inMillions ? 1_000_000_00 : 1_00
    );
    setSideBarData({
      side: oppositeSide,
      baseAmount: newBaseAmount,
      ...impYield,
    });
    triggerFlashes();
  };

  const triggerFlashes = () => {
    interestFwdPointsFlash();
    baseFieldFlash();
    secondFieldFlash();
  };

  const isOffer = side === 'SellBuy';
  // for SellBuy show tooltip on top otherwise at the bottom
  const tooltipPosition = side === 'SellBuy' ? 'top' : 'bottom';

  return (
    <TooltipWrapper
      message={`YOUR ORDERS: ${displayAmountWithUnit(agg.ourUnmatchedAmount, inMillions)}`}
      disabled={agg.ourUnmatchedAmount.quantity <= 0}
      position={tooltipPosition}
      variant={'light'}
    >
      <SSidebarOrder isOffer data-testid='clob-side-bar-row' onClick={updateOrderForm}>
        <SOrderRate width={[1, 1 / 3]} isOffer={isOffer}>
          {agg.ourUnmatchedAmount.quantity > 0 && (
            <Marker data-testid='clob-your-order-icon'>
              <YellowCircleIcon />
            </Marker>
          )}
          {referenceData === ReferenceData.ImpliedYield || isRepo ?
            <MarkerValue data-testid='clob-side-bar-implied-yield-value'>
              {displayInterestRate(agg.impliedYield)}
            </MarkerValue>
          : <MarkerValue data-testid='clob-side-bar-forward-points-value'>
              {displayForwardPoints(agg.forwardPoints)}
            </MarkerValue>
          }
        </SOrderRate>
        <SCol width={[1, 1 / 3]} data-testid='clob-side-bar-amount-value' textAlign='right' pr='10px'>
          {displayAmountWithUnit(agg.unmatchedAmount, inMillions)}
        </SCol>
        <SCol width={[1, 1 / 3]} position='relative' textAlign='right'>
          {displayAmountWithUnit(agg.cumulativeAmount, inMillions)}
        </SCol>
      </SSidebarOrder>
    </TooltipWrapper>
  );
};
