import {defineMessages} from '@formatjs/intl';

export const order = defineMessages({
  orderType: {
    id: 'app.field.orderType',
    description: 'Field title, order type',
    defaultMessage: 'Order type',
  },
  isNonAggressorOnlyNegativeNo: {
    id: 'app.rfq.isNonAggressorOnlyNegativeNo',
    description: 'RFQ economics, is Non Aggressor Only Negative No.',
    defaultMessage: 'Assumed No',
  },
  economicsTabHeader: {
    id: 'app.rfq.economicsTabHeader',
    description: 'RFQ economics, economics Tab Header',
    defaultMessage: 'Expected trade economics, assuming full and immediate order matching:',
  },
});
