import styled from 'styled-components';

import {
  EmptyGridTableCellProps,
  EmptyGridTableHeaderProps,
  GridTableCellProps,
  GridTableHeaderGroupProps,
  GridTableHeaderProps,
  GridTableRowProps,
  GridTableWrapperProps,
  GridTableVariant,
} from './types';

export const REGULAR_ROW_HEIGHT_PX = 40;
export const MINIMAL_ROW_HEIGHT_PX = 38;

export const GridTableWrapper = styled.div<GridTableWrapperProps>`
  ${({fullWidth, gridTemplateColumns, theme, variant}) => `
    background-color: ${theme.colors.blue900};
    display: inline-grid;
    grid-template-columns: ${gridTemplateColumns};
    width: ${fullWidth ? '100%' : 'auto'};
    height: 100%;
    overflow-y: auto;

    ${
      variant === GridTableVariant.Minimal ?
        `
      grid-template-rows: repeat(auto-fit, ${MINIMAL_ROW_HEIGHT_PX}px);
      width: 100%;
      height: 100%;
      overflow-y: auto;
    `
      : ''
    }
  `}
`;

export const GridTableHeaderGroup = styled.div<GridTableHeaderGroupProps>`
  ${({gridTemplateColumns, variant}) => `
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: ${gridTemplateColumns};

    ${
      variant === GridTableVariant.Minimal ?
        `
      background-color: rgba(0, 0, 0, 0.03);
      position: sticky;
      top: 0;
      z-index: 2;
    `
      : ''
    }
  `}
`;

export const GridTableHeader = styled.div<GridTableHeaderProps>`
  ${({align, hasLeftSpacing, hasRightSpacing, isRoot, sortable, start, span, theme, variant}) => `
    background-color: ${theme.colors.gray500};
    font-weight: bold;
    text-align: ${align ?? 'right'};
    ${
      variant === GridTableVariant.Regular ?
        `
          margin-left: ${hasLeftSpacing ? '5px' : 'initial'};
          margin-right: ${hasRightSpacing ? '5px' : 'initial'};
          padding: ${isRoot ? '15px 10px' : '10px'};
        `
      : ''
    }
    color: ${isRoot ? 'white' : theme.colors.blue50};
    text-transform: ${isRoot ? 'initial' : 'uppercase'};
    font-size: ${isRoot ? '16px' : '12px'};
    grid-column: ${start} / span ${span};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${align ?? 'end'};

    i.la {
      font-size: 12px;
      color: ${theme.colors.blue50};
    }

    ${sortable ? 'cursor: pointer' : ''};

    ${
      variant === GridTableVariant.Minimal ?
        `
      max-height: ${MINIMAL_ROW_HEIGHT_PX}px;
      background-color: ${theme.colors.blue900};
      font-size: 10px;
      font-weight: normal;
      border-bottom: 2px solid ${theme.colors.gray900};
      user-select: none;
    `
      : ''
    }
  `}
`;

export const EmptyGridTableHeader = styled.div<EmptyGridTableHeaderProps>`
  ${({align, isRoot, theme}) => `
    background-color: ${theme.colors.gray500};
    font-weight: bold;
    text-align: ${align ?? 'right'};
    color: ${isRoot ? 'white' : theme.colors.blue50};
    text-transform: ${isRoot ? 'initial' : 'uppercase'};
    font-size: ${isRoot ? '16px' : '12px'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${align ?? 'end'};

    i.la {
      font-size: 12px;
      color: ${theme.colors.blue50};
    }
  `}
`;

export const GridTableRow = styled.div<GridTableRowProps>`
  ${({clickable = false, gridTemplateColumns, theme, variant}) => `
    grid-column: 1 / -1;
    cursor: ${clickable ? 'pointer' : 'auto'};
    display: grid;
    grid-template-columns: ${gridTemplateColumns};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    ${
      variant === GridTableVariant.Minimal ?
        `
      max-height: ${MINIMAL_ROW_HEIGHT_PX}px;

      &:nth-child(even) > div {
        background-color: rgba(0, 0, 0, 0.03);
      }

      &:nth-child(odd) > div {
        background-color: ${theme.colors.blue950};
      }

      &:hover > div {
        background-color: ${theme.colors.tableRowHover};
      }
    `
      : ''
    }
  `}
`;

export const GridTableCell = styled.div<GridTableCellProps>`
  ${({align, alignItems, isInsideEvenRow, hasLeftSpacing, hasRightSpacing, theme, variant}) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${REGULAR_ROW_HEIGHT_PX}px;
    background-color: ${isInsideEvenRow ? theme.colors.blue900 : theme.colors.blue650};
    text-align: ${align ?? 'right'};
    align-items: ${alignItems ?? 'start'};
    font-size: 12px;
    ${
      variant === GridTableVariant.Regular ?
        `
          background-color: ${isInsideEvenRow ? theme.colors.blue900 : theme.colors.blue650};
          margin-left: ${hasLeftSpacing ? '5px' : 'initial'};
          margin-right: ${hasRightSpacing ? '5px' : 'initial'};
          padding: 7.5px 10px;
        `
      : ''
    }

    ${
      variant === GridTableVariant.Minimal ?
        `
      max-height: ${MINIMAL_ROW_HEIGHT_PX}px;
      flex-direction: row;
      justify-content: ${alignItems ?? 'space-evenly'} ;
      align-items: center;
    `
      : ''
    }
  `}
`;

export const EmptyGridTableCell = styled.div<EmptyGridTableCellProps>`
  ${({align, alignItems, isInsideEvenRow, theme}) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${REGULAR_ROW_HEIGHT_PX}px;
    background-color: ${isInsideEvenRow ? theme.colors.blue900 : theme.colors.blue650};
    text-align: ${align ?? 'right'};
    align-items: ${alignItems ?? 'start'};
    font-size: 12px;
    background-color: ${isInsideEvenRow ? theme.colors.blue900 : theme.colors.blue650};
    padding: 7.5px 10px;
  `}
`;

export const GridTableVirtualItemsWrapper = styled.div`
  width: 100%;
  position: relative;
  grid-column: 1 / -1;
`;
