import {Dayjs} from 'dayjs';

import {fieldTitles} from 'constants/messages/labels';
import {order} from 'constants/messages/order';

import {Rate} from './rfq';
import {Amount, Asset, AssetPair, Side} from './api';

export enum CounterParty {
  ThirdParty = 'ThirdParty',
  Intercompany = 'Intercompany',
}

export enum MarketType {
  Market = 'Market',
  Limit = 'Limit',
}

export interface NewOrder {
  side: Side;
  marketType: MarketType;
  isNonAggressor: boolean;
  baseAsset: Asset;
  secondAsset: Asset;
  baseAmount: Amount;
  interestRate?: number;
  forwardPoints?: number;
  spotRange: number;
  counterparty: string;
  validUntil: Dayjs;
  maturityTime: Dayjs;
  initialRate: number;
}

export interface OrderFormState {
  side: Side;
  marketType: MarketType;
  isNonAggressorOnly: boolean;
  baseAmount?: number;
  secondAmount?: number;
  initialRate: number;
  rate: Rate | undefined;
  spotRangeBigFig: number;
  validUntil: string;
  marketCloseTime: string;
  maturity: string;
  counterparty: CounterParty;
  assetPair: AssetPair;
}
export const getOrderFormMessage = (statusCode: number) => {
  switch (statusCode) {
    case 201:
      return 'Order successfully registered with Matching Node.';
    case 202:
      return 'Order sent to the Matching Node for registration.';
    case 400:
      return 'Order validation failed. Invalid parameters.';
    case 500:
    default:
      return 'Service Unavailable.';
  }
};

export const getOrderFormMessageType = (statusCode: number) => {
  switch (statusCode) {
    case 201:
      return 'success';
    case 202:
      return 'warning';
    case 400:
    case 500:
    default:
      return 'error';
  }
};

export const ConfirmationStepLabels = {
  type: order.orderType,
  isNonAggressorOnly: fieldTitles.nonAggressorOnly,
  isNonAggressor: fieldTitles.nonAggressor,
  isNonAggressorOnlyNegative: order.isNonAggressorOnlyNegativeNo,
  baseAmt: fieldTitles.baseAmt,
  repoAmt: fieldTitles.repoAmt,
  minSecondAmount: fieldTitles.minSecondAmt,
  maxSecondAmount: fieldTitles.maxSecondAmt,
  initialRate: fieldTitles.initialRate,
  implYield: fieldTitles.impYield,
  interestSide: fieldTitles.interestSide,
  fwdPts: fieldTitles.fwdPts,
  rate: fieldTitles.rate,
  spotRange: fieldTitles.spotRange,
  securitiesAmount: fieldTitles.securitiesAmt,
  secondAmount: fieldTitles.secondAmt,
  earmarkAmount: fieldTitles.earmarkAmount,
  validUntil: fieldTitles.validUntil,
  entity: fieldTitles.entity,
  counterparty: fieldTitles.counterparty,
  maturity: fieldTitles.maturity,
  economicsTabHeader: order.economicsTabHeader,
};
