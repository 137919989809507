import {useState} from 'react';

import {useIntl} from 'react-intl';

import {useUser} from 'contexts/auth-context';
import {getRFC2253ValueByKey} from 'utils/utils';
import {showToast} from 'utils/ToastUtils';
import {convertQuantity, displayAmountWithCode, To, validateAmount} from 'utils/AmountUtils';

import {updateLimit} from 'api/api';

import {AxiosError} from 'axios';

import {LimitType} from 'types/api';

import TableRow from 'utils/TableUtils';

import {Scope} from 'constants/permission-maps';

import {orderFormLimits} from 'constants/orderForm';
import {fieldTitles} from 'constants/messages/labels';

import AmountField from 'ui/AmountField';
import Button from 'ui/Button';
import Popup from 'ui/Popup';

import {Bottom, StyledTable} from 'containers/RFQPopup/styles';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {ChangeLimitPopupProps} from './types';
import {SButtonsContainer} from './styles';

const ChangeLimitPopup = ({limit, onClose}: ChangeLimitPopupProps) => {
  const userWithMeta = useUser();
  const {formatMessage} = useIntl();
  const canAdjustLimits = hasPermission({
    permissions: getRolePermissions(userWithMeta.user.role),
    scopes: [Scope.AdjustCreditLimits],
  });
  const oldLimit = convertQuantity(limit.amount.quantity, To.View);
  const [newLimit, setNewLimit] = useState<number>(oldLimit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const secondAmountValidation = validateAmount({
    value: newLimit,
    min: 0,
    max: 99999,
    decimals: orderFormLimits.amount.dec,
  });
  const isDisabled = !canAdjustLimits || isLoading;
  const isInvalid = !secondAmountValidation.valid;
  const onAdjustLimit = () => {
    const amountInMillions = convertQuantity(newLimit, To.Store);

    setIsLoading(true);
    updateLimit(limit.counterparty, amountInMillions, limit.limitType)
      .then(() => {
        showToast('Limit was adjusted', 'success');
        setIsLoading(false);
        onClose();
      })
      .catch((e: AxiosError) => {
        showToast('Failed to to adjust limit', 'error');
        console.error(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEditClick = () => {
    setShowConfirm(false);
  };

  const counterpartyRow = (
    <TableRow key='counterparty' title={formatMessage(fieldTitles.counterparty)}>
      {getRFC2253ValueByKey(limit.counterparty, 'O')}
    </TableRow>
  );

  const labels = () => {
    switch (limit.limitType) {
      case LimitType.Settlement:
        return {
          oldLimitLabel: formatMessage(fieldTitles.oldSettlementRiskLimit),
          newLimitLabel: formatMessage(fieldTitles.newSettlementRiskLimit),
        };
      case LimitType.Mtm:
        return {
          oldLimitLabel: formatMessage(fieldTitles.oldMtmRiskLimit),
          newLimitLabel: formatMessage(fieldTitles.newMtmRiskLimit),
        };
      case LimitType.ClobMatches:
        return {
          oldLimitLabel: formatMessage(fieldTitles.oldClobMatchesLimit),
          newLimitLabel: formatMessage(fieldTitles.newClobMatchesLimit),
        };
      default:
        return {
          oldLimitLabel: formatMessage(fieldTitles.oldClobMatchesLimit),
          newLimitLabel: formatMessage(fieldTitles.newClobMatchesLimit),
        };
    }
  };

  const oldLimitRow = (
    <TableRow key='oldLimit' title={labels().oldLimitLabel} rightTestId='old-limit-value'>
      {displayAmountWithCode(limit.amount, false, true)}
    </TableRow>
  );
  const newLimitRow = (
    <TableRow key='newLimit' title={labels().newLimitLabel} rightTestId='new-limit-value'>{`$${newLimit}m`}</TableRow>
  );
  const creditRiskLimitRow = (
    <TableRow key='creditRisk' title={labels().newLimitLabel}>
      <AmountField
        data-testid='change-limit'
        name='secondAmount'
        prefix='$'
        unit='m'
        value={newLimit}
        min={0}
        decimals={orderFormLimits.amount.dec}
        onChange={setNewLimit}
        invalid={isInvalid}
        disabled={isDisabled || showConfirm}
        warning={oldLimit === newLimit}
        required
      />
    </TableRow>
  );

  const newNumberOrEditField = showConfirm ? newLimitRow : creditRiskLimitRow;
  const tableRows = [counterpartyRow, oldLimitRow, newNumberOrEditField];
  const confirmOrChangeTitle =
    showConfirm ? fieldTitles.confirmNewLimitPopupHeader : fieldTitles.changeLimitPopupHeader;

  return (
    <Popup title={formatMessage(confirmOrChangeTitle)} onClose={onClose} width='500px'>
      <StyledTable className='limit-popup' elements={tableRows} />
      <Bottom>
        <>
          {!showConfirm && (
            <Button
              data-testid='update-limit-btn'
              buttonStyle='primary'
              onClick={() => setShowConfirm(true)}
              disabled={isDisabled || isInvalid || oldLimit === newLimit}
            >
              Update Limit
            </Button>
          )}
          {showConfirm && (
            <SButtonsContainer>
              <Button data-testid='edit-limit-btn' width='45%' buttonStyle='grey' onClick={onEditClick}>
                Edit
              </Button>
              <Button loading={isLoading} data-testid='confirm-limit-btn' width='45%' onClick={onAdjustLimit}>
                Confirm
              </Button>
            </SButtonsContainer>
          )}
        </>
      </Bottom>
    </Popup>
  );
};

export default ChangeLimitPopup;
