import {CapacityAdjustment} from 'types/api';

import {QueryClient} from '@tanstack/react-query';

import isEqual from 'lodash/isEqual';

import unionWith from 'lodash/unionWith';

import {loadCapacityAdjustments} from '../api';
import {unifiedEventStream} from '../unifiedEventStream';
import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['RealTimeCapacityAdjustments'];

interface Data {
  capacityAdjustments: CapacityAdjustment[];
  lastEventId: string;
}

function subscribeForCapacityAdjustments(client: QueryClient, lastEventId?: string) {
  const handler = (adjustment: CapacityAdjustment) => {
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) return;
      return {
        ...data,
        capacityAdjustments: unionWith(data.capacityAdjustments, [adjustment], isEqual),
      };
    });
    return {type: ''};
  };

  return unifiedEventStream.subscribe('capacity-adjustment', handler, {lastEventId: lastEventId || '0,0'});
}

export const useRealTimeCapacityAdjustmentsQuery = () => {
  const {data} = useSubscriptionQuery(
    {
      queryKey: QUERY_KEY,
      queryFn: async () => {
        const response = await loadCapacityAdjustments();
        const capacityAdjustments = [...response.data].reverse();
        const lastEventId = response.headers['x-last-event-id'];
        return {data: {capacityAdjustments}, lastEventId};
      },
    },
    subscribeForCapacityAdjustments
  );

  return {
    capacityAdjustments: data?.capacityAdjustments || [],
  };
};
