import {map, min, max, zipObject} from 'lodash/fp';
import {Label, LineChart, XAxis, YAxis, Line, ReferenceLine, Legend, Tooltip} from 'recharts';
import dayjs from 'dayjs';
import {useMemo} from 'react';

import {Asset, AssetWithTimezone} from 'types/api';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayAsset} from 'utils/AssetUtils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {appTimezone} from 'utils/setupDayjs';

import {useRealTimeCapacityQuery} from 'api/hooks/useRealTimeCapacityQuery';

import colors from 'constants/colors';
import {useAggregatedCapacities} from 'containers/CapacityManagement/hooks/useAggregatedCapacities';
import useTicks from 'containers/CapacityManagement/hooks/useTicks';
import {CapacityAggregationType} from 'containers/CapacityManagement/types';
import {ResponsiveBox} from 'components/ResponsiveContainer/ResponsiveBox';

import {getAssetColors} from './utils';

const tickFormatter = (val: number, i: number, tz: string) => (i === 0 ? 'NOW' : displayDate(dayjs(val), 'HH:mm', tz));
const tooltipLabelFormatter = (val: number) => displayDate(dayjs(val), 'ddd, DD MMM YYYY HH:mm' as DateFormat);

interface Props {
  mainAsset: Asset;
  comparedAsset?: Asset;
  capacityType?: CapacityAggregationType;
  assetsWithTimezones: AssetWithTimezone[];
}

const CapacityChart = ({mainAsset, comparedAsset, capacityType, assetsWithTimezones}: Props) => {
  const mainAssetWithTimezone = assetsWithTimezones.find(({asset}) => asset.currency === mainAsset.currency);
  const comparedAssetWithTimezone = assetsWithTimezones.find(({asset}) => asset.currency === comparedAsset?.currency);

  const {balances} = useRealTimeCapacityQuery({withEquivalent: false});
  const {mainAssetData, comparedAssetData} = useAggregatedCapacities({
    mainAsset,
    comparedAsset,
    capacityType,
  });

  const ticks = useTicks();

  const lineColors = useMemo(() => getAssetColors(map('asset', balances)), [balances]);
  const colorMap = zipObject(
    map(({asset}) => displayAsset(asset), balances),
    lineColors
  );

  const assetsDataset = [...mainAssetData, ...(comparedAssetData || [])];

  const dataMax = max(map('v', assetsDataset)) ?? 10;
  const upperBound = Math.ceil(dataMax / 10) * 10;

  const dataMin = min(map('v', assetsDataset)) ?? 0;
  // Always use 0 as lower bound
  const lowerBound = capacityType === 'earmark' ? 0 : Math.min(Math.ceil((dataMin - 10) / 10) * 10, 0);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: mainAsset.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const yAxisFormatter = (val: number) => `${currencyFormatter.format(val)}m`;
  const tooltipValueFormatter = (val: number) =>
    displayAmountWithCode({asset: mainAsset, quantity: val * 1_000_000 * 100}, false, true);

  const getLocalTimezoneLabel = () =>
    assetsWithTimezones.find(({timezoneLabeled}) => timezoneLabeled.id === appTimezone)?.timezoneLabeled.label ??
    appTimezone.split('/')[1];

  const shouldShowComparedAxis =
    comparedAssetWithTimezone &&
    comparedAssetWithTimezone.timezoneLabeled.id !== mainAssetWithTimezone?.timezoneLabeled.id &&
    comparedAssetWithTimezone.timezoneLabeled.label !== getLocalTimezoneLabel();

  if (!mainAssetData.length) {
    return <div style={{background: colors.blue900, height: '100%'}} />;
  }

  return (
    <ResponsiveBox>
      <LineChart margin={{top: 30, right: 30, left: 20, bottom: 20}} style={{background: colors.blue900, fontSize: 12}}>
        <XAxis
          dataKey='t'
          type='number'
          domain={[ticks[0], 'dataMax']}
          allowDataOverflow
          allowDuplicatedCategory={false}
          tickFormatter={(value: number, index: number) => tickFormatter(value, index, appTimezone)}
          tickSize={12}
          stroke={colors.gray500}
          tick={{stroke: colors.gray400}}
          ticks={ticks}
          offset={28}
          xAxisId={0}
        >
          <Label offset={30} position='left' stroke={colors.gray400}>
            {getLocalTimezoneLabel()}
          </Label>
        </XAxis>
        {mainAssetWithTimezone && mainAssetWithTimezone.timezoneLabeled.id !== appTimezone && (
          <XAxis
            dataKey='t'
            type='number'
            domain={[ticks[0], 'dataMax']}
            allowDataOverflow
            allowDuplicatedCategory={false}
            tickFormatter={(value: number, index: number) =>
              tickFormatter(value, index, mainAssetWithTimezone.timezoneLabeled.id)
            }
            tickSize={12}
            stroke={colors.gray500}
            tick={{stroke: colors.gray400}}
            ticks={ticks}
            offset={28}
            xAxisId={1}
          >
            <Label offset={30} position='left' stroke={colors.gray400}>
              {mainAssetWithTimezone.timezoneLabeled.label}
            </Label>
          </XAxis>
        )}
        {comparedAsset && comparedAssetWithTimezone && shouldShowComparedAxis && (
          <XAxis
            dataKey='t'
            type='number'
            domain={[ticks[0], 'dataMax']}
            allowDataOverflow
            allowDuplicatedCategory={false}
            tickFormatter={(value: number, index: number) =>
              tickFormatter(value, index, comparedAssetWithTimezone.timezoneLabeled.id)
            }
            tickSize={12}
            stroke={colors.gray500}
            tick={{stroke: colors.gray400}}
            ticks={ticks}
            offset={28}
            xAxisId={2}
          >
            <Label offset={30} position='left' stroke={colors.gray400}>
              {comparedAssetWithTimezone.timezoneLabeled.label}
            </Label>
          </XAxis>
        )}
        <YAxis
          domain={[dataMin < 0 ? dataMin - 10 : 0, 'dataMax + 10']}
          padding={{top: 10, bottom: 10}}
          ticks={[lowerBound, 0, upperBound]}
          tick={{stroke: colors.gray400}}
          tickFormatter={yAxisFormatter}
        />

        <ReferenceLine x={dayjs().tz().endOf('day').valueOf()} stroke={colors.gray500}>
          <Label position='bottom' stroke={colors.gray400} offset={-14}>
            T+1
          </Label>
        </ReferenceLine>
        <ReferenceLine x={dayjs().tz().add(1, 'day').endOf('day').valueOf()} stroke={colors.gray500}>
          <Label position='bottom' stroke={colors.gray400} offset={-14}>
            T+2
          </Label>
        </ReferenceLine>
        <ReferenceLine y={0} stroke={colors.gray500} />

        <Legend align='right' iconType='plainline' />
        <Tooltip
          filterNull={false}
          contentStyle={{
            background: colors.gray500,
            border: 0,
          }}
          // @ts-expect-error recharts types are wrong. number => string is not a valid formatter for some reason.
          formatter={tooltipValueFormatter}
          labelFormatter={tooltipLabelFormatter}
        />

        {renderCapacityChartLine(mainAsset, colorMap[displayAsset(mainAsset)] ?? colors.yellow350, mainAssetData, 'v')}

        {!!(comparedAsset && comparedAssetData) ?
          renderCapacityChartLine(
            comparedAsset,
            colorMap[displayAsset(comparedAsset)] ?? colors.blue300,
            comparedAssetData.map(({t, v}) => ({t, v2: v})),
            'v2'
          )
        : null}
      </LineChart>
    </ResponsiveBox>
  );
};

// Why it's not a component:
// https://github.com/recharts/recharts/issues/412
const renderCapacityChartLine = (asset: Asset, lineColor: string, data: Array<{t: number}>, dataKey: string) => (
  <Line
    type='stepAfter'
    data={data}
    dataKey={dataKey}
    name={displayAsset(asset)}
    stroke={lineColor}
    dot={{fill: lineColor}}
    isAnimationActive={false}
    connectNulls
  />
);

export default CapacityChart;
