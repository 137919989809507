import React, {useCallback} from 'react';

import {RequesteeAndQuote, RFQRequest} from 'types/api';
import {RFQRequestWithRequestee} from 'types/rfq';
import {isRFQRequestExpired} from 'utils/RFQUtils';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import Button from 'ui/Button';

import {CancelRequestConfirmation} from 'components/popups/CancelRequestConfirmation';

import PermissionsGate from '../PermissionGate';

interface PropsRfqCancelAllButton {
  request: RFQRequest;
}

export const RFQCancelAllButton: React.FC<PropsRfqCancelAllButton> = (props: PropsRfqCancelAllButton) => {
  const {request} = props;
  const {openModal, closeModal} = useModal();
  const isRequestor = !request.isReceived;
  const isExpired = isRFQRequestExpired(request);
  const isCancellable =
    !isExpired &&
    request.requesteesAndQuotes.some(
      (requesteeAndQuote: RequesteeAndQuote) => !requesteeAndQuote.expired && requesteeAndQuote.stage.name === 'Started'
    );

  const closeDetails = useCallback(() => closeModal(), []);
  const showConfirmationPopup = useCallback(
    (r: RFQRequestWithRequestee) => {
      openModal({modal: CancelRequestConfirmation, props: {requestId: r.request.id}});
    },
    [openModal]
  );
  const showCancelRequestConfirmation = useCallback((): void => {
    closeDetails();
    showConfirmationPopup({request});
  }, [request, closeDetails, showConfirmationPopup]);

  if (isRequestor && isCancellable) {
    return (
      <PermissionsGate scopes={[Scope.CanUseRfqActions]}>
        <Button onClick={showCancelRequestConfirmation} buttonStyle='secondary' className='reject-button'>
          Cancel All
        </Button>
      </PermissionsGate>
    );
  }

  return <span />;
};
