import React from 'react';

import {useIntl} from 'react-intl';
import dayjs from 'dayjs';
import {useUser} from 'contexts/auth-context';
import {displayForwardPoints, displayInterestRate, displayParty} from 'utils/utils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {createRowFromArray} from 'utils/TableUtils';
import {createSingleQuoteWithRequest, shouldShowQuoteValidUntil} from 'utils/RFQUtils';
import {RequesteeAndQuote, RFQQuoteWithRequest} from 'types/api';
import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import {fieldTitles} from 'constants/messages/labels';

import Table from 'ui/Table';
import {Row} from 'ui/Table/types';
import Countdown from 'ui/Countdown';
import FieldTitle from 'ui/FieldTitle';

import {AcceptQuoteConfirmation} from 'components/popups/AcceptQuoteConfirmation';

import {RejectQuoteConfirmation} from 'components/popups/RejectQuoteConfirmation';

import {Header, HeaderColumn, Content, TableValue, TableBottom} from './styles';
import {PropsRequestAndQuotes} from './types';
import {getRolePermissions, hasPermission} from '../PermissionGate/utils';
import {Container} from '../RFQRequestReceived/styles';
import {AcceptRejectOrStatus} from './components/AcceptRejectOrStatus';

// Default type, length and empty cell for this table
const createQuotesRow = createRowFromArray<RequesteeAndQuote>(4, <TableValue>-</TableValue>);

export const RequestAndQuotes: React.FC<PropsRequestAndQuotes> = ({
  requestData,
  requestData: {requestorSide, tradedAmount, baseAsset, secondAsset, requesteesAndQuotes, validUntil},
  showForwardPoints,
}) => {
  const user = useUser();
  const {openModal} = useModal();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base: baseAsset, second: secondAsset});
  const isRepo = isRepoPair({base: baseAsset, second: secondAsset});
  const {formatMessage} = useIntl();
  const canTrade = hasPermission({
    permissions: getRolePermissions(user?.user.role),
    scopes: [Scope.CanTrade],
  });

  const onAccept = (requesteeAndQuote: RequesteeAndQuote): void => {
    const quoteAndRequestToConfirm: RFQQuoteWithRequest = createSingleQuoteWithRequest(
      requesteeAndQuote,
      requestData,
      user.legalEntities
    );
    openModal({modal: AcceptQuoteConfirmation, props: {quoteId: quoteAndRequestToConfirm.quote.id}});
  };
  const onReject = (requesteeAndQuote: RequesteeAndQuote): void => {
    const quoteAndRequestToReject: RFQQuoteWithRequest = createSingleQuoteWithRequest(
      requesteeAndQuote,
      requestData,
      user.legalEntities
    );
    openModal({modal: RejectQuoteConfirmation, props: {quoteId: quoteAndRequestToReject.quote.id}});
  };

  const counterpartyRow: Row = createQuotesRow(
    [<FieldTitle key='counterparty'>{formatMessage(fieldTitles.counterparty)}</FieldTitle>],
    requesteesAndQuotes,
    (requesteeAndQuote: RequesteeAndQuote, index: number) => (
      <TableValue data-testid='counterparty-value' key={index}>
        {displayParty(requesteeAndQuote.requestee)}
      </TableValue>
    )
  );

  // TODO: Display 'Rate' label if isRepo
  const interestRateRow: Row = createQuotesRow(
    [
      isRepo ? <FieldTitle>{formatMessage(fieldTitles.rate)}</FieldTitle>
      : showForwardPoints ? <FieldTitle>{formatMessage(fieldTitles.fwdPts)}</FieldTitle>
      : <FieldTitle>{formatMessage(fieldTitles.impYield)}</FieldTitle>,
    ],
    requesteesAndQuotes,
    (requesteeAndQuote: RequesteeAndQuote, index: number) => (
      <TableValue data-testid='yield-value' key={index}>
        {requesteeAndQuote.quote ?
          !isRepo && showForwardPoints ?
            displayForwardPoints(requesteeAndQuote.quote.forwardPoints)
          : displayInterestRate(requesteeAndQuote.quote.interestRate, '-')
        : '-'}
      </TableValue>
    )
  );
  const amountRow: Row = createQuotesRow(
    [<FieldTitle key='amount'>{formatMessage(fieldTitles.amount)}</FieldTitle>],
    requesteesAndQuotes,
    (requesteeAndQuote: RequesteeAndQuote, index: number) => (
      <TableValue data-testid='amount-value' key={index}>
        {requesteeAndQuote.quote ? displayAmountWithCode(requesteeAndQuote.quote.tradedAmount, false, inMillions) : '-'}
      </TableValue>
    )
  );
  const remainingRow: Row = createQuotesRow(
    [<FieldTitle key='remaining'>{formatMessage(fieldTitles.remaining)}</FieldTitle>],
    requesteesAndQuotes,
    (requesteeAndQuote: RequesteeAndQuote, index: number) => (
      <TableValue key={index}>
        {requesteeAndQuote.quote ?
          <Countdown
            timeTarget={dayjs(requesteeAndQuote.quote.validUntil)}
            forceExpired={!shouldShowQuoteValidUntil(requesteeAndQuote)}
          />
        : '-'}
      </TableValue>
    )
  );
  const buttonsRow: Row = createQuotesRow(
    [''],
    requesteesAndQuotes,
    (requesteeAndQuote: RequesteeAndQuote, index: number) => (
      <TableBottom data-testid='quote-status-value' key={index}>
        {user.legalEntities.map(x => x.legalName).includes(requestData.requestor.legalName) &&
          AcceptRejectOrStatus({requesteeAndQuote, canTrade, onAccept, onReject})}
      </TableBottom>
    )
  );
  const rows: Row[] = [counterpartyRow, interestRateRow, amountRow, remainingRow, buttonsRow];

  return (
    <Container>
      <Header>
        <HeaderColumn data-testid='asset-pair-value'>
          {displayAssetPairWithSide({base: baseAsset, second: secondAsset}, requestorSide, true)}
        </HeaderColumn>
        <HeaderColumn data-testid='traded-amount-value'>
          {displayAmountWithCode(tradedAmount, false, inMillions)}
        </HeaderColumn>
        <HeaderColumn>
          <Countdown timeTarget={dayjs(validUntil)} />
        </HeaderColumn>
      </Header>
      <Content>
        <Table rows={rows} />
      </Content>
    </Container>
  );
};

export default RequestAndQuotes;
