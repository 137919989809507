import {defineMessages} from '@formatjs/intl';

export const request = defineMessages({
  requestSide: {
    id: 'app.field.requestSide',
    description: 'Field title, request side',
    defaultMessage: 'Request Side',
  },
  yourSide: {
    id: 'app.field.yourSide',
    description: 'Field title, your side',
    defaultMessage: 'Your Side',
  },
  economicsTabHeader: {
    id: 'app.rfq.economicsTabHeader',
    description: 'RFQ economics, economicsTabHeader.',
    defaultMessage: 'Expected trade economics, assuming full and immediate quote acceptance:',
  },
  isNonAggressorOnlyNegativeNo: {
    id: 'app.rfq.isNonAggressorOnlyNegativeNo',
    description: 'RFQ economics, isNonAggressorOnlyNegative No.',
    defaultMessage: 'No',
  },
  isNonAggressorOnlyNegativeYes: {
    id: 'app.rfq.isNonAggressorOnlyNegativeYes',
    description: 'RFQ economics, isNonAggressorOnlyNegative Yes.',
    defaultMessage: 'Yes',
  },
  nonAggressor: {
    id: 'app.rfq.nonAggressor',
    description: 'RFQ economics, non-aggressor.',
    defaultMessage: 'Non-Aggressor',
  },
});
