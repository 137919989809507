import {PERMISSIONS, Role, Scope} from 'constants/permission-maps';

interface HasPermissionProps {
  permissions: Scope[];
  scopes: Scope[];
}
export const hasPermission = ({permissions, scopes}: HasPermissionProps) => {
  const scopesMap: {[key in Scope]?: boolean} = {};
  scopes.forEach(scope => {
    scopesMap[scope] = true;
  });

  return permissions.some(permission => scopesMap[permission]);
};

export const getRolePermissions = (role?: Role) => (role && PERMISSIONS[role] ? PERMISSIONS[role] : []);
