import {displayAmountWithCode, displaySmallAmount} from 'utils/AmountUtils';
import {convertMills, displayInterestRate, getRFC2253ValueByKey} from 'utils/utils';
import {isNil} from 'lodash';
import {displaySide, displayTwoAssetsAsPair} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {useIntl} from 'react-intl';
import dayjs from 'dayjs';
import {
  calculateOutrightRate,
  convertOutgoingCashflowSign,
  displayLateSettlementFeeStatus,
  getCashflowByAsset,
  getTradeStatus,
} from 'utils/TradeUtils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {TradeStatus} from 'types/api';

import {appTzShortName} from 'utils/setupDayjs';

import {fieldTitles, tradeLateSettlementFeesStatuses, tradeStatuses} from 'constants/messages/labels';

import FieldValue from 'ui/FieldValue';
import FieldTitle from 'ui/FieldTitle';

import {SCol, SContainer, SRow} from 'ui/Layout/styles';
import {DetailsTableProps} from 'components/popups/TradePopup/types';

const EmptyCell = () => (
  <>
    <SCol width={{md: 1 / 4}} className='details-popup__label' />
    <SCol width={{md: 1 / 4}} className='details-popup__value' />
  </>
);

const DetailsTable = ({trade, markToMarket}: DetailsTableProps) => {
  const tradeEarlyMatured =
    !isNil(trade.farLeg.actualSettlementTime) &&
    dayjs(trade.farLeg.actualSettlementTime).isBefore(trade.farLeg.scheduledTime);
  const {formatMessage} = useIntl();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({
    base: trade.baseAmount.asset,
    second: trade.secondAmount.asset,
  });
  const isFxSwaps = trade.tradeType === 'FxSwapTradeType';
  const lateSettlementFeeStatus = displayLateSettlementFeeStatus(trade);
  const shouldShowMtm = !!(
    markToMarket && [TradeStatus.Outstanding, TradeStatus.Upcoming, TradeStatus.Processing].includes(trade.status)
  );

  return (
    <SRow className='details-popup__table'>
      <SCol width={{md: 1}}>
        <SContainer>
          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.finteumTradeId)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-id-value'>{trade.id}</FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.status)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-status-value'>
                {formatMessage(tradeStatuses[getTradeStatus(trade)])}
                {lateSettlementFeeStatus && (
                  <div>{formatMessage(tradeLateSettlementFeesStatuses[lateSettlementFeeStatus])}</div>
                )}
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.market)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-market-value'>
                {displayTwoAssetsAsPair(trade.baseAmount.asset, trade.secondAmount.asset)}
              </FieldValue>
            </SCol>

            {isFxSwaps ?
              <>
                <SCol width={{md: 1 / 4}} className='details-popup__label'>
                  <FieldTitle>{formatMessage(fieldTitles.tradeDetailsFwdPts)}</FieldTitle>
                </SCol>
                <SCol width={{md: 1 / 4}} className='details-popup__value'>
                  <FieldValue data-testid='trade-fwd-pts-value'>{trade.forwardPointsString}</FieldValue>
                </SCol>
              </>
            : <EmptyCell />}
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.side)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-side-value'>{displaySide(trade.side, !isFxSwaps, false)}</FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>
                {isFxSwaps ? formatMessage(fieldTitles.tradeDetialsImpYield) : formatMessage(fieldTitles.rate)}
              </FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-imp-yield-value'>{displayInterestRate(trade.interestRate)}</FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>Near Leg {trade.baseAmount.asset.currency}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='nearleg-trade-base-amt-value'>
                {displaySmallAmount(
                  getCashflowByAsset(convertOutgoingCashflowSign(trade['nearLeg']), trade.baseAmount.asset)
                )}
              </FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.interestAmt)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-interest-amt-value'>
                {displaySmallAmount(trade.ourInterestAmount)}
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>Near Leg {trade.secondAmount.asset.currency}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='nearleg-trade-second-amt-value'>
                {displaySmallAmount(
                  getCashflowByAsset(convertOutgoingCashflowSign(trade['nearLeg']), trade.secondAmount.asset)
                )}
              </FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.interestSide)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue>
                <span className='interest-side'>
                  {trade.ourInterestAmount.quantity > 0 ? 'Receiving interest' : 'Paying interest'}
                </span>
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>Far Leg {trade.baseAmount.asset.currency}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='farleg-trade-base-amt-value'>
                {displaySmallAmount(
                  getCashflowByAsset(convertOutgoingCashflowSign(trade['farLeg']), trade.baseAmount.asset)
                )}
              </FieldValue>
            </SCol>

            {shouldShowMtm ?
              <>
                <SCol width={{md: 1 / 4}} className='details-popup__label'>
                  <FieldTitle>{formatMessage(fieldTitles.markToMarket)}</FieldTitle>
                </SCol>
                <SCol width={{md: 1 / 4}} className='details-popup__value'>
                  <FieldValue>{displayAmountWithCode(markToMarket.amount, true, inMillions)}</FieldValue>
                </SCol>
              </>
            : <EmptyCell />}
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>Far Leg {trade.secondAmount.asset.currency}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='farleg-trade-second-amt-value'>
                {displaySmallAmount(
                  getCashflowByAsset(convertOutgoingCashflowSign(trade['farLeg']), trade.secondAmount.asset)
                )}
              </FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.tradeCreatedAt)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue>{displayDate(trade.createdAt, DateFormat.DateTime)}</FieldValue>
            </SCol>
          </SRow>

          <SRow>
            {trade.relatedOrder && (
              <>
                <SCol width={{md: 1 / 4}} className='details-popup__label'>
                  <FieldTitle>{formatMessage(fieldTitles.orderCreated)}</FieldTitle>
                </SCol>
                <SCol width={{md: 1 / 4}} className='details-popup__value'>
                  <FieldValue>{displayDate(trade.relatedOrder.createdAt, 'HH:mm:ss')}</FieldValue>
                </SCol>
              </>
            )}

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.tradeMtfExecutionTime)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-execution-date'>
                {displayDate(trade?.executionDetails?.executedAt, 'DD MMM YYYY HH:mm:ss')}
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            {isFxSwaps ?
              <>
                <SCol width={{md: 1 / 4}} className='details-popup__label'>
                  <FieldTitle>{formatMessage(fieldTitles.spotRate)}</FieldTitle>
                </SCol>
                <SCol width={{md: 1 / 4}} className='details-popup__value'>
                  <FieldValue data-testid='trade-spot-rate-value'>{trade.spotRate}</FieldValue>
                </SCol>
              </>
            : <EmptyCell />}

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.tradeMtfConfirmationTime)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue>{displayDate(trade?.executionDetails?.confirmedAt, DateFormat.DateTime)}</FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.outrightRate)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              {trade.spotRate && trade.forwardPoints ?
                calculateOutrightRate(trade.spotRate, trade.forwardPoints)
              : '--'}
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>
                {isFxSwaps ? formatMessage(fieldTitles.nearLegTime) : formatMessage(fieldTitles.onLegTime)}
              </FieldTitle>
            </SCol>
            <SCol
              width={{md: 1 / 4}}
              className='details-popup__value d-flex justify-content-between align-items-center'
            >
              <FieldValue data-testid='near-leg-date-value'>
                {displayDate(trade.nearLeg.scheduledTime, 'DD MMM YYYY HH:mm:ss')} {appTzShortName}
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.entity)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-entity-value' uppercase={false}>
                {getRFC2253ValueByKey(trade.entity, 'O')}
              </FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>
                {isFxSwaps ? formatMessage(fieldTitles.farLegTime) : formatMessage(fieldTitles.offLegTime)}
              </FieldTitle>
            </SCol>
            <SCol
              width={{md: 1 / 4}}
              className='details-popup__value  d-flex justify-content-between align-items-center'
            >
              <FieldValue data-testid='far-leg-date-value'>
                {displayDate(trade.farLeg.scheduledTime, 'DD MMM YYYY HH:mm:ss')} {appTzShortName}
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.tradeDetailsCounterparty)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-counterparty-value' uppercase={false}>
                {getRFC2253ValueByKey(trade.counterparty, 'O')}
              </FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.actMatTime)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='early-maturity-date-value'>
                {tradeEarlyMatured &&
                  displayDate(trade.farLeg.actualSettlementTime, 'DD MMM YYYY HH:mm:ss', undefined, 'N/A')}
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.aggressorType)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='aggressor-mode-value'>{trade.isAggressor ? 'Agg' : 'Non-Agg'}</FieldValue>
            </SCol>

            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.expTradeDur)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='exp-trade-duration-value'>
                {convertMills(trade.expectedTradeDuration)}
              </FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>MTF For Execution</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trade-execution-for'>{trade.executionDetails?.mtfName ?? '--'}</FieldValue>
            </SCol>

            {shouldShowMtm ?
              <>
                <SCol width={{md: 1 / 4}} className='details-popup__label'>
                  <FieldTitle>{formatMessage(fieldTitles.actTradeDur)}</FieldTitle>
                </SCol>
                <SCol width={{md: 1 / 4}} className='details-popup__value'>
                  <FieldValue>
                    {tradeEarlyMatured && trade.actualTradeDuration ? convertMills(trade.actualTradeDuration) : 'N/A'}
                  </FieldValue>
                </SCol>
              </>
            : <EmptyCell />}
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.brokerTradeReference)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              {trade.executionDetails?.brokerTradeReference ?? '--'}
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.reportTrackingNumber)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              {trade.executionDetails?.reportTrackingNumber ?? '--'}
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>MTF Trade Id 1 (UTI)</FieldTitle>
            </SCol>
            <SCol width={{md: 3 / 4}} className='details-popup__value'>
              <FieldValue>{trade.executionDetails?.nearLeg.uti ?? '--'}</FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>MTF Trade Id 2 (UTI)</FieldTitle>
            </SCol>
            <SCol width={{md: 3 / 4}} className='details-popup__value'>
              <FieldValue>{trade.executionDetails?.farLeg.uti ?? '--'}</FieldValue>
            </SCol>
          </SRow>

          <SRow>
            <SCol width={{md: 1 / 4}} className='details-popup__label'>
              <FieldTitle>{formatMessage(fieldTitles.traderInfo)}</FieldTitle>
            </SCol>
            <SCol width={{md: 1 / 4}} className='details-popup__value'>
              <FieldValue data-testid='trader-email-value' uppercase={false}>
                {trade.userId}
              </FieldValue>
            </SCol>
          </SRow>
        </SContainer>
      </SCol>
    </SRow>
  );
};

export default DetailsTable;
