import {Trade} from 'types/api';

import {getFixmlUrlForTrade, getPreMatchDetailsUrlForTrade} from 'api/api';

import {useState} from 'react';

import {downloadFileByUrl} from 'utils/utils';

import {ReactComponent as IconDownloadFile} from 'assets/icons/download.svg';

import {Scope} from 'constants/permission-maps';
import {SCol} from 'ui/Layout/styles';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import PermissionsGate from 'components/PermissionGate';
import {FileIcon} from 'components/popups/DragAndDropPopup/components/FileIcon';

import {SDownloadIconWrapper} from '../../styles';
import {SupportedFileTypes} from '../../types';

interface PrematchDetailsProps {
  tradeId: Trade['id'];
  onClick?: () => void;
  canDownloadFIXML: boolean;
}
export const FileDownloads = ({tradeId, canDownloadFIXML}: PrematchDetailsProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onDownloadTradeDetails = (fileType: SupportedFileTypes): void => {
    switch (fileType) {
      case SupportedFileTypes.CSV: {
        downloadFileByUrl(getPreMatchDetailsUrlForTrade(tradeId));
        break;
      }
      case SupportedFileTypes.FIXML: {
        downloadFileByUrl(getFixmlUrlForTrade(tradeId));
        break;
      }
      default: {
        console.error('Unsupported file type: ' + fileType);
      }
    }
  };

  return (
    <PermissionsGate scopes={[Scope.ViewBlotter]}>
      <SCol width='auto' className='pre-match-details'>
        <div className='status-icon-container'>
          <div className='icon-box'>
            <SDownloadIconWrapper data-testid='trade-download' onClick={() => setShowTooltip(prev => !prev)}>
              <IconDownloadFile />
            </SDownloadIconWrapper>
            {showTooltip && (
              <div className='status-tooltip'>
                <span className='tooltip-action-container'>
                  <TooltipWrapper
                    message="Incomplete Execution details. Can't download trade details"
                    disabled={canDownloadFIXML}
                    position='top'
                    maxWidth={300}
                    styles={{
                      height: '90%',
                    }}
                  >
                    <FileIcon
                      disabled={!canDownloadFIXML}
                      fileType={SupportedFileTypes.FIXML}
                      onClick={onDownloadTradeDetails}
                    />
                  </TooltipWrapper>
                  <FileIcon fileType={SupportedFileTypes.CSV} onClick={onDownloadTradeDetails} />
                </span>
              </div>
            )}
          </div>
        </div>
      </SCol>
    </PermissionsGate>
  );
};
