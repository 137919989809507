import {useMemo, useEffect} from 'react';
import * as yup from 'yup';

import dayjs from 'dayjs';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {AssetPair, RFQRequest, SpotRate} from 'types/api';

import {
  To,
  admissibleForwardPointRange,
  convertQuantity,
  currencySymbol,
  getErrorTextForInvalidRange,
} from 'utils/AmountUtils';
import {currencyPairToAssetPair, isAssetPairEqual, isRepoPair} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import useReferenceDataStore from 'stores/useReferenceDataStore';
import {ReferenceData} from 'types/layout';
import {Rate} from 'types/rfq';

import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';
import {isValidDecimal} from 'utils/utils';

import {NumLimit, orderFormLimits} from 'constants/orderForm';
import {REPO_HAIRCUT} from 'containers/OrderForm/types';

const VALIDFOR_ERROR = 'Valid for has to be integer between 1 and 30 minutes';
const IMP_YIELD_ERROR = 'Please enter a number between -999bp and 999bp';

export default function useCreateQuoteForm({
  baseAsset,
  secondAsset,
  nearLegTime,
  maturityTime,
  tradedAmount,
}: Pick<RFQRequest, 'baseAsset' | 'secondAsset' | 'nearLegTime' | 'maturityTime' | 'tradedAmount'>) {
  const {exchangeRates} = useRealTimeExchangeRatesQuery();
  const {referenceData} = useReferenceDataStore();
  const assetPair = useMemo(() => ({base: baseAsset, second: secondAsset}), [baseAsset, secondAsset]);
  const isRepo = isRepoPair(assetPair);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);
  const maxTradedAmount = convertQuantity(tradedAmount.quantity, To.View, true, inMillions ? 1_000_000_00 : 1_00);
  const pair = {base: baseAsset, second: secondAsset};

  const quoteTradedAmountLimits: NumLimit = {
    dec: orderFormLimits.amount.dec,
    min: orderFormLimits.amount.min,
    max: maxTradedAmount,
  };

  const QUOTE_TRADED_AMOUNT_ERROR = getErrorTextForInvalidRange(
    quoteTradedAmountLimits,
    currencySymbol(baseAsset.currency),
    'm'
  );

  const schema = useMemo(() => {
    return yup.object({
      assetPair: yup.mixed<AssetPair>(),
      exchangeRate: yup.mixed<Omit<SpotRate, 'currencyPair'>>(),
      validFor: yup
        .number()
        .typeError(VALIDFOR_ERROR)
        .required(VALIDFOR_ERROR)
        .test('max', VALIDFOR_ERROR, value => {
          if (!value || value < 1 || value % 1 !== 0 || value > 30) return false;
          return true;
        }),
      quoteTradedAmount: yup
        .number()
        .typeError(QUOTE_TRADED_AMOUNT_ERROR)
        .required(QUOTE_TRADED_AMOUNT_ERROR)
        .min(orderFormLimits.amount.min, QUOTE_TRADED_AMOUNT_ERROR)
        .max(maxTradedAmount, QUOTE_TRADED_AMOUNT_ERROR)
        .test(
          'is-decimal',
          `Please enter up to ${orderFormLimits.amount.dec} digit after the decimal point`,
          value => value % 1 === 0 || new RegExp(`^\\d*\\.?\\d{0,${orderFormLimits.amount.dec}}$`).test(`${value}`)
        ),
      rate: yup.mixed<Rate>().test('is-valid', (rate, ctx) => {
        if (rate === undefined || rate.value === undefined)
          return new yup.ValidationError(
            referenceData === ReferenceData.ImpliedYield ? IMP_YIELD_ERROR : 'Fwd Pts has to be number',
            undefined,
            'rate'
          );
        if (rate.referenceData === ReferenceData.ImpliedYield) {
          if (typeof rate.value !== 'number') return new yup.ValidationError(IMP_YIELD_ERROR, undefined, 'rate');
          if (rate.value < orderFormLimits.rate.min || rate.value > orderFormLimits.rate.max)
            return new yup.ValidationError(IMP_YIELD_ERROR, undefined, 'rate');
          if (!isValidDecimal(rate.value, orderFormLimits.rate.dec))
            return new yup.ValidationError(
              `Please enter up to ${orderFormLimits.rate.dec} digit after the decimal point`,
              undefined,
              'rate'
            );

          return true;
        }

        if (rate.referenceData === ReferenceData.ForwardPoints) {
          if (typeof rate.value !== 'number')
            return new yup.ValidationError('Fwd Pts has to be number', undefined, 'rate');
          if (!maturityTime) return true;
          if (!ctx.parent.exchangeRate)
            return new yup.ValidationError(
              `We cannot fetch exchange rate, please refresh page and try again`,
              undefined,
              'rate'
            );

          const validationRules = admissibleForwardPointRange(
            orderFormLimits.rate.min,
            orderFormLimits.rate.max,
            typeof nearLegTime === 'string' ? dayjs() : nearLegTime || dayjs(),
            dayjs(maturityTime),
            ctx.parent.exchangeRate.value
          );

          if (!new RegExp(`^\-?\\d*\\.?\\d{0,${validationRules.dec}}$`).test(`${rate.value}`))
            return new yup.ValidationError(
              `Please enter up to ${validationRules.dec} digit after the decimal point`,
              undefined,
              'rate'
            );
          if (rate.value < validationRules.min || rate.value > validationRules.max)
            return new yup.ValidationError(
              `Please enter a number between ${validationRules.min}pts and ${validationRules.max}pts`,
              undefined,
              'rate'
            );

          return true;
        }
      }),
    });
  }, [QUOTE_TRADED_AMOUNT_ERROR, maturityTime, maxTradedAmount, nearLegTime, referenceData]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      validFor: 2,
      quoteTradedAmount: convertQuantity(tradedAmount.quantity, To.View, true, inMillions ? 1_000_000_00 : 1_00),
      rate: undefined,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    let foundRate: Omit<SpotRate, 'currencyPair'> | undefined = exchangeRates.find(
      er => pair && isAssetPairEqual(currencyPairToAssetPair(er.pair), pair)
    )?.rate;
    if (isRepo)
      foundRate = {
        value: 1.0 / (1.0 - REPO_HAIRCUT),
        timestamp: dayjs().valueOf(),
      };

    methods.setValue('exchangeRate', foundRate);
  }, [JSON.stringify(pair), isRepo, JSON.stringify(exchangeRates)]);

  useEffect(() => {
    methods.setValue('assetPair', pair);
  }, [JSON.stringify(pair)]);

  return {methods, schema};
}

export type CreateQuoteFormValues = yup.InferType<ReturnType<typeof useCreateQuoteForm>['schema']>;
