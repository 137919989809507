import styled from 'styled-components';

import {StyledTabProps} from './types';

export const Container = styled.div<{isNarrow?: boolean}>`
  display: flex;
  width: ${p => (p.isNarrow ? '66%' : '100%')};
`;

export const StyledTab = styled.span<StyledTabProps>`
  flex-grow: 1;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 1em 0;
  text-transform: uppercase;
  background-color: ${props => (props.active ? props.theme.colors.blue900 : props.theme.colors.blue650)};
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
`;
