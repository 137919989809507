import {
  AssetPair,
  EarlyCancellationReason,
  FlagType,
  Notification,
  NotificationType,
  ReceivedQuoteCancelledPayload,
  ReceivedQuoteExpiredPayload,
  ReceivedQuotePayload,
  ReceivedRequestPayload,
  Side,
  User,
} from 'types/api';
import {getRFC2253ValueByKey} from 'utils/utils';

import {displayAmount, displayAmountWithCode, displayAmountWithUnit} from 'utils/AmountUtils';
import {assetCode, displayAssetPair, displayAssetPairWithSide, displayTwoAssetsAsPair} from 'utils/AssetUtils';
import {NegotiationStatusText} from 'utils/RFQUtils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {ShorthandSide} from 'constants/commonEnums';

import {NotificationData, NotificationStorageKey} from './types';

export const getValidFor = (n: NotificationData) => {
  switch (n.type) {
    case 'ReceivedRequest': {
      const p = n.payload as ReceivedRequestPayload;
      return p.requestValidUntil;
    }
    case 'ReceivedQuote': {
      const p = n.payload as ReceivedQuotePayload;
      return p.quoteValidUntil;
    }
    default:
      return undefined;
  }
};

/**
 * List of noties, whose only purpose is updating status of already existing base notification.
 * If this map has Notification's type - it won't be displayed in NotificationTable
 */
export const notificationTypeToStatus: {[key in NotificationType]?: NegotiationStatusText} = {
  ReceivedRequestCancelled: 'Request Cancelled',
  ReceivedRequestExpired: 'Request Expired',
  ReceivedRequestRejected: 'Request Rejected',
  ReceivedQuoteAccepted: 'Quote Accepted',
  ReceivedQuoteCancelled: 'Quote Cancelled',
  ReceivedQuoteExpired: 'Quote Expired',
  ReceivedQuoteRejected: 'Quote Rejected',
  SentQuoteAccepted: 'Quote Accepted',
  SentQuoteCancelled: 'Quote Cancelled',
  SentQuoteExpired: 'Quote Expired',
  SentQuoteRejected: 'Quote Rejected',
  ReceivedLateFeeRequestWithdrawn: 'Late Fee Withdrawn',
  ReceivedLateFeeRequestAccepted: 'Late Fee Accepted',
  ReceivedLateFeeRequestRejected: 'Late Fee Rejected',
  ReceivedTradeUnwindProposalCancelled: 'Unwind Cancelled',
  ReceivedTradeUnwindProposalAccepted: 'Unwind Accepted',
  ReceivedTradeUnwindProposalRejected: 'Unwind Rejected',
  ReceivedTradeUnwindProposalExpired: 'Unwind Expired',
  SentTradeUnwindProposalExpired: 'Unwind Expired',
  ReceivedEarlyMaturityRequestExpired: 'EMR Expired',
  ReceivedEarlyMaturityRequestAccepted: 'EMR Accepted',
  ReceivedEarlyMaturityRequestRejected: 'EMR Rejected',
  SentEarlyMaturityRequestExpired: 'EMR Expired',
  ReceivedTradeAnnulProposalAccepted: 'Annul Accepted',
  ReceivedTradeAnnulProposalRejected: 'Annul Rejected',
  MtMLimitBreachResolved: 'Resolved',
};

export const parseReadableNotifications = (notifications: NotificationData[]): NotificationData[] =>
  notifications.filter(notification => notification.message && !notificationTypeToStatus[notification.type]);

export const formatPartyName = (shortNames: Map<string, string>) => (party: string) =>
  shortNames.get(party) ?? getRFC2253ValueByKey(party, 'O')?.slice(0, 4) ?? party;

export const formatPartyNameWithAssetPair =
  (shortNames: Map<string, string>) => (party: string, assetPair: AssetPair) =>
    `${formatPartyName(shortNames)(party)} ${displayAssetPair(assetPair)}`;

export const formatMessagePrefix =
  (shortNames: Map<string, string>) => (party: string, assetPair: AssetPair, side: Side) =>
    `${formatPartyName(shortNames)(party)}: ${displayAssetPairWithSide(assetPair, side, true)}`;

/**
 * Creates a matching message to provided notification. Used by "Notification Center" and "Events tab"
 *
 * @param shortNames Used to decode counterpaty's legalName to readable name
 * @param n notification to be displayed as message
 */
export const displayMessage = (
  shortNames: Map<string, string>,
  n: Notification,
  inMillions: boolean
): string | undefined => {
  const messagePrefix = formatMessagePrefix(shortNames);
  const partyName = formatPartyName(shortNames);
  const partyNameWithAsset = formatPartyNameWithAssetPair(shortNames);

  switch (n.notificationType) {
    case 'RequestSent': {
      const p = n.payload;
      return `${messagePrefix(p.quoter ?? '--', p.assetPair, p.ourSide)} - RFQ Request Sent`;
    }
    case 'ReceivedRequest': {
      const p = n.payload;
      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - RFQ Request Received`;
    }
    case 'ReceivedTradeUnwindProposalCancelled': {
      const p = n.payload;
      return `Trade Unwind Request withdrawn: ${partyNameWithAsset(p.otherParty, p.assetPair)} ${displayAmountWithCode(
        p.amount,
        false,
        inMillions
      )}`;
    }
    case 'SentTradeUnwindProposalRejected': {
      const p = n.payload;
      return `Trade Unwind Request rejected: ${partyNameWithAsset(p.otherParty, p.assetPair)} ${displayAmountWithCode(
        p.amount,
        false,
        inMillions
      )}`;
    }
    case 'SentTradeUnwindProposalAccepted': {
      const p = n.payload;
      return `Trade Unwind Request accepted: ${partyNameWithAsset(p.otherParty, p.assetPair)} ${displayAmountWithCode(
        p.amount,
        false,
        inMillions
      )}`;
    }
    case 'ReceivedTradeUnwindProposal': {
      const p = n.payload;
      return `Trade Unwind Request requested: ${partyNameWithAsset(p.otherParty, p.assetPair)} ${displayAmountWithCode(
        p.amount,
        false,
        inMillions
      )}`;
    }
    case 'ReceivedTradeUnwindProposalExpired': {
      const p = n.payload;
      return `Trade Unwind Request (Received) Expired: ${partyNameWithAsset(
        p.otherParty,
        p.assetPair
      )} ${displayAmountWithCode(p.amount, false, inMillions)}`;
    }
    case 'ReceivedTradeUnwindProposalAccepted': {
      const p = n.payload;
      return `Received trade unwind proposal from ${partyName(p.otherParty)} was accepted`;
    }
    case 'ReceivedTradeUnwindProposalRejected': {
      const p = n.payload;
      return `Received trade unwind proposal from ${partyName(p.otherParty)} was rejected`;
    }
    case 'SentTradeUnwindProposalExpired': {
      const p = n.payload;
      return `Trade Unwind Request (Sent) Expired: ${partyNameWithAsset(
        p.otherParty,
        p.assetPair
      )} ${displayAmountWithCode(p.amount, false, inMillions)}`;
    }
    case 'ReceivedQuote': {
      const p = n.payload;
      return `${messagePrefix(p.quoter, p.assetPair, p.ourSide)} - RFQ Quote Received`;
    }
    case 'ReceivedQuoteCancelled': {
      const p = n.payload as ReceivedQuoteCancelledPayload;
      return `${messagePrefix(p.quoter, p.assetPair, p.ourSide)} - RFQ Quote (Received) Cancelled`;
    }
    case 'ReceivedQuoteExpired': {
      const p = n.payload as ReceivedQuoteExpiredPayload;
      return `${messagePrefix(p.quoter, p.assetPair, p.ourSide)} - RFQ Quote (Received) Expired`;
    }
    case 'ReceivedRequestCancelled': {
      const p = n.payload;
      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - RFQ Request (Received) Cancelled`;
    }
    case 'ReceivedRequestExpired': {
      const p = n.payload;
      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - RFQ Request (Received) Expired`;
    }
    case 'SentQuote': {
      const p = n.payload;
      return `${partyName(p.requestor)} ${displayAssetPair(p.assetPair)} - RFQ Quote Sent`;
    }
    case 'SentQuoteAccepted': {
      const p = n.payload;
      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - RFQ Quote (Sent) Accepted`;
    }
    case 'SentQuoteExpired': {
      const p = n.payload;
      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - RFQ Quote (Sent) Expired`;
    }
    case 'SentQuoteRejected': {
      const p = n.payload;
      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - RFQ Quote (Sent) Rejected`;
    }
    case 'SentQuoteFailed': {
      const p = n.payload;
      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - RFQ Quote Creation Failed`;
    }
    case 'SentRequestRejected': {
      const p = n.payload;
      return `${messagePrefix(p.quoter, p.assetPair, p.ourSide)} - RFQ Request (Sent) Rejected`;
    }
    case 'SentRequestExpired': {
      const p = n.payload;
      return `${messagePrefix(p.quoter, p.assetPair, p.ourSide)} - RFQ Request (Sent) Expired`;
    }
    case 'SentRequestFailed': {
      const p = n.payload;
      return `${messagePrefix(p.quoter, p.assetPair, p.ourSide)} - RFQ Request Creation Failed`;
    }
    case 'UnableToCommunicateWithCounterparty': {
      const p = n.payload;
      return `${messagePrefix(p.quoter, p.assetPair, p.ourSide)} - unable to connect to node 
      of ${partyName(p.quoter)} - RFQ Request Failed`;
    }
    case 'CapacityAdjustmentScheduled': {
      const p = n.payload;
      if (p.isASAP) {
        return `${assetCode(p.amount.asset)} Capacity has been changed by ${displayAmountWithCode(
          p.amount,
          false,
          true
        )}`;
      }
      return `Projected ${assetCode(p.amount.asset)} Capacity has been changed by ${displayAmountWithCode(
        p.amount,
        false,
        true
      )} from ${displayDate(p.from, 'DD MMM YY')} - ${displayDate(p.from, 'HH:mm')}`;
    }
    case 'ReceivedEarlyMaturityRequest': {
      const p = n.payload;
      return `${messagePrefix(p.otherParty, p.assetPair, p.ourSide)} - Early Mat. Req. Received`;
    }
    case 'ReceivedEarlyMaturityRequestCancelled': {
      const p = n.payload;
      return `${messagePrefix(p.otherParty, p.assetPair, p.ourSide)} - Early Mat. Req. Withdrawn`;
    }
    case 'SentEarlyMaturityRequestRejected': {
      const p = n.payload;
      return `${messagePrefix(p.otherParty, p.assetPair, p.ourSide)} - Early Mat. Req. Rejected`;
    }
    case 'SentEarlyMaturityRequestAccepted': {
      const p = n.payload;
      return `${messagePrefix(p.otherParty, p.assetPair, p.ourSide)} - Early Mat. Req. Accepted`;
    }
    case 'ReceivedEarlyMaturityRequestExpired': {
      const p = n.payload;
      return `${messagePrefix(p.otherParty, p.assetPair, p.ourSide)} - Early Mat. Req. Expired`;
    }
    case 'ReceivedEarlyMaturityRequestAccepted': {
      const p = n.payload;
      return `Received Early Maturity request from ${partyName(p.otherParty)} was accepted`;
    }
    case 'ReceivedEarlyMaturityRequestRejected': {
      const p = n.payload;
      return `Received Early Maturity request from ${partyName(p.otherParty)} was rejected`;
    }
    case 'SentEarlyMaturityRequestExpired': {
      const p = n.payload;
      return `${messagePrefix(p.otherParty, p.assetPair, p.ourSide)} - Early Mat. Req. Expired`;
    }
    case 'OrderMatched': {
      const p = n.payload;
      return `${displayTwoAssetsAsPair(p.assetPair.base, p.assetPair.second)} - ${ShorthandSide[p.side]}
             - CLOB Order Matched - ${displayAmount(p.unmatchedAmount, inMillions)}/${displayAmountWithUnit(
               p.amount,
               inMillions
             )} remaining`;
    }
    case 'OrderExpired': {
      const p = n.payload;
      return `${displayTwoAssetsAsPair(p.assetPair.base, p.assetPair.second)} - ${
        ShorthandSide[p.side]
      } - CLOB Order Expired`;
    }
    case 'OrderLimitReached': {
      const p = n.payload;
      return `${displayTwoAssetsAsPair(p.assetPair.base, p.assetPair.second)} - ${ShorthandSide[p.side]}
        - CLOB Order Failed - CLOB Limit reached`;
    }
    case 'OrderBulkExpired': {
      const p = n.payload;
      const message = p.orderIds.length > 1 ? `${p.orderIds.length} CLOB Orders Expired` : 'CLOB Order Expired';
      return `${displayTwoAssetsAsPair(p.assetPair.base, p.assetPair.second)} - ${message}`;
    }
    case 'OrderSpotRateOutOfRange': {
      const p = n.payload;
      return `${displayTwoAssetsAsPair(p.assetPair.base, p.assetPair.second)} - ${ShorthandSide[p.side]}
             - CLOB Order Failed - Spot rate out of defined range`;
    }
    case 'MarketOrderNotFulfilled': {
      const p = n.payload;
      return `${displayTwoAssetsAsPair(p.assetPair.base, p.assetPair.second)} - ${ShorthandSide[p.side]}
             - CLOB Market Order Failed - No suitable matches`;
    }
    case 'ReceivedLateFeeRequest': {
      const p = n.payload;
      return `Late settlement fee request received: ${partyName(p.requestor)} ${displayTwoAssetsAsPair(
        p.assetPair.base,
        p.assetPair.second
      )} ${displayAmountWithCode(p.amount, false, inMillions)} ${
        p.nearLegTime && displayDate(p.nearLegTime, DateFormat.DateTime)
      }`;
    }
    case 'ReceivedLateFeeRequestAccepted': {
      const p = n.payload;
      return `Received late settlement fee request from ${partyName(p.requestor)} was accepted`;
    }
    case 'ReceivedLateFeeRequestRejected': {
      const p = n.payload;
      return `Received late settlement fee request from ${partyName(p.requestor)} was rejected`;
    }
    case 'SentLateFeeRequestAccepted': {
      const p = n.payload;
      return `Your late settlement fee request has been accepted by ${partyName(p.recipient)}`;
    }
    case 'SentLateFeeRequestRejected': {
      const p = n.payload;
      return `Your late settlement fee request has been rejected by ${partyName(p.recipient)}`;
    }
    case 'ReceivedTradeAnnulProposal': {
      const p = n.payload;
      return `Trade annul proposal received: ${partyName(p.otherParty)} ${displayTwoAssetsAsPair(
        p.assetPair.base,
        p.assetPair.second
      )} ${displayAmountWithCode(p.amount, false, inMillions)}`;
    }
    case 'ReceivedTradeAnnulProposalCancelled': {
      const p = n.payload;
      return `Trade annul proposal cancelled: ${partyName(p.otherParty)} ${displayTwoAssetsAsPair(
        p.assetPair.base,
        p.assetPair.second
      )} ${displayAmountWithCode(p.amount, false, inMillions)}`;
    }
    case 'ReceivedTradeAnnulProposalExpired': {
      const p = n.payload;
      return `Trade annul proposal expired: ${partyName(p.otherParty)} ${displayTwoAssetsAsPair(
        p.assetPair.base,
        p.assetPair.second
      )} ${displayAmountWithCode(p.amount, false, inMillions)}`;
    }
    case 'ReceivedTradeAnnulProposalAccepted': {
      const p = n.payload;
      return `Received trade annul proposal from ${partyName(p.otherParty)} was accepted`;
    }
    case 'ReceivedTradeAnnulProposalRejected': {
      const p = n.payload;
      return `Received trade annul proposal from ${partyName(p.otherParty)} was rejected`;
    }
    case 'SentTradeAnnulProposalAccepted': {
      const p = n.payload;
      return `Your trade annul proposal has been accepted by ${partyName(p.otherParty)}`;
    }
    case 'SentTradeAnnulProposalExpired': {
      const p = n.payload;
      return `Your trade annul proposal has expired: ${partyName(p.otherParty)} ${displayTwoAssetsAsPair(
        p.assetPair.base,
        p.assetPair.second
      )} ${displayAmountWithCode(p.amount, false, inMillions)}`;
    }
    case 'SentTradeAnnulProposalRejected': {
      const p = n.payload;
      return `Your trade annul proposal has been rejected by ${partyName(p.otherParty)}`;
    }
    case 'MtMLimitBreached': {
      const p = n.payload;
      return `Limit breach: ${partyName(p.counterparty)}: Exposure ${displayAmountWithCode(
        p.exposure,
        false,
        true
      )} vs Limit ${displayAmountWithCode(p.limit, false, true)}`;
    }
    case 'CollectSignaturesTimeout': {
      const p = n.payload;
      const counterpartiesMsg = p.counterparties.map(c => partyName(c)).join(', ');
      return `Failed to communicate with node of ${
        p.counterparties.length > 1 ? 'Counterparties:' : 'Counterparty:'
      } ${counterpartiesMsg}. Try again later.`;
    }
    case 'CashFlowFlaggedAs': {
      const p = n.payload;
      return `${partyName(p.otherParty)}: ${displayTwoAssetsAsPair(p.assetPair.base, p.assetPair.second)} - ${
        ShorthandSide[p.ourSide]
      } - ${displayAmountWithCode(p.amount, false, inMillions)} - ${cashFlowFlagToVerbose(
        p.flagEventType,
        p.flagValue
      )}`;
    }
    case 'ExecutionDetailsMismatched': {
      const p = n.payload;
      return `Execution details don't match, UTI ${p.uti}. See TP ICAP email`;
    }
    case 'SentRequestCancelledEarly': {
      const p = n.payload;
      return `${messagePrefix(
        p.quoter,
        p.assetPair,
        p.ourSide
      )} - RFQ Request Cancelled - ${stringifyEarlyCancellationReason(p.reason)}`;
    }
    case 'SettlementRiskLimitBreached': {
      const p = n.payload;

      return `${messagePrefix(p.requestor, p.assetPair, p.ourSide)} - ${
        p.remainingLimit.quantity === 0 ?
          'Quote cancelled, not enough settlement risk'
        : 'Quote exceeds available risk limits'
      }`;
    }
    default:
      return undefined;
  }
};

function stringifyEarlyCancellationReason(reason: EarlyCancellationReason) {
  switch (reason) {
    case 'NotEnoughCounterpartyLimit':
      return 'Not enough settlement risk limit';
  }
}

export const cashFlowFlagToVerbose = (flag: FlagType, flagValue: boolean) => {
  const messages: Record<FlagType, string> = {
    [FlagType.NearLegSent]: `Near Leg ${flagValue ? 'Flagged as Sent' : 'Sent Flag Withdrawn'}`,
    [FlagType.NearLegReceived]: `Near Leg ${flagValue ? 'Flagged as Received' : 'Received Flag Withdrawn'}`,
    [FlagType.NearLegNonreceipt]: `Near Leg ${flagValue ? 'Flagged as Non-Receipt' : 'Non-Receipt Flag Withdrawn'}`,
    [FlagType.FarLegSent]: `Far Leg ${flagValue ? 'Flagged as Sent' : 'Sent Flag Withdrawn'}`,
    [FlagType.FarLegReceived]: `Far Leg ${flagValue ? 'Flagged as Received' : 'Received Flag Withdrawn'}`,
    [FlagType.FarLegNonreceipt]: `Far Leg ${flagValue ? 'Flagged as Non-Receipt' : 'Non-Receipt Flag Withdrawn'}`,
  };

  return messages[flag] || '';
};

// timestamp of the most recent notification which was marked as read.
export const getKeyWithUser = (key: NotificationStorageKey, user: User): string => `${key}-${user.email}`;

export const isNotificationCenterPage = () => window.location.pathname === '/notifications';

export const storageGetLastReadTime = (user: User): number =>
  getLocalStorageTime(NotificationStorageKey.LAST_NOTIFICATION_READ_TIME, user);

export const storageSetLastReadTime = (time: string, user: User): void =>
  setLocalStorageTime(NotificationStorageKey.LAST_NOTIFICATION_READ_TIME, user, time);

export const storageGetLastRenderedTime = (user: User): number =>
  getLocalStorageTime(NotificationStorageKey.LAST_NOTIFICATION_RENDERED_TIME, user);

export const storageSetLastRenderedTime = (time: string, user: User): void =>
  setLocalStorageTime(NotificationStorageKey.LAST_NOTIFICATION_RENDERED_TIME, user, time);

export const storageGetLastCenterOpenedTime = (user: User): number =>
  getLocalStorageTime(NotificationStorageKey.LAST_NOTIFICATION_CENTER_OPENED_TIME, user);
export const storageSetLastCenterOpenedTime = (time: string, user: User): void =>
  setLocalStorageTime(NotificationStorageKey.LAST_NOTIFICATION_CENTER_OPENED_TIME, user, time);

const getLocalStorageTime = (timeRelatedKey: NotificationStorageKey, user: User) => {
  const key = getKeyWithUser(timeRelatedKey, user);
  return parseInt(localStorage.getItem(key) ?? '0') || 0;
};
const setLocalStorageTime = (timeRelatedKey: NotificationStorageKey, user: User, time: string) => {
  const key = getKeyWithUser(timeRelatedKey, user);
  localStorage.setItem(key, time);
};
