import {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {CapacityAdjustment} from 'types/api';

import {useRealTimeCapacityAdjustmentsQuery} from 'api/hooks/useRealTimeCapacityAdjustmentsQuery';

import GridTable, {GridTableVariant} from 'ui/GridTable';

import {getCapacityAdjustmentsTableColumns} from './columns';

const CapacityAdjustmentsTable = () => {
  const {formatMessage} = useIntl();
  const {capacityAdjustments} = useRealTimeCapacityAdjustmentsQuery();

  const columns = useMemo(() => getCapacityAdjustmentsTableColumns(formatMessage), [formatMessage]);

  const getRowId = useCallback(
    (adjustment: CapacityAdjustment) => `${adjustment.createdAt}-${adjustment.from}-${adjustment.to}`,
    []
  );

  return (
    <div data-testid='capacity-adjustments-table' className='table-container'>
      <GridTable
        defaultSorted={[{id: 'createdAt', desc: true}]}
        columns={columns}
        data={capacityAdjustments}
        variant={GridTableVariant.Minimal}
        sortable
        getRowId={getRowId}
      />
    </div>
  );
};

export default CapacityAdjustmentsTable;
