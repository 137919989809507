import {useQuery, useQueryClient} from '@tanstack/react-query';
import {createMarketSnapshotsEventSource} from 'api/api';
import {useCallback, useEffect, useState} from 'react';
import useBestRateStore from 'stores/useBestRateStore';
import {MarketSnapshot, QueryParamsType} from 'types/api';
import {isAssetPairEqual} from 'utils/AssetUtils';
import {updateMarketDepth} from 'utils/MarketUtils';

const queryKeyGenerator = (queryParams: QueryParamsType) => ['MarketSnapshots', queryParams];

export default function useMarketSnapshotsQuery() {
  const {rateAmount, rateToShow} = useBestRateStore();
  const queryParams: QueryParamsType = updateMarketDepth(rateToShow, rateAmount);
  const [loading, setLoading] = useState(true);
  const {data, isLoading} = useQuery<MarketSnapshot[]>({
    queryKey: queryKeyGenerator(queryParams),
    initialData: [],
  });
  const client = useQueryClient();

  const onNewMarketSnapshot = useCallback(
    ({data}: MessageEvent<MarketSnapshot>) => {
      setLoading(false);
      client.setQueryData<MarketSnapshot[]>(queryKeyGenerator(queryParams), prevMarketSnapshots => [
        ...(prevMarketSnapshots?.filter(snapshot => !isAssetPairEqual(snapshot.assetPair, data.assetPair)) || []),
        data,
      ]);
    },
    [queryParams, client]
  );

  useEffect(() => {
    const eventSource = createMarketSnapshotsEventSource(
      {
        lastEventId: undefined,
        onmessage: data => {
          onNewMarketSnapshot(data);
        },
      },
      queryParams
    );
    return () => {
      eventSource.close();
    };
  }, [JSON.stringify(queryParams)]);

  return {marketSnapshots: data, isLoading: isLoading || loading};
}
