import {assetCode, isAssetEqual} from 'utils/AssetUtils';
import {Amount} from 'types/api';

import {useRealTimeCapacityQuery} from 'api/hooks/useRealTimeCapacityQuery';

import {AvailableCapacityWarningProps} from '../AvailableCapacityWarning';

type Operation = 'Request' | 'Order' | 'Quote';

export const NO_WARNING = {
  message: '',
  tooltipMessage: '',
  show: false,
};

const getMessageByProjectedCapacity = (projectedCapacity: number, earmark: Amount): AvailableCapacityWarningProps => {
  if (projectedCapacity < 0) return NO_WARNING;

  return projectedCapacity - earmark.quantity < 0 ?
      {
        show: true,
        message: `This Request amount could lead to negative Available Capacity in ${assetCode(earmark.asset)}.`,
        tooltipMessage: `Your Available Capacity represents the amount of ${assetCode(
          earmark.asset
        )} your Balance Manager indicates you can safely swap out. Consider checking that the resulting trade would not negatively impact intraday liquidity usage in ${assetCode(
          earmark.asset
        )}.`,
      }
    : NO_WARNING;
};

const useAvailableCapacityWarning = (
  operation: Operation,
  earmarkAmount?: Amount,
  projectedCapacity?: number
): AvailableCapacityWarningProps => {
  const {balancesList: balances} = useRealTimeCapacityQuery({withEquivalent: false});
  if (!earmarkAmount) return NO_WARNING;

  const balance = balances.find(b => isAssetEqual(b.asset, earmarkAmount.asset));
  if (!balance) return NO_WARNING;
  if (projectedCapacity) return getMessageByProjectedCapacity(projectedCapacity, earmarkAmount);

  const isAlreadyNegative = balance.available.quantity < 0;
  if (isAlreadyNegative) return NO_WARNING;

  return {
    show: balance.available.quantity - earmarkAmount.quantity < 0,
    message: `This ${operation} amount could lead to negative Available Capacity in ${assetCode(earmarkAmount.asset)}.`,
    tooltipMessage: `Your Available Capacity represents the amount of ${assetCode(
      earmarkAmount.asset
    )} your Balance Manager indicates you can safely swap out. Consider checking that the resulting trade would not negatively impact intraday liquidity usage in ${assetCode(
      earmarkAmount.asset
    )}.`,
  };
};

export default useAvailableCapacityWarning;
