import {useRealTimeOrdersQuery} from 'api/hooks/useRealTimeOrdersQuery';
import dayjs from 'dayjs';

import {CapacityChange} from '../types';

interface Options {
  currentDate: string;
}

function useCapacityChangesFromOrders({currentDate}: Options) {
  const {orders} = useRealTimeOrdersQuery();

  return orders
    .filter(order => dayjs(order.data.validUntil).isAfter(currentDate) && ['Live', 'Pending'].includes(order.status))
    .flatMap(
      order =>
        [
          {
            asset: order.remainingEarmark.asset,
            at: dayjs(order.createdAt).valueOf(),
            by: -order.remainingEarmark.quantity,
            type: 'available',
          },
          {
            asset: order.remainingEarmark.asset,
            at: dayjs(order.maturity).valueOf(),
            by: order.remainingEarmark.quantity,
            type: 'available',
          },
          {
            asset: order.remainingEarmark.asset,
            at: dayjs(order.createdAt).valueOf(),
            by: order.remainingEarmark.quantity,
            type: 'earmark',
          },
          {
            asset: order.remainingEarmark.asset,
            at: dayjs(order.maturity).valueOf(),
            by: -order.remainingEarmark.quantity,
            type: 'earmark',
          },
        ] as CapacityChange[]
    );
}

export default useCapacityChangesFromOrders;
