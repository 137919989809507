import {useParams} from 'react-router-dom';
import {decodeAsset} from 'utils/AssetUtils';

export default function useStateAssets() {
  const {first, second} = useParams<{first: string; second: string}>();
  const baseAsset = decodeAsset(first!);
  const secondAsset = decodeAsset(second!);
  return {
    baseAsset,
    secondAsset,
  };
}
