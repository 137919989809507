import {Bubble} from './styles';

export interface Props {
  num: number;
}

const NotificationBubble = (props: Props) => (
  <Bubble className='notification-number'>{props.num < 100 ? props.num : '99+'}</Bubble>
);

export default NotificationBubble;
