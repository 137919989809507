import {Trade} from 'types/api';
import {getReasonText} from 'utils/RFQUtils';

interface Props {
  trade: Trade;
}

const AnnulTradeReasonsText = ({trade}: Props) => (
  <>
    {trade.tradeAnnul?.reasons.map(reason => (
      <p key={reason} data-testid='annul-trade-reasons-value'>
        {getReasonText(trade.side, reason, trade?.baseAmount.asset, trade?.secondAmount.asset)}
      </p>
    ))}
  </>
);

export default AnnulTradeReasonsText;
