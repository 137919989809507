import {useCallback} from 'react';

import {Order} from 'types/api';
import {unifiedEventStream} from 'api/unifiedEventStream';

import {QueryClient} from '@tanstack/react-query';

import unionBy from 'lodash/unionBy';

import {sortBlotterByCreatedAt} from 'utils/BlotterUtils';

import {getOrdersData} from '../api';
import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['RealTimeOrders'];

interface Data {
  orders: Order[];
  lastEventId: string;
}

function subscribeForOrders(client: QueryClient, lastEventId?: string) {
  const handler = (order: Order) => {
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) {
        return;
      }
      return {...data, orders: unionBy([order], data.orders, 'id').sort(sortBlotterByCreatedAt)};
    });
    return {type: ''};
  };

  return unifiedEventStream.subscribe('order', handler, {lastEventId: lastEventId || '0,0'});
}

export const useRealTimeOrdersQuery = () => {
  const {data, isLoading} = useSubscriptionQuery(
    {
      queryKey: QUERY_KEY,
      staleTime: 5 * 60 * 1000,
      queryFn: () =>
        getOrdersData().then(response => {
          const orders = [...response.data].reverse();
          const lastEventId = response.headers['x-last-event-id'];
          return {data: {orders}, lastEventId};
        }),
    },
    subscribeForOrders
  );

  const selectOrderById = useCallback((id: string) => data?.orders.find(order => order.id === id), [data]);

  return {
    orders: data?.orders || [],
    selectOrderById,
    isLoading,
  };
};
