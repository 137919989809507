import {Controller, ControllerProps} from 'react-hook-form';

import {ValidationResult} from 'utils/AmountUtils';
import {ReferenceData} from 'types/layout';

import useFormDisplayRate from 'utils/hooks/useDisplayRate';

import {NumLimit} from 'constants/orderForm';
import {Unit} from 'constants/commonEnums';
import AmountField from 'ui/AmountField';
import ImplYldMessage, {ImplYldMessageProps} from 'ui/Message/ImplYldMessage';
import ExpectedInterestMessage, {ExpectedInterestMessageProps} from 'components/ExpectedInterestMessage';

interface SFPFieldProps {
  disabled: boolean;
  value: number | undefined;
  onChange: (newValue: number) => void;
  range: NumLimit;
  implYldMessage?: ImplYldMessageProps;
  validationResult: ValidationResult;
  warning?: boolean;
  required?: boolean;
  highlightEmpty?: boolean;
  error?: string;
  helperProps?: ExpectedInterestMessageProps;
}

export interface AdditionalFieldProps {
  setIsImpliedYieldFieldTouched?: React.Dispatch<React.SetStateAction<boolean>>;
  flash?: boolean;
  'data-testid'?: string;
}
export const doubleCheckTooltip: string = 'Please double check before submitting';

export const FormSuggestedForwardPointsField = ({
  control,
  setIsImpliedYieldFieldTouched,
  flash,
  helperProps,
  ...props
}: Omit<ControllerProps, 'name' | 'render'> &
  AdditionalFieldProps &
  Omit<SFPFieldProps, 'value' | 'range' | 'validationResult' | 'onChange'>) => {
  const parseRate = useFormDisplayRate();
  return (
    <Controller
      render={({field: {onChange, value: rate}, fieldState: {error}}) => (
        <>
          <AmountField
            flash={flash}
            data-testid={props['data-testid']}
            disabled={props.disabled}
            value={parseRate(rate)}
            unit={Unit.PTS}
            onChange={newValue => {
              onChange({
                value: newValue,
                referenceData: ReferenceData.ForwardPoints,
              });
              if (setIsImpliedYieldFieldTouched) setIsImpliedYieldFieldTouched(true);
            }}
            invalid={!!error}
            step={0.001}
            tooltipPosition='left'
            tooltip={error?.message}
            warning={props.warning}
            highlightEmpty={props.highlightEmpty}
            required={props.required}
            forceValidated
          />
          {props.implYldMessage && <ImplYldMessage {...props.implYldMessage} />}
          {helperProps && <ExpectedInterestMessage {...helperProps} />}
        </>
      )}
      name='rate'
      control={control}
    />
  );
};
