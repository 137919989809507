import {useState} from 'react';
import {HistoryType} from 'types/blotter';

import {BlotterPosition} from 'types/layout';

import {ReactComponent as MinifiedIcon} from 'assets/icons/minified-blotter.svg';
import {ReactComponent as DefaultIcon} from 'assets/icons/default-blotter.svg';
import {ReactComponent as ExpandIcon} from 'assets/icons/expanded-blotter.svg';

import useBlotterPositionStore from 'stores/useBlotterPositionStore';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {Scope} from 'constants/permission-maps';

import PermissionsGate from 'components/PermissionGate';

import './Blotter.scss';
import {BlotterContainer, BlotterControls, TabSwitcher} from './styles';
import {BlotterTabsBadges} from './components/BlotterTabsBadges';
import {tabs} from './constants';

export const Blotter = () => {
  const [selectedBlotterTab, setSelectedBlotterTab] = useState(HistoryType.Trades);
  const {tradesRequiringAction} = useRealTimeTradesQuery();
  const {requestsRequiringAction} = useRealTimeRequestsQuery();
  const [blotterPosition, setBlotterPosition] = useBlotterPositionStore();

  const toggleMinified = () => setBlotterPosition(BlotterPosition.Minified);
  const toggleRegular = () => setBlotterPosition(BlotterPosition.Default);
  const toggleExpanded = () => setBlotterPosition(BlotterPosition.Expanded);

  const DataTableComponent = tabs[selectedBlotterTab ?? HistoryType.Trades];

  const blotterTabsBadges = {
    [HistoryType.Trades]: tradesRequiringAction,
    [HistoryType.Requests]: requestsRequiringAction,
  };

  return (
    <PermissionsGate scopes={[Scope.ViewBlotter]}>
      <BlotterContainer position={blotterPosition} data-testid='blotter'>
        <TabSwitcher>
          <BlotterControls data-testid='blotter-controls'>
            <div id='minify-btn' onClick={toggleMinified} className='control' role='button'>
              <MinifiedIcon />
            </div>
            <div id='default-btn' onClick={toggleRegular} className='control' role='button'>
              <DefaultIcon />
            </div>
            <div id='expand-btn' onClick={toggleExpanded} className='control' role='button'>
              <ExpandIcon />
            </div>
          </BlotterControls>

          {blotterPosition !== BlotterPosition.Minified && (
            <BlotterTabsBadges selectedBlotterTab={selectedBlotterTab} setSelectedBlotterTab={setSelectedBlotterTab} />
          )}
        </TabSwitcher>

        <DataTableComponent />
      </BlotterContainer>
    </PermissionsGate>
  );
};
