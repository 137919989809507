import styled from 'styled-components';

const FormLock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: space-evenly;
  backdrop-filter: blur(2px);
  z-index: 1;
  padding: 1rem;
`;

export default FormLock;
