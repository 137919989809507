import {useMemo} from 'react';
import {AmountAndEquivalent, BalanceWithEquivalent, CapacityProjectionUpdate} from 'types/api';

import {displayAmountWithCode} from 'utils/AmountUtils';
import {isAssetEqual, assetCode, displayAssetTitle} from 'utils/AssetUtils';

import useEquivCurrencyStore from 'stores/useEquivCurrencyStore';

import {EquivCurrency} from 'types/layout';

import {MANAGE_CAPACITY} from 'constants/paths';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import {Row} from 'ui/Table/types';
import {WarningIcon} from 'components/IconComponents/WarningIcon';

import {TableCurrencyContainer, TableCurrency, TableValue, Bold, Small} from '../styles';

export const useCapacityRows = (
  balances: BalanceWithEquivalent[],
  capacityProjections?: CapacityProjectionUpdate | null
) => {
  const [equivCurrency] = useEquivCurrencyStore();

  const isUsdEquiv = equivCurrency === EquivCurrency.UsdEquiv;

  const getEquivOrDefault = (b: AmountAndEquivalent) =>
    isUsdEquiv && b.equivalentAmount ? b.equivalentAmount : b.amount;

  const getDisplayedAmount = (amount: AmountAndEquivalent): string => {
    const displayedAmount = getEquivOrDefault(amount);
    const inMillions = Math.abs(displayedAmount.quantity) >= 1_000_000_00;
    return displayAmountWithCode(displayedAmount, false, inMillions);
  };

  const capacityRows = useMemo(
    () =>
      balances.map((balance: BalanceWithEquivalent) => {
        const isMarkedWithWarning = capacityProjections?.warnings.some(({asset}) => isAssetEqual(asset, balance.asset));

        return [
          <TableCurrencyContainer key={assetCode(balance.asset) + '-asset'}>
            <TableCurrency data-testid='available-capacity-currency'>{displayAssetTitle(balance.asset)}</TableCurrency>
            <TooltipWrapper
              message='Projected capacity is expected to become negative'
              position='right'
              styles={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isMarkedWithWarning && <WarningIcon />}
            </TooltipWrapper>
          </TableCurrencyContainer>,
          <TableValue data-testid='available-capacity-row-value' key={assetCode(balance.asset) + '-value'}>
            <Bold>{getDisplayedAmount(balance.available)}</Bold>
            {(isUsdEquiv || balance.asset.type === 'Securities') && <Small>EQUIV</Small>}
          </TableValue>,
        ];
      }),
    [balances, capacityProjections, isUsdEquiv]
  );

  const capacityLinks: string[] = useMemo(
    () => balances.map((balance: BalanceWithEquivalent) => MANAGE_CAPACITY.buildAbsolute({asset: balance.asset})),
    [balances]
  );

  const earmarkRows: Row[] = useMemo(
    () =>
      balances.map((balance: BalanceWithEquivalent) => [
        <TableCurrency key={assetCode(balance.asset) + '-asset'}>{displayAssetTitle(balance.asset)}</TableCurrency>,
        <TableValue data-testid='earmarked-capacity-row-value' key={assetCode(balance.asset) + '-value'}>
          <Bold>{getDisplayedAmount(balance.earmark)}</Bold>
          {(isUsdEquiv || balance.asset.type === 'Securities') && <Small>EQUIV</Small>}
        </TableValue>,
      ]),
    [balances, isUsdEquiv]
  );

  return {capacityRows, capacityLinks, earmarkRows};
};
