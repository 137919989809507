import {max, isEmpty} from 'lodash/fp';

import {useRealTimeCapacityQuery} from 'api/hooks/useRealTimeCapacityQuery';

import {Scope} from 'constants/permission-maps';
import BalanceTile from 'components/BalanceTile/BalanceTile';
import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import PermissionsGate from 'components/PermissionGate';

import {SBalancesContent, SBalancesView} from './styles';

export const BalancesView = () => {
  const {balances} = useRealTimeCapacityQuery({withEquivalent: true});

  const maxValue = max(balances.map(balance => balance.total.equivalentAmount?.quantity)) ?? 1;
  return (
    <SBalancesView data-testid='balances-view' fluid height={['auto', 'auto', 'inherit']}>
      <BoardHeader title='Real Time Capacity Viewer' />
      <PermissionsGate scopes={[Scope.ViewCapacity]}>
        <SBalancesContent>
          {!isEmpty(balances) &&
            balances.map((balance, index) => {
              const chartWidth: number = (balance.total.amount.quantity / maxValue) * 100;
              return <BalanceTile chartWidth={chartWidth} balanceData={balance} key={index} />;
            })}
        </SBalancesContent>
      </PermissionsGate>
    </SBalancesView>
  );
};
