import {Asset, AssetPair, Currency, CurrencyPair, RequestTypeEnum, RFQMarketConfig, Side, SpotRate} from 'types/api';
import {isEmpty} from 'lodash/fp';
import {ExchangeRate} from 'types/exchangeRate';
import Decimal from 'decimal.js';

import {ShorthandSide, RepoShorthandSide, RepoFullLengthSide} from 'constants/commonEnums';

export const assetCode = (asset: Asset): string => {
  const secPart = asset.type === 'Securities' ? 'S' : '';
  const currencyPart = asset.currency;
  const atPart = asset.holder ? '@' + asset.holder : '';

  return secPart + currencyPart + atPart;
};

export const assetPairCode = (assetPair: AssetPair): string =>
  `${assetCode(assetPair.base)}-${assetCode(assetPair.second)}`;

export const currencyPairCode = (currencyPair: CurrencyPair): string => `${currencyPair.base}${currencyPair.second}`;

export const displayAssetTitle = (asset: Asset): string =>
  asset.holder ? (asset.type === 'Cash' ? 'Cash' : 'Sec') + '@' + asset.holder : asset.currency;

interface WithRequestAndSecondAsset {
  requestAsset: Asset;
  secondAsset: Asset;
}

interface WithBaseAndSecondAsset {
  baseAsset: Asset;
  secondAsset: Asset;
}

export const getRequestType = (assetPair?: AssetPair, defaultValue?: RequestTypeEnum) => {
  if (!assetPair) return defaultValue;

  return assetPair.second.type === 'Securities' || (assetPair.second.holder && 'holder' in assetPair.second) ?
      'RepoRequestType'
    : 'FXSwapRequestType';
};

export const isRepoPair = (assetPair?: AssetPair, defaultValue: boolean = false): boolean =>
  assetPair ? getRequestType(assetPair) === 'RepoRequestType' : defaultValue;

export const isFxPair = (assetPair?: AssetPair, defaultValue: boolean = false): boolean =>
  assetPair ? getRequestType(assetPair) === 'FXSwapRequestType' : defaultValue;

export const displayAsset = (asset: Asset): string =>
  asset.currency + (asset.holder ? ' ' + displayAssetTitle(asset) : '');

export const displayAssetPair = (assetPair: AssetPair, separator: string = '/'): string =>
  assetPair.base.holder ?
    `${assetPair.base.currency} Repo (${assetPair.base.holder})`
  : `${assetCode(assetPair.base)}${separator}${assetCode(assetPair.second)}`;

// this function produces long or short version of the side
// long or short refers to the length of the string and is not related to longing/shorting an asset
// in some places in our UI, we don't have sufficient space to display the full side name
// in such cases, we only display the short version of the side e.g. B/S or B
// in other places, we display the full length of the side e.g. BuySell or Buy
export const displaySide = (
  side: Side,
  isRepo: boolean,
  isShort: boolean = false
): RepoShorthandSide | RepoFullLengthSide | Side | ShorthandSide => {
  if (isShort) {
    return isRepo ? RepoShorthandSide[side] : ShorthandSide[side];
  } else {
    return isRepo ? RepoFullLengthSide[side] : side;
  }
};

export const displayAssetPairWithSide = (
  assetPair: AssetPair,
  side: Side,
  isShort: boolean = false,
  separator: string = '/'
): string => {
  const sidePart = displaySide(side, isRepoPair(assetPair), isShort);
  return `${displayAssetPair(assetPair, separator)} - ${sidePart}`;
};

export const displayAssetPairFrom = (
  assetPair: WithRequestAndSecondAsset | WithBaseAndSecondAsset,
  separator: string = '/'
): string =>
  displayAssetPair(
    {base: 'requestAsset' in assetPair ? assetPair.requestAsset : assetPair.baseAsset, second: assetPair.secondAsset},
    separator
  );

export const displayTwoAssetsAsPair = (base: Asset, second: Asset): string => displayAssetPair({base, second});

export const isAssetEqual = (a: Asset, b: Asset): boolean =>
  a.currency === b.currency && a.type === b.type && ((isEmpty(a.holder) && isEmpty(b.holder)) || a.holder === b.holder);

export const isAssetPairEqual = (a: AssetPair, b: AssetPair): boolean =>
  isAssetEqual(a.base, b.base) && isAssetEqual(a.second, b.second);

export const prepareAssetPairsForDisplay = (markets: RFQMarketConfig[]) =>
  markets.reduce<(AssetPair | null)[]>((agg, curr) => {
    const prev: AssetPair | null = agg.length > 0 ? agg[agg.length - 1] : null;
    return prev && isRepoPair(prev) !== isRepoPair(curr.assetPair) ?
        [...agg, null, curr.assetPair]
      : [...agg, curr.assetPair];
  }, []);

const reverseCurrencyPair = (cp: CurrencyPair) => ({base: cp.second, second: cp.base});

export const currencyToAsset = (c: Currency): Asset => ({type: 'Cash', currency: c});

export const currencyPairToAssetPair = (cp: CurrencyPair): AssetPair => ({
  base: currencyToAsset(cp.base),
  second: currencyToAsset(cp.second),
});

const reverseRate = (r: SpotRate) => ({
  value: Decimal.round((1.0 / r.value) * 1000000)
    .div(1000000)
    .toNumber(),
  timestamp: r.timestamp,
  currencyPair: r.currencyPair,
});

export const reverseExchangeRate = (er: ExchangeRate) => ({
  pair: reverseCurrencyPair(er.pair),
  rate: reverseRate(er.rate),
});

const reverseAssetPair = (ap: AssetPair) => ({base: ap.second, second: ap.base});

/**
 * Find an exchange rate from a list for a specific asset pair or undefined if not found. Reverses the exchange rate if neccessary.
 *
 * @param assetPair
 * @param exchangeRates
 * @returns
 */
export const findExchangeRate = (assetPair: AssetPair, exchangeRates: ExchangeRate[]): ExchangeRate | undefined => {
  const foundExchangeRates: ExchangeRate[] = exchangeRates.flatMap(er => {
    const baseAsset = currencyToAsset(er.pair.base);
    const secondAsset = currencyToAsset(er.pair.second);
    const erAssetPair: AssetPair = {base: baseAsset, second: secondAsset};
    return (
      isAssetPairEqual(assetPair, erAssetPair) ? [er]
      : isAssetPairEqual(assetPair, reverseAssetPair(erAssetPair)) ? reverseExchangeRate(er)
      : []
    );
  });

  return foundExchangeRates[0];
};

export const pairToItem = (ap: AssetPair) => ({
  label: displayAssetPair(ap),
  value: ap,
});

export const decodeAsset = (asset: string) => {
  const [type, currency, holder] = asset.split('@');
  return {
    type,
    currency,
    ...(holder ? {holder} : {}),
  } as Asset;
};

export function getAssetFromQuery(query: URLSearchParams, name: string) {
  const assetQueryParam = query.get(name) ?? '';
  if (!assetQueryParam) {
    return;
  }
  return decodeAsset(assetQueryParam);
}
