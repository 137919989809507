import {IntlShape} from 'react-intl';
import {Row} from '@tanstack/react-table';

import {AssetPair, Trade, TradeStatus, UserWithMeta} from 'types/api';
import {displayAmountWithCode, displaySmallAmount} from 'utils/AmountUtils';
import {displayAssetPairWithSide} from 'utils/AssetUtils';
import {getRFC2253ValueByKey, displayInterestRate, displayForwardPoints} from 'utils/utils';
import {displayDate} from 'utils/DayjsUtils';
import {getTradeStatus, getUserLateFeeRequestsByStatus} from 'utils/TradeUtils';

import {ReferenceData} from 'types/layout';

import {fieldTitles} from 'constants/messages/labels';
import {ColumnDef} from 'ui/GridTable';

import FieldTitle from 'ui/FieldTitle';
import Icon, {AdditionalIcon} from 'containers/Blotter/components/StatusIcon';

import {DateContainer} from 'containers/Blotter/styles';

import {TradesFilters} from './types';
import StatusTableHeader from './components/StatusTableHeader';

import {IconsContainer} from './styles';

export const statusFilter = (
  row: Row<Trade>,
  value: TradesFilters[],
  flaggedTradesIds: Trade['id'][] | undefined,
  user: UserWithMeta
) => {
  if (!value.length) return true;

  const checkers = {
    [TradesFilters.OutstandingStatus]: row.original.status === TradeStatus.Outstanding,
    [TradesFilters.FlaggedAsNonReceipt]: flaggedTradesIds?.includes(row.original.id),
    [TradesFilters.SentLateFeeProposalWithoutResponse]:
      getUserLateFeeRequestsByStatus(row.original, user, 'Pending', 'requestor').length > 0,
    [TradesFilters.SentAndRejectedLateFeeProposal]:
      getUserLateFeeRequestsByStatus(row.original, user, 'Rejected', 'requestor').length > 0,
    [TradesFilters.ReceivedLateFeeProposalWithoutResponse]:
      getUserLateFeeRequestsByStatus(row.original, user, 'Pending', 'recipient').length > 0,
  };

  return value.some(filter => checkers[filter]);
};

export const getBlotterTradesTableColumns = (
  formatMessage: IntlShape['formatMessage'],
  referenceData: ReferenceData,
  flaggedTradesIds: string[] | undefined,
  user: UserWithMeta,
  shouldDisplayInMillions: (assetPair: AssetPair) => boolean
): ColumnDef<Trade>[] => [
  {
    accessorKey: 'createdAt',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.dateAndTime)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer data-id={info.row.original.id}>
        <span>{displayDate(info.row.original.createdAt, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.createdAt, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortingFn: 'datetime',
    sortDescFirst: true,
  },
  {
    accessorKey: 'executionDetails.brokerTradeReference',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.reference)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer data-test-id='broker-trader-reference'>
        <span>{info.row.original.executionDetails?.brokerTradeReference}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 9.0,
    },
    enableSorting: false,
  },
  {
    accessorKey: 'side',
    enableSorting: false,
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.marketSide)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span data-testid='asset-pair-value'>
        {displayAssetPairWithSide(
          {
            base: info.row.original.baseAmount.asset,
            second: info.row.original.secondAmount.asset,
          },
          info.row.original.side,
          true
        )}
      </span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
  },
  {
    accessorKey: 'counterparty',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.counterparty)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span data-testid='counterparty-value'>{getRFC2253ValueByKey(info.row.original.counterparty, 'O')}</span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortingFn: 'text',
  },
  {
    accessorKey: 'status',
    enableSorting: false,
    enableGlobalFilter: true,
    header: header => <StatusTableHeader column={header.column} />,
    cell: info => (
      <IconsContainer data-testid='trade-status-icons-container'>
        <Icon status={getTradeStatus(info.row.original)} />
        <AdditionalIcon trade={info.row.original} />
      </IconsContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 7.1,
    },
    filterFn: (row, columnId, value) => statusFilter(row, value, flaggedTradesIds, user),
    sortingFn: 'text',
  },
  {
    accessorKey: 'baseAmount.quantity',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.baseAmt)}</FieldTitle>
      </span>
    ),
    cell: info => {
      const inMillions = shouldDisplayInMillions({
        base: info.row.original.baseAmount.asset,
        second: info.row.original.secondAmount.asset,
      });

      return (
        <span data-testid='base-amt-value'>
          {displayAmountWithCode(info.row.original.baseAmount, false, inMillions)}
        </span>
      );
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'secondAmount.quantity',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.secondAmt)}</FieldTitle>
      </span>
    ),
    cell: info => {
      const inMillions = shouldDisplayInMillions({
        base: info.row.original.baseAmount.asset,
        second: info.row.original.secondAmount.asset,
      });

      return (
        <span data-testid='second-amt-value'>
          {displayAmountWithCode(info.row.original.secondAmount, false, inMillions)}
        </span>
      );
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'interestAmount.quantity',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.interest)}</FieldTitle>
      </span>
    ),
    cell: info => <span>{displaySmallAmount(info.row.original.ourInterestAmount)}</span>,
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: referenceData === ReferenceData.ImpliedYield ? 'interestRate' : 'forwardPoints',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle data-testid='imp-yield-value'>
          {referenceData === ReferenceData.ImpliedYield ?
            formatMessage(fieldTitles.impYieldOrRate)
          : formatMessage(fieldTitles.fwdPts)}
        </FieldTitle>
      </span>
    ),
    cell: info => {
      if (referenceData === ReferenceData.ImpliedYield) {
        return displayInterestRate(info.row.original.interestRate);
      } else {
        return info.row.original.forwardPoints ? displayForwardPoints(info.row.original.forwardPoints, '') : '--';
      }
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 6.1,
    },
    sortingFn: 'alphanumeric',
    sortUndefined: -1,
  },
  {
    accessorKey: 'farLeg.scheduledTime',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.maturesAt)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer>
        <span>{displayDate(info.row.original.farLeg.scheduledTime, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.farLeg.scheduledTime, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortDescFirst: true,
    sortingFn: 'datetime',
  },
];
