import {unifiedEventStream} from 'api/unifiedEventStream';
import {QueryClient} from '@tanstack/react-query';
import {CreditRiskData} from 'types/analytics';

import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['CreditRisk'];

function subscribeCreditRisk(client: QueryClient) {
  // Loader in view timeout
  setTimeout(() => {
    client.setQueryData<CreditRiskData[]>(QUERY_KEY, data => (!data ? [] : data));
  }, 5000);
  const handler = (creditRisk: CreditRiskData[]) => {
    client.setQueryData<CreditRiskData[]>(QUERY_KEY, () => creditRisk);
  };

  return unifiedEventStream.subscribe('rt-credit-risk', handler, {lastEventId: '0,0'});
}

export const useRealTimeCreditRiskQuery = () => {
  const {data: creditRisk} = useSubscriptionQuery<CreditRiskData[]>(
    {
      queryKey: QUERY_KEY,
    },
    subscribeCreditRisk
  );

  return {
    creditRisk,
  };
};
