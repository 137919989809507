import React from 'react';

import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import {RefSwitch} from 'components/RefSwitch/RefSwitch';

import {Container} from './styles';
import LeftSide from './components/LeftSide';
import RightSide from './components/RightSide';

export const RFQBoard: React.FC = () => (
  <Container>
    <BoardHeader title='RFQ Dashboard'>
      <RefSwitch />
    </BoardHeader>
    <LeftSide />
    <RightSide />
  </Container>
);
