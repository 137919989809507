import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {displaySpotRangeForRequest, shouldShowQuoteValidUntil} from 'utils/RFQUtils';
import dayjs from 'dayjs';
import {
  displayForwardPoints,
  displayInterestRate,
  getImplYldMessage,
  getRFC2253ValueByKey,
  getSelectedEntityShortName,
} from 'utils/utils';
import {isNumber} from 'lodash/fp';
import {ReferenceData} from 'types/layout';

import {displayFullLeg, displayLegPrefixAndDateTime} from 'utils/DayjsUtils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {isRepoPair, displayAssetPairWithSide} from 'utils/AssetUtils';
import {useUser} from 'contexts/auth-context';
import TableRow from 'utils/TableUtils';

import {RFQQuoteWithRequest} from 'types/api';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {fieldTitles} from 'constants/messages/labels';
import colors from 'constants/colors';
import ImplYldMessage from 'ui/Message/ImplYldMessage';
import FieldValue from 'ui/FieldValue';
import Countdown from 'ui/Countdown';
import FieldTitle from 'ui/FieldTitle';

import {CountdownCell} from '../styles';

const implYldStyles = {display: 'inline-block'};

/**
 * Gerenates rows for Quote related popup
 *
 * @param quoteAndRequestProp passed only for CancelQuoteConfirmation, and indicated fields switch
 * @returns array of rows for Quote related popup
 */
export const useQuoteDetailsRows = (
  quoteAndRequest: RFQQuoteWithRequest | null,
  quoteAndRequestProp?: RFQQuoteWithRequest
) => {
  const user = useUser();
  const {formatMessage} = useIntl();
  const {referenceData} = useReferenceDataStore();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const assetPair = useMemo(
    () => (quoteAndRequest ? {base: quoteAndRequest.baseAsset, second: quoteAndRequest.secondAsset} : undefined),
    [quoteAndRequest?.baseAsset, quoteAndRequest?.secondAsset]
  );

  if (!quoteAndRequest || !assetPair) return [];

  const inMillions = shouldDisplayInMillions(assetPair);
  const isRepo = isRepoPair(assetPair);
  const counterparty = quoteAndRequestProp ? quoteAndRequest.requestor : quoteAndRequest.requestee;

  const implYldMessage =
    quoteAndRequest &&
    getImplYldMessage(
      getSelectedEntityShortName(user, quoteAndRequest.requestor),
      quoteAndRequest.requestorSide,
      quoteAndRequest.quote.interestRate
    );

  const remainingRow = (
    <TableRow key='remainingRow' title={formatMessage(fieldTitles.remaining)} rightTestId='remaining'>
      <CountdownCell className='text-right' key={1}>
        <Countdown
          timeTarget={dayjs(quoteAndRequest.quote.validUntil)}
          forceExpired={!shouldShowQuoteValidUntil(quoteAndRequest)}
        />
      </CountdownCell>
    </TableRow>
  );

  const cpartyRow = (
    <TableRow key='counterparty' title={formatMessage(fieldTitles.counterparty)} rightTestId='counterparty'>
      {getRFC2253ValueByKey(counterparty, 'O')}
    </TableRow>
  );
  const yourSideRow = (
    <TableRow key='side' title={formatMessage(fieldTitles.yourSide)} rightTestId='pair-side'>
      {displayAssetPairWithSide(
        assetPair,
        quoteAndRequestProp ? quoteAndRequest.requesteeSide : quoteAndRequest.requestorSide,
        true
      )}
    </TableRow>
  );

  const requestAmtRow = (
    <TableRow key='requestAmt' title={formatMessage(fieldTitles.tradedAmt)} rightTestId='request-amount'>
      {displayAmountWithCode(quoteAndRequest.quote.tradedAmount, false, inMillions)}
    </TableRow>
  );

  const spotRangeRow = (
    <TableRow key='spotRange' title={formatMessage(fieldTitles.spotRange)} rightTestId='spot-range'>
      {displaySpotRangeForRequest(quoteAndRequest.requestType)}
    </TableRow>
  );

  const interestRateLabel =
    isRepo ? 'Rate'
    : referenceData === ReferenceData.ImpliedYield ? formatMessage(fieldTitles.impYield)
    : formatMessage(fieldTitles.fwdPts);
  <FieldTitle>{interestRateLabel}</FieldTitle>;
  const interestRateRow = (
    <TableRow key='interestRate' title={interestRateLabel} rightTestId='implied-yield'>
      {isRepo || referenceData === ReferenceData.ImpliedYield || !isNumber(quoteAndRequest.quote.forwardPoints) ?
        displayInterestRate(quoteAndRequest.quote.interestRate)
      : displayForwardPoints(quoteAndRequest.quote.forwardPoints)}
    </TableRow>
  );

  const implYldMessageRow = (
    <TableRow key='implYldMessage' title=''>
      <ImplYldMessage key='implYldWarning' styles={implYldStyles} {...implYldMessage} noSpacing />
    </TableRow>
  );
  const impYldRows = [interestRateRow, ...(implYldMessage ? [implYldMessageRow] : [])];
  const nearLegRow = (
    <TableRow
      key='nearLeg'
      title={isRepo ? formatMessage(fieldTitles.onLeg) : formatMessage(fieldTitles.nearLeg)}
      rightTestId='near-leg'
    >
      <FieldValue color={quoteAndRequest.nearLegTime ? colors.yellow350 : undefined}>
        {displayFullLeg(quoteAndRequest.nearLegTime, quoteAndRequest.nearLegTimeInput?.transactionDate)}
      </FieldValue>
    </TableRow>
  );

  const farLegRow = (
    <TableRow
      key='farLeg'
      title={isRepo ? formatMessage(fieldTitles.offLeg) : formatMessage(fieldTitles.farLeg)}
      rightTestId='far-leg'
    >
      {displayLegPrefixAndDateTime(
        quoteAndRequest.maturityTime,
        quoteAndRequest.maturityTimeInput.transactionDate,
        'HH:mm'
      )}
    </TableRow>
  );
  const entityRow = (
    <TableRow key='entity' title={formatMessage(fieldTitles.entity)} rightTestId='entity-row'>
      {getRFC2253ValueByKey(quoteAndRequestProp ? quoteAndRequest.requestee : quoteAndRequest.requestor, 'O')}
    </TableRow>
  );

  return [
    remainingRow,
    cpartyRow,
    yourSideRow,
    requestAmtRow,
    spotRangeRow,
    ...impYldRows,
    nearLegRow,
    farLegRow,
    entityRow,
  ];
};
