import {MarkToMarket, Trade, TradeRelatedOrder} from 'types/api';

import {Tab} from 'ui/Tabs/types';

export enum SupportedFileTypes {
  FIXML = 'FIXML',
  CSV = 'CSV',
}

export interface RelatedOrderProps {
  relatedOrder: TradeRelatedOrder;
  inMillions: boolean;
}

export interface DetailsTableProps {
  trade: Trade;
  markToMarket: MarkToMarket | null;
}

export interface TradePopupProps {
  modelId: Trade['id'];
  onClose?: () => void;
  tradePopupTab?: Tab;
}
