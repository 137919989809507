import styled from 'styled-components';

import {color} from 'utils/theme';

import {SContainer} from 'ui/Layout/styles';

export const Marker = styled.span`
  position: absolute;
  left: 7px;
`;

export const MarkerValue = styled.span`
  padding-left: 5px;
  color: inherit;
`;

export const ClobSideBarWrapper = styled(SContainer)`
  background-color: ${color('blue650')};
  border-right: 4px solid ${color('gray600')};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SClobHeader = styled.div`
  padding: 20px 12px;
  background-color: ${color('gray600')};
  color: ${color('blue50')};
  font-size: 0.625rem;
  text-transform: uppercase;
  text-align: center;
`;
