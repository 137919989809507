import {useCallback, useEffect, useMemo, useState} from 'react';

import {Order, OrderStatus} from 'types/api';
import {useIntl} from 'react-intl';

import {pauseOrder, resumeOrder, cancelOrder} from 'api/api';
import {showToast} from 'utils/ToastUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useRealTimeOrdersQuery} from 'api/hooks/useRealTimeOrdersQuery';

import {useUser} from 'contexts/auth-context';

import {useModal} from 'contexts/modal-context';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {useInterval} from 'react-use';

import {Scope} from 'constants/permission-maps';

import GridTable, {GridTableVariant} from 'ui/GridTable';
import {loader as Loader} from 'components/Loader/Loader';

import {hasPermission, getRolePermissions} from 'components/PermissionGate/utils';

import OrderPopup from 'components/popups/OrderPopup';

import {PendingChange, getBlotterOrdersTableColumns} from './columns';

const OrdersTable = () => {
  const {openModal} = useModal();
  const [isReloading, setIsReloading] = useState(0);
  const [isCancelOpen, setCancelOpenState] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState<string>();
  const {referenceData} = useReferenceDataStore();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const showOrderDetailsPopup = useCallback(
    (orderDetails: Order) => openModal({modal: OrderPopup, props: {modelId: orderDetails.id}}),
    []
  );

  const {formatMessage} = useIntl();
  const [pendingChanges, setPendingChanges] = useState<PendingChange[]>([]);
  const [awaitingCancel, setAwaitingCancel] = useState(false);
  const {user} = useUser();
  const canTrade = hasPermission({
    permissions: getRolePermissions(user.role),
    scopes: [Scope.CanTrade],
  });
  const {orders} = useRealTimeOrdersQuery();

  useInterval(() => {
    setIsReloading(i => i + 1);
  }, 5000);

  const toggleConfirmCancellingHandler = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setCancelOpenState(!isCancelOpen);
      setCancelOrderId(e.currentTarget.id);
    },
    [isCancelOpen, setCancelOpenState, setCancelOrderId]
  );

  const confirmPauseHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const orderId = e.currentTarget.id;

    setPendingChanges(currentPendingChanges => [
      ...currentPendingChanges,
      {id: orderId, pendingStatus: OrderStatus.Paused},
    ]);

    pauseOrder(orderId)
      .then(() => {
        showToast('The order has been paused successfully.', 'success');
      })
      .catch((error: Error) => {
        console.error(`Error while trying to pause the order: ${error.message}`);
        setPendingChanges(changes => changes.filter(pendingChange => pendingChange.id !== orderId));
      });
  }, []);

  const confirmResumeHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const orderId = e.currentTarget.id;

    setPendingChanges(currentPendingChanges => [
      ...currentPendingChanges,
      {id: orderId, pendingStatus: OrderStatus.Live},
    ]);

    resumeOrder(orderId)
      .then(() => {
        showToast('The order has been resumed successfully.', 'success');
      })
      .catch((error: Error) => {
        console.error(`Error while trying to resume the order: ${error.message}`);
        setPendingChanges(changes => changes.filter(pendingChange => pendingChange.id !== orderId));
      });
  }, []);

  const updatePendingChanges = useCallback(() => {
    // Refactor into immutable version
    let newPendingChanges = pendingChanges;
    for (const pendingChange of pendingChanges) {
      const order = orders.find(value => value.id === pendingChange.id);

      if (order && order.status === pendingChange.pendingStatus) {
        newPendingChanges = pendingChanges.filter(value => value.id !== pendingChange.id);
      }
    }

    setPendingChanges(newPendingChanges);
  }, [pendingChanges, orders]);

  const confirmCancellingHandler = () => {
    setAwaitingCancel(true);

    if (cancelOrderId) {
      cancelOrder(cancelOrderId)
        .then(() => {
          setAwaitingCancel(false);
          setCancelOpenState(!isCancelOpen);
          setCancelOrderId(undefined);

          showToast('Cancellation request sent.', 'success');
        })
        .catch((err: Error) => {
          showToast(err.toString(), 'error');
        });
    }
  };

  const onRowClick = useCallback(
    (orderDetails: Order): void => {
      showOrderDetailsPopup(orderDetails);
    },
    [showOrderDetailsPopup]
  );

  useEffect(() => {
    updatePendingChanges();
  }, [isReloading, updatePendingChanges]);

  const columns = useMemo(
    () =>
      getBlotterOrdersTableColumns(
        formatMessage,
        referenceData,
        pendingChanges,
        canTrade,
        confirmPauseHandler,
        confirmResumeHandler,
        toggleConfirmCancellingHandler,
        shouldDisplayInMillions
      ),
    [
      formatMessage,
      referenceData,
      pendingChanges,
      canTrade,
      confirmPauseHandler,
      confirmResumeHandler,
      toggleConfirmCancellingHandler,
      shouldDisplayInMillions,
    ]
  );

  return (
    <div data-testid='orders-table' className='table-container'>
      <GridTable
        columns={columns}
        data={orders}
        defaultSorted={[{id: 'createdAt', desc: true}]}
        onRowClick={onRowClick}
        sortable
        variant={GridTableVariant.Minimal}
      />
      {isCancelOpen && (
        <div className='cancel-wrapper'>
          <div className='cancel'>
            <div className='question'>Are you sure you want to</div>
            <div className='question question-last'>cancel this order?</div>
            <div className='info'>This cancels only the unfilled</div>
            <div className='info info-last'>portion of the order.</div>
            <div className='actions'>
              <div onClick={toggleConfirmCancellingHandler} className='decline'>
                decline
              </div>
              <div data-testid='cancel-order-wrapper-confirm' onClick={confirmCancellingHandler} className='confirm'>
                confirm
              </div>
            </div>
          </div>
          <Loader show={awaitingCancel} />
        </div>
      )}
    </div>
  );
};

export default OrdersTable;
