import {useEffect, useState} from 'react';

import {LineChart, Line, XAxis, YAxis, ResponsiveContainer} from 'recharts';
import {OrderAggregate} from 'types/api';
import {convertQuantity, To} from 'utils/AmountUtils';

import useOrderBookQuery from 'api/hooks/useOrderBookQuery';

import useStateAssets from 'utils/hooks/useStateAssets';

import {Unit} from 'constants/commonEnums';

import {SVolumeContainer, SVolumeHeader, SVolumeWrapper, SVolumeChart} from './styles';

interface OrderChartData {
  impYld: number;
  aggVol: number;
}

const VolumeChart = () => {
  const {baseAsset, secondAsset} = useStateAssets();
  const [sellBuy, setSellBuy] = useState<OrderChartData[]>([]);
  const [buySell, setBuySell] = useState<OrderChartData[]>([]);
  const [sellBuyDomain, setSellBuyDomain] = useState<[number, number]>([50, 100]);
  const [buySellDomain, setBuySellDomain] = useState<[number, number]>([50, 0]);
  const [volDomain, setVolDomain] = useState<[number, number]>([0, 30]);
  const {data: orderBook} = useOrderBookQuery({base: baseAsset, second: secondAsset});

  const updateChartData = () => {
    const newSellBuy: OrderChartData[] = [];
    const newBuySell: OrderChartData[] = [];
    const newSellBuyDomain: [number, number] = sellBuyDomain;
    const newBuySellDomain: [number, number] = buySellDomain;
    const newVolDomain: [number, number] = volDomain;
    const chartMargin: number = 1;
    let maxDifference: number = 0;
    let sellBuyDifference: number = 0;
    let minSellBuy: number = 0;
    let buySellDifference: number = 0;
    let maxBuySell: number = 0;

    if (orderBook) {
      orderBook.sellBuy.forEach((order: OrderAggregate) => {
        newSellBuy.push({
          impYld: order.impliedYield,
          aggVol: convertQuantity(order.cumulativeAmount.quantity, To.View),
        });
      });
      orderBook.buySell.forEach((order: OrderAggregate) => {
        newBuySell.push({
          impYld: order.impliedYield,
          aggVol: convertQuantity(order.cumulativeAmount.quantity, To.View),
        });
      });
      if (newSellBuy.length > 0) {
        sellBuyDifference = newSellBuy[0].impYld - newSellBuy[newSellBuy.length - 1].impYld;
      }

      if (newBuySell.length > 0) {
        buySellDifference = newBuySell[0].impYld - newBuySell[newBuySell.length - 1].impYld;
      }

      minSellBuy = Math.min(...newSellBuy.map((order: OrderChartData) => order.impYld));
      maxBuySell = Math.max(...newBuySell.map((order: OrderChartData) => order.impYld));
      maxDifference = Math.max(sellBuyDifference, buySellDifference);

      // Find the vertical center of the chart
      const centerDistance: number = newSellBuy.length > 0 && newBuySell.length > 0 ? (minSellBuy - maxBuySell) / 2 : 0;

      // Minimum and maximum numbers for Y and X axis
      newSellBuyDomain[0] = minSellBuy - centerDistance;
      newSellBuyDomain[1] = newSellBuyDomain[0] + centerDistance + maxDifference + chartMargin;

      newBuySellDomain[1] = maxBuySell + centerDistance;
      newBuySellDomain[0] = newBuySellDomain[1] - centerDistance - maxDifference - chartMargin;

      newVolDomain[1] = Math.max(
        ...newBuySell.map((order: OrderChartData) => order.aggVol),
        ...newSellBuy.map((order: OrderChartData) => order.aggVol)
      );

      // Adding virtual points to the edges of the charts, so we create an infinite line
      if (newSellBuy.length > 0) {
        newSellBuy.unshift({aggVol: newSellBuy[0].aggVol, impYld: newSellBuyDomain[1]});
        newSellBuy.push({aggVol: 0, impYld: newSellBuyDomain[0]});
      }
      if (newBuySell.length > 0) {
        newBuySell.push({aggVol: newBuySell[newBuySell.length - 1].aggVol, impYld: newBuySellDomain[0]});
        newBuySell.unshift({aggVol: 0, impYld: newBuySellDomain[1]});
      }

      // Sort orders by implied yield
      newBuySell.sort((a: OrderChartData, b: OrderChartData) => b.impYld - a.impYld);
      newSellBuy.sort((a: OrderChartData, b: OrderChartData) => b.impYld - a.impYld);

      setSellBuy(newSellBuy);
      setBuySell(newBuySell);
      setSellBuyDomain(newSellBuyDomain);
      setBuySellDomain(newBuySellDomain);
      setVolDomain(newVolDomain);
    }
  };

  useEffect(() => {
    updateChartData();
  }, [orderBook?.buySell, orderBook?.sellBuy]);

  return (
    <SVolumeContainer fluid>
      <SVolumeHeader>
        <p>Volume Chart</p>
      </SVolumeHeader>
      <SVolumeWrapper>
        {sellBuy.length > 0 && (
          <SVolumeChart>
            <ResponsiveContainer>
              <LineChart layout='vertical' data={sellBuy} margin={{left: 0}}>
                <XAxis dataKey='aggVol' type='number' unit='m' orientation='top' domain={volDomain} />
                <YAxis
                  dataKey='impYld'
                  type='number'
                  unit={Unit.BP}
                  tickLine={false}
                  reversed
                  domain={sellBuyDomain}
                  interval='preserveStart'
                />
                <Line type='stepAfter' dataKey='aggVol' stroke='#FF5070' dot={false} activeDot={false} />
              </LineChart>
            </ResponsiveContainer>
          </SVolumeChart>
        )}
        {buySell.length > 0 && (
          <SVolumeChart>
            <ResponsiveContainer>
              <LineChart layout='vertical' data={buySell} margin={{left: 0}}>
                <XAxis dataKey='aggVol' type='number' unit='m' domain={volDomain} />
                <YAxis
                  dataKey='impYld'
                  type='number'
                  unit={Unit.BP}
                  tickLine={false}
                  reversed
                  domain={buySellDomain}
                  interval='preserveEnd'
                />
                <Line type='stepBefore' dataKey='aggVol' stroke='#75E6B5' dot={false} activeDot={false} />
              </LineChart>
            </ResponsiveContainer>
          </SVolumeChart>
        )}
      </SVolumeWrapper>
    </SVolumeContainer>
  );
};

export default VolumeChart;
