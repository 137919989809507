import {useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';

import {range} from 'lodash/fp';

const useTicks = () => {
  const [now, setNow] = useState(dayjs().valueOf());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(dayjs().valueOf());
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  // Ticks every 3 hours
  const ticks = useMemo(
    () => [
      now,
      ...range(1, 13).map(i =>
        dayjs(now)
          .startOf('hour')
          .add(i * 3, 'hours')
          .valueOf()
      ),
    ],
    [now]
  );

  return ticks;
};

export default useTicks;
