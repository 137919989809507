import {IntlShape} from 'react-intl';

import {AssetPair, Order, OrderStatus} from 'types/api';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displaySide, displayTwoAssetsAsPair, isRepoPair} from 'utils/AssetUtils';
import {displayInterestRate, displayForwardPoints, map} from 'utils/utils';
import {displayDate} from 'utils/DayjsUtils';

import {ReferenceData} from 'types/layout';
import {cancelMessage} from 'types/blotter';

import {fieldTitles} from 'constants/messages/labels';
import {ColumnDef} from 'ui/GridTable';

import FieldTitle from 'ui/FieldTitle';
import Icon from 'containers/Blotter/components/StatusIcon';
import {DateContainer} from 'containers/Blotter/styles';

export interface PendingChange {
  id: string;
  pendingStatus: OrderStatus;
}

export const getBlotterOrdersTableColumns = (
  formatMessage: IntlShape['formatMessage'],
  referenceData: ReferenceData,
  pendingChanges: PendingChange[],
  canTrade: boolean,
  confirmPauseHandler: (e: React.MouseEvent<HTMLElement>) => void,
  confirmResumeHandler: (e: React.MouseEvent<HTMLElement>) => void,
  toggleConfirmCancellingHandler: (e: React.MouseEvent<HTMLElement>) => void,
  shouldDisplayInMillions: (assetPair: AssetPair) => boolean
): ColumnDef<Order>[] => [
  {
    accessorKey: 'createdAt',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.dateAndTime)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer data-id={info.row.original.id}>
        <span>{displayDate(info.row.original.createdAt, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.createdAt, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 13.1,
    },
    sortingFn: 'datetime',
    sortDescFirst: true,
  },
  {
    accessorKey: 'pair',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.pair)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span>{displayTwoAssetsAsPair(info.row.original.data.baseAsset, info.row.original.data.secondAsset)}</span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: (rowA, rowB) => {
      const rowAPair = displayTwoAssetsAsPair(rowA.original.data.baseAsset, rowA.original.data.secondAsset);
      const rowBPair = displayTwoAssetsAsPair(rowB.original.data.baseAsset, rowB.original.data.secondAsset);

      return rowAPair.localeCompare(rowBPair);
    },
  },
  {
    accessorKey: 'orderSide',
    enableSorting: false,
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.orderSide)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span data-testid='order-side-column'>
        {info.row.original.data.marketType} -{' '}
        {displaySide(
          info.row.original.data.side,
          isRepoPair({base: info.row.original.data.baseAsset, second: info.row.original.data.secondAsset}),
          true
        )}
      </span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
  },
  {
    accessorKey: 'earmark',
    enableSorting: false,
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.earmark)}</FieldTitle>
      </span>
    ),
    cell: info => {
      const inMillions = shouldDisplayInMillions({
        base: info.row.original.data.baseAsset,
        second: info.row.original.data.secondAsset,
      });

      return (
        <span data-testid='earmark-column'>
          {displayAmountWithCode(info.row.original.remainingEarmark, false, inMillions)}
        </span>
      );
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
  },
  {
    accessorKey: 'status',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.status)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <div className='status-icon-container'>
        <Icon
          status={info.row.original.status}
          statusMessage={map(info.row.original.statusReason, reason => cancelMessage[reason])}
        />
      </div>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: 'text',
  },
  {
    accessorKey: 'data.baseAmount.quantity',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.amount)}</FieldTitle>
      </span>
    ),
    cell: info => {
      const inMillions = shouldDisplayInMillions({
        base: info.row.original.data.baseAsset,
        second: info.row.original.data.secondAsset,
      });

      return (
        <span data-testid='amount-column'>
          {displayAmountWithCode(info.row.original.data.baseAmount, true, inMillions)}
        </span>
      );
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'lifted.quantity',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.filled)}</FieldTitle>
      </span>
    ),
    cell: info => {
      const inMillions = shouldDisplayInMillions({
        base: info.row.original.data.baseAsset,
        second: info.row.original.data.secondAsset,
      });

      return (
        <span data-testid='filled-column'>
          {info.row.original.lifted.quantity > 0 ?
            displayAmountWithCode(info.row.original.lifted, true, inMillions)
          : '--'}
        </span>
      );
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: 'text',
    sortUndefined: 1,
  },
  {
    accessorKey: 'rate',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>
          {formatMessage(
            referenceData === ReferenceData.ImpliedYield ? fieldTitles.impYieldOrRate : fieldTitles.fwdPtsOrRate
          )}
        </FieldTitle>
      </span>
    ),
    cell: info => {
      const isRepo = isRepoPair({
        base: info.row.original.data.baseAsset,
        second: info.row.original.data.secondAsset,
      });
      const isFwdPts = referenceData === ReferenceData.ForwardPoints;
      return (
        <span data-testid='rate-data'>
          {isRepo || !isFwdPts ?
            displayInterestRate(info.row.original.data.interestRate)
          : displayForwardPoints(info.row.original.currentForwardPoints || info.row.original.initialForwardPoints, '')}
        </span>
      );
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: (rowA, rowB) => {
      const isFwdPts = referenceData === ReferenceData.ForwardPoints;
      const isRowARepo = isRepoPair({
        base: rowA.original.data.baseAsset,
        second: rowA.original.data.secondAsset,
      });
      const isRowBRepo = isRepoPair({
        base: rowB.original.data.baseAsset,
        second: rowB.original.data.secondAsset,
      });
      const rowARate =
        isRowARepo || !isFwdPts ?
          displayInterestRate(rowA.original.data.interestRate)
        : displayForwardPoints(rowA.original.initialForwardPoints, '');
      const rowBRate =
        isRowBRepo || !isFwdPts ?
          displayInterestRate(rowB.original.data.interestRate)
        : displayForwardPoints(rowB.original.initialForwardPoints, '');

      return rowARate.localeCompare(rowBRate);
    },
  },
  {
    accessorKey: 'data.initialRate',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.initialRate)}</FieldTitle>
      </span>
    ),
    cell: info => <span>{info.row.original.data.initialRate}</span>,
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 10,
    },
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'buttons',
    enableSorting: false,
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{''}</FieldTitle>
      </span>
    ),
    cell: info => {
      const foundPendingChange = pendingChanges.filter(pendingChange => pendingChange.id === info.row.original.id)[0];
      const isStatusCorrect = !foundPendingChange || foundPendingChange.pendingStatus === info.row.original.status;

      return canTrade ?
          <>
            {!isStatusCorrect && <i className='la la-spinner' />}
            {isStatusCorrect && info.row.original.status === OrderStatus.Live && (
              <i
                id={info.row.original.id}
                data-testid='order-pause'
                onClick={confirmPauseHandler}
                className='la la-pause-circle pause'
              />
            )}
            {isStatusCorrect && info.row.original.isActive && info.row.original.status === OrderStatus.Paused && (
              <i
                id={info.row.original.id}
                data-testid='order-resume'
                onClick={confirmResumeHandler}
                className='la la-play resume'
              />
            )}
            {isStatusCorrect && info.row.original.isCancellable && (
              <i
                id={info.row.original.id}
                data-testid='order-cancel'
                onClick={toggleConfirmCancellingHandler}
                className='la la-times-circle remove'
              />
            )}
          </>
        : <></>;
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 6.9,
    },
  },
];
