import {displayDate} from 'utils/DayjsUtils';
import {appTzShortName} from 'utils/setupDayjs';

import {StyledParagraph} from 'containers/HelpPopup/styles';

import {SCommitHash, SVerticalDiv, Section} from './styles';

const gitCommitHash = process.env.REACT_APP_COMMIT_SHORT_SHA;
const gitCommitDate = new Date(process.env.REACT_APP_COMMIT_TIMESTAMP || 0).toISOString();
const deploymentDate = new Date(process.env.REACT_APP_JOB_STARTED_AT || 0).toISOString();

export const ContactsForHelp = () => {
  return (
    <>
      <Section>
        <StyledParagraph>For support, please use our Client Support Portal:</StyledParagraph>
      </Section>

      <Section>
        <SVerticalDiv>
          <StyledParagraph>
            <a href='https://support.finteum.com' rel='noreferrer' target='_blank'>
              support.finteum.com
            </a>
          </StyledParagraph>
        </SVerticalDiv>
      </Section>

      <Section fontSize='0.75rem'>
        <SVerticalDiv>
          {gitCommitHash ?
            <>
              <StyledParagraph>Version</StyledParagraph>
              <SCommitHash>{gitCommitHash}</SCommitHash>
            </>
          : null}
          {gitCommitDate ?
            <>
              <StyledParagraph>Released</StyledParagraph>
              <StyledParagraph>
                {displayDate(gitCommitDate, 'DD MMM YYYY HH:mm:ss')} {appTzShortName}
              </StyledParagraph>
            </>
          : null}
          {deploymentDate ?
            <>
              <StyledParagraph>Deployed</StyledParagraph>
              <StyledParagraph>
                {displayDate(deploymentDate, 'DD MMM YYYY HH:mm:ss')} {appTzShortName}
              </StyledParagraph>
            </>
          : null}
        </SVerticalDiv>
      </Section>
    </>
  );
};
