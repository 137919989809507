import {useUser} from 'contexts/auth-context';

import {HelpPopup} from 'containers/HelpPopup';

import HeaderBar from 'components/HeaderBar/HeaderBar';
import Logo from 'components/HeaderBar/Logo';
import Nav from 'components/HeaderBar/components/Navigation';
import UserMenu from 'components/HeaderBar/UserMenu';

import {useHeader} from './hooks/useHeader';

interface HeaderProps {
  nav?: boolean;
  notifications?: boolean;
  balances?: boolean;
}

const Header = ({nav = true, notifications = true, balances = true}: HeaderProps) => {
  const {user} = useUser();
  const {tryToSignOut, showHelp, hideHelp, isHelpPopupShown} = useHeader();

  return (
    <HeaderBar>
      <Logo />

      {nav && <Nav />}
      <UserMenu
        notifications={notifications}
        balances={balances}
        signOutCallback={tryToSignOut}
        helpCallback={showHelp}
        user={user}
      />

      {isHelpPopupShown && <HelpPopup onClose={hideHelp} />}
    </HeaderBar>
  );
};

export default Header;
