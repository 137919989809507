import styled from 'styled-components';

export const FormContainer = styled.div`
  height: inherit;
  background: ${p => p.theme.colors.blue900};
  overflow-y: auto;
  padding-bottom: 1em;
`;

export const StyledForm = styled.form`
  text-align: center;
`;

export const FieldRow = styled.div`
  display: flex;
  padding: 0 20px;
  margin: 1em 0;
  align-items: center;
`;

export const Label = styled.span`
  width: 35%;
  color: ${p => p.theme.colors.blue50};
  text-transform: uppercase;
  text-align: left;
  font-size: 0.6875rem;
`;

export const NonAggLabel = styled.span`
  text-transform: uppercase;
  text-align: left;
  font-size: 0.6875rem;
`;

export const Field = styled.div`
  width: 65%;
  text-align: center;
`;

export const ConfirmationContainer = styled.div`
  position: relative;
  height: inherit;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: ${p => p.theme.colors.blue900};
  padding: 0 0 1em 0;
  overflow-y: auto;
`;

export const TabContent = styled.div`
  position: relative;
  width: 100%;
  display: block;
  padding-top: 1em;
`;

export const TabHeader = styled.div`
  text-align: center;
  color: ${p => p.theme.colors.blue50};
  font-size: 0.875rem;
  padding: 0 0.2em;
`;

export const MarketClosedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 290px);
  background: ${p => p.theme.colors.blue900};
  overflow-y: auto;
  text-align: center;
`;

export const SButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
`;

export const SFormButtonsContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 15px;
  padding: 0 20px;
`;

export const resetButtonStyles = {flexGrow: 3, padding: '0.6rem 0'};
