import {QueryClient} from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError(error) {
        if (error.message.includes('Missing queryFn')) return false;
        console.error(error);
        return false;
      },
    },
  },
});
