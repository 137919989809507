import {NotificationPayloadMap, NotificationType} from 'types/api';

export interface NotificationData<Type extends NotificationType = NotificationType> {
  type: Type;
  createdAt: string;
  message?: string;
  status?: string;
  isUnread: boolean;
  isUnseen: boolean;
  payload: NotificationPayloadMap[Type];
  sortable: boolean;
  aggregateId?: string;
}

export enum NotificationStorageKey {
  LAST_NOTIFICATION_READ_TIME = 'lastNotificationReadTime',
  // timestamp of the most recent notification which was rendered in the notification centre.
  LAST_NOTIFICATION_RENDERED_TIME = 'lastNotificationRenderedTime',
  // timestamp of the last moment when the notification centre has been opened.
  LAST_NOTIFICATION_CENTER_OPENED_TIME = 'lastNotificationCenterOpenedTime',
}
