import {LegType, Trade, TradeRelatedLateSettlementFee} from 'types/api';

export interface RequestLateSettlementFeePopupProps {
  tradeId: Trade['id'];

  legType: LegType;
  lateFee?: TradeRelatedLateSettlementFee;
}

export interface ReviewLateSettlementFeePopupProps extends Required<RequestLateSettlementFeePopupProps> {}

export interface ReviewAcceptedLateFeePopupProps extends ReviewLateSettlementFeePopupProps {
  type: 'requestor' | 'recipient';
}

export const tradeSettlementDeadline: number = 30;
