import React from 'react';

import {Container, LoaderItem} from './styles';

const Loader: React.FC = () => (
  <Container data-testid='loader-container'>
    <LoaderItem>
      <div />
      <div />
      <div />
    </LoaderItem>
  </Container>
);

export default Loader;
