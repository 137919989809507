import dayjs from 'dayjs';

import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';
import {Asset} from 'types/api';
import {reverseExchangeRate} from 'utils/AssetUtils';

import {isEqual} from 'lodash';

import {CapacityAggregationType} from '../types';
import useAggregatedCapacity from './useAggregatedCapacity';

export function useAggregatedCapacities({
  mainAsset,
  comparedAsset,
  capacityType,
}: {
  mainAsset: Asset;
  comparedAsset?: Asset;
  capacityType?: CapacityAggregationType;
}) {
  if (!mainAsset) {
    throw new Error('Main asset is required');
  }
  const currentDate = dayjs().toISOString();
  const mainAssetData = useAggregatedCapacity({asset: mainAsset, currentDate, capacityType});
  const comparedAssetData = useAggregatedCapacity({asset: comparedAsset, currentDate, capacityType});
  const {exchangeRates} = useRealTimeExchangeRatesQuery();

  if (!comparedAsset || mainAsset.currency === comparedAsset.currency) {
    return {mainAssetData, comparedAssetData};
  }

  const mainCurrencyExchangeRates = exchangeRates.flatMap(er =>
    isEqual(er.pair.second, mainAsset.currency) ? [er]
    : isEqual(er.pair.base, mainAsset.currency) ? [reverseExchangeRate(er)]
    : []
  );

  const exchangeRate = mainCurrencyExchangeRates.find(er => isEqual(er.pair.base, comparedAsset?.currency));

  if (!exchangeRate) {
    return {mainAssetData, comparedAssetData};
  }

  const comparedAssetDataInMainCurrency = comparedAssetData?.map(({t, v}) => ({t, v: exchangeRate.rate.value * v}));

  return {
    mainAssetData,
    comparedAssetData: comparedAssetDataInMainCurrency,
  };
}
