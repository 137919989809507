import {useQuery} from '@tanstack/react-query';

import {FlagType, Trade} from 'types/api';

import {getTradeIdsWithCashflowFlags} from 'api/api';

const QUERY_KEY = ['FlaggedTradesIds'];

interface Data {
  flaggedTradesIds: Trade['id'][];
}

export const useFlaggedTradesIdsQuery = (flagTypes: FlagType[], flagValue: boolean) => {
  const queryKey = [...QUERY_KEY, ...flagTypes, flagValue];
  const {data, refetch} = useQuery<Data>({
    queryKey,
    queryFn: () =>
      getTradeIdsWithCashflowFlags(flagTypes, flagValue).then(response => {
        return {flaggedTradesIds: response.data};
      }),
    staleTime: 5000,
  });

  return {
    flaggedTradesIds: data?.flaggedTradesIds,
    refetchFlaggedTradesIds: refetch,
  };
};
