import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {isFunction} from 'lodash';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  HeaderGroup,
  Row,
  SortingState,
  Header,
  SortDirection,
  getFilteredRowModel,
} from '@tanstack/react-table';
import {VirtualItem, useVirtualizer} from '@tanstack/react-virtual';

import Loader from 'ui/Loader';

import {GridTableProps, GridTableVariant} from './types';
import {
  GridTableWrapper,
  GridTableHeader,
  GridTableCell,
  GridTableRow,
  GridTableHeaderGroup,
  GridTableVirtualItemsWrapper,
  MINIMAL_ROW_HEIGHT_PX,
  REGULAR_ROW_HEIGHT_PX,
  EmptyGridTableHeader,
  EmptyGridTableCell,
} from './styles';
import {buildGridTemplate, getWrapperHeight, hasLeftSpacing, hasRightSpacing} from './utils';

const GridTable = <T extends object>({
  data,
  columns,
  defaultSorted = [],
  fullWidth = false,
  getRowId,
  sortable = false,
  filterable = false,
  onRowClick,
  variant = GridTableVariant.Regular,
  isLoading,
}: GridTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>(defaultSorted);
  const [globalFilter, setGlobalFilter] = useState<unknown>();
  const table = useReactTable<T>({
    data,
    columns,
    enableSorting: sortable,
    enableFilters: filterable,
    enableGlobalFilter: filterable,
    enableSortingRemoval: false,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId,
  });
  const headerGroups = table.getHeaderGroups();
  const headers = table.getFlatHeaders();
  const leafColumns = table.getAllLeafColumns();
  const rows = table.getRowModel().rows;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollContainerRef.current?.scrollTo?.(0, 0);
  }, [scrollContainerRef.current, JSON.stringify(data)]);

  const rowClickHandler = useCallback(
    (row: Row<T>) => {
      if (isFunction(onRowClick)) {
        onRowClick(row.original);
      }
    },
    [onRowClick]
  );

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => scrollContainerRef.current,
    count: rows.length,
    estimateSize: () => (variant === GridTableVariant.Minimal ? MINIMAL_ROW_HEIGHT_PX : REGULAR_ROW_HEIGHT_PX),
    overscan: 4,
    getItemKey: index => rows[index].id,
  });
  const {getVirtualItems, getTotalSize} = rowVirtualizer;

  const renderSortArrow = (header: Header<T, unknown>) => {
    const arrows = {
      asc: <i className='la la-angle-up' />,
      desc: <i className='la la-angle-down' />,
    };

    if (header.column.getCanSort() && header.column.getIsSorted()) {
      return arrows[header.column.getIsSorted() as SortDirection];
    }

    return null;
  };

  const Headers = ({headerGroup}: {headerGroup: HeaderGroup<T>}) => {
    let nextHeaderPosition = 1;
    const firstHeader = headerGroup.headers[0];

    return (
      <GridTableHeaderGroup
        gridTemplateColumns={buildGridTemplate(leafColumns, fullWidth)}
        key={headerGroup.id}
        variant={variant}
      >
        {headerGroup.headers.map(header => {
          const TableHeader = (
            <GridTableHeader
              align={header.column.columnDef.meta?.align}
              className='grid-table-header'
              hasLeftSpacing={hasLeftSpacing(header, headers)}
              hasRightSpacing={hasRightSpacing(header, headers)}
              isRoot={!header.column.parent}
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              sortable={header.column.getCanSort()}
              start={nextHeaderPosition}
              span={header.colSpan}
              variant={variant}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {renderSortArrow(header)}
            </GridTableHeader>
          );

          nextHeaderPosition += header.colSpan;

          return TableHeader;
        })}
        {fullWidth && variant === GridTableVariant.Regular && (
          <EmptyGridTableHeader
            align={firstHeader.column.columnDef.meta?.align}
            className='grid-table-header'
            isRoot={!firstHeader.column.parent}
            key='last-header'
            onClick={firstHeader.column.getToggleSortingHandler()}
          />
        )}
      </GridTableHeaderGroup>
    );
  };

  const VirtualRow = ({
    index,
    size,
    start,
  }: {
    index: VirtualItem['index'];
    size: VirtualItem['size'];
    start: VirtualItem['start'];
  }) => {
    const row = rows[index];
    const firstCell = row.getVisibleCells()[0];
    return (
      <GridTableRow
        data-testid='grid-table-row'
        className='grid-table-row'
        clickable={isFunction(onRowClick)}
        gridTemplateColumns={buildGridTemplate(leafColumns, fullWidth)}
        onClick={() => rowClickHandler(row)}
        variant={variant}
        style={{
          height: `${size}px`,
          transform: `translateY(${start}px)`,
        }}
      >
        {row.getVisibleCells().map(cell => (
          <GridTableCell
            align={cell.column.columnDef.meta?.align}
            alignItems={cell.column.columnDef.meta?.alignItems}
            className='grid-table-cell'
            isInsideEvenRow={(row.index + 1) % 2 === 0}
            key={cell.id}
            hasLeftSpacing={hasLeftSpacing(cell, headers)}
            hasRightSpacing={hasRightSpacing(cell, headers)}
            variant={variant}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </GridTableCell>
        ))}
        {fullWidth && variant === GridTableVariant.Regular && (
          <EmptyGridTableCell
            align={firstCell.column.columnDef.meta?.align}
            alignItems={firstCell.column.columnDef.meta?.alignItems}
            className='grid-table-cell'
            isInsideEvenRow={(row.index + 1) % 2 === 0}
            key='last-cell'
          />
        )}
      </GridTableRow>
    );
  };

  const virtualItems = getVirtualItems();

  return (
    <GridTableWrapper
      ref={scrollContainerRef}
      className='grid-table'
      fullWidth={fullWidth}
      gridTemplateColumns={buildGridTemplate(leafColumns, fullWidth)}
      variant={variant}
    >
      {headerGroups.map(headerGroup => (
        <Headers key={headerGroup.id} headerGroup={headerGroup} />
      ))}

      <GridTableVirtualItemsWrapper style={{height: isLoading ? getWrapperHeight(variant) : getTotalSize()}}>
        {isLoading && <Loader />}
        {virtualItems.map(({key, index, size, start}) => (
          <VirtualRow key={key} index={index} size={size} start={start} />
        ))}
      </GridTableVirtualItemsWrapper>
    </GridTableWrapper>
  );
};

export default memo(GridTable) as typeof GridTable;
