import styled from 'styled-components';
import {color} from 'utils/theme';

export const Top = styled.div`
  text-align: center;
  padding: 1em 0.2em 0.2em;
  max-width: calc(100% - 20px);
  margin: auto;

  p {
    font-size: 0.9em;
    line-height: 1.4em;
    color: ${p => p.theme.colors.blue50};
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-flow: row;
  padding: 1em;
  justify-content: space-evenly;
`;

export const BottomConfirmation = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 0.4em;
  align-items: center;
`;

export const CountdownContainer = styled.div`
  color: ${p => p.theme.colors.white};

  .seconds {
    font-size: 2em;
  }
`;

export const TabContent = styled.div`
  position: relative;
  width: 100%;
  display: block;
  padding-top: 1em;
`;

export const TabHeader = styled.div`
  text-align: center;
  color: ${p => p.theme.colors.blue50};
  font-size: 0.875rem;
  padding: 0 0.2em;
`;

export const TableLabel = styled.span`
  color: ${p => p.theme.colors.gray200};
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
`;

export const TableValue = styled.span`
  text-align: left;
`;

export const WarningRow = styled.div`
  min-height: 40px;
  margin-bottom: 1em;
  padding: 0 0.8em;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 0.5em 0;
`;

export const FormRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: 0.8em;

  &:nth-of-type(2n) {
    background-color: ${color('blue950')};
  }

  &:first-child {
    color: ${color('gray50')};
  }

  & > * {
    flex-grow: 1;
    padding: 0 0.2em;
    font-size: 0.75em;
    width: 50%;
  }
`;

export const FormCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin: 1rem 0rem;
`;
