import {useQuery} from '@tanstack/react-query';
import {getAvailableAssetsWithTimezones} from 'api/api';

import {useUser} from 'contexts/auth-context';
import {useMemo} from 'react';

import {AxiosError} from 'axios';
import {AssetWithTimezone, ErrorResponse} from 'types/api';

import {Scope} from 'constants/permission-maps';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

export default function useAvailableAssetsQuery() {
  const userWithMeta = useUser();
  const userHasObserverPermission = useMemo(
    () => hasPermission({permissions: getRolePermissions(userWithMeta.user?.role), scopes: [Scope.ViewClob]}),
    [userWithMeta]
  );
  return useQuery<AssetWithTimezone[], AxiosError<ErrorResponse>>({
    queryKey: ['AvailableAssets'],
    queryFn: () => getAvailableAssetsWithTimezones().then(response => response.data),
    enabled: !!userHasObserverPermission && window.REACT_APP_OBSERVER !== 'true',
    staleTime: 900_000,
  });
}
