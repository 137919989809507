import styled, {css} from 'styled-components';
import {switchProp} from 'styled-tools';

import {pulse} from 'utils/AnimationUtils';

import {color} from 'utils/theme';

import {ContainerProps, InputProps, ArrowProps} from './types';

export const Container = styled.div<ContainerProps>`
  position: relative;
  ${(props: ContainerProps) => props.width && `width: ${props.width};`}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const Prefix = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.6em;
  height: 1.2em;
  margin: auto;
  color: ${p => p.theme.colors.blue50};
  font-size: 12px;
`;

export const Input = styled.input<InputProps>`
  -moz-appearance: textfield;
  appearance: textfield;
  box-sizing: border-box;
  background-color: transparent;
  max-height: 40px;
  outline: none;
  font-size: 0.75rem;
  text-transform: uppercase;
  border-radius: 25px;
  padding: ${props => props.inputPadding ?? '10px 30px'};
  box-shadow: none;
  font-family: 'LatoBold', sans-serif;
  width: 100%;
  color: ${p => p.theme.colors.white};
  border-width: 2px;
  border-style: solid;
  border-color: ${switchProp(
    'inputColor',
    {
      highlight: color('green500'),
      warning: color('yellow500'),
      invalid: color('red400'),
      highlightRequired: color('white'),
    },
    color('gray470')
  )};
  animation: ${(props: InputProps) =>
    props.flash ?
      css`
        ${pulse} 1.5s linear
      `
    : 'none'};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:disabled {
    color: ${p => p.theme.colors.gray350};
  }
`;

export const ArrowsContainer = styled.div`
  position: absolute;
  top: 10%;
  right: 0.6em;
  height: 70%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowBtn = styled.div<ArrowProps>`
  background: transparent;
  border: none;
  color: ${p => p.theme.colors.gray350};
  width: 100%;

  &:focus {
    outline: none;
  }
  ${props =>
    !props.disabled &&
    `cursor: pointer;
      &:hover {
        color: ${props.theme.colors.green500};
      }`}
`;

export const Unit = styled.span`
  position: absolute;
  top: 0;
  right: 2.6em;
  bottom: 0;
  height: 1.2em;
  margin: auto;
  color: ${p => p.theme.colors.blue50};
  font-size: 12px;
`;
