export const getQuoteFormMessage = (statusCode: number) => {
  switch (statusCode) {
    case 201:
      return 'Quote successfully created.';
    case 202:
      return 'Quote sent for registration.';
    case 400:
      return 'Quote validation failed. Invalid parameters.';
    case 500:
    default:
      return 'Service Unavailable.';
  }
};

export const getQuoteFormMessageType = (statusCode: number) => {
  switch (statusCode) {
    case 201:
      return 'success';
    case 202:
      return 'warning';
    case 400:
    case 500:
    default:
      return 'error';
  }
};
