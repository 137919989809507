import styled, {CSSProperties} from 'styled-components';

export const SubmitInput = styled.input<{
  hoverStyle?: CSSProperties;
  disabled?: boolean;
}>`
  cursor: pointer;
  border: 0px;
  display: block;
  user-select: none;
  margin: 8px auto 0;
  font-size: 12px;
  text-align: center;
  width: 80px;
  color: #000;
  border-radius: 25px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: LatoBold, sans-serif;
  background-color: ${p => p.theme.colors.green400};
  opacity: ${p => (p.disabled ? '0.5' : 'unset')};
`;
