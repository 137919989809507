import React, {PropsWithChildren} from 'react';

import {LoginForm, UserWithMeta} from 'types/api';
import {authenticate, getMyJwtProfile, getMyProfile, makeLogOutApiCall} from 'api/api';
import {showToast} from 'utils/ToastUtils';
import {useQuery} from '@tanstack/react-query';
import {queryClient} from 'api/query';

import FullPageSpinner from 'components/Loader/FullPageSpinner';

export interface AuthContext {
  data?: UserWithMeta | null;
  login: (form: LoginForm) => Promise<number | undefined>;
  logout: () => Promise<unknown>;
}

const initialContext: AuthContext = {
  data: null,
  login: () => Promise.resolve(undefined),
  logout: () => Promise.resolve(undefined),
};

const authContext: React.Context<AuthContext> = React.createContext(initialContext);
const initializeUser: () => Promise<UserWithMeta | null> = () =>
  getMyProfile()
    .then(r => r.data)
    .catch(() =>
      getMyJwtProfile()
        .then(r => r.data)
        .catch(() => null)
    );

const AuthProvider = ({children}: PropsWithChildren) => {
  const {data, refetch, isLoading} = useQuery({
    queryKey: ['User'],
    queryFn: initializeUser,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  if (isLoading) {
    return <FullPageSpinner />;
  }

  const login = (form: LoginForm) =>
    authenticate(form).then(response => {
      if (response.status === 200) {
        void refetch();
      }
      return response.status;
    });

  const logout = () =>
    makeLogOutApiCall()
      .then(response => {
        if (response.status === 200) {
          showToast('Your session has expired. Please log in to proceed.', 'error');
          queryClient.clear();
          window.location.replace(`${window.location.origin}/login`);
        }
      })
      .catch(error => {
        console.log(error);
        showToast('Could not log out. Please try again later.', 'error');
      });

  return <authContext.Provider value={{data, login, logout}}>{children}</authContext.Provider>;
};

const useAuth: () => AuthContext = () => React.useContext(authContext);

const useUser = () => {
  const auth = React.useContext(authContext);
  if (!auth.data) {
    window.location.replace(`${window.location.origin}/login`);
    return {user: {}} as User;
  }
  return auth.data;
};

export type User = UserWithMeta;

export {AuthProvider, useAuth, authContext, useUser};
