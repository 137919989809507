import styled from 'styled-components';

import {StageProps} from './types';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${p => p.theme.colors.blue900};
  margin: 8px;
  padding: 8px 12px;
  &:hover {
    background-color: ${p => p.theme.colors.blue950};
  }
`;

export const ContainerExpired = styled(Container)`
  color: ${p => p.theme.colors.blue50};
`;

export const Row = styled.div`
  padding: 0;
  display: flex;
  // flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const RequestReceivedStatus = styled.span<StageProps>`
  color: ${props => {
    if (props.stage.name === 'QuoteAccepted') {
      return props.theme.colors.green500;
    }
    if (props.expired && props.stage.name === 'Started') {
      return props.theme.colors.red700;
    }
    return props.theme.colors.blue200;
  }};
  padding-left: 4px;
  text-align: center;
`;

export const RequestSentStatus = styled.span`
  padding-left: 4px;
  text-align: center;
`;

interface CellProps {
  width: number;
}

export const Cell = styled.div<CellProps>`
  flex-flow: column;
  padding: 6px 0;
  width: ${props => props.width}%;
`;

export const RightCell = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-right;
  padding: 4px 0;
  margin-left: auto;
  margin-right: 0;

  > button {
    margin-left: 12px;
    padding: 6px 18px;
  }
`;

export const GreenHighlight = styled.span`
  color: ${p => p.theme.colors.green500};
`;

export const RedHighlight = styled.span`
  color: ${p => p.theme.colors.red700};
`;
