import Decimal from 'decimal.js';
import dayjs, {Dayjs, ManipulateType} from 'dayjs';

import {User} from 'contexts/auth-context';

import {
  Amount,
  Asset,
  Currency,
  LateSettlementFeeStatus,
  LegType,
  MarkToMarket,
  MarkToMarketStatus,
  Side,
  SpotRate,
  Trade,
  TradeLateSettlementFeesStatus,
  TradeRelatedLateSettlementFee,
  TradeStatus,
} from 'types/api';
import {ExchangeRate} from 'types/exchangeRate';

import {isEqual} from 'lodash';

import {tradeSettlementDeadline} from 'components/popups/LateSettlementFeePopup/types';
import {CashFlowLegType} from 'components/CashFlowTab/types';

import {findExchangeRate, isAssetEqual} from './AssetUtils';
import {getMinutesBetweenDates} from './DayjsUtils';

export function getTradeStatus(trade: Trade): TradeStatus {
  if (trade.earlyMaturityRequest?.status === 'Proposed') return TradeStatus.EarlyMaturityRequested;
  if (trade.earlyMaturityRequest?.status === 'Accepted') return TradeStatus.EarlyMaturityAccepted;

  return trade.status;
}

/**
 * Returns general late fee status for trade for display purposes.
 */
export function getLateSettlementFeeStatus(trade: Trade): LateSettlementFeeStatus | undefined {
  if (trade.relatedLateSettlementFees.length === 0) {
    return;
  } else if (trade.relatedLateSettlementFees.some(lateFee => lateFee.status === 'Accepted')) {
    return 'Accepted';
  } else if (trade.relatedLateSettlementFees.some(lateFee => lateFee.status === 'Pending')) {
    return 'Pending';
  }
}

export function displayLateSettlementFeeStatus(trade: Trade): TradeLateSettlementFeesStatus | undefined {
  const status = getLateSettlementFeeStatus(trade);

  switch (status) {
    case 'Pending':
      return TradeLateSettlementFeesStatus.LateFeeRequested;
    case 'Accepted':
      return TradeLateSettlementFeesStatus.LateFeeAccepted;
    default:
      return undefined;
  }
}

export const getLateFeeRequestsByStatus = (trade: Trade, status: LateSettlementFeeStatus) =>
  trade.relatedLateSettlementFees.filter(request => request.status === status);

export const getUserLateFeeRequestsByStatus = (
  trade: Trade,
  user: User,
  status: LateSettlementFeeStatus,
  type: 'requestor' | 'recipient'
) =>
  getLateFeeRequestsByStatus(trade, status).filter(request =>
    user.legalEntities.find(entity => entity.legalName === request[type])
  );

export const getUserLateFeeRequestsByStatusForLegType = (
  trade: Trade,
  user: User,
  status: LateSettlementFeeStatus,
  type: 'requestor' | 'recipient',
  legType: LegType
) => getUserLateFeeRequestsByStatus(trade, user, status, type).filter(request => request.legType === legType);

export const doesTradeHasAcceptedFeeOnLeg = (trade: Trade, user: User, legType: LegType): boolean =>
  getUserLateFeeRequestsByStatusForLegType(trade, user, 'Accepted', 'requestor', legType).length +
    getUserLateFeeRequestsByStatusForLegType(trade, user, 'Accepted', 'recipient', legType).length >
  0;

export const getReceivedPendingLateFeeRequestsForLegType = (
  trade: Trade,
  user: User,
  legType: LegType
): TradeRelatedLateSettlementFee[] =>
  getUserLateFeeRequestsByStatusForLegType(trade, user, 'Pending', 'recipient', legType);

export const getUserLateFeeSide = (
  lateFee: TradeRelatedLateSettlementFee,
  user: User
): 'requestor' | 'recipient' | undefined => {
  if (user.legalEntities.find(entity => entity.legalName === lateFee.requestor)) {
    return 'requestor';
  } else if (user.legalEntities.find(entity => entity.legalName === lateFee.recipient)) {
    return 'recipient';
  }
};

const hasReceivedLateFeeRequests = (trade: Trade, user: User): boolean =>
  getUserLateFeeRequestsByStatus(trade, user, 'Pending', 'recipient').length > 0;

export const hasPendingLateFeeRequests = (trade: Trade, legType?: LegType): boolean => {
  const pendingLateFeeRequests = getLateFeeRequestsByStatus(trade, 'Pending');

  if (legType) {
    return pendingLateFeeRequests.some(request => request.legType === legType);
  }

  return pendingLateFeeRequests.length > 0;
};

export const hasPendingReceivedUnwindRequest = (trade: Trade): boolean =>
  trade.tradeUnwind?.status === 'Proposed' && trade.tradeUnwind?.canBeAccepted;

export const hasPendingSentUnwindRequest = (trade: Trade): boolean =>
  trade.tradeUnwind?.status === 'Proposed' && !trade.tradeUnwind?.canBeAccepted;

export const hasPendingSentTradeAnnulProposal = (trade: Trade): boolean =>
  trade.tradeAnnul?.status === 'Proposed' && !trade.tradeAnnul?.canBeAccepted;

export const hasPendingReceivedTradeAnnulProposal = (trade: Trade): boolean =>
  trade.tradeAnnul?.status === 'Proposed' && trade.tradeAnnul?.canBeAccepted;

export const hasPendingReceivedEarlyMaturity = (trade: Trade): boolean => !!trade.earlyMaturityRequest?.canBeAccepted;

export const hasPendingSentEarlyMaturity = (trade: Trade): boolean =>
  trade.earlyMaturityRequest !== undefined &&
  trade.earlyMaturityRequest !== null &&
  !trade.earlyMaturityRequest.canBeAccepted;

/**
 * Returns number of trades, requiring an action. Latest update: FX-3447
 *
 * @returns Number represents trades with: Received Late Fee Req., Pending Unwind, Pending Received EMR
 */
export const findTradesWithActions = (trades: Trade[], user: User): number =>
  trades.filter(
    trade =>
      hasReceivedLateFeeRequests(trade, user) ||
      hasPendingReceivedUnwindRequest(trade) ||
      hasPendingReceivedEarlyMaturity(trade) ||
      hasPendingReceivedUnwindRequest(trade) ||
      hasPendingReceivedTradeAnnulProposal(trade)
  ).length;

export const getTradeLateSettlementFeeActions = (trade: Trade, user: User): number =>
  getUserLateFeeRequestsByStatus(trade, user, 'Pending', 'recipient').length;

/**
 * Find an exchange rate from a list for a specific trade or undefined if not found. Reverses the exchange rate if neccessary.
 *
 * @param trade
 * @param exchangeRates
 * @returns
 */
export const findExchangeRateByTrade = (trade: Trade, exchangeRates: ExchangeRate[]): ExchangeRate | undefined =>
  findExchangeRate({base: trade.baseAmount.asset, second: trade.secondAmount.asset}, exchangeRates);

export const calculateMarkToMarket = (
  baseAmount: Amount,
  interestAmount: Amount,
  side: Side,
  initialRate: SpotRate,
  latestFetchedRate: SpotRate
): MarkToMarket => {
  const latestExchangeRate = initialRate.timestamp > latestFetchedRate.timestamp ? initialRate : latestFetchedRate;

  const decBaseAmount = new Decimal(baseAmount.quantity);
  const decInitialRate = new Decimal(initialRate.value);

  const decInterestAmount =
    isAssetEqual(interestAmount.asset, baseAmount.asset) ?
      new Decimal(interestAmount.quantity)
    : new Decimal(interestAmount.quantity).div(decInitialRate);

  const decOne = new Decimal('1.0');
  const decLatestExchangeRate = new Decimal(latestExchangeRate.value);

  const decExchangeRateChange =
    side === 'SellBuy' ?
      decOne.minus(decInitialRate.div(decLatestExchangeRate))
    : decInitialRate.div(decLatestExchangeRate).minus(decOne);

  const mtmQuantity = Math.round(decBaseAmount.plus(decInterestAmount).mul(decExchangeRateChange).toNumber());

  return {
    status: mtmQuantity >= 0 ? MarkToMarketStatus.InTheMoney : MarkToMarketStatus.OutOfTheMoney,
    calculatedAt: latestExchangeRate.timestamp,
    latestExchangeRate,
    amount: {
      quantity: mtmQuantity,
      asset: baseAmount.asset,
    },
  };
};

/**
 *
 * @param trade
 * @param initialRate
 * @param latestFetchedRate This can be with timestamp before or after initialRate timestamp
 * @returns
 */
export const calculateMarkToMarketForTrade = (trade: Trade, latestFetchedRate: SpotRate): MarkToMarket | null => {
  if (!trade.spotRate) {
    return null;
  }

  const initialRate: SpotRate = {
    value: trade.spotRate,
    timestamp: dayjs(trade.createdAt).unix(),
    currencyPair: {base: trade.baseAmount.asset.currency, second: trade.secondAmount.asset.currency},
  };

  return calculateMarkToMarket(trade.baseAmount, trade.ourInterestAmount, trade.side, initialRate, latestFetchedRate);
};

export const convertOutgoingCashflowSign = (leg: Trade[CashFlowLegType]) => ({
  ...leg,
  cashFlowOut: {...leg.cashFlowOut, quantity: leg.cashFlowOut.quantity * -1},
});

export const getCashflowByAsset = (leg: Trade[CashFlowLegType], asset: Asset) =>
  isEqual(asset, leg.cashFlowIn.asset) ? leg.cashFlowIn : leg.cashFlowOut;

export const calculateOutrightRate = (spotRate: number, forwardPoints: number) =>
  (spotRate - forwardPoints / 10000).toFixed(8);

export const convertCashFlowLegTypeToLegType = (legType: CashFlowLegType): LegType => {
  switch (legType) {
    case 'nearLeg':
      return LegType.NearLeg;
    case 'farLeg':
      return LegType.FarLeg;
    default:
      return LegType.NearLeg;
  }
};

export const convertLegTypeToCashFlowLegType = (legType: LegType): CashFlowLegType => {
  switch (legType) {
    case LegType.NearLeg:
      return 'nearLeg';
    case LegType.FarLeg:
      return 'farLeg';
    default:
      return 'nearLeg';
  }
};

/**
 * Fee can only be applied after 30 minutes has passed since the settlementTime
 *
 * @param trade
 * @param legType For which leg you want to calculate settlement time
 * @param incrementValue For how many units you want to increase sheduled time of trade
 * @param incrementType Which type of units to use for incrementration: minutes/days...
 * @returns
 */
export const getTradeSettlementTimeWithDeadLine = (
  trade: Trade,
  legType: LegType,
  incrementValue = tradeSettlementDeadline,
  incrementType: ManipulateType = 'minutes',
  isScheduledTime: boolean = true
): Dayjs => {
  const leg = legType === LegType.NearLeg ? 'nearLeg' : 'farLeg';
  let settlementTime = isScheduledTime ? trade[leg].scheduledTime : trade[leg].actualSettlementTime;

  if (settlementTime === null) {
    settlementTime = trade[leg].scheduledTime;
  }

  return dayjs(settlementTime).add(incrementValue, incrementType);
};

const getCurrencyForReceivedFlag = (trade: Trade, isNearLeg: boolean): Currency => {
  const nearLegAmount = trade.side === 'BuySell' ? 'baseAmount' : 'secondAmount';
  const farLegAmount = trade.side === 'BuySell' ? 'secondAmount' : 'baseAmount';
  return trade[isNearLeg ? nearLegAmount : farLegAmount].asset.currency;
};

const getCurrencyForSentFlag = (trade: Trade, isNearLeg: boolean): Currency => {
  const nearLegAmount = trade.side === 'BuySell' ? 'secondAmount' : 'baseAmount';
  const farLegAmount = trade.side === 'BuySell' ? 'baseAmount' : 'secondAmount';
  return trade[isNearLeg ? nearLegAmount : farLegAmount].asset.currency;
};

/**
 * This function decides under which currency to display a flag as sent checkbox (opposite for flag as received):
 *
 * So if you are B/S EUR/USD then you are selling(sending) USD in the Near leg.
 * You will then be sending EUR back in the far leg. So SENT should only be under USD in the near leg and under EUR in the far leg.
 * If you are S/B EUR/USD, then you are selling (sending) EUR in the near leg
 * so the box will be under EUR for the near leg.
 * It will be under USD for the far leg as you're returning the funds (sending).
 *
 * @param trade
 * @param isNearLeg Are we calculating currency for Near Leg
 * @param isFlagAsReceived Whether to return opposite value or not
 * @returns Currency, under which to display a Checkbox
 */
export const getCurrencyToFlagAsSent = (
  trade: Trade,
  isNearLeg: boolean,
  isFlagAsReceived: boolean = false
): Currency =>
  isFlagAsReceived ? getCurrencyForReceivedFlag(trade, isNearLeg) : getCurrencyForSentFlag(trade, isNearLeg);

// This should be a property on Market/MarketDay
// Replace after correct implementation
export const getMinimumTradeDurationInMinutes = () => 30;

export const isUnwindOrEMRPossible = (trade: Trade): boolean => {
  const now = dayjs();
  return (
    getMinutesBetweenDates(trade.nearLeg.scheduledTime, now) >= 30 &&
    getMinutesBetweenDates(now, trade.farLeg.scheduledTime) >= 30
  );
};
