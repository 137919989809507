import {useFormContext} from 'react-hook-form';
import {Amount} from 'types/api';

import {getRfqAmounts, prepareRFQLegsForRequest} from 'utils/RFQUtils';
import {pairToItem} from 'utils/AssetUtils';

import dayjs from 'dayjs';

import useMinimalProjectedCapacityQuery from 'api/hooks/useMinimalProjectedCapacityQuery';

import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';

import {RFQFormValues} from './useRFQForm';

export default function useCapacityMessage() {
  const methods = useFormContext<RFQFormValues>();

  const [pair, tradedAmount, tradedCurrency, side, spotRangeBigFig, exchangeRate, nearleg, farleg] = methods.watch([
    'currentPair',
    'tradedAmount',
    'tradedAmountCurrency',
    'side',
    'bigfig',
    'exchangeRate.value',
    'nearleg',
    'farleg',
  ]);
  const currentPair = pair && pairToItem(pair);
  const [nearLeg, farLeg] = prepareRFQLegsForRequest(nearleg, farleg);

  const getEarmarkAmount = (): Amount | undefined => {
    if (!currentPair || !exchangeRate || tradedAmount === undefined) return;

    const {sellingRfqAmount: sellingRange} = getRfqAmounts({
      pair,
      side,
      spotRangeBigFig: spotRangeBigFig || 1,
      spotRate: exchangeRate,
      tradedAmount,
      tradedCurrency,
      inMillions: true,
    });

    return {
      asset: sellingRange.asset,
      quantity: Math.max(...(sellingRange.quantity.filter(Boolean) as number[])),
    };
  };
  const projectedEarmark = getEarmarkAmount();
  const {data: projectedCapacity} = useMinimalProjectedCapacityQuery({
    projectedEarmark,
    nearleg: nearLeg ?? dayjs(),
    farleg: farLeg,
  });

  return useAvailableCapacityWarning('Request', projectedEarmark, projectedCapacity);
}
