import styled from 'styled-components';
import {color} from 'utils/theme';

import {SContainer} from 'ui/Layout/styles';

export const SClobBidsTotal = styled(SContainer)`
  padding: 10px 12px 10px 12px;
  font-family: 'LatoBold', sans-serif;
  font-weight: bold;
  border-top: 2px solid ${color('blue700')};
  border-bottom: 2px solid ${color('blue700')};
  font-size: 0.75rem;
  position: relative;
`;

export const SClobBids = styled(SContainer)`
  flex: 1 1 50%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  box-sizing: border-box;
  border-top: 4px solid ${color('gray600')};
  padding: 10px 0 20px;
  overflow-y: scroll;
  text-align: center;
`;
