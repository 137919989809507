import {RateToShow} from 'types/layout';

import useBestRateStore from 'stores/useBestRateStore';

import {Scope} from 'constants/permission-maps';

import {RadioSwitcher} from 'ui/Switcher';
import AmountField from 'ui/AmountField';

import PermissionsGate from 'components/PermissionGate';

import './style.scss';

export const BestRateSelector = () => {
  const {rateToShow, rateAmount, setRateToShow, setRateAmount} = useBestRateStore();
  const changeSwitch = () => {
    const rate: RateToShow = rateToShow === RateToShow.BestRate ? RateToShow.CustomAmount : RateToShow.BestRate;
    setRateToShow(rate);
  };

  return (
    <PermissionsGate scopes={[Scope.ViewClob]}>
      <div className='best-rate-container'>
        <RadioSwitcher
          onChange={changeSwitch}
          leftLabel='BEST RATE'
          leftValue={RateToShow.BestRate}
          rightLabel=''
          rightValue={RateToShow.CustomAmount}
          value={rateToShow}
        />
        <AmountField
          onChange={setRateAmount}
          disabled={rateToShow === RateToShow.BestRate}
          value={rateAmount}
          unit='m'
        />
      </div>
    </PermissionsGate>
  );
};

export default BestRateSelector;
