import {AxiosError} from 'axios';

import {RiskMeasure} from 'types/analytics';
import {ErrorResponse} from 'types/api';
import {showErrorToast} from 'utils/ToastUtils';
import {getMarkToMarketData} from 'api/api';
import {Dayjs} from 'dayjs';

import {useQuery} from '@tanstack/react-query';

export default function useMarkToMarketQuery(startTime: Dayjs, endTime: Dayjs, riskMeasure?: RiskMeasure) {
  const queryResult = useQuery({
    queryKey: ['MarkToMarket', startTime.toISOString(), endTime.toISOString(), riskMeasure],
    queryFn: async () => {
      if (!riskMeasure) return null;
      return getMarkToMarketData(startTime, endTime, riskMeasure)
        .then(({data}) => data)
        .catch((error: AxiosError<ErrorResponse>) => {
          showErrorToast(error);
          throw error;
        });
    },
    enabled: false,
    initialData: {
      relatedCounterparties: [],
      values: [],
    },
  });

  const fetchData = () => {
    void queryResult.refetch();
  };

  return {
    fetchData,
    isLoading: queryResult.isFetching,
    counterparties: queryResult.data?.relatedCounterparties || [],
    mtmData: queryResult.data?.values || [],
  };
}
