import {ReactComponent as BellIdle} from 'assets/icons/bell-idle.svg';
import {ReactComponent as BellAlert} from 'assets/icons/bell-alert.svg';

import Badge from 'ui/Badge';

import {Wrapper} from './styles';

export interface NotificationIconProps {
  active?: boolean;
  notificationCount?: number;
}

const NotificationIcon = ({active = false, notificationCount}: NotificationIconProps) => (
  <Wrapper active={active}>
    {!notificationCount && <BellIdle />}
    {!!notificationCount && (
      <>
        <div className='mt-n1'>
          <BellAlert />
        </div>
        <Badge top='5px' right='0px' num={notificationCount} />
      </>
    )}
  </Wrapper>
);

export default NotificationIcon;
