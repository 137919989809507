import React from 'react';
import {useIntl} from 'react-intl';

import {displayLegPrefixAndDateTime} from 'utils/DayjsUtils';
import {displayAmountWithCode, displayAmountWithUnit} from 'utils/AmountUtils';
import {displayAssetPairWithSide} from 'utils/AssetUtils';
import {displayParty} from 'utils/utils';
import {Amount} from 'types/api';
import {getQuoteStatusText, getRequestStatusText, isRFQRequestQuotesCancelled} from 'utils/RFQUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {rfqRequestStatuses} from 'constants/messages/labels';

import {GreenHighlight, Cell, ContainerExpired, RightCell, Row, RequestSentStatus} from './styles';
import {PropsPreviousRequest} from '../RFQRequestSent/types';

const PreviousRequestSent = (props: PropsPreviousRequest) => {
  const {
    requestData: {
      tradedAmount,
      baseAsset,
      secondAsset,
      maturityTime: farLegTime,
      maturityTimeInput,
      requestorSide,
      requesteesAndQuotes,
    },
  } = props;

  const assetPair = {
    base: baseAsset,
    second: secondAsset,
  };

  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);

  const rejectionReasonsCount = requesteesAndQuotes.filter(
    r => r.stage.name === 'RequestRejected' && r.stage.reasons.length > 0
  ).length;

  const acceptedQuotes = requesteesAndQuotes.flatMap(raq =>
    raq.quote && raq.stage.name === 'QuoteAccepted' ? [raq.quote] : []
  );

  const totalQuoteAccepted: Amount = {
    quantity: acceptedQuotes.map(quote => quote.tradedAmount.quantity).reduce((sum, curr) => sum + curr, 0),
    asset: tradedAmount.asset,
  };

  const status = getRequestStatusText(props.requestData);

  const {formatMessage} = useIntl();

  return (
    <ContainerExpired>
      <Row>
        <Cell data-testid='asset-pair-value' width={32}>
          {displayAssetPairWithSide(assetPair, requestorSide, true)}
        </Cell>
        <Cell data-testid='counterparty-value' width={32} style={{textTransform: 'uppercase'}}>
          {requesteesAndQuotes
            .map<React.ReactNode>(r =>
              acceptedQuotes.map(q => q.quoter).includes(r.requestee.legalName) ?
                <GreenHighlight data-testid='accepted-counterparty-value' key={r.id}>
                  {displayParty(r.requestee)}
                </GreenHighlight>
              : <span data-testid='not-accepted-counterparty-value' key={r.id}>
                  {displayParty(r.requestee)}
                </span>
            )
            .reduce((prev, curr) => [prev, ', ', curr])}
        </Cell>
        <RightCell data-testid='amount-value'>
          {displayAmountWithCode(totalQuoteAccepted, false, inMillions)}/
          {displayAmountWithUnit(tradedAmount, inMillions)}
        </RightCell>
      </Row>
      <Row>
        <Cell width={35}>{displayLegPrefixAndDateTime(farLegTime, maturityTimeInput.transactionDate, 'HH:mm')}</Cell>
        <RightCell>
          {rejectionReasonsCount > 0 ? 'ⓘ ' : ''}
          <RequestSentStatus data-testid='status-value'>
            {isRFQRequestQuotesCancelled(props.requestData) ?
              getQuoteStatusText(false, false, 'QuoteCancelled')
            : formatMessage(rfqRequestStatuses[status])}
          </RequestSentStatus>
        </RightCell>
      </Row>
    </ContainerExpired>
  );
};

export default PreviousRequestSent;
