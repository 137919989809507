import {CSSProperties, PropsWithChildren} from 'react';

import {BoardHeaderWrapper} from './styles';

interface BoardHeaderProps {
  title?: string;
  style?: CSSProperties;
  fixedPosition?: boolean;
  borderBottom?: string;
}

export const BoardHeader = ({title, children, ...restProps}: PropsWithChildren<BoardHeaderProps>) => (
  <BoardHeaderWrapper data-testid='board-header' {...restProps}>
    {title && (
      <div className='title'>
        <span>{title}</span>
      </div>
    )}
    {children}
  </BoardHeaderWrapper>
);
