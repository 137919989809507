import React from 'react';

import './FullPageSpinner.scss';
/* eslint-disable @typescript-eslint/naming-convention, id-denylist, id-match */
const FullPageSpinner: React.FC = () => (
  <div className='full-page-spinner'>
    <svg>
      <g>
        <path d='M 50,100 A 1,1 0 0 1 50,0' />
      </g>
      <g>
        <path d='M 50,75 A 1,1 0 0 0 50,-25' />
      </g>
      <defs>
        <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor='#FF5070' stopOpacity={1} />
          <stop offset='100%' stopColor='#79F2BE' stopOpacity={1} />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export default FullPageSpinner;
