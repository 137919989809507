import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';
import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';
import {HistoryType} from 'types/blotter';

import Badge from 'ui/Badge';

import {Option} from '../styles';
import {tabs} from '../constants';

interface BlotterTabsBadgesProps {
  selectedBlotterTab: HistoryType;
  setSelectedBlotterTab: (newType: HistoryType) => void;
}

export const BlotterTabsBadges = ({selectedBlotterTab, setSelectedBlotterTab}: BlotterTabsBadgesProps) => {
  const {tradesRequiringAction} = useRealTimeTradesQuery();
  const {requestsRequiringAction} = useRealTimeRequestsQuery();

  const changeHistoryTypeHandler = (newType: HistoryType) => {
    if (selectedBlotterTab === newType) {
      return;
    }
    setSelectedBlotterTab(newType);
  };

  const blotterTabsBadges = {
    [HistoryType.Trades]: tradesRequiringAction,
    [HistoryType.Requests]: requestsRequiringAction,
  };

  return (
    <>
      {Object.keys(tabs).map(historyType => {
        const tabHaveBadge =
          Object.keys(blotterTabsBadges).includes(historyType) &&
          blotterTabsBadges[historyType as keyof typeof blotterTabsBadges] > 0;
        return (
          <Option
            isActive={selectedBlotterTab === (historyType as HistoryType)}
            key={historyType}
            id={`${historyType}-button`}
            onClick={() => changeHistoryTypeHandler(historyType as HistoryType)}
            role='button'
          >
            {historyType}
            {tabHaveBadge && (
              <Badge top='10px' right='7px' num={blotterTabsBadges[historyType as keyof typeof blotterTabsBadges]} />
            )}
          </Option>
        );
      })}
    </>
  );
};
