import styled from 'styled-components';

export const Container = styled.div`
  height: inherit;
  background-color: ${p => p.theme.colors.blue680};
  padding: 0;
  font-size: 14px;
`;

export const ColumnStyled = styled.div`
  width: 50%;
  height: calc(100% - 50px);
  overflow-y: auto;
  float: left;
  padding: 0.4em;
`;

export const ListContainer = styled.div`
  margin-bottom: 1em;
`;

export const ListHeader = styled.div`
  width: 100%;
  padding: 0.8em 1em;
  color: ${p => p.theme.colors.white};
  background: ${p => p.theme.colors.gray500};
  text-transform: uppercase;
`;

export const ListItem = styled.div`
  background-color: ${p => p.theme.colors.blue900};
  color: ${p => p.theme.colors.blue50};
  margin: 0;
  cursor: pointer;
  &:hover {
    background-color: ${p => p.theme.colors.blue950};
  }
`;
