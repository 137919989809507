import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {color} from 'utils/theme';

export const BoardHeaderWrapper = styled.div<{fixedPosition?: boolean; borderBottom?: string}>`
  position: ${p => (p.fixedPosition ? 'fixed' : 'unset')};
  height: 50px;
  width: 100%;
  top: 70px;
  border-bottom: ${ifProp('borderBottom', p => p.borderBottom, `2px solid ${color('blue700')}`)};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .title,
  > .switch,
  .subset-container {
    border-right: 2px solid ${color('blue700')};
  }
  .subset-container,
  .best-rate-container {
    height: 100%;
  }
  .title {
    font-size: 14px;
    line-height: 14px;
    color: ${color('white')};
    padding: 0 0 0 20px;
    height: 100%;
    min-width: 220px;
    text-transform: uppercase;

    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  > .switch {
    height: 100%;
    width: 220px;
  }
  .best-rate-container {
    padding-left: 12px;

    .switch {
      width: 140px;
    }
  }
`;
