import {displayDate, displayNearLegAsAsap} from 'utils/DayjsUtils';

import {IntlShape} from 'react-intl';

import {AssetPair, RFQRequest, UserWithMeta} from 'types/api';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayAssetPairWithSide} from 'utils/AssetUtils';
import {displayEntities} from 'utils/utils';

import {getInitialFXRate, getRequestStatusText} from 'utils/RFQUtils';

import {fieldTitles} from 'constants/messages/labels';
import {ColumnDef} from 'ui/GridTable';

import FieldTitle from 'ui/FieldTitle';
import {DateContainer} from 'containers/Blotter/styles';
import {RFQRequestStatusNode} from 'components/RFQNegotiationStatusNode/RFQNegotiationStatusNode';

export const getBlotterRequestsTableColumns = (
  formatMessage: IntlShape['formatMessage'],
  user: UserWithMeta,
  shouldDisplayInMillions: (assetPair: AssetPair) => boolean
): ColumnDef<RFQRequest>[] => [
  {
    accessorKey: 'createdAt',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.dateAndTime)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer data-id={info.row.original.id}>
        <span>{displayDate(info.row.original.createdAt, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.createdAt, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 13.1,
    },
    sortingFn: 'datetime',
    sortDescFirst: true,
  },
  {
    accessorKey: 'sentReceived',
    enableSorting: false,
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.sentReceived)}</FieldTitle>
      </span>
    ),
    cell: info => <span>{info.row.original.isReceived ? 'RECEIVED' : 'SENT'}</span>,
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
  },
  {
    accessorKey: 'marketSide',
    enableSorting: false,
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.marketSide)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span>
        {displayAssetPairWithSide(
          {base: info.row.original.baseAsset, second: info.row.original.secondAsset},
          info.row.original.isReceived ? info.row.original.requesteeSide : info.row.original.requestorSide,
          true
        )}
      </span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
  },
  {
    accessorKey: 'counterparty',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.counterparty)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span>
        {displayEntities(
          user.legalEntities.map(x => x.legalName).includes(info.row.original.requestor.legalName) ?
            info.row.original.requesteesAndQuotes.map(requesteesAndQuote => requesteesAndQuote.requestee)
          : [info.row.original.requestor]
        )}
      </span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 9.1,
    },
    sortingFn: (rowA, rowB) => {
      const rowACparties = displayEntities(
        user.legalEntities.map(x => x.legalName).includes(rowA.original.requestor.legalName) ?
          rowA.original.requesteesAndQuotes.map(requesteesAndQuote => requesteesAndQuote.requestee)
        : [rowA.original.requestor]
      );
      const rowBCparties = displayEntities(
        user.legalEntities.map(x => x.legalName).includes(rowB.original.requestor.legalName) ?
          rowB.original.requesteesAndQuotes.map(requesteesAndQuote => requesteesAndQuote.requestee)
        : [rowB.original.requestor]
      );

      return rowACparties.localeCompare(rowBCparties);
    },
  },
  {
    accessorKey: 'status',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.status)}</FieldTitle>
      </span>
    ),
    cell: info => <RFQRequestStatusNode statusText={getRequestStatusText(info.row.original)} />,
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortingFn: (rowA, rowB) => {
      const rowAStatus = getRequestStatusText(rowA.original);
      const rowBStatus = getRequestStatusText(rowB.original);

      return rowAStatus.localeCompare(rowBStatus);
    },
  },
  {
    accessorKey: 'tradedAmount.quantity',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.tradedAmt)}</FieldTitle>
      </span>
    ),
    cell: info => {
      const inMillions = shouldDisplayInMillions({
        base: info.row.original.baseAsset,
        second: info.row.original.secondAsset,
      });

      return <span>{displayAmountWithCode(info.row.original.tradedAmount, false, inMillions)}</span>;
    },
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'nearLegTime',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.startDate)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <span>
        {info.row.original.nearLegTime ?
          <DateContainer>
            <span>{displayDate(info.row.original.nearLegTime, 'DD MMM YY')}</span>
            <span>{displayDate(info.row.original.nearLegTime, 'HH:mm:ss')}</span>
          </DateContainer>
        : <span>{displayNearLegAsAsap(false)}</span>}
      </span>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 11.1,
    },
    sortDescFirst: true,
    sortingFn: 'datetime',
    sortUndefined: 1,
  },
  {
    accessorKey: 'maturityTime',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.endDate)}</FieldTitle>
      </span>
    ),
    cell: info => (
      <DateContainer>
        <span>{displayDate(info.row.original.maturityTime, 'DD MMM YY')}</span>
        <span>{displayDate(info.row.original.maturityTime, 'HH:mm:ss')}</span>
      </DateContainer>
    ),
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 13.1,
    },
    sortDescFirst: true,
    sortingFn: 'datetime',
  },
  {
    accessorKey: 'initialRate',
    header: () => (
      <span style={{paddingRight: '5px'}}>
        <FieldTitle>{formatMessage(fieldTitles.initialRate)}</FieldTitle>
      </span>
    ),
    cell: info => <span>{getInitialFXRate(info.row.original.requestType)?.toString() ?? '--'}</span>,
    meta: {
      align: 'center',
      alignItems: 'center',
      size: 9.1,
    },
    sortingFn: (rowA, rowB) => {
      const rowARate = getInitialFXRate(rowA.original.requestType)?.toString() ?? '--';
      const rowBRate = getInitialFXRate(rowB.original.requestType)?.toString() ?? '--';

      return rowARate.localeCompare(rowBRate);
    },
  },
];
