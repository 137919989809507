import React, {useState} from 'react';

import dayjs from 'dayjs';
import {AxiosResponse, AxiosError} from 'axios';
import {createNewOrder} from 'api/api';
import {showToast, showErrorToast} from 'utils/ToastUtils';
import {To, convertQuantityToAmount} from 'utils/AmountUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {MarketType, NewOrder, getOrderFormMessage, getOrderFormMessageType} from 'types/orderForm';
import {Amount, ErrorResponse} from 'types/api';
import {ReferenceData} from 'types/layout';

import {useQueryClient} from '@tanstack/react-query';

import {confirmationTabs} from 'constants/tabs';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import Button from 'ui/Button';
import Loader from 'ui/Loader';

import {FormType, Economics} from 'components/Economics/Economics';

import Details from './Details';
import {ConfirmationProps} from './types';
import {ConfirmationContainer, SButtonsContainer} from './styles';

const Confirmation: React.FC<ConfirmationProps> = ({onEdit, orderForm, onSuccess}: ConfirmationProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(confirmationTabs[0]);
  const client = useQueryClient();

  const {
    assetPair: {base, second},
    side,
    marketType,
    isNonAggressorOnly,
    baseAmount,
    initialRate,
    rate,
    spotRangeBigFig,
    validUntil,
    maturity,
    counterparty,
  } = orderForm;

  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base, second});

  if (baseAmount === undefined) {
    return <></>;
  }

  function onChangeTab(newTab: Tab): void {
    setTab(newTab);
  }

  // Calculate values
  const calculatedBaseAmount: Amount = convertQuantityToAmount(baseAmount, To.Store, base, true, inMillions);

  function onSubmit(): void {
    if (
      marketType === MarketType.Limit &&
      (!rate || (rate.referenceData === ReferenceData.ForwardPoints && rate.value === undefined))
    ) {
      return;
    }
    setLoading(true);
    const postData: NewOrder = {
      side,
      marketType,
      isNonAggressor: isNonAggressorOnly,
      baseAsset: base,
      secondAsset: second,
      baseAmount: calculatedBaseAmount,
      spotRange: spotRangeBigFig / 100,
      counterparty,
      validUntil: dayjs(validUntil),
      maturityTime: dayjs(maturity),
      initialRate,
    };

    if (rate?.referenceData === ReferenceData.ImpliedYield) {
      postData.interestRate = rate.value;
    }
    if (rate?.referenceData === ReferenceData.ForwardPoints) {
      postData.forwardPoints = rate.value;
    }
    if (marketType === MarketType.Market) {
      delete postData.interestRate;
      delete postData.forwardPoints;
    }

    createNewOrder(postData)
      .then(async (response: AxiosResponse) => {
        const message = getOrderFormMessage(response.status);
        const type = getOrderFormMessageType(response.status);

        setLoading(false);
        showToast(message, type);
        onSuccess?.();
        await client.invalidateQueries({queryKey: ['OrderBook']});
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        showErrorToast(err);
        setLoading(false);
      });
  }

  return (
    <ConfirmationContainer data-testid='confirmation-container'>
      <Tabs tabs={confirmationTabs} value={tab} onChange={onChangeTab} />
      {tab.value === 'details' && <Details orderForm={orderForm} />}
      {tab.value === 'economics' && (
        <Economics
          formType={FormType.Order}
          tradedAmount={baseAmount}
          tradedAsset={base}
          counterAsset={second}
          marketType={marketType}
          side={side}
          spotRange={spotRangeBigFig}
          spotRate={initialRate}
          isNonAggressorOnly={isNonAggressorOnly}
          maturity={dayjs(maturity)}
          rate={rate}
          assetPair={orderForm.assetPair}
        />
      )}
      <SButtonsContainer data-testid='button-container'>
        <Button buttonStyle='grey' width='45%' onClick={onEdit}>
          EDIT
        </Button>
        <Button width='45%' onClick={onSubmit}>
          Confirm
        </Button>
      </SButtonsContainer>
      {loading && <Loader />}
    </ConfirmationContainer>
  );
};

export default Confirmation;
