import {defineMessages} from '@formatjs/intl';
import {RFQRequestStatusText} from 'types/api';

export const fieldTitles = defineMessages({
  delayedLeg: {
    id: 'app.fields.delayedLeg',
    description: 'Field title, delayed leg.',
    defaultMessage: 'Delayed leg',
  },
  youPaid: {
    id: 'app.fields.youPaid',
    description: 'Field title, you paid.',
    defaultMessage: 'You paid',
  },
  youReceived: {
    id: 'app.fields.youReceived',
    description: 'Field title, you received.',
    defaultMessage: 'You received',
  },
  youSent: {
    id: 'app.fields.youSent',
    description: 'Field title, you sent.',
    defaultMessage: 'You sent',
  },
  youShouldHaveReceived: {
    id: 'app.fields.youShouldHaveReceived',
    description: 'Field title, you should have received.',
    defaultMessage: 'You should have received',
  },
  youSentLate: {
    id: 'app.fields.youSentLate',
    description: 'Field title, you sent late.',
    defaultMessage: 'You sent late (CP claimed)',
  },
  youPaidLate: {
    id: 'app.fields.youPaidLate',
    description: 'Field title, you paid late.',
    defaultMessage: 'You paid late (CP claimed)',
  },
  settlementDeadline: {
    id: 'app.fields.settlementDeadline',
    description: 'Field title, settlement deadline.',
    defaultMessage: 'Settlement deadline',
  },
  dateReceived: {
    id: 'app.fields.dateReceived',
    description: 'Field title, date received.',
    defaultMessage: 'Date received',
  },
  dateReceivedClaimed: {
    id: 'app.fields.dateReceivedClaimed',
    description: 'Field title, date received, claimed.',
    defaultMessage: 'Date received (CP claimed)',
  },
  timeReceived: {
    id: 'app.fields.timeReceived',
    description: 'Field title, time received.',
    defaultMessage: 'Time received',
  },
  timeReceivedClaimed: {
    id: 'app.fields.timeReceivedClaimed',
    description: 'Field title, time received, claimed.',
    defaultMessage: 'Time received (CP claimed)',
  },
  delay: {
    id: 'app.fields.delay',
    description: 'Field title, delay',
    defaultMessage: 'Delay (DD:HH:MM).',
  },
  delayClaimed: {
    id: 'app.fields.delayClaimed',
    description: 'Field title, delay, claimed',
    defaultMessage: 'Claimed delay (DD:HH:MM).',
  },
  requestLateFeeHeader: {
    id: 'app.fields.requestLateFeeHeader',
    description: 'Header, request late fee',
    defaultMessage: 'Request late fee',
  },
  reviewLateFeeHeader: {
    id: 'app.fields.reviewLateFeeHeader',
    description: 'Header, review late fee',
    defaultMessage: 'Late settlement: review late fee',
  },
  lateFeeRate: {
    id: 'app.fields.lateFeeRate',
    description: 'Field title, late fee rate.',
    defaultMessage: 'Late fee rate',
  },
  lateFeeAmount: {
    id: 'app.fields.lateFeeAmount',
    description: 'Field title, late fee amount.',
    defaultMessage: 'Late fee amount',
  },
  lateFeeBreakdown: {
    id: 'app.fields.lateFeeBreakdown',
    description: 'Field title, late fee breakdown.',
    defaultMessage: 'Late fee breakdown',
  },
  market: {
    id: 'app.fields.market',
    description: 'Field title, market.',
    defaultMessage: 'Market',
  },
  leg: {
    id: 'app.fields.leg',
    description: 'Field leg',
    defaultMessage: 'Leg',
  },
  counterparty: {
    id: 'app.fields.counterparty',
    description: 'Field title, counterparty.',
    defaultMessage: 'Counterparty',
  },
  tradeDetailsCounterparty: {
    id: 'app.fields.tradeDetailsCounterparty',
    description: 'Field title, counterparty.',
    defaultMessage: 'Counterparty',
  },
  counterparties: {
    id: 'app.fields.counterparties',
    description: 'Field title, counterparties.',
    defaultMessage: 'Counterparties',
  },
  yourSide: {
    id: 'app.fields.yourSide',
    description: 'Field title, your side.',
    defaultMessage: 'Your side',
  },
  quoteAmt: {
    id: 'app.fields.quoteAmt',
    description: 'Field title, quote Amt.',
    defaultMessage: 'Quote Amt',
  },
  quoteSecondAmt: {
    id: 'app.fields.quoteSecondAmt',
    description: 'Field title, quote Second Amt.',
    defaultMessage: 'Quote Second Amt',
  },
  minSecondAmt: {
    id: 'app.fields.minSecondAmt',
    description: 'Field title, min second amount.',
    defaultMessage: 'Min Second Amt',
  },
  maxSecondAmt: {
    id: 'app.fields.maxSecondAmt',
    description: 'Field title, max second amount.',
    defaultMessage: 'Max Second Amt',
  },
  spotRange: {
    id: 'app.fields.spotRange',
    description: 'Field title, spot range.',
    defaultMessage: 'Spot range',
  },
  spotRangeAct: {
    id: 'app.fields.spotRangeAct',
    description: 'Field title, spotRange Act.',
    defaultMessage: 'Spot range act',
  },
  spotRate: {
    id: 'app.fields.spotRate',
    description: 'Field title, spot rate.',
    defaultMessage: 'Spot rate',
  },
  outrightRate: {
    id: 'app.fields.outrightRate',
    description: 'Field title, outright rate.',
    defaultMessage: 'Outright rate',
  },
  nearLeg: {
    id: 'app.fields.nearLeg',
    description: 'Field title, near leg.',
    defaultMessage: 'Near leg',
  },
  nearLegDate: {
    id: 'app.fields.nearLegDate',
    description: 'Field title, near leg date.',
    defaultMessage: 'Near leg date',
  },
  nearLegTime: {
    id: 'app.fields.nearLegTime',
    description: 'Field title, near leg time.',
    defaultMessage: 'Near leg time',
  },
  farLeg: {
    id: 'app.fields.farLeg',
    description: 'Field title, far leg.',
    defaultMessage: 'Far leg',
  },
  farLegDate: {
    id: 'app.fields.farLegDate',
    description: 'Field title, far leg date.',
    defaultMessage: 'Far leg date',
  },
  farLegTime: {
    id: 'app.fields.farLegTime',
    description: 'Field title, far leg time.',
    defaultMessage: 'Far leg time',
  },
  onLeg: {
    id: 'app.fields.onLeg',
    description: 'Field title, on leg.',
    defaultMessage: 'On leg',
  },
  onLegDate: {
    id: 'app.fields.onLegDate',
    description: 'Field title, on leg date.',
    defaultMessage: 'On leg date',
  },
  onLegTime: {
    id: 'app.fields.onLegTime',
    description: 'Field title, on leg time.',
    defaultMessage: 'On leg time',
  },
  offLeg: {
    id: 'app.fields.offLeg',
    description: 'Field title, off leg.',
    defaultMessage: 'Off leg',
  },
  offLegDate: {
    id: 'app.fields.offLegDate',
    description: 'Field title, off leg date.',
    defaultMessage: 'Off leg date',
  },
  offLegTime: {
    id: 'app.fields.offLegTime',
    description: 'Field title, off leg time.',
    defaultMessage: 'Off leg time',
  },
  validFor: {
    id: 'app.fields.validFor',
    description: 'Field title, valid for.',
    defaultMessage: 'Valid for',
  },
  quoteValidFor: {
    id: 'app.fields.quoteValidFor',
    description: 'Field title, quote valid for.',
    defaultMessage: 'Quote valid for',
  },
  requestValidFor: {
    id: 'app.fields.requestValidFor',
    description: 'Field title, request valid for.',
    defaultMessage: 'Request valid for',
  },
  impYield: {
    id: 'app.field.impYield',
    description: 'Field title, imp. yield',
    defaultMessage: 'Imp. yield',
  },
  tradeDetialsImpYield: {
    id: 'app.field.tradeDetailsImpYield',
    description: 'Field title, imp. yield',
    defaultMessage: 'Implied yield',
  },
  impYieldOrRate: {
    id: 'app.field.impYieldToRate',
    description: 'Field title, impYield / Rate',
    defaultMessage: 'Rate',
  },
  suggestedImpYield: {
    id: 'app.field.suggestedImpYield',
    description: 'Field title, suggested imp. yield',
    defaultMessage: 'Suggested Imp. yield',
  },
  suggestImpYield: {
    id: 'app.field.suggestImpYield',
    description: 'Field title, suggest imp. yield',
    defaultMessage: 'Suggest Imp. yield',
  },
  suggestFwdPts: {
    id: 'app.field.suggestFwdPts',
    description: 'Field title, suggest Fwd pts',
    defaultMessage: 'Suggest Fwd. Pts',
  },
  suggestedFwdPts: {
    id: 'app.field.suggestedFwdPts',
    description: 'Field title, suggested Fwd pts',
    defaultMessage: 'Suggested Fwd. Pts',
  },
  suggestedRate: {
    id: 'app.field.suggestedRate',
    description: 'Field title, suggested rate',
    defaultMessage: 'Suggested rate',
  },
  entity: {
    id: 'app.field.entity',
    description: 'Field title, entity',
    defaultMessage: 'entity',
  },
  traderInfo: {
    id: 'app.field.traderInfo',
    description: 'Field title, trader info',
    defaultMessage: 'Trader Info',
  },
  type: {
    id: 'app.field.type',
    description: 'Field title, type',
    defaultMessage: 'type',
  },
  baseAmt: {
    id: 'app.field.quoteRequestAmt',
    description: 'Field title, base Amt',
    defaultMessage: 'Base amt',
  },
  requestAmt: {
    id: 'app.fields.requestAmt',
    description: 'Field title, request amt',
    defaultMessage: 'Request Amt',
  },
  quoteRequestAmt: {
    id: 'app.field.quoteRequestAmt',
    description: 'Field title, quote request Amt',
    defaultMessage: 'Quote request amt',
  },
  tradedAmt: {
    id: 'app.fields.tradedAmt',
    description: 'Field title, traded amt',
    defaultMessage: 'Traded Amt',
  },
  quoteTradedAmt: {
    id: 'app.field.quoteTradedAmt',
    description: 'Field title, quote traded Amt',
    defaultMessage: 'Quote traded amt',
  },
  repoAmt: {
    id: 'app.field.repoAmt',
    description: 'Field title, repo amt',
    defaultMessage: 'Repo amt',
  },
  securitiesAmt: {
    id: 'app.field.securitiesAmt',
    description: 'Field title, Securities Amt',
    defaultMessage: 'Securities Amt',
  },
  secondAmt: {
    id: 'app.field.secondAmt',
    description: 'Field title, Second amount',
    defaultMessage: 'Second Amt',
  },
  counterAmt: {
    id: 'app.field.counterAmt',
    description: 'Field title, Counter amount',
    defaultMessage: 'Counter Amt',
  },
  youBuy: {
    id: 'youBuy',
    description: 'Field title, you buy amt',
    defaultMessage: 'You buy',
  },
  youSell: {
    id: 'youSell',
    description: 'Field title, you sell amt',
    defaultMessage: 'You sell',
  },
  interest: {
    id: 'app.field.interest',
    description: 'Field title, Interest',
    defaultMessage: 'Interest',
  },
  interestSide: {
    id: 'app.field.interestSide',
    description: 'Field title, Interest side',
    defaultMessage: 'Interest side',
  },
  interestAmt: {
    id: 'app.field.interestAmt',
    description: 'Field title, Interest Amt',
    defaultMessage: 'Interest Amount',
  },
  rate: {
    id: 'app.field.rate',
    description: 'Field title, rate',
    defaultMessage: 'rate',
  },
  validUntil: {
    id: 'app.field.validUntil',
    description: 'Field title, valid until',
    defaultMessage: 'Valid until',
  },
  maturity: {
    id: 'app.field.maturity',
    description: 'Field title, maturity',
    defaultMessage: 'Maturity',
  },
  maturesAt: {
    id: 'app.field.maturesAt',
    description: 'Field title, Matures At',
    defaultMessage: 'Matures at',
  },
  nonAggressorOnly: {
    id: 'app.field.nonAggressorOnly',
    description: 'Field title, non-aggressor only',
    defaultMessage: 'Non-aggressor only',
  },
  aggressorType: {
    id: 'app.field.aggressorType',
    description: 'Field title, aggressor Type',
    defaultMessage: 'Aggressor Type',
  },
  reference: {
    id: 'app.field.reference',
    description: 'Field title, reference Type',
    defaultMessage: 'Ref',
  },
  brokerTradeReference: {
    id: 'app.field.brokerTradeReference',
    description: 'Field title, broker trade reference Type',
    defaultMessage: 'Broker trade ref',
  },
  reportTrackingNumber: {
    id: 'app.field.reportTrackingNumber',
    description: 'Field title, Report tracking number Type',
    defaultMessage: 'Report tracking no (RTN)',
  },
  suggestRate: {
    id: 'app.field.suggestRate',
    description: 'Field title, suggest rate',
    defaultMessage: 'Suggest rate',
  },
  amount: {
    id: 'app.field.amount',
    description: 'Field title, amount',
    defaultMessage: 'Amount',
  },
  initialRate: {
    id: 'app.field.initialRate',
    description: 'Field title, initial rate',
    defaultMessage: 'Initial XR',
  },
  nonAggressor: {
    id: 'app.field.nonAggressor',
    description: 'Field title, nonAggressor',
    defaultMessage: 'Non-Aggressor',
  },
  side: {
    id: 'app.field.side',
    description: 'Field title, side',
    defaultMessage: 'Side',
  },
  fwdPts: {
    id: 'app.field.fwdPts',
    description: 'Field title, Forward pts',
    defaultMessage: 'Fwd. pts',
  },
  tradeDetailsFwdPts: {
    id: 'app.field.tradeDetailsFwdPts',
    description: 'Field title, Forward pts',
    defaultMessage: 'Forward points',
  },
  fwdPtsOrRate: {
    id: 'app.field.fwdPtsOrRate',
    description: 'Field title, Forward pts / rate',
    defaultMessage: 'Fwd. pts / rate',
  },
  earmarkAmount: {
    id: 'app.field.earmarkAmt',
    description: 'Field title, earmark amount',
    defaultMessage: 'Earmark amt',
  },
  lastTraded: {
    id: 'app.field.lastTraded',
    description: 'Field title, last Traded',
    defaultMessage: 'Last traded',
  },
  clobPricing: {
    id: 'app.field.clobPricing',
    description: 'Field title, clob Pricing',
    defaultMessage: 'Clob pricing',
  },
  finteumTradeId: {
    id: 'app.field.uniqueTradeId',
    description: 'Field title, Finteum Trade Id',
    defaultMessage: 'Finteum Trade ID',
  },
  uniqueOrderId: {
    id: 'app.field.uniqueOrderId',
    description: 'Field title, unique Order Id',
    defaultMessage: 'Unique Order ID',
  },
  uniqueQuoteId: {
    id: 'app.field.uniqueQuoteId',
    description: 'Field title, unique Quote Id',
    defaultMessage: 'Unique Quote ID',
  },
  uniqueRequestId: {
    id: 'app.field.uniqueRequestId',
    description: 'Field title, unique Request Id',
    defaultMessage: 'Unique Request ID',
  },
  status: {
    id: 'app.field.status',
    description: 'Field title, status',
    defaultMessage: 'Status',
  },
  markToMarket: {
    id: 'app.field.markToMarket',
    description: 'Mark-to-Market',
    defaultMessage: 'MtM',
  },
  statusReason: {
    id: 'app.field.statusReason ',
    description: 'Field title, status reason',
    defaultMessage: 'Status reason',
  },
  orderSide: {
    id: 'app.field.orderSide',
    description: 'Field title, order Side',
    defaultMessage: 'Order side',
  },
  orderType: {
    id: 'app.field.orderType',
    description: 'Field title, order Type',
    defaultMessage: 'Order type',
  },
  orderCreated: {
    id: 'app.field.orderCreated',
    description: 'Field title, order Created',
    defaultMessage: 'Order created',
  },
  creationDate: {
    id: 'app.field.creationDate',
    description: 'Field title, creation date',
    defaultMessage: 'Creation date',
  },
  quoteCreatedAt: {
    id: 'app.field.quoteCreatedAt',
    description: 'Field title, quote created At',
    defaultMessage: 'Quote created',
  },
  creationAt: {
    id: 'app.field.creationAt',
    description: 'Field title, created at',
    defaultMessage: 'Created at',
  },
  validatedAt: {
    id: 'app.field.validatedAt',
    description: 'Field title, validated at',
    defaultMessage: 'Validated at',
  },
  executedAt: {
    id: 'app.field.executedAt',
    description: 'Field title, executed at',
    defaultMessage: 'Executed at',
  },
  resumedAt: {
    id: 'app.field.resumedAt',
    description: 'Field title, resumed At',
    defaultMessage: 'Resumed at',
  },
  orderValid: {
    id: 'app.field.orderValid',
    description: 'Field title, order Valid',
    defaultMessage: 'Order valid',
  },
  actMatDate: {
    id: 'app.field.actMatDate',
    description: 'Field title, actMatDate',
    defaultMessage: 'Early mat date',
  },
  actMatTime: {
    id: 'app.field.actMatTime',
    description: 'Field title, actMatTime',
    defaultMessage: 'Early mat time',
  },
  expTradeDur: {
    id: 'app.field.expTradeDur',
    description: 'Field title, expTradeDur',
    defaultMessage: 'Exp Trade Dur',
  },
  actTradeDur: {
    id: 'app.field.actTradeDur',
    description: 'Field title, actTradeDur',
    defaultMessage: 'Early mat dur',
  },
  dateAndTime: {
    id: 'app.field.dateAndTime',
    description: 'Field title, date and time',
    defaultMessage: 'Date & Time',
  },
  marketSide: {
    id: 'app.field.marketSide',
    description: 'Field title, market side',
    defaultMessage: 'Market side',
  },
  pair: {
    id: 'app.field.pair',
    description: 'Field title, pair',
    defaultMessage: 'Pair',
  },
  earmark: {
    id: 'app.field.earmark',
    description: 'Field title, earmark',
    defaultMessage: 'Earmark',
  },
  filled: {
    id: 'app.field.filled',
    description: 'Field title, filled',
    defaultMessage: 'Filled',
  },
  sentReceived: {
    id: 'app.field.sentReceived',
    description: 'Field title, sent/received',
    defaultMessage: 'Sent / received',
  },
  startDate: {
    id: 'app.field.startDate',
    description: 'Field title, start Date',
    defaultMessage: 'Start date',
  },
  endDate: {
    id: 'app.field.endDate',
    description: 'Field title, end Date',
    defaultMessage: 'End date',
  },
  rejectionReason: {
    id: 'app.field.rejectionReason',
    description: 'Field title, rejection Reason',
    defaultMessage: 'Rejection reason',
  },
  remaining: {
    id: 'app.field.remaining',
    description: 'Field title, remaining',
    defaultMessage: 'Remaining',
  },
  requestStatus: {
    id: 'app.fields.requestStatus',
    description: 'Field title, request status.',
    defaultMessage: 'Status',
  },
  haircut: {
    id: 'app.field.haircut',
    description: 'Field title, haircut',
    defaultMessage: 'Haircut',
  },
  capacity: {
    id: 'app.field.capacity',
    description: 'Field title, capacity',
    defaultMessage: 'Capacity',
  },
  from: {
    id: 'app.field.from',
    description: 'Field title, from',
    defaultMessage: 'From',
  },
  to: {
    id: 'app.field.to',
    description: 'Field title, to',
    defaultMessage: 'To',
  },
  incrementalAmount: {
    id: 'app.field.incrementalAmount',
    description: 'Field title, incremental amount',
    defaultMessage: 'Incremental amount',
  },
  asset: {
    id: 'app.field.asset',
    description: 'Field title, asset',
    defaultMessage: 'Asset',
  },
  increment: {
    id: 'app.field.increment',
    description: 'Field title, increment',
    defaultMessage: 'Increment',
  },
  receivedEarlyMaturityButton: {
    id: 'app.field.receivedUnwindButton',
    description: 'Label for Received Early Maturity button in Trade details popup',
    defaultMessage: 'Early Maturity Received',
  },
  withdrawEarlyMaturityButton: {
    id: 'app.field.withdrawUnwindButton',
    description: 'Label for Withdrawing Early Maturity button in Trade details popup',
    defaultMessage: 'Early Maturity Proposed',
  },
  requestEarlyMaturityButton: {
    id: 'app.field.requestEarlyMaturity',
    description: 'Label for Requesting Early Maturity button in Trade details popup',
    defaultMessage: 'Request Early Maturity',
  },
  requestUnwindButton: {
    id: 'app.field.unwindButton',
    description: 'Label for Unwind button in Trade details popup',
    defaultMessage: 'Propose Trade Unwind',
  },
  receivedUnwindButton: {
    id: 'app.field.receivedUnwindButton',
    description: 'Label for Received Unwind button in Trade details popup',
    defaultMessage: 'Trade Unwind Received',
  },
  withdrawUnwindButton: {
    id: 'app.field.withdrawUnwindButton',
    description: 'Label for Withdrawing  Unwind button in Trade details popup',
    defaultMessage: 'Trade Unwind Proposed',
  },
  unwindPopUpHeader: {
    id: 'app.field.unwindPopUpHeader',
    description: 'Label for Unwind popup header',
    defaultMessage: 'Propose Trade Unwind',
  },
  reviewUnwindPopUpHeader: {
    id: 'app.field.reviewUnwindPopUpHeader',
    description: 'Label for Review Unwind popup header',
    defaultMessage: 'Trade Unwind Request Received',
  },
  unwindUnwindPopUpHeader: {
    id: 'app.field.unwindUnwindPopUpHeader',
    description: 'Label for Withdraw Unwind popup header',
    defaultMessage: 'Withdraw Unwind Trade',
  },
  unwindPopUpFooter: {
    id: 'app.field.unwindPopUpFooter',
    description: 'Label for Unwind popup footer',
    defaultMessage: 'Please confirm you want to propose unwinding the trade',
  },
  reviewUnwindPopUpFooter: {
    id: 'app.field.reviewUnwindPopUpFooter',
    description: 'Label for Review Unwind popup footer',
    defaultMessage:
      'Unwinding a trade means that the Opening Transfers have not been fully completed. If agreed by your counterparty, any money or securities sent as part of Opening Transfers' +
      ' will be returned without undue delay. Please note that Closing Transfers will not be executed.',
  },
  reviewUnwindPopUpFooter2: {
    id: 'app.field.reviewUnwindPopUpFooter2',
    description: 'Second Label for Review Unwind popup footer',
    defaultMessage: 'Late fees are not automatically levied and should be requested and agreed separately.',
  },
  withdrawUnwindPopUpFooter: {
    id: 'app.field.withdrawUnwindPopUpFooter',
    description: 'Label for Withdraw Unwind popup footer',
    defaultMessage: 'Please confirm you want to withdraw this unwind request',
  },
  unwindReason: {
    id: 'app.field.unwindReason',
    description: 'Field Unwind reason for Unwind popup',
    defaultMessage: 'Unwind reason',
  },
  unwindInformation: {
    id: 'app.field.unwindInformation',
    description: 'Description of what Unwind for Trade means',
    defaultMessage:
      'Unwinding a trade means that the opening transfers have not been fully completed.' +
      ' If agreed by your counterparty, any money or securities sent as part of Opening Transfers' +
      ' will be returned without undue delay. Please note that Closing Transfers will not be executed.',
  },
  earlyMaturityInformation: {
    id: 'app.field.earlyMaturityInformation',
    description: 'Description of what Early Maturity in Trade means',
    defaultMessage: 'Are you sure you want to request Early maturity on following Trade?',
  },
  earlyMaturityInformationWithUnwind: {
    id: 'app.field.earlyMaturityInformationWithUnwind',
    description: 'Description of what Early Maturity in Trade means. And that it will withdraw unwind request',
    defaultMessage:
      'You have an active Unwind request, this action will automatically withdraw your Unwind Request. Are you sure you want to proceed?',
  },
  rejectQuoteConfirmation: {
    id: 'app.field.requestQuoteConfirmation',
    description: 'Label in Popup of quote confirm.',
    defaultMessage: 'Please review the details of the quote before rejecting',
  },
  dragAndDropFileLabel: {
    id: 'app.field.downloadPreMatchDetails',
    description: 'Label for popup of drag and drop',
    defaultMessage: 'Drag and drop in the zone below file you want to upload',
  },
  oldSettlementRiskLimit: {
    id: 'app.field.oldSettlementRiskLimit',
    description: 'Label for popup',
    defaultMessage: 'Current settlement risk limit',
  },
  newSettlementRiskLimit: {
    id: 'app.field.newSettlementRiskLimit',
    description: 'Label for popup',
    defaultMessage: 'New settlement risk limit',
  },
  oldMtmRiskLimit: {
    id: 'app.field.oldMtmRiskLimit',
    description: 'Label for popup',
    defaultMessage: 'Current MtM risk limit',
  },
  newMtmRiskLimit: {
    id: 'app.field.newMtmRiskLimit',
    description: 'Label for popup',
    defaultMessage: 'New MtM risk limit',
  },
  oldClobMatchesLimit: {
    id: 'app.field.oldClobMatchesLimit',
    description: 'Label for popup',
    defaultMessage: 'Current clob matches limit',
  },
  newClobMatchesLimit: {
    id: 'app.field.newClobMatchesLimit',
    description: 'Label for popup',
    defaultMessage: 'New clob matches limit',
  },
  creditRiskTooltipOne: {
    id: 'creditRiskTooltipOne',
    description: 'Show data btn tooltip, when button is disabled',
    defaultMessage: 'First, please select Risk Measure and valid date range',
  },
  creditRiskTooltipTwo: {
    id: 'creditRiskTooltipOne',
    description: 'Show data btn tooltip, when form is disabled',
    defaultMessage: "You don't have the required permissions",
  },
  changeLimitPopupHeader: {
    id: 'changeLimitPopupHeader',
    description: 'Label on Change Limit',
    defaultMessage: 'Please enter the new limit:',
  },
  confirmNewLimitPopupHeader: {
    id: 'confirmNewLimitPopupHeader',
    description: 'Label on Confirm Limit Popup',
    defaultMessage: 'Confirmation',
  },
  confirmCapacityAdjustment: {
    id: 'confirmCapacityAdjustment',
    description: 'Confirm Capacity Adjustment',
    defaultMessage: 'Confirmation',
  },
  tradeAnnulProposalButton: {
    id: 'app.field.tradeAnnulProposalButton',
    description: 'Label for Proposing Trade Annulment in Trade details popup',
    defaultMessage: 'Propose Trade Annul',
  },
  tradeAnnulProposalHeader: {
    id: 'app.field.tradeAnnulProposalHeader',
    description: 'Label for Trade Annul popup header',
    defaultMessage: 'Propose Trade Annul',
  },
  reviewTradeAnnulProposalPopUpFooter: {
    id: 'app.field.reviewTradeAnnulProposalPopUpFooter',
    description: 'Label for Review Trade Annul popup footer',
    defaultMessage:
      'A Trade Annul can be requested up to 30 minutes before the Opening Transfer time. ' +
      'If agreed by your counterparty the trade will not go ahead and both Opening and Closing transfers will not occur. ' +
      '\n' +
      'Late fees are not owed by either party in this situation.',
  },
  reviewTradeAnnulProposalPopUpFooterConfirmationText: {
    id: 'app.field.reviewTradeAnnulProposalPopUpFooterConfirmationText',
    description: 'Label for Trade Annul popup footer',
    defaultMessage: 'Please confirm you want to propose a trade annul',
  },
  receivedTradeAnnulButton: {
    id: 'app.field.receivedTradeAnnulButton',
    description: 'Label for Received Trade Annul button in Trade details popup',
    defaultMessage: 'Trade Annul Received',
  },
  sentTradeAnnulButton: {
    id: 'app.field.sentTradeAnnulButton',
    description: 'Label for Sent Trade Annul button in Trade details popup',
    defaultMessage: 'Trade Annul Proposed',
  },
  receivedTradeAnnulProposalTitle: {
    id: 'app.field.receivedTradeAnnulButton',
    description: 'Title for Received Trade Annul popup ',
    defaultMessage: 'Trade Annul Proposal Received',
  },
  expiredTradeAnnulButton: {
    id: 'app.field.receivedTradeAnnulButton',
    description: 'Label for Trade Annul button for when the trade has expired',
    defaultMessage: 'Trade Annul Expired',
  },
  receivingPartyReviewingTradeAnnulProposalPopUpFooter: {
    id: 'app.field.receivingPartyReviewingTradeAnnulProposalPopUpFooter',
    description: 'Label for the receiving party to Review Trade Annul popup footer',
    defaultMessage:
      'Annulling a trade means that the Opening Transfers and Closing Transfers will not be executed by either party – the trade will not occur.' +
      '\n' +
      'Late fees will not owed by either party in this situation.',
  },
  receivingPartyReviewingTradeAnnulProposalWarningPopUpFooter: {
    id: 'app.field.receivingPartyReviewingTradeAnnulProposalWarningPopUpFooter',
    description: 'Warning for the receiving party to Review Trade Annul popup footer',
    defaultMessage: 'Please confirm if you want to accept the Trade Annul',
  },
  withdrawTradeAnnulProposalWarningPopUpTitle: {
    id: 'app.field.withdrawTradeAnnulProposalWarningPopUpTitle',
    description: 'Title for the sending party to Withdraw Trade Annul popup footer',
    defaultMessage: 'Withdraw Trade Annul Proposal',
  },
  withdrawTradeAnnulProposalWarningPopUpFooter: {
    id: 'app.field.withdrawTradeAnnulProposalWarningPopUpFooter',
    description: 'Label for the sending party to Withdraw Trade Annul popup footer',
    defaultMessage: 'Please confirm if you want to withdraw the Trade Annul',
  },
  flagAsSentPopupInfo: {
    id: 'app.field.flagAsSentPopupInfo',
    description: 'Label for footer of flag as sent popup',
    defaultMessage:
      "Flagging a trade as 'Sent' updates credit risk metrics in Finteum and informs Finteum users at your bank." +
      '\n' +
      'This cannot be seen by your counterparty.',
  },
  flagAsReceivedPopupInfo: {
    id: 'app.field.flagAsReceivedPopupInfo',
    description: 'Label for footer of flag as received popup',
    defaultMessage:
      "Flagging a trade as 'Received' updates credit risk metrics in Finteum and informs Finteum users at your bank that the full amount was received from your counterparty for this leg of the trade." +
      '\n' +
      "This cannot be seen by your counterparty and, if late, does not start a 'Late Fee' request.",
  },
  flagAsNonreceiptPopupInfo: {
    id: 'app.field.flagAsNonreceiptPopupInfo',
    description: 'Label for footer of flag as non-receipt popup',
    defaultMessage:
      "Flagging a trade as 'Non-Receipt' updates credit risk metrics in Finteum and informs Finteum users at your bank that the full amount has not yet been received from your counterparty for this leg of the trade." +
      '\n' +
      "This cannot be seen by your counterparty and, if late, does not start a 'Late Fee' request.",
  },
  tradeCreatedAt: {
    id: 'trade.createdAt',
    description: 'trade creation datetime',
    defaultMessage: 'Trade Created at',
  },
  tradeMtfExecutionDate: {
    id: 'trade.executionDetails.executionDate',
    description: 'trade MTF execution date',
    defaultMessage: 'MTF Execution Date',
  },
  tradeMtfExecutionTime: {
    id: 'trade.executionDetails.executionTime',
    description: 'trade MTF execution time',
    defaultMessage: 'MTF Execution Time',
  },
  tradeMtfConfirmationTime: {
    id: 'trade.executionDetails.confirmedAt',
    description: 'trade MTF confirmation datetime',
    defaultMessage: 'Confirmation Time',
  },
});

export const tradeStatuses = defineMessages({
  Upcoming: {
    id: 'trade.status.upcoming',
    description: 'Trade upcoming status',
    defaultMessage: 'Upcoming',
  },
  Processing: {
    id: 'trade.status.processing',
    description: 'Trade processing status',
    defaultMessage: 'Processing',
  },
  Outstanding: {
    id: 'trade.status.outstanding',
    description: 'Trade outstanding status',
    defaultMessage: 'Outstanding',
  },
  EarlyMaturityRequested: {
    id: 'trade.status.earlyMaturityRequested',
    description: 'Trade early maturity requested status',
    defaultMessage: 'Early Maturity Requested',
  },
  EarlyMaturityAccepted: {
    id: 'trade.status.earlyMaturityAccepted',
    description: 'Trade early maturity accepted status',
    defaultMessage: 'Early Maturity Accepted',
  },
  PendingMaturity: {
    id: 'trade.status.pendingMaturity',
    description: 'Trade pending maturity status',
    defaultMessage: 'Pending Maturity',
  },
  Matured: {
    id: 'trade.status.matured',
    description: 'Trade matured status',
    defaultMessage: 'Matured',
  },
  SettlementError: {
    id: 'trade.status.settlementError',
    description: 'Trade settlement error status',
    defaultMessage: 'Settlement Error',
  },
  Error: {
    id: 'trade.status.error',
    description: 'Trade error status',
    defaultMessage: 'Error',
  },
  AwaitingNearLegTime: {
    id: 'trade.status.awaitingNearLegTime',
    description: 'Trade awaiting near leg time status',
    defaultMessage: 'Awaiting Near Leg Time',
  },
  Unwind: {
    id: 'trade.status.unwind',
    description: 'Trade unwind status',
    defaultMessage: 'Trade Unwind',
  },
  Annulled: {
    id: 'trade.status.annulledAccepted',
    description: 'Proposed trade annul status',
    defaultMessage: 'Trade Annulled',
  },
});

export const tradeRequestDetailedStatus = defineMessages({
  UnwindRequested: {
    id: 'trade.status.unwindRequested',
    description: 'Trade unwind status',
    defaultMessage: 'Trade Unwind Requested',
  },
  AnnulRequested: {
    id: 'trade.status.annulledRequested',
    description: 'Proposed trade annul status',
    defaultMessage: 'Trade Annul Requested',
  },
  UnwindRejected: {
    id: 'trade.status.unwindRejected',
    description: 'Trade unwind status',
    defaultMessage: 'Trade Unwind Rejected',
  },
  AnnulRejected: {
    id: 'trade.status.annulledRejected',
    description: 'Proposed trade annul status',
    defaultMessage: 'Trade Annul Rejected',
  },
  UnwindExpired: {
    id: 'trade.status.unwindExpired',
    description: 'Trade unwind status',
    defaultMessage: 'Trade Unwind Expired',
  },
  AnnulExpired: {
    id: 'trade.status.annulledExpired',
    description: 'Proposed trade annul status',
    defaultMessage: 'Trade Annul Expired',
  },
  UnwindCancelled: {
    id: 'trade.status.unwindCancelled',
    description: 'Trade unwind status',
    defaultMessage: 'Trade Unwind Cancelled',
  },
  AnnulCancelled: {
    id: 'trade.status.annulledCancelled',
    description: 'Proposed trade annul status',
    defaultMessage: 'Trade Annul Cancelled',
  },
});

export const tradeLateSettlementFeesStatuses = defineMessages({
  LateFeeRequested: {
    id: 'tradeLateSettlementFee.status.lateFeeRequested',
    description: 'Trade late fee requested status',
    defaultMessage: 'Late Fee Requested',
  },
  LateFeeAccepted: {
    id: 'tradeLateSettlementFee.status.lateFeeAccepted',
    description: 'Trade late fee accepted status',
    defaultMessage: 'Late Fee Accepted',
  },
});

export const orderStatuses = defineMessages({
  Pending: {
    id: 'order.status.pending',
    description: 'Order pending status',
    defaultMessage: 'Pending',
  },
  Live: {
    id: 'order.status.live',
    description: 'Order live status',
    defaultMessage: 'Live',
  },
  Fulfilled: {
    id: 'order.status.fulfilled',
    description: 'Order fulfilled status',
    defaultMessage: 'Fulfilled',
  },
  Paused: {
    id: 'order.status.paused',
    description: 'Order paused status',
    defaultMessage: 'Paused',
  },
  Cancelled: {
    id: 'order.status.cancelled',
    description: 'Order cancelled status',
    defaultMessage: 'Cancelled',
  },
  Invalid: {
    id: 'order.status.invalid',
    description: 'Order invalid status',
    defaultMessage: 'Invalid',
  },
  Error: {
    id: 'order.status.error',
    description: 'Order error status',
    defaultMessage: 'Error',
  },
});

export const rfqRequestStatuses = defineMessages<RFQRequestStatusText>({
  Filled: {
    id: 'rfqRequest.status.filled',
    defaultMessage: 'Filled',
  },
  PartialFill: {
    id: 'rfqRequest.status.partialFill',
    defaultMessage: 'Partial Fill',
  },
  RequestCancelled: {
    id: 'rfqRequest.status.requestCancelled',
    defaultMessage: 'Request Cancelled',
  },
  RequestExpired: {
    id: 'rfqRequest.status.requestExpired',
    defaultMessage: 'Request Expired',
  },
  RequestRejected: {
    id: 'rfqRequest.status.requestRejected',
    defaultMessage: 'Request Rejected',
  },
  QuoteExpired: {
    id: 'rfqRequest.status.quoteExpired',
    defaultMessage: 'Quote Expired',
  },
  QuoteRejected: {
    id: 'rfqRequest.status.quoteRejected',
    defaultMessage: 'Quote Rejected',
  },
  QuoteCancelled: {
    id: 'rfqRequest.status.quoteCancelled',
    defaultMessage: 'Quote Cancelled',
  },
  Waiting: {
    id: 'rfqRequest.status.waiting',
    defaultMessage: 'Waiting',
  },
  NetworkError: {
    id: 'rfqRequest.status.networkError',
    defaultMessage: 'Network Error',
  },
  RequestCancelledEarly: {
    id: 'rfqRequest.status.requestCancelledEarly',
    defaultMessage: 'Request Cancelled',
  },
});

export const formErrorTooltips = defineMessages({
  amountErrorOverOneMillion: {
    id: 'form.error.amountErrorOverOneMillion',
    defaultMessage: 'Please enter a number between $1m and $4999m',
  },
  amountErrorUnderOneMillion: {
    id: 'form.error.amountErrorUnderOneMillion',
    defaultMessage: 'Please enter a number between $1 and $499',
  },
  amountGenericError: {
    id: 'form.error.amountGenericError',
    defaultMessage: 'Please enter a valid amount',
  },
  bigFigError: {
    id: 'form.error.bigFig',
    defaultMessage: 'Please enter a number between 0.1bigfig and 9.9bigfig',
  },
  validForError: {
    id: 'form.error.validFor',
    defaultMessage: 'Valid for has to be integer between 1 and 30 minutes',
  },
  impYieldError: {
    id: 'form.error.impYield',
    defaultMessage: 'Suggested Imp. Yield has to be number between -999 and 999',
  },
  fwdPointsError: {
    id: 'form.error.fwdPoints',
    defaultMessage: 'Forward points is required',
  },
  datetimeError: {
    id: 'form.error.datetime',
    defaultMessage: 'Incorrect date and time format, please correct field',
  },
});
