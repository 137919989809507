import {useEffect, useState} from 'react';
import {AxiosError} from 'axios';

import {useRealTimeOrdersQuery} from 'api/hooks/useRealTimeOrdersQuery';
import {ErrorResponse, Order, OrderStatus} from 'types/api';
import {DetailsTabType} from 'types/layout';
import {showToast, showErrorToast} from 'utils/ToastUtils';
import {pauseOrder, resumeOrder, cancelOrder} from 'api/api';

import {useUser} from 'contexts/auth-context';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import {RFQTabs} from 'constants/tabs';
import Button from 'ui/Button';
import Popup from 'ui/Popup';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';

import {SCol, SContainer} from 'ui/Layout/styles';
import StatusIcon from 'containers/Blotter/components/StatusIcon';

import DetailsTable from './components/DetailsTable';

import {RelatedTrades} from '../../RelatedTradesTab/RelatedTrades';
import {getRolePermissions, hasPermission} from '../../PermissionGate/utils';

import {SHeader, SOrderBottom, SStatus} from './styles';
export interface OrderPopupProps {
  modelId: Order['id'];
}

const OrderPopup = ({modelId}: OrderPopupProps) => {
  const [isLoadingPauseOrder, setLoadingPauseOrder] = useState(false);
  const [isLoadingResumeOrder, setLoadingResumeOrder] = useState(false);
  const [isLoadingCancelOrder, setLoadingCancelOrder] = useState(false);
  const [currentTab, setCurrentTab] = useState<Tab>(RFQTabs[0]);
  const {user} = useUser();
  const {closeModal} = useModal();
  const {selectOrderById} = useRealTimeOrdersQuery();
  const canNotTrade = !hasPermission({
    permissions: getRolePermissions(user.role),
    scopes: [Scope.CanTrade],
  });

  useEffect(() => {
    setLoadingResumeOrder(false);
    setLoadingPauseOrder(false);
    setLoadingCancelOrder(false);
  }, [modelId]);

  const order = selectOrderById(modelId);
  if (!order) return null;

  const handleClose = () => closeModal();

  const onCancelOrder = () => {
    setLoadingCancelOrder(true);
    cancelOrder(modelId)
      .then(() => {
        showToast('Order has been cancelled.', 'warning');
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        showErrorToast(err);
      })
      .finally(() => {
        setLoadingCancelOrder(false);
      });
  };

  const onPauseOrder = () => {
    setLoadingPauseOrder(true);
    pauseOrder(modelId)
      .then(() => {
        showToast('Order been paused successfully.', 'success');
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        showErrorToast(err);
        setLoadingPauseOrder(false);
      });
  };

  const onResumeOrder = () => {
    setLoadingResumeOrder(true);
    resumeOrder(modelId)
      .then(() => {
        showToast('Order been resumed successfully.', 'success');
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        showErrorToast(err);
        setLoadingResumeOrder(false);
      });
  };

  return (
    <Popup title='Order details' onClose={handleClose}>
      <SContainer fluid>
        <SHeader>
          <Tabs tabs={RFQTabs} value={currentTab} onChange={setCurrentTab}>
            <SStatus data-testid='header-status-tab'>
              Status
              {<StatusIcon status={order.status} />}
            </SStatus>
          </Tabs>
        </SHeader>
        {currentTab.label === DetailsTabType.Details && (
          <>
            <DetailsTable order={order} />
            <SOrderBottom>
              {order.status === OrderStatus.Live && (
                <SCol width={{md: 1 / 6}}>
                  <Button
                    data-testid='pause-order-button'
                    disabled={canNotTrade}
                    onClick={onPauseOrder}
                    buttonStyle='warning'
                    loading={isLoadingPauseOrder}
                  >
                    Pause order
                  </Button>
                </SCol>
              )}
              {order.status === OrderStatus.Paused && (
                <SCol width={{md: 1 / 6}}>
                  <Button
                    data-testid='resume-order-button'
                    disabled={canNotTrade}
                    onClick={onResumeOrder}
                    loading={isLoadingResumeOrder}
                  >
                    Resume order
                  </Button>
                </SCol>
              )}
              {order.isCancellable && (
                <SCol width={{md: 1 / 6}}>
                  <Button
                    data-testid='cancel-order-button'
                    disabled={canNotTrade}
                    onClick={onCancelOrder}
                    buttonStyle='secondary'
                    loading={isLoadingCancelOrder}
                  >
                    Cancel order
                  </Button>
                </SCol>
              )}
            </SOrderBottom>
          </>
        )}
        {currentTab.label === DetailsTabType.RelatedTrades && <RelatedTrades originId={modelId} />}
      </SContainer>
    </Popup>
  );
};

export default OrderPopup;
