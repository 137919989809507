import {useQuery, useQueryClient} from '@tanstack/react-query';

const QUERY_KEY = ['HistoryChartSize'];

export default function useHistoryChartSizeStore() {
  const {data} = useQuery({
    queryKey: QUERY_KEY,
    initialData: {
      height: 400,
      width: 800,
    },
  });
  const client = useQueryClient();

  const setChartHeight = (height: number) => {
    client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, height}));
  };
  const setChartWidth = (width: number) => {
    client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, width}));
  };

  const setChartSize = ({width, height}: {width: number; height: number}) => {
    client.setQueryData(QUERY_KEY, {width, height});
  };

  return {...data, setChartHeight, setChartWidth, setChartSize};
}
