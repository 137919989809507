import useErrorLogsStore from 'stores/useErrorLogsStore';

import Button from 'ui/Button';

import {ContactsForHelp} from './components/ContactsForHelp';
import {SPopup, StyledParagraph} from './styles';

export type HelpPopupProps = {
  onClose: () => void;
};

export const HelpPopup = ({onClose}: HelpPopupProps) => {
  const {logs, downloadBlobUrl, clearLogs} = useErrorLogsStore();

  return (
    <SPopup
      onClose={() => {
        clearLogs();
        onClose();
      }}
    >
      <ContactsForHelp />
      {logs.length > 0 && (
        <>
          <StyledParagraph data-testid='error-message' variant='warning'>
            WE FOUND UNEXPECTED BEHAVIOUR DURING YOUR SESSION. TO IMPROVE OUR SOFTWARE, WE ASK YOU SEND THE LOGS TO US
            IN THE FINTEUM CLIENT SUPPORT JIRA, OR BY EMAIL. PLEASE REMOVE ANY DATA YOU DON’T WISH THE FINTEUM TEAM TO
            SEE.
          </StyledParagraph>
          <a data-testid='clear-logs' href={downloadBlobUrl} download='logs.txt' onClick={clearLogs}>
            <Button>Download Log File</Button>
          </a>
        </>
      )}
    </SPopup>
  );
};
