import {isRFQRequestExpired, isCreatedLessThan12HoursAgo} from 'utils/RFQUtils';
import {RFQRequest} from 'types/api';
import {ReferenceData} from 'types/layout';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {ListContainer, ListHeader} from 'containers/RFQBoard/styles';
import RFQOverviewPopup from 'components/popups/RFQOverviewPopup';

import SentRequestListItem from '../SentRequestListItem';

const PreviousRequestsSent = () => {
  const {openModal} = useModal();
  const {rfqRequestsSent} = useRealTimeRequestsQuery();
  const {referenceData} = useReferenceDataStore();

  return (
    <ListContainer data-testid='previous-request-sent-container'>
      <ListHeader>Previous Requests Sent</ListHeader>
      {rfqRequestsSent.filter(isCreatedLessThan12HoursAgo).map((request: RFQRequest) => {
        const isExpired: boolean = isRFQRequestExpired(request);
        const handleClick = () =>
          openModal({modal: RFQOverviewPopup, props: {requestId: request.id, defaultView: 'comparison'}});
        return (
          isExpired && (
            <SentRequestListItem
              testid='previous-request-sent-item'
              key={request.id}
              showForwardPoints={referenceData === ReferenceData.ForwardPoints}
              request={request}
              expired={isExpired}
              showComparison={handleClick}
            />
          )
        );
      })}
    </ListContainer>
  );
};

export default PreviousRequestsSent;
