import styled from 'styled-components';
import {switchProp} from 'styled-tools';
import {color} from 'utils/theme';

export const TableWrapper = styled.div`
  padding: 2em 1em;
`;

export const Table = styled.table`
  width: 100%;
  text-transform: uppercase;
`;

export const HeaderRow = styled.tr`
  background-color: ${p => p.theme.colors.blue900};
`;

export const Row = styled.tr<{index: number}>`
  background-color: ${p => (p.index % 2 === 0 ? p.theme.colors.blue900 : p.theme.colors.blue650)};
`;

export const Cell = styled.td<{textColor?: string}>`
  padding: 10px;
  flex: 4;
  color: ${switchProp('textColor', {
    late: color('yellow500'),
    nonreceipt: color('gray100'),
  })};
`;

export const CurrencyCell = styled(Cell)`
  white-space: nowrap;
`;

export const HeaderCell = styled(Cell)`
  flex: 1;
  color: ${color('blue150')};
`;

export const cellStyles = {verticalAlign: 'middle'};
