import {useMemo, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {ColumnDef} from '@tanstack/react-table';

import {RiskExposureRow, RiskMeasure} from 'types/analytics';
import {useUser} from 'contexts/auth-context';

import {AxiosError, AxiosResponse} from 'axios';

import {downloadFile} from 'utils/utils';
import {showErrorToast} from 'utils/ToastUtils';

import {ErrorResponse} from 'types/api';
import {getMarkToMarketCSVFile} from 'api/api';

import {useIntl} from 'react-intl';

import {roundToInterval} from 'utils/DayjsUtils';

import {SAnalyticsContainer} from 'pages/AnalyticsPage/styles';

import useMarkToMarketQuery from 'api/hooks/useMarkToMarketQuery';

import useBlotterPositionStore from 'stores/useBlotterPositionStore';

import {BlotterPosition} from 'types/layout';

import {Scope} from 'constants/permission-maps';

import {fieldTitles} from 'constants/messages/labels';
import Button from 'ui/Button';
import DateTimeField from 'ui/DateTimeField';
import FieldTitle from 'ui/FieldTitle';
import GridTable, {GridTableVariant} from 'ui/GridTable';
import SelectField, {Item} from 'ui/SelectField';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';
import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import {SDownloadButton, SFormField, SFormItem, SFormLabel, SFormLayout, STableContainer} from './styles';
import {getDateColumn, getRiskExposureColumn} from './columns';

const riskMeasures: Item<RiskMeasure>[] = [
  {value: RiskMeasure.MtmExposure, label: 'Mtm Exposure'},
  {value: RiskMeasure.MtmRemaining, label: 'Mtm Remaining Limit'},
  {value: RiskMeasure.MtmTotal, label: 'Mtm Total Limit'},
  {value: RiskMeasure.SettlementRiskExposure, label: 'Settlement Risk Exposure'},
  {value: RiskMeasure.SettlementRiskRemaining, label: 'Settlement Risk Remaining Limit'},
  {value: RiskMeasure.SettlementRiskTotal, label: 'Settlement Risk Total Limit'},
];

export type HistoricalCreditRiskProps = {
  fromTime?: Dayjs | string;
  toTime?: Dayjs | string;
};
const HistoricalCreditRisk = ({fromTime, toTime}: HistoricalCreditRiskProps) => {
  const userWithMeta = useUser();
  const {formatMessage} = useIntl();
  const [blotterPosition] = useBlotterPositionStore();
  const permissions = getRolePermissions(userWithMeta.user?.role);
  const [riskMeasure, setRiskMeasure] = useState<Item<RiskMeasure> | undefined>(undefined);
  const [startTime, setStartTime] = useState<Dayjs>(roundToInterval(dayjs(fromTime)).subtract(1, 'day'));
  const [endTime, setEndTime] = useState<Dayjs>(roundToInterval(dayjs(toTime)));
  const {
    fetchData,
    mtmData,
    counterparties,
    isLoading: isDataLoading,
  } = useMarkToMarketQuery(startTime, endTime, riskMeasure?.value);

  const isFormDisabled = !hasPermission({permissions, scopes: [Scope.ViewAnalytics]});
  const isDatePickersInvalid = endTime.isBefore(startTime);
  const isShowButtonDisabled = !riskMeasure || isDatePickersInvalid;

  const riskExposureColumns = useMemo(
    () => [getDateColumn(), getRiskExposureColumn(counterparties)] as ColumnDef<RiskExposureRow>[],
    [counterparties]
  );

  const downloadReport = () => {
    if (!riskMeasure) return;
    getMarkToMarketCSVFile(startTime, endTime, riskMeasure.value)
      .then((response: AxiosResponse) => downloadFile(response))
      .catch((error: AxiosError<ErrorResponse>) => {
        showErrorToast(error);
      });
  };

  const showDataTooltipMessage = formatMessage(
    isShowButtonDisabled ? fieldTitles.creditRiskTooltipOne : fieldTitles.creditRiskTooltipTwo
  );

  return (
    <SAnalyticsContainer>
      <div id='sticky-one' style={{position: 'sticky', zIndex: 100}}>
        <BoardHeader borderBottom='unset' title='Historical Credit Risk' />
        <SFormLayout>
          <SFormItem>
            <SFormLabel>
              <FieldTitle>Risk Measure</FieldTitle>
            </SFormLabel>
            <SFormField>
              <SelectField
                data-testid='risk-measure-dropdown'
                disabled={isFormDisabled}
                items={riskMeasures}
                value={riskMeasure}
                onChange={item => setRiskMeasure(item)}
                required
              />
            </SFormField>
          </SFormItem>
          <SFormItem>
            <SFormLabel>
              <FieldTitle>Start Time</FieldTitle>
            </SFormLabel>
            <SFormField>
              <DateTimeField
                invalid={isDatePickersInvalid}
                disabled={isFormDisabled}
                value={startTime}
                onChange={date => date && setStartTime(date)}
                required
                showTimeSelect
              />
            </SFormField>
          </SFormItem>
          <SFormItem>
            <SFormLabel>
              <FieldTitle>End Time</FieldTitle>
            </SFormLabel>
            <SFormField>
              <DateTimeField
                invalid={isDatePickersInvalid}
                disabled={isFormDisabled}
                value={endTime}
                onChange={date => date && setEndTime(date)}
                required
                showTimeSelect
              />
            </SFormField>
          </SFormItem>
          <TooltipWrapper
            disabled={!isShowButtonDisabled}
            message={showDataTooltipMessage}
            position='bottom'
            className='w-100'
            maxWidth={400}
            style={{zIndex: 101}}
          >
            <Button
              data-testid='show-data-btn'
              buttonStyle='primary'
              disabled={isFormDisabled || isShowButtonDisabled}
              onClick={fetchData}
            >
              Show Data
            </Button>
          </TooltipWrapper>
          <TooltipWrapper message='Download to CSV' maxWidth={500}>
            <Button
              data-testid='download-report-btn'
              buttonStyle='grey'
              disabled={isFormDisabled || isShowButtonDisabled}
              onClick={() => downloadReport()}
            >
              <SDownloadButton className='la la-file-download' />
            </Button>
          </TooltipWrapper>
        </SFormLayout>
      </div>
      <STableContainer isBlotterMinified={blotterPosition === BlotterPosition.Minified}>
        {riskMeasure?.value && (
          <GridTable
            isLoading={isDataLoading}
            columns={riskExposureColumns}
            // TODO: After story for calcualting Net Sent/Received will be done. "data" variable will be
            // TODO: replaced by "adding to each number of MtM table the corresponding "Net Sent/Received" value"
            data={mtmData}
            variant={GridTableVariant.Regular}
            fullWidth
            sortable
          />
        )}
      </STableContainer>
    </SAnalyticsContainer>
  );
};

export default HistoricalCreditRisk;
