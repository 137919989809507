import {useModal} from 'contexts/modal-context';
import {Trade} from 'types/api';

import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayDate} from 'utils/DayjsUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {displayInterestRate, getRFC2253ValueByKey} from 'utils/utils';

import TradePopup from 'components/popups/TradePopup';

type Props = {
  trade: Trade;
};

export const RelatedTrade = ({trade}: Props) => {
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const {openModal} = useModal();
  const inMillions = shouldDisplayInMillions({base: trade.baseAmount.asset, second: trade.secondAmount.asset});

  return (
    <tr
      key={trade.id}
      data-id={trade.id}
      onClick={() => {
        openModal({modal: TradePopup, props: {modelId: trade.id}});
      }}
    >
      <td data-testid='related-trade-id'>{trade.id}</td>
      <td>{displayAmountWithCode(trade.baseAmount, true, inMillions)}</td>
      <td>{displayInterestRate(trade.interestRate)}</td>
      <td>{displayDate(trade.createdAt, 'HH:mm:ss')}</td>
      <td>{getRFC2253ValueByKey(trade.counterparty, 'O')}</td>
    </tr>
  );
};
