import styled from 'styled-components';
import {ifProp, ifNotProp} from 'styled-tools';

import {SupportedFileTypes} from 'components/popups/TradePopup/types';

const SFileIconWrapper = styled.div<{disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  height: 90%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0.75rem;
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  opacity: ${ifProp('disabled', '70%', '100%')};

  &:hover {
    background: ${ifNotProp('disabled', '#768194')};
  }
`;

export interface FileIconProps {
  fileType: SupportedFileTypes;
  onClick?: (fileType: SupportedFileTypes) => void;
  disabled?: boolean;
}
export const FileIcon = ({fileType, onClick = () => ({}), disabled}: FileIconProps) => {
  const handleClick = () => {
    if (disabled) return;
    onClick(fileType);
  };

  return (
    <SFileIconWrapper
      data-testid={fileType === SupportedFileTypes.FIXML ? 'fixml-download-button' : 'csv-download-button'}
      onClick={handleClick}
      disabled={disabled}
    >
      <i
        style={{
          fontSize: 25,
        }}
        className={fileType === SupportedFileTypes.FIXML ? 'la la-file-excel-o' : 'la la-file-o'}
      />
      <span>{fileType}</span>
    </SFileIconWrapper>
  );
};
