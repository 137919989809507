import styled from 'styled-components';

import {ifProp} from 'styled-tools';
import {color} from 'utils/theme';

import {SCol, SRow} from 'ui/Layout/styles';

interface OrderProps {
  isOffer?: boolean;
}

export const SSidebarOrder = styled(SRow)<OrderProps>`
  padding: 5px 5px 5px 20px;
  min-height: 20px;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    background-color: ${color('gray600')};
  }
`;

export const SOrderRate = styled(SCol)<OrderProps>`
  text-align: left;
  padding-right: 10px;
  font-weight: bold;
  color: ${ifProp('isOffer', color('red400'), color('green500'))};
`;
