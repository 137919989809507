import {useMemo, useState} from 'react';

import {uniq} from 'lodash/fp';

import {Currency} from 'types/api';

import useAvailableAssetsQuery from 'api/hooks/useAvailableAssetsQuery';

import {Scope} from 'constants/permission-maps';
import {Item} from 'ui/SelectField';
import SelectField from 'ui/SelectField';

import {SubsetSelectorContainer} from './styles';
import PermissionsGate from '../PermissionGate';

interface Props {
  currencySubset: Currency | 'ALL' | undefined;
  setCurrencySubset: (subset: Currency | 'ALL' | undefined) => void;
}

type ItemType = Item<Currency | 'ALL' | undefined>;

const allLabel = {
  label: 'ALL',
  value: 'ALL',
} as const;

export const SubsetSelector = ({currencySubset, setCurrencySubset}: Props) => {
  const [option, setOption] = useState<ItemType>(
    currencySubset ? {label: currencySubset, value: currencySubset} : allLabel
  );
  const {data: assetsData} = useAvailableAssetsQuery();
  const supportedAssets = useMemo(() => (assetsData || []).map(({asset}) => asset), [assetsData]);

  const handleSelect = (currency: ItemType): void => {
    setOption(currency);
    setCurrencySubset(currency.value);
  };

  const currenciesSet = uniq(supportedAssets.map(asset => asset.currency)).map(currency => {
    return {label: currency, value: currency};
  });

  const arrayOfOptions: ItemType[] = [allLabel, ...currenciesSet];

  return (
    <PermissionsGate scopes={[Scope.ViewClob]}>
      <SubsetSelectorContainer>
        <span>SUBSET:</span>
        <SelectField
          data-testid='currency-subset-dropdown'
          placeholder='--'
          items={arrayOfOptions}
          value={option}
          onChange={handleSelect}
        />
      </SubsetSelectorContainer>
    </PermissionsGate>
  );
};
