import React from 'react';

import {getQuoteStatusText} from 'utils/RFQUtils';
import {displayForwardPoints, displayInterestRate, displayParty} from 'utils/utils';
import {displayAmountWithCode, displayAmountWithUnit} from 'utils/AmountUtils';
import {displayLegPrefixAndDateTime} from 'utils/DayjsUtils';
import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {ReferenceData} from 'types/layout';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {Cell, ContainerExpired, RightCell, Row, RequestReceivedStatus} from './styles';
import {RequestExpiredProps} from './types';

export const RequestExpired: React.FC<RequestExpiredProps> = (props: RequestExpiredProps) => {
  const {
    requestData: {
      requesteeSide,
      requestor,
      baseAsset,
      secondAsset,
      tradedAmount,
      maturityTime: farLegTime,
      maturityTimeInput,
      isReceived,
    },
  } = props;
  const {referenceData} = useReferenceDataStore();
  const requesteeAndQuote = props.requestData.requesteesAndQuotes[0];
  const isRepo = isRepoPair({base: baseAsset, second: secondAsset});
  const quote = requesteeAndQuote.quote;
  const quotedAmount = quote?.tradedAmount ?? {quantity: 0, asset: tradedAmount.asset};
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base: baseAsset, second: secondAsset});

  return (
    <ContainerExpired data-testid='request-expired' data-request-id={props.requestData.id}>
      <Row>
        <Cell width={32}>{displayAssetPairWithSide({base: baseAsset, second: secondAsset}, requesteeSide, true)}</Cell>
        <Cell width={32}>{displayParty(requestor)}</Cell>
        <RightCell>
          {displayAmountWithCode(quotedAmount, false, inMillions)}/{displayAmountWithUnit(tradedAmount, inMillions)}
        </RightCell>
      </Row>
      <Row>
        <Cell width={32}>{displayLegPrefixAndDateTime(farLegTime, maturityTimeInput.transactionDate, 'HH:mm')}</Cell>
        <Cell width={24}>
          {quote ?
            isRepo || referenceData === ReferenceData.ImpliedYield ?
              displayInterestRate(quote.interestRate)
            : displayForwardPoints(quote.forwardPoints)
          : '--'}
        </Cell>
        <RightCell>
          {requesteeAndQuote.stage.name === 'QuoteRejected' && requesteeAndQuote.stage.reasons.length > 0 && 'ⓘ'}
          <RequestReceivedStatus expired={requesteeAndQuote.expired} stage={requesteeAndQuote.stage}>
            {getQuoteStatusText(isReceived, requesteeAndQuote.expired, requesteeAndQuote.stage.name)}
          </RequestReceivedStatus>
        </RightCell>
      </Row>
    </ContainerExpired>
  );
};

export default RequestExpired;
