import {useQuery} from '@tanstack/react-query';

import {getOrderBook} from 'api/api';
import {AssetPair} from 'types/api';

export default function useOrderBookQuery(pair: Partial<AssetPair>) {
  return useQuery({
    queryKey: ['OrderBook', pair],
    queryFn: () => getOrderBook(pair as AssetPair).then(response => response.data),
    refetchInterval: 5 * 1000,
    enabled: !!pair.base && !!pair.second,
  });
}
