import {ReactNode} from 'react';
import {IntlProvider} from 'react-intl';
import {QueryClientProvider} from '@tanstack/react-query';

import {queryClient} from 'api/query';

import {AuthProvider} from './auth-context';
import ModalContextProvider from './modal-context';

// import available languages
import en from '../translations/en.json';
// declare translation strings
const translations = {en};
// get locale from env
const locale = process.env.REACT_APP_LOCALE || 'en';

const messages =
  Object.keys(translations).includes(locale) ? translations[locale as keyof typeof translations] : translations['en'];

const AppProviders = ({children}: {children: ReactNode}) => (
  <QueryClientProvider client={queryClient}>
    <IntlProvider locale={locale} defaultLocale='en' messages={messages}>
      <AuthProvider>
        <ModalContextProvider>{children}</ModalContextProvider>
      </AuthProvider>
    </IntlProvider>
  </QueryClientProvider>
);

export default AppProviders;
