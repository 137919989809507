import React from 'react';

import dayjs from 'dayjs';
import {displayParty} from 'utils/utils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayLegPrefixAndDateTime} from 'utils/DayjsUtils';
import {displayAssetPairWithSide} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import Countdown from 'ui/Countdown';
import Button from 'ui/Button';

import {RejectRequestConfirmation} from 'components/popups/RejectRequestConfirmation';

import CreateQuotePopup from 'components/popups/CreateQuote';

import RFQOverviewPopup from 'components/popups/RFQOverviewPopup';

import {RequestReceivedProps} from './types';
import {Container, Row, RightCell, Cell} from './styles';
import PermissionsGate from '../PermissionGate';

export const RequestReceived: React.FC<RequestReceivedProps> = (props: RequestReceivedProps) => {
  const {
    requestData: {
      requesteeSide,
      requestor,
      baseAsset,
      secondAsset,
      tradedAmount,
      validUntil,
      maturityTime: farLegTime,
      maturityTimeInput,
    },
  } = props;

  const {openModal} = useModal();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base: baseAsset, second: secondAsset});

  // Reply and create quote
  function onClickReply(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    openModal({modal: CreateQuotePopup, props: {quoteFormRequest: props.requestData}});
  }
  // Show reject request
  function onClickReject(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    openModal({modal: RejectRequestConfirmation, props: {requestId: props.requestData.id}});
  }
  function onRequestClicked(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    openModal({modal: RFQOverviewPopup, props: {requestId: props.requestData.id, defaultView: 'details'}});
  }

  return (
    <Container data-testid='latest-request-container' onClick={onRequestClicked}>
      <Row>
        <Cell width={32}>{displayAssetPairWithSide({base: baseAsset, second: secondAsset}, requesteeSide, true)}</Cell>
        <Cell width={24}>{displayParty(requestor)}</Cell>
        <Cell width={22}>{displayAmountWithCode(tradedAmount, false, inMillions)}</Cell>
        <RightCell>
          <Countdown timeTarget={dayjs(validUntil)} />
        </RightCell>
        <Cell width={32}>{displayLegPrefixAndDateTime(farLegTime, maturityTimeInput.transactionDate, 'HH:mm')}</Cell>
        <PermissionsGate scopes={[Scope.CanUseRfqActions]}>
          <RightCell>
            <Button data-testid='latest-request-reject' buttonStyle='grey' onClick={onClickReject}>
              Reject
            </Button>
            <Button data-testid='latest-request-reply' buttonStyle='primary' onClick={onClickReply}>
              Reply
            </Button>
          </RightCell>
        </PermissionsGate>
      </Row>
    </Container>
  );
};

export default RequestReceived;
