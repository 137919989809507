import {useQuery} from '@tanstack/react-query';
import {getMinimalCapacityProjection} from 'api/api';
import {AxiosError} from 'axios';

import {Dayjs} from 'dayjs';
import {Amount, ErrorResponse} from 'types/api';

interface Props {
  projectedEarmark: Amount | undefined;
  nearleg: Dayjs;
  farleg: Dayjs;
}
export default function useMinimalProjectedCapacityQuery({projectedEarmark, nearleg, farleg}: Props) {
  const nearLegKey = nearleg.format('ddd DD MMM HH:mm z');

  return useQuery<number, AxiosError<ErrorResponse>>({
    queryKey: ['MinimalProjectedCapacity', projectedEarmark?.asset, nearLegKey, farleg.toISOString()],
    queryFn: () =>
      getMinimalCapacityProjection((projectedEarmark as Amount).asset, nearleg, farleg).then(response => response.data),
    enabled: !!projectedEarmark,
  });
}
