import {MTFExecutionDetails, MTFLeg, Trade} from 'types/api';
import * as yup from 'yup';

export const checkExecutionDetailsValid = (trade: Trade): boolean => {
  if (!trade.executionDetails) {
    return false;
  }

  try {
    executionDetailsSchema.validateSync(trade.executionDetails);
    return true;
  } catch (error) {
    return false;
  }
};

export const executionDetailsSchema: yup.ObjectSchema<MTFExecutionDetails> = yup.object({
  mtfName: yup.string().required(),
  executedAt: yup.string().required(),
  confirmedAt: yup.string().required(),
  nearLeg: yup
    .mixed<MTFLeg>()
    .required()
    .test('is-uti-presented', value => !!value.uti),
  farLeg: yup
    .mixed<MTFLeg>()
    .required()
    .test('is-uti-presented', value => !!value.uti),
  brokerTradeReference: yup.string().required(),
  reportTrackingNumber: yup.string().required(),
  mic: yup.string().required(),
});
