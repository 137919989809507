import {useState} from 'react';

import {DetailsTabType} from 'types/layout';
import {createSingleQuoteWithRequest} from 'utils/RFQUtils';
import {useUser} from 'contexts/auth-context';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {RFQTabs} from 'constants/tabs';
import Popup from 'ui/Popup';
import Button from 'ui/Button';
import {SEmptyPopupFiller} from 'ui/Popup/styles';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import {SCol, SContainer, SRow} from 'ui/Layout/styles';
import {RelatedTrades} from 'components/RelatedTradesTab/RelatedTrades';

import RFQActionButtons from 'components/RFQActionButtons/RFQActionButtons';

import QuoteDetailsTable from './components/QuoteDetailsTable';

import {RFQDetalsContainer} from './styles';

interface Props {
  requestId: string;
  quoteId: string;
  onCompare?: () => void;
}

const RFQQuoteDetails = ({requestId, quoteId, onCompare}: Props) => {
  const [currentTab, setCurrentTab] = useState<Tab>(RFQTabs[0]);

  const user = useUser();
  const {closeModal} = useModal();
  const {selectRfqById} = useRealTimeRequestsQuery();

  const request = selectRfqById(requestId);

  if (!request) {
    return null;
  }

  const requesteeAndQuote = request?.requesteesAndQuotes.find(r => r.quote?.id === quoteId);

  return (
    <Popup title='Quote details' onClose={closeModal}>
      <SContainer fluid>
        <SRow className='details-popup__header'>
          <Tabs tabs={RFQTabs} value={currentTab} onChange={setCurrentTab} />
          {/* Empty column to fill the whole row responsively */}
          <SCol />
        </SRow>
        {currentTab.label === DetailsTabType.Details && (
          <>
            <QuoteDetailsTable requestId={requestId} quoteId={quoteId} />
            <RFQDetalsContainer>
              <SCol width={{md: 1 / 6}}>
                <Button buttonStyle='grey' onClick={onCompare}>
                  Compare
                </Button>
              </SCol>
              <SCol d='flex' justifyContent='end'>
                {request && requesteeAndQuote && (
                  <RFQActionButtons
                    request={request}
                    requestAndQuote={createSingleQuoteWithRequest(requesteeAndQuote, request, user.legalEntities)}
                  />
                )}
              </SCol>
            </RFQDetalsContainer>
          </>
        )}
        {currentTab.label === DetailsTabType.RelatedTrades && <RelatedTrades originId={quoteId ?? request.id} />}
        {currentTab.label === DetailsTabType.RelatedTrades && <SEmptyPopupFiller />}
      </SContainer>
    </Popup>
  );
};

export default RFQQuoteDetails;
