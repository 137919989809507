import {OrderFormState} from 'types/orderForm';

import {OrderFormValues} from './hooks/useOrderForm';

export const REPO_HAIRCUT = 0.1;

export type FormProps = {
  disabled?: boolean;
  onSubmit: (values: OrderFormValues) => void;
};

export interface ConfirmationProps {
  orderForm: OrderFormState;
  onEdit?: () => void;
  onSuccess?: () => void;
}
