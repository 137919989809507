import React from 'react';

import {uniqueId} from 'lodash';

import {ComponentProps, Row} from './types';
import {DetailsContainer, DetailsRow, DetailsCell} from './styles';

const DetailsTable: React.FC<ComponentProps> = ({className, rows = []}: ComponentProps) => {
  const cellWidth: number = rows[0] ? 100 / rows[0].length : 100;
  return (
    <DetailsContainer className={className}>
      {rows.map((row: Row) => {
        const key = `row-${uniqueId()}`;
        return (
          <DetailsRow className='row' key={key}>
            {row.map((cell: string, i: number) => (
              <DetailsCell key={i} width={cellWidth}>
                {cell}
              </DetailsCell>
            ))}
          </DetailsRow>
        );
      })}
    </DetailsContainer>
  );
};

export default DetailsTable;
