import {useState} from 'react';
import {useIntl} from 'react-intl';

import {useUser} from 'contexts/auth-context';

import {TradeUnwindRequestReason} from 'types/api';
import {showToast} from 'utils/ToastUtils';

import {requestTradeUnwind} from 'api/api';

import TableRow from 'utils/TableUtils';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import {fieldTitles} from 'constants/messages/labels';

import SelectField from 'ui/SelectField';
import Button from 'ui/Button';
import Popup from 'ui/Popup';

import {Bottom, StyledTable} from 'containers/RFQPopup/styles';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {FooterLabel, InfoParagraph, SButtonsContainer} from './styles';
import {selectorItems, UnwindTradePopupProps} from './types';

export const unwindReasonToItem = (reason: TradeUnwindRequestReason | undefined) =>
  selectorItems.find(i => i.value === reason);

export const UnwindTradePopup = ({tradeId}: UnwindTradePopupProps) => {
  const userWithMeta = useUser();
  const {formatMessage} = useIntl();
  const canTrade = hasPermission({
    permissions: getRolePermissions(userWithMeta.user.role),
    scopes: [Scope.CanTrade],
  });
  const {goBack} = useModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<TradeUnwindRequestReason | undefined>(undefined);

  const isDisabled = !reason || isLoading;

  const handleClose = () => goBack();

  const onPropose = () => {
    if (!reason) {
      return;
    }

    setIsLoading(true);
    requestTradeUnwind(tradeId, reason)
      .then(() => {
        showToast('Unwind trade proposed', 'success');
        handleClose();
      })
      .catch((e: Error) => {
        showToast('Failed to propose unwind trade', 'error');
        console.error(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const delayedLegRow = (
    <TableRow key='delayedLegRow' title={formatMessage(fieldTitles.unwindReason)}>
      <SelectField
        data-testid='unwind-selector'
        className='unwind-selector'
        placeholder='--'
        disabled={!canTrade}
        items={selectorItems}
        value={unwindReasonToItem(reason)}
        onChange={option => setReason(option.value)}
        required
      />
    </TableRow>
  );

  return (
    <Popup
      title={formatMessage(fieldTitles.unwindPopUpHeader)}
      onClose={handleClose}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable className='late-fee-popup' elements={[delayedLegRow]} />
      <InfoParagraph>{formatMessage(fieldTitles.unwindInformation)}</InfoParagraph>
      <Bottom>
        <SButtonsContainer>
          <Button data-testid='cancel' buttonStyle='grey' onClick={handleClose}>
            Back
          </Button>
          <Button
            data-testid='confirm'
            loading={isLoading}
            buttonStyle='primary'
            onClick={onPropose}
            disabled={isDisabled}
          >
            Confirm
          </Button>
        </SButtonsContainer>
        <FooterLabel>{formatMessage(fieldTitles.unwindPopUpFooter)}</FooterLabel>
      </Bottom>
    </Popup>
  );
};
