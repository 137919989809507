import {useMemo} from 'react';

import styled from 'styled-components';
import Chart from 'react-apexcharts';
import {Link} from 'react-router-dom';
import {BalanceWithEquivalent} from 'types/api';
import {EquivCurrency} from 'types/layout';
import {To, convertQuantity, displayAmount, displayAmountWithCode} from 'utils/AmountUtils';
import {assetCode, displayAsset, displayAssetTitle} from 'utils/AssetUtils';

import {ApexOptions} from 'apexcharts';
import useEquivCurrencyStore from 'stores/useEquivCurrencyStore';

import {MANAGE_CAPACITY} from 'constants/paths';

import Button from 'ui/Button';

import {SCol, SRow} from 'ui/Layout/styles';

import {
  SBalanceChartContainer,
  SBalanceContent,
  SBalanceHeader,
  SBalanceLabels,
  SBalanceRightContent,
  SButtonContainer,
} from './styles';

type Props = {
  balanceData: BalanceWithEquivalent;
  chartWidth: number;
};

export type BalanceTileProps = Props;

export const SButton = styled(Button)`
  color: black;
  font-size: 12px;
  font-weight: bold;
  padding: 9px 14px;
`;

const BalanceTile = ({balanceData}: BalanceTileProps) => {
  const [equivCurrency] = useEquivCurrencyStore();
  const chartSeries = useMemo(
    () => [displayAmount(balanceData.available.amount, true), displayAmount(balanceData.earmark.amount, true)],
    [balanceData.available.amount, balanceData.earmark.amount]
  );

  const chartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        width: 100,
        height: 100,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: false,
        width: 0,
      },
      labels: ['AVAILABLE', 'EARMARK'],
      colors: ['#BEC0D2', '#97A4BF'],
      tooltip: {
        y: {
          formatter: quantity =>
            displayAmountWithCode(
              {
                asset: balanceData.asset,
                quantity: convertQuantity(quantity, To.Store),
              },
              false,
              true
            ),
        },
      },
    }),
    [balanceData.asset]
  );

  return (
    <SCol data-testid='balance-tile' width={[1, 1 / 2, 1 / 4]} padding='0 12px 12px 0'>
      <SBalanceHeader data-testid='balance-tile-header'>
        <span>{displayAssetTitle(balanceData.asset)}</span>
        <span>
          {equivCurrency === EquivCurrency.UsdEquiv ?
            balanceData.total.equivalentAmount && displayAmountWithCode(balanceData.total.equivalentAmount, false, true)
          : displayAmountWithCode(balanceData.total.amount, false, true)}
        </span>
        {equivCurrency === EquivCurrency.UsdEquiv && assetCode(balanceData.asset) !== 'USD' && (
          <span className='equiv'>Equiv</span>
        )}
      </SBalanceHeader>
      <SBalanceContent>
        <SRow>
          <SCol width={[0.8, 7 / 12, 1 / 2]}>
            <SBalanceChartContainer>
              <Chart options={chartOptions} series={chartSeries} type='pie' />
            </SBalanceChartContainer>
          </SCol>
          <SBalanceRightContent width={[1 / 3, 5 / 12, 1 / 2]}>
            <SBalanceLabels>
              <div className='row'>
                <div className='color color--available' />
                <div data-testid='available-amount' className='name'>
                  <span>Available</span>
                  <span className='name--amount'>
                    {equivCurrency === EquivCurrency.UsdEquiv ?
                      balanceData.available.equivalentAmount &&
                      displayAmountWithCode(balanceData.available.equivalentAmount, false, true)
                    : displayAmountWithCode(balanceData.available.amount, false, true)}
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='color color--earmark' />
                <div data-testid='earmark-amount' className='name'>
                  <span>Earmark</span>
                  <span className='name--amount'>
                    {equivCurrency === EquivCurrency.UsdEquiv ?
                      balanceData.earmark.equivalentAmount &&
                      displayAmountWithCode(balanceData.earmark.equivalentAmount, false, true)
                    : displayAmountWithCode(balanceData.earmark.amount, false, true)}
                  </span>
                </div>
              </div>
            </SBalanceLabels>
          </SBalanceRightContent>
        </SRow>
        <SButtonContainer>
          <Link to={MANAGE_CAPACITY.buildAbsolute({asset: balanceData.asset})}>
            <SButton type='button' data-testid={`balance-review-button-${displayAsset(balanceData.asset)}`}>
              Review
            </SButton>
          </Link>
        </SButtonContainer>
      </SBalanceContent>
    </SCol>
  );
};

export default BalanceTile;
