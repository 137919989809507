import styled, {DefaultTheme, ThemeProps, css, keyframes} from 'styled-components';
import {ifProp} from 'styled-tools';
import chroma from 'chroma-js';

import {color} from 'utils/theme';

import Box from 'ui/Box';

export const Container = styled(Box).attrs({display: 'flex', flexDirection: 'column', w: 1, h: '100%'})`
  background-color: ${color('blue900')};
  font-size: 0.75rem;
  box-sizing: border-box;
  overflow: auto;
  border: none;
  max-height: 100%;

  * {
    box-sizing: border-box;
  }

  th {
    padding: 5px 5px;
    text-transform: uppercase;
    color: rgb(163, 178, 207);
    line-height: normal;
    text-align: center;
  }

  tbody {
    min-width: 450px;
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    }
  }
`;

const unreadStyle = css`
  ${ifProp(
    'isOdd',
    css`
      background-color: ${({theme}) => chroma(theme.colors.yellow300).alpha(0.3).css()};
    `,
    css`
      background-color: ${({theme}) => chroma(theme.colors.yellow300).alpha(0.2).css()};
    `
  )}
  &:hover {
    background-color: ${({theme}) => chroma(theme.colors.yellow300).alpha(0.5).css()};
  }
`;

const flashOdd = (props: ThemeProps<DefaultTheme>) => keyframes`
  0% {
    background-color: ${chroma(props.theme.colors.yellow300).alpha(0.3).css()};
  }
  50% {
    background-color: ${chroma(props.theme.colors.yellow300).alpha(0.5).css()};
  }
  100% {
    background-color: ${chroma(props.theme.colors.yellow300).alpha(0.3).css()};
  }
`;

const flashEven = (props: ThemeProps<DefaultTheme>) => keyframes`
  0% {
    background-color: ${chroma(props.theme.colors.yellow300).alpha(0.2).css()};
  }
  50% {
    background-color: ${chroma(props.theme.colors.yellow300).alpha(0.4).css()};
  }
  100% {
    background-color: ${chroma(props.theme.colors.yellow300).alpha(0.2).css()};
  }
`;

const flashStyle = css`
  ${ifProp(
    'isOdd',
    css`
      animation: ${props => flashOdd(props)} 1s 10 ease-in-out 0.5s;
    `,
    css`
      animation: ${props => flashEven(props)} 1s 10 ease-in-out 0.5s;
    `
  )}
`;

export const Tr = styled.tr<{isUnread: boolean; isFlashing: boolean; isOdd: boolean; isClickable: boolean}>`
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
  }
  &:hover {
    background-color: ${color('blue650')};
    cursor: ${ifProp('isClickable', 'pointer', 'default')};
  }
  ${ifProp('isUnread', unreadStyle)}
  ${ifProp('isFlashing', flashStyle)}
`;

export const MessageTd = styled.td`
  color: ${color('white')};
  padding: 2rem;
  font-size: 1.25rem;
  text-align: center;
`;
