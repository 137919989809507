import {cloneElement, isValidElement, ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import {isFunction} from 'lodash/fp';
import {useMeasure} from 'react-use';

const SChartContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

type Dimensions = {
  width: number;
  height: number;
};

type Props = {
  children: ReactElement | ((dim: Dimensions) => ReactNode);
  className?: string;
};

export const ResponsiveBox = ({children, className}: Props) => {
  const [ref, {width, height}] = useMeasure<HTMLDivElement>();
  const dimensions = {
    width,
    height,
  };

  return (
    <SChartContainer className={className} ref={ref}>
      {isValidElement(children) && cloneElement(children, dimensions)}

      {isFunction(children) && children(dimensions)}
    </SChartContainer>
  );
};
