import {OrderAggregate} from 'types/api';
import {displayAmountWithUnit} from 'utils/AmountUtils';

import {ClobSideBarRow} from 'components/ClobSideBarRow/ClobSideBarRow';

import {SClobBids, SClobBidsTotal} from './styles';
import {TotalRow} from '../TotalRow/TotalRow';
import {OffersSectionProps} from '../OffersSection';

export const BidsSection = ({isRepo, data, inMillions}: OffersSectionProps) => {
  const totalBidsLabel = isRepo ? 'Total Sell' : 'Total B/S';
  const totalBidsAmount =
    data && data.length > 0 ? displayAmountWithUnit(data[data.length - 1].cumulativeAmount, inMillions) : '-';

  return (
    <>
      <SClobBids id='clob-bids' fluid>
        {data &&
          data
            .slice()
            .reverse()
            .map((agg: OrderAggregate, i: number) => {
              return (
                <ClobSideBarRow
                  key={agg.impliedYield.toString() + i.toString()}
                  side={'BuySell'}
                  agg={agg}
                  isRepo={isRepo}
                />
              );
            })}
      </SClobBids>
      <SClobBidsTotal id='clob-bids-total'>
        <TotalRow label={totalBidsLabel} amount={totalBidsAmount} />
      </SClobBidsTotal>
    </>
  );
};
