import {useQuery} from '@tanstack/react-query';

import {getClobMarketDays} from 'api/api';

export default function useClobMarketDaysQuery() {
  return useQuery({
    queryKey: ['MarketDays'],
    queryFn: () => getClobMarketDays().then(response => response.data),
    staleTime: 900_000,
  });
}
