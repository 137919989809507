import React from 'react';

import {CheckboxSwitcherProps} from './types';
import {Container, Label, Line, Dot} from './styles';

const CheckboxSwitcher: React.FC<CheckboxSwitcherProps> = (props: CheckboxSwitcherProps) => {
  const dotPosition: 'left' | 'right' = props.checked ? 'right' : 'left';

  function onToggle(): void {
    if (props.onChange && !props.disabled) {
      props.onChange(!props.checked);
    }
  }

  return (
    <Container disabled={props.disabled} onClick={onToggle} className={props.className}>
      <Label active={props.checked}>{props.label}</Label>
      <Line>
        <Dot position={dotPosition} />
      </Line>
      {props.showValueLabel && (
        <Label style={{marginLeft: '3px'}} active={props.checked}>
          {dotPosition === 'left' ? 'NO' : 'YES'}
        </Label>
      )}
    </Container>
  );
};

export default CheckboxSwitcher;
