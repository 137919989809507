export const historyMargin = {left: 60, right: 40, top: 0, bottom: 30};

export const candleStickStroke = (d: any) =>
  d.close > d.open ? '#53d89e'
  : d.close === d.open ? 'orange'
  : '#fe3d64';

export function getGridScale(chartHeight: number, chartWidth: number) {
  const tickStrokeColor: string = '#7C879C';

  const gridHeight = chartHeight - historyMargin.top - historyMargin.bottom;
  const gridWidth = chartWidth - historyMargin.left - historyMargin.right;

  const xGrid = {
    stroke: '#363940',
    innerTickSize: -1 * gridHeight,
    tickStrokeOpacity: 0.2,
    tickStroke: tickStrokeColor,
  };
  const yGrid = {
    stroke: '#363940',
    innerTickSize: -1 * gridWidth,
    tickStrokeOpacity: 0.2,
    tickStroke: tickStrokeColor,
  };
  const yGridVolume = {
    stroke: 'rgba(0, 0, 0, 0)',
    innerTickSize: 0,
    tickStrokeOpacity: 0,
    tickStroke: tickStrokeColor,
  };
  return {xGrid, yGrid, yGridVolume};
}
