import {QueryClient, UseQueryOptions, useQuery, useQueryClient} from '@tanstack/react-query';

interface SubscriptionQueryFn<T> {
  data?: T;
  lastEventId?: string;
}

export function useSubscriptionQuery<T>(
  {
    queryKey,
    queryFn,
    staleTime,
  }: Pick<UseQueryOptions<SubscriptionQueryFn<T>, Error, T>, 'queryFn' | 'queryKey' | 'initialData' | 'staleTime'>,
  subscriptionFn: (client: QueryClient, lastEventId?: string) => () => void
) {
  const client = useQueryClient();
  return useQuery<T | null, Error, T | null>({
    queryKey,
    staleTime: staleTime || Infinity,
    queryFn: async ctx => {
      let lastEventId = '0,0',
        data = null;
      if (queryFn) {
        const response = await queryFn(ctx);
        lastEventId = response.lastEventId || '0,0';
        data = response.data || null;
      }
      subscriptionFn(client, lastEventId);
      return data;
    },
  });
}
