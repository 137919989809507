import {useQuery} from '@tanstack/react-query';

import {getCashflowFlags} from 'api/api';

export default function useCashFlowFlagsQuery(tradeId: string) {
  const {data} = useQuery({
    queryKey: ['CashFlowFlags', tradeId],
    queryFn: () => getCashflowFlags(tradeId).then(response => response.data),
    enabled: !!tradeId,
  });

  return {data};
}
