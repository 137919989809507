import {useMemo, useState} from 'react';

import dayjs from 'dayjs';
import {AxiosError} from 'axios';
import {acceptRfqQuote} from 'api/api';
import {Amount, ErrorResponse, RFQQuoteWithRequest} from 'types/api';
import {showErrorToast} from 'utils/ToastUtils';
import {convertQuantity, displayAmountWithCode, To} from 'utils/AmountUtils';
import {isRepoPair} from 'utils/AssetUtils';
import {
  displayRfqAmount,
  getInterestRateForComputation,
  getCounterAmount,
  getCounterAsset,
  getQuoteValidUntil,
  getSpotRangeBigFigForComputation,
  getRfqAmounts,
  getRFQAmountFieldLabel,
} from 'utils/RFQUtils';
import {useIntl} from 'react-intl';

import TableRow from 'utils/TableUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useModal} from 'contexts/modal-context';

import {useRealTimeQuotesQuery} from 'api/hooks/useRealTimeQuotesQuery';

import {ReferenceData} from 'types/layout';

import {fieldTitles} from 'constants/messages/labels';

import {confirmationTabs} from 'constants/tabs';
import Popup from 'ui/Popup';
import Button from 'ui/Button';
import Loader from 'ui/Loader';
import BestRate from 'ui/BestRate';

import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import {Title, StyledTable, Warning, ButtonsContainer} from 'containers/RFQPopup/styles';
import {useQuoteDetailsRows} from 'containers/RFQPopup/hooks/useQuoteDetailsRows';
import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';

import AvailableCapacityWarning from 'components/AvailableCapacityWarning/AvailableCapacityWarning';

import {Economics, FormType} from 'components/Economics/Economics';

interface Props {
  quoteId: string;
}

export const AcceptQuoteConfirmation = ({quoteId}: Props) => {
  const {selectQuoteWithRequestByQuoteId} = useRealTimeQuotesQuery();
  const quoteAndRequest = selectQuoteWithRequestByQuoteId(quoteId);
  const {closeModal} = useModal();

  if (!quoteAndRequest) {
    return (
      <Popup onClose={() => closeModal()} width='360px'>
        <span className='message'>Please select a quote to show.</span>
      </Popup>
    );
  }

  return <AcceptQuoteConfirmationContent quoteAndRequest={quoteAndRequest} />;
};

const AcceptQuoteConfirmationContent = ({quoteAndRequest}: {quoteAndRequest: RFQQuoteWithRequest}) => {
  const baseAsset = quoteAndRequest.baseAsset;
  const secondAsset = quoteAndRequest.secondAsset;
  const assetPair = useMemo(() => ({base: baseAsset, second: secondAsset}), [baseAsset, secondAsset]);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);

  const quoteRows = useQuoteDetailsRows(quoteAndRequest);
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(confirmationTabs[0]);

  const {closeModal: onClose} = useModal();
  const {formatMessage} = useIntl();
  const isRepo = isRepoPair(assetPair);
  const tradedAsset = quoteAndRequest.tradedAmount.asset;

  const getEarmarkAmount = (): Amount => {
    const {sellingRfqAmount: sellingRange} = getRfqAmounts({
      pair: assetPair,
      side: quoteAndRequest.requestorSide,
      spotRangeBigFig: getSpotRangeBigFigForComputation(quoteAndRequest.requestType),
      spotRate: getInterestRateForComputation(quoteAndRequest.requestType),
      tradedAmount:
        convertQuantity(quoteAndRequest.quote.tradedAmount.quantity, To.View, true, inMillions ? 1_000_000_00 : 1_00) ??
        0,
      tradedCurrency: tradedAsset.currency,
      inMillions,
    });

    return {
      asset: sellingRange.asset,
      quantity: Math.max(...(sellingRange.quantity.filter(Boolean) as number[])),
    };
  };

  const {
    show: showAvailableCapacityWarning,
    message,
    tooltipMessage,
  } = useAvailableCapacityWarning('Quote', getEarmarkAmount());

  function onConfirm(): void {
    if (quoteAndRequest && quoteAndRequest.quote && !loading) {
      setLoading(true);
      acceptRfqQuote(quoteAndRequest.requestId, quoteAndRequest.quote.id)
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((response: AxiosError<ErrorResponse>) => {
          showErrorToast(response);
          setLoading(false);
          onClose();
        });
    }
  }
  const earmarkAmt = (
    <TableRow key='earmarkAmount' title={formatMessage(fieldTitles.earmarkAmount)} rightTestId='earmark-amount'>
      {displayAmountWithCode(getEarmarkAmount(), false, inMillions)}
    </TableRow>
  );

  const lastTradedRow = (
    <TableRow key='lastTraded' title={formatMessage(fieldTitles.lastTraded)} rightTestId='last-traded'>
      --
    </TableRow>
  );

  const clobPricingRow = (
    <TableRow key='clobPricing' title={formatMessage(fieldTitles.clobPricing)} rightTestId='clob-pricing'>
      <BestRate marketSnapshot={undefined} side={quoteAndRequest.requesteeSide} isOnlyPartialFill={undefined} />
    </TableRow>
  );

  const quoteTradedAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'quote-traded',
    assetPair,
    isRepo,
    tradedCurrency: tradedAsset.currency,
    side: quoteAndRequest.requestorSide,
  });

  const quoteTradedAmountRow = (
    <TableRow key='quoteTraded' title={formatMessage(quoteTradedAmountFieldLabel)} rightTestId='quote-request-amount'>
      {displayRfqAmount({asset: tradedAsset, quantity: [quoteAndRequest.quote.tradedAmount.quantity]}, inMillions)}
    </TableRow>
  );

  const counterAsset = getCounterAsset({baseAsset: assetPair.base, secondAsset: assetPair.second, tradedAsset});
  const counterAmountRange = getCounterAmount({
    assetPair,
    spotRangeBigFig: getSpotRangeBigFigForComputation(quoteAndRequest.requestType),
    tradedCurrency: tradedAsset.currency,
    tradedAmount:
      convertQuantity(quoteAndRequest.quote.tradedAmount.quantity, To.View, true, inMillions ? 1_000_000_00 : 1_00) ??
      0,
    isRepo,
    exchangeRate: getInterestRateForComputation(quoteAndRequest.requestType),
    inMillions,
  });

  const counterAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'counter',
    assetPair,
    isRepo,
    tradedCurrency: tradedAsset.currency,
    side: quoteAndRequest.requestorSide,
  });

  const counterAmountRow = (
    <TableRow key='counterAmount' title={formatMessage(counterAmountFieldLabel)} rightTestId='counter-amount'>
      {displayRfqAmount({asset: counterAsset, quantity: counterAmountRange ?? [0]}, inMillions)}
    </TableRow>
  );

  const uniqueRows = [lastTradedRow, clobPricingRow, earmarkAmt, quoteTradedAmountRow, counterAmountRow];
  const rows = [...quoteRows, ...uniqueRows];

  const quoteAmounts = getRfqAmounts({
    pair: assetPair,
    spotRate: getInterestRateForComputation(quoteAndRequest.requestType),
    spotRangeBigFig: getSpotRangeBigFigForComputation(quoteAndRequest.requestType),
    tradedAmount:
      convertQuantity(quoteAndRequest.quote.tradedAmount.quantity, To.View, true, inMillions ? 1_000_000_00 : 1_00) ??
      0,
    tradedCurrency: tradedAsset.currency,
    side: quoteAndRequest.requestorSide,
    inMillions,
  });

  return (
    <Popup title='Accept Quote' onClose={onClose} width='360px'>
      {loading ?
        <Loader />
      : undefined}
      <Tabs tabs={confirmationTabs} value={tab} onChange={setTab} />
      {tab.value === 'details' ?
        <>
          <Title>Please, review the details of your trade before confirming.</Title>
          <StyledTable elements={rows} />
          {showAvailableCapacityWarning && (
            <Warning>
              <AvailableCapacityWarning
                show={showAvailableCapacityWarning}
                message={message}
                tooltipMessage={tooltipMessage}
              />
            </Warning>
          )}
        </>
      : <></>}
      {tab.value === 'economics' ?
        <Economics
          formType={FormType.QuoteAcceptance}
          tradedAmount={convertQuantity(
            quoteAndRequest.tradedAmount.quantity,
            To.View,
            true,
            inMillions ? 1_000_000_00 : 1_00
          )}
          tradedAsset={quoteAndRequest.baseAsset}
          counterAsset={quoteAndRequest.secondAsset}
          side={quoteAndRequest.requestorSide}
          spotRange={getSpotRangeBigFigForComputation(quoteAndRequest.requestType)}
          spotRate={getInterestRateForComputation(quoteAndRequest.requestType)}
          nearLeg={quoteAndRequest.nearLegTime ? dayjs(quoteAndRequest.nearLegTime) : dayjs()}
          maturity={dayjs(quoteAndRequest.maturityTime)}
          rate={{
            value: quoteAndRequest.quote.forwardPoints || quoteAndRequest.quote.interestRate,
            referenceData:
              quoteAndRequest.quote.forwardPoints ? ReferenceData.ForwardPoints : ReferenceData.ImpliedYield,
          }}
          validUntil={dayjs(getQuoteValidUntil(quoteAndRequest))}
          rfqAmounts={quoteAmounts}
          assetPair={assetPair}
        />
      : <></>}
      <ButtonsContainer>
        <Button data-testid='confirm-acceptance-button' onClick={onConfirm}>
          Confirm Acceptance
        </Button>
      </ButtonsContainer>
    </Popup>
  );
};
