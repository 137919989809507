import styled, {css} from 'styled-components';
import {ifProp, switchProp} from 'styled-tools';

import {pulse} from 'utils/AnimationUtils';

import {color} from 'utils/theme';

import {ContainerProps, LabelProps, ListProps} from './types';

export const Container = styled.div<ContainerProps>`
  position: relative;
  text-align: left;
  ${ifProp(
    'disabled',
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `,
    css`
      cursor: pointer;
    `
  )}
`;

export const Label = styled.span<LabelProps>`
  position: relative;
  display: block;
  font-size: 12px;
  padding: 0.8em;
  border-width: 2px;
  border-style: solid;
  border-color: ${switchProp(
    'borderColor',
    {
      highlight: color('green500'),
      warning: color('yellow500'),
      invalid: color('red400'),
      highlightRequired: color('white'),
    },
    color('gray470')
  )};
  border-radius: 25px;
  background-color: ${p => p.theme.colors.blue900};
  ${(props: LabelProps) => props.open && 'z-index: 3;'}
  animation: ${(props: LabelProps) =>
    props.flash ?
      css`
        ${pulse} 1.5s linear
      `
    : 'none'};
`;

export const Icon = styled.i<{static?: boolean}>`
  position: ${props => (props.static ? 'static' : 'absolute')};
  top: 0.6em;
  right: 0.8em;
  bottom: 0;
  height: 14px;
  color: ${p => p.theme.colors.white};
`;

export const List = styled.ul<ListProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  list-style: none;
  transition: 0.3s all;
  overflow-y: hidden;
  padding-left: 1em;
  border-style: solid;
  border-color: ${p => p.theme.colors.gray470};
  border-radius: 0 0 25px 25px;
  z-index: 2;
  background-color: ${p => p.theme.colors.blue900};
  transform-origin: 50% 0;
  transform: scaleY(${(props: ListProps) => (props.open ? '1' : '0')}) translateY(16px);
  ${(props: ListProps) => props.open && 'padding-top: 1em;'}
  ${(props: ListProps) => props.open && 'border-width: 0 2px 2px;'}
  min-width: 64px;
`;

export const ListItem = styled.li`
  display: block;
  margin: 0.4em;
  font-size: 12px;
`;
