import {useFormContext} from 'react-hook-form';
import dayjs from 'dayjs';

import {currencyPairToAssetPair, isAssetPairEqual, isRepoPair} from 'utils/AssetUtils';
import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';
import {ReferenceData} from 'types/layout';
import {roundForwardPoints, toForwardPoints, toImpliedYield} from 'utils/AmountUtils';
import useReferenceDataStore from 'stores/useReferenceDataStore';

import {Rate} from 'types/rfq';

import {AssetPair} from 'types/api';

import {REPO_HAIRCUT} from 'containers/OrderForm/types';
import {RFQFormValues} from 'containers/RFQForm/hooks/useRFQForm';
import {OrderFormValues} from 'containers/OrderForm/hooks/useOrderForm';
import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';

export function useDisplayRate(assetPair: AssetPair, from: dayjs.Dayjs, to: dayjs.Dayjs) {
  const {exchangeRates} = useRealTimeExchangeRatesQuery();
  const {referenceData} = useReferenceDataStore();
  const isRepo = isRepoPair(assetPair);

  if (!assetPair) return () => undefined;

  const spotRate =
    isRepo ?
      1.0 / REPO_HAIRCUT
    : exchangeRates.find(er => isAssetPairEqual(currencyPairToAssetPair(er.pair), assetPair))?.rate.value;

  return (rate?: Rate) => {
    if (!rate || !spotRate) return;
    if (isRepo) {
      return rate.referenceData === ReferenceData.ForwardPoints ?
          toImpliedYield(rate.value, from, to, spotRate)
        : rate.value;
    }
    if (rate.referenceData !== referenceData) {
      if (referenceData === ReferenceData.ImpliedYield) {
        return toImpliedYield(rate.value, from, to, spotRate);
      }
      if (referenceData === ReferenceData.ForwardPoints) {
        const newFwdPoints = toForwardPoints(rate.value, from, to, spotRate);
        const roundedFwdPoints = roundForwardPoints(newFwdPoints);
        return roundedFwdPoints;
      }
    }
    return rate.value;
  };
}

export default function useFormDisplayRate() {
  const methods = useFormContext<OrderFormValues | RFQFormValues | CreateQuoteFormValues>();
  const currentTime = dayjs();

  const [assetPair, maturityDateTime, currentPair, nearleg, farleg] = methods.watch([
    'assetPair',
    'maturityDateTime',
    'currentPair',
    'nearleg',
    'farleg',
  ]);

  const from = !nearleg || nearleg === 'ASAP' ? currentTime : nearleg;
  const to = dayjs(maturityDateTime || farleg);

  return useDisplayRate(currentPair || assetPair, from, to);
}
