import {useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

import {useIntl} from 'react-intl';

import {useUser} from 'contexts/auth-context';
import {Party, Side} from 'types/api';
import {getSelectedEntityShortName, displayParty} from 'utils/utils';
import {isFxPair, isRepoPair} from 'utils/AssetUtils';

import {createFlasher} from 'utils/AnimationUtils';

import {showToast} from 'utils/ToastUtils';

import useRFQMarketsQuery from 'api/hooks/useRFQMarketsQuery';
import useReferenceDataStore from 'stores/useReferenceDataStore';

import {orderFormLimits} from 'constants/orderForm';
import {fieldTitles} from 'constants/messages/labels';
import {fxSwapTabs, repoTabs} from 'constants/tabs';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import SelectField, {FormSelectField} from 'ui/SelectField';
import {FormAmountField} from 'ui/AmountField';
import Button from 'ui/Button';
import FieldTitle from 'ui/FieldTitle';
import Box from 'ui/Box';
import FormLock from 'ui/FormLock';
import {SFormButtonsContainer, resetButtonStyles} from 'containers/OrderForm/styles';
import {FormProps} from 'containers/RFQForm/types';
import {FORM_DEFAULT_VALUES, RFQFormValues} from 'containers/RFQForm/hooks/useRFQForm';
import {Field, FormContainer, FormRow, Label, RequestButtonTooltip} from 'containers/RFQForm/styles';
import useSelectedMarketDay from 'containers/RFQForm/hooks/useSelectedMarketDay';
import useCapacityMessage from 'containers/RFQForm/hooks/useCapacityMessage';
import AvailableCapacityWarning from 'components/AvailableCapacityWarning/AvailableCapacityWarning';

import CounterpartiesRows, {startingList} from '../CounterPartiesRows';
import LegsRows from '../LegsRows';
import CounterAmountRow from '../CounterAmountRow';
import TradedAmountRow from '../TradedAmountRow';
import SuggestedRateRow from '../SuggestedRateRow';

const {id: rfqYouBuyLabelFlashId, trigger: triggerRfqYouBuyLabelFlash} = createFlasher('RfqFarYouBuyLabel');
const {id: rfqYouSellLabelFlashId, trigger: triggerRfqYouSellLabelFlash} = createFlasher('RfqFarYouSellLabel');
const {id: counterparty1FlashId, trigger: triggerCounterpartyFlash} = createFlasher('counterParty1');
const flashYouBuySellLabels = () => {
  triggerRfqYouBuyLabelFlash();
  triggerRfqYouSellLabelFlash();
};

export const RFQForm = ({disabled, onSubmit}: FormProps) => {
  const {assetPairs, marketDays} = useRFQMarketsQuery();
  const user = useUser();
  const {referenceData} = useReferenceDataStore();

  const userEntities = useMemo(
    () =>
      user.legalEntities.map((legalEntity: Party) => ({
        label: displayParty(legalEntity),
        value: legalEntity.legalName,
      })),
    [user]
  );

  const initialEntity = userEntities.at(0)?.value;

  const {formatMessage} = useIntl();

  const methods = useFormContext<RFQFormValues>();
  const {selectedMarket, selectedNearLegMarketDay, hasClosedMarket, marketReopensDate, getMarketDayForTransactionDate} =
    useSelectedMarketDay();

  const {currentPair: pair, side, counterparty1, counterparty2, counterparty3, exchangeRate} = methods.watch();

  const isRepo: boolean = isRepoPair(pair, false);
  const isFXSwap: boolean = isFxPair(pair, true);
  const isCounterpartySelected = counterparty1 || counterparty2 || counterparty3;

  // Tabs
  const tabs = isRepo ? repoTabs : fxSwapTabs;
  const currentTab: Tab = tabs.find((value: Tab) => value.value === side) || tabs[0];

  const onChangeTab = (newTab: Tab): void => {
    methods.setValue('side', newTab.value as Side);
    flashYouBuySellLabels();
  };

  const entitySelected =
    (userEntities && userEntities.find(userEntity => userEntity.value === initialEntity)) || startingList[0];

  // ImplYld Message
  const entityShortName = getSelectedEntityShortName(user, entitySelected.label);

  useEffect(() => {
    methods.setValue('rate', undefined);
  }, [pair?.base, pair?.second]);

  const onReset = () => {
    methods.reset({
      ...FORM_DEFAULT_VALUES,
      side,
      exchangeRate: methods.getValues().exchangeRate,
      rate: {value: undefined, referenceData},
    });
  };

  const {
    show: showAvailableCapacityWarning,
    message: capacityMessage,
    tooltipMessage: capacityTooltipMessage,
  } = useCapacityMessage();

  const requestTooltipMessage =
    currentTab.value === 'SellBuy' ?
      'Buy (Reverse Repo) = Buy Securities, Lend Cash'
    : 'Sell (Repo) = Sell Securities, Borrow Cash';

  const disabledOrClosedMarket = disabled || hasClosedMarket;
  const isButtonDisabled = disabled || !marketDays.length || hasClosedMarket || !exchangeRate;

  const errorMessage = useMemo(() => {
    if (!pair) return;
    if (!marketDays.length) return 'Market days are unavailable.';
    if (hasClosedMarket)
      return `Selected market is currently closed. It reopens at ${marketReopensDate?.format('DD MMM HH:mm')}.`;
    if (!exchangeRate) return 'Exchange rate for selected asset pair is temporarily unavailable.';
  }, [JSON.stringify(marketDays), hasClosedMarket, marketReopensDate, JSON.stringify(exchangeRate)]);

  return (
    <FormContainer
      onSubmit={methods.handleSubmit(
        values => {
          onSubmit(values);
        },
        () => {
          if (!isCounterpartySelected) {
            showToast('Please select at least 1 counterparty', 'error');
            triggerCounterpartyFlash();
          } else {
            showToast('Please verify the form data and try again.', 'error');
          }
        }
      )}
    >
      <Tabs data-testid='rfq-form-side' tabs={tabs} value={currentTab} onChange={onChangeTab} />
      <FormRow>
        <Label>
          <FieldTitle>{formatMessage(fieldTitles.market)}</FieldTitle>
        </Label>
        <Field>
          <FormSelectField
            name='currentPair'
            data-testid='market-selector'
            items={assetPairs}
            disabled={disabled}
            highlight
          />
        </Field>
      </FormRow>
      <Box position='relative'>
        {errorMessage && (
          <FormLock data-testid='form-lock'>
            <p>{errorMessage}</p>
            <div />
          </FormLock>
        )}
        <CounterpartiesRows flashIds={[counterparty1FlashId]} disabled={disabledOrClosedMarket} />
        <TradedAmountRow
          isRepo={isRepo}
          disabled={disabledOrClosedMarket}
          rfqYouSellLabelFlashId={rfqYouSellLabelFlashId}
          rfqYouBuyLabelFlashId={rfqYouBuyLabelFlashId}
          flashYouBuySellLabels={flashYouBuySellLabels}
          availableCapacityWarning={capacityMessage}
          selectedMarket={selectedMarket}
        />
        <CounterAmountRow
          rfqYouBuyLabelFlashId={rfqYouBuyLabelFlashId}
          rfqYouSellLabelFlashId={rfqYouSellLabelFlashId}
          isRepo={isRepo}
          selectedMarket={selectedMarket}
        />
        {isFXSwap && (
          <FormRow>
            <Label>
              <FieldTitle>{formatMessage(fieldTitles.spotRange)}</FieldTitle>
            </Label>
            <Field>
              <FormAmountField
                name='bigfig'
                unit='bigfig'
                tooltipPosition='left'
                prefix='+/-'
                disabled={disabledOrClosedMarket}
                min={orderFormLimits.spotRangeBigFig.min}
                max={orderFormLimits.spotRangeBigFig.max}
                data-testid='spot-range'
              />
            </Field>
          </FormRow>
        )}
        <LegsRows
          selectedMarket={selectedMarket}
          selectedNearLegMarketDay={selectedNearLegMarketDay}
          isRepo={isRepo}
          disabled={disabledOrClosedMarket}
          getMarketDayForTransactionDate={getMarketDayForTransactionDate}
        />
        <FormRow>
          <Label>
            <FieldTitle>{formatMessage(fieldTitles.validFor)}</FieldTitle>
          </Label>
          <Field>
            <FormAmountField
              name='validFor'
              unit='min'
              decimals={0}
              min={1}
              max={30}
              tooltipPosition='left'
              disabled={disabledOrClosedMarket}
              data-testid='valid-for'
            />
          </Field>
        </FormRow>
        <SuggestedRateRow
          isRepo={isRepo}
          entityShortName={entityShortName}
          disabled={disabledOrClosedMarket || !pair}
        />
        <FormRow>
          <Label>
            <FieldTitle>{formatMessage(fieldTitles.entity)}</FieldTitle>
          </Label>
          <Field>
            <SelectField items={userEntities} value={entitySelected} disabled />
          </Field>
        </FormRow>
        <FormRow minHeight={40}>
          <AvailableCapacityWarning
            show={showAvailableCapacityWarning}
            message={capacityMessage}
            tooltipMessage={capacityTooltipMessage}
          />
        </FormRow>
        <SFormButtonsContainer>
          <RequestButtonTooltip disabled={disabledOrClosedMarket || !isRepo} message={requestTooltipMessage}>
            <Button
              data-testid='rfq-submit-btn'
              buttonStyle={currentTab.value === 'SellBuy' ? 'secondary' : 'primary'}
              disabled={isButtonDisabled}
              width='100%'
              type='submit'
            >
              Request {currentTab.label}
            </Button>
          </RequestButtonTooltip>

          <Button data-testid='reset-form-button' buttonStyle='grey' style={resetButtonStyles} onClick={onReset}>
            Reset
          </Button>
        </SFormButtonsContainer>
      </Box>
    </FormContainer>
  );
};

export default RFQForm;
