import Loader from 'ui/Loader';
import Button from 'ui/Button';

import {SCancelMessage, SCancelMessageText, SCancelMessageTextItalic} from './styles';

export interface CancelOrderModalProps {
  title: string;
  description: string;
  onConfirm: () => void;
  closeModal: () => void;
  isLoading: boolean;
}

export default function CancelOrderModal({
  title,
  description,
  onConfirm,
  closeModal,
  isLoading,
}: CancelOrderModalProps) {
  return (
    <SCancelMessage width={[0.83, 0.207, 0.207]}>
      {isLoading && <Loader />}
      <div>
        <SCancelMessageText>{title}</SCancelMessageText>
        <SCancelMessageTextItalic>{description}</SCancelMessageTextItalic>
      </div>
      <div>
        <Button data-testid='cancel-modal-confirm' onClick={onConfirm} style={{marginRight: '0.8rem'}}>
          Confirm
        </Button>
        <Button data-testid='cancel-modal-decline' onClick={closeModal} buttonStyle='transparent'>
          Decline
        </Button>
      </div>
    </SCancelMessage>
  );
}
