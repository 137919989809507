import {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import {displayForwardPoints, displayInterestRate, dropUndefined, getRFC2253ValueByKey} from 'utils/utils';
import {
  convertQuantity,
  displayAmountWithCode,
  displayRangeWithCode,
  displaySpotRangeBigfig,
  To,
  expectedRangeOfAmountExchanged,
  earmarkAmount,
  payingOrReceivingInterest,
  convertQuantityToAmount,
} from 'utils/AmountUtils';
import {ConfirmationStepLabels, MarketType} from 'types/orderForm';
import {Amount} from 'types/api';
import {ReferenceData} from 'types/layout';
import {useIntl} from 'react-intl';
import {displayAssetPair, displaySide, isRepoPair} from 'utils/AssetUtils';
import {displayDate} from 'utils/DayjsUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useUser} from 'contexts/auth-context';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import useStateAssets from 'utils/hooks/useStateAssets';

import {useDisplayRate} from 'utils/hooks/useDisplayRate';

import {fieldTitles} from 'constants/messages/labels';
import Table from 'ui/Table';
import {Row} from 'ui/Table/types';
import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

import {ConfirmationProps, REPO_HAIRCUT} from './types';
import {TabContent, TabHeader} from './styles';
import {repoSecuritiesMessage} from '../RFQForm/types';

export const Details = ({orderForm}: ConfirmationProps) => {
  const {formatMessage} = useIntl();
  const {legalEntities} = useUser();
  const [initialEntity, setInitialEntity] = useState<string>('');
  const {referenceData} = useReferenceDataStore();
  const {baseAsset, secondAsset} = useStateAssets();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base: baseAsset, second: secondAsset});
  const isRepo = isRepoPair(orderForm.assetPair);
  const parseRate = useDisplayRate(orderForm.assetPair, dayjs(), dayjs(orderForm.maturity));

  useEffect(() => {
    const newEntity = legalEntities[0];
    setInitialEntity(newEntity?.legalName);
  }, [JSON.stringify(legalEntities)]);

  if (orderForm.baseAmount === undefined) {
    return <></>;
  }

  // Calculate values
  const baseAmount = convertQuantityToAmount(orderForm.baseAmount, To.Store, baseAsset, true, inMillions);

  const isFwdPts = referenceData === ReferenceData.ForwardPoints;
  const isMarket = orderForm.marketType === MarketType.Market;
  const initialFXRate = orderForm.initialRate;

  const secondRange: number[] = expectedRangeOfAmountExchanged(
    convertQuantity(orderForm.baseAmount, To.Store, true, inMillions ? 1_000_000_00 : 1_00),
    isRepo ? 1.0 / (1 - REPO_HAIRCUT) : initialFXRate,
    isRepo ? 0 : orderForm.spotRangeBigFig
  );

  const orderEarmarkAmount: Amount =
    isRepo ?
      orderForm.side === 'BuySell' ?
        {asset: orderForm.assetPair.second, quantity: secondRange[0]}
      : baseAmount
    : earmarkAmount(baseAmount, secondAsset.currency, orderForm.initialRate, orderForm.spotRangeBigFig, orderForm.side);

  // Rows for table
  const currencyRow: Row = [
    <FieldTitle key='currencyTitle'>{formatMessage(fieldTitles.market)}</FieldTitle>,
    <FieldValue data-testid='selected-currency-value' key='selectedCurrencyValue'>
      {displayAssetPair(orderForm.assetPair)}
    </FieldValue>,
  ];

  const orderType: Row = [
    <FieldTitle key='orderTypeTitle'>{formatMessage(ConfirmationStepLabels.type)}</FieldTitle>,
    <FieldValue data-testid='order-type-value' key='orderTypeValue'>{`${orderForm.marketType} ${displaySide(
      orderForm.side,
      isRepo,
      false
    )}`}</FieldValue>,
  ];

  const baseAmountRow: Row = [
    <FieldTitle key='baseAmountRowTitle'>
      {formatMessage(isRepo ? ConfirmationStepLabels.repoAmt : ConfirmationStepLabels.baseAmt)}
    </FieldTitle>,
    <FieldValue data-testid='base-amt-value' key='baseAmountRowValue'>
      {displayAmountWithCode(baseAmount, false, inMillions)}
    </FieldValue>,
  ];

  const secondAmountTableValue =
    isRepo ?
      <TooltipWrapper key='secondAmountRowValue' position={'left'} message={repoSecuritiesMessage}>
        <FieldValue data-testid='second-amt-value'>
          {displayAmountWithCode(
            {
              asset: orderForm.assetPair.second,
              quantity: secondRange[0],
            },
            false,
            inMillions
          )}
        </FieldValue>
      </TooltipWrapper>
    : <FieldValue data-testid='second-amt-value' key='secondAmountRowValue'>
        {displayRangeWithCode(
          {asset: orderForm.assetPair.second, quantity: secondRange[0]},
          {asset: orderForm.assetPair.second, quantity: secondRange[1]},
          inMillions
        )}
      </FieldValue>;

  const secondAmountRow: Row = [
    <FieldTitle key='secondAmountRowTitle'>
      {formatMessage(isRepo ? ConfirmationStepLabels.securitiesAmount : ConfirmationStepLabels.secondAmount)}
    </FieldTitle>,
    secondAmountTableValue,
  ];

  const initialRate: Row | undefined =
    isRepo ? undefined : (
      [
        <FieldTitle key='initialRateTitle'>{formatMessage(ConfirmationStepLabels.initialRate)}</FieldTitle>,
        <FieldValue key='initialRateValue'>{orderForm.initialRate.toString()}</FieldValue>,
      ]
    );

  const interestRate: Row | undefined =
    isMarket ? undefined : (
      [
        <FieldTitle key='interestRateTitle'>
          {formatMessage(
            isRepo ? ConfirmationStepLabels.rate
            : isFwdPts ? ConfirmationStepLabels.fwdPts
            : ConfirmationStepLabels.implYield
          )}
        </FieldTitle>,
        <FieldValue data-testid='imp-yield-value' key='interestRateValue'>
          {orderForm.marketType === MarketType.Market || (!isRepo && isFwdPts) ?
            displayForwardPoints(parseRate(orderForm.rate))
          : displayInterestRate(parseRate(orderForm.rate))}
        </FieldValue>,
      ]
    );

  const interestSide: Row = [
    <FieldTitle key='interestSideTitle'>{formatMessage(ConfirmationStepLabels.interestSide)}</FieldTitle>,
    <FieldValue key='interestSideValue'>
      {!orderForm.rate || (orderForm.rate.referenceData === ReferenceData.ImpliedYield && !orderForm.rate?.value) ?
        '--'
      : payingOrReceivingInterest(orderForm.side, orderForm.rate?.value)}
    </FieldValue>,
  ];

  const spotRange: Row | undefined =
    isRepo ? undefined : (
      [
        <FieldTitle key='spotRangeTitle'>{formatMessage(ConfirmationStepLabels.spotRange)}</FieldTitle>,
        <FieldValue key='spotRangeValue' data-testid='spot-range-value'>{`${displaySpotRangeBigfig(
          orderForm.spotRangeBigFig
        )} bigfig`}</FieldValue>,
      ]
    );

  const earmarkAmountRow: Row = [
    <FieldTitle key='earmarkAmountTitle'>{formatMessage(ConfirmationStepLabels.earmarkAmount)}</FieldTitle>,
    <FieldValue key='earmarkAmountValue' data-testid='earmark-amount-value'>
      {displayAmountWithCode(orderEarmarkAmount, true, inMillions)}
    </FieldValue>,
  ];

  const validUntil: Row = [
    <FieldTitle key='validUntilTitle'>{formatMessage(ConfirmationStepLabels.validUntil)}</FieldTitle>,
    <FieldValue key='validUntilValue' data-testid='valid-until-value'>
      {displayDate(orderForm.validUntil, 'HH:mm')}
    </FieldValue>,
  ];

  const entity: Row = [
    <FieldTitle key='entityTitle'>{formatMessage(ConfirmationStepLabels.entity)}</FieldTitle>,
    <FieldValue key='entityValue'>{getRFC2253ValueByKey(initialEntity, 'O')}</FieldValue>,
  ];

  const counterparty: Row = [
    <FieldTitle key='counterpartyTitle'>{formatMessage(ConfirmationStepLabels.counterparty)}</FieldTitle>,
    <FieldValue key='counterpartyValue'>{orderForm.counterparty}</FieldValue>,
  ];

  const maturity: Row = [
    <FieldTitle key='maturityTitle'>{formatMessage(ConfirmationStepLabels.maturity)}</FieldTitle>,
    <FieldValue key='maturityValue' data-testid='maturity-value'>
      {displayDate(orderForm.maturity, 'HH:mm')}
    </FieldValue>,
  ];

  const nonAggressorOnly: Row = [
    <FieldTitle key='nonAggressorOnlyTitle'>{formatMessage(ConfirmationStepLabels.isNonAggressorOnly)}</FieldTitle>,
    <FieldValue data-testid='non-aggressor-value' key='nonAggressorOnlyValue'>
      {orderForm.isNonAggressorOnly ? 'Yes' : 'No'}
    </FieldValue>,
  ];

  const rows: Row[] = dropUndefined([
    currencyRow,
    orderType,
    baseAmountRow,
    secondAmountRow,
    initialRate,
    interestRate,
    interestSide,
    spotRange,
    earmarkAmountRow,
    validUntil,
    entity,
    counterparty,
    maturity,
    nonAggressorOnly,
  ]);

  return (
    <TabContent>
      <TabHeader data-testid='details-header'>
        <p>Please, review the details of your order before confirming.</p>
      </TabHeader>
      <Table rows={rows} />
    </TabContent>
  );
};

export default Details;
