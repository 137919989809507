import {PropsWithChildren} from 'react';

interface BalanceProps {
  currency: string;
}

const Balance = ({children, currency}: PropsWithChildren<BalanceProps>) => (
  <span className='balance'>
    <span className='balance-box d-flex text-center '>
      <span data-testid='user-balance' className='balance-available d-flex flex-column px-3 '>
        <span className='title pb-1'>{currency}</span>
        {children}
      </span>
    </span>
  </span>
);

export default Balance;
