import useMarketSnapshotsQuery from 'api/hooks/useMarketSnapshotsQuery';

import './ClobOverview.scss';
import ClobOverviewTiles from './components/ClobOverviewTiles';

export const ClobOverview = () => {
  const {marketSnapshots, isLoading} = useMarketSnapshotsQuery();

  return <ClobOverviewTiles isLoading={isLoading} marketSnapshots={marketSnapshots} />;
};
