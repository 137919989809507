import styled from 'styled-components';

import {CellProps} from './types';

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 0;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: 0.8em;

  &:nth-of-type(2n) {
    background-color: ${p => p.theme.colors.blue950};
  }
`;

export const StyledLinkDiv = styled.div`
  &:nth-of-type(2n) {
    background-color: ${p => p.theme.colors.blue950};
  }

  :hover {
    background: ${p => p.theme.colors.gray500};
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;

  &:nth-of-type(2n) {
    background-color: ${p => p.theme.colors.blue950};
  }
`;

export const Cell = styled.div<CellProps>`
  flex-grow: 1;
  color: ${p => p.theme.colors.gray50};
  padding: 0 0.2em;
  width: ${(props: CellProps) => props.width}%;
  font-size: 0.75em;
`;

export const DetailsCell = styled.div<CellProps>`
  flex-grow: 1;
  color: ${p => p.theme.colors.gray50};
  padding: 1em 2em;
  width: ${(props: CellProps) => props.width}%;
  font-size: 12px;
  height: 100%;
  min-width: 220px;
  text-transform: uppercase;

  &:nth-of-type(1) {
    color: ${p => p.theme.colors.blue50};
  }
  &:nth-of-type(2) {
    background-color: ${p => p.theme.colors.blue950};
    color: ${p => p.theme.colors.blue50};
  }
`;
