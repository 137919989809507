import {useIntl} from 'react-intl';

import {showToast} from 'utils/ToastUtils';

import {AxiosError} from 'axios';

import {useCallback} from 'react';

import {acceptEarlyMaturity, rejectEarlyMaturity, requestEarlyMaturity, withdrawEarlyMaturity} from 'api/api';

import {hasPendingReceivedEarlyMaturity, hasPendingSentEarlyMaturity} from 'utils/TradeUtils';

import {Trade} from 'types/api';

import {displayDate} from 'utils/DayjsUtils';
import {getRFC2253ValueByKey} from 'utils/utils';

import {useUser} from 'contexts/auth-context';

import TableRow from 'utils/TableUtils';

import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {Scope} from 'constants/permission-maps';

import {fieldTitles} from 'constants/messages/labels';

import Popup from 'ui/Popup';

import {Bottom, StyledTable} from 'containers/RFQPopup/styles';

import {UnwindTradePopupProps} from 'components/popups/TradePopup/components/UnwindTradePopup/types';

import {InfoParagraph} from 'components/popups/TradePopup/components/UnwindTradePopup/styles';
import {ActionButton} from 'components/ConfirmationButtons/types';
import ConfirmationButtons from 'components/ConfirmationButtons/ConfirmationButtons';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

export type EarlyMaturityActionsPopupProps = UnwindTradePopupProps;

enum PopupType {
  Received = 'Received',
  Request = 'Request',
  Withdraw = 'Withdraw',
}
const getPopupType = (trade: Trade): PopupType => {
  if (hasPendingReceivedEarlyMaturity(trade)) {
    return PopupType.Received;
  }
  if (hasPendingSentEarlyMaturity(trade)) {
    return PopupType.Withdraw;
  }
  return PopupType.Request;
};

/* We will decide what information to display inside this popup by earlyMaturity field in trade:
1) If this field is undefined -> its Request Early Maturity
2) If this field is presented, BUT "canBeAccepted" field is FALSY -> its Withdraw Early Maturity
3) If this field is presented, BUT "canBeAccepted" field is TRUE -> its Received Early Maturity = Accept/Reject buttons
*/
export const EarlyMaturityActionsPopup = ({tradeId}: EarlyMaturityActionsPopupProps) => {
  const {user} = useUser();
  const {goBack, closeModal: handleClose} = useModal();
  const {formatMessage} = useIntl();
  const {selectTradeById} = useRealTimeTradesQuery();
  const canTrade = hasPermission({
    permissions: getRolePermissions(user.role),
    scopes: [Scope.CanTrade],
  });

  const onRequestEarlyMaturity = useCallback((): void => {
    requestEarlyMaturity(tradeId)
      .then(() => {
        showToast('Early Maturity Request has been sent.', 'success');
        handleClose();
      })
      .catch((e: AxiosError) => {
        console.error(e);
        let message = 'There was an error when requesting early maturity.';
        if (e.response && e.response.status === 409) {
          message = 'Invalid trade status or early maturity request already present.';
        }

        showToast(message, 'warning');
      });
  }, [handleClose, tradeId]);

  const onWithdrawEarlyMaturity = useCallback(() => {
    withdrawEarlyMaturity(tradeId)
      .then(() => {
        showToast('Early maturity request has been withdrawn.', 'success');
        handleClose();
      })
      .catch((e: AxiosError) => {
        console.error(e);
        showToast('Could not withdraw the early maturity request.', 'warning');
      });
  }, [handleClose, tradeId]);

  const onRejectEarlyMaturity = useCallback(() => {
    rejectEarlyMaturity(tradeId)
      .then(() => {
        showToast('Early maturity request has been rejected.', 'success');
        handleClose();
      })
      .catch((e: AxiosError) => {
        console.error(e);
        showToast('Could not reject the early maturity request.', 'warning');
      });
  }, [handleClose, tradeId]);

  const onAcceptEarlyMaturity = useCallback((): void => {
    acceptEarlyMaturity(tradeId)
      .then(() => {
        showToast('Early maturity request has been accepted.', 'success');
        handleClose();
      })
      .catch((e: AxiosError) => {
        console.error(e);
        showToast('Could not accept early maturity request.', 'warning');
      });
  }, [handleClose, tradeId]);

  const trade = selectTradeById(tradeId);
  if (!trade) return null;

  const popupType = getPopupType(trade);

  const counterpartyRow = (
    <TableRow key='counterparty' title={formatMessage(fieldTitles.counterparty)} rightTestId={'counterparty-value'}>
      {getRFC2253ValueByKey(trade.counterparty, 'O')}
    </TableRow>
  );

  const nearLegDate = (
    <TableRow key='nearLegDate' title={formatMessage(fieldTitles.nearLeg)} rightTestId={'near-leg-date-value'}>
      {displayDate(trade.nearLeg.scheduledTime, 'DD MMM YYYY HH:mm:ss')}
    </TableRow>
  );

  const farLegDate = (
    <TableRow key='farLegDate' title={formatMessage(fieldTitles.farLeg)} rightTestId={'far-leg-date-value'}>
      {displayDate(trade.farLeg.scheduledTime, 'DD MMM YYYY HH:mm:ss')}
    </TableRow>
  );

  const tableRows = [counterpartyRow, nearLegDate, farLegDate];

  const getActions = (): ActionButton[] => {
    switch (popupType) {
      case PopupType.Withdraw:
        return [
          {
            label: 'Confirm Withdrawal',
            onClick: onWithdrawEarlyMaturity,
            buttonStyle: 'primary',
          },
        ];
      case PopupType.Received:
        return [
          {
            label: 'Reject',
            onClick: onRejectEarlyMaturity,
            buttonStyle: 'secondary',
            disabled: !canTrade,
          },
          {
            label: 'Accept',
            onClick: onAcceptEarlyMaturity,
            disabled: !canTrade,
            buttonStyle: 'primary',
          },
        ];
      default:
        return [
          {
            label: 'Confirm Request',
            onClick: onRequestEarlyMaturity,
            buttonStyle: 'primary',
          },
        ];
    }
  };

  const onClose = () => {
    goBack();
  };

  return (
    <Popup title={popupType + ' Early Maturity'} onClose={onClose} width='500px' closeButtonType='arrow'>
      <StyledTable elements={tableRows} />
      {popupType === PopupType.Request ?
        <InfoParagraph>
          {formatMessage(
            trade.tradeUnwind ? fieldTitles.earlyMaturityInformationWithUnwind : fieldTitles.earlyMaturityInformation
          )}
        </InfoParagraph>
      : <></>}
      <Bottom data-testid='popup-buttons'>
        <ConfirmationButtons actions={getActions()} withConfirmationMessage={false} />
      </Bottom>
    </Popup>
  );
};
