import dayjs from 'dayjs';

import {useCallback, useMemo} from 'react';

import {useIntl} from 'react-intl';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {RFQQuoteWithRequest, RFQRequest} from 'types/api';

import {useUser} from 'contexts/auth-context';

import {useRealTimeQuotesQuery} from 'api/hooks/useRealTimeQuotesQuery';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {useModal} from 'contexts/modal-context';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import GridTable, {GridTableVariant} from 'ui/GridTable';

import RFQOverviewPopup from 'components/popups/RFQOverviewPopup';

import {getBlotterQuotesTableColumns} from './columns';

const QuotesTable = () => {
  const {formatMessage} = useIntl();
  const {openModal} = useModal();
  const {quotes} = useRealTimeQuotesQuery();
  const user = useUser();
  const {referenceData} = useReferenceDataStore();
  const {rfqRequestsSent, rfqRequestsReceived} = useRealTimeRequestsQuery();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const allRequests = useMemo(
    () => [...rfqRequestsReceived, ...rfqRequestsSent],
    [rfqRequestsReceived, rfqRequestsSent]
  );

  const showQuoteDetails = useCallback(
    (request: RFQRequest, quoteId: string) =>
      openModal({modal: RFQOverviewPopup, props: {requestId: request.id, quoteId, defaultView: 'details'}}),
    []
  );

  const onRowClick = useCallback(
    (quoteDetails: RFQQuoteWithRequest): void => {
      // get all requests from blotter
      // find corresponding request for the quote
      const request = allRequests.find(req => req.id === quoteDetails.requestId);
      // if found, show details
      if (request) {
        showQuoteDetails(request, quoteDetails.quote.id);
      }
    },
    [allRequests, showQuoteDetails]
  );

  const sortedQuotes = useMemo(
    () => quotes.sort((a, b) => (dayjs(b.quote.createdAt).isAfter(dayjs(a.quote.createdAt)) ? 1 : -1)),
    [quotes]
  );

  const columns = useMemo(
    () => getBlotterQuotesTableColumns(formatMessage, referenceData, user, shouldDisplayInMillions),
    [formatMessage, referenceData, user, shouldDisplayInMillions]
  );

  const getRowId = useCallback((rfq: RFQQuoteWithRequest) => rfq.quote.id, []);

  return (
    <div data-testid='quotes-table' className='table-container'>
      <GridTable
        defaultSorted={[{id: 'quote.createdAt', desc: true}]}
        columns={columns}
        data={sortedQuotes}
        variant={GridTableVariant.Minimal}
        onRowClick={onRowClick}
        sortable
        getRowId={getRowId}
      />
    </div>
  );
};

export default QuotesTable;
