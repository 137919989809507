import RTCreditRisk from 'pages/AnalyticsPage/components/RTCreditRisk';
import HistoricalCreditRisk from 'pages/AnalyticsPage/components/HistoricalCreditRisk/HistoricalCreditRisk';
import ManageLimits from 'pages/AnalyticsPage/components/ManageLimits';

import {Scope} from 'constants/permission-maps';

import {
  AGGRESSOR_BY_CPARTY,
  HIST_CLOB_EXPOSURE,
  HIST_CREDIT_RISK,
  MANAGE_LIMITS,
  RT_CLOB_EXPOSURE,
  RT_CREDIT_RISK,
  TIMING_BY_CPARTY,
  FO_ACTIVITY_REPORTS,
  TRANSACTION_FEES_REPORTS,
} from 'constants/paths';

import FrontOfficeActivityReports from './components/FrontOfficeActivityReports';
import TransactionFeesReports from './components/TransactionFeesReports';

export const collapsableAnalyticsSideBarMenu = [
  {
    key: 'credit-risk-analytics',
    title: 'Credit Risk Analytics',
    requiredScope: Scope.ViewCreditRiskAnalytics,
    subItems: [
      {
        key: 'rt-credit-risk',
        label: 'RT Credit Risk',
        path: RT_CREDIT_RISK,
        component: <RTCreditRisk />,
      },
      {
        key: 'historical-credit-risk',
        label: 'Historical Credit Risk',
        path: HIST_CREDIT_RISK,
        component: <HistoricalCreditRisk />,
      },
      {
        key: 'rt-clob-exposure',
        label: 'RT CLOB Exposure',
        path: RT_CLOB_EXPOSURE,
        component: <h2>RT CLOB Exposure</h2>,
      },
      {
        key: 'historical-clob-exposure',
        label: 'Historical CLOB Exposure',
        path: HIST_CLOB_EXPOSURE,
        component: <h2>Historical CLOB Exposure</h2>,
      },
      {
        key: 'manage-limits',
        label: 'Manage Limits',
        path: MANAGE_LIMITS,
        component: <ManageLimits />,
      },
    ],
    isOpen: true,
  },
  {
    key: 'settlements-analytics',
    title: 'Settlements Analytics',
    subItems: [
      {
        key: 'timing-by-counterparty',
        label: 'Timing By C’party',
        path: TIMING_BY_CPARTY,
        component: <h2>Timing By C’party</h2>,
      },
    ],
    isOpen: false,
  },
  {
    key: 'commercial-analytics',
    title: 'Commercial Analytics',
    subItems: [
      {
        key: 'aggressor-by-counterparty',
        label: 'Aggressor By C’party',
        path: AGGRESSOR_BY_CPARTY,
        component: <h2>Aggressor By C’party</h2>,
      },
      {
        key: 'fo-activity-reports',
        label: 'FO Activity Reports',
        path: FO_ACTIVITY_REPORTS,
        component: <FrontOfficeActivityReports />,
      },
      {
        key: 'transaction-fees-reports',
        label: 'Finteum Transaction Fees',
        path: TRANSACTION_FEES_REPORTS,
        component: <TransactionFeesReports />,
      },
    ],
    isOpen: false,
  },
];
