import {isRFQRequestExpired} from 'utils/RFQUtils';
import {RFQRequest} from 'types/api';
import {ReferenceData} from 'types/layout';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {useModal} from 'contexts/modal-context';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import RFQOverviewPopup from 'components/popups/RFQOverviewPopup';

import SentRequestListItem from '../SentRequestListItem';
import {ListContainer, ListHeader} from '../../styles';

const LatestRequestsSent = () => {
  const {rfqRequestsSent} = useRealTimeRequestsQuery();
  const {openModal} = useModal();
  const {referenceData} = useReferenceDataStore();

  return (
    <ListContainer>
      <ListHeader>Latest Requests Sent</ListHeader>

      {rfqRequestsSent.map((request: RFQRequest) => {
        const isExpired: boolean = isRFQRequestExpired(request);
        const handleClick = () =>
          openModal({modal: RFQOverviewPopup, props: {requestId: request.id, defaultView: 'comparison'}});

        return (
          !isExpired && (
            <SentRequestListItem
              testid='latest-request-sent-item'
              key={request.id}
              request={request}
              showForwardPoints={referenceData === ReferenceData.ForwardPoints}
              expired={isExpired}
              showComparison={handleClick}
            />
          )
        );
      })}
    </ListContainer>
  );
};

export default LatestRequestsSent;
