import {unifiedEventStream} from 'api/unifiedEventStream';
import {AggregatedLimit} from 'types/api';
import {QueryClient} from '@tanstack/react-query';

import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['AggregatedLimits'];

function subscribeLimits(client: QueryClient) {
  const handler = (limits: AggregatedLimit[]) => {
    client.setQueryData(QUERY_KEY, () => limits);
    return {type: ''};
  };

  return unifiedEventStream.subscribe('limit', handler, {lastEventId: '0,0'});
}

export const useRealTimeLimitsQuery = () => {
  const {data: limits} = useSubscriptionQuery<AggregatedLimit[]>(
    {
      queryKey: QUERY_KEY,
    },
    subscribeLimits
  );

  return {
    limits: limits || [],
  };
};
