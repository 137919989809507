import styled from 'styled-components';

export const Container = styled.div<{pays?: boolean; noSpacing?: boolean}>`
   {
    display: flex;
    justify-content: flex-start;
    height: 12px;
    margin-top: ${p => (p.noSpacing ? 0 : '4px')};
    color: ${p => (p.pays ? p.theme.colors.red500 : p.theme.colors.green500)};
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
  }
`;
