export const jsonContentConfig: object = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const fxConfig: object = {
  // headers: {Authorization: `bearer ${token.replace(/"/g, '')}`},
  crossDomain: true,
};
