import styled, {CSSProperties} from 'styled-components';
import {setInlineRules} from 'utils/utils';

import {ButtonProps} from './types';

const ActionButtonComponent = styled.div<{
  hoverStyle?: CSSProperties;
  disabled?: boolean;
}>`
  cursor: pointer;
  user-select: none;
  margin: 8px 0 0 auto;
  font-size: 12px;
  text-align: center;
  width: 80px;
  color: #000;
  border-radius: 25px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: LatoBold, sans-serif;
  background-color: ${p => p.theme.colors.green400};
  opacity: ${p => (p.disabled ? '0.5' : 'unset')};

  &:hover {
    ${props => props.hoverStyle && setInlineRules(props.hoverStyle)}
  }
`;
// TODO: Unify all button components in one Button, with different pre-build types
export const ActionButton = ({label, onClick, ...rest}: ButtonProps) => (
  <ActionButtonComponent className='action-button' onClick={() => !rest.disabled && onClick()} {...rest}>
    {label}
  </ActionButtonComponent>
);
