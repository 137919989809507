import styled from 'styled-components';
import {find} from 'lodash/fp';

import SelectField, {Item} from 'ui/SelectField';

import {CapacityAggregationType} from '../types';

type ItemType = Item<CapacityAggregationType>;

const SSelectField: typeof SelectField = styled(SelectField)`
  min-width: 150px;
  margin-right: 20px;
`;

const items: ItemType[] = [
  {
    label: 'Total',
    value: 'total',
  },
  {
    label: 'Available only',
    value: 'available',
  },
  {
    label: 'Earmark only',
    value: 'earmark',
  },
];

interface Props {
  onChange: (val: ItemType) => void;
  selectedType: CapacityAggregationType;
}

const CapacityTypeSelector = ({selectedType, onChange}: Props) => {
  const selectedItem = find({value: selectedType}, items);

  return <SSelectField data-testid='capacityTypeSelector' items={items} value={selectedItem} onChange={onChange} />;
};

export default CapacityTypeSelector;
