import {useMemo} from 'react';
import {matchRoutes, useLocation} from 'react-router-dom';
import {LayoutPath} from 'types/layout';

export const useCurrentPath = () => {
  const location = useLocation();
  const layoutPath = useMemo(() => {
    const routes = Object.values(LayoutPath).map(path => ({path}));
    const match = matchRoutes(routes, location);

    if (match) {
      const [{route}] = match;
      return route.path;
    }

    // Default case if no paths match
    return null;
  }, [location]);

  return layoutPath;
};

export default useCurrentPath;
