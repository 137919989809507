import {LegType, Trade, TradeRelatedLateSettlementFee, TradeStatus, UserWithMeta} from 'types/api';

import {convertCashFlowLegTypeToLegType, getUserLateFeeRequestsByStatusForLegType} from 'utils/TradeUtils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {useUser} from 'contexts/auth-context';

import MarkAsLateButton from './MarkAsLateButton';
import ReviewClaimButton from './ReviewClaimButton';

import {CashFlowLegType} from './types';

import {getLateFeeOperationsAndStatusesOrder} from './utils';

import {Cell} from './styles';
import ReviewAcceptedFeeButton from './ReviewAcceptedFeeButton';

export type RequestedLateFeesProps = {
  trade: Trade;
  legType: CashFlowLegType;
};

// Renders ReviewAcceptedBtn, if we have accepted Fee for this leg. Or MarkAsLate btn with request/withdraw functionality
const renderMarkAsLateOrReviewClaim = (trade: Trade, user: UserWithMeta, legType: LegType, disabled?: boolean) => {
  if (getUserLateFeeRequestsByStatusForLegType(trade, user, 'Accepted', 'requestor', legType).length > 0) {
    return <ReviewAcceptedFeeButton trade={trade} legType={legType} type='requestor' disabled={disabled} />;
  } else {
    return <MarkAsLateButton trade={trade} legType={legType} disabled={disabled} />;
  }
};
// Renders ReviewAcceptedBtn, if we have accepted Fee for this leg. Or ReviewClaimButton btn with accept/reject functionality
const renderReviewReceivedOrAcceptedClaim = (
  trade: Trade,
  user: UserWithMeta,
  legType: LegType,
  disabled?: boolean
) => {
  if (getUserLateFeeRequestsByStatusForLegType(trade, user, 'Accepted', 'recipient', legType).length > 0) {
    return <ReviewAcceptedFeeButton trade={trade} legType={legType} type='recipient' disabled={disabled} />;
  } else {
    return <ReviewClaimButton legType={legType} trade={trade} disabled={disabled} />;
  }
};

const formatLateFeeRequestDate = (date: string) => displayDate(date, DateFormat.DateTime);

const RequestedLateFeesRow = ({trade, legType}: RequestedLateFeesProps) => {
  const user = useUser();
  const convertedLegType = convertCashFlowLegTypeToLegType(legType);
  const receivedPendingLateFeeRequest: TradeRelatedLateSettlementFee | undefined =
    getUserLateFeeRequestsByStatusForLegType(trade, user, 'Pending', 'recipient', convertedLegType)[0];
  const requestedPendingLateFeeRequest: TradeRelatedLateSettlementFee | undefined =
    getUserLateFeeRequestsByStatusForLegType(trade, user, 'Pending', 'requestor', convertedLegType)[0];
  const isTradeActionsBlocked = trade.status === TradeStatus.Annulled;

  return (
    <>
      {getLateFeeOperationsAndStatusesOrder(trade, legType) === 'receiveThenSend' ?
        <>
          <Cell>
            {receivedPendingLateFeeRequest && formatLateFeeRequestDate(receivedPendingLateFeeRequest.createdAt)}
            {receivedPendingLateFeeRequest && requestedPendingLateFeeRequest && ' - '}
            {requestedPendingLateFeeRequest && formatLateFeeRequestDate(requestedPendingLateFeeRequest.createdAt)}
          </Cell>
          <Cell>{renderReviewReceivedOrAcceptedClaim(trade, user, convertedLegType, isTradeActionsBlocked)}</Cell>
          <Cell>
            <>{renderMarkAsLateOrReviewClaim(trade, user, convertedLegType, isTradeActionsBlocked)}</>
          </Cell>
        </>
      : <>
          <Cell>
            {requestedPendingLateFeeRequest && formatLateFeeRequestDate(requestedPendingLateFeeRequest.createdAt)}
            {receivedPendingLateFeeRequest && requestedPendingLateFeeRequest && ' - '}
            {receivedPendingLateFeeRequest && formatLateFeeRequestDate(receivedPendingLateFeeRequest.createdAt)}
          </Cell>
          <Cell>
            <>{renderMarkAsLateOrReviewClaim(trade, user, convertedLegType, isTradeActionsBlocked)}</>
          </Cell>
          <Cell>{renderReviewReceivedOrAcceptedClaim(trade, user, convertedLegType, isTradeActionsBlocked)}</Cell>
        </>
      }
    </>
  );
};

export default RequestedLateFeesRow;
