import {
  GridProps,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  PositionProps,
  space,
  layout,
  grid,
  flexbox,
  typography,
  TypographyProps,
  compose,
  position,
} from '@techstack/styled-system';
import styled from 'styled-components';
import {ifProp} from 'styled-tools';

type StyledSystemProps = SpaceProps & LayoutProps & GridProps & FlexboxProps & TypographyProps & PositionProps;
export type ContainerProps = StyledSystemProps & {fluid?: boolean};
export const SContainer = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-flow: column;

  ${ifProp('fluid', 'padding: 0; margin: 0;')};
  ${compose(space, layout, grid, position)}
`;

export const SCol = styled.div<StyledSystemProps>`
  flex-basis: 0 0 100%;
  max-width: 100%;

  ${compose(flexbox, space, layout, grid, typography, position)}
`;

interface SRowProps extends StyledSystemProps {
  className?: string;
}
export const SRow = styled.div.attrs(props => ({
  className: `row ${props.className ? props.className : ''}`.toString(),
}))<SRowProps>`
  display: flex;
  flex-direction: row;

  ${compose(flexbox, space, layout, typography, position)}
`;
