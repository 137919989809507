import {useQuery, useQueryClient} from '@tanstack/react-query';
import {Side} from 'types/api';

const QUERY_KEY = ['ClobOrderData'];

type SideBarData = {
  side: Side;
  baseAmount: number;
  interestRate?: number;
  forwardPoints?: number;
};
type ClobOrder = {
  sideBarData?: SideBarData;
};

export default function useClobOrderDataStore() {
  const {data} = useQuery<ClobOrder>({
    queryKey: QUERY_KEY,
  });
  const client = useQueryClient();
  const setSideBarData = (newSideBarData: SideBarData) => {
    return client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, sideBarData: newSideBarData}));
  };
  const removeSideBarData = () => {
    return client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, sideBarData: undefined}));
  };
  return {
    sideBarData: data?.sideBarData,
    setSideBarData,
    removeSideBarData,
  };
}
