import {Controller, ControllerProps} from 'react-hook-form';

import {ValidationResult} from 'utils/AmountUtils';
import {ReferenceData} from 'types/layout';

import useFormDisplayRate from 'utils/hooks/useDisplayRate';

import {Unit} from 'constants/commonEnums';

import ImplYldMessage, {ImplYldMessageProps} from 'ui/Message/ImplYldMessage';
import AmountField from 'ui/AmountField';

import ExpectedInterestMessage, {ExpectedInterestMessageProps} from 'components/ExpectedInterestMessage';

import {AdditionalFieldProps} from './ForwardPointsField';

interface SIRFieldProps {
  isRepo: boolean;
  disabled: boolean;
  value: number | undefined;
  onChange: (newValue: number) => void;
  implYldMessage?: ImplYldMessageProps;
  validationResult: ValidationResult;
  warning?: boolean;
  highlightEmpty?: boolean;
  required?: boolean;
  error?: string;
  helperProps?: ExpectedInterestMessageProps;
}

export const FormSuggestedInterestRateField = ({
  control,
  flash,
  setIsImpliedYieldFieldTouched,
  helperProps,
  ...props
}: Omit<ControllerProps, 'name' | 'render'> &
  Omit<SIRFieldProps, 'value' | 'validationResult' | 'isRepo' | 'onChange'> &
  AdditionalFieldProps) => {
  const parseRate = useFormDisplayRate();
  return (
    <Controller
      render={({field: {onChange, value: rate}, fieldState: {error}}) => (
        <>
          <AmountField
            optional
            data-testid={props['data-testid']}
            flash={flash}
            disabled={props.disabled}
            unit={Unit.BP}
            value={parseRate(rate)}
            onChange={newValue => {
              onChange({
                value: newValue,
                referenceData: ReferenceData.ImpliedYield,
              });
              if (setIsImpliedYieldFieldTouched) setIsImpliedYieldFieldTouched(true);
            }}
            invalid={!!error}
            tooltipPosition='left'
            tooltip={error?.message}
            warning={props.warning}
            required={props.required}
            highlightEmpty={props.highlightEmpty}
            forceValidated
          />
          {props.implYldMessage && <ImplYldMessage {...props.implYldMessage} />}
          {helperProps && <ExpectedInterestMessage {...helperProps} />}
        </>
      )}
      name='rate'
      control={control}
    />
  );
};
