/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {useEffect, useMemo} from 'react';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {AssetPair, Currency, Side, SpotRate, TransactionDate} from 'types/api';

import dayjs, {Dayjs} from 'dayjs';
import {useForm} from 'react-hook-form';

import {currencyPairToAssetPair, isAssetPairEqual, isFxPair, isRepoPair} from 'utils/AssetUtils';
import {admissibleForwardPointRange, currencySymbol} from 'utils/AmountUtils';

import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';

import {getMinimumTradeDurationInMinutes} from 'utils/TradeUtils';

import {isValidDecimal} from 'utils/utils';
import {useIntl} from 'react-intl';
import {ReferenceData} from 'types/layout';
import {Rate} from 'types/rfq';

import {orderFormLimits} from 'constants/orderForm';
import {formErrorTooltips} from 'constants/messages/labels';

import {REPO_HAIRCUT} from 'containers/OrderForm/types';

import {ExchangeRate} from '../types';

export const FORM_DEFAULT_VALUES = {
  side: 'SellBuy' as const,
  bigfig: 1,
  nearlegTransactionDate: TransactionDate.TPlusZero,
  farlegTransactionDate: TransactionDate.TPlusZero,
  nearleg: 'ASAP' as const,
  validFor: 4,
  counterparty1: '',
  counterparty2: '',
  counterparty3: '',
};

export default function useRFQForm() {
  const {formatMessage} = useIntl();
  const BIGFIG_ERROR = formatMessage(formErrorTooltips.bigFigError);
  const VALIDFOR_ERROR = formatMessage(formErrorTooltips.validForError);
  const IMP_YIELD_ERROR = formatMessage(formErrorTooltips.impYieldError);
  const TRADED_AMOUNT_GENERIC_ERROR = formatMessage(formErrorTooltips.amountGenericError);

  const schema = useMemo(
    () =>
      yup.object({
        tradedAmountMin: yup.number(),
        tradedAmountMax: yup.number(),
        side: yup.mixed<Side>().required(),
        currentPair: yup.mixed<AssetPair>().required('Market selection is obligatory.'),
        bigfig: yup
          .number()
          .typeError(BIGFIG_ERROR)
          .min(orderFormLimits.spotRangeBigFig.min, BIGFIG_ERROR)
          .max(orderFormLimits.spotRangeBigFig.max, BIGFIG_ERROR)
          .test('is-required', BIGFIG_ERROR, (value, ctx) => {
            const isFXSwap: boolean = isFxPair(ctx.parent.currentPair, false);
            return !isFXSwap || !!value;
          })
          .test(
            'is-decimal',
            `Please enter up to ${orderFormLimits.spotRangeBigFig.dec} digit after the decimal point`,
            value => {
              return (
                !value ||
                value % 1 === 0 ||
                new RegExp(`^\\d*\\.?\\d{0,${orderFormLimits.spotRangeBigFig.dec}}$`).test(`${value}`)
              );
            }
          ),
        tradedAmount: yup
          .number()
          .typeError(TRADED_AMOUNT_GENERIC_ERROR)
          .required(TRADED_AMOUNT_GENERIC_ERROR)
          .test('is-decimal', `Please enter up to ${orderFormLimits.amount.dec} digit after the decimal point`, value =>
            isValidDecimal(value, orderFormLimits.amount.dec)
          )
          .test('min-max', '', function (value) {
            const {tradedAmountMin, tradedAmountMax, tradedAmountCurrency} = this.parent;

            const isMillions = tradedAmountMin >= 1_000_000 && tradedAmountMax >= 1_000_000;

            const adjustedMinLimit = isMillions ? tradedAmountMin / 1_000_000 : tradedAmountMin;
            const adjustedMaxLimit = isMillions ? tradedAmountMax / 1_000_000 : tradedAmountMax;

            if (value < adjustedMinLimit || value >= adjustedMaxLimit) {
              const unit = isMillions ? 'm' : '';
              const currencySign = currencySymbol(tradedAmountCurrency);
              return this.createError({
                message: `Please enter a number between ${currencySign}${adjustedMinLimit}${unit} and ${currencySign}${
                  adjustedMaxLimit - 1
                }${unit}`,
              });
            }

            return true;
          }),
        counterparty1: yup.string().test('is-selected', 'Select at least one counterparty', (value, ctx) => {
          if (!!value) return true;
          if (!!ctx.parent.counterparty2 || !!ctx.parent.counterparty3) return true;
          return false;
        }),
        nearlegTransactionDate: yup.mixed<TransactionDate>().oneOf(Object.values(TransactionDate)).required(),
        farlegTransactionDate: yup.mixed<TransactionDate>().oneOf(Object.values(TransactionDate)).required(),
        nearleg: yup
          .mixed<Dayjs | 'ASAP'>()
          .typeError('Incorrect datetime format, please correct field')
          .required('Please enter near leg')
          .test('is-future', 'Near leg should be a future datetime', value => {
            if (value === 'ASAP') return true;
            if (value.isAfter(dayjs())) return true;
            return false;
          }),
        farleg: yup
          .mixed<Dayjs>()
          .required('Please enter far leg')
          .test('is-future', 'Far leg should be a future datetime', value => {
            if (value.isAfter(dayjs())) return true;
            return false;
          })
          .test(
            'is-after-nearleg',
            `Minimum trade duration is expected to be ${getMinimumTradeDurationInMinutes()} minutes.`,
            (value, ctx) => {
              const nearlegDatetime = ctx.parent.nearleg === 'ASAP' ? dayjs() : ctx.parent.nearleg;
              if (value.diff(nearlegDatetime as Dayjs, 'minutes') < getMinimumTradeDurationInMinutes()) return false;
              return true;
            }
          )
          .test('transaction-date', 'Far leg should be same transaction date as near leg', (_, ctx) => {
            const nearlegTD = ctx.parent.nearlegTransactionDate;
            const farlegTD = ctx.parent.farlegTransactionDate;
            return nearlegTD && farlegTD && nearlegTD === farlegTD;
          }),
        rate: yup.mixed<Rate>().test('is-valid', (rate, ctx) => {
          if (!rate) return true;

          if (rate.referenceData === ReferenceData.ImpliedYield) {
            if (typeof rate.value !== 'number') return new yup.ValidationError(IMP_YIELD_ERROR, undefined, 'rate');
            if (rate.value < orderFormLimits.rate.min || rate.value > orderFormLimits.rate.max)
              return new yup.ValidationError(IMP_YIELD_ERROR, undefined, 'rate');
            if (!isValidDecimal(rate.value, orderFormLimits.rate.dec))
              return new yup.ValidationError(
                `Please enter up to ${orderFormLimits.rate.dec} digit after the decimal point`,
                undefined,
                'rate'
              );

            return true;
          }

          if (rate.referenceData === ReferenceData.ForwardPoints) {
            const nearleg = ctx.parent.nearleg;
            const farleg = ctx.parent.farleg;

            if (typeof rate.value !== 'number')
              return new yup.ValidationError('Suggested Fwd Pts has to be number', undefined, 'rate');
            if (!nearleg || !farleg) return true;
            if (!ctx.parent.exchangeRate)
              return new yup.ValidationError(
                `We cannot fetch exchange rate, please refresh page and try again`,
                undefined,
                'rate'
              );

            const validationRules = admissibleForwardPointRange(
              orderFormLimits.rate.min,
              orderFormLimits.rate.max,
              typeof nearleg === 'string' ? dayjs() : nearleg || dayjs(),
              farleg,
              ctx.parent.exchangeRate.value
            );

            if (!isValidDecimal(rate.value, validationRules.dec))
              return new yup.ValidationError(
                `Please enter up to ${validationRules.dec} digit after the decimal point`,
                undefined,
                'rate'
              );
            if (rate.value < validationRules.min || rate.value > validationRules.max)
              return new yup.ValidationError(
                `Please enter a number between ${validationRules.min}pts and ${validationRules.max}pts`,
                undefined,
                'rate'
              );

            return true;
          }
        }),
        validFor: yup
          .number()
          .typeError(VALIDFOR_ERROR)
          .required(VALIDFOR_ERROR)
          .test('max', VALIDFOR_ERROR, value => {
            if (!value || value < 1 || value % 1 !== 0 || value > 30) return false;
            return true;
          }),
        tradedAmountCurrency: yup.mixed<Currency>().required(),
        counterparty2: yup.string(),
        counterparty3: yup.string(),
        exchangeRate: yup.mixed<Omit<SpotRate, 'currencyPair'>>(),
      }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...FORM_DEFAULT_VALUES,
    },
    mode: 'onChange',
  });

  const [pair] = methods.watch(['currentPair']);

  const {exchangeRates} = useRealTimeExchangeRatesQuery();

  const isRepo: boolean = isRepoPair(pair, false);

  const repoExchangeRate: () => ExchangeRate = () => ({
    value: 1.0 / (1.0 - REPO_HAIRCUT),
    timestamp: dayjs().valueOf(),
  });

  useEffect(() => {
    methods.setValue(
      'exchangeRate',
      isRepo ? repoExchangeRate() : (
        exchangeRates.find(er => pair && isAssetPairEqual(currencyPairToAssetPair(er.pair), pair))?.rate
      )
    );
  }, [JSON.stringify(pair), isRepo, JSON.stringify(exchangeRates)]);

  return {methods, schema};
}

export type RFQFormValues = yup.InferType<ReturnType<typeof useRFQForm>['schema']>;
