export enum Role {
  Observer = 'Observer',
  Trader = 'Trader',
  CapacityManager = 'CapacityManager',
  RiskManager = 'RiskManager',
  SuperUser = 'SuperUser',
}

export enum Scope {
  ViewClob = 'view-clob',
  ViewRfq = 'view-rfq',
  ViewAnalytics = 'view-analytics',
  ViewCapacity = 'view-capacity',
  ViewNotifications = 'view-notifications',
  ViewBlotter = 'view-blotter',
  ViewCapacityChart = 'view-capacity-chart',
  ViewOrderAndRfqForms = 'view-order-and-rfq-forms',
  ViewCreditRiskAnalytics = 'view-credit-risk-analytics',
  CanTrade = 'can-trade',
  CanUseRfqActions = 'can_use-rfq-actions',
  ManageEarlyMaturationRequests = 'manage-early-maturation-requests',
  ManageLateFees = 'manage-late-fees',
  AdjustCapacity = 'adjust-capacity',
  AdjustCreditLimits = 'adjust-credit-limits',
  CanCreateEditWithdrawFlagAs = 'can-create-edit-withdraw-flag-as',
}

export type Permission = {[key in Role]: Scope[]};
const VIEW_ALL = [
  Scope.ViewRfq,
  Scope.ViewClob,
  Scope.ViewAnalytics,
  Scope.ViewCapacity,
  Scope.ViewNotifications,
  Scope.ViewBlotter,
  Scope.ViewCreditRiskAnalytics,
  Scope.ViewCapacityChart,
];

export const PERMISSIONS: Permission = {
  [Role.Observer]: [...VIEW_ALL],
  [Role.Trader]: [
    ...VIEW_ALL,
    Scope.ViewOrderAndRfqForms,
    Scope.CanTrade,
    Scope.ManageEarlyMaturationRequests,
    Scope.ManageLateFees,
    Scope.CanUseRfqActions,
  ],
  [Role.CapacityManager]: [
    ...VIEW_ALL,
    Scope.AdjustCapacity,
    // as per FX-2810, Capacity Managers should be able to manage late fees
    Scope.ManageLateFees,
    // as per FX-3048, Capacity Managers should be able to create, edit and withdraw flags
    Scope.CanCreateEditWithdrawFlagAs,
  ],
  [Role.RiskManager]: [...VIEW_ALL, Scope.AdjustCreditLimits],
  [Role.SuperUser]: [
    ...VIEW_ALL,
    Scope.ViewOrderAndRfqForms,
    Scope.CanTrade,
    Scope.ManageEarlyMaturationRequests,
    Scope.ManageLateFees,
    Scope.CanUseRfqActions,
    Scope.AdjustCapacity,
    Scope.AdjustCreditLimits,
    Scope.CanCreateEditWithdrawFlagAs,
  ],
};
