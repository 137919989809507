import styled from 'styled-components';

export const FooterLabel = styled.p`
  text-align: center;
  color: ${p => p.theme.colors.blue50};
  margin: 1em 10px 1em;
`;

export const SButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
