import {useEffect, useMemo} from 'react';
import dayjs from 'dayjs';

import {CurrencyPair, SpotRate} from 'types/api';

import {QueryClient, useQuery, useQueryClient} from '@tanstack/react-query';

import {ExchangeRate, ExchangeRateState} from 'types/exchangeRate';

import {createExchangeRatesEventSource} from '../api';
import useAvailableAssetsQuery from './useAvailableAssetsQuery';

let eventSource: EventSource | undefined;
let initiated = false;
let clearDataTimeout: ReturnType<typeof setTimeout>;
const QUERY_KEY = ['RealTimeExchangeRates'];
// TODO: Change to 5 once BE is fixed
const STALE_TIME = 15;

function isExchangeRateFresh(timestamp: number) {
  return dayjs(timestamp).isBefore(dayjs().subtract(STALE_TIME, 'minutes'));
}

function subscribeExchangeRates(client: QueryClient, pairs: CurrencyPair[]) {
  eventSource = createExchangeRatesEventSource(
    {
      lastEventId: undefined,
      onmessage: ({data}: {data: SpotRate}) => {
        client.setQueryData(QUERY_KEY, (state: ExchangeRateState | undefined) => {
          const filteredTable = (state || []).reduce<ExchangeRate[]>((acc, curr) => {
            if (curr.pair.base === data.currencyPair.base && curr.pair.second === data.currencyPair.second) return acc;
            if (isExchangeRateFresh(curr.rate.timestamp)) return acc;
            return [...acc, curr];
          }, []);

          return [...filteredTable, {pair: data.currencyPair, rate: data}];
        });
      },
      onopen: () => {
        clearTimeout(clearDataTimeout);
      },
      onerror: () => {
        clearDataTimeout = setTimeout(() => {
          client.setQueryData(QUERY_KEY, () => []);
        }, 5 * 1000);
      },
    },
    pairs
  );
}

export const useRealTimeExchangeRatesQuery = () => {
  const {data: availableAssetsData} = useAvailableAssetsQuery();
  const currencyPairs = useMemo(() => {
    const availableCurrencies = availableAssetsData
      ?.map(({asset}) => asset)
      .filter(asset => asset.type === 'Cash' && !asset.holder)
      .map(asset => asset.currency);

    // generate all unique currency pairs
    return (
      availableCurrencies?.reduce<CurrencyPair[]>((acc, baseCurrency, requestIndex) => {
        const secondCurrencies = availableCurrencies.filter((_, secondIndex) => secondIndex !== requestIndex);
        const requestPairs = secondCurrencies.map(secondCurrency => ({base: baseCurrency, second: secondCurrency}));
        return [...acc, ...requestPairs];
      }, []) || []
    );
  }, [availableAssetsData]);
  const {data} = useQuery<ExchangeRateState>({
    queryKey: QUERY_KEY,
  });
  const client = useQueryClient();

  useEffect(() => {
    let checkDataFreshInterval: ReturnType<typeof setInterval>;
    if (!initiated && Array.isArray(currencyPairs) && currencyPairs.length > 0) {
      subscribeExchangeRates(client, currencyPairs);
      initiated = true;

      checkDataFreshInterval = setInterval(() => {
        client.setQueryData(QUERY_KEY, (state: ExchangeRateState | undefined) => [
          ...(state || []).filter(v => !isExchangeRateFresh(v.rate.timestamp)),
        ]);
      }, 5000);
    }
    return () => {
      clearInterval(checkDataFreshInterval);
    };
  }, [client, JSON.stringify(currencyPairs)]);

  return {
    exchangeRates: data || [],
  };
};
