import styled from 'styled-components';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
`;

export const InfoLine = styled.div`
  width: 100%;
  padding: 0.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &:nth-child(odd) {
    background-color: ${({theme}) => theme.colors.blue950};
  }
`;
