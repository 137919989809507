import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {color} from 'utils/theme';

interface ClobPricingProps {
  warnAboutPartialCovering: boolean;
}
export const ClobPricing = styled.span<ClobPricingProps>`
  color: ${ifProp('warnAboutPartialCovering', color('yellow400'), color('blue50'))};
`;
