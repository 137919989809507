import dayjs from 'dayjs';
import {filter, map, reduce, flow, sortBy, last, isEqual, reduceRight, head, tail} from 'lodash/fp';

import {Asset, CapacityProjectionPoint} from 'types/api';
import {convertQuantity, To} from 'utils/AmountUtils';

import {isAssetEqual} from 'utils/AssetUtils';

import {useRealTimeCapacityQuery} from 'api/hooks/useRealTimeCapacityQuery';
import {useRealTimeCapacityProjectionsQuery} from 'api/hooks/useRealTimeCapacityProjectionsQuery';

import {CapacityAggregationType, CapacityChange} from '../types';
import useCapacityChangesFromOrders from './useCapacityChangesFromOrders';
import useCapacityChangesFromQuotes from './useCapacityChangesFromQuotes';

export interface DataPoint {
  t: number;
  v: number;
}

interface ProjectionChangesAggregate {
  changes: CapacityChange[];
  prev: CapacityProjectionPoint | null;
}

interface Options<T = Asset | undefined> {
  asset: T;
  currentDate: string;
  capacityType?: CapacityAggregationType;
}

// TODO: useMemo()
const useAggregatedCapacity = <T extends Asset | undefined, R = T extends Asset ? DataPoint[] : undefined>({
  asset,
  currentDate,
  capacityType = 'available',
}: Options<T>): R => {
  const {balances} = useRealTimeCapacityQuery({withEquivalent: false});
  const capacityProjections = useRealTimeCapacityProjectionsQuery();
  const orderCapacityChanges = useCapacityChangesFromOrders({currentDate});
  const quoteCapacityChanges = useCapacityChangesFromQuotes({currentDate});

  // Hooks can't be called conditionally so we have to exit from hook instead
  if (!asset) {
    // @ts-expect-error: it's only unsafe if the caller overrides generics,
    // but otherwise allows inference that hook will not return undefined if asset is provided
    return undefined;
  }

  const onlyMatchingAssets = filter((change: CapacityChange) => isEqual(change.asset, asset));
  const onlyMatchingCapacityTypes = filter((change: CapacityChange) =>
    capacityType === 'available' ? change.type !== 'earmark' : change.type === capacityType
  );
  const withoutPastChanges = filter((change: {at: number}) => change.at >= currentUnixTimeMilSeconds);

  const currentBalance = balances.find(balance => isAssetEqual(balance.asset, asset));
  const currentUnixTimeMilSeconds = dayjs(currentDate).valueOf();
  const currentCapacity = {
    at: currentUnixTimeMilSeconds,
    value: currentBalance?.[capacityType].amount.quantity ?? 0,
  };
  const assetCapacityProjection = {
    ...(capacityProjections?.projections.find(p => isAssetEqual(p.asset, asset)) ?? {
      asset,
      points: [],
    }),
  };

  assetCapacityProjection.points = withoutPastChanges(
    sortBy<CapacityProjectionPoint>('at')([{...currentCapacity}, ...assetCapacityProjection.points])
  ) as CapacityProjectionPoint[];

  // TODO: Remove conversion to changes and apply capacity projection as a baseline for the chart
  const [, ...projectionChanges]: CapacityChange[] =
    assetCapacityProjection?.points.reduce<ProjectionChangesAggregate>(
      (agg, p) => {
        const prevPointValue = agg.prev?.value ?? 0;
        const by = p.value - prevPointValue;

        return {
          changes: [...agg.changes, {asset, at: p.at, by, type: 'total'}],
          prev: p,
        };
      },
      {changes: [], prev: null}
    ).changes ?? [];

  const capacityChanges = [...projectionChanges, ...orderCapacityChanges, ...quoteCapacityChanges];

  const sortByDateTime = sortBy<CapacityChange>('at');

  const dedupe = reduceRight((item: CapacityChange, acc: CapacityChange[]): CapacityChange[] => {
    const h: CapacityChange | undefined = head(acc);

    return h?.at === item.at ?
        [
          {
            at: item.at,
            by: h.by + item.by,
            type: item.type,
          },
          ...tail(acc),
        ]
      : [item, ...acc];
  }, []);

  const toDataPoint = map((change: CapacityChange) => ({t: change.at, v: change.by}));

  const startingCapacityDataPoint = [head(assetCapacityProjection.points)!].map(({at, value}) => ({t: at, v: value}));

  const aggregate = reduce(
    (acc: DataPoint[], item: DataPoint) => [
      ...acc,
      {
        t: item.t,
        v: last(acc)!.v + item.v,
      },
    ],
    startingCapacityDataPoint
  );

  const fixed36HoursIntoTheFuture = dayjs(currentDate).add(36, 'hours').startOf('hour').valueOf();

  const pad = (points: DataPoint[]) =>
    [
      ...points,
      last(points)!.t < fixed36HoursIntoTheFuture && {
        t: fixed36HoursIntoTheFuture,
        v: last(points)!.v,
      },
    ].filter(Boolean) as DataPoint[];

  // TODO: Do this in the same step as date conversion
  const toMil = map((dataPoint: DataPoint) => ({
    ...dataPoint,
    v: convertQuantity(dataPoint.v, To.View),
  }));

  return flow(
    onlyMatchingAssets,
    onlyMatchingCapacityTypes,
    withoutPastChanges,
    sortByDateTime,
    dedupe,
    toDataPoint,
    aggregate,
    pad,
    toMil
  )(capacityChanges);
};

export default useAggregatedCapacity;
