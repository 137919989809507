import {Link} from 'react-router-dom';

import {RateToShow, ReferenceData} from 'types/layout';
import {BestRate, LastTraded, MarketSnapshot} from 'types/api';

import {displayAmountWithCode, displayAmountWithUnit} from 'utils/AmountUtils';
import {displayForwardPoints, displayInterestRate} from 'utils/utils';
import {displayAssetPair, isRepoPair} from 'utils/AssetUtils';

import useBestRateStore from 'stores/useBestRateStore';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {CHART_VIEW} from 'constants/paths';
import Button from 'ui/Button';

import {
  SCurrencyTile,
  SCurrencyTileCol,
  SCurrencyTileContent,
  SCurrencyTileHeader,
  SCurrencyTileLabel,
  SCurrencyTileLabelTop,
  SCurrencyTileLastTraded,
  SCurrencyTilePair,
  SCurrencyTileRow,
  SCurrencyTileValue,
  tileButtonStyles,
} from './styles';

type ClobOverviewTileProps = {
  marketSnapshot: MarketSnapshot;
};

export const ClobOverviewTile = ({marketSnapshot}: ClobOverviewTileProps) => {
  const {referenceData} = useReferenceDataStore();
  const {rateToShow} = useBestRateStore();
  const isBestRateShown = rateToShow === RateToShow.BestRate;
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const {assetPair, bestBuySellRate, bestSellBuyRate, totalBuySellDepth, totalSellBuyDepth, lastTraded} =
    marketSnapshot;
  const isRepo = isRepoPair(assetPair);
  const inMillions = shouldDisplayInMillions(assetPair);

  return (
    <SCurrencyTile data-testid='currency-tile'>
      <SCurrencyTileHeader data-testid='currency-tile-header'>
        <SCurrencyTilePair data-testid='currency-tile-title'>{displayAssetPair(assetPair)}</SCurrencyTilePair>
        <SCurrencyTileLastTraded>
          LAST TRADED: {displayLastTraded(lastTraded, referenceData, inMillions)}
        </SCurrencyTileLastTraded>
      </SCurrencyTileHeader>
      <SCurrencyTileContent>
        <SCurrencyTileRow>
          <SCurrencyTileLabelTop cols={2} /> {/* Empty */}
          <SCurrencyTileLabelTop cols={4}>Depth</SCurrencyTileLabelTop>
          <SCurrencyTileLabelTop cols={3}>{isBestRateShown ? 'Best:Rate' : 'Rate'}</SCurrencyTileLabelTop>
          <SCurrencyTileLabelTop cols={3}>{isBestRateShown ? 'Best:Amt' : 'Amt'}</SCurrencyTileLabelTop>
        </SCurrencyTileRow>

        <SCurrencyTileRow>
          <SCurrencyTileLabel cols={2}>{isRepo ? 'Buy' : 'S/B'}</SCurrencyTileLabel>

          <SCurrencyTileValue cols={4}>
            {totalSellBuyDepth ?
              displayAmountWithCode(
                {
                  quantity: totalSellBuyDepth,
                  asset: assetPair.base,
                },
                false,
                inMillions
              )
            : '--m'}
          </SCurrencyTileValue>

          <SCurrencyTileValue cols={3}>{displayBestRate(bestSellBuyRate, referenceData, isRepo)}</SCurrencyTileValue>

          <SCurrencyTileValue cols={3}>
            {bestSellBuyRate ?
              displayAmountWithUnit(
                {
                  quantity: bestSellBuyRate.depth,
                  asset: assetPair.base,
                },
                inMillions
              )
            : '--m'}
          </SCurrencyTileValue>
        </SCurrencyTileRow>

        <SCurrencyTileRow>
          <SCurrencyTileLabel cols={2}>{isRepo ? 'Sell' : 'B/S'}</SCurrencyTileLabel>

          <SCurrencyTileValue cols={4}>
            {totalBuySellDepth ?
              displayAmountWithCode(
                {
                  quantity: totalBuySellDepth,
                  asset: assetPair.base,
                },
                undefined,
                inMillions
              )
            : '--m'}
          </SCurrencyTileValue>

          <SCurrencyTileValue cols={3}>{displayBestRate(bestBuySellRate, referenceData, isRepo)}</SCurrencyTileValue>

          <SCurrencyTileValue cols={3}>
            {bestBuySellRate ?
              displayAmountWithUnit(
                {
                  quantity: bestBuySellRate.depth,
                  asset: assetPair.base,
                },
                inMillions
              )
            : '--m'}
          </SCurrencyTileValue>
        </SCurrencyTileRow>

        {!isRepo ?
          <SCurrencyTileRow>
            <SCurrencyTileLabel cols={2}>Spot</SCurrencyTileLabel>
            <SCurrencyTileValue cols={4}>{marketSnapshot.spotRate.value}</SCurrencyTileValue>
            <SCurrencyTileCol cols={6}>
              <Link to={CHART_VIEW.buildAbsolute({assetPair})}>
                <Button
                  type='button'
                  data-testid='review-currency-button'
                  buttonStyle='primary'
                  style={tileButtonStyles}
                >
                  Review
                </Button>
              </Link>
            </SCurrencyTileCol>
          </SCurrencyTileRow>
        : <SCurrencyTileRow>
            <SCurrencyTileCol cols={12}>
              <Link to={CHART_VIEW.buildAbsolute({assetPair})}>
                <Button
                  type='button'
                  data-testid='review-currency-button'
                  buttonStyle='primary'
                  style={tileButtonStyles}
                >
                  Review
                </Button>
              </Link>
            </SCurrencyTileCol>
          </SCurrencyTileRow>
        }
      </SCurrencyTileContent>
    </SCurrencyTile>
  );
};

function displayLastTraded(lastTraded: LastTraded | undefined, referenceData: ReferenceData, inMillions: boolean) {
  if (!lastTraded) {
    return '--';
  }

  const amount = displayAmountWithUnit(lastTraded.amount, inMillions);
  const interest =
    referenceData === ReferenceData.ImpliedYield ?
      displayInterestRate(lastTraded.impliedYield)
    : displayForwardPoints(lastTraded.forwardPoints);
  return [amount, interest].join(' ');
}

function displayBestRate(rate: BestRate | undefined, referenceData: ReferenceData, isRepo: boolean) {
  if (!rate) {
    return '--';
  }

  return isRepo || referenceData === ReferenceData.ImpliedYield ?
      displayInterestRate(rate.interestRate)
    : displayForwardPoints(rate.forwardPoints);
}
