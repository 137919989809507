import {useModal} from 'contexts/modal-context';
import dayjs from 'dayjs';
import {useIntl} from 'react-intl';
import {Trade, TradeStatus} from 'types/api';

import {hasPendingReceivedTradeAnnulProposal, hasPendingSentTradeAnnulProposal} from 'utils/TradeUtils';

import {fieldTitles} from 'constants/messages/labels';
import Button from 'ui/Button';

import {ProposeTradeAnnul} from '../ProposeTradeAnnulPopup';
import {ReviewTradeAnnulProposalPopup} from '../ReviewTradeAnnulProposalPopup';
import {WithdrawTradeAnnulProposalPopup} from '../WithdrawTradeAnnulProposalPopup';

interface Props {
  trade: Trade;
}

export default function TradeAnnulButton({trade}: Props) {
  const {formatMessage} = useIntl();
  const {openModal} = useModal();

  const showTradeAnnulBtn =
    dayjs().isBefore(dayjs(trade.nearLeg.scheduledTime).subtract(30, 'minute')) &&
    trade.status === TradeStatus.Upcoming &&
    trade.tradeAnnul?.status !== 'Proposed';

  const isTradeAnnulExpired = trade.tradeAnnul?.status === 'Expired';

  const onProposeTradeAnnulClick = (): void => {
    if (!trade) return;

    openModal({modal: ProposeTradeAnnul, props: {tradeId: trade.id}});
  };

  const onReviewTradeAnnulProposalClick = (): void => {
    if (!trade) return;

    openModal({modal: ReviewTradeAnnulProposalPopup, props: {tradeId: trade.id}});
  };

  const onWithdrawTradeAnnulProposalClick = (): void => {
    if (!trade) return;

    openModal({modal: WithdrawTradeAnnulProposalPopup, props: {tradeId: trade.id}});
  };

  if (hasPendingSentTradeAnnulProposal(trade))
    return (
      <Button
        data-testid='trade-annul-proposed-button'
        key={fieldTitles.sentTradeAnnulButton.id}
        buttonStyle='warning'
        onClick={onWithdrawTradeAnnulProposalClick}
      >
        {formatMessage(fieldTitles.sentTradeAnnulButton)}
      </Button>
    );

  if (hasPendingReceivedTradeAnnulProposal(trade))
    return (
      <Button
        data-testid='trade-annul-received-button'
        key={fieldTitles.receivedTradeAnnulButton.id}
        buttonStyle='warning'
        onClick={onReviewTradeAnnulProposalClick}
      >
        {formatMessage(fieldTitles.receivedTradeAnnulButton)}
      </Button>
    );

  if (showTradeAnnulBtn)
    return (
      <Button
        data-testid='propose-trade-annul-button'
        key={fieldTitles.tradeAnnulProposalButton.id}
        buttonStyle='secondary'
        onClick={onProposeTradeAnnulClick}
      >
        {formatMessage(fieldTitles.tradeAnnulProposalButton)}
      </Button>
    );

  if (isTradeAnnulExpired)
    return (
      <Button key={fieldTitles.expiredTradeAnnulButton.id} buttonStyle='grey' disabled>
        {formatMessage(fieldTitles.expiredTradeAnnulButton)}
      </Button>
    );

  return null;
}
