import {useUser} from 'contexts/auth-context';

import {getUserLateFeeRequestsByStatusForLegType} from 'utils/TradeUtils';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import Button from 'ui/Button';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {ReviewAcceptedLateFeePopup} from 'components/popups/LateSettlementFeePopup/Popups/ReviewAcceptedLateFeePopup';

import {ReviewAcceptedFeeButtonProps} from './types';

export const buttonInlineStyle = {padding: '0.2rem 1rem'};

const ReviewAcceptedFeeButton = ({trade, legType, type, disabled}: ReviewAcceptedFeeButtonProps) => {
  const user = useUser();
  const permissions = getRolePermissions(user.user?.role);
  const {openModal} = useModal();

  const acceptedLateFeeRequest = getUserLateFeeRequestsByStatusForLegType(trade, user, 'Accepted', type, legType)[0];
  const isButtonDisabled =
    !hasPermission({permissions, scopes: [Scope.ManageLateFees]}) || !acceptedLateFeeRequest || disabled;

  const onClick = () => {
    openModal({
      modal: ReviewAcceptedLateFeePopup,
      props: {tradeId: trade.id, lateFee: acceptedLateFeeRequest, type, legType},
    });
  };

  return (
    <Button
      onClick={onClick}
      style={buttonInlineStyle}
      buttonStyle={isButtonDisabled ? 'grey' : 'warning'}
      disabled={isButtonDisabled}
      data-testid={`review-accepted-fee-btn-${legType.toLowerCase()}`}
    >
      Fee Accepted
    </Button>
  );
};

export default ReviewAcceptedFeeButton;
