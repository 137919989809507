import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {Dayjs} from 'dayjs';

import {RiskExposureRow} from 'types/analytics';
import {Amount, Party} from 'types/api';

import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayDate, DateFormat} from 'utils/DayjsUtils';

type DateType = {
  date: Dayjs;
};
export const getDateColumn = (): ColumnDef<DateType> => ({
  id: 'date',
  accessorKey: 'date',
  header: '',
  cell: info => (
    <span>
      {displayDate(info.getValue() as Dayjs, DateFormat.Standard)}{' '}
      {displayDate(info.getValue() as Dayjs, DateFormat.Time)}
    </span>
  ),
  meta: {
    align: 'left',
    alignItems: 'start',
  },
});

const mtmColumnHelper = createColumnHelper<RiskExposureRow>();
export const getRiskExposureColumn = (counterParties: Party[]): ColumnDef<RiskExposureRow> => {
  const columnsArray: ColumnDef<RiskExposureRow, Amount>[] = [];

  counterParties.forEach(({legalName, shortName}) => {
    columnsArray.push(
      mtmColumnHelper.accessor(`values.${legalName}`, {
        id: shortName ?? legalName,
        header: shortName ?? legalName,
        cell: info => (info.getValue() ? displayAmountWithCode(info.getValue(), false, true) : <></>),
        meta: {
          align: 'right',
          alignItems: 'end',
        },
      })
    );
  });

  return {
    header: 'Risk Exposure (USDe)',
    columns: columnsArray,
  };
};
