import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {LegType} from 'types/api';

import TableRow from 'utils/TableUtils';

import {useIntl} from 'react-intl';

import dayjs from 'dayjs';

import {useMemo} from 'react';

import {getTradeSettlementTimeWithDeadLine} from 'utils/TradeUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {displayDate, displayDuration} from 'utils/DayjsUtils';

import {displaySmallAmount} from 'utils/AmountUtils';

import {fieldTitles} from 'constants/messages/labels';
import Popup from 'ui/Popup';

import {StyledTable} from 'containers/RFQPopup/styles';

import '../LateSettlementFeePopup.scss';
import TradePopup from 'components/popups/TradePopup';

import {ReviewAcceptedLateFeePopupProps} from '../types';
import {YouReceivedOrYouPaidLateValue, getLateSettlementAmount} from '../utils';

import {DetailsTabType} from 'types/layout';

export const ReviewAcceptedLateFeePopup = ({tradeId, lateFee, type, legType}: ReviewAcceptedLateFeePopupProps) => {
  const {formatMessage} = useIntl();
  const {selectTradeById} = useRealTimeTradesQuery();
  const {openModal} = useModal();
  const isReceived = type === 'recipient';
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const trade = selectTradeById(tradeId);

  if (!trade) return null;
  const inMillions = shouldDisplayInMillions({base: trade.baseAmount.asset, second: trade.secondAmount.asset});
  const isRepoPaidAmount = (paidOrReceived: 'paid' | 'received'): boolean =>
    getLateSettlementAmount({trade, legType, paidOrReceived}).asset.type === 'Securities';

  const goBackToCashFlowsTab = () => {
    openModal({
      modal: TradePopup,
      props: {
        modelId: tradeId,
        tradePopupTab: {value: DetailsTabType.CashFlows, label: DetailsTabType.CashFlows},
      },
    });
  };

  const settlementReceivedTime = useMemo(() => dayjs(lateFee.settlementReceivedTimestamp), [lateFee]);
  const settlementTimeWithDeadline = getTradeSettlementTimeWithDeadLine(trade, legType);

  const delayedLegRow = (
    <TableRow key='delayedLeg' title={formatMessage(fieldTitles.delayedLeg)}>
      {legType === LegType.NearLeg ? 'Near leg' : 'Far leg'}
    </TableRow>
  );

  const youPaidLabel =
    isRepoPaidAmount('paid') ? formatMessage(fieldTitles.youSent) : formatMessage(fieldTitles.youPaid);
  // You paid(sent) / You should have received
  const youPaidLateRow = (
    <TableRow
      key='youPaidOrReceived'
      title={isReceived ? formatMessage(fieldTitles.youShouldHaveReceived) : youPaidLabel}
      leftTestId='you-paid-or-received-label'
      rightTestId='you-paid-or-received-value'
    >
      <YouReceivedOrYouPaidLateValue
        trade={trade}
        legType={legType}
        paidOrReceived={isReceived ? 'received' : 'paid'}
        inMillions={inMillions}
      />
    </TableRow>
  );

  const youPaidLateLabel =
    isRepoPaidAmount('received') ? formatMessage(fieldTitles.youSentLate) : formatMessage(fieldTitles.youPaidLate);
  // You Should have received / You paid(sent) late (cp claimed)
  const youReceivedRow = (
    <TableRow
      key='youReceivedOrPaid'
      title={isReceived ? youPaidLateLabel : formatMessage(fieldTitles.youShouldHaveReceived)}
      leftTestId='you-paid-late-label'
      rightTestId='you-paid-late-value'
    >
      <YouReceivedOrYouPaidLateValue
        trade={trade}
        legType={legType}
        paidOrReceived={isReceived ? 'paid' : 'received'}
        inMillions={inMillions}
      />
    </TableRow>
  );

  const settlementDeadlineRow = (
    <TableRow key='settlementDead' title={formatMessage(fieldTitles.settlementDeadline)}>
      {displayDate(settlementTimeWithDeadline, 'DD MMM YYYY HH:mm:ss')}
    </TableRow>
  );

  const dateReceivedRow = (
    <TableRow
      key='dateReceived'
      title={formatMessage(isReceived ? fieldTitles.dateReceivedClaimed : fieldTitles.dateReceived)}
    >
      {displayDate(settlementReceivedTime, 'DD MMM YYYY')}
    </TableRow>
  );

  const timeReceivedRow = (
    <TableRow
      key='timeReceived'
      title={formatMessage(isReceived ? fieldTitles.timeReceivedClaimed : fieldTitles.timeReceived)}
    >
      {displayDate(settlementReceivedTime, 'HH:mm:ss')}
    </TableRow>
  );

  const delayRow = (
    <TableRow
      key='delay'
      title={formatMessage(isReceived ? fieldTitles.delayClaimed : fieldTitles.delay)}
      rightTestId='settlement-delay'
    >
      {displayDuration(settlementTimeWithDeadline, settlementReceivedTime)}
    </TableRow>
  );

  const lateFeeRateRow = (
    <TableRow key='lateFeeRate' title={formatMessage(fieldTitles.lateFeeRate)}>{`${lateFee.fee.points} bp`}</TableRow>
  );

  const lateFeeAmountRow = (
    <TableRow key='lateFeeAmount' title={formatMessage(fieldTitles.lateFeeAmount)}>
      {displaySmallAmount(lateFee.fee.amountView)}
    </TableRow>
  );

  const rows = [
    delayedLegRow,
    youReceivedRow,
    youPaidLateRow,
    settlementDeadlineRow,
    dateReceivedRow,
    timeReceivedRow,
    delayRow,
    lateFeeRateRow,
    lateFeeAmountRow,
  ];

  return (
    <Popup
      title='Late Settlement: Review late fee'
      onClose={goBackToCashFlowsTab}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable className='late-fee-popup' elements={rows} />
    </Popup>
  );
};
