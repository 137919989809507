import {Dayjs} from 'dayjs';

import {ToastType} from 'utils/ToastUtils';

import {Role} from 'constants/permission-maps';

import {MarketType} from './orderForm';

export interface Amount {
  quantity: number;
  asset: Asset;
}

type AssetType = 'Cash' | 'Securities';

export interface Asset {
  type: AssetType;
  currency: Currency;
  holder?: string;
}

export interface AssetWithTimezone {
  asset: Asset;
  timezoneLabeled: {
    id: string;
    label: string;
  };
}

export interface AssetPair {
  base: Asset;
  second: Asset;
}

export interface AmountAndEquivalent {
  amount: Amount;
  equivalentAmount?: Amount;
}

export interface LastTraded {
  amount: Amount;
  impliedYield: number;
  forwardPoints: number;
  at: string;
}

export interface CurrencyPair {
  base: Currency;
  second: Currency;
}

export interface BestRate {
  depth: number;
  interestRate: number;
  forwardPoints: number;
}

export interface Party {
  legalName: string;
  shortName: string | null;
}

export type Currency = 'USD' | 'EUR' | 'GBP' | 'CAD';
export type Side = 'BuySell' | 'SellBuy';

export type QueryParamsType = {[key: string]: any};

export interface MarketDataQuery {
  asset?: string;
  marketDepth?: number;
}

export interface MarketSnapshot {
  assetPair: AssetPair;
  spotRate: SpotRate;
  lastTraded?: LastTraded;
  bestSellBuyRate?: BestRate;
  bestBuySellRate?: BestRate;
  totalSellBuyDepth?: number;
  totalBuySellDepth?: number;
}

export interface OrderBook {
  buySell: OrderAggregate[];
  sellBuy: OrderAggregate[];
}

export interface OrderAggregate {
  unmatchedAmount: Amount;
  cumulativeAmount: Amount;
  impliedYield: number;
  forwardPoints: number;
  ourUnmatchedAmount: Amount;
}

interface OrderData {
  side: Side;
  marketType: string;
  baseAsset: Asset;
  secondAsset: Asset;
  baseAmount: Amount;
  interestRate: number | null;
  counterparty: string;
  validUntil: string;
  spotRange: number;
  isNonAggressor: boolean;
  initialRate: number;
  userId: string;
}

export enum OrderStatus {
  Pending = 'Pending',
  Live = 'Live',
  Fulfilled = 'Fulfilled',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
  Invalid = 'Invalid',
  Error = 'Error',
}

export interface Order {
  id: string;
  createdBy: string;
  data: OrderData;
  initialForwardPoints: number;
  currentForwardPoints: number | null;
  maturity: string;
  remainingEarmark: Amount;
  unmatchedAmount: Amount;
  lifted: Amount;
  status: OrderStatus;
  isActive: boolean;
  isCancellable: boolean;
  statusReason: string | null;
  createdAt: string;
  resumedAt: string | null;
  lastUpdatedAt: string;
  executedAt?: string | null;
}

export interface SpotRate {
  value: number;
  timestamp: number;
  currencyPair: CurrencyPair;
}

interface MarketHistoryItem {
  startTime: string;
  duration: number;
  volume: number;
  open?: number;
  low?: number;
  high?: number;
  close?: number;
}

export interface MarketHistory {
  assetPair: AssetPair;
  lastTraded?: LastTraded;
  items: MarketHistoryItem[];
}

export type TradeRequestStatus = 'Accepted' | 'Rejected' | 'Proposed' | 'Cancelled' | 'Expired';

export interface EarlyMaturityRequest {
  canBeAccepted: boolean;
  counterparty: string;
  createdAt: string;
  requestor: string;
  status: TradeRequestStatus;
}

export interface TradeUnwindRequest {
  canBeAccepted: boolean;
  createdAt: string;
  changedAt?: string;
  reason: TradeUnwindRequestReason;
  recipient: string;
  requestor: string;
  status: TradeRequestStatus;
  tradeId: string;
}

export interface TradeAnnul {
  canBeAccepted: boolean;
  createdAt: string;
  changedAt?: string;
  reasons: AnnulTradeProposalReason[];
  recipient: string;
  requestor: string;
  status: TradeRequestStatus;
  tradeId: string;
}

export interface TradeRelatedOrder {
  baseAmount: Amount;
  createdAt: string;
  filled: Amount;
  id: string;
  impliedYield?: number;
  side: Side;
}

export type LateSettlementFeeStatus = 'Pending' | 'Accepted' | 'Rejected';

export enum LegType {
  NearLeg = 'NearLeg',
  FarLeg = 'FarLeg',
}

export type LateFeeAmount = {
  points: number;
  amountView: Amount;
};

export interface TradeRelatedLateSettlementFee {
  createdAt: string;
  id: string;
  recipient: string;
  requestor: string;
  settlementReceivedTimestamp: string;
  status: LateSettlementFeeStatus;
  legType: LegType;
  fee: LateFeeAmount;
}

type TradeType = 'FxSwapTradeType' | 'RepoTradeType';

export enum TradeStatus {
  Upcoming = 'Upcoming',
  Processing = 'Processing',
  Outstanding = 'Outstanding',
  EarlyMaturityRequested = 'EarlyMaturityRequested',
  EarlyMaturityAccepted = 'EarlyMaturityAccepted',
  PendingMaturity = 'PendingMaturity',
  Matured = 'Matured',
  SettlementError = 'SettlementError',
  Error = 'Error',
  Unwind = 'Unwind',
  Annulled = 'Annulled',
}

export enum TradeLateSettlementFeesStatus {
  LateFeeRequested = 'LateFeeRequested',
  LateFeeAccepted = 'LateFeeAccepted',
}

export interface Trade {
  id: string;
  tradeType: TradeType;
  createdAt: string;
  relatedOrder?: TradeRelatedOrder;
  relatedLateSettlementFees: TradeRelatedLateSettlementFee[];
  side: Side;
  status: TradeStatus;
  statusReason?: StatusReason | TradeUnwindRequestReason | AnnulTradeProposalReason;
  baseAmount: Amount;
  secondAmount: Amount;
  userId: string;
  entity: string;
  counterparty: string;
  isAggressor: boolean;
  interestRate: number;
  spotRate?: number;
  forwardPoints?: number;
  forwardPointsString?: string;
  interestAmount: Amount;
  ourInterestAmount: Amount;
  expectedTradeDuration: number;
  actualTradeDuration?: number;
  earlyMaturityRequest?: EarlyMaturityRequest;
  tradeUnwind?: TradeUnwindRequest;
  tradeAnnul?: TradeAnnul;
  executionDetails: MTFExecutionDetails | null;
  nearLeg: Leg;
  farLeg: Leg;
}

export interface TradeEvent {
  id: string;
  date: Dayjs;
  event: string;
  leg?: LegType;
  amount?: Amount;
}

type Leg = {
  id: string;
  actualSettlementTime: string | null;
  scheduledTime: string;
  cashFlowIn: Amount;
  cashFlowOut: Amount;
};

export type MTFLeg = {
  uti: string;
  utiNamespace: string;
};

export type MTFExecutionDetails = {
  mtfName: string;
  executedAt: string;
  confirmedAt: string;
  nearLeg: MTFLeg;
  farLeg: MTFLeg;
  brokerTradeReference: string;
  reportTrackingNumber: string;
  mic: string;
};

export enum StatusReason {
  UserRequest = 'UserRequest',
  MaturityTimeReached = 'MaturityTimeReached',
}

export enum MarkToMarketStatus {
  OutOfTheMoney = 'out-of-the-money',
  InTheMoney = 'in-the-money',
}

export interface Balance {
  asset: Asset;
  available: Amount;
  earmark: Amount;
  total: Amount;
}

export enum LimitType {
  Settlement = 'SettlementRiskLimitType',
  Mtm = 'MtMLimitType',
  ClobMatches = 'ClobMatchesLimitType',
}

/**
 * @param timestamp represents the time when the limit was calculated. It might
 * be meaningful, because earmarks can be set in the future - different timestamps can result in different values
 */
export type CombinedLimit = {
  earmark: Amount;
  exposure: Amount;
  total: Amount;
  timestamp: number;
};

export type AggregatedLimit = {
  counterparty: string;
  settlementRiskLimit: CombinedLimit;
  mtmLimit: CombinedLimit;
  clobMatchesLimit: CombinedLimit;
};

export interface Limit {
  counterparty: string;
  isTotal: boolean;
  limitType: LimitType;
  amount: Amount;
}

export interface MarkToMarket {
  status: MarkToMarketStatus;
  amount: Amount;
  latestExchangeRate: SpotRate;
  calculatedAt: number;
}

export interface BalanceWithEquivalent {
  asset: Asset;
  available: AmountAndEquivalent;
  earmark: AmountAndEquivalent;
  total: AmountAndEquivalent;
}

export interface CapacityWarning {
  asset: Asset;
  type: CapacityWarningType;
  createdAt: string;
}

export enum CapacityWarningType {
  NeedToBorrow = 'ProjectedNeedToBorrow',
}

export interface CapacityProjectionPoint {
  at: number;
  value: number;
}

interface CapacityProjection {
  asset: Asset;
  points: CapacityProjectionPoint[];
}

export interface CapacityProjectionUpdate {
  projections: CapacityProjection[];
  warnings: CapacityWarning[];
}

export interface RFQMarketConfig {
  assetPair: AssetPair;
  zoneId: string;
}

export type MarketDay = {
  assetPair: AssetPair;
  startsAt: string; // timestamp
  endsAt: string; // timestamp
  maturesAt: string;
  minTradeSize: number;
  maxTradeSize: number;
};

export interface RFQQuote {
  id: string;
  quoter: string;
  interestRate: number;
  forwardPoints?: number;
  tradedAmount: Amount;
  createdAt: string;
  validUntil: string;
  userId: string;
}

export enum RequesteeAndQuoteStage {
  Started = 'Started',
  QuoteIssued = 'QuoteIssued',
  QuoteAccepted = 'QuoteAccepted',
  QuoteRejected = 'QuoteRejected',
  RequestRejected = 'RequestRejected',
  QuoteCancelled = 'QuoteCancelled',
}

/**
 * Text representation of negotiation status.
 * Used to present to user what actions were taken in readable format.
 */
export type RFQRequestStatusText =
  | 'Filled'
  | 'PartialFill'
  | 'RequestCancelled'
  | 'RequestExpired'
  | 'RequestRejected'
  | 'QuoteExpired'
  | 'QuoteRejected'
  | 'QuoteCancelled'
  | 'Waiting'
  | 'RequestCancelledEarly'
  | 'NetworkError';

export type RFQNegotiationStageName =
  | 'Initialised'
  | 'Started'
  | 'QuoteIssued'
  | 'QuoteAccepted'
  | 'QuoteRejected'
  | 'RequestRejected'
  | 'RequestCancelled'
  | 'QuoteCancelled'
  | 'CancelledEarly'
  | 'NetworkError';

type RFQRequestStageName = 'Accepted' | 'Rejected' | 'Cancelled';

export type RFQRequestRejectionReason =
  | 'DoesNotSuitToSell'
  | 'DoesNotSuitToBuy'
  | 'FarLegTooEarly'
  | 'FarLegTooLate'
  | 'AmountTooSmall'
  | 'TryBackLater';

export type RFQQuoteRejectionReason = 'PricingUnsuitable' | 'TradeAlreadyCompleted' | 'QuotedSizeTooSmall';

export enum TradeUnwindRequestReason {
  NotSent = 'NotSent',
  NotReceived = 'NotReceived',
  NotSentAndNotReceived = 'NotSentAndNotReceived',
}

export type AnnulTradeProposalReason =
  | 'NoLongerRequired'
  | 'NoLongerAvailable'
  | 'MarketConditions'
  | 'ExpectedSettlementIssues'
  | 'Other';

export interface RFQRequestStage {
  name: RFQRequestStageName;
  reasons: RFQRequestRejectionReason[] | RFQQuoteRejectionReason[];
}

export interface RFQNegotiationStage {
  name: RFQNegotiationStageName;
  reasons: RFQRequestRejectionReason[] | RFQQuoteRejectionReason[];
}

export interface RequesteeAndQuote {
  id: string;
  expired: boolean;
  requestee: Party;
  stage: RFQNegotiationStage;
  quote?: RFQQuote;
}

export type RequestType = FXSwapRequest | RepoRequest;

export enum TransactionDate {
  TPlusZero = 'TPlusZero',
  TPlusOne = 'TPlusOne',
}

export interface LegTimeInput {
  transactionDate: TransactionDate;
  localTime: string;
  /**
   * @param timezone represents ZoneId class of Java. Example: "Europe/Warsaw", "America/New_York"
   */
  zoneId: string;
}

export type LegDateTimeInput = {
  transactionDate: TransactionDate;
  timestamp: string;
};

/**
 * @param nearLegTime considered as ASAP, if no value provided.
 */
export interface CreateRFQRequest {
  assetPair: AssetPair;
  validUntil: string;
  requestorSide: Side;
  tradedAmount: Amount;
  nearLegTime?: LegTimeInput;
  maturityTime: LegTimeInput;
  requestees: string[];
  requestType: RequestType;
  suggestedInterestRate: number | undefined;
  suggestedForwardPoints: number | undefined;
}

export type RequestTypeEnum = FXSwapRequestType | RepoRequestType;

export type FXSwapRequestType = 'FXSwapRequestType';

export interface FXSwapRequest {
  type: FXSwapRequestType;
  initialFXRate: number;
  spotRange: number;
}

type RepoRequestType = 'RepoRequestType';

export interface RepoRequest {
  type: RepoRequestType;
}

/**
 * @param nearLegTime considered as ASAP, if no value provided.
 */
export interface RFQRequest {
  id: string;
  isReceived: boolean;
  requestor: Party;
  createdAt: string;
  validUntil: string;
  baseAsset: Asset;
  secondAsset: Asset;
  requestorSide: Side;
  requesteeSide: Side;
  tradedAmount: Amount;
  requestType: FXSwapRequest | RepoRequest;
  nearLegTime?: string;
  nearLegTimeInput?: LegTimeInput;
  maturityTime: string;
  maturityTimeInput: LegTimeInput;
  suggestedInterestRate?: number;
  suggestedForwardPoints?: number;
  requesteesAndQuotes: RequesteeAndQuote[];
  status: RFQRequestStatusText;
  userId: string | null;
}

export interface RFQQuoteForm {
  interestRate: number | undefined;
  forwardPoints: number | undefined;
  tradedAmount: Amount;
  validUntil: string;
}

export interface RFQQuoteWithRequest {
  requestId: string;
  requestor: string;
  requestee: string;
  baseAsset: Asset;
  secondAsset: Asset;
  requestorSide: Side;
  requesteeSide: Side;
  tradedAmount: Amount;
  nearLegTime?: string | null;
  nearLegTimeInput?: LegTimeInput | null;
  maturityTime: string;
  maturityTimeInput: LegTimeInput;
  requestType: RequestType;
  isReceived: boolean;
  quote: RFQQuote;
  expired: boolean;
  stage: RFQNegotiationStage;
}

type NotificationSeverity = 'Info' | 'ResponseRequested' | 'Warning' | 'Error';

export type NotificationPayloadMap = {
  RequestSent: RequestSent;
  ReceivedRequest: ReceivedRequestPayload;
  ReceivedTradeUnwindProposalCancelled: ReceivedTradeUnwindProposalCancelledPayload;
  SentTradeUnwindProposalRejected: SentTradeUnwindProposalRejectedPayload;
  SentTradeUnwindProposalAccepted: SentTradeUnwindProposalAcceptedPayload;
  ReceivedTradeUnwindProposal: ReceivedTradeUnwindProposalPayload;
  ReceivedTradeUnwindProposalExpired: ReceivedTradeUnwindProposalExpiredPayload;
  ReceivedTradeUnwindProposalAccepted: ReceivedTradeUnwindProposalAcceptedPayload;
  ReceivedTradeUnwindProposalRejected: ReceivedTradeUnwindProposalRejectedPayload;
  SentTradeUnwindProposalExpired: SentTradeUnwindProposalExpiredPayload;
  ReceivedQuote: ReceivedQuotePayload;
  ReceivedQuoteAccepted: ReceivedQuoteAcceptedPayload;
  ReceivedQuoteCancelled: ReceivedQuoteAcceptedPayload;
  ReceivedQuoteExpired: ReceivedQuoteCancelledPayload;
  ReceivedRequestCancelled: ReceivedRequestCancelledPayload;
  ReceivedRequestRejected: ReceivedRequestRejectedPayload;
  ReceivedRequestExpired: ReceivedRequestExpiredPayload;
  SentQuoteAccepted: SentQuoteAcceptedPayload;
  SentQuoteExpired: SentQuoteExpiredPayload;
  SentQuoteRejected: SentQuoteRejectedPayload;
  SentQuoteFailed: SentQuoteFailedPayload;
  SentQuoteCancelled: SentQuoteCancelledPayload;
  SentRequestRejected: SentRequestRejectedPayload;
  SentRequestExpired: SentRequestExpiredPayload;
  SentRequestFailed: SentRequestFailedPayload;
  CapacityAdjustmentScheduled: CapacityAdjustmentScheduledPayload;
  ReceivedEarlyMaturityRequest: ReceivedEarlyMaturityRequestPayload;
  ReceivedEarlyMaturityRequestCancelled: ReceivedEarlyMaturityRequestCancelledPayload;
  ReceivedEarlyMaturityRequestExpired: ReceivedEarlyMaturityRequestExpiredPayload;
  ReceivedEarlyMaturityRequestAccepted: ReceivedEarlyMaturityRequestAcceptedPayload;
  ReceivedEarlyMaturityRequestRejected: ReceivedEarlyMaturityRequestRejectedPayload;
  SentEarlyMaturityRequestExpired: SentEarlyMaturityRequestExpiredPayload;
  SentEarlyMaturityRequestRejected: SentEarlyMaturityRequestRejectedPayload;
  SentEarlyMaturityRequestAccepted: SentEarlyMaturityRequestAcceptedPayload;
  OrderMatched: OrderMatchedPayload;
  OrderExpired: OrderExpiredPayload;
  OrderLimitReached: OrderLimitReachedPayload;
  OrderBulkExpired: OrderBulkExpiredPayload;
  OrderSpotRateOutOfRange: OrderSpotRateOutOfRangePayload;
  MarketOrderNotFulfilled: MarketOrderNotFulfilledPayload;
  ReceivedQuoteRejected: ReceivedQuoteRejectedPayload;
  SentQuote: SentQuotePayload;
  ReceivedLateFeeRequest: ReceivedLateFeeRequestPayload;
  ReceivedLateFeeRequestWithdrawn: ReceivedLateFeeRequestWithdrawnPayload;
  ReceivedLateFeeRequestAccepted: ReceivedLateFeeRequestAcceptedPayload;
  ReceivedLateFeeRequestRejected: ReceivedLateFeeRequestRejectedPayload;
  SentLateFeeRequestAccepted: SentLateFeeRequestAcceptedPayload;
  SentLateFeeRequestRejected: SentLateFeeRequestRejectedPayload;
  ReceivedTradeAnnulProposal: ReceivedTradeAnnulProposalPayload;
  ReceivedTradeAnnulProposalCancelled: ReceivedTradeAnnulProposalCancelledPayload;
  ReceivedTradeAnnulProposalExpired: ReceivedTradeAnnulProposalExpiredPayload;
  ReceivedTradeAnnulProposalAccepted: ReceivedTradeAnnulProposalAcceptedPayload;
  ReceivedTradeAnnulProposalRejected: ReceivedTradeAnnulProposalRejectedPayload;
  SentTradeAnnulProposalAccepted: SentTradeAnnulProposalAcceptedPayload;
  SentTradeAnnulProposalExpired: SentTradeAnnulProposalExpiredPayload;
  SentTradeAnnulProposalRejected: SentTradeAnnulProposalRejectedPayload;
  MtMLimitBreached: MtMLimitBreachedPayload;
  MtMLimitBreachResolved: MtMLimitBreachResolvedPayload;
  CollectSignaturesTimeout: CollectSignaturesTimeoutPayload;
  UnableToCommunicateWithCounterparty: UnableToCommunicateWithCounterpartyPayload;
  CashFlowFlaggedAs: CashFlowFlaggedAsPayload;
  ExecutionDetailsMismatched: ExecutionDetailsMismatchedPayload;
  SentRequestCancelledEarly: SentRequestCancelledEarlyPayload;
  SettlementRiskLimitBreached: SettlementRiskLimitBreachedPayload;
};

export type NotificationType = keyof NotificationPayloadMap;

interface RequestSent {
  requestId: string;
  quoter?: string;
  assetPair: AssetPair;
  ourSide: Side;
}

export interface ReceivedQuotePayload {
  quoteId: string;
  requestId: string;
  quoter: string;
  assetPair: AssetPair;
  ourSide: Side;
  quoteValidUntil: string;
}

interface ReceivedQuoteAcceptedPayload {
  quoteId: string;
  requestId: string;
  quoter: string;
  assetPair: AssetPair;
  ourSide: Side;
  quoteValidUntil: string;
}

export interface ReceivedQuoteCancelledPayload {
  quoteId: string;
  requestId: string;
  quoter: string;
  assetPair: AssetPair;
  ourSide: Side;
}

export interface ReceivedQuoteExpiredPayload {
  quoteId: string;
  requestId: string;
  assetPair: AssetPair;
  quoter: string;
  ourSide: Side;
}

interface ReceivedQuoteRejectedPayload {
  quoteId: string;
  requestId: string;
  assetPair: AssetPair;
  quoter: string;
  ourSide: Side;
}

export interface ReceivedRequestPayload {
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  ourSide: Side;
  requestValidUntil: string;
  requestCreatedAt: string;
}

interface ReceivedRequestCancelledPayload {
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  ourSide: Side;
}

interface ReceivedRequestExpiredPayload {
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  ourSide: Side;
}

interface ReceivedRequestRejectedPayload {
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  ourSide: Side;
}

export interface ReceivedTradeUnwindProposalCancelledPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeUnwindProposalPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface SentTradeUnwindProposalRejectedPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface SentTradeUnwindProposalAcceptedPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeUnwindProposalExpiredPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeUnwindProposalAcceptedPayload {
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeUnwindProposalRejectedPayload {
  otherParty: string;
  amount: Amount;
}

export interface SentTradeUnwindProposalExpiredPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeAnnulProposalCancelledPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface SentTradeAnnulProposalAcceptedPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface SentTradeAnnulProposalExpiredPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface SentTradeAnnulProposalRejectedPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeAnnulProposalPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeAnnulProposalExpiredPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeAnnulProposalAcceptedPayload {
  otherParty: string;
  amount: Amount;
}

export interface ReceivedTradeAnnulProposalRejectedPayload {
  otherParty: string;
  amount: Amount;
}

interface SentQuotePayload {
  quoteId: string;
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  quoteValidUntil: string;
}

interface SentQuoteAcceptedPayload {
  quoteId: string;
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  ourSide: Side;
}

interface SentQuoteCancelledPayload {
  quoteId: string;
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  ourSide: Side;
}

interface SentQuoteExpiredPayload {
  quoteId: string;
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  ourSide: Side;
}

interface SentQuoteFailedPayload {
  quoteId: string;
  requestId: string;
  requestor: string;
  assetPair: AssetPair;
  ourSide: Side;
}

interface SentQuoteRejectedPayload {
  quoteId: string;
  requestId: string;
  requestor: string;
  assetPair: AssetPair;
  ourSide: Side;
}

export interface CapacityAdjustmentScheduledPayload {
  amount: Amount;
  from: string;
  to: string;
  userId: string;
  isASAP: boolean;
}

interface SentRequestExpiredPayload {
  requestId: string;
  assetPair: AssetPair;
  quoter: string;
  ourSide: Side;
}

interface SentRequestFailedPayload {
  requestId: string;
  assetPair: AssetPair;
  quoter: string;
  ourSide: Side;
}

interface SentRequestRejectedPayload {
  requestId: string;
  assetPair: AssetPair;
  quoter: string;
  ourSide: Side;
  requestValidUntil: string;
  requestCreatedAt: string;
}

export interface ReceivedEarlyMaturityRequestCancelledPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedEarlyMaturityRequestExpiredPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedEarlyMaturityRequestAcceptedPayload {
  otherParty: string;
  amount: Amount;
}

export interface ReceivedEarlyMaturityRequestRejectedPayload {
  otherParty: string;
  amount: Amount;
}

export interface SentEarlyMaturityRequestExpiredPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface SentEarlyMaturityRequestRejectedPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface SentEarlyMaturityRequestAcceptedPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface ReceivedEarlyMaturityRequestPayload {
  assetPair: AssetPair;
  ourSide: Side;
  otherParty: string;
  amount: Amount;
}

export interface OrderMatchedPayload {
  orderId: string;
  side: Side;
  marketType: MarketType;
  assetPair: AssetPair;
  unmatchedAmount: Amount;
  amount: Amount;
}

interface OrderExpiredPayload {
  orderId: string;
  side: Side;
  marketType: MarketType;
  assetPair: AssetPair;
}

interface OrderLimitReachedPayload {
  orderId: string;
  side: Side;
  marketType: MarketType;
  assetPair: AssetPair;
}

interface OrderBulkExpiredPayload {
  orderIds: Array<string>;
  marketType: MarketType;
  assetPair: AssetPair;
}

interface OrderSpotRateOutOfRangePayload {
  orderId: string;
  side: Side;
  marketType: MarketType;
  assetPair: AssetPair;
}

interface MarketOrderNotFulfilledPayload {
  orderId: string;
  side: Side;
  marketType: MarketType;
  assetPair: AssetPair;
}

export interface ReceivedLateFeeRequestPayload {
  requestor: string;
  assetPair: AssetPair;
  amount: Amount;
  nearLegTime?: string;
  status: string;
  lateFeeId: string;
  legId: string;
}

export interface ReceivedLateFeeRequestWithdrawnPayload {
  requestor: string;
  assetPair: AssetPair;
  amount: Amount;
  nearLegTime: string;
  lateFeeId: string;
  legId: string;
}

export interface ReceivedLateFeeRequestAcceptedPayload {
  requestor: string;
  lateFeeId: string;
  legId: string;
  status: string;
  amount: Amount;
}

export interface SentLateFeeRequestAcceptedPayload {
  recipient: string;
  lateFeeId: string;
  legId: string;
  amount: Amount;
}

export interface ReceivedLateFeeRequestRejectedPayload {
  requestor: string;
  lateFeeId: string;
  legId: string;
  amount: Amount;
}

export interface SentLateFeeRequestRejectedPayload {
  recipient: string;
  lateFeeId: string;
  legId: string;
  amount: Amount;
}

export interface MtMLimitBreachedPayload {
  counterparty: string;
  exposure: Amount;
  limit: Amount;
  timestamp: number;
}

interface MtMLimitBreachResolvedPayload {
  counterparty: string;
  exposure: number;
  limit: number;
  resolvedTimestamp: number;
}

interface CollectSignaturesTimeoutPayload {
  counterparties: string[];
  operationStart: string;
  expirationTime: string;
}

interface UnableToCommunicateWithCounterpartyPayload {
  requestId: string;
  assetPair: AssetPair;
  requestor: string;
  quoter: string;
  ourSide: Side;
}

export type NotificationPayload = NotificationPayloadMap[NotificationType];

export type Notification<T = NotificationType> =
  T extends NotificationType ?
    {
      timestamp: string;
      severity: NotificationSeverity;
      owner: string;
      notificationType: T;
      payload: NotificationPayloadMap[T];
      aggregateId: string;
    }
  : never;

export interface User {
  token?: string;
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: Role;
}

/**
 * @param initialEntity represents current user's Legal name
 */
export interface UserWithMeta {
  id: string;
  user: User;
  legalEntities: Party[];
}

export enum FlagType {
  NearLegReceived = 'NEAR_LEG_RECEIVED',
  NearLegSent = 'NEAR_LEG_SENT',
  NearLegNonreceipt = 'NEAR_LEG_NON_RECEIPT',
  FarLegReceived = 'FAR_LEG_RECEIVED',
  FarLegSent = 'FAR_LEG_SENT',
  FarLegNonreceipt = 'FAR_LEG_NON_RECEIPT',
}

export interface CashflowBody {
  markedTimestamp?: string | null;
  flagType: FlagType;
  flagValue: boolean;
}

export interface TradeEconomicsRequestBody {
  nonAggressorOnly: boolean;
  startTime: string;
  maturityTime: string;
  impliedYield?: number;
  forwardPoints?: number;
  baseAsset: Asset;
  tradedAmount: Amount;
  assumedExchangeRate: number;
  spotRange: number;
  side: Side;
}

interface AmountWithNote {
  amount: Amount;
  note: string;
}

export interface TradeEconomicsResponse {
  liquidityBufferSaving: AmountWithNote;
  interest: AmountWithNote;
  settlementCost: AmountWithNote;
  finteumFee: AmountWithNote;
  netProfitAndLoss: Amount;
}

enum LocationType {
  QueryParameter = 'queryParameter',
  PathParameter = 'pathParameter',
  RequestBody = 'requestBody',
}

interface ErrorDetail {
  reason: string;
  location: string;
  locationType: LocationType;
  message: string;
}

type ErrorCode =
  | 'rfq.cannotAcceptQuoteAlreadyAcceptedByRequestor'
  | 'rfq.cannotAcceptQuoteAlreadyCancelledByQuotor'
  | 'rfq.cannotCancelQuoteAlreadyCancelledByQuotor'
  | 'rfq.cannotCancelQuoteAlreadyAcceptedByRequestor';

export const translateErrorCodeToSeverity = (code: ErrorCode): ToastType => {
  switch (code) {
    case 'rfq.cannotCancelQuoteAlreadyAcceptedByRequestor':
      return 'warning';
    case 'rfq.cannotAcceptQuoteAlreadyCancelledByQuotor':
      return 'warning';
    case 'rfq.cannotAcceptQuoteAlreadyAcceptedByRequestor':
      return 'info';
    case 'rfq.cannotCancelQuoteAlreadyCancelledByQuotor':
      return 'info';
    default:
      return 'error';
  }
};

export interface ErrorResponse {
  code?: ErrorCode;
  userMessage: string;
  developerMessage?: string;
  moreInfo?: string;
  details?: ErrorDetail[];
}

export interface CapacityUpdateRequest {
  from: string | undefined;
  to: string;
  incrementalAmount: Amount;
}

export interface CapacityAdjustment {
  amount: Amount;
  createdAt: string;
  issuerId: string;
  from: string;
  to: string;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface RequestTradeLateSettlementFeeAmountsBody {
  settlementReceivedTimestamp: string;
  legType: LegType;
  side: Side;
  baseAmount: Amount;
  secondAmount: Amount;
  nearLeg: Leg;
  farLeg: Leg;
}

export interface CashFlowFlaggedAsPayload {
  otherParty: string;
  amount: Amount;
  assetPair: AssetPair;
  ourSide: Side;
  flagEventType: FlagType;
  flagValue: boolean;
}

export interface ExecutionDetailsMismatchedPayload {
  uti: string;
}

export type EarlyCancellationReason = 'NotEnoughCounterpartyLimit';

export interface SentRequestCancelledEarlyPayload {
  requestId: string;
  assetPair: AssetPair;
  quoter: string;
  ourSide: Side;
  reason: EarlyCancellationReason;
}

// Notification payload when RFQ quote gets auto rejected due to settlement risk limit breach
export interface SettlementRiskLimitBreachedPayload {
  quoteId: string;
  requestId: string;
  requestor: string;
  assetPair: AssetPair;
  ourSide: Side;
  remainingLimit: Amount;
  requiredLimit: Amount;
}
