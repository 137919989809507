import {color} from 'utils/theme';
import styled from 'styled-components';

import {SRow} from 'ui/Layout/styles';

export const SOrderItem = styled(SRow)`
  margin-top: 6px;
  position: relative;
  color: ${color('blue150')};
  background-color: ${color('blue650')};
  padding: 4px 16px;

  .icon-box {
    width: 100%;
  }

  .status-icon {
    bottom: 0;
    line-height: 16px;
  }
`;
