import {useQuery, useQueryClient} from '@tanstack/react-query';
import {BlotterPosition} from 'types/layout';

const QUERY_KEY = ['BlotterPosition'];

export default function useBlotterPositionStore() {
  const {data} = useQuery({
    queryKey: QUERY_KEY,
    initialData: BlotterPosition.Default,
  });
  const client = useQueryClient();
  const setBlotterPosition = (blotterPosition: BlotterPosition) => client.setQueryData(QUERY_KEY, blotterPosition);
  return [data, setBlotterPosition] as const;
}
