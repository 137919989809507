import {useMemo} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import {useUser} from 'contexts/auth-context';
import {
  storageGetLastCenterOpenedTime,
  storageGetLastReadTime,
  storageGetLastRenderedTime,
  storageSetLastCenterOpenedTime,
  storageSetLastReadTime,
  storageSetLastRenderedTime,
} from 'pages/NotificationCenterPage/utils';

interface Data {
  lastReadTime: number;
  lastNotificationCenterOpenedTime: number;
  lastRenderedTime: number;
}

const STORAGE_FN = {
  lastReadTime: storageSetLastReadTime,
  lastNotificationCenterOpenedTime: storageSetLastCenterOpenedTime,
  lastRenderedTime: storageSetLastRenderedTime,
};

export default function useNotificationsReadDataStore() {
  const {user} = useUser();
  const queryKey = useMemo(() => ['NotificationsReadData', user.email], [user.email]);
  const {data} = useQuery<Data>({
    queryKey,
    queryFn: () => ({
      lastReadTime: storageGetLastReadTime(user),
      lastNotificationCenterOpenedTime: storageGetLastCenterOpenedTime(user),
      lastRenderedTime: storageGetLastRenderedTime(user),
    }),
  });
  const client = useQueryClient();

  const setReadData = (key: keyof Data) => (time: number) => {
    STORAGE_FN[key](time.toString(), user);
    client.setQueryData<typeof data>(queryKey, d => (d ? {...d, [key]: time} : undefined));
  };

  return {
    lastNotificationCenterOpenedTime: data?.lastNotificationCenterOpenedTime || 0,
    lastRenderedTime: data?.lastRenderedTime || 0,
    lastReadTime: data?.lastReadTime || 0,
    updateLastNotificationCenterOpenedTime: setReadData('lastNotificationCenterOpenedTime'),
    updateLastReadTimeWithStorage: setReadData('lastReadTime'),
    updateLastRenderedTime: setReadData('lastRenderedTime'),
  };
}
