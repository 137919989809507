import dayjs from 'dayjs';

import {minSecondAmount} from 'utils/AmountUtils';
import {FXSwapRequest} from 'types/api';
import {useRealTimeQuotesQuery} from 'api/hooks/useRealTimeQuotesQuery';

interface Options {
  currentDate: string;
}

function useCapacityChangesFromQuotes({currentDate}: Options) {
  const {quotes} = useRealTimeQuotesQuery();

  return quotes
    .filter(
      quoteWithRequest =>
        dayjs(quoteWithRequest.quote.validUntil).isAfter(currentDate) &&
        !quoteWithRequest.expired &&
        !quoteWithRequest.isReceived &&
        quoteWithRequest.stage.name === 'QuoteIssued'
    )
    .flatMap(({quote, maturityTime, baseAsset, secondAsset, requestorSide, requestType}) => {
      const request = requestType as unknown as FXSwapRequest;

      const [amount, asset] =
        requestorSide === 'SellBuy' ?
          [minSecondAmount(quote.tradedAmount.quantity, request.initialFXRate, request.spotRange * 100), secondAsset]
        : [quote.tradedAmount.quantity, baseAsset];

      return [
        {
          at: dayjs(quote.createdAt).valueOf(),
          by: amount,
          type: 'earmark',
          asset,
        },
        {
          at: dayjs(maturityTime).valueOf(),
          by: -amount,
          type: 'earmark',
          asset,
        },
        {
          at: dayjs(quote.createdAt).valueOf(),
          by: -amount,
          type: 'available',
          asset,
        },
        {
          at: dayjs(maturityTime).valueOf(),
          by: amount,
          type: 'available',
          asset,
        },
      ];
    });
}

export default useCapacityChangesFromQuotes;
