import {useCallback} from 'react';

import {useNavigate, useLocation} from 'react-router';

import {collapsableAnalyticsSideBarMenu} from 'pages/AnalyticsPage/constants';

import {Scope} from 'constants/permission-maps';

import Accordion from 'ui/Accordion';

import PermissionsGate from 'components/PermissionGate';

import {AccordionMenuItem} from './styles';

const AnalyticsSideBar = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const getSelectedTab = useCallback(() => {
    const location = pathname.split('/');
    return location[location.length - 1];
  }, [pathname]);

  return (
    <PermissionsGate scopes={[Scope.ViewAnalytics]}>
      <>
        {collapsableAnalyticsSideBarMenu.map(({key, title, subItems, isOpen}) => (
          <Accordion data-testid={key} title={title} key={key} defaultOpen={isOpen}>
            {subItems.map(({key: subItemKey, label, path}) => (
              <AccordionMenuItem
                key={subItemKey}
                active={path.relative === getSelectedTab()}
                onClick={() => navigate(path.absolute)}
                data-testid={subItemKey}
              >
                {label}
              </AccordionMenuItem>
            ))}
          </Accordion>
        ))}
      </>
    </PermissionsGate>
  );
};

export default AnalyticsSideBar;
