import axios, {AxiosInstance} from 'axios';

import {showToast} from 'utils/ToastUtils';

import {queryClient} from 'api/query';

import {makeLogOutApiCall} from 'api/api';

import {fxApiUrl} from 'constants/app_defaults';

export const baseInstance: AxiosInstance = axios.create({
  baseURL: fxApiUrl,
  withCredentials: true,
});

export const authInstance: AxiosInstance = axios.create({
  baseURL: fxApiUrl,
  withCredentials: true,
});

export const handleUnauthorized = () => {
  showToast('Your session has expired. Please sign in to proceed.', 'error');
  queryClient.clear();
  makeLogOutApiCall()
    .then(() => window.location.replace(`${window.location.origin}/login`))
    .catch(error => {
      console.log(error);
      showToast('Could not log out. Please try again later.', 'error');
    });
};

authInstance.interceptors.response.use(undefined, error => {
  if (error.response.status === 401) {
    handleUnauthorized();
  }
  return Promise.reject(error);
});
