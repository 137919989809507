import {useEffect, useState, useMemo} from 'react';
import isEqual from 'lodash/isEqual';

import {RFQMarketConfig, TransactionDate} from 'types/api';
import {useFormContext} from 'react-hook-form';

import dayjs from 'dayjs';

import {ensureFutureDate, getUpcomingMarketDays} from 'utils/DayjsUtils';

import useRFQMarketsQuery from 'api/hooks/useRFQMarketsQuery';

import {isAssetPairEqual} from 'utils/AssetUtils';

import {isTradeSizeInMillions} from 'utils/MarketUtils';

import {orderFormLimits} from 'constants/orderForm';

import {RFQFormValues} from './useRFQForm';
import {triggerRfqFarLegFlash, triggerRfqNearLegFlash} from '../components/LegsRows/constants';

export default function useSelectedMarketDay() {
  const [selectedMarket, setSelectedMarket] = useState<RFQMarketConfig | undefined>();
  const {marketDays, markets} = useRFQMarketsQuery();
  const methods = useFormContext<RFQFormValues>();
  const {getFieldState} = useFormContext<RFQFormValues>();
  const [pair, nearleg, farleg, nearlegTransactionDate, tradedAmountCurrency] = methods.watch([
    'currentPair',
    'nearleg',
    'farleg',
    'nearlegTransactionDate',
    'tradedAmountCurrency',
  ]);
  const selectedMarketDays = marketDays.filter(day => isEqual(day.assetPair, selectedMarket?.assetPair));
  const [marketTPlusZeroDay, marketTPlusOneDay] = getUpcomingMarketDays(selectedMarketDays);

  const isNowWithinTPlusZeroDay = useMemo(() => {
    if (!marketTPlusZeroDay) return false;
    return dayjs(marketTPlusZeroDay.startsAt).isBefore(dayjs()) && dayjs(marketTPlusZeroDay.endsAt).isAfter(dayjs());
  }, [marketTPlusZeroDay?.startsAt, marketTPlusZeroDay?.endsAt]);

  const checkCurrentLegsValid = () => {
    if (!nearleg || !farleg) return false;
    if (nearlegTransactionDate === TransactionDate.TPlusOne && !marketTPlusOneDay) return false;
    if (
      nearleg === 'ASAP' &&
      (!marketTPlusZeroDay ||
        dayjs().isBefore(dayjs(marketTPlusZeroDay.startsAt)) ||
        dayjs().isAfter(dayjs(marketTPlusZeroDay.endsAt)))
    )
      return false;
    if (nearleg && nearleg !== 'ASAP' && dayjs(nearleg).isBefore(dayjs())) return false;
    return true;
  };

  useEffect(() => {
    const newSelectedMarket = markets.find(
      (market: RFQMarketConfig) => pair && isAssetPairEqual(market.assetPair, pair)
    );
    setSelectedMarket(newSelectedMarket);
    if (pair) methods.setValue('tradedAmountCurrency', pair.base.currency);
  }, [JSON.stringify(markets), JSON.stringify(pair)]);

  useEffect(() => {
    const minTradeSize = marketTPlusZeroDay?.minTradeSize ?? orderFormLimits.amount.min;
    const maxTradeSize = marketTPlusZeroDay?.maxTradeSize ?? orderFormLimits.amount.max;

    methods.setValue('tradedAmountMin', minTradeSize);
    methods.setValue('tradedAmountMax', maxTradeSize);
    // this checks if tradedAmount has been changed and if so, only then we trigger validation
    // if this field has not been changed, then we do not run validation on it
    getFieldState('tradedAmount').isDirty && methods.trigger('tradedAmount');
  }, [JSON.stringify(pair), JSON.stringify(marketTPlusZeroDay), JSON.stringify(tradedAmountCurrency)]);

  useEffect(() => {
    if (!selectedMarket || checkCurrentLegsValid()) return;
    if (!marketTPlusZeroDay) return;

    methods.setValue('farleg', dayjs(marketTPlusZeroDay.endsAt), {shouldValidate: true});
    methods.setValue('farlegTransactionDate', TransactionDate.TPlusZero, {shouldValidate: true});
    methods.setValue(
      'nearleg',
      dayjs(marketTPlusZeroDay.startsAt).isBefore(dayjs()) ? 'ASAP' : ensureFutureDate(marketTPlusZeroDay.startsAt),
      {shouldValidate: true}
    );
    methods.setValue('nearlegTransactionDate', TransactionDate.TPlusZero, {shouldValidate: true});
    triggerRfqNearLegFlash();
    triggerRfqFarLegFlash();
  }, [JSON.stringify(selectedMarket), JSON.stringify(marketTPlusZeroDay)]);

  const getMarketDayForTransactionDate = (transactionDate: TransactionDate) =>
    transactionDate === TransactionDate.TPlusZero ? marketTPlusZeroDay : marketTPlusOneDay;
  const selectedNearLegMarketDay = getMarketDayForTransactionDate(nearlegTransactionDate ?? TransactionDate.TPlusZero);

  return {
    selectedMarket,
    selectedNearLegMarketDay,
    hasClosedMarket: !!selectedMarket && !isNowWithinTPlusZeroDay,
    marketReopensDate:
      marketTPlusZeroDay || marketTPlusOneDay ? dayjs((marketTPlusZeroDay || marketTPlusOneDay)?.startsAt) : undefined,
    getMarketDayForTransactionDate,
    inMillions: selectedNearLegMarketDay ? isTradeSizeInMillions(selectedNearLegMarketDay) : true,
  };
}
