import useMarketHistoryQuery from 'api/hooks/useMarketHistoryQuery';

import dayjs from 'dayjs';
import {discontinuousTimeScaleProvider} from 'react-stockcharts/lib/scale';
import {ema} from 'react-stockcharts/lib/indicator';
import {last} from 'react-stockcharts/lib/utils';

import {useState, useEffect, useCallback} from 'react';
import {AssetPair, LastTraded} from 'types/api';
import {setTimeDifference} from 'utils/utils';

import {useInterval} from 'react-use';

import {ReferenceData} from 'types/layout';

import {timezoneOffset} from 'constants/app_defaults';

// there are no types for react-stockcharts
type HistoryChartData = {
  data?: any;
  lastTraded?: LastTraded;
  xScale?: any;
  xExtents?: any[];
  yExtents?: any[];
  xAccessor?: any;
  displayXAccessor?: any;
};

type ChartScale = Omit<HistoryChartData, 'lastTraded'>;

export function useHistoryChartData(assetPair: AssetPair, referenceData: ReferenceData) {
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [since, setSince] = useState<number>();
  const {data: history, isError} = useMarketHistoryQuery({assetPair, referenceData, since});
  const [isReloading, setIsReloading] = useState(0);
  useInterval(() => {
    setIsReloading(i => i + 1);
  }, 10 * 1000);

  const isHistoryUnavailable = !history || !history.data || history.data?.items.length < 1;

  useEffect(() => {
    const secondsFromLastUpdate: number = (new Date().getTime() - lastUpdateTime) / 1000;

    if (secondsFromLastUpdate > 9) {
      setLastUpdateTime(new Date().getTime());

      if (isHistoryUnavailable) return;

      const lastHistoryItem = history.data?.items[history.data?.items.length - 1];
      const newSince = dayjs(lastHistoryItem.startTime).toDate().getTime();

      setSince(newSince);
    }
  }, [
    JSON.stringify(referenceData),
    isReloading,
    lastUpdateTime,
    isHistoryUnavailable,
    JSON.stringify(history?.data?.items),
  ]);

  const calculateChartScale = useCallback((): ChartScale => {
    const ema20: any = ema()
      .id(0)
      .options({windowSize: 20})
      .merge((d: any, c: any) => ({
        ...d,
        ema20: c,
      }))
      .accessor((d: any) => d.ema20);

    const ema50: any = ema()
      .id(2)
      .options({windowSize: 50})
      .merge((d: any, c: any) => {
        d.ema50 = c;
      })
      .accessor((d: any) => d.ema50);
    const yExtents = [(d: any) => [d.high * 1.1, d.low * 0.9], ema20.accessor(), ema50.accessor()];

    if (isHistoryUnavailable) return {yExtents};
    const initialData = history;

    const calculatedData: any = ema50(ema20(initialData.data?.items)); // same as initialData for now

    const xScaleProvider: any = discontinuousTimeScaleProvider.inputDateAccessor((d: any) =>
      setTimeDifference(new Date(d.startTime), timezoneOffset)
    );

    const {data, xScale, xAccessor, displayXAccessor} = xScaleProvider(calculatedData);
    const start: number = xAccessor(last(data)) < 8 ? 8 : xAccessor(last(data));
    const end: number = xAccessor(data[Math.max(0, data.length - 48)]);
    const xExtents: any = [start, end];
    return {
      data,
      xScale,
      xExtents,
      yExtents,
      xAccessor,
      displayXAccessor,
    };
  }, [history, isHistoryUnavailable]);

  const errorMessage: string =
    (history?.status || 0) >= 500 ?
      'Unable to connect with the matching server.'
    : 'Sorry, can not connect to the API server.';
  const infoMessage: string =
    isError ? errorMessage
    : history ? 'Market history is empty.'
    : 'Market history is being loaded.';

  const showChart = history && history.data?.items.length > 1;
  const historyData: HistoryChartData = {
    lastTraded: history?.data?.lastTraded,
    ...calculateChartScale(),
  };
  return {historyData, infoMessage, showChart};
}
