import styled from 'styled-components';

export const CountdownContainer = styled.div`
  color: #fff;

  .seconds {
    font-size: 2em;
  }
`;

export const TabContent = styled.div`
  position: relative;
  width: 100%;
  display: block;
  padding-top: 1em;
`;

export const TabHeader = styled.div`
  text-align: center;
  color: #a3b2cf;
  font-size: 0.875rem;
  padding: 0 0.2em;
`;

export const TableLabel = styled.span`
  text-align: left;
  cursor: pointer;
`;

export const TableValue = styled.span`
  text-align: left;
`;

export const TableNumber = styled.span`
  text-align: right;
  display: block;
`;
