import styled from 'styled-components';

export const ModalContainer = styled.section<{showOverlay?: boolean}>`
   {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    min-height: 0;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background-color: ${p => (p.showOverlay ? 'rgba(0,0,0,.7)' : 'none')};
  }
`;

export const ModalContentCard = styled.div`
   {
    position: relative;
    margin: auto;
    overflow: hidden;
  }
`;

export const ModalClose = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border: none;
  background: none;
  &:focus {
    outline: 1px solid #32a1ce;
  }
`;
