import {useQuery} from '@tanstack/react-query';
import {getAllMarketDays, listRFQMarkets} from 'api/api';
import {AxiosError} from 'axios';
import {pairToItem, prepareAssetPairsForDisplay} from 'utils/AssetUtils';

export default function useRFQMarketsQuery() {
  const {data, isLoading} = useQuery({
    queryKey: ['RFQMarkets'],
    queryFn: () =>
      Promise.all([
        listRFQMarkets()
          .then(response => {
            const items = prepareAssetPairsForDisplay(response.data).map(ap => (ap ? pairToItem(ap) : null));
            return {assetPairs: items, markets: response.data};
          })
          .catch((err: AxiosError) => {
            console.error(err.message);
            throw err;
          }),
        getAllMarketDays()
          .then(response => response.data)
          .catch((err: AxiosError) => {
            console.error(err.message);
            throw err;
          }),
      ]),
    staleTime: Infinity,
  });

  return data ?
      {
        markets: data[0].markets,
        assetPairs: data[0].assetPairs,
        marketDays: data[1],
        isLoading,
      }
    : {markets: [], assetPairs: [], marketDays: [], isLoading};
}
