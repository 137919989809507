import styled from 'styled-components';

export const AccordionContainer = styled.div`
  margin-bottom: 10px;
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1.5em;
  background-color: #4e5561;
  cursor: pointer;

  .arrow-icon {
    color: white;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  }
`;

export const AccordionTitle = styled.h3`
  margin: 0;
`;
