import {useRef, FC, useReducer, useEffect} from 'react';

import Portal from './Portal';
import {LightTooltip, DarkTooltip, TooltipContainer} from './styles';
import {Action, TooltipState, TooltipWrapperProps, Coordinates} from './types';

const initialState: TooltipState = {isOn: false, widthLimit: 0};

const stateReducer = (state: TooltipState, action: Action): TooltipState => {
  switch (action.type) {
    case 'SHOW':
      return {...state, isOn: true, ...action.payload};
    case 'HIDE':
      // when hiding, make sure to reset coordinates
      return {isOn: false, widthLimit: state.widthLimit};
    case 'MAX_WIDTH':
      return {...state, widthLimit: action.payload};
    default:
      return state;
  }
};

const TooltipWrapper: FC<TooltipWrapperProps> = ({
  children,
  message,
  position = 'top',
  variant = 'dark',
  disabled = false,
  maxWidth,
  styles,
  ...rest
}) => {
  // define references to the tooltip and the element
  // so it is possible to access elements data later on
  const elementRef = useRef<HTMLSpanElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // state reducer allows to change multiple state values at once
  const [{isOn, top, right, bottom, left, widthLimit}, dispatch] = useReducer(stateReducer, initialState);

  useEffect(() => {
    // in case maxWidth props is not provided
    if (elementRef.current && !maxWidth) {
      dispatch({
        type: 'MAX_WIDTH',
        payload:
          ['top', 'bottom'].includes(position) ?
            // limit tooltip width to the width of the element for top and bottom variant
            elementRef.current.clientWidth
            // limit tooltip width to 300px for the right and left variant
          : 300,
      });
    }
    return () => {
      // hide tooltip on unmount
      dispatch({type: 'HIDE'});
    };
  }, [maxWidth, position]);

  // returns tooltip coordinates in the document based on selected position
  const getCoordinates = (): Coordinates => {
    if (!elementRef.current || !tooltipRef.current) {
      return {};
    }
    // get dimensions of the wrapped element
    const rect = elementRef.current.getBoundingClientRect();
    // calculate distance from the top of the page to the top of the tooltip,
    // used for positioning of hte left and right side tooltips
    const horizontalTop =
      rect.y + // distance to the top of the wrapped element
      window.scrollY + // window scroll offset
      elementRef.current.clientHeight / 2 - // half of the wrapped element height
      tooltipRef.current.clientHeight / 2; // half of the tooltip height

    const verticalLeft =
      // for light variant always position left
      variant === 'light' ?
        rect.x
        // for dark center it
        // wrapped element left position
      : rect.x -
        // half of the difference between tooltip width and wrapped element width
        // allows to center the tooltip
        (tooltipRef.current.clientWidth - elementRef.current.clientWidth) / 2;

    switch (position) {
      case 'right':
        return {
          left: rect.x + elementRef.current.clientWidth + (variant === 'light' ? 8 : 12), // offset for the arrow
          top: horizontalTop,
        };
      case 'bottom':
        return {
          left: verticalLeft,
          top: rect.y + window.scrollY + elementRef.current.clientHeight + 8, // 8px offset for the arrow
        };
      case 'left':
        return {
          left: rect.x - tooltipRef.current.clientWidth - (variant === 'light' ? 8 : 12), // offset for the arrow
          top: horizontalTop,
        };
      case 'top':
      default:
        return {
          left: verticalLeft,
          top: rect.y + window.scrollY - tooltipRef.current.clientHeight - 8, // 8px offset for the arrow
        };
    }
  };

  const handleMouseEnter = () => {
    if (elementRef.current && !disabled && !isOn) {
      dispatch({type: 'SHOW', payload: getCoordinates()});
    }
  };

  const handleMouseLeave = () => {
    if (!disabled && isOn) {
      dispatch({type: 'HIDE'});
    }
  };

  const Style = variant === 'light' ? LightTooltip : DarkTooltip;

  return (
    <TooltipContainer
      ref={elementRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onWheel={handleMouseLeave}
      style={styles}
      {...rest}
    >
      {children}

      {message && (
        <Portal>
          <Style
            ref={tooltipRef}
            position={position}
            style={{
              top,
              right,
              bottom,
              left,
              maxWidth: maxWidth || widthLimit,
              visibility: isOn && !disabled ? 'visible' : 'hidden',
            }}
          >
            {message}
          </Style>
        </Portal>
      )}
    </TooltipContainer>
  );
};

export default TooltipWrapper;
