import {Navigate, useRoutes} from 'react-router-dom';

import {CHART_VIEW} from 'constants/paths';
import {ClobOverview} from 'containers/ClobOverview/ClobOverview';

import {ObserverLayout} from 'containers/layout/ObserverLayout';
import {History} from 'containers/History';

import ForOfor from '../pages/404';

const ObserverRoutes = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to='/clob' />},
    {path: '/saml-login', element: <Navigate to='/clob' />},
    {path: '/login', element: <Navigate to='/clob' />},
    {
      path: '/',
      element: <ObserverLayout />,
      children: [
        {path: 'clob', element: <ClobOverview />},
        {path: CHART_VIEW.absolute, element: <History />},
      ],
    },
    {path: '/404', element: <ForOfor />},
    {path: '*', element: <ForOfor />},
  ]);

  return routes;
};

export default ObserverRoutes;
