export const getCapacityMessage = (statusCode: number) => {
  switch (statusCode) {
    case 200:
    case 204:
      return 'Capacity successfully adjusted.';
    case 202:
      return 'Capacity adjustment in progress.';
    case 400:
      return 'Capacity adjustment failed. Invalid parameters.';
    case 500:
    default:
      return 'Service Unavailable.';
  }
};

export const getCapacityMessageType = (statusCode: number) => {
  switch (statusCode) {
    case 200:
    case 204:
      return 'success';
    case 202:
      return 'warning';
    case 400:
    case 500:
    default:
      return 'error';
  }
};
