import dayjs, {Dayjs} from 'dayjs';

import {
  Asset,
  Party,
  RequesteeAndQuote,
  RFQQuoteWithRequest,
  RFQRequest,
  RFQRequestRejectionReason,
  Side,
  RequestType,
  RFQNegotiationStageName,
  RFQQuoteRejectionReason,
  Amount,
  RFQNegotiationStage,
  RFQQuote,
  LegTimeInput,
  TransactionDate,
  AssetPair,
  Currency,
  FXSwapRequest,
  LegDateTimeInput,
  RFQRequestStatusText,
  AnnulTradeProposalReason,
} from 'types/api';

import Decimal from 'decimal.js';

import {MessageDescriptor} from '@formatjs/intl';

import {QUOTE_REJECTION_REASONS, REJECTION_REASONS} from 'constants/rfqForm';
import {TRADE_ANNUL_REASONS} from 'constants/trade';

import {fieldTitles} from 'constants/messages/labels';

import {Leg} from 'containers/RFQForm/types';

import {REPO_HAIRCUT} from 'containers/OrderForm/types';

import {RejectionReasons} from 'components/popups/RFQOverviewPopup/components/Comparison/styles';

import {assetCode, isRepoPair} from './AssetUtils';
import {DateFormat, displayDate} from './DayjsUtils';
import {displayParty, map} from './utils';
import {
  convertQuantity,
  displayAmountWithCode,
  displayRangeWithCode,
  displaySpotRangeBigfig,
  exactSecondAmount,
  expectedRangeOfAmountExchanged,
  maxSecondAmount,
  minSecondAmount,
  To,
} from './AmountUtils';

import {appTimezone} from './setupDayjs';
import {intlImperative} from './IntlUtils';

// Set of stages that count as final stage, meaning there is no more possible actions for that negotiation
const finalRequestStages = new Set<RFQNegotiationStageName>([
  'RequestCancelled',
  'RequestRejected',
  'QuoteAccepted',
  'QuoteRejected',
  'QuoteCancelled',
  'NetworkError',
  'CancelledEarly',
]);

export const isRFQRequestExpired = (rfqRequest: RFQRequest): boolean =>
  rfqRequest.requesteesAndQuotes.every(
    (requesteeAndQuote: RequesteeAndQuote) =>
      requesteeAndQuote.expired || finalRequestStages.has(requesteeAndQuote.stage.name)
  );

export const isRFQRequestQuotesCancelled = (rfqRequest: RFQRequest): boolean =>
  rfqRequest.requesteesAndQuotes.every(
    (requesteeAndQuote: RequesteeAndQuote) => requesteeAndQuote.stage.name === 'QuoteCancelled'
  );

// RFQ Request has any quote
export const rfqRequestHasQuote = (rfqRequest: RFQRequest): boolean =>
  rfqRequest.requesteesAndQuotes.some((requesteeAndQuote: RequesteeAndQuote) => requesteeAndQuote.quote);

export type NegotiationStatusText =
  | 'Request Sent'
  | 'Request Cancelled'
  | 'Request Expired'
  | 'Request Rejected'
  | 'Started'
  | 'Quote Accepted'
  | 'Quote Cancelled'
  | 'Quote Expired'
  | 'Quote Received'
  | 'Quote Rejected'
  | 'Quote Sent'
  | 'Respond via Blotter'
  | 'Late Fee Withdrawn'
  | 'Late Fee Accepted'
  | 'Late Fee Rejected'
  | 'Unwind Cancelled'
  | 'Unwind Rejected'
  | 'Unwind Accepted'
  | 'Unwind Withdrawn'
  | 'Unwind Proposed'
  | 'Unwind Expired'
  | 'EMR Expired'
  | 'EMR Accepted'
  | 'EMR Rejected'
  | 'Annul Accepted'
  | 'Annul Rejected'
  | 'Waiting'
  | 'Resolved'
  | 'Network Error';

export const getQuoteStatusText = (
  isRequestReceived: boolean,
  isExpired: boolean,
  statusName: RFQNegotiationStageName
): NegotiationStatusText => {
  switch (statusName) {
    case 'QuoteAccepted':
      return 'Quote Accepted';
    case 'QuoteRejected':
      return 'Quote Rejected';
    case 'QuoteCancelled':
      return 'Quote Cancelled';
    case 'RequestCancelled':
      return 'Request Cancelled';
    case 'RequestRejected':
      return 'Request Rejected';
    case 'QuoteIssued':
      if (isExpired) {
        return 'Quote Expired';
      } else {
        return isRequestReceived ? 'Quote Sent' : 'Quote Received';
      }
    case 'Started':
      return isExpired ? 'Request Expired' : 'Waiting';
    case 'NetworkError':
      return 'Network Error';
    case 'CancelledEarly':
      return 'Request Cancelled';
    case 'Initialised':
    default:
      return 'Waiting';
  }
};

// Create a single quote with its request details in one single object
export const createSingleQuoteWithRequest = (
  requesteeAndQuote: RequesteeAndQuote,
  request: RFQRequest,
  legalEntities: Party[]
): RFQQuoteWithRequest => {
  const singleQuoteWithRequest: RFQQuoteWithRequest = {
    requestId: request.id,
    requestor: request.requestor.legalName,
    baseAsset: request.baseAsset,
    secondAsset: request.secondAsset,
    requestorSide: request.requestorSide,
    requestee: requesteeAndQuote.requestee.legalName,
    requesteeSide: request.requesteeSide,
    tradedAmount: request.tradedAmount,
    requestType: request.requestType,
    nearLegTime: request.nearLegTime,
    nearLegTimeInput: request.nearLegTimeInput,
    maturityTime: request.maturityTime,
    maturityTimeInput: request.maturityTimeInput,
    stage: requesteeAndQuote.stage,
    expired: requesteeAndQuote.expired,
    quote: requesteeAndQuote.quote!,
    isReceived: legalEntities.map(x => x.legalName).includes(request.requestor.legalName) ? true : false,
  };
  return singleQuoteWithRequest;
};

// RFQ Request is waiting for reply or not
const isRFQRequestWaitingForReply = (request: RFQRequest): boolean =>
  request.requesteesAndQuotes.every(
    (requesteeAndQuote: RequesteeAndQuote) => !requesteeAndQuote.expired && requesteeAndQuote.stage.name === 'Started'
  );

// Count RFQ Requests waiting for reply( requiring action)
export const getRfqRequestsRequiringAction = (requests: RFQRequest[]): number =>
  requests.filter((request: RFQRequest) => isRFQRequestWaitingForReply(request)).length;

// Sort RFQ counterparties
export const sortRfqRequesteesAndQuotes = (rfqRequest: RFQRequest): RFQRequest => {
  return {
    ...rfqRequest,
    requesteesAndQuotes: rfqRequest.requesteesAndQuotes.sort((a: RequesteeAndQuote, b: RequesteeAndQuote) =>
      displayParty(a.requestee).localeCompare(displayParty(b.requestee))
    ),
  };
};

// Sort RFQ counterparties on multiple RFQ Requests
export const sortMultipleRequesteesAndQuotes = (rfqRequests: RFQRequest[]): RFQRequest[] =>
  rfqRequests.map((request: RFQRequest) => sortRfqRequesteesAndQuotes(request));

// Returns rejection reason string with currency depend on side
export const getReasonText = (
  requesteeSide: Side,
  reason: RFQRequestRejectionReason | RFQQuoteRejectionReason | AnnulTradeProposalReason,
  baseAsset: Asset,
  secondAsset: Asset
): string => {
  switch (reason) {
    case 'DoesNotSuitToSell':
      return `${REJECTION_REASONS[reason]} ${assetCode(requesteeSide === 'BuySell' ? secondAsset : baseAsset)}`;
    case 'DoesNotSuitToBuy':
      return `${REJECTION_REASONS[reason]} ${assetCode(requesteeSide === 'BuySell' ? baseAsset : secondAsset)}`;
    case 'NoLongerRequired':
      return `${assetCode(requesteeSide === 'BuySell' ? baseAsset : secondAsset)}: ${TRADE_ANNUL_REASONS[reason]}`;
    case 'NoLongerAvailable':
      return `${assetCode(requesteeSide === 'BuySell' ? secondAsset : baseAsset)}: ${TRADE_ANNUL_REASONS[reason]}`;
    default:
      return (
        REJECTION_REASONS[reason as RFQRequestRejectionReason] ??
        QUOTE_REJECTION_REASONS[reason as RFQQuoteRejectionReason] ??
        TRADE_ANNUL_REASONS[reason as AnnulTradeProposalReason]
      );
  }
};

export const getInitialFXRate = (requestType: RequestType) =>
  requestType.type === 'FXSwapRequestType' ? requestType.initialFXRate : undefined;

export const getInterestRateForComputation = (requestType: RequestType) =>
  requestType.type === 'FXSwapRequestType' ? requestType.initialFXRate : 1.0 / 0.9;

const getSpotRange = (requestType: RequestType) =>
  requestType.type === 'FXSwapRequestType' ? requestType.spotRange : undefined;

const getSpotRangeBigFig = (requestType: RequestType) =>
  map(getSpotRange(requestType), n =>
    Decimal.round(n * 1000000)
      .div(10000)
      .toNumber()
  );

export const getSpotRangeBigFigForComputation = (requestType: RequestType) => getSpotRangeBigFig(requestType) ?? 0;

export const displaySpotRangeForRequest = (requestType: RequestType): string => {
  const spotRangeBigFig = getSpotRangeBigFig(requestType);
  return spotRangeBigFig ? `${displaySpotRangeBigfig(spotRangeBigFig)} bigfig` : '--';
};

export const counterAmountToString = (
  requestAmt: Amount,
  requestType: RequestType,
  baseAsset: Asset,
  secondAsset: Asset,
  isRepo: boolean,
  inMillions: boolean
) => {
  const counterAsset = getCounterAsset({baseAsset, secondAsset, tradedAsset: requestAmt.asset});

  const range = expectedRangeOfAmountExchanged(
    requestAmt.quantity,
    counterAsset.currency === secondAsset.currency ?
      getInterestRateForComputation(requestType)
    : 1 / getInterestRateForComputation(requestType),
    getSpotRangeBigFigForComputation(requestType)
  );
  return isRepo ?
      displayAmountWithCode({quantity: range[0], asset: counterAsset}, false, inMillions)
    : displayRangeWithCode(
        {quantity: range[0], asset: counterAsset},
        {quantity: range[1], asset: counterAsset},
        inMillions
      );
};

export const findPartyFromList = (rfqRequest: RFQRequest, listOfEntities: string[]): Party | undefined => {
  if (rfqRequest.isReceived) {
    // find users quote
    const quote = rfqRequest.requesteesAndQuotes.find(requesteeAndQuote =>
      listOfEntities.includes(requesteeAndQuote.requestee.legalName)
    );
    return quote?.requestee; // fallback to empty entity names
  }
  // otherwise, user is requestor
  return rfqRequest.requestor;
};

// Returns status for RFQ request/quote
export const getRFQReceivedSent = (isRequestReceived: boolean, forQuote: boolean): 'Received' | 'Sent' => {
  if (!forQuote) {
    // return request status
    return isRequestReceived ? 'Received' : 'Sent';
  }
  // return quote status
  return isRequestReceived ? 'Sent' : 'Received';
};

export const getRequestStatusText = (request: RFQRequest): RFQRequestStatusText => request.status;

export const shouldShowRequestValidUntil = (request: RFQRequest) => getRequestStatusText(request) === 'Waiting';

export const shouldShowQuoteValidUntil = ({stage, expired}: {stage: RFQNegotiationStage; expired: boolean}) =>
  ['Started', 'QuoteIssued'].includes(stage.name) && !expired;

const MIN_TIMESTAMP = dayjs.unix(0).toISOString();

/** For countdown purposes in some components it returns Jan 1, 1970 if quote is no longer valid */
export const getRequestValidUntil = (request: RFQRequest) =>
  shouldShowRequestValidUntil(request) ? request.validUntil : MIN_TIMESTAMP;

/** For countdown purposes in some components it returns Jan 1, 1970 if quote is no longer valid */
export const getQuoteValidUntil = ({
  stage,
  expired,
  quote,
}: {
  stage: RFQNegotiationStage;
  expired: boolean;
  quote: RFQQuote;
}) => (shouldShowQuoteValidUntil({stage, expired}) ? quote?.validUntil : MIN_TIMESTAMP);

export const isFXSwapRequestType = (requestType: RFQRequest['requestType']): requestType is FXSwapRequest =>
  'initialFXRate' in requestType && 'spotRange' in requestType;

const getTransactionDate = (leg: Leg): TransactionDate =>
  leg === 'T+0' ? TransactionDate.TPlusZero : TransactionDate.TPlusOne;

export const getLeg = (transactionDate: TransactionDate): Leg =>
  transactionDate === TransactionDate.TPlusZero ? 'T+0' : 'T+1';

export const getLegTimeInput = (date: string | Dayjs, prefix: Leg, zoneId = appTimezone): LegTimeInput => ({
  transactionDate: getTransactionDate(prefix),
  localTime: displayDate(dayjs(date), DateFormat.LocalTime, zoneId),
  zoneId,
});

export const getLegDateTimeInput = (date: string | Dayjs, prefix: Leg): LegDateTimeInput => ({
  transactionDate: getTransactionDate(prefix),
  timestamp: dayjs(date).toISOString(),
});

export const convertLegDateTimeToTimeInput = ({timestamp, transactionDate}: LegDateTimeInput): LegTimeInput => ({
  transactionDate,
  zoneId: appTimezone,
  localTime: displayDate(timestamp, 'HH:mm:ss'),
});

function shouldReverseRfqAssets(assetPair: AssetPair, tradedCurrency: Currency) {
  return assetPair.base.currency !== tradedCurrency;
}

export type RfqAmount = {asset: Asset; quantity: [number, number?]};

export function getRfqAmounts({
  pair,
  spotRate,
  spotRangeBigFig,
  tradedAmount,
  tradedCurrency,
  side,
  inMillions,
}: {
  side: Side;
  pair: AssetPair;
  tradedCurrency: Currency;
  tradedAmount: number;
  spotRate: number;
  spotRangeBigFig: number;
  inMillions: boolean;
}): Record<'tradedRfqAmount' | 'counterRfqAmount' | 'sellingRfqAmount' | 'buyingRfqAmount', RfqAmount> {
  const isRepo = isRepoPair(pair);
  const tradedAmountInCents = convertQuantity(tradedAmount, To.Store, true, inMillions ? 1_000_000_00 : 1_00);
  const tradedAsset =
    isRepo ? pair.base
    : pair.base.currency === tradedCurrency ? pair.base
    : pair.second;
  const tradedRfqAmount: RfqAmount = {asset: tradedAsset, quantity: [tradedAmountInCents]};
  const shouldReverse = shouldReverseRfqAssets(pair, tradedCurrency);
  const counterRfqAmount: RfqAmount = {
    asset: getCounterAsset({baseAsset: pair.base, secondAsset: pair.second, tradedAsset}),
    quantity:
      isRepo ?
        [exactSecondAmount(tradedAmountInCents, 1.0 / (1 - REPO_HAIRCUT))]
      : [
          minSecondAmount(tradedAmountInCents, shouldReverse ? 1 / spotRate : spotRate, spotRangeBigFig),
          maxSecondAmount(tradedAmountInCents, shouldReverse ? 1 / spotRate : spotRate, spotRangeBigFig),
        ],
  };

  const rfqAmounts = {tradedRfqAmount, counterRfqAmount};
  const isSellBuy = side === 'SellBuy';

  const sellingRfqAmount =
    isSellBuy ?
      shouldReverse ? 'counterRfqAmount'
      : 'tradedRfqAmount'
    : shouldReverse ? 'tradedRfqAmount'
    : 'counterRfqAmount';

  const buyingRfqAmount =
    isSellBuy ?
      shouldReverse ? 'tradedRfqAmount'
      : 'counterRfqAmount'
    : shouldReverse ? 'counterRfqAmount'
    : 'tradedRfqAmount';

  return {
    ...rfqAmounts,
    get sellingRfqAmount() {
      return this[sellingRfqAmount];
    },
    get buyingRfqAmount() {
      return this[buyingRfqAmount];
    },
  };
}

export function displayRfqAmount({asset, quantity}: {asset: Asset; quantity: [number, number?]}, inMillions: boolean) {
  return quantity[1] !== undefined ?
      displayRangeWithCode({asset, quantity: quantity[0]}, {asset, quantity: quantity[1]}, inMillions)
    : displayAmountWithCode({asset, quantity: quantity[0]}, true, inMillions);
}

export function getCounterAmount({
  tradedCurrency,
  tradedAmount,
  exchangeRate,
  isRepo,
  assetPair,
  alwaysExact,
  spotRangeBigFig,
  inMillions,
}: {
  assetPair: AssetPair | undefined;
  tradedCurrency: Currency;
  tradedAmount: number | undefined;
  isRepo: boolean;
  exchangeRate: number;
  alwaysExact?: boolean;
  spotRangeBigFig: number;
  inMillions: boolean;
}): null | [number, number?] {
  if (!tradedAmount || !assetPair) return null;

  const shouldReverseExchangeRate = shouldReverseRfqAssets(assetPair, tradedCurrency);

  const tradedAmountInCents = convertQuantity(tradedAmount, To.Store, true, inMillions ? 1_000_000_00 : 1_00); // match Details precision

  if (isRepo || alwaysExact) {
    return [exactSecondAmount(tradedAmountInCents, shouldReverseExchangeRate ? 1 / exchangeRate : exchangeRate)];
  } else {
    return expectedRangeOfAmountExchanged(
      tradedAmountInCents,
      shouldReverseExchangeRate ? 1 / exchangeRate : exchangeRate,
      spotRangeBigFig
    );
  }
}

export function formatCounterAmountRange(range: [number, number?] | null, inMillions: boolean) {
  return range ? getTrimmedRange(range, 16, inMillions) : '';
}

function getTrimmedRange(range: [number, number?], maxChars: number = 16, inMillions: boolean) {
  if (range[1] === undefined) return String(convertQuantity(range[0], To.View, true, inMillions ? 1_000_000_00 : 1_00));

  // formatRange is called twice because maximumSignificantDigits would override minimumFractionDigits
  // see https://stackoverflow.com/questions/55834596
  const formattedWithFractionDigits = intlImperative.formatters
    .getNumberFormat(intlImperative.locale, {
      minimumFractionDigits: 2,
      useGrouping: false,
    })
    .formatRange(
      convertQuantity(range[0], To.View, true, inMillions ? 1_000_000_00 : 1_00),
      convertQuantity(range[1], To.View, true, inMillions ? 1_000_000_00 : 1_00)
    );

  if (formattedWithFractionDigits.length > maxChars) {
    return intlImperative.formatters
      .getNumberFormat(intlImperative.locale, {
        maximumSignificantDigits: 6,
        useGrouping: false,
      })
      .formatRange(
        convertQuantity(range[0], To.View, true, inMillions ? 1_000_000_00 : 1_00),
        convertQuantity(range[1], To.View, true, inMillions ? 1_000_000_00 : 1_00)
      );
  } else {
    return formattedWithFractionDigits;
  }
}

export function getCounterAsset({
  baseAsset,
  secondAsset,
  tradedAsset,
}: {
  baseAsset: Asset;
  secondAsset: Asset;
  tradedAsset: Asset;
}) {
  return tradedAsset.currency === baseAsset.currency ? secondAsset : baseAsset;
}

export const transactionDateToPrefix = (transactionDate: TransactionDate) =>
  transactionDate === TransactionDate.TPlusZero ? 'T+0' : 'T+1';

export const prefixToTransactionDate = (prefix: Leg) =>
  prefix === 'T+0' ? TransactionDate.TPlusZero : TransactionDate.TPlusOne;

export function getRFQAmountFieldLabel({
  assetPair,
  isRepo,
  tradedCurrency,
  side,
  labelFor,
}: {
  assetPair: AssetPair | undefined;
  isRepo: boolean;
  side: Side;
  tradedCurrency: Currency;
  labelFor: 'traded' | 'quote-traded' | 'counter';
}): MessageDescriptor {
  const isCounter = labelFor === 'counter';
  const isQuoteRequest = labelFor === 'quote-traded';

  if (isRepo) {
    return (
      isCounter ? fieldTitles.securitiesAmt
      : isQuoteRequest ? fieldTitles.quoteTradedAmt
      : fieldTitles.tradedAmt
    );
  } else {
    if (!assetPair || !shouldReverseRfqAssets(assetPair, tradedCurrency)) {
      if (side === 'BuySell') {
        return isCounter ? fieldTitles.youSell : fieldTitles.youBuy;
      } else {
        return isCounter ? fieldTitles.youBuy : fieldTitles.youSell;
      }
    } else {
      if (side === 'BuySell') {
        return isCounter ? fieldTitles.youBuy : fieldTitles.youSell;
      } else {
        return isCounter ? fieldTitles.youSell : fieldTitles.youBuy;
      }
    }
  }
}

export const prepareRFQLegsForRequest = (nearLegInput: 'ASAP' | dayjs.Dayjs | undefined, farFarInput: dayjs.Dayjs) => {
  const nearLeg = !nearLegInput || nearLegInput === 'ASAP' ? undefined : nearLegInput;
  const farLeg = !farFarInput ? dayjs() : farFarInput;
  return [nearLeg, farLeg] as const;
};

export const displayRFQRejectionReasons = (request: RFQRequest, responseOfRequestee: RequesteeAndQuote) => {
  const rejectionReasons = responseOfRequestee.stage.reasons;
  if (!rejectionReasons.length) return '-';

  return (
    <RejectionReasons key={displayParty(responseOfRequestee.requestee)}>
      {rejectionReasons.map(reason => (
        <div key={reason}>{getReasonText(request.requesteeSide, reason, request.baseAsset, request.secondAsset)}</div>
      ))}
    </RejectionReasons>
  );
};

export const isCreatedLessThan12HoursAgo = (request: RFQRequest) => {
  const now = dayjs().tz();
  const requestCreationTime = dayjs(request.createdAt).tz();
  const hoursDiff = now.diff(requestCreationTime, 'hours');

  return hoursDiff < 12;
};

export const determineTradedAssetForRequest = (assetPair: AssetPair, side: Side) => {
  switch (side) {
    case 'BuySell':
      return assetPair.base;
    case 'SellBuy':
    default:
      return assetPair.second;
  }
};

export const determineTradedAssetForQuote = (assetPair: AssetPair, side: Side) => {
  switch (side) {
    case 'BuySell':
      return assetPair.second;
    case 'SellBuy':
    default:
      return assetPair.base;
  }
};
