import {ReactElement, ReactNode, useCallback} from 'react';

import {useIntl} from 'react-intl';
import {Party, RequesteeAndQuote, RequesteeAndQuoteStage, RFQRequest} from 'types/api';
import {
  displayAmountWithCode,
  displayAmountWithUnit,
  displaySpotRangeBigfig,
  payingOrReceivingInterest,
} from 'utils/AmountUtils';
import {
  getInitialFXRate,
  getRequestStatusText,
  isFXSwapRequestType,
  counterAmountToString,
  displayRFQRejectionReasons,
} from 'utils/RFQUtils';
import {
  actualSpotRange,
  displayEntities,
  displayForwardPoints,
  displayInterestRate,
  displayParty,
  getRFC2253ValueByKey,
} from 'utils/utils';
import {displayLegPrefixAndDateTime, displayNearLegAsAsap, displayDate} from 'utils/DayjsUtils';

import {displaySide} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {fieldTitles, rfqRequestStatuses} from 'constants/messages/labels';

import Box from 'ui/Box';

interface DetailsTableColPair {
  title: string;
  value: ReactNode | ReactElement;
  uppercase?: boolean;
}

type DetailsTableRow = [DetailsTableColPair, DetailsTableColPair?];
interface UseRequestTableRowsType {
  getRequestDetailsRows: (request: RFQRequest, isRepo: boolean) => Map<string, DetailsTableRow>;
}

const useRequestTableRows = (legalEntities: Party[]): UseRequestTableRowsType => {
  const {formatMessage} = useIntl();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const getRequestDetailsRows = useCallback(
    (request: RFQRequest, isRepo: boolean) => {
      const inMillions = shouldDisplayInMillions({base: request.baseAsset, second: request.secondAsset});

      // Define counterparties
      const counterpartiesEntities: Party[] =
        legalEntities.map(x => x.legalName).includes(request.requestor.legalName) ?
          request.requesteesAndQuotes.map((requesteeAndQuote: RequesteeAndQuote) => requesteeAndQuote.requestee)
        : [request.requestor];

      // Define user side
      const side = request.isReceived ? request.requesteeSide : request.requestorSide;

      // Calculate filled value for the request
      const filled = request.requesteesAndQuotes.reduce(
        (acc, requesteeAndQuote) =>
          requesteeAndQuote.stage.name === RequesteeAndQuoteStage.QuoteAccepted ?
            acc + (requesteeAndQuote.quote?.tradedAmount.quantity || 0)
          : acc,
        0
      );
      // Create Filled amount
      const filledAmount = {
        quantity: filled,
        asset: request.tradedAmount.asset,
      };
      // Define spotRangeAct value
      const spotRangeAct =
        isFXSwapRequestType(request.requestType) ?
          actualSpotRange(request.requestType.initialFXRate, request.requestType.spotRange)
        : null;

      // Define Entity
      const requestees = request.requesteesAndQuotes.map(r => r.requestee.legalName);
      const entityLegalName =
        request.isReceived ?
          legalEntities.find(e => requestees.includes(e.legalName))?.legalName || ''
        : request.requestor.legalName;

      // key format guide: first row And second row (or Blank)
      const requestRows = new Map<string, DetailsTableRow>();

      requestRows.set('RequestIdAndStatus', [
        {
          title: formatMessage(fieldTitles.uniqueRequestId),
          value: request.id,
        },
        {
          title: formatMessage(fieldTitles.status),
          value: formatMessage(rfqRequestStatuses[getRequestStatusText(request)]),
        },
      ]);
      requestRows.set('YourSideAndImpYieldOrRate', [
        {
          title: formatMessage(fieldTitles.yourSide),
          value: displaySide(side, isRepo),
        },
        {
          title: isRepo ? formatMessage(fieldTitles.suggestedRate) : formatMessage(fieldTitles.suggestedImpYield),
          value: displayInterestRate(request.suggestedInterestRate),
        },
      ]);
      requestRows.set('SentOrReceivedAndFwdPoints', [
        {
          title: formatMessage(fieldTitles.sentReceived),
          value: request.isReceived ? 'RECEIVED' : 'SENT',
        },
        {
          title: isRepo ? '' : formatMessage(fieldTitles.suggestedFwdPts), // empty for Repo
          value: isRepo ? '' : displayForwardPoints(request.suggestedForwardPoints), // empty for Repo
        },
      ]);
      requestRows.set('CounterpartyAndInterestSide', [
        {
          title: formatMessage(fieldTitles.counterparty),
          value: displayEntities(counterpartiesEntities),
        },
        {
          title: formatMessage(fieldTitles.interestSide),
          value: payingOrReceivingInterest(side, request.suggestedInterestRate as number),
        },
      ]);
      requestRows.set('RequestAmountAndCreateDate', [
        {
          title: formatMessage(fieldTitles.tradedAmt),
          value: displayAmountWithUnit(request.tradedAmount, inMillions),
        },
        {
          title: formatMessage(fieldTitles.creationDate),
          value: displayDate(request.createdAt, 'DD MMM YYYY'),
        },
      ]);
      requestRows.set('CounterAmountAndCreatedTime', [
        {
          title: formatMessage(isRepo ? fieldTitles.securitiesAmt : fieldTitles.counterAmt),
          value:
            request ?
              counterAmountToString(
                request.tradedAmount,
                request.requestType,
                request.baseAsset,
                request.secondAsset,
                isRepo,
                inMillions
              )
            : '',
        },
        {
          title: formatMessage(fieldTitles.quoteCreatedAt),
          value: displayDate(request.createdAt, 'HH:mm:ss'),
        },
      ]);
      requestRows.set('FilledAndNearLegDate', [
        {
          title: formatMessage(fieldTitles.filled),
          value: displayAmountWithCode(filledAmount, false, inMillions),
        },
        {
          title: isRepo ? formatMessage(fieldTitles.onLegDate) : formatMessage(fieldTitles.nearLegDate),
          value: request ? displayDate(request.nearLegTime ?? request.createdAt, 'DD MMM YYYY') : '',
        },
      ]);
      requestRows.set('InitialXRAndNearLegTime', [
        {
          // For repo show haircut
          // For FX-swap show initial rate
          title: isRepo ? formatMessage(fieldTitles.haircut) : formatMessage(fieldTitles.initialRate),
          value: isRepo ? 'Assumed 10%' : getInitialFXRate(request.requestType)?.toString() ?? '--',
        },
        {
          title: isRepo ? formatMessage(fieldTitles.onLegTime) : formatMessage(fieldTitles.nearLegTime),
          value:
            request.nearLegTime ?
              displayLegPrefixAndDateTime(request.nearLegTime, request.nearLegTimeInput?.transactionDate, 'HH:mm')
            : displayNearLegAsAsap(),
        },
      ]);
      requestRows.set('SpotRangeAndFarLegDate', [
        {
          // Empty for repo
          title: isRepo ? '' : formatMessage(fieldTitles.spotRange),
          value:
            isRepo || !request || !('spotRange' in request.requestType) ?
              ''
            : displaySpotRangeBigfig(request.requestType.spotRange),
        },
        {
          title: isRepo ? formatMessage(fieldTitles.offLegDate) : formatMessage(fieldTitles.farLegDate),
          value: displayDate(request.maturityTime, 'DD MMM YYYY'),
        },
      ]);
      requestRows.set('SpotRangeACTAndFarLegTime', [
        {
          // Empty for repo
          title: isRepo ? '' : formatMessage(fieldTitles.spotRangeAct),
          value: isRepo || !spotRangeAct ? '' : `${spotRangeAct.min.toFixed(4)} - ${spotRangeAct.max.toFixed(4)}`,
        },
        {
          title: isRepo ? formatMessage(fieldTitles.offLegTime) : formatMessage(fieldTitles.farLegTime),
          value: displayLegPrefixAndDateTime(request.maturityTime, request.maturityTimeInput.transactionDate, 'HH:mm'),
        },
      ]);
      requestRows.set('EntityAndBlank', [
        {
          title: formatMessage(fieldTitles.entity),
          value: getRFC2253ValueByKey(entityLegalName, 'O'),
        },
      ]);
      requestRows.set('TraderInfoAndRejectionReasons', [
        {
          title: formatMessage(fieldTitles.traderInfo),
          value: request.userId,
          uppercase: false,
        },
        {
          title: formatMessage(fieldTitles.rejectionReason),
          value: (
            <Box display='flex' flexDirection='column'>
              {request.requesteesAndQuotes.length ?
                request.requesteesAndQuotes.map(responseOfRequestee => {
                  const partyName = displayParty(responseOfRequestee.requestee);
                  const reasons = displayRFQRejectionReasons(request, responseOfRequestee);
                  return (
                    <span key={partyName}>
                      {partyName} : {reasons}
                    </span>
                  );
                })
              : 'N/A'}
            </Box>
          ),
        },
      ]);
      return requestRows;
    },
    [formatMessage, legalEntities]
  );

  return {getRequestDetailsRows};
};

export default useRequestTableRows;
