export type FiltersToggleProps = {
  open: boolean;
  filtersApplied: boolean;
};

export enum TradesFilters {
  OutstandingStatus = 'OutstandingStatus',
  FlaggedAsNonReceipt = 'FlaggedAsNonReceipt',
  SentLateFeeProposalWithoutResponse = 'SentLateFeeProposalWithoutResponse',
  SentAndRejectedLateFeeProposal = 'SentAndRejectedLateFeeProposal',
  ReceivedLateFeeProposalWithoutResponse = 'ReceivedLateFeeProposalWithoutResponse',
}
