import styled from 'styled-components';

import {TooltipProps} from './types';

export const TooltipContainer = styled.span`
  display: inline-block;
  width: 100%;
  flex-basis: fit-content;
  z-index: 9;
`;

export const DarkTooltip = styled.div<TooltipProps>`
  position: absolute;
  display: inline-block;
  background-color: ${p => p.theme.colors.gray500};
  padding: 4px 0.8em;
  font-size: 12px;
  z-index: 99;
  line-height: 1.3;
  ${props => {
    switch (props.position) {
      case 'top':
        return `::after {
                    content: "";
                    position: absolute;
                    left: calc(50% - 18px);
                    right: auto;
                    top: auto;
                    bottom: -20px;
                    width: 0;
                    height: 0;
                    border-left: 18px solid transparent;
                    border-right: 18px solid transparent;
                    border-top: 10px solid ${props.theme.colors.gray500};
                    border-bottom: 10px solid transparent;
                    clear: both;
                }
                `;

      case 'right':
        return `::after {
                    content: "";
                    position: absolute;
                    left: -24px;
                    right: 0;
                    top: calc(50% - 10px);
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid ${props.theme.colors.gray500};
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    clear: both;
                }`;

      case 'bottom':
        return `::after {
                    content: "";
                    position: absolute;
                    left: calc(50% - 18px);
                    right: auto;
                    bottom: auto;
                    top: -20px;
                    width: 0;
                    height: 0;
                    border-left: 18px solid transparent;
                    border-right: 18px solid transparent;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid ${props.theme.colors.gray500};
                    clear: both;
                }`;

      default:
        return `::after {
                    content: "";
                    position: absolute;
                    left: auto;
                    right: -24px;
                    top: calc(50% - 10px);
                    width: 0;
                    height: 0;
                    border-left: 12px solid ${props.theme.colors.gray500};
                    border-right: 12px solid transparent;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    clear: both;
                }`;
    }
  }}
`;

export const LightTooltip = styled.div<TooltipProps>`
  position: absolute;
  display: inline-block;
  z-index: 99;
  background: ${p => p.theme.colors.blue50};
  color: ${p => p.theme.colors.white};
  font-size: 10px;
  padding: 4px 6px;
  ::after {
    content: '';
    position: absolute;
    left: 0;
    right: auto;
    width: 0;
    height: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    ${props => {
      switch (props.position) {
        case 'top':
          return `
                top: auto;
                bottom: -12px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 6px solid ${props.theme.colors.blue50};
                border-bottom: 6px solid transparent;
                `;

        case 'right':
          return `
                left: -14px;
                right: auto;
                border-left: 8px solid transparent;
                border-right: 6px solid ${props.theme.colors.blue50};
                border-top: 8px solid transparent;
                border-bottom: 6px solid transparent;
                `;

        case 'bottom':
          return `
                top: -12px;
                bottom: auto;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 6px solid transparent;
                border-bottom:6px solid ${props.theme.colors.blue50};
                `;

        default:
          return `
                right: -14px;
                left: auto;
                border-left: 6px solid ${props.theme.colors.blue50};
                border-right: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-top: 6px solid transparent;
                `;
      }
    }}
    clear: both;
  }
`;
