import {FC} from 'react';

import {displayAmountWithCode, displayAmountWithUnit} from 'utils/AmountUtils';
import {displayInterestRate} from 'utils/utils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {SCol, SRow} from 'ui/Layout/styles';

import {RelatedOrderProps} from '../../types';

const RelatedOrder: FC<RelatedOrderProps> = ({relatedOrder, inMillions}: RelatedOrderProps) => (
  <SRow className='details-popup__related-order'>
    <SCol width={{md: 1}}>
      <table>
        <thead>
          <tr>
            <td>Unique Order ID</td>
            <td>Amount</td>
            <td>Filled</td>
            <td>Imp Yld / Rate</td>
            <td>Start Time</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{relatedOrder.id}</td>
            <td>{displayAmountWithUnit(relatedOrder.baseAmount, inMillions)}</td>
            <td>{displayAmountWithCode(relatedOrder.filled, false, inMillions)}</td>
            <td>{displayInterestRate(relatedOrder.impliedYield)}</td>
            <td>{displayDate(relatedOrder.createdAt, DateFormat.FullWithZone)}</td>
          </tr>
        </tbody>
      </table>
    </SCol>
  </SRow>
);

export default RelatedOrder;
