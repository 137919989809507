import chroma from 'chroma-js';

import {Asset} from 'types/api';

import colors from 'constants/colors';

const isNotBaseCurrency = (asset: Asset) => {
  const isBaseCurrency = ['USD', 'EUR', 'GBP', 'CAD'].includes(asset.currency);

  return !isBaseCurrency || (isBaseCurrency && (asset.type !== 'Cash' || !!asset.holder));
};

function getRequestColor(asset: Asset) {
  switch (asset.currency) {
    case 'EUR':
      return colors.blue300;
    case 'USD':
      return colors.yellow350;
    case 'GBP':
      return colors.red500;
    default:
      return colors.green500;
  }
}

function correctColorContrast(color: string): string {
  switch (color) {
    case '#5e4fa2':
      return '#7d6bc2';
    case '#9e0142':
      return '#d1446a';
    default:
      return color;
  }
}

export function getAssetColors(assets: Asset[]) {
  const balancesWithoutCommonCurrencies = assets.filter(isNotBaseCurrency);

  // RdBu
  const palette = chroma.scale('Spectral').colors(balancesWithoutCommonCurrencies.length);
  let nextColor = 0;

  return assets.map(asset =>
    isNotBaseCurrency(asset) ? correctColorContrast(palette[nextColor++]) : getRequestColor(asset)
  );
}
