import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';
import {isRepoPair} from 'utils/AssetUtils';

import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';

import {SCol, SContainer, SRow} from 'ui/Layout/styles';
import {useQuoteTableRows} from 'components/popups/RFQOverviewPopup/components/RFQDetails/hooks/useQuoteTableRows';

interface Props {
  requestId: string;
  quoteId: string;
}

const QuoteDetailsTable = ({requestId, quoteId}: Props) => {
  const {selectRfqById} = useRealTimeRequestsQuery();
  const request = selectRfqById(requestId);
  const {getQuoteDetailsRows} = useQuoteTableRows();

  if (!request) {
    return null;
  }

  // Find corresponding quote in the request.
  const requesteeAndQuote = request?.requesteesAndQuotes.find(r => r.quote?.id === quoteId);

  // Define if is repo.
  const isRepo: boolean = isRepoPair({base: request.baseAsset, second: request.secondAsset});

  const detailsTableRows = getQuoteDetailsRows(request, isRepo, requesteeAndQuote);

  return (
    <section className='table'>
      <SRow className='details-popup__table'>
        <SCol width={1}>
          <SContainer>
            {detailsTableRows.map(([left, right], idx) => (
              <SRow key={idx}>
                <SCol width={1 / 4} className='details-popup__label'>
                  <FieldTitle data-testid='field-title'>{left.title}</FieldTitle>
                </SCol>
                <SCol width={1 / 4} className='details-popup__value'>
                  <FieldValue data-testid='field-value' uppercase={left.uppercase}>{left.value}</FieldValue>
                </SCol>
                <SCol width={1 / 4} className='details-popup__label'>
                  <FieldTitle data-testid='field-title'>{right?.title}</FieldTitle>
                </SCol>
                <SCol width={1 / 4} className='details-popup__value'>
                  <FieldValue data-testid='field-value' uppercase={right?.uppercase}>{right?.value}</FieldValue>
                </SCol>
              </SRow>
            ))}
          </SContainer>
        </SCol>
      </SRow>
    </section>
  );
};

export default QuoteDetailsTable;
