import {useEffect} from 'react';
import dayjs from 'dayjs';

import useNotificationsReadDataStore from 'stores/useNotificationsReadDataStore';

import {useUser} from 'contexts/auth-context';

import useNotificationsQuery from 'api/hooks/useNotificationsQuery';

import {Scope} from 'constants/permission-maps';
import {SCol, SContainer, SRow} from 'ui/Layout/styles';

import NotificationCenterHeader from 'components/NotificationCenter/components/NotificationCenterHeader';
import ConnectedNotificationTable from 'components/NotificationCenter';
import PermissionsGate from 'components/PermissionGate';

import './Notifications.scss';

const NotificationCenterPage = () => {
  const {markAllAsRead} = useNotificationsQuery();
  const {updateLastNotificationCenterOpenedTime} = useNotificationsReadDataStore();
  const {user} = useUser();

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateLastNotificationCenterOpenedTime(dayjs().unix());
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [user.id, updateLastNotificationCenterOpenedTime]);

  return (
    <SContainer className='NotificationCenterPage' fluid>
      <SRow height='100%' alignContent='center'>
        <SCol width={[1]}>
          <NotificationCenterHeader markAsReadCallback={markAllAsRead} />

          <PermissionsGate scopes={[Scope.ViewNotifications]}>
            <SRow height='calc(100% - 73px)' flexGrow='1' position='relative'>
              <SCol position='absolute' style={{top: 0, bottom: 0, right: 0, left: 0}}>
                <ConnectedNotificationTable isNotificationClickable={false} />
              </SCol>
            </SRow>
          </PermissionsGate>
        </SCol>
      </SRow>
    </SContainer>
  );
};

export default NotificationCenterPage;
