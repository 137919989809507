import useRFQMarketsQuery from 'api/hooks/useRFQMarketsQuery';
import dayjs, {Dayjs} from 'dayjs';
import {filter, isEqual} from 'lodash';
import {AssetPair, TransactionDate} from 'types/api';
import {getUpcomingMarketDays} from 'utils/DayjsUtils';
import {transactionDateToPrefix} from 'utils/RFQUtils';
import {appTimezone} from 'utils/setupDayjs';

export default function useDisplayLegPrefixWithDate(assetPair: AssetPair) {
  const {marketDays: allMarketDays} = useRFQMarketsQuery();
  const assetPairMarketDays = filter([...allMarketDays], day => isEqual(assetPair, day.assetPair));
  const [marketTPlusZeroDay, marketTPlusOneDay] = getUpcomingMarketDays(assetPairMarketDays);

  return (target: Dayjs | string | undefined, legPrefix: TransactionDate | undefined) => {
    if (assetPairMarketDays.length === 0) return '';
    const marketDay = legPrefix === TransactionDate.TPlusOne ? marketTPlusOneDay : marketTPlusZeroDay;
    const targetDate = dayjs(target).utc();
    return `${transactionDateToPrefix(legPrefix || TransactionDate.TPlusZero)} - ${dayjs(marketDay?.startsAt || target)
      .utc()
      .set('minutes', targetDate.minute())
      .set('hours', targetDate.hour())
      .tz(appTimezone)
      .format('ddd DD MMM YYYY HH:mm')}`;
  };
}
