import {useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {AxiosError, AxiosResponse} from 'axios';

import {useUser} from 'contexts/auth-context';

import {ErrorResponse} from 'types/api';
import {HistoryType} from 'types/blotter';

import {downloadFile} from 'utils/utils';
import {showErrorToast} from 'utils/ToastUtils';
import {roundToInterval} from 'utils/DayjsUtils';

import {
  getOrdersActivityReport,
  getRFQQuotesActivityReport,
  getRFQRequestsActivityReport,
  getTradesActivityReport,
} from 'api/api';

import {SAnalyticsContainer} from 'pages/AnalyticsPage/styles';

import {Scope} from 'constants/permission-maps';

import Button from 'ui/Button';
import DateTimeField from 'ui/DateTimeField';
import FieldTitle from 'ui/FieldTitle';
import SelectField, {Item} from 'ui/SelectField';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';
import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import {SFormField, SFormItem, SFormLabel, SFormLayout} from './styles';

type ItemWithReportHandler<T> = Item<T> & {
  reportHandler: (startTime: Dayjs, endTime: Dayjs) => Promise<AxiosResponse>;
};

const activities: ItemWithReportHandler<HistoryType>[] = [
  {value: HistoryType.Trades, label: 'Trades', reportHandler: getTradesActivityReport},
  {value: HistoryType.Orders, label: 'Orders', reportHandler: getOrdersActivityReport},
  {value: HistoryType.Quotes, label: 'Quotes', reportHandler: getRFQQuotesActivityReport},
  {value: HistoryType.Requests, label: 'Requests', reportHandler: getRFQRequestsActivityReport},
];

export type FrontOfficeActivityReportsProps = {
  fromTime?: Dayjs | string;
  toTime?: Dayjs | string;
};
const FrontOfficeActivityReports = ({fromTime, toTime}: FrontOfficeActivityReportsProps) => {
  const userWithMeta = useUser();
  const permissions = getRolePermissions(userWithMeta.user?.role);
  const [activity, setActivity] = useState<ItemWithReportHandler<HistoryType> | undefined>(undefined);
  const [startTime, setStartTime] = useState<Dayjs>(roundToInterval(dayjs(fromTime)).subtract(1, 'day'));
  const [endTime, setEndTime] = useState<Dayjs>(roundToInterval(dayjs(toTime)));

  const isFormDisabled = !hasPermission({permissions, scopes: [Scope.ViewAnalytics]});
  const isDatePickersInvalid = endTime.isBefore(startTime);
  const isShowButtonDisabled = !activity || isDatePickersInvalid;

  const downloadReport = () => {
    activity
      ?.reportHandler(startTime, endTime)
      .then((response: AxiosResponse) => downloadFile(response))
      .catch((error: AxiosError<ErrorResponse>) => {
        showErrorToast(error);
      });
  };

  return (
    <SAnalyticsContainer>
      <BoardHeader title='Front Office Activity Reports' />
      <SFormLayout>
        <SFormItem>
          <SFormLabel>
            <FieldTitle>Activity</FieldTitle>
          </SFormLabel>
          <SFormField>
            <SelectField
              data-testid='activity-dropdown'
              disabled={isFormDisabled}
              items={activities}
              value={activity}
              onChange={item => setActivity(activities.find(a => a.value === item.value))}
              required
            />
          </SFormField>
        </SFormItem>
        <SFormItem>
          <SFormLabel>
            <FieldTitle>Start Time</FieldTitle>
          </SFormLabel>
          <SFormField>
            <DateTimeField
              invalid={isDatePickersInvalid}
              disabled={isFormDisabled}
              value={startTime}
              onChange={date => date && setStartTime(date)}
              required
              showTimeSelect
            />
          </SFormField>
        </SFormItem>
        <SFormItem>
          <SFormLabel>
            <FieldTitle>End Time</FieldTitle>
          </SFormLabel>
          <SFormField>
            <DateTimeField
              invalid={isDatePickersInvalid}
              disabled={isFormDisabled}
              value={endTime}
              onChange={date => date && setEndTime(date)}
              required
              showTimeSelect
            />
          </SFormField>
        </SFormItem>
        <Button
          data-testid='download-report-btn'
          buttonStyle='primary'
          disabled={isFormDisabled || isShowButtonDisabled}
          onClick={() => downloadReport()}
        >
          Download CSV Report
        </Button>
      </SFormLayout>
    </SAnalyticsContainer>
  );
};

export default FrontOfficeActivityReports;
