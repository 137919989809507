import {useMemo} from 'react';

import dayjs from 'dayjs';

import {TradeRelatedLateSettlementFee, TradeStatus} from 'types/api';

import {useUser} from 'contexts/auth-context';

import {getTradeSettlementTimeWithDeadLine, getUserLateFeeRequestsByStatusForLegType} from 'utils/TradeUtils';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import Button from 'ui/Button';
import {RequestLateSettlementFeePopup} from 'components/popups/LateSettlementFeePopup/Popups/RequestLateSettlementFeePopup';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {RequestLateSettlementFeePopupProps} from 'components/popups/LateSettlementFeePopup/types';

import {LateFeeButtonProps} from './types';

export const buttonInlineStyle = {padding: '0.2rem 1rem'};

const MarkAsLateButton = ({trade, legType, disabled}: LateFeeButtonProps) => {
  const user = useUser();
  const permissions = getRolePermissions(user.user?.role);
  const {openModal} = useModal();
  const settlementTimeWithDeadline = getTradeSettlementTimeWithDeadLine(trade, legType);
  const currentTime = dayjs();

  const requestedPendingLateFeeRequest: TradeRelatedLateSettlementFee | undefined = useMemo(
    () => getUserLateFeeRequestsByStatusForLegType(trade, user, 'Pending', 'requestor', legType)[0],
    [trade, legType, user]
  );

  const isButtonDisabled = useMemo(() => {
    if (!hasPermission({permissions, scopes: [Scope.ManageLateFees]})) return true;
    return (
      !settlementTimeWithDeadline.isValid() ||
      ([TradeStatus.Outstanding, TradeStatus.Upcoming, TradeStatus.Processing].includes(trade?.status) &&
        currentTime.isBefore(settlementTimeWithDeadline)) ||
      disabled
    );
  }, [permissions, disabled]);

  const onClick = () => {
    openModal({
      modal: RequestLateSettlementFeePopup,
      props: {
        tradeId: trade.id,
        legType,
        lateFee: requestedPendingLateFeeRequest,
      } as RequestLateSettlementFeePopupProps,
    });
  };

  return (
    <Button
      onClick={onClick}
      style={buttonInlineStyle}
      buttonStyle={isButtonDisabled ? 'grey' : 'secondary'}
      disabled={isButtonDisabled}
      data-testid={`mark-as-late-btn-${legType.toLowerCase()}`}
    >
      {requestedPendingLateFeeRequest ? 'Withdraw Fee' : 'Mark as Late'}
    </Button>
  );
};

export default MarkAsLateButton;
