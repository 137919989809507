import {DetailsTabType} from 'types/layout';

import {Tab} from 'ui/Tabs/types';

export const confirmationTabs: Tab[] = [
  {
    label: 'Details',
    value: 'details',
    'data-testid': 'tab-details',
  },
  {
    label: 'Economics',
    value: 'economics',
    'data-testid': 'tab-economics',
  },
];

export const fxSwapTabs: Tab[] = [
  {
    label: 'SELL/BUY',
    value: 'SellBuy',
    className: 'sell-buy-tab',
  },
  {
    label: 'BUY/SELL',
    value: 'BuySell',
    className: 'buy-sell-tab',
  },
];

export const repoTabs: Tab[] = [
  {
    label: 'BUY',
    value: 'SellBuy',
    className: 'sell-buy-tab',
  },
  {
    label: 'SELL',
    value: 'BuySell',
    className: 'buy-sell-tab',
  },
];

export const RFQTabs: Tab[] = [
  {
    label: DetailsTabType.Details,
    value: 'details',
  },
  {
    label: DetailsTabType.RelatedTrades,
    value: 'trades',
  },
];
