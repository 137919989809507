import {FC} from 'react';

import {formatRate} from 'utils/utils';

import {SSpotRateContainer} from './styles';

export interface Props {
  spotRate: number;
  error: boolean;
}

const SpotRate: FC<Props> = (props: Props) => (
  <SSpotRateContainer>
    {props.error ?
      <span className='spot-rate--error'>Sorry, we were not able to connect to the FX rates server</span>
    : <span className='spot-rate'>
        {formatRate(props.spotRate)[0]}
        <span className='spot-rate--middle'>{formatRate(props.spotRate)[1]}</span>
        {formatRate(props.spotRate)[2]}
      </span>
    }
  </SSpotRateContainer>
);

export default SpotRate;
