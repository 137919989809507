import {useState} from 'react';

import {ReferenceData} from 'types/layout';

import {useIntl} from 'react-intl';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {Scope} from 'constants/permission-maps';

import {globalDisableReferenceDataSwitch} from 'constants/app_defaults';
import {fieldTitles} from 'constants/messages/labels';

import {RadioSwitcher} from 'ui/Switcher';

import PermissionsGate from '../PermissionGate';

export const RefSwitch = () => {
  const {referenceData, setReferenceData} = useReferenceDataStore();
  const [selectedOption, setSelectedOption] = useState(referenceData || ReferenceData.ImpliedYield);
  // i18n
  const {formatMessage} = useIntl();

  const changeReferenceData = (newValue: ReferenceData): void => {
    setSelectedOption(newValue);
    setReferenceData(newValue);
  };

  const handleSwitchChange = (): void => {
    const newValue: ReferenceData =
      selectedOption === ReferenceData.ImpliedYield ? ReferenceData.ForwardPoints : ReferenceData.ImpliedYield;
    changeReferenceData(newValue);
  };

  return (
    <PermissionsGate scopes={[Scope.ViewClob]}>
      <RadioSwitcher
        disabled={globalDisableReferenceDataSwitch}
        onChange={handleSwitchChange}
        leftLabel={formatMessage(fieldTitles.impYield)}
        leftValue={ReferenceData.ImpliedYield}
        rightLabel={formatMessage(fieldTitles.fwdPts)}
        rightValue={ReferenceData.ForwardPoints}
        value={selectedOption}
      />
    </PermissionsGate>
  );
};
