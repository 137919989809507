import useErrorLogsStore from 'stores/useErrorLogsStore';

import Badge from 'ui/Badge';

interface SignOutProps {
  helpCallback(): void;
}

const Help = ({helpCallback}: SignOutProps) => {
  const {logs} = useErrorLogsStore();

  return (
    <div
      id='help'
      data-testid='help-icon'
      style={{position: 'relative'}}
      className='help-button'
      onClick={helpCallback}
    >
      <i className='la la-question-circle icon' />
      <Badge top='-6px' right='-6px' num={logs.length} />
    </div>
  );
};

export default Help;
