import {ReactElement} from 'react';
import './Notifications.scss';

export interface NotificationProps {
  msg: string;
  type: string;
}

const notification: (props: NotificationProps) => ReactElement = (props: NotificationProps): ReactElement => {
  let messageType: string;
  let image: ReactElement;

  switch (props.type) {
    case 'success':
      messageType = 'toast-msg toast-msg-success';
      // eslint-disable-next-line
      image = <i className='la la-check-circle toast-img toast-img-success'></i>;
      break;
    case 'error':
      messageType = 'toast-msg toast-msg-error';
      // eslint-disable-next-line
      image = <i className='la la-close toast-img toast-img-error'></i>;
      break;
    case 'warning':
      messageType = 'toast-msg toast-msg-warning';
      // eslint-disable-next-line
      image = <i className='la la-warning toast-img toast-img-warning'></i>;
      break;
    case 'info':
      messageType = 'toast-msg toast-msg-info';
      // eslint-disable-next-line
      image = <i className='la la-info-circle toast-img toast-img-info'></i>;
      break;
    default:
      messageType = 'toast-msg toast-msg-warning';
      // eslint-disable-next-line
      image = <i className='la la-warning toast-img toast-img-warning'></i>;
      break;
  }

  return (
    <div>
      {image}
      <div data-testid='toast-msg' className={messageType}>
        {props.msg}
      </div>
    </div>
  );
};

export default notification;
