import {useSetupFlash} from 'utils/AnimationUtils';

import {CheckBoxProps} from './types';

import {SIconContainer, SCheckboxContainer, SHiddenCheckbox} from './styles';

export const CheckBox = ({checked = false, onChange, disabled, value, ...props}: CheckBoxProps) => {
  const {isFlashing} = useSetupFlash(props.flashKey);

  return (
    <SCheckboxContainer role='checkbox' data-testid={props['data-testid']}>
      <SHiddenCheckbox checked={checked} disabled={disabled} onChange={onChange} value={value} />
      <SIconContainer disabled={disabled} flash={isFlashing}>
        {checked && <i className='la la-solid la-check' />}
      </SIconContainer>
    </SCheckboxContainer>
  );
};
