export const STATUS_ICON_DICT = {
  'la-clock-o': ['Pending', 'Upcoming', 'Processing', 'PendingMaturity', 'LateFeeRequested', 'LateFeeAccepted'],
  'la-check-circle': ['Live', 'Fulfilled', 'Outstanding', 'Matured'],
  'la-minus-circle': ['Cancelled'],
  'la-times-circle': ['Invalid', 'AnnulRequested', 'Annulled', 'AnnulRejected', 'AnnulExpired', 'AnnulCancelled'],
  'la-pause-circle': ['Paused'],
  'la-reply-all': ['UnwindRequested', 'Unwind', 'UnwindRejected', 'UnwindExpired', 'UnwindCancelled'],
  'la-exclamation-triangle': ['EarlyMaturityRequested', 'EarlyMaturityAccepted'],
};

export const STATUS_COLOR_DICT = {
  green: ['Live', 'Outstanding', 'Unwind', 'Annulled', 'EarlyMaturityAccepted', 'LateFeeAccepted'],
  yellow: [
    'Pending',
    'Upcoming',
    'Processing',
    'PendingMaturity',
    'UnwindRequested',
    'AnnulRequested',
    'EarlyMaturityRequested',
    'LateFeeRequested',
  ],
  blue: ['Fulfilled', 'Matured', 'Invalid', 'Paused'],
  red: [
    'AnnulRejected',
    'AnnulExpired',
    'AnnulCancelled',
    'UnwindCancelled',
    'UnwindExpired',
    'UnwindRejected',
    'Cancelled',
  ],
};
