import {Tab} from 'ui/Tabs/types';

import {Trade} from './api';

export enum LayoutPath {
  ChartView = '/clob/chart-view/:first/:second',
  ClobView = '/clob',
  CapacityView = '/capacity',
  CapacityManagerView = '/capacity/manage',
  RfqView = '/rfq',
  AnalyticsView = '/analytics/*',
}

export enum ReferenceData {
  ImpliedYield = 'ImpliedYield',
  ForwardPoints = 'ForwardPoints',
}

export enum EquivCurrency {
  UsdEquiv = 'usdEquiv',
  OrigCcy = 'origCcy',
}

export enum RateToShow {
  BestRate = 'bestRate',
  CustomAmount = 'customAmount',
}

export enum BlotterPosition {
  Expanded = 'expanded',
  Minified = 'minified',
  Default = 'default',
}

export enum DetailsTabType {
  Details = 'Details',
  CashFlows = 'Cash Flows',
  RelatedTrades = 'Related Trades',
  RelatedOrder = 'Related Order',
  Events = 'Events',
}

export type RecentlyOpenTradePopup = {
  id: Trade['id'];
  tab: Tab;
};
