import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  width: 99%;
  height: 99%;
  padding: 10px 20px 20px;
`;
