import {useIntl} from 'react-intl';
import {useFormContext} from 'react-hook-form';

import {To, calculateSecondAmount, convertQuantityToAmount, currencySymbol} from 'utils/AmountUtils';

import {Amount} from 'types/api';

import {useCallback} from 'react';

import {useSetupFlash} from 'utils/AnimationUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {orderFormLimits} from 'constants/orderForm';
import {fieldTitles} from 'constants/messages/labels';
import FieldTitle from 'ui/FieldTitle';
import {FormAmountField} from 'ui/AmountField';
import {OrderFormValues} from 'containers/OrderForm/hooks/useOrderForm';
import {FormRow, Label, Field} from 'containers/RFQForm/styles';
import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';

interface Props {
  isRepo: boolean;
  flashLabelId: string;
  flashFieldId: string;
  triggerFlash: () => void;
  disabled?: boolean;
}

export default function BaseAmountRow({isRepo, flashLabelId, flashFieldId}: Props) {
  const {formatMessage} = useIntl();
  const {isFlashing} = useSetupFlash(flashFieldId);

  const methods = useFormContext<OrderFormValues>();
  const [assetPair, baseAmount, side, requestType] = methods.watch(['assetPair', 'baseAmount', 'side', 'requestType']);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);

  // Works like sideEffect to onChange
  const updateSecondAmount = useCallback(
    (newBaseAmount: number) => {
      if (!requestType) return;
      const newSecondAmount: number | undefined =
        newBaseAmount && calculateSecondAmount(side, requestType, newBaseAmount);
      methods.setValue('secondAmount', newSecondAmount, {shouldValidate: true});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestType, side]
  );

  const getOrderAmount = (): Amount | undefined => {
    if (side === 'SellBuy' && baseAmount) {
      return convertQuantityToAmount(baseAmount, To.Store, assetPair.base, true, inMillions);
    }
  };

  const {show: showAvailableCapacityWarning, message} = useAvailableCapacityWarning('Order', getOrderAmount());
  const baseAmountFieldWarning = side === 'SellBuy' && showAvailableCapacityWarning;

  return (
    <FormRow>
      <Label>
        <FieldTitle key={1} flashKey={flashLabelId}>
          {formatMessage(isRepo ? fieldTitles.repoAmt : fieldTitles.baseAmt)}
        </FieldTitle>
      </Label>
      <Field>
        <FormAmountField
          name='baseAmount'
          onChange={updateSecondAmount}
          data-testid='base-amt-field'
          min={orderFormLimits.amount.min}
          max={orderFormLimits.amount.max}
          unit={inMillions ? 'm' : undefined}
          decimals={orderFormLimits.amount.dec}
          prefix={currencySymbol(assetPair.base.currency)}
          tooltip={message}
          tooltipPosition='left'
          flash={isFlashing}
          warning={baseAmountFieldWarning}
          invalid={!showAvailableCapacityWarning}
          highlight
        />
      </Field>
    </FormRow>
  );
}
