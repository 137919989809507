import dayjs from 'dayjs';

import {useUser} from 'contexts/auth-context';

import {Trade, TradeRelatedLateSettlementFee} from 'types/api';

import {convertCashFlowLegTypeToLegType, getUserLateFeeRequestsByStatusForLegType} from 'utils/TradeUtils';
import {displaySmallAmount} from 'utils/AmountUtils';
import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {CashFlowLegType} from './types';
import {getLateFeeOperationsAndStatusesOrder} from './utils';

import {Cell} from './styles';

export type AgreedLateFeesProps = {
  trade: Trade;
  legType: CashFlowLegType;
};

const formatLateFeePaymentDate = (date: string) =>
  displayDate(dayjs(date).add(3, 'month').endOf('month'), DateFormat.Standard);

const AgreedLateFeesRow = ({trade, legType}: AgreedLateFeesProps) => {
  const user = useUser();
  const convertedLegType = convertCashFlowLegTypeToLegType(legType);
  const receivedAcceptedLateFeeRequest: TradeRelatedLateSettlementFee | undefined =
    getUserLateFeeRequestsByStatusForLegType(trade, user, 'Accepted', 'recipient', convertedLegType)[0];
  const requestedAcceptedLateFeeRequest: TradeRelatedLateSettlementFee | undefined =
    getUserLateFeeRequestsByStatusForLegType(trade, user, 'Accepted', 'requestor', convertedLegType)[0];

  return (
    <>
      {getLateFeeOperationsAndStatusesOrder(trade, legType) === 'receiveThenSend' ?
        <>
          <Cell>
            {receivedAcceptedLateFeeRequest && formatLateFeePaymentDate(receivedAcceptedLateFeeRequest.createdAt)}
            {receivedAcceptedLateFeeRequest && requestedAcceptedLateFeeRequest && ' - '}
            {requestedAcceptedLateFeeRequest && formatLateFeePaymentDate(requestedAcceptedLateFeeRequest.createdAt)}
          </Cell>
          <Cell>
            {receivedAcceptedLateFeeRequest && displaySmallAmount(receivedAcceptedLateFeeRequest.fee.amountView)}
          </Cell>
          <Cell>
            {requestedAcceptedLateFeeRequest && displaySmallAmount(requestedAcceptedLateFeeRequest.fee.amountView)}
          </Cell>
        </>
      : <>
          <Cell>
            {requestedAcceptedLateFeeRequest && formatLateFeePaymentDate(requestedAcceptedLateFeeRequest.createdAt)}
            {receivedAcceptedLateFeeRequest && requestedAcceptedLateFeeRequest && ' - '}
            {receivedAcceptedLateFeeRequest && formatLateFeePaymentDate(receivedAcceptedLateFeeRequest.createdAt)}
          </Cell>
          <Cell>
            {requestedAcceptedLateFeeRequest && displaySmallAmount(requestedAcceptedLateFeeRequest.fee.amountView)}
          </Cell>
          <Cell>
            {receivedAcceptedLateFeeRequest && displaySmallAmount(receivedAcceptedLateFeeRequest.fee.amountView)}
          </Cell>
        </>
      }
    </>
  );
};

export default AgreedLateFeesRow;
