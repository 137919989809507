import {useState, useEffect} from 'react';
import dayjs from 'dayjs';

import {convertCashFlowLegTypeToLegType, getTradeSettlementTimeWithDeadLine} from 'utils/TradeUtils';

import {useUser} from 'contexts/auth-context';

import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {useModal} from 'contexts/modal-context';

import {FlagType, TradeStatus} from 'types/api';

import {Scope} from 'constants/permission-maps';

import {CheckBox} from 'ui/CheckBox';

import FlagAsPopup from 'components/popups/FlagAsPopup';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {CheckboxProps, FlagAs} from './types';

import {Cell, cellStyles} from './styles';

import {provideFlagType, isFlagAsBlockedByOtherFlag} from './utils';

const FlagAsCheckboxes = ({trade, legType, flagAsCheckboxType, cashflows, legCashflow}: CheckboxProps) => {
  const [isTicked, setIsTicked] = useState<boolean>(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState<boolean | undefined>(true);
  const userWithMeta = useUser();
  const permissions = getRolePermissions(userWithMeta.user?.role);
  const currentFlagType = provideFlagType(legType, flagAsCheckboxType);
  const currentFlag = cashflows.find(cashflow => cashflow.flagType === currentFlagType);
  const {openModal} = useModal();
  const convertedLegTrade = convertCashFlowLegTypeToLegType(legType);
  const [flaggedAsTime, setFlaggedAsTime] = useState<string>();

  useEffect(() => {
    const interval = setInterval(() => {
      const tradeTimeStarted = dayjs().isSameOrAfter(
        dayjs(trade[legType].actualSettlementTime ?? trade[legType].scheduledTime)
      );

      const isFlagAsNonReceiptDisabled =
        trade.status === TradeStatus.Unwind && currentFlagType === FlagType.NearLegNonreceipt;

      setIsCheckboxDisabled(
        !hasPermission({permissions, scopes: [Scope.CanCreateEditWithdrawFlagAs]}) ||
          !tradeTimeStarted ||
          isFlagAsNonReceiptDisabled ||
          isFlagAsBlockedByOtherFlag(currentFlagType, cashflows)
      );

      if (tradeTimeStarted) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [JSON.stringify(cashflows)]);

  useEffect(() => {
    if (currentFlag) {
      setIsTicked(currentFlag.flagValue);
      if (currentFlag.markedTimestamp) setFlaggedAsTime(currentFlag.markedTimestamp);
    }
  }, [JSON.stringify(cashflows)]);

  const provideDateTextColor = () => {
    if (
      (currentFlagType === FlagType.NearLegNonreceipt || currentFlagType === FlagType.FarLegNonreceipt) &&
      currentFlag?.flagValue
    ) {
      return 'nonreceipt';
    }
    if (flaggedAsTime && dayjs(flaggedAsTime).isAfter(getTradeSettlementTimeWithDeadLine(trade, convertedLegTrade))) {
      return 'late';
    } else {
      return 'notLate';
    }
  };

  const generateCheckbox = () => (
    <CheckBox disabled={isCheckboxDisabled} checked={isTicked} onChange={openFlagAsPopup} />
  );

  const openFlagAsPopup = () => {
    openModal({
      modal: FlagAsPopup,
      props: {
        tradeId: trade.id,
        legType: convertedLegTrade,
        flagAsPopupType: flagAsCheckboxType,
        cashflowLegType: legType,
        isTicked,
        selectedDateTime: isTicked ? dayjs(flaggedAsTime) : undefined,
      },
    });
  };

  return (
    <>
      <Cell textColor={provideDateTextColor()}>{flaggedAsTime && displayDate(flaggedAsTime, DateFormat.DateTime)}</Cell>
      {flagAsCheckboxType === FlagAs.Sent ?
        <>
          <Cell style={cellStyles}>{legCashflow.quantity > 0 && generateCheckbox()}</Cell>
          <Cell style={cellStyles}>{legCashflow.quantity < 0 && generateCheckbox()}</Cell>
        </>
      : <>
          <Cell style={cellStyles}>{legCashflow.quantity < 0 && generateCheckbox()}</Cell>
          <Cell style={cellStyles}>{legCashflow.quantity > 0 && generateCheckbox()}</Cell>
        </>
      }
    </>
  );
};

export default FlagAsCheckboxes;
