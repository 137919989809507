import {ReactNode} from 'react';

import styled from 'styled-components';

import Header from 'containers/Header';

type Props = {
  children?: ReactNode;
};

const SRoot = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Screen = ({children}: Props) => (
  <SRoot>
    <Header />
    {children}
  </SRoot>
);

export default Screen;
