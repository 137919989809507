import styled from 'styled-components';

import Table from 'ui/Table';
import {RadioSwitcher} from 'ui/Switcher';

export const StyledSwitcher = styled(RadioSwitcher)`
  margin: 1em 0;
` as typeof RadioSwitcher;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${p => p.theme.colors.blue650};
  border-right: 4px solid ${p => p.theme.colors.gray600};
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const BalancesTable = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

export const StyledTable = styled(Table)`
  margin: 0;
`;

export const BalancesTitle = styled.span`
  background-color: ${p => p.theme.colors.gray500};
  color: ${p => p.theme.colors.gray10};
  font-size: 0.8rem;
  text-align: center;
  padding: 1rem;
  text-transform: uppercase;
  width: 100%;
`;

export const TableScroll = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const TableCurrency = styled.p`
  font-weight: bold;
  text-align: left;
  color: ${p => p.theme.colors.gray10};
  font-size: 0.9rem;
  width: 100%;
`;

export const TableCurrencyContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableValue = styled.p`
  text-align: right;
  color: ${p => p.theme.colors.gray10};
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
`;

export const Small = styled.span`
  font-size: 0.6rem;
  margin-left: 4px;
`;
