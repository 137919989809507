import {ComponentProps} from 'react';
import styled, {DefaultTheme, ThemedStyledProps, css, keyframes} from 'styled-components';

const textPulse = (props: ThemedStyledProps<ComponentProps<'span'>, DefaultTheme>) => keyframes`
  0% {
    color: white;
    font-weight: bold;
  }
  100% {
    color: ${props.theme.colors.blue50};
  }
`;

export const Container = styled.span<{uppercase: boolean; isFlashing: boolean}>`
  color: ${p => p.theme.colors.blue50};
  line-height: 1;
  text-transform: ${p => (p.uppercase ? 'uppercase' : 'none')};

  ${({isFlashing}) =>
    isFlashing ?
      css`
        animation: ${textPulse} 1.5s linear;
      `
    : ''}
`;
