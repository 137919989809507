import {authInstance, baseInstance} from 'axios/axios';
import {NewOrder} from 'types/orderForm';
import {
  Order,
  SpotRate,
  Trade,
  Balance,
  RFQRequest,
  RFQQuoteForm,
  TradeEconomicsRequestBody,
  TradeEconomicsResponse,
  MarketSnapshot,
  CreateRFQRequest,
  RFQRequestRejectionReason,
  RFQRequestStage,
  RFQQuoteRejectionReason,
  RFQQuoteWithRequest,
  Party,
  Notification,
  AssetPair,
  AssetWithTimezone,
  CapacityAdjustment,
  UserWithMeta,
  LimitType,
  CurrencyPair,
  LoginForm,
  MarketHistory,
  OrderBook,
  LegType,
  MarketDataQuery,
  RFQMarketConfig,
  MarketDay,
  AnnulTradeProposalReason,
  RequestTradeLateSettlementFeeAmountsBody,
  CashflowBody,
  TradeUnwindRequestReason,
  FlagType,
  Asset,
} from 'types/api';
import {ReferenceData} from 'types/layout';
import {Dayjs} from 'dayjs';
import {assetPairCode, currencyPairCode} from 'utils/AssetUtils';
import {formatMarketDataQuery} from 'utils/MarketUtils';
import {createEventSource, CreateEventSourceProps} from 'api/utils';

import ReconnectingEventSource from 'reconnecting-eventsource';

import {MarkToMarketData, RiskMeasure} from 'types/analytics';
import {appTimezone} from 'utils/setupDayjs';

import {truncateTimePrecision} from 'utils/DayjsUtils';

import {constructApiUrl, fxApiUrl} from 'constants/app_defaults';

import {fxConfig, jsonContentConfig} from './apiConstants';

export const getOrdersData = () => {
  const request: string = '/v2/orders';
  return authInstance.get<Order[]>(request, fxConfig);
};

export const createNewOrder = (orderData: NewOrder) => authInstance.post<Order>('/v2/orders', orderData);

export const getTradesData = () => authInstance.get<Trade[]>('/v2/allTrades', fxConfig);

export const getTradeData = (tradeId: string) => authInstance.get<Trade>(`/v2/trades/${tradeId}`, fxConfig);

export const getActiveOrders = () => authInstance.get<Order[]>('/v2/orders/active');

export const pauseActiveOrders = () => {
  const body: string = '"Paused"';
  return authInstance.put<Order[]>('/v2/orders/live/status', body, jsonContentConfig);
};

export const cancelActiveOrders = () => authInstance.delete<Order[]>('/v2/orders/active');

export const cancelOrder = (id: string) => authInstance.delete<never>('/v2/orders/' + id + '/isActive', fxConfig);

export const getSingleMarketSnapshot = (assetPair: AssetPair, marketDepth?: number) => {
  const code = assetPairCode(assetPair);
  if (marketDepth) {
    return authInstance.get<MarketSnapshot>(`/v2/markets/snapshots/${code}?marketDepth=${marketDepth}`);
  }
  return authInstance.get<MarketSnapshot>(`/v2/markets/snapshots/${code}`);
};

export const getMarketHistory = (assetPair: AssetPair, rateType: ReferenceData, since?: number) => {
  const code = assetPairCode(assetPair);
  const parameter: string = since ? `?rateType=${rateType}&since=${since}` : `?rateType=${rateType}`;
  return authInstance.get<MarketHistory>(`/v2/markets/${code}/history${parameter}`);
};

export const getOrderBook = (assetPair: AssetPair) =>
  authInstance.get<OrderBook>('/v2/markets/orderbooks/' + assetPairCode(assetPair));

// Get complete data for balances
export const getAllCapacities = () => authInstance.get<Balance[]>('/v2/capacity');

export const getMinimalCapacityProjection = (asset: Asset, startTime: Dayjs, endTime: Dayjs) => {
  const body = {
    asset,
    from: truncateTimePrecision(startTime).toISOString(),
    to: truncateTimePrecision(endTime).toISOString(),
  };
  return authInstance.post<number>('/v2/capacity/projection', body);
};

export const pauseOrder = (id: string) => {
  const body: string = '"Paused"';
  return authInstance.put<never>(`/v2/orders/${id}/status`, body, jsonContentConfig);
};

export const resumeOrder = (id: string) => {
  const body: string = '"Live"';
  return authInstance.put<never>(`/v2/orders/${id}/status`, body, jsonContentConfig);
};

export const getFixmlUrlForTrade = (tradeId: string) => `${fxApiUrl}/v2/trades/${tradeId}/fixml`;

export const getPreMatchDetailsUrlForTrade = (tradeId: string) =>
  `${fxApiUrl}/v2/trades/${tradeId}/pre-match?fileExtension=csv`;

export const getAvailableAssetsWithTimezones = () =>
  authInstance.get<AssetWithTimezone[]>('/v2/configs/supportedAssetsWithTimezones');

export const requestTradeLateSettlementFee = (tradeId: string, timeSettlementReceived: Dayjs, legType: LegType) =>
  authInstance.post<never>(`/v2/trades/${tradeId}/lateSettlementFees`, {timeSettlementReceived, legType});

export const withdrawTradeLateSettlementFee = (tradeId: string, lateFeeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/lateSettlementFees/${lateFeeId}`, {statusName: 'Withdrawn'});

export const acceptTradeLateSettlementFee = (tradeId: string, lateFeeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/lateSettlementFees/${lateFeeId}`, {statusName: 'Accepted'});

export const rejectTradeLateSettlementFee = (tradeId: string, lateFeeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/lateSettlementFees/${lateFeeId}`, {statusName: 'Rejected'});

export const requestTradeLateSettlementFeeAmounts = (body: RequestTradeLateSettlementFeeAmountsBody) =>
  authInstance.post<never>('/v2/trades/lateSettlementFee/calculateFee', body);

export const requestEarlyMaturity = (tradeId: string) =>
  authInstance.post<never>(`/v2/trades/${tradeId}/earlyMaturityRequest`);

export const withdrawEarlyMaturity = (tradeId: string) =>
  authInstance.delete<never>(`/v2/trades/${tradeId}/earlyMaturityRequest`);

export const rejectEarlyMaturity = (tradeId: string) =>
  authInstance.delete<never>(`/v2/trades/${tradeId}/earlyMaturityRequest`);

// Accept early maturity request by changing trade status
export const acceptEarlyMaturity = (orderId: string) => {
  const body: string = '"PendingMaturity"';
  return authInstance.put<never>(`/v2/trades/${orderId}/status`, body, jsonContentConfig);
};

export const requestTradeUnwind = (tradeId: string, reason: TradeUnwindRequestReason) => {
  return authInstance.post<never>(`/v2/trades/${tradeId}/unwind`, {reason});
};

export const rejectTradeUnwind = (tradeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/unwind`, {statusName: 'Rejected'});

export const acceptTradeUnwind = (tradeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/unwind`, {statusName: 'Accepted'});

export const withdrawTradeUnwind = (tradeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/unwind`, {statusName: 'Withdrawn'});

export const proposeTradeAnnul = (tradeId: string, reasons: AnnulTradeProposalReason[]) =>
  authInstance.post<never>(`/v2/trades/${tradeId}/annul`, reasons);

export const rejectTradeAnnulProposal = (tradeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/annul`, {statusName: 'Rejected'});

export const acceptTradeAnnulProposal = (tradeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/annul`, {statusName: 'Accepted'});

export const withdrawTradeAnnulProposal = (tradeId: string) =>
  authInstance.put<never>(`/v2/trades/${tradeId}/annul`, {statusName: 'Withdrawn'});

/** Retrivies related Trade, by provided id of Order, Request or Quote entity, if such presented
 * @param originId
 *   - id of Order, Request or Quote entity
 */
export const getRelatedTradesByOriginId = (originId: string) =>
  authInstance.get<Trade[]>(`/v2/trades?originId=${originId}`);

export const getMarkToMarketData = (startTime: Dayjs, endTime: Dayjs, riskMeasure: RiskMeasure) =>
  authInstance.get<MarkToMarketData>(
    `/v2/risk/historical?from=${truncateTimePrecision(startTime).toISOString()}` +
      `&to=${truncateTimePrecision(endTime).toISOString()}` +
      `&type=${riskMeasure}`
  );

export const listRFQMarkets = () => authInstance.get<RFQMarketConfig[]>('/v2/rfq/markets');

export const getAllMarketDays = () => authInstance.get<MarketDay[]>('/v2/market/days');

export const getMarkToMarketCSVFile = (startTime: Dayjs, endTime: Dayjs, measure: RiskMeasure) =>
  authInstance.get(
    `/v2/risk/historical/report?from=${truncateTimePrecision(startTime).toISOString()}` +
      `&to=${truncateTimePrecision(endTime).toISOString()}&timezone=${appTimezone}&csvType=${measure}`
  );

export const getFeesCSVFile = (startDate: Dayjs, endDate: Dayjs) =>
  authInstance.get(
    `/v2/analytics/fees?from=${startDate.toISOString()}&to=${endDate.toISOString()}&timezone=${appTimezone}`
  );

export const getTradesActivityReport = (startTime: Dayjs, endTime: Dayjs) =>
  authInstance.get(
    `/v2/trades/report?from=${truncateTimePrecision(startTime).toISOString()}` +
      `&to=${truncateTimePrecision(endTime).toISOString()}&timezone=${appTimezone}`
  );

export const getOrdersActivityReport = (startTime: Dayjs, endTime: Dayjs) =>
  authInstance.get(
    `/v2/orders/report?from=${truncateTimePrecision(startTime).toISOString()}` +
      `&to=${truncateTimePrecision(endTime).toISOString()}&timezone=${appTimezone}`
  );

export const getRFQRequestsActivityReport = (startTime: Dayjs, endTime: Dayjs) =>
  authInstance.get(
    `/v2/rfq/report?resourceType=request&from=${truncateTimePrecision(startTime).toISOString()}` +
      `&to=${truncateTimePrecision(endTime).toISOString()}&timezone=${appTimezone}`
  );

export const getRFQQuotesActivityReport = (startTime: Dayjs, endTime: Dayjs) =>
  authInstance.get(
    `/v2/rfq/report?resourceType=quote&from=${truncateTimePrecision(startTime).toISOString()}` +
      `&to=${truncateTimePrecision(endTime).toISOString()}&timezone=${appTimezone}`
  );

export const createRfqRequest = (newRequest: CreateRFQRequest) =>
  authInstance.post<never>('/v2/rfq/requests', newRequest);

export const getRfqRequests = () => authInstance.get<RFQRequest[]>('/v2/rfq/requests', fxConfig);

export const getSentRfqRequests = () => {
  const urlParams: string = '?createdByUs=true';
  return authInstance.get<RFQRequest[]>(`/v2/rfq/requests${urlParams}`, fxConfig);
};

export const getReceivedRfqRequests = () => {
  const urlParams: string = '?createdByUs=false';
  return authInstance.get<RFQRequest[]>(`/v2/rfq/requests${urlParams}`, fxConfig);
};

export const getQuotesWithRequests = () => authInstance.get<RFQQuoteWithRequest[]>('/v2/rfq/quotes', fxConfig);

export const createRfqQuote = (requestId: string, rfqForm: RFQQuoteForm) =>
  authInstance.post<never>(`/v2/rfq/requests/${requestId}/quotes`, rfqForm);

export const acceptRfqQuote = (requestId: string, quoteId: string) => {
  const newStatus: RFQRequestStage = {name: 'Accepted', reasons: []};
  return authInstance.put<never>(
    `/v2/rfq/requests/${requestId}/quotes/${quoteId}/status`,
    newStatus,
    jsonContentConfig
  );
};

export const rejectRfqQuote = (requestId: string, quoteId: string, reasons: RFQQuoteRejectionReason[] = []) => {
  const newStatus: RFQRequestStage = {name: 'Rejected', reasons};
  return authInstance.put<never>(
    `/v2/rfq/requests/${requestId}/quotes/${quoteId}/status`,
    newStatus,
    jsonContentConfig
  );
};

export const cancelRfqNegotiation = (requestId: string, negotiationId: string) => {
  const newStatus: RFQRequestStage = {name: 'Cancelled', reasons: []};
  return authInstance.put<never>(
    `/v2/rfq/requests/${requestId}/negotiations/${negotiationId}/status`,
    newStatus,
    jsonContentConfig
  );
};

export const cancelRfqRequest = (requestId: string) => {
  const newStatus: RFQRequestStage = {name: 'Cancelled', reasons: []};
  return authInstance.put<never>(`/v2/rfq/requests/${requestId}/status`, newStatus, jsonContentConfig);
};

export const rejectRfqRequest = (requestId: string, reasons: RFQRequestRejectionReason[] = []) => {
  const newStatus: RFQRequestStage = {name: 'Rejected', reasons};
  return authInstance.put<never>(`/v2/rfq/requests/${requestId}/status`, newStatus, jsonContentConfig);
};

export const loadCapacityAdjustments = () => authInstance.get<CapacityAdjustment[]>('/v2/capacity/adjustments');

// Get network participants
export const getAvailableCounterparties = () => authInstance.get<Party[]>('/v2/networkMap/counterparties');

export const updateLimit = (counterparty: string, quantity: number, limitType: LimitType) =>
  authInstance.post<never>('/v2/limits', {counterparty, quantity, limitType});

export const calculateTradeEconomics = (tradeEconomicsRequest: TradeEconomicsRequestBody) =>
  authInstance.post<TradeEconomicsResponse>('/v2/trades/economics', tradeEconomicsRequest);

export const cancelRFQQuote = (requestId: string, quoteId: string) => {
  const newStatus: RFQRequestStage = {name: 'Cancelled', reasons: []};
  return authInstance.put<never>(
    `/v2/rfq/requests/${requestId}/quotes/${quoteId}/status`,
    newStatus,
    jsonContentConfig
  );
};

// Get notifications history (gets default page and size)
export const getNotifications = (aggregateId?: string) => {
  const queryParam = aggregateId ? `?aggregateId=${aggregateId}` : '';
  return authInstance.get<Notification[]>(`/v2/notifications${queryParam}`);
};

export const getClobMarketDays = () => authInstance.get<MarketDay[]>('/v2/markets/days');

export const getCashflowFlags = (tradeId: string) =>
  authInstance.get<CashflowBody[]>(`/v2/trades/${tradeId}/cashflows`);

export const getTradeIdsWithCashflowFlags = (flagTypes: FlagType[], flagValue: boolean) =>
  authInstance.get<Trade['id'][]>(`/v2/trades/cashflows?flagTypes=${flagTypes.join()}&flagValue=${flagValue}`);

export const createOrUpdateOrWithdrawCashflowFlag = (tradeId: string, cashflowBody: CashflowBody) =>
  authInstance.post<CashflowBody[]>(`/v2/trades/${tradeId}/cashflows`, cashflowBody);

// Server sent events

// Attempt to cache event sources
// TODO: Extract and refactor
let notificationsEventSource: ReconnectingEventSource | undefined;

export const createNotificationsEventSource = (props: Omit<CreateEventSourceProps<Notification>, 'url'>) => {
  if (!notificationsEventSource) {
    notificationsEventSource = createEventSource<Notification>({
      url: constructApiUrl('/v2/notifications/events'),
      ...props,
    });
  }

  return notificationsEventSource;
};

export const createMarketSnapshotsEventSource = (
  props: Omit<CreateEventSourceProps<MarketSnapshot>, 'url'>,
  queryParams: MarketDataQuery = {}
) => {
  const query = formatMarketDataQuery(queryParams);
  return createEventSource<MarketSnapshot>({
    url: constructApiUrl('/v2/markets/snapshots/events?' + query),
    timeBeweenReconections: 4000,
    ...props,
  });
};

export const createExchangeRatesEventSource = (
  props: Omit<CreateEventSourceProps<SpotRate>, 'url'>,
  pairs: CurrencyPair[]
) => {
  const queryString = pairs.map(cp => `currencyPairs=${currencyPairCode(cp)}`).join('&');
  return createEventSource<SpotRate>({
    url: constructApiUrl('/v2/exchangeRates/events?' + queryString),
    ...props,
  });
};

export const getMyProfile = () => authInstance.get<UserWithMeta>('/v2/users/me');

export const getMyJwtProfile = () => authInstance.get<UserWithMeta>('/v2/jwt/users/me');

export const authenticate = (data: LoginForm) => baseInstance.post('/v2/login', data);

export const makeLogOutApiCall = () => authInstance.post('/v2/logout', {});
