import {useState} from 'react';

import Button from 'ui/Button';
import {Bottom} from 'containers/RFQPopup/styles';
import {SButtonsContainer} from 'components/popups/TradePopup/components/UnwindTradePopup/styles';

import {ActionButton, ConfirmationButtonsProps, ConfirmationState} from './types';

import './ConfirmationButtons.scss';

const ConfirmationButtons = ({actions, onChange, withConfirmationMessage = true}: ConfirmationButtonsProps) => {
  const [chosenAction, setChosenAction] = useState<ActionButton | undefined>();
  const [, setCurrentState] = useState<ConfirmationState>('default');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const changeState = (state: ConfirmationState) => {
    setCurrentState(state);

    if (onChange) {
      onChange(state);
    }
  };

  const resetChosenAction = () => {
    setChosenAction(undefined);
    changeState('default');
  };

  const chooseAction = (action: ActionButton) => {
    setChosenAction(action);
    changeState('confirmation');
  };

  const onCancel = () => {
    resetChosenAction();
  };

  const onConfirm = () => {
    if (chosenAction) {
      chosenAction.onClick();
      setIsDisabled(true);
    }
  };

  return (
    <>
      <>
        {!chosenAction && (
          <SButtonsContainer>
            {actions.map((action, index) => (
              <Button
                key={index}
                loading={action.loading}
                buttonStyle={action.buttonStyle}
                onClick={() => chooseAction(action)}
                disabled={action.disabled}
                data-testid={action.testId}
              >
                {action.label}
              </Button>
            ))}
          </SButtonsContainer>
        )}
        {chosenAction && (
          <SButtonsContainer>
            <Button data-testid='cancel' buttonStyle='grey' onClick={onCancel} disabled={isDisabled}>
              Back
            </Button>
            <Button data-testid='confirm' buttonStyle='primary' onClick={onConfirm} disabled={isDisabled}>
              Confirm
            </Button>
          </SButtonsContainer>
        )}
      </>
      {chosenAction && withConfirmationMessage && (
        <Bottom>Are you sure you want to {chosenAction.label.toLowerCase()}?</Bottom>
      )}
    </>
  );
};

export default ConfirmationButtons;
