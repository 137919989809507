import {isEqual, uniqBy} from 'lodash';

import {AssetPair, Currency, MarketSnapshot} from 'types/api';
import {assetPairCode} from 'utils/AssetUtils';
import {useRealTimeMarketDaysQuery} from 'api/hooks/useRealTimeMarketDaysQuery';

import {useSessionStorage} from 'react-use';

import {useMemo} from 'react';

import Skeleton from 'ui/Skeleton';
import BestRateSelector from 'components/BestRateSelector/BestRateSelector';
import BoardHeader from 'components/BoardHeader';
import {ClobOverviewTile} from 'components/ClobOverviewTile/ClobOverviewTile';
import {RefSwitch} from 'components/RefSwitch/RefSwitch';
import {SubsetSelector} from 'components/SubsetSelector/SubsetSelector';

interface Props {
  marketSnapshots: MarketSnapshot[];
  isLoading: boolean;
}

function ClobOverviewTiles({isLoading, marketSnapshots}: Props) {
  const [currencySubset, setCurrencySubset] = useSessionStorage<Currency | 'ALL' | undefined>('currency-subset');
  const {marketDays, isLoading: isMarketDaysLoading} = useRealTimeMarketDaysQuery();

  const getSnapshotForPair = (pair: AssetPair) => marketSnapshots.find(snapshot => isEqual(pair, snapshot.assetPair));

  const filteredMarketsByCurrencySubset = useMemo(() => {
    const uniqueMarketDays = uniqBy(
      marketDays.map(day => day.assetPair),
      assetPairCode
    );

    return currencySubset === 'ALL' ? uniqueMarketDays : (
        uniqueMarketDays.filter(
          pair => !currencySubset || currencySubset === pair.second.currency || currencySubset === pair.base.currency
        )
      );
  }, [marketDays, currencySubset]);

  return (
    <div className='ClobOverview'>
      <BoardHeader title='CLOB Overview'>
        <RefSwitch />
        {window.REACT_APP_OBSERVER !== 'true' && (
          <SubsetSelector currencySubset={currencySubset} setCurrencySubset={setCurrencySubset} />
        )}
        <BestRateSelector />
      </BoardHeader>

      <div className='currencies-container'>
        {filteredMarketsByCurrencySubset.length === 0 &&
          (isLoading || isMarketDaysLoading) &&
          new Array(4).fill(0).map((_, i) => <Skeleton key={i} minH='220px' />)}
        {filteredMarketsByCurrencySubset.map(assetPair => {
          const marketSnapshot = getSnapshotForPair(assetPair);
          if (!marketSnapshot) return null;

          return <ClobOverviewTile marketSnapshot={marketSnapshot} key={assetPairCode(assetPair)} />;
        })}
      </div>
    </div>
  );
}

export default ClobOverviewTiles;
