import {FC, HTMLProps} from 'react';

import './Header.scss';
import {SCol} from 'ui/Layout/styles';

const List: FC<HTMLProps<HTMLDivElement>> = ({children, className}) => (
  <SCol width={[1, 1 / 3, 5 / 12]} className={className || ''}>
    <ul className='user-menu'>{children}</ul>
  </SCol>
);

const Item: FC<HTMLProps<HTMLLIElement>> = ({children, id, className}) => (
  <li id={id} className={['user', className].join(' ')}>
    {children}
  </li>
);

export default {List, Item};
