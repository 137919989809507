import {FormProvider} from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

import {useModal} from 'contexts/modal-context';

import {useState} from 'react';

import {RFQRequest} from 'types/api';

import Popup from 'ui/Popup';

import {CreateQuoteForm} from './components/CreateQuoteForm';
import {Confirmation} from './components/Confirmation';
import useCreateQuoteForm, {CreateQuoteFormValues} from './hooks/useCreateQuoteForm';

export interface CreateQuotePopupProps {
  quoteFormRequest: RFQRequest;
}

const CreateQuotePopup = ({quoteFormRequest}: CreateQuotePopupProps) => {
  const [isQuotingDisabled, setIsQuotingDisabled] = useState<boolean>(false);
  const [quoteFormState, setQuoteFormState] = useState<CreateQuoteFormValues>();
  const {closeModal} = useModal();
  const {methods} = useCreateQuoteForm(quoteFormRequest);

  const isQuoteConfirmationShown = !!quoteFormState;
  const isQuoteFormShown = !quoteFormState;

  function handleCountdownTimeUp() {
    setIsQuotingDisabled(true);
  }

  function dismissForm(): void {
    closeModal();
  }

  function getBackToForm(): void {
    setQuoteFormState(undefined);
  }

  return (
    <Popup
      title='Create Quote'
      onClose={isQuoteFormShown || isQuotingDisabled ? dismissForm : getBackToForm}
      width='360px'
      closeButtonType={isQuoteConfirmationShown && !isQuotingDisabled ? 'arrow' : 'default'}
    >
      <FormProvider {...methods}>
        {isQuoteConfirmationShown ?
          <Confirmation
            isQuotingDisabled={isQuotingDisabled}
            handleCountdownTimeUp={handleCountdownTimeUp}
            quoteFormRequest={quoteFormRequest}
            quoteFormState={quoteFormState}
            onEdit={() => {
              setQuoteFormState(undefined);
            }}
            onSubmit={() => {
              dismissForm();
              const exchangeRate = cloneDeep(methods.getValues().exchangeRate);
              setQuoteFormState(undefined);
              methods.reset();
              methods.setValue('exchangeRate', exchangeRate);
            }}
          />
        : <CreateQuoteForm
            isQuotingDisabled={isQuotingDisabled}
            handleCountdownTimeUp={handleCountdownTimeUp}
            dismissForm={dismissForm}
            quoteFormRequest={quoteFormRequest}
            onQuoteSubmit={values => {
              setQuoteFormState(values);
            }}
          />
        }
      </FormProvider>
    </Popup>
  );
};

export default CreateQuotePopup;
