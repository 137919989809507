import {useMemo} from 'react';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';

import {Currency} from 'types/api';
import {CreditRiskData} from 'types/analytics';

import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';

import useEquivCurrencyStore from 'stores/useEquivCurrencyStore';

import {useRealTimeCreditRiskQuery} from 'api/hooks/useRealTimeCreditRiskQuery';

import {getRFC2253ValueByKey} from 'utils/utils';

import {uniq} from 'lodash';

import {SContainer} from 'ui/Layout/styles';
import GridTable from 'ui/GridTable';
import Loader from 'ui/Loader';
import Box from 'ui/Box';
import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import {
  getSettlementRiskVersusLimitColumns,
  getMtmVersusLimitColumns,
  getSettlementRisksPerCurrencies,
} from './columns';

const columnHelper = createColumnHelper<CreditRiskData>();

const RTCreditRisk = () => {
  const [equivCurrency] = useEquivCurrencyStore();
  const {exchangeRates} = useRealTimeExchangeRatesQuery();
  const {creditRisk} = useRealTimeCreditRiskQuery();

  const currencies = useMemo<Currency[]>(
    () => uniq(exchangeRates.flatMap(exchangeRate => [exchangeRate.pair.base, exchangeRate.pair.second])),
    [JSON.stringify(exchangeRates)]
  );

  const columns = useMemo<ColumnDef<CreditRiskData>[]>(() => {
    return [
      {
        id: 'counterparty',
        columns: [
          columnHelper.accessor('counterparty', {
            cell: info => getRFC2253ValueByKey(info.row.original.counterparty, 'O'),
            header: '',
            meta: {
              align: 'left',
            },
          }),
        ],
      },
      getSettlementRiskVersusLimitColumns(),
      getMtmVersusLimitColumns(),
      ...getSettlementRisksPerCurrencies(currencies, equivCurrency, exchangeRates),
    ];
  }, [JSON.stringify(exchangeRates), columnHelper, JSON.stringify(currencies), equivCurrency]);

  return (
    <SContainer position='relative' height='100%' px='15px'>
      {(!creditRisk || exchangeRates.length === 0) && <Loader />}
      <BoardHeader title='RT Credit Risk' />
      <Box height='fit-content' width='100%' overflow='auto'>
        <GridTable columns={columns} data={creditRisk || []} sortable />
      </Box>
    </SContainer>
  );
};

export default RTCreditRisk;
