import {useState} from 'react';

import {Comparison} from './components/Comparison';
import RFQQuoteDetails from './components/RFQDetails/RFQQuoteDetails';
import RFQRequestDetails from './components/RFQDetails/RFQRequestDetails';

export type View = 'comparison' | 'details';

interface Props {
  requestId: string;
  quoteId?: string;
  defaultView: View;
}

export default function RFQOverviewPopup({requestId, quoteId: propsQuoteId, defaultView}: Props) {
  const [view, setView] = useState<View>(defaultView);
  const [quoteId, setQuoteId] = useState(propsQuoteId);

  if (view === 'comparison') {
    return (
      <Comparison
        requestId={requestId}
        onShowDetails={(qId?: string) => {
          setView('details');
          setQuoteId(qId);
        }}
      />
    );
  }
  if (view === 'details' && !!quoteId) {
    return <RFQQuoteDetails requestId={requestId} quoteId={quoteId} onCompare={() => setView('comparison')} />;
  }
  if (view === 'details' && !quoteId) {
    return <RFQRequestDetails requestId={requestId} onCompare={() => setView('comparison')} />;
  }
  return null;
}
