import {useUser} from 'contexts/auth-context';
import {useModal} from 'contexts/modal-context';
import {useIntl} from 'react-intl';
import {Trade, TradeStatus, TradeUnwindRequest} from 'types/api';

import {hasPendingReceivedUnwindRequest, hasPendingSentUnwindRequest, isUnwindOrEMRPossible} from 'utils/TradeUtils';

import {fieldTitles} from 'constants/messages/labels';
import Button from 'ui/Button';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

import {UnwindTradePopup} from '../UnwindTradePopup';
import {WithdrawUnwindTradePopup} from '../WithdrawUnwindTradePopup';
import {ReviewUnwindTradePopup} from '../ReviewUnwindTradePopup';

interface Props {
  trade: Trade;
}

const TRADE_UNWIND_DISPLAY_UNWIND_STATUSES: TradeUnwindRequest['status'][] = ['Cancelled', 'Expired', 'Rejected'];

export default function TradeUnwindButton({trade}: Props) {
  const userWithMeta = useUser();
  const {formatMessage} = useIntl();
  const {openModal} = useModal();
  const canRequestUnwind = isUnwindOrEMRPossible(trade);

  const isRequestUnwindBtnDisabled = !canRequestUnwind || Boolean(trade.earlyMaturityRequest);

  const showUnwindTooltipMessage = () => {
    if (!trade.earlyMaturityRequest) return null;
    const action =
      userWithMeta.legalEntities.map(party => party.legalName).includes(trade.earlyMaturityRequest.requestor) ?
        'withdraw'
      : 'reject';
    return `Please ${action} Early Maturity request first.`;
  };

  const onRequestUnwindClick = (): void => {
    if (!trade) return;
    openModal({modal: UnwindTradePopup, props: {tradeId: trade.id}});
  };

  const onWithdrawUnwindClick = (): void => {
    if (!trade) return;

    openModal({modal: WithdrawUnwindTradePopup, props: {tradeId: trade.id}});
  };

  const onReviewUnwindClick = (): void => {
    if (!trade) return;

    openModal({modal: ReviewUnwindTradePopup, props: {tradeId: trade.id}});
  };

  if (hasPendingReceivedUnwindRequest(trade))
    return (
      <Button
        data-testid='received-unwind'
        key={fieldTitles.receivedUnwindButton.id}
        buttonStyle='warning'
        onClick={onReviewUnwindClick}
      >
        {formatMessage(fieldTitles.receivedUnwindButton)}
      </Button>
    );

  if (hasPendingSentUnwindRequest(trade))
    return (
      <Button
        data-testid='withdraw-unwind'
        key={fieldTitles.withdrawUnwindButton.id}
        buttonStyle='warning'
        onClick={onWithdrawUnwindClick}
      >
        {formatMessage(fieldTitles.withdrawUnwindButton)}
      </Button>
    );

  if (
    trade.status === TradeStatus.Outstanding &&
    (!trade.tradeUnwind || TRADE_UNWIND_DISPLAY_UNWIND_STATUSES.includes(trade.tradeUnwind.status))
  )
    return (
      <TooltipWrapper message={showUnwindTooltipMessage()} position='top'>
        <Button
          key={fieldTitles.requestUnwindButton.id}
          data-testid='propose-trade-unwind'
          disabled={isRequestUnwindBtnDisabled}
          buttonStyle={isRequestUnwindBtnDisabled ? 'grey' : 'secondary'}
          onClick={onRequestUnwindClick}
        >
          {formatMessage(fieldTitles.requestUnwindButton)}
        </Button>
      </TooltipWrapper>
    );

  return null;
}
