import {useState} from 'react';

import {acceptTradeLateSettlementFee, rejectTradeLateSettlementFee} from 'api/api';
import {showToast} from 'utils/ToastUtils';
import {getTradeSettlementTimeWithDeadLine} from 'utils/TradeUtils';

import {AxiosResponse} from 'axios';
import {LegType} from 'types/api';

import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {useIntl} from 'react-intl';

import dayjs from 'dayjs';
import {displaySmallAmount} from 'utils/AmountUtils';
import {displayDate, displayDuration} from 'utils/DayjsUtils';
import TableRow from 'utils/TableUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {fieldTitles} from 'constants/messages/labels';
import Popup from 'ui/Popup';

import {Bottom, StyledTable} from 'containers/RFQPopup/styles';
import ConfirmationButtons from 'components/ConfirmationButtons/ConfirmationButtons';
import {ActionButton} from 'components/ConfirmationButtons/types';

import {ReviewLateSettlementFeePopupProps} from '../types';

import '../LateSettlementFeePopup.scss';
import {YouReceivedOrYouPaidLateValue, getLateSettlementAmount} from '../utils';

import TradePopup from 'components/popups/TradePopup';
import {DetailsTabType} from 'types/layout';

export const ReviewLateSettlementFeePopup = ({tradeId, legType, lateFee}: ReviewLateSettlementFeePopupProps) => {
  const {openModal} = useModal();
  const {formatMessage} = useIntl();
  const {selectTradeById} = useRealTimeTradesQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const trade = selectTradeById(tradeId);

  if (!trade) return null;
  const inMillions = shouldDisplayInMillions({base: trade.baseAmount.asset, second: trade.secondAmount.asset});
  const isRepoPaidAmount = (paidOrReceived: 'paid' | 'received'): boolean =>
    getLateSettlementAmount({trade, legType, paidOrReceived}).asset.type === 'Securities';

  const goBackToCashFlowsTab = () => {
    openModal({
      modal: TradePopup,
      props: {
        modelId: tradeId,
        tradePopupTab: {value: DetailsTabType.CashFlows, label: DetailsTabType.CashFlows},
      },
    });
  };

  const handleAction = (
    action: (tradeId: string, lateFeeId: string) => Promise<AxiosResponse>,
    successMessage: string,
    errorMessage: string
  ) => {
    if (!lateFee) {
      return;
    }
    setIsLoading(true);
    action(tradeId, lateFee.id)
      .then(() => {
        showToast(successMessage, 'success');
        goBackToCashFlowsTab();
      })
      .catch((e: any) => {
        console.error(e);
        showToast(errorMessage, 'warning');
        setIsLoading(false);
      });
  };

  const onAccept = () => {
    handleAction(
      acceptTradeLateSettlementFee,
      'Late settlement fee has been accepted.',
      'There was an error when accepting late settlement fee.'
    );
  };

  const onReject = () => {
    handleAction(
      rejectTradeLateSettlementFee,
      'Late settlement fee has been rejected.',
      'There was an error when rejecting late settlement fee.'
    );
  };

  const settlementReceivedTime = dayjs(lateFee.settlementReceivedTimestamp);
  const settlementTimeWithDeadline = getTradeSettlementTimeWithDeadLine(trade, legType);

  const delayedLegRow = (
    <TableRow key='delayedLeg' title={formatMessage(fieldTitles.delayedLeg)}>
      {legType === LegType.NearLeg ? 'Near leg' : 'Far leg'}
    </TableRow>
  );

  const youReceivedRow = (
    <TableRow
      key='youReceived'
      title={formatMessage(fieldTitles.youShouldHaveReceived)}
      leftTestId='you-received-label'
      rightTestId='you-received-value'
    >
      <YouReceivedOrYouPaidLateValue
        trade={trade}
        legType={legType}
        paidOrReceived={'received'}
        inMillions={inMillions}
      />
    </TableRow>
  );

  const youPaidLateRow = (
    <TableRow
      key='youPaidLate'
      title={formatMessage(isRepoPaidAmount('paid') ? fieldTitles.youSentLate : fieldTitles.youPaidLate)}
      leftTestId='you-paid-late-label'
      rightTestId='you-paid-late-value'
    >
      <YouReceivedOrYouPaidLateValue trade={trade} legType={legType} paidOrReceived={'paid'} inMillions={inMillions} />
    </TableRow>
  );

  const settlementDeadlineRow = (
    <TableRow key='settlementDeadline' title={formatMessage(fieldTitles.settlementDeadline)}>
      {displayDate(settlementTimeWithDeadline, 'DD MMM YYYY HH:mm:ss')}
    </TableRow>
  );

  const dateReceivedRow = (
    <TableRow key='dateReceived' title={formatMessage(fieldTitles.dateReceivedClaimed)}>
      {displayDate(settlementReceivedTime, 'DD MMM YYYY')}
    </TableRow>
  );

  const timeReceivedRow = (
    <TableRow key='timeReceived' title={formatMessage(fieldTitles.timeReceivedClaimed)}>
      {displayDate(settlementReceivedTime, 'HH:mm:ss')}
    </TableRow>
  );

  const delayRow = (
    <TableRow key='delay' title={formatMessage(fieldTitles.delayClaimed)} rightTestId='settlement-delay'>
      {displayDuration(settlementTimeWithDeadline, settlementReceivedTime)}
    </TableRow>
  );

  const lateFeeRateRow = (
    <TableRow key='lateFeeRate' title={formatMessage(fieldTitles.lateFeeRate)}>{`${lateFee.fee.points} bp`}</TableRow>
  );

  const lateFeeAmountRow = (
    <TableRow key='lateFeeAmount' title={formatMessage(fieldTitles.lateFeeAmount)}>
      {displaySmallAmount(lateFee.fee.amountView)}
    </TableRow>
  );

  // This is commented out because we need to implement FX-3051 for detailed late fee breakdown
  // until then, we will not display this row but will keep it here for future reference
  // const lateFeeBreakdownRow: Row = getRow(formatMessage(fieldTitles.lateFeeBreakdown), 0);

  const rows = [
    delayedLegRow,
    youReceivedRow,
    youPaidLateRow,
    settlementDeadlineRow,
    dateReceivedRow,
    timeReceivedRow,
    delayRow,
    lateFeeRateRow,
    lateFeeAmountRow,
  ];

  const buttons: ActionButton[] = [
    {
      label: 'Reject',
      onClick: onReject,
      disabled: isLoading,
      buttonStyle: 'secondary',
    },
    {
      label: 'Accept',
      onClick: onAccept,
      disabled: isLoading,
      buttonStyle: 'primary',
    },
  ];

  return (
    <Popup
      title='Late Settlement: Review late fee'
      onClose={goBackToCashFlowsTab}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable className='late-fee-popup' elements={rows} />
      <Bottom>
        <ConfirmationButtons actions={buttons} />
      </Bottom>
    </Popup>
  );
};
