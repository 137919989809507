import styled from 'styled-components';

import {LabelWithTooltipStyles} from './types';

export const TableLabel = styled.span<{styles?: LabelWithTooltipStyles}>`
  color: ${p => p?.styles?.color || p.theme.colors.gray200};
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
`;
