import {useQuery} from '@tanstack/react-query';

import {getNotifications} from 'api/api';
import {useMemo} from 'react';
import {displayMessage} from 'pages/NotificationCenterPage/utils';
import dayjs from 'dayjs';

import {Amount, TradeEvent} from 'types/api';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useAvailableCounterpartiesQuery} from './useAvailableCounterpartiesQuery';

export default function useTradeEventsQuery(tradeId: string): {
  tradeEvents: TradeEvent[];
  isLoading: boolean;
  error: Error | null;
} {
  const {shortNamesMap: shortNames} = useAvailableCounterpartiesQuery();
  const {data, isLoading, error} = useQuery({
    queryKey: ['TradeEvents', tradeId],
    queryFn: () => getNotifications(tradeId).then(res => res.data),
  });
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const tradeEvents = useMemo(
    () =>
      (data || []).map(notification => {
        const amount: Amount | undefined =
          'amount' in notification.payload && notification.payload.amount ? notification.payload.amount : undefined;
        const inMillions =
          'assetPair' in notification.payload ? shouldDisplayInMillions(notification.payload.assetPair) : true;

        return {
          id: notification.timestamp,
          date: dayjs(notification.timestamp),
          event: displayMessage(shortNames, notification, inMillions) || '',
          leg: undefined,
          amount,
        };
      }),
    [JSON.stringify(data), JSON.stringify(shortNames)]
  );

  return {tradeEvents, isLoading, error};
}
