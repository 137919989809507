const colors = {
  white: '#fff',
  black: '#000',
  rgbBlack: 'rgb(0, 0, 0)',
  green400: '#79f2be',
  green450: '#94e6b4',
  green500: '#74e6b4',
  green530: '#53d89e',
  green550: '#62c49a',
  rgbGreen500: 'rgb(116, 230, 180)',
  green600: '#55ab86',
  red200: '#ff738c',
  red400: '#ff5070',
  rgbRed400: 'rgb(255, 80, 112)',
  red500: '#f34e6c',
  red550: '#df4b67',
  red600: '#d1415b',
  red700: '#e02020',
  yellow100: '#f7f68b',
  yellow300: '#fa9b0a',
  yellow350: '#ffb400',
  yellow400: '#dea112',
  yellow500: '#d69b0a',
  yellow550: '#cc971f',
  blue50: '#a3b2cf',
  blue80: '#b3b2cf',
  blue100: '#9caac6',
  blue150: '#95a4be',
  blue200: '#919eb8',
  blue250: '#8895ad',
  blue300: '#78bde8',
  blue600: '#3a3e47',
  blue650: '#383d48',
  blue680: '#353b46',
  blue700: '#363940',
  blue900: '#2b2f39',
  blue950: '#262a33',
  gray10: '#fdfdfd',
  gray50: '#efeff0',
  gray80: '#dfe0e1',
  gray100: '#bec0d2',
  gray200: '#a1a7b4',
  gray300: '#97a4bf',
  gray350: '#7c879c',
  gray400: '#768194',
  gray450: '#585f6b',
  gray470: '#555b6b',
  gray500: '#4e5561',
  gray550: '#43454a',
  gray600: '#404552',
  gray700: '#333',
  gray900: '#282a2e',
  gray1000: '#15151a',
  tableRowHover: '#383d48',
};

export default colors;
