import {PropsWithChildren, useEffect} from 'react';

import {createPortal} from 'react-dom';

const Portal = ({children}: PropsWithChildren) => {
  const mount = document.getElementById('tooltip') || document.body;
  const el = document.createElement('div');
  el.setAttribute('style', 'position:absolute; left:0; right:0; bottom: 100%');

  useEffect(() => {
    mount.appendChild(el);
    return () => {
      mount.removeChild(el);
    };
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
