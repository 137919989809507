import {createColumnHelper} from '@tanstack/react-table';

import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {NotificationData} from 'pages/NotificationCenterPage/types';

import {NotificationStatusRenderer} from 'components/NotificationStatusRenderer/NotificationStatusRenderer';

import {DateRow} from './styles';

const notificationColumnHelper = createColumnHelper<NotificationData>();

export default function getColumns(updatingNotifications: NotificationData[]) {
  return [
    notificationColumnHelper.accessor('createdAt', {
      header: 'DATE & TIME',
      id: 'date-column',
      cell: ({row}) => (
        <DateRow>
          <span>{displayDate(row.original.createdAt, 'DD/MM' as DateFormat)}</span>
          <span>{displayDate(row.original.createdAt, 'HH:mm:ss')}</span>
        </DateRow>
      ),
      size: 150,
    }),
    notificationColumnHelper.display({
      header: 'ALERT',
      id: 'alert-column',
      cell: ({row}) => (
        <span data-testid='alert-value' className='justify-content-start text-left'>
          {row.original.message}
        </span>
      ),
    }),
    notificationColumnHelper.display({
      header: 'VALID FOR',
      id: 'valid-column',
      cell: ({row}) => (
        <span data-testid='alert-status'>
          <NotificationStatusRenderer notification={row.original} updatingNotifications={updatingNotifications} />
        </span>
      ),
      size: 200,
    }),
  ];
}
