import {Dayjs} from 'dayjs';

import {Amount, Party, Asset} from './api';

export type SettlementRisk = {
  bought: Amount;
  sold: Amount;
  asset: Asset;
  // make the below 2 -- for now
  sent: Amount | '--';
  received: Amount | '--';
};

export type CreditRiskData = {
  counterparty: string;
  settlementVersusLimit: {
    exposure: Amount;
    limit: Amount;
    remaining: Amount;
    earmarked: Amount;
    // make the below 2 -- for now
    bought: Amount | '--';
    sold: Amount | '--';
  };
  mtm: {
    counterparty: string;
    exposure: Amount;
    limit: Amount;
    remaining: Amount;
    timestamp: string;
  };
  settlementRisks: SettlementRisk[];
};

export enum RiskMeasure {
  MtmExposure = 'MtmExposure',
  MtmRemaining = 'MtmRemaining',
  MtmTotal = 'MtmTotal',
  SettlementRiskExposure = 'SettlementRiskExposure',
  SettlementRiskRemaining = 'SettlementRiskRemaining',
  SettlementRiskTotal = 'SettlementRiskTotal',
}

export type RiskExposureData = {
  // TODO: Update when consuming the api
  date: Dayjs;
  riskExposure: {
    [key: string]: Amount;
  };
};

export type MarkToMarketData = {
  relatedCounterparties: Party[];
  values: MarkToMarketRow[];
};

export type MarkToMarketRow = {
  date: Dayjs;
  values: {
    [key: string]: Amount;
  };
};
export type RiskExposureRow = MarkToMarketRow;
