import {PropsWithChildren, useState} from 'react';

import {AccordionContainer, AccordionHeader, AccordionTitle} from './styles';

interface AccordionProps {
  title: string;
  defaultOpen: boolean;
  'data-testid'?: string;
}

const Accordion = ({title, children, defaultOpen = false, ...rest}: PropsWithChildren<AccordionProps>) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContainer>
      <AccordionHeader onClick={toggleAccordion}>
        <AccordionTitle data-testid={rest['data-testid']}>{title}</AccordionTitle>
        <i className={`arrow-icon la la-arrow-circle-${isOpen ? 'up' : 'down'}`} />
      </AccordionHeader>
      {isOpen && <div>{children}</div>}
    </AccordionContainer>
  );
};

export default Accordion;
