import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px 3em 1em;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 8px;

  & > * {
    width: auto;
  }
`;

export const SDownloadIconWrapper = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
