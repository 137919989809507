import {useEffect, useState} from 'react';
import {useEffectOnce} from 'react-use';

import {MarkToMarket} from 'types/api';
import {DetailsTabType} from 'types/layout';
import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';
import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {
  calculateMarkToMarketForTrade,
  findExchangeRateByTrade,
  getTradeLateSettlementFeeActions,
} from 'utils/TradeUtils';

import {useUser} from 'contexts/auth-context';

import {useModal} from 'contexts/modal-context';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {detailsTab, relatedOrderTab, cashFlowTab, eventsTab} from 'constants/layout';
import Tabs from 'ui/Tabs';
import Popup from 'ui/Popup';
import {Tab} from 'ui/Tabs/types';

import StatusIcon, {AdditionalIcon} from 'containers/Blotter/components/StatusIcon';
import {TradePopupFooter} from 'components/popups/TradePopup/components/Footer';
import {CashFlowTab} from 'components/CashFlowTab/index';

import DetailsTable from './components/DetailsTable';
import RelatedOrder from './components/RelatedOrder';
import {TradePopupProps} from './types';
import 'containers/Blotter/components/StatusIcon/index.scss';

import {SContainer, SRow} from 'ui/Layout/styles';

import './TradePopup.scss';
import TradeEvents from './components/TradeEvents';

import {SStatus} from '../OrderPopup/styles';
import {FileDownloads} from './components/FileDownloads';
import {checkExecutionDetailsValid} from './utils';

const clobTabs: Tab[] = [detailsTab, relatedOrderTab, cashFlowTab, eventsTab];
const rfqTabs: Tab[] = [detailsTab, cashFlowTab, eventsTab];

const TradePopup = ({modelId, onClose, tradePopupTab}: TradePopupProps) => {
  const {closeModal} = useModal();
  const user = useUser();
  const [currentTab, setCurrentTab] = useState<Tab>(tradePopupTab ?? detailsTab);
  const {exchangeRates} = useRealTimeExchangeRatesQuery();
  const [markToMarket, setMarkToMarket] = useState<MarkToMarket | null>(null);
  const {selectTradeById} = useRealTimeTradesQuery();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  const trade = selectTradeById(modelId);

  useEffect(() => {
    if (!trade) return;

    const latestFetchedRate = findExchangeRateByTrade(trade, exchangeRates);
    if (!latestFetchedRate) return;

    setMarkToMarket(calculateMarkToMarketForTrade(trade, latestFetchedRate.rate));
  }, [JSON.stringify(exchangeRates), JSON.stringify(trade)]);

  useEffect(() => {
    if (!trade) return;

    cashFlowTab.notifications = getTradeLateSettlementFeeActions(trade, user);
  }, [JSON.stringify(trade), JSON.stringify(user)]);

  useEffectOnce(() => {
    if (currentTab.value === DetailsTabType.RelatedOrder && !trade?.relatedOrder) {
      setCurrentTab(detailsTab);
    }
  });

  if (!trade) return null;

  const handleChangeTab = (newTab: Tab): void => {
    setCurrentTab(newTab);
  };

  const executionDetailsValid = checkExecutionDetailsValid(trade);
  const inMillions = shouldDisplayInMillions({
    base: trade.baseAmount.asset,
    second: trade.secondAmount.asset,
  });

  return (
    <Popup
      title='Trade details'
      onClose={() => {
        onClose?.();
        closeModal();
      }}
    >
      <SContainer fluid>
        <SRow className='details-popup__header'>
          <Tabs tabs={!trade.relatedOrder ? rfqTabs : clobTabs} value={currentTab} onChange={handleChangeTab}>
            <SStatus data-testid='header-status-tab'>
              Status
              <StatusIcon status={trade.status} />
              <AdditionalIcon trade={trade} />
              <FileDownloads canDownloadFIXML={executionDetailsValid} tradeId={modelId} />
            </SStatus>
          </Tabs>
        </SRow>
        {currentTab.value === DetailsTabType.Details && (
          <>
            <DetailsTable trade={trade} markToMarket={markToMarket} />
            <TradePopupFooter trade={trade} />
          </>
        )}
        {currentTab.value === DetailsTabType.RelatedOrder && trade.relatedOrder && (
          <RelatedOrder relatedOrder={trade.relatedOrder} inMillions={inMillions} />
        )}
        {currentTab.value === DetailsTabType.CashFlows && <CashFlowTab tradeId={modelId} />}
        {currentTab.value === DetailsTabType.Events && <TradeEvents tradeId={modelId} />}
      </SContainer>
    </Popup>
  );
};

export default TradePopup;
