import styled, {keyframes} from 'styled-components';
import {layout, LayoutProps} from '@techstack/styled-system';
import {color} from 'utils/theme';

const animation = keyframes`
  to {
    background-position-x: -200%;
  }
`;

const Skeleton = styled.div<LayoutProps>`
  background: linear-gradient(110deg, ${color('gray500')} 8%, ${color('gray470')} 18%, ${color('gray500')} 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s ${animation} linear infinite;
  ${layout}
`;

export default Skeleton;
