import {User} from 'types/api';

import {useMatch} from 'react-router';

import {LayoutPath} from 'types/layout';

import {Scope} from 'constants/permission-maps';

import {logoUrl} from 'constants/app_defaults';

import MenuList from './MenuList';
import CurrentDateTime from './CurrentDateTime';
import SignOut from './SignOut';
import NotificationsButton from './NotificationsButton';
import PermissionsGate from '../PermissionGate';
import Help from './Help';
import BalanceContainer from './components/BalanceContainer';

export interface UserMenuProps {
  user?: User;
  notifications?: boolean;
  balances?: boolean;
  signOutCallback(): void;
  helpCallback(): void;
}

const UserMenu = ({signOutCallback, helpCallback, user, notifications = true, balances = true}: UserMenuProps) => {
  const isChartView = useMatch(LayoutPath.ChartView);
  return (
    <MenuList.List>
      {isChartView && balances && <BalanceContainer />}

      {notifications && (
        <PermissionsGate scopes={[Scope.ViewNotifications]}>
          <MenuList.Item id='application-date' className='user-date'>
            <NotificationsButton path='/notifications' />
          </MenuList.Item>
        </PermissionsGate>
      )}

      <MenuList.Item id='application-date' className='user-date'>
        <CurrentDateTime />
      </MenuList.Item>

      <MenuList.Item id='customer-name' className='user-name user'>
        <span className='names'>
          {user?.firstName && user?.lastName ?
            <span>{`${user.firstName} ${user.lastName}`}</span>
          : null}
          <span data-testid='role-name-span'>{user?.role}</span>
        </span>
      </MenuList.Item>

      <MenuList.Item id='customer-logo' className='user-logo'>
        <img className='bank-logo' src={logoUrl} alt='Logo' />
      </MenuList.Item>

      <MenuList.Item id='help' className='user-help'>
        <Help helpCallback={helpCallback} />
      </MenuList.Item>

      <MenuList.Item id='sign-out' className='user-sign-out'>
        <SignOut signOutCallback={signOutCallback} />
      </MenuList.Item>
    </MenuList.List>
  );
};

export default UserMenu;
