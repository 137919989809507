import {useIntl} from 'react-intl';

import {ReferenceData} from 'types/layout';
import useReferenceDataStore from 'stores/useReferenceDataStore';

import {useSetupFlash} from 'utils/AnimationUtils';

import {useFormContext} from 'react-hook-form';

import {RFQRequest} from 'types/api';

import dayjs from 'dayjs';

import {determineTradedAssetForQuote, getSpotRangeBigFigForComputation} from 'utils/RFQUtils';

import {fieldTitles} from 'constants/messages/labels';

import FieldTitle from 'ui/FieldTitle';

import {FormRow, FormCell} from 'components/popups/CreateQuote/styles';
import {FormSuggestedForwardPointsField} from 'components/FormFields/ForwardPointsField';
import {FormSuggestedInterestRateField} from 'components/FormFields/InterestRateField';

import {ExpectedInterestMessageProps} from 'components/ExpectedInterestMessage';

import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';

interface Props {
  quoteFormRequest: RFQRequest;
  isRepo: boolean;
  flashId?: string;
  disabled?: boolean;
  warning?: boolean;
  highlightEmpty?: boolean;
  setIsImpliedYieldFieldTouched?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InterestOrForwardField({
  isRepo,
  flashId = '',
  disabled = false,
  highlightEmpty,
  setIsImpliedYieldFieldTouched,
  warning,
  quoteFormRequest,
}: Props) {
  const {formatMessage} = useIntl();
  const {isFlashing} = useSetupFlash(flashId);
  const {referenceData} = useReferenceDataStore();
  const methods = useFormContext<CreateQuoteFormValues>();

  const [baseAsset, spotRate, rate, tradedAmount, assetPair] = methods.watch([
    'assetPair.base',
    'exchangeRate.value',
    'rate',
    'quoteTradedAmount',
    'assetPair',
  ]);
  const interestSideHelperProps: ExpectedInterestMessageProps = {
    side: quoteFormRequest.requesteeSide,
    rate,
    startTime: !quoteFormRequest.nearLegTime ? 'ASAP' : dayjs(quoteFormRequest.nearLegTime),
    maturityTime: dayjs(quoteFormRequest.maturityTime),
    tradedAmount,
    tradedAsset:
      assetPair ?
        determineTradedAssetForQuote(assetPair, quoteFormRequest.requesteeSide)
      : quoteFormRequest.tradedAmount.asset,
    spotRate,
    baseAsset,
    spotRange: getSpotRangeBigFigForComputation(quoteFormRequest.requestType),
    debounceTime: 1500,
  };
  const isFwdPts = referenceData === ReferenceData.ForwardPoints;
  const shouldUseFwdPts = !isRepo && referenceData === ReferenceData.ForwardPoints;

  const getFieldTitle = () => {
    if (isRepo) return formatMessage(fieldTitles.rate);

    return isFwdPts ? formatMessage(fieldTitles.fwdPts) : formatMessage(fieldTitles.impYield);
  };

  return (
    <FormRow>
      <FormCell>
        <FieldTitle>{getFieldTitle()}</FieldTitle>
      </FormCell>
      <FormCell>
        {shouldUseFwdPts ?
          <FormSuggestedForwardPointsField
            flash={isFlashing}
            data-testid='fwd-points-field'
            disabled={disabled}
            warning={warning}
            helperProps={interestSideHelperProps}
            highlightEmpty={highlightEmpty && !disabled}
            setIsImpliedYieldFieldTouched={setIsImpliedYieldFieldTouched}
          />
        : <FormSuggestedInterestRateField
            flash={isFlashing}
            data-testid='imp-yield-field'
            disabled={disabled}
            warning={warning}
            helperProps={interestSideHelperProps}
            highlightEmpty={highlightEmpty && !disabled}
            setIsImpliedYieldFieldTouched={setIsImpliedYieldFieldTouched}
          />
        }
      </FormCell>
    </FormRow>
  );
}
