import {Outlet, useMatch} from 'react-router-dom';

import {useEffect, useState} from 'react';

import {shouldShowVolumeChart} from 'utils/utils';
import {getRfqRequestsRequiringAction} from 'utils/RFQUtils';

import {showToast} from 'utils/ToastUtils';

import {BlotterPosition, LayoutPath} from 'types/layout';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import useBlotterPositionStore from 'stores/useBlotterPositionStore';

import {SCol, SContainer, SRow} from 'ui/Layout/styles';
import {Blotter} from 'containers/Blotter';
import Header from 'containers/Header/Header';

import {SOutletWrapper, getContainerStyles} from './styles';
import {LeftSideBar} from './LeftSideBar';
import {RightSideBar} from './RightSideBar';

export const MainPage = () => {
  const isChartView = useMatch(LayoutPath.ChartView);
  const isCapacityView = useMatch(LayoutPath.CapacityView);
  const isAnalyticsView = useMatch(LayoutPath.AnalyticsView);
  const {rfqRequestsReceived} = useRealTimeRequestsQuery();
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(0);
  const [blotterPosition] = useBlotterPositionStore();
  const showVolumeChart = shouldShowVolumeChart();

  useEffect(() => {
    const secondsFromLastUpdate: number = (new Date().getTime() - lastUpdateTime) / 1000;
    const currentRFQRequestsWaitingForReply: number = getRfqRequestsRequiringAction(rfqRequestsReceived);
    const newRFQRequestsWaitingForReply: number = getRfqRequestsRequiringAction(rfqRequestsReceived);
    if (newRFQRequestsWaitingForReply > currentRFQRequestsWaitingForReply) {
      showToast('New Request for Quote received', 'warning');
    }

    if (secondsFromLastUpdate > 5) {
      setLastUpdateTime(new Date().getTime());
    }
  }, [lastUpdateTime, JSON.stringify(rfqRequestsReceived)]);

  const mainpageContainerWidth = isChartView && showVolumeChart ? 3 / 4 : 5 / 6;
  const outletContainerWidth = isCapacityView || isAnalyticsView ? 1 : 3 / 4;

  return (
    <SContainer className='Layout' fluid>
      <SRow>
        <SCol width='100%'>
          <Header />
        </SCol>
      </SRow>
      <SRow flexDirection={['column', 'row', 'row']}>
        <LeftSideBar />

        <SCol id='mainpage-container' width={[1, mainpageContainerWidth]} height='calc(100vh - 70px)'>
          <SRow style={getContainerStyles(blotterPosition || BlotterPosition.Default)}>
            <SCol width={[1, outletContainerWidth]} height='100%'>
              <SOutletWrapper>
                <Outlet />
              </SOutletWrapper>
            </SCol>
            <RightSideBar />
          </SRow>
          <SRow>
            <SCol width='100%'>
              <Blotter />
            </SCol>
          </SRow>
        </SCol>
      </SRow>
    </SContainer>
  );
};
