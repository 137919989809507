import {ParaWrapper, HelperParagraph} from './styles';

/** Component to be used for popup footers where we need to break down the text into multiple lines */

interface PopupHelperTextProps {
  helperText: string;
}

const PopupHelperText = ({helperText}: PopupHelperTextProps) => {
  const separatedText = helperText.split('\n');

  return (
    <ParaWrapper>
      {separatedText.map((text, idx) => (
        <HelperParagraph key={idx}>{text}</HelperParagraph>
      ))}
    </ParaWrapper>
  );
};

export default PopupHelperText;
