import {Trade} from 'types/api';

import {Scope} from 'constants/permission-maps';
import PermissionGate from 'components/PermissionGate';
import {FooterContainer} from 'components/popups/TradePopup/styles';

import TradeAnnulButton from '../TradeAnnulButton';
import TradeUnwindButton from '../TradeUnwindButton';
import TradeEarlyMaturityRequestButton from '../TradeEarlyMaturityRequestButton';

export interface Props {
  trade: Trade;
}

export const TradePopupFooter = ({trade}: Props) => {
  return (
    <FooterContainer>
      <PermissionGate scopes={[Scope.CanTrade]}>
        <TradeAnnulButton trade={trade} />
        <TradeUnwindButton trade={trade} />
      </PermissionGate>
      <PermissionGate scopes={[Scope.ManageEarlyMaturationRequests]}>
        <TradeEarlyMaturityRequestButton trade={trade} />
      </PermissionGate>
    </FooterContainer>
  );
};
