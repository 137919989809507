import styled from 'styled-components';

export const Header = styled.div`
  padding: 6px 0;
  border-bottom: solid ${p => p.theme.colors.blue700} 6px;
  display: flex;
  flex-flow: row;
`;

export const HeaderColumn = styled.span`
  flex-grow: 1;
`;

export const Content = styled.div`
  border-bottom: solid ${p => p.theme.colors.blue700} 6px;
`;

export const TableValue = styled.p`
  color: ${p => p.theme.colors.white};
  text-align: center;
`;

export const TableBottom = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  text-align: center;

  button {
    font-size: 14px;
    padding: 4px 16px;
    margin: 4px 0;
  }
  .reject-button {
    font-size: 10px;
  }
`;
