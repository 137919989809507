import {Outlet, useMatch} from 'react-router-dom';

import {LayoutPath} from 'types/layout';

import Box from 'ui/Box';
import Header from 'containers/Header/Header';
import {ClobSideBar} from 'containers/ClobSideBar';

export const ObserverLayout = () => {
  const isChartView = useMatch(LayoutPath.ChartView);

  return (
    <>
      <Header nav={false} notifications={false} balances={false} />
      <Box display='flex' w='100%'>
        {isChartView && (
          <Box minW={320}>
            <ClobSideBar />
          </Box>
        )}
        <Box flex={1}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
