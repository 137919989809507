import {useIntl} from 'react-intl';
import findKey from 'lodash/findKey';
import {Trade, TradeStatus, TradeLateSettlementFeesStatus, OrderStatus} from 'types/api';
import {displayLateSettlementFeeStatus} from 'utils/TradeUtils';

import {
  orderStatuses,
  tradeLateSettlementFeesStatuses,
  tradeRequestDetailedStatus,
  tradeStatuses,
} from 'constants/messages/labels';

import './index.scss';
import {STATUS_COLOR_DICT, STATUS_ICON_DICT} from './constants';

export enum DetailedTradeRequestStatus {
  UnwindRequested = 'UnwindRequested',
  AnnulRequested = 'AnnulRequested',
  UnwindRejected = 'UnwindRejected',
  UnwindCancelled = 'UnwindCancelled',
  UnwindExpired = 'UnwindExpired',
  AnnulRejected = 'AnnulRejected',
  AnnulCancelled = 'AnnulCancelled',
  AnnulExpired = 'AnnulExpired',
}

export interface Props {
  status: TradeStatus | TradeLateSettlementFeesStatus | OrderStatus | DetailedTradeRequestStatus;
  statusMessage?: string;
}

function getIconClass(status: string) {
  const iconClass = findKey(STATUS_ICON_DICT, o => o.includes(status)) || 'la-ban';
  const colorClass = findKey(STATUS_COLOR_DICT, o => o.includes(status)) || '';
  return `${iconClass} ${colorClass}`;
}

const StatusIcon = ({status, statusMessage}: Props) => {
  const {formatMessage} = useIntl();
  const classNames = getIconClass(status);

  const renderStatus = (status: string) => {
    switch (true) {
      case Object.values(TradeLateSettlementFeesStatus).includes(status as TradeLateSettlementFeesStatus):
        return formatMessage(tradeLateSettlementFeesStatuses[status as TradeLateSettlementFeesStatus]);
      case Object.values(TradeStatus).includes(status as TradeStatus):
        return formatMessage(tradeStatuses[status as TradeStatus]);
      case Object.values(OrderStatus).includes(status as OrderStatus):
        return formatMessage(orderStatuses[status as OrderStatus]);
      case Object.values(DetailedTradeRequestStatus).includes(status as DetailedTradeRequestStatus):
        return formatMessage(tradeRequestDetailedStatus[status as DetailedTradeRequestStatus]);
      default:
        return status;
    }
  };

  return (
    <div className='status-icon-container'>
      <div className='icon-box'>
        <i data-testid={`status-${status?.toLowerCase()}-icon`} className={`la ${classNames} status-icon`} />
        <div data-testid='status-tooltip' className='status-tooltip'>
          <p>
            {renderStatus(status)}
            <br />
            {statusMessage}
          </p>
        </div>
      </div>
    </div>
  );
};

function getActiveTradeRequestStatus(trade: Trade) {
  if (trade.tradeAnnul?.status === 'Proposed') return DetailedTradeRequestStatus.AnnulRequested;
  if (trade.tradeUnwind?.status === 'Proposed') return DetailedTradeRequestStatus.UnwindRequested;
}

function getInactiveTradeRequestStatus(trade: Trade) {
  if (trade.tradeUnwind?.status === 'Rejected') return DetailedTradeRequestStatus.UnwindRejected;
  if (trade.tradeAnnul?.status === 'Rejected') return DetailedTradeRequestStatus.AnnulRejected;
  if (trade.tradeUnwind?.status === 'Expired') return DetailedTradeRequestStatus.UnwindExpired;
  if (trade.tradeAnnul?.status === 'Expired') return DetailedTradeRequestStatus.AnnulExpired;
  if (trade.tradeUnwind?.status === 'Cancelled') return DetailedTradeRequestStatus.UnwindCancelled;
  if (trade.tradeAnnul?.status === 'Cancelled') return DetailedTradeRequestStatus.AnnulCancelled;
}

export function AdditionalIcon({trade}: {trade: Trade}) {
  const activeTradeRequest = getActiveTradeRequestStatus(trade);
  const inactiveTradeRequest = getInactiveTradeRequestStatus(trade);
  const lateSettlementFeeStatus = displayLateSettlementFeeStatus(trade);
  if (activeTradeRequest) return <StatusIcon status={activeTradeRequest} />;
  if (lateSettlementFeeStatus) return <StatusIcon status={lateSettlementFeeStatus} />;
  if (inactiveTradeRequest) return <StatusIcon status={inactiveTradeRequest} />;
  return null;
}

export default StatusIcon;
