import {useQuery, useQueryClient} from '@tanstack/react-query';
import {ReferenceData} from 'types/layout';

const QUERY_KEY = ['ReferenceData'];

export default function useReferenceDataStore() {
  const {data} = useQuery({
    queryKey: QUERY_KEY,
    initialData: ReferenceData.ForwardPoints,
  });
  const client = useQueryClient();
  const setReferenceData = (referenceData: ReferenceData) => {
    client.setQueryData(QUERY_KEY, referenceData);
  };
  return {referenceData: data, setReferenceData};
}
