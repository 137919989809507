import styled from 'styled-components';

import {color} from 'utils/theme';

import {SCol, SContainer} from 'ui/Layout/styles';

export const SBalanceHeader = styled.div`
  font-weight: bold;
  background-color: ${color('gray500')};
  padding: 1rem 1rem 1.8rem;

  span {
    margin-right: 2.2rem;
  }

  .equiv {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
  }
`;

export const SBalanceContent = styled(SContainer)`
  background-color: ${color('blue900')};
  padding: 12px 14px;
`;

export const SBalanceRightContent = styled(SCol)`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const SBalanceChartContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const SHeaderCurrency = styled.span`
  font-weight: bold;
  background-color: ${color('gray500')};
  padding: 1rem 1rem 1.8rem;
`;

export const SButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SBalanceLabels = styled.div`
  .row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
  }

  .color {
    width: 13px;
    height: 13px;

    &--available {
      background-color: ${color('gray100')};
    }
    &--earmark {
      background-color: ${color('gray300')};
    }
  }

  .name {
    font-size: 13px;
    text-align: right;
    text-transform: uppercase;

    &--amount {
      display: block;
      font-size: 10px;
      color: ${color('gray100')};
      margin-top: 0.4rem;
    }
  }
`;
