import React from 'react';

import {isRFQRequestExpired, rfqRequestHasQuote} from 'utils/RFQUtils';
import {RFQRequest} from 'types/api';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import RFQOverviewPopup from 'components/popups/RFQOverviewPopup';

import ReceivedRequestListItem from '../ReceivedRequestListItem';
import {ListContainer, ListHeader} from '../../styles';

const QuoteProvided: React.FC = () => {
  const {openModal} = useModal();
  const {rfqRequestsReceived} = useRealTimeRequestsQuery();
  return (
    <ListContainer>
      <ListHeader>Quote Provided</ListHeader>
      {rfqRequestsReceived.map((request: RFQRequest) => {
        const isExpired: boolean = isRFQRequestExpired(request);
        const hasQuote: boolean = rfqRequestHasQuote(request);
        const handleClick = () =>
          openModal({modal: RFQOverviewPopup, props: {requestId: request.id, defaultView: 'comparison'}});

        return (
          !isExpired &&
          hasQuote && <ReceivedRequestListItem key={request.id} request={request} showComparison={handleClick} />
        );
      })}
    </ListContainer>
  );
};

export default QuoteProvided;
