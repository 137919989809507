import {useCallback, useState} from 'react';
import {AxiosError} from 'axios';
import {useIntl} from 'react-intl';
import dayjs from 'dayjs';

import {cancelRfqNegotiation, cancelRfqRequest} from 'api/api';
import {ErrorResponse, Party, RequesteeAndQuote} from 'types/api';
import {displayEntities} from 'utils/utils';
import {showToast, showErrorToast} from 'utils/ToastUtils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayAssetPairWithSide} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {fieldTitles} from 'constants/messages/labels';

import Popup from 'ui/Popup';
import Loader from 'ui/Loader';
import Countdown from 'ui/Countdown';
import Button from 'ui/Button';
import {Row} from 'ui/Table/types';
import FieldTitle from 'ui/FieldTitle';

import {StyledTable, Title, CountdownContainer, Cell, Bottom} from 'containers/RFQPopup/styles';

interface Props {
  requestId: string;
  selectedNegotiationId?: string;
  selectedRequestee?: Party;
}

export const CancelRequestConfirmation = ({requestId, selectedNegotiationId, selectedRequestee}: Props) => {
  const [loading, setLoading] = useState(false);
  const {goBack, closeModal} = useModal();
  const {selectRfqById} = useRealTimeRequestsQuery();
  const request = selectRfqById(requestId)!;
  const {formatMessage} = useIntl();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base: request.baseAsset, second: request.secondAsset});

  // Below function returns only the parties that have not responded to the RFQ in any way
  function filterStartedQuotes(quotes: RequesteeAndQuote[]) {
    return quotes.filter(quote => quote.stage.name === 'Started').map(startedQuote => startedQuote.requestee);
  }

  const requesteeList = selectedRequestee ? [selectedRequestee] : filterStartedQuotes(request.requesteesAndQuotes);

  // i18n

  const {id, validUntil, baseAsset, tradedAmount, secondAsset, requestorSide} = request;

  const onConfirm = useCallback(() => {
    setLoading(true);

    const cancellationPromise =
      selectedNegotiationId ? cancelRfqNegotiation(id, selectedNegotiationId) : cancelRfqRequest(id);

    cancellationPromise
      .then(() => {
        showToast('Request for Quote successfully cancelled.', 'success');
        setLoading(false);
        closeModal();
      })
      .catch((response: AxiosError<ErrorResponse>) => {
        showErrorToast(response);
        setLoading(false);
        closeModal();
      });
  }, [id, selectedNegotiationId]);

  const validFor: Row = [
    <FieldTitle key={0}>{formatMessage(fieldTitles.validFor)}</FieldTitle>,
    <CountdownContainer key={1}>
      <Countdown timeTarget={dayjs(validUntil)} />
    </CountdownContainer>,
  ];

  const counterparty: Row = [
    <FieldTitle key={2}>{formatMessage(fieldTitles.counterparties)}</FieldTitle>,
    <Cell key={3}>{displayEntities(requesteeList)}</Cell>,
  ];

  const yourSide: Row = [
    <FieldTitle key={4}>{formatMessage(fieldTitles.yourSide)}</FieldTitle>,
    <Cell key={5}>{displayAssetPairWithSide({base: baseAsset, second: secondAsset}, requestorSide, true)}</Cell>,
  ];

  const tradedAmountRow: Row = [
    <FieldTitle key={6}>{formatMessage(fieldTitles.tradedAmt)}</FieldTitle>,
    <Cell key={7}>{displayAmountWithCode(tradedAmount, false, inMillions)}</Cell>,
  ];

  const rows: Row[] = [validFor, counterparty, yourSide, tradedAmountRow];

  return (
    <Popup title='Cancel RFQ' onClose={goBack} width='360px'>
      {loading ?
        <Loader />
      : undefined}
      <Title>
        Are you sure you want to cancel the following request for quote? RFQ Quotes already received will not be
        cancelled or rejected.
      </Title>
      <StyledTable rows={rows} />
      <Bottom>
        <Button buttonStyle='secondary' onClick={onConfirm}>
          Confirm
        </Button>
      </Bottom>
    </Popup>
  );
};
