import ReconnectingEventSource from 'reconnecting-eventsource';

export interface CreateEventSourceProps<T>
  extends Pick<EventSource, 'url'>,
    Partial<Pick<EventSource, 'onopen' | 'onerror'>> {
  onmessage?: (e: MessageEvent<T>) => void;
  lastEventId?: string;
  timeBeweenReconections?: number;
}

// TODO: Move to api/utils
export const createEventSource = <T>({
  url,
  onmessage,
  onerror,
  onopen,
  lastEventId,
  timeBeweenReconections,
}: CreateEventSourceProps<T>) => {
  const eventSource = new ReconnectingEventSource(url, {
    lastEventId,
    max_retry_time: timeBeweenReconections,
    withCredentials: true,
  });

  if (onmessage) {
    // TODO: Check lastEventId can be lost if .addEventListener is not used ??
    eventSource.onmessage = (e: MessageEvent<string>) =>
      onmessage({
        ...e,
        data: JSON.parse(e.data) as T,
      });
  }

  if (onerror) {
    eventSource.onerror = onerror;
  }

  if (onopen) {
    eventSource.onopen = onopen;
  }

  return eventSource;
};
