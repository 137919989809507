import styled from 'styled-components';

import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

export const Container = styled.div<{shouldHideForm: boolean}>`
  background-color: ${p => p.theme.colors.blue900};
  height: inherit;
  overflow-y: auto;
  & form {
    ${p => p.shouldHideForm && 'display: none;'}
  }
`;

export const FormRow = styled.div<{minHeight?: number}>`
  display: flex;
  padding: 8px 20px;
  align-items: center;
  min-height: ${props => props.minHeight}px;
`;

export const FormContainer = styled.form`
  position: relative;
  text-align: center;
  padding-bottom: 1em;
  > ${FormRow} {
    padding-top: 1rem;
  }
`;

export const Label = styled.span`
  width: 35%;
  text-align: left;
  font-size: 0.6875rem;
`;

export const Field = styled.div`
  width: 65%;
  text-align: center;
`;

export const ConfirmationContainer = styled.div`
  position: relative;
  height: inherit;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: ${p => p.theme.colors.blue900};
  padding: 0 0 1em 0;
  overflow-y: auto;
`;

export const TabContent = styled.div`
  position: relative;
  width: 100%;
  display: block;
  padding-top: 1em;
`;

export const TabHeader = styled.div`
  text-align: center;
  color: ${p => p.theme.colors.blue50};
  font-size: 0.875rem;
  padding: 0 0.2em;
`;

export const TableLabel = styled.span`
  text-align: left;
  cursor: pointer;
`;

export const TableValue = styled.span`
  text-align: left;
`;

export const RequestButtonTooltip = styled(TooltipWrapper)`
  width: 100%;
  flex-grow: 7;
  z-index: 0;
`;

export const SButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
`;
