import finteumLogoUrl from 'assets/images/finteum-logo.svg';
import styled from 'styled-components';
import waveUrl from 'assets/images/wave.jpg';

const SContainer = styled.div`
  background-image: url(${waveUrl});
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const SFinteumLogo = styled.img`
  max-width: 275px;
`;

const LoginFiller = () => (
  <SContainer>
    <SFinteumLogo src={finteumLogoUrl} />
  </SContainer>
);

export default LoginFiller;
