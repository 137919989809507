import {useCallback} from 'react';

import {RFQRequest} from 'types/api';
import {
  getRfqRequestsRequiringAction,
  sortMultipleRequesteesAndQuotes,
  sortRfqRequesteesAndQuotes,
} from 'utils/RFQUtils';
import {unifiedEventStream} from 'api/unifiedEventStream';

import {QueryClient} from '@tanstack/react-query';
import unionBy from 'lodash/unionBy';

import {getRfqRequests} from '../api';
import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['RealTimeRequests'];

interface Data {
  rfqRequestsReceived: RFQRequest[];
  rfqRequestsSent: RFQRequest[];
  requestsRequiringAction: number;
  lastEventId: string;
}

function subscribeForRequests(client: QueryClient, lastEventId?: string) {
  const handler = (request: RFQRequest) => {
    const dataKey = request.isReceived ? 'rfqRequestsReceived' : 'rfqRequestsSent';
    const sortedRequestToUpdate: RFQRequest = sortRfqRequesteesAndQuotes(request);
    client.setQueryData<Data>(QUERY_KEY, data => {
      if (!data) return;
      const updatedRequests = unionBy([sortedRequestToUpdate], data[dataKey], 'id');
      const waitingRequests =
        request.isReceived ? getRfqRequestsRequiringAction(updatedRequests) : data.requestsRequiringAction;
      return {...data, requestsRequiringAction: waitingRequests, [dataKey]: updatedRequests};
    });
    return {type: ''};
  };

  return unifiedEventStream.subscribe('request', handler, {lastEventId: lastEventId || '0,0'});
}

export const useRealTimeRequestsQuery = () => {
  const {data} = useSubscriptionQuery(
    {
      queryKey: QUERY_KEY,
      queryFn: async () => {
        const response = await getRfqRequests();

        const requests = [...response.data].reverse();
        const lastEventId = response.headers['x-last-event-id'];

        const receivedRequests = requests.filter(request => request.isReceived);
        const sentRequests = requests.filter(request => !request.isReceived);

        const rfqRequestsReceived = sortMultipleRequesteesAndQuotes(receivedRequests);
        const rfqRequestsSent: RFQRequest[] = sortMultipleRequesteesAndQuotes(sentRequests);
        const requestsRequiringAction = getRfqRequestsRequiringAction(rfqRequestsReceived);

        return {data: {rfqRequestsReceived, rfqRequestsSent, requestsRequiringAction}, lastEventId};
      },
    },
    subscribeForRequests
  );

  const selectRfqById = useCallback(
    (id: string) =>
      [...(data?.rfqRequestsReceived || []), ...(data?.rfqRequestsSent || [])].find(request => request.id === id),
    [data]
  );

  return {
    rfqRequestsReceived: data?.rfqRequestsReceived || [],
    rfqRequestsSent: data?.rfqRequestsSent || [],
    requestsRequiringAction: data?.requestsRequiringAction || 0,
    selectRfqById,
  };
};
