import {AxiosError} from 'axios';
import {useIntl} from 'react-intl';

import {rejectRfqQuote} from 'api/api';
import {ErrorResponse, RFQQuoteRejectionReason} from 'types/api';
import {showToast, showErrorToast} from 'utils/ToastUtils';
import useRejectConfirmationReasons from 'utils/hooks/useRejectConfirmationReasons';

import {useModal} from 'contexts/modal-context';

import {useRealTimeQuotesQuery} from 'api/hooks/useRealTimeQuotesQuery';

import {QUOTE_REJECTION_REASONS} from 'constants/rfqForm';
import {fieldTitles} from 'constants/messages/labels';

import Popup from 'ui/Popup';
import {Row} from 'ui/Table/types';
import Button from 'ui/Button';
import Loader from 'ui/Loader';
import FieldTitle from 'ui/FieldTitle';
import {CheckBox} from 'ui/CheckBox';

import {StyledTable, ButtonsContainer, Line, Title} from 'containers/RFQPopup/styles';
import {useQuoteDetailsRows} from 'containers/RFQPopup/hooks/useQuoteDetailsRows';
import {LabelWithTooltip} from 'components/LabelWithTooltip/LabelWithTooltip';

const popupHeaderStyles = {fontSize: '1rem'};

interface Props {
  quoteId: string;
}

export const RejectQuoteConfirmation = ({quoteId}: Props) => {
  const {selectQuoteWithRequestByQuoteId} = useRealTimeQuotesQuery();
  const quoteAndRequest = selectQuoteWithRequestByQuoteId(quoteId);
  const quoteRows = useQuoteDetailsRows(quoteAndRequest ?? null);
  const {loading, rejectionReasons, setLoading, handleCheckbox} =
    useRejectConfirmationReasons<RFQQuoteRejectionReason>();
  const {formatMessage} = useIntl();
  const {closeModal} = useModal();

  const onConfirm = (): void => {
    if (quoteAndRequest && quoteAndRequest.quote) {
      setLoading(true);
      rejectRfqQuote(quoteAndRequest.requestId, quoteAndRequest.quote.id, rejectionReasons)
        .then(() => {
          showToast('Quote successfully rejected.', 'success');
          setLoading(false);
          closeModal();
        })
        .catch((response: AxiosError<ErrorResponse>) => {
          showErrorToast(response);
          setLoading(false);
          closeModal();
        });
    }
  };

  if (!(quoteAndRequest && quoteAndRequest.quote)) {
    return (
      <Popup
        title='Please select a quote to show.'
        headerStyles={popupHeaderStyles}
        onClose={closeModal}
        width='450px'
      />
    );
  }

  const reasonsTitle: Row = [
    <LabelWithTooltip
      key='rejection-reasons-title'
      tooltip='Your counterparty will receive this information'
      tooltipPosition={'right'}
    >
      <FieldTitle>Send reasons (optional):</FieldTitle>
    </LabelWithTooltip>,
  ];

  // Map reasons
  const reasons = Object.keys(QUOTE_REJECTION_REASONS).map(reason => [
    <Line data-testid='rejection-reason' key={reason}>
      {QUOTE_REJECTION_REASONS[reason as RFQQuoteRejectionReason]}
      <CheckBox
        data-testid='rejection-reason-checkbox'
        checked={rejectionReasons.includes(reason as RFQQuoteRejectionReason)}
        value={reason}
        onChange={event => handleCheckbox(event.target.value as RFQQuoteRejectionReason)}
      />
    </Line>,
  ]);

  const uniqueRows = [reasonsTitle, ...reasons];

  return (
    <Popup title='Confirm' headerStyles={popupHeaderStyles} onClose={closeModal} width='360px'>
      <Title>{formatMessage(fieldTitles.rejectQuoteConfirmation)}</Title>
      {loading ?
        <Loader />
      : undefined}
      <StyledTable rows={uniqueRows} elements={quoteRows} />
      <ButtonsContainer>
        <Button data-testid='confirm-rejection-button' width='100%' buttonStyle='grey' onClick={onConfirm}>
          Confirm Rejection
        </Button>
      </ButtonsContainer>
    </Popup>
  );
};
