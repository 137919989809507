import {useIntl} from 'react-intl';
import {ReferenceData} from 'types/layout';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {useFormContext} from 'react-hook-form';

import {currencyToAsset} from 'utils/AssetUtils';
import {determineTradedAssetForRequest} from 'utils/RFQUtils';

import {fieldTitles} from 'constants/messages/labels';
import FieldTitle from 'ui/FieldTitle';

import {Field, FormRow, Label} from 'containers/RFQForm/styles';
import {RFQFormValues} from 'containers/RFQForm/hooks/useRFQForm';
import {FormSuggestedInterestRateField} from 'components/FormFields/InterestRateField';
import {FormSuggestedForwardPointsField} from 'components/FormFields/ForwardPointsField';
import {ExpectedInterestMessageProps} from 'components/ExpectedInterestMessage';

interface Props {
  isRepo: boolean;
  entityShortName: string;
  disabled?: boolean;
}

export default function SuggestedRateRow({isRepo, disabled}: Props) {
  const methods = useFormContext<RFQFormValues>();
  const [
    side,
    rate,
    startTime,
    maturityTime,
    spotRange,
    tradedAmount,
    spotRate,
    baseAsset,
    currentPair,
    tradedAmountCurrency,
  ] = methods.watch([
    'side',
    'rate',
    'nearleg',
    'farleg',
    'bigfig',
    'tradedAmount',
    'exchangeRate.value',
    'currentPair.base',
    'currentPair',
    'tradedAmountCurrency',
  ]);

  const interestSideHelperProps: ExpectedInterestMessageProps = {
    side,
    rate,
    startTime,
    maturityTime,
    spotRange,
    tradedAmount,
    spotRate,
    baseAsset,
    tradedAsset:
      currentPair ? determineTradedAssetForRequest(currentPair, side) : currencyToAsset(tradedAmountCurrency),
  };

  const {referenceData} = useReferenceDataStore();
  const {formatMessage} = useIntl();

  const shouldUseFwdPts = !isRepo && referenceData === ReferenceData.ForwardPoints;

  return (
    <FormRow>
      <Label>
        <FieldTitle>
          {isRepo ?
            formatMessage(fieldTitles.suggestRate)
          : referenceData === ReferenceData.ImpliedYield ?
            formatMessage(fieldTitles.suggestImpYield)
          : formatMessage(fieldTitles.suggestFwdPts)}
        </FieldTitle>
      </Label>
      <Field>
        {shouldUseFwdPts ?
          <FormSuggestedForwardPointsField
            data-testid='fwd-points-field'
            disabled={!!disabled}
            helperProps={interestSideHelperProps}
          />
        : <FormSuggestedInterestRateField
            data-testid='imp-yield-field'
            disabled={!!disabled}
            helperProps={interestSideHelperProps}
          />
        }
      </Field>
    </FormRow>
  );
}
