import styled from 'styled-components';

export const SCloseBtn = styled.span`
  grid-area: close;
  color: ${p => p.theme.colors.white};
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  margin-left: 16px;
  margin-top: 7px;
  margin-right: 10px;

  i {
    border: 1px solid ${p => p.theme.colors.white};
  }
`;
