import {createRoot} from 'react-dom/client';
import {ThemeProvider} from 'styled-components';

import './utils/setupDayjs';

import AppProviders from 'contexts';

import {BrowserRouter} from 'react-router-dom';

import ObserverRoutes from 'routes/observerRoutes';

import ProtectedRoutes from 'routes/protectedRoutes';

import ErrorBoundary from 'containers/ErrorBoundary';

import App from './App';
import {theme} from './theme';

import './index.scss';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <BrowserRouter>
        <AppProviders>
          <App routes={window.REACT_APP_OBSERVER === 'true' ? ObserverRoutes : ProtectedRoutes} />
        </AppProviders>
      </BrowserRouter>
    </ErrorBoundary>
  </ThemeProvider>
);
