import {Header, Cell, Column} from '@tanstack/react-table';
import {flatMap} from 'lodash';

import {GridTableVariant} from './types';

export const buildGridTemplate = (columns: Column<any>[], fullWidth: boolean = false) => {
  const columnDefs = flatMap(columns, col => col.columnDef);
  const lastColumnIndex = columns.length;

  return columnDefs.reduce((acc, columnDef, index) => {
    const isLastColumn = (index + 1) % lastColumnIndex === 0;

    if (columnDef.meta?.size && typeof columnDef.meta?.size === 'number') {
      return `${acc} ${columnDef.meta?.size}%`;
    }

    if (columnDef.meta?.size && typeof columnDef.meta?.size === 'string') {
      return `${acc} ${columnDef.meta?.size}`;
    }

    if (columnDef.size) {
      if (isLastColumn && fullWidth) {
        return `${acc} ${columnDef.size}px auto`;
      }

      return `${acc} ${columnDef.size}px`;
    }

    return `${acc} 1fr`;
  }, '');
};

export const hasLeftSpacing = (
  element: Header<any, unknown> | Cell<any, unknown>,
  headers: Header<any, unknown>[]
): boolean => {
  if (!element.column.parent) {
    if ('headerGroup' in element) {
      const headerIndexInHeaderGroup = element.headerGroup.headers.findIndex(h => h.id === element.id);

      return headerIndexInHeaderGroup !== 0;
    }

    if ('row' in element) {
      const cellIndexInRow = element.row.getVisibleCells().findIndex(c => c.id === element.id);

      return cellIndexInRow !== 0;
    }
  }

  const parent = element.column.parent as Column<any>;
  const leafColumns = parent.getLeafColumns();
  const leafIndex = leafColumns.findIndex(col => col.id === element.column.id);
  const parentIndex = headers.findIndex(header => header.column.id === parent.id);

  return leafIndex === 0 && parentIndex !== 0;
};

export const hasRightSpacing = (
  element: Header<any, unknown> | Cell<any, unknown>,
  headers: Header<any, unknown>[]
): boolean => {
  if (!element.column.parent) {
    if ('headerGroup' in element) {
      const headerIndexInHeaderGroup = element.headerGroup.headers.findIndex(h => h.id === element.id);

      return headerIndexInHeaderGroup !== element.headerGroup.headers.length - 1;
    }

    if ('row' in element) {
      const cells = element.row.getVisibleCells();
      const cellIndexInRow = cells.findIndex(c => c.id === element.id);

      return cellIndexInRow !== cells.length - 1;
    }
  }

  const parent = element.column.parent as Column<any>;
  const leafColumns = parent.getLeafColumns();
  const leafIndex = leafColumns.findIndex(col => col.id === element.column.id);
  const parentHeader = headers.find(header => header.column.id === parent.id) as Header<any, unknown>;
  const parentIndex = parentHeader.headerGroup.headers.findIndex(header => header.column.id === parent.id);

  return leafIndex === leafColumns?.length - 1 && parentIndex !== parentHeader.headerGroup.headers.length - 1;
};

export const getWrapperHeight = (variant: GridTableVariant) => (variant === GridTableVariant.Regular ? 690 : 300);
