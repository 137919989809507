import {toast} from 'react-toastify';

import {cancelMessage} from 'types/blotter';
import {Order, ErrorResponse, translateErrorCodeToSeverity} from 'types/api';

import {AxiosError} from 'axios';

import {displayAmountWithCode} from './AmountUtils';

import Notification from '../components/Notification/Notification';
import {DateFormat, displayDate} from './DayjsUtils';

export type ToastType = 'error' | 'info' | 'success' | 'warning' | 'default' | undefined;

// Display toast notification
export const showToast = (message: string, type: ToastType = 'default') => {
  toast(<Notification msg={message} type={type} />, {type, className: `toast-${type}`});
};

/**
 * Show toast notification for changing order status if necessary
 *
 * @param order [Order] - Order data
 */
export const showToastForOrderStatus = (order: Order, inMillions: boolean): void => {
  const statusesToNotify: string[] = ['Cancelled', 'Invalid'];

  if (statusesToNotify.includes(order.status)) {
    const statusReason: string = order.statusReason ? order['statusReason'] : '';
    const additionalMessage: string = cancelMessage[statusReason] ? cancelMessage[statusReason] : '';

    const message: string = ` Order ${order.data.side} for ${displayAmountWithCode(
      order.data.baseAmount,
      false,
      inMillions
    )}m
        placed at ${displayDate(order.createdAt, 'DD MMM YYYY HH:mm:ss' as DateFormat)} has been cancelled.
        ${additionalMessage}`;
    toastUtilsExportRefs.showToast(message, 'warning');
  }
};

// Show toast from API error
export const showErrorToast = (error: AxiosError<ErrorResponse>): void => {
  const response = error.response;

  let userMessage: string = 'Unexpected error. Please contact support.';
  let developerMessage: string = error.toString();
  let toastType: ToastType = 'error';

  if (response) {
    if (response.status === 503 || response.status === 504) {
      userMessage = 'Have not received a timely response from the server.';
      developerMessage = `${response.status}: ${response.statusText}`;
      toastType = 'warning';
    } else {
      userMessage = response.data.userMessage;
      developerMessage = response.data.developerMessage ?? `${response.status}: ${response.statusText}`;
      toastType = response.data.code ? translateErrorCodeToSeverity(response.data.code) : 'error';
    }
  }

  console.error(developerMessage);
  toastUtilsExportRefs.showToast(userMessage, toastType);
};

// This is for testing purposes
// I'm sorry
// http://webcache.googleusercontent.com/search?q=cache:zH1ZWJkHky4J:https://medium.com/@DavideRama/mock-spy-exported-functions-within-a-single-module-in-jest-cdf2b61af642&hl=en&gl=pl&strip=1&vwsrc=0
const toastUtilsExportRefs = {
  showToast,
  showErrorToast,
  showToastForOrderStatus,
};

export default toastUtilsExportRefs;
