import {RefObject, useCallback, useEffect} from 'react';
import useBlotterPositionStore from 'stores/useBlotterPositionStore';
import useHistoryChartSizeStore from 'stores/useHistoryChartSizeStore';

export default function useWatchChartSize(ref: RefObject<HTMLDivElement>) {
  const [blotterPosition] = useBlotterPositionStore();
  const {setChartSize} = useHistoryChartSizeStore();
  const handleResize = useCallback(() => {
    const width: number = ref.current !== null ? ref.current.clientWidth : 0;
    const height: number = ref.current !== null ? ref.current.clientHeight : 0;

    setChartSize({width, height});
  }, []);

  useEffect(() => {
    if (ref.current) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [blotterPosition, handleResize]);

  useEffect(() => {
    handleResize();
  }, [ref.current, handleResize]);
}
