import styled from 'styled-components';

export const SFormLayout = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  column-gap: 5rem;
`;

export const SFormItem = styled.div`
  display: flex;
  align-items: center;
`;

export const SFormLabel = styled.span`
  width: 250px;
  text-align: left;
  font-size: 0.75rem;
`;

export const SFormField = styled.span`
  text-align: center;
`;
