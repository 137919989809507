import {DetailsTabType} from 'types/layout';

import {Tab} from 'ui/Tabs/types';

export const MIN_WINDOW_HEIGHT: number = 720;
export const MIN_WINDOW_WIDTH: number = 1500;

export const detailsTab: Tab = {
  label: DetailsTabType.Details,
  value: DetailsTabType.Details,
};

export const relatedOrderTab: Tab = {
  label: DetailsTabType.RelatedOrder,
  value: DetailsTabType.RelatedOrder,
};

export const cashFlowTab: Tab = {
  label: DetailsTabType.CashFlows,
  value: DetailsTabType.CashFlows,
};

export const eventsTab: Tab = {
  label: DetailsTabType.Events,
  value: DetailsTabType.Events,
};
