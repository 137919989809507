import styled from 'styled-components';

import {color} from 'utils/theme';

import {SContainer} from 'ui/Layout/styles';

export const SClobOffers = styled(SContainer)`
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 20px 0 10px;
  height: 100%;
  min-height: 0;
  margin-bottom: 4px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;
  border-bottom: 4px solid ${color('gray600')};

  & > :first-child {
    margin-top: auto !important;
    /* use !important to prevent breakage from child margin settings */
  }
`;
