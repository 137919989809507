import React from 'react';

import {ColumnStyled} from '../../styles';
import LatestRequestsSent from '../LatestRequestsSent';
import PreviousRequestsSent from '../PreviousRequestsSent';

const LeftSide: React.FC = () => (
  <ColumnStyled>
    <LatestRequestsSent />
    <PreviousRequestsSent />
  </ColumnStyled>
);

export default LeftSide;
