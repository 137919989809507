import {useCallback, useMemo} from 'react';
import {FlagType, Trade} from 'types/api';
import {useIntl} from 'react-intl';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';
import {useFlaggedTradesIdsQuery} from 'api/hooks/useFlaggedTradesIdsQuery';

import {useModal} from 'contexts/modal-context';
import {useUser} from 'contexts/auth-context';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import GridTable, {GridTableVariant} from 'ui/GridTable';

import TradePopup from 'components/popups/TradePopup';

import {getBlotterTradesTableColumns} from './columns';

const TradesTable = () => {
  const user = useUser();
  const {referenceData} = useReferenceDataStore();
  const {openModal} = useModal();
  const showTradeDetailsPopup = useCallback(
    (tradeDetails: Trade) => openModal({modal: TradePopup, props: {modelId: tradeDetails.id}}),
    [openModal]
  );
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const {formatMessage} = useIntl();
  const {trades} = useRealTimeTradesQuery();
  const {flaggedTradesIds} = useFlaggedTradesIdsQuery([FlagType.NearLegNonreceipt, FlagType.FarLegNonreceipt], true);

  const onRowClick = useCallback(
    (tradeDetails: Trade): void => {
      showTradeDetailsPopup(tradeDetails);
    },
    [showTradeDetailsPopup]
  );

  const columns = useMemo(
    () => getBlotterTradesTableColumns(formatMessage, referenceData, flaggedTradesIds, user, shouldDisplayInMillions),
    [formatMessage, referenceData, flaggedTradesIds, user, shouldDisplayInMillions]
  );

  const getRowId = useCallback((trade: Trade) => trade.id, []);

  return (
    <div data-testid='trades-table' className='table-container'>
      <GridTable
        columns={columns}
        data={trades}
        defaultSorted={[{id: 'createdAt', desc: true}]}
        onRowClick={onRowClick}
        sortable
        filterable
        variant={GridTableVariant.Minimal}
        getRowId={getRowId}
      />
    </div>
  );
};

export default TradesTable;
