import {logoUrl} from 'constants/app_defaults';

import {SCol} from 'ui/Layout/styles';

import Logo from '../../../HeaderBar/Logo';
import HeaderBar from '../../../HeaderBar/HeaderBar';
import {HeaderRow, NotificationCenterButton} from '../../styles';

interface Props {
  markAsReadCallback(): void;
}
const NotificationCenterHeader = ({markAsReadCallback}: Props) => (
  <HeaderBar height='auto'>
    <Logo width={[1, 1 / 4, 1 / 6]} />

    <SCol width={[1, 2 / 3, 2 / 3, 5 / 12]} mt={3} mb={3}>
      <HeaderRow>
        <li>
          <NotificationCenterButton
            data-testid='mark-all-as-read'
            buttonStyle='transparent'
            onClick={markAsReadCallback}
          >
            Mark all as read
          </NotificationCenterButton>
        </li>
        <li>
          <img className='bank-logo' src={logoUrl} alt='Bank Logo' />
        </li>
      </HeaderRow>
    </SCol>
  </HeaderBar>
);

export default NotificationCenterHeader;
