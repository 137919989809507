import React from 'react';

import {RFQRequest} from 'types/api';
import {isRFQRequestExpired, isCreatedLessThan12HoursAgo} from 'utils/RFQUtils';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import RFQOverviewPopup from 'components/popups/RFQOverviewPopup';

import ReceivedRequestListItem from '../ReceivedRequestListItem';
import {ListContainer, ListHeader} from '../../styles';

const PreviousRequestsReceived: React.FC = () => {
  const {openModal} = useModal();
  const {rfqRequestsReceived} = useRealTimeRequestsQuery();
  return (
    <ListContainer>
      <ListHeader>Previous Requests Received</ListHeader>
      {rfqRequestsReceived.filter(isCreatedLessThan12HoursAgo).map((request: RFQRequest) => {
        const handleClick = () =>
          openModal({modal: RFQOverviewPopup, props: {requestId: request.id, defaultView: 'comparison'}});
        const isExpired: boolean = isRFQRequestExpired(request);
        return isExpired && <ReceivedRequestListItem key={request.id} showComparison={handleClick} request={request} />;
      })}
    </ListContainer>
  );
};

export default PreviousRequestsReceived;
