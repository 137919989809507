import {DetailsTabType} from 'types/layout';

import {PropsWithChildren} from 'react';

import NotificationBubble from 'ui/Badge/components/NotificationBubble';

import {ComponentProps, Tab} from './types';
import {Container, StyledTab} from './styles';

const Tabs = ({onChange, value, tabs, children, ...props}: PropsWithChildren<ComponentProps>) => {
  const onClick = (tab: Tab) => () => {
    if (onChange) {
      onChange(tab);
    }
  };

  const isActive = (tabValue: DetailsTabType | string) => tabValue === value.value;
  const getClassName = (tab: Tab) => `${tab.className || ''} ${isActive(tab.value) ? 'active' : ''}`;

  return (
    <Container data-testid={props['data-testid']}>
      {tabs.map((tab: Tab, index: number) => (
        <StyledTab
          key={index}
          active={isActive(tab.value)}
          data-testid={tab['data-testid'] || ''}
          className={getClassName(tab)}
          onClick={onClick(tab)}
        >
          {tab.label}
          {/* Using && instead of ternary would always return 0 even after checking equality */}
          {tab.notifications ?
            <NotificationBubble num={tab.notifications} />
          : null}
        </StyledTab>
      ))}
      {children}
    </Container>
  );
};

export default Tabs;
