import React from 'react';

import {ListItem} from 'containers/RFQBoard/styles';
import {SentRequestListItemProps} from 'containers/RFQBoard/types';
import RFQRequestSent from 'components/RFQRequestSent';

const SentRequestListItem = ({
  request,
  expired,
  showForwardPoints,
  showComparison,
  testid,
}: SentRequestListItemProps) => {
  const onKeyDown = (e: React.KeyboardEvent) => {
    // handle space and return buttons
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      showComparison();
    }
  };
  return (
    <ListItem
      data-testid={testid}
      data-requestid={request.id}
      role='button'
      aria-pressed='false'
      tabIndex={0}
      onClick={showComparison}
      onKeyDown={onKeyDown}
    >
      <RFQRequestSent key={request.id} requestData={request} showForwardPoints={showForwardPoints} expired={expired} />
    </ListItem>
  );
};

export default SentRequestListItem;
