import styled from 'styled-components';

import {color} from 'utils/theme';

import {SRow} from 'ui/Layout/styles';

export const SHeader = styled(SRow)`
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;

  [class^='col-'] {
    padding: 0 1.2em;
  }
  .tab-button {
    text-align: center;

    .btn,
    .btn:focus {
      width: 100%;
      background: none;
      border: none;
      padding: 0.8em;
      color: ${color('blue100')};
      box-shadow: none;
    }
    .btn:active {
      background: ${color('blue650')};
    }
    .btn--active,
    .btn--active.btn:focus {
      background: ${color('blue650')};
      color: ${color('white')};
      box-shadow: none;
    }
  }
`;

export const SStatus = styled.div`
  display: flex;
  flex-basis: 20%;
  align-items: center;
  justify-content: center;
`;

export const SOrderBottom = styled(SRow)`
  justify-content: flex-end;
  background: inherit;
  padding: 0.8em;
`;
