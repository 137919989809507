import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

import {ParagraphProps} from './types';
import {Paragraph} from './styles';

interface Props {
  message: string;
  tooltipMessage: string;
  variant?: ParagraphProps['variant'];
}

function FormParagraph({message, tooltipMessage, variant}: Props) {
  return (
    <TooltipWrapper message={tooltipMessage} position={'left'} className='w-100' maxWidth={400}>
      <Paragraph variant={variant}>{message}</Paragraph>
    </TooltipWrapper>
  );
}

export default FormParagraph;
