import styled, {keyframes} from 'styled-components';
import chroma from 'chroma-js';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${props => chroma(props.theme.colors.black).alpha(0.25).css()};
  z-index: 10;
  height: 100%;
  width: 100%;
`;

const LoaderAnimation = keyframes`
  0% { top: 6px; height: 51px; }
  50%, 100% { top: 19px; height: 26px; }
`;

export const LoaderItem = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: ${p => p.theme.colors.green500};
    animation: ${LoaderAnimation} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  div:first-of-type {
    left: 6px;
    animation-delay: -0.24s;
  }

  div:nth-of-type(2) {
    left: 26px;
    animation-delay: -0.12s;
  }

  div:nth-of-type(3) {
    left: 45px;
    animation-delay: 0s;
  }
`;
