import {CSSProperties, FC} from 'react';

export const WarningIcon: FC<{style?: CSSProperties}> = ({style}) => (
  <i
    style={{
      fontSize: '2rem',
      margin: '-2rem',
      ...style,
    }}
    className='la la-warning toast-img-warning'
  />
);
