import {useState} from 'react';

import {DetailsTabType} from 'types/layout';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import {RFQTabs} from 'constants/tabs';
import Popup from 'ui/Popup';
import Button from 'ui/Button';
import Tabs from 'ui/Tabs';
import {Tab} from 'ui/Tabs/types';
import {SEmptyPopupFiller} from 'ui/Popup/styles';
import {SCol, SContainer, SRow} from 'ui/Layout/styles';

import {RelatedTrades} from 'components/RelatedTradesTab/RelatedTrades';

import RequestDetailsTable from './components/RequestDetailsTable';
import {RFQDetalsContainer} from './styles';

interface Props {
  requestId: string;
  onCompare?: () => void;
}

const RFQRequestDetails = ({requestId, onCompare}: Props) => {
  const [currentTab, setCurrentTab] = useState<Tab>(RFQTabs[0]);
  const {closeModal} = useModal();
  const {selectRfqById} = useRealTimeRequestsQuery();

  const request = selectRfqById(requestId);

  if (!request) {
    return null;
  }

  return (
    <Popup title='Request details' onClose={closeModal}>
      <SContainer fluid id='details-popup'>
        <SRow className='details-popup__header'>
          <Tabs tabs={RFQTabs} value={currentTab} onChange={setCurrentTab} />
          {/* Empty column to fill the whole row responsively */}
          <SCol />
        </SRow>
        {currentTab.label === DetailsTabType.Details && (
          <>
            <RequestDetailsTable requestId={requestId} />

            <RFQDetalsContainer>
              <SCol width={{md: 1 / 6}}>
                <Button data-testid='compare-button' buttonStyle='grey' onClick={onCompare}>
                  Compare
                </Button>
              </SCol>
              <SCol d='flex' justifyContent='end' />
            </RFQDetalsContainer>
          </>
        )}
        {currentTab.label === DetailsTabType.RelatedTrades && <RelatedTrades originId={request.id} />}
        {currentTab.label === DetailsTabType.RelatedTrades && <SEmptyPopupFiller />}
      </SContainer>
    </Popup>
  );
};

export default RFQRequestDetails;
