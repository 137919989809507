import {useMemo} from 'react';

import {useUser} from 'contexts/auth-context';

import {getUserLateFeeRequestsByStatusForLegType} from 'utils/TradeUtils';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import Button from 'ui/Button';

import {ReviewLateSettlementFeePopup} from 'components/popups/LateSettlementFeePopup/Popups/ReviewLateSettlementFeePopup';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {LateFeeButtonProps} from './types';

import {buttonInlineStyle} from './MarkAsLateButton';

const ReviewClaimButton = ({legType, trade, disabled}: LateFeeButtonProps) => {
  const user = useUser();
  const permissions = getRolePermissions(user.user?.role);
  const {openModal} = useModal();

  const isButtonDisabled = useMemo(() => {
    if (!hasPermission({permissions, scopes: [Scope.ManageLateFees]})) return true;
    // If trade has Pending Late Fee request - btn not disabled
    return (
      !(getUserLateFeeRequestsByStatusForLegType(trade, user, 'Pending', 'recipient', legType).length > 0) || disabled
    );
  }, [permissions, disabled]);

  const onClick = () => {
    openModal({
      modal: ReviewLateSettlementFeePopup,
      props: {
        tradeId: trade.id,
        lateFee: getUserLateFeeRequestsByStatusForLegType(trade, user, 'Pending', 'recipient', legType)[0],
        legType,
      },
    });
  };

  return (
    <Button
      data-testid={`review-claim-btn-${legType.toLowerCase()}`}
      disabled={isButtonDisabled}
      onClick={onClick}
      style={buttonInlineStyle}
      buttonStyle={isButtonDisabled ? 'grey' : 'warning'}
    >
      Review Claim
    </Button>
  );
};

export default ReviewClaimButton;
