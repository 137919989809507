import {useState} from 'react';

import {Order} from 'types/api';

import {useRealTimeOrdersQuery} from 'api/hooks/useRealTimeOrdersQuery';

import {useUser} from 'contexts/auth-context';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {Scope} from 'constants/permission-maps';

import Loader from 'ui/Loader/';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {SActiveOrders, SActiveOrdersContainer, SHeader, SHeaderButtons} from './styles';
import {ActiveOrder} from './components/ActiveOrder';
import {PauseAllButton} from './components/PauseAllButton';
import {CancelAllButton} from './components/CancelAllButton';

export const ActiveOrders = () => {
  const [isLoadingModal, setLoadingModal] = useState(false);
  const {referenceData} = useReferenceDataStore();
  const {user} = useUser();
  const canTrade = hasPermission({
    permissions: getRolePermissions(user.role),
    scopes: [Scope.CanTrade],
  });
  const {orders, isLoading} = useRealTimeOrdersQuery();

  const setLoading = () => setLoadingModal(prev => !prev);

  return (
    <SActiveOrders data-testid='active-orders' fluid>
      <SHeader>
        <h4>Active Orders</h4>
      </SHeader>
      {canTrade && (
        <SHeaderButtons flexDirection={['column', 'column', 'row']}>
          <PauseAllButton isLoading={isLoadingModal} setLoading={setLoading} />
          <CancelAllButton isLoading={isLoadingModal} setLoading={setLoading} />
        </SHeaderButtons>
      )}

      <SActiveOrdersContainer data-testid={'active-orders-container'}>
        {isLoading && <Loader />}
        {orders.map((order: Order) => (
          <ActiveOrder key={order.id} order={order} referenceData={referenceData} />
        ))}
      </SActiveOrdersContainer>
    </SActiveOrders>
  );
};

export default ActiveOrders;
