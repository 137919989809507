import styled from 'styled-components';

export const Container = styled.span<{uppercase: boolean; color?: string}>`
   {
    color: ${p => p.color ?? p.theme.colors.white};
    line-height: 1;
    text-transform: ${p => (p.uppercase ? 'uppercase' : 'none')};
    white-space: pre-line;
  }
`;
