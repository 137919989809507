import {useIntl} from 'react-intl';

import {showToast} from 'utils/ToastUtils';

import {withdrawTradeUnwind} from 'api/api';

import {AxiosError} from 'axios';

import TableRow from 'utils/TableUtils';

import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {fieldTitles} from 'constants/messages/labels';

import Popup from 'ui/Popup';

import {Bottom, StyledTable} from 'containers/RFQPopup/styles';

import {FooterLabel} from 'components/popups/TradePopup/components/ReviewUnwindTradePopup/styles';
import {UnwindTradePopupProps} from 'components/popups/TradePopup/components/UnwindTradePopup/types';

import {unwindReasonToItem} from 'components/popups/TradePopup/components/UnwindTradePopup';
import {ActionButton} from 'components/ConfirmationButtons/types';
import ConfirmationButtons from 'components/ConfirmationButtons/ConfirmationButtons';

export const WithdrawUnwindTradePopup = ({tradeId}: UnwindTradePopupProps) => {
  const {formatMessage} = useIntl();
  const {goBack} = useModal();
  const {selectTradeById} = useRealTimeTradesQuery();

  const handleClose = () => goBack();

  const onConfirmWithdrawal = () => {
    withdrawTradeUnwind(tradeId)
      .then(() => {
        showToast('Trade unwind request has been withdrawn.', 'success');
        handleClose();
      })
      .catch((e: AxiosError) => {
        showToast('Failed to withdraw proposed unwind', 'error');
        console.error(e.message);
      });
  };

  const trade = selectTradeById(tradeId);
  if (!trade) return null;

  const unwindReasonRow = (
    <TableRow key='unwindReason' title='Unwind Reason Given'>
      {unwindReasonToItem(trade.tradeUnwind?.reason)?.label}
    </TableRow>
  );

  const tableRows = [unwindReasonRow];

  const actions: ActionButton[] = [
    {
      label: 'Confirm Withdrawal',
      onClick: onConfirmWithdrawal,
      buttonStyle: 'primary',
      testId: 'confirm-withdrawal',
    },
  ];

  return (
    <Popup
      title={formatMessage(fieldTitles.unwindUnwindPopUpHeader)}
      onClose={handleClose}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable className='late-fee-popup' elements={tableRows} />
      <Bottom>
        <FooterLabel textAlign='left'>{formatMessage(fieldTitles.reviewUnwindPopUpFooter)}</FooterLabel>
        <FooterLabel textAlign='left'>{formatMessage(fieldTitles.reviewUnwindPopUpFooter2)}</FooterLabel>
        <ConfirmationButtons actions={actions} withConfirmationMessage={false} />
        <FooterLabel textAlign='left'>{formatMessage(fieldTitles.withdrawUnwindPopUpFooter)}</FooterLabel>
      </Bottom>
    </Popup>
  );
};
