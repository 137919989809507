import {useState} from 'react';

import {AxiosError, AxiosResponse} from 'axios';
import {useIntl} from 'react-intl';

import {Amount, ErrorResponse} from 'types/api';
import {cancelRFQQuote} from 'api/api';
import {showToast, showErrorToast, ToastType} from 'utils/ToastUtils';
import {To, convertQuantity, displayAmountWithCode} from 'utils/AmountUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {
  getInterestRateForComputation,
  getSpotRangeBigFigForComputation,
  displayRfqAmount,
  getRfqAmounts,
} from 'utils/RFQUtils';

import TableRow from 'utils/TableUtils';

import {useModal} from 'contexts/modal-context';

import {useRealTimeQuotesQuery} from 'api/hooks/useRealTimeQuotesQuery';

import {fieldTitles} from 'constants/messages/labels';
import Button from 'ui/Button';
import Popup from 'ui/Popup';
import Loader from 'ui/Loader';

import {Title, Bottom, StyledTable} from 'containers/RFQPopup/styles';
import {useQuoteDetailsRows} from 'containers/RFQPopup/hooks/useQuoteDetailsRows';

interface Props {
  requestId: string;
}

export const CancelQuoteConfirmation = ({requestId}: Props) => {
  const {selectQuoteWithRequestByRequestId} = useRealTimeQuotesQuery();
  const quoteAndRequest = selectQuoteWithRequestByRequestId(requestId)!;
  const assetPair = {base: quoteAndRequest.baseAsset, second: quoteAndRequest.secondAsset};
  const quote = quoteAndRequest.quote;
  const {closeModal: onClose} = useModal();
  const quoteRows = useQuoteDetailsRows(quoteAndRequest, quoteAndRequest);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);
  const [isLoading, setIsLoading] = useState<boolean>();
  const {formatMessage} = useIntl();

  function onConfirm() {
    setIsLoading(true);
    cancelRFQQuote(quoteAndRequest.requestId, quote.id)
      .then((response: AxiosResponse) => {
        const parameters = getCancelRFQQuoteToastParameters(response);
        showToast(...parameters);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        showErrorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  }

  function getCancelRFQQuoteToastParameters(response: AxiosResponse): [string, ToastType] {
    if (response.status === 200) {
      return ['Your quote has been successfully cancelled', 'warning'];
    }

    return ['You cancellation request has been sent', 'warning'];
  }

  // Calculating values for rows
  if (!quote) {
    return (
      <Popup onClose={onClose} width='360px'>
        <Title className='message'>Please, select a quote to cancel.</Title>
      </Popup>
    );
  }

  const {tradedAmount} = quote;

  const {buyingRfqAmount, sellingRfqAmount} = getRfqAmounts({
    pair: assetPair,
    side: quoteAndRequest.requesteeSide,
    spotRangeBigFig: getSpotRangeBigFigForComputation(quoteAndRequest.requestType),
    spotRate: getInterestRateForComputation(quoteAndRequest.requestType),
    tradedAmount: convertQuantity(quote.tradedAmount.quantity, To.View, true, inMillions ? 1_000_000_00 : 1_00) ?? 0,
    tradedCurrency: tradedAmount.asset.currency,
    inMillions,
  });

  const earmarkAmt: Amount = {
    asset: sellingRfqAmount.asset,
    quantity: Math.max(...(sellingRfqAmount.quantity.filter(Boolean) as number[])),
  };

  const youBuyRow = (
    <TableRow key='youBuy' title={formatMessage(fieldTitles.youBuy)} rightTestId='quote-you-buy'>
      {displayRfqAmount(buyingRfqAmount, inMillions)}
    </TableRow>
  );

  const youSellRow = (
    <TableRow key='youSell' title={formatMessage(fieldTitles.youSell)} rightTestId='quote-you-sell'>
      {displayRfqAmount(sellingRfqAmount, inMillions)}
    </TableRow>
  );

  const earmarkAmountRow = (
    <TableRow key='earmarkAmount' title={formatMessage(fieldTitles.earmarkAmount)} rightTestId='earmark-amount'>
      {displayAmountWithCode(earmarkAmt, false, inMillions)}
    </TableRow>
  );

  const uniqueRows = [
    ...(quoteAndRequest.requesteeSide === 'SellBuy' ? [youSellRow, youBuyRow] : [youBuyRow, youSellRow]),
    earmarkAmountRow,
  ];
  const rows = [...quoteRows, ...uniqueRows];

  return (
    <Popup title='Cancel Quote' onClose={onClose} width='360px'>
      {isLoading && <Loader />}

      <Title>Please, review the details of your quote before cancelling.</Title>
      <StyledTable elements={rows} />
      <Bottom>
        <Button data-testid='confirm-cancellation-btn' loading={isLoading} buttonStyle='secondary' onClick={onConfirm}>
          Confirm Cancellation
        </Button>
      </Bottom>
    </Popup>
  );
};
