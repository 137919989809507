import {OrderAggregate} from 'types/api';
import {displayAmountWithUnit} from 'utils/AmountUtils';

import {ClobSideBarRow} from 'components/ClobSideBarRow/ClobSideBarRow';

import {TotalRow} from '../TotalRow/TotalRow';
import {SClobBidsTotal} from '../BidsSection/styles';
import {SClobOffers} from './styles';

export interface OffersSectionProps {
  isRepo: boolean;
  data: OrderAggregate[] | undefined;
  inMillions: boolean;
}

export const OffersSection = ({isRepo, data, inMillions}: OffersSectionProps) => {
  const totalOffersLabel = isRepo ? 'Total Buy' : 'Total S/B';
  const totalOffersAmount = data && data.length > 0 ? displayAmountWithUnit(data[0].cumulativeAmount, inMillions) : '-';

  return (
    <>
      <SClobBidsTotal id='clob-offers-total' fluid>
        <TotalRow label={totalOffersLabel} amount={totalOffersAmount} />
      </SClobBidsTotal>
      <SClobOffers id='clob-offers' fluid>
        {data &&
          data.map((agg: OrderAggregate, i: number) => {
            return (
              <ClobSideBarRow
                key={agg.impliedYield.toString() + i.toString()}
                side={'SellBuy'}
                agg={agg}
                isRepo={isRepo}
              />
            );
          })}
      </SClobOffers>
    </>
  );
};
