import {Dayjs} from 'dayjs';

import {fieldTitles} from 'constants/messages/labels';
import {request} from 'constants/messages/rfq';

import {Rate} from './rfq';
import {AssetPair, Currency, LegDateTimeInput, Side} from './api';

export interface RequestFormState {
  side: Side;
  pair: AssetPair;
  counterparty1: string;
  counterparty2: string;
  counterparty3: string;
  tradedAmount?: number;
  tradedCurrency: Currency;
  spotRangeBigFig: number;
  nearLeg?: LegDateTimeInput;
  farLeg?: LegDateTimeInput;
  nearLegInstant?: Dayjs;
  farLegInstant?: Dayjs;
  validFor: number;
  pending: boolean;
  initialFXRate?: number;
  suggestedRate: Rate | undefined;
}

export const ConfirmationStepLabels = {
  type: request.requestSide,
  isNonAggressor: fieldTitles.nonAggressor,
  isNonAggressorOnlyNegative: request.isNonAggressorOnlyNegativeNo,
  side: fieldTitles.side,
  amount: fieldTitles.amount,
  minSecondAmount: fieldTitles.minSecondAmt,
  maxSecondAmount: fieldTitles.maxSecondAmt,
  initialRate: fieldTitles.initialRate,
  implYield: fieldTitles.impYield,
  fwdPts: fieldTitles.fwdPts,
  spotRange: fieldTitles.spotRange,
  secondAmount: fieldTitles.secondAmt,
  securitiesAmount: fieldTitles.securitiesAmt,
  earmarkAmount: fieldTitles.earmarkAmount,
  validUntil: fieldTitles.validUntil,
  validFor: fieldTitles.validFor,
  entity: fieldTitles.entity,
  nearLeg: fieldTitles.nearLeg,
  farLeg: fieldTitles.farLeg,
  onLeg: fieldTitles.onLeg,
  offLeg: fieldTitles.offLeg,
  counterparty: fieldTitles.counterparties,
  maturity: fieldTitles.maturity,
  suggestedImpliedYield: fieldTitles.suggestedImpYield,
  suggestedForwardPoints: fieldTitles.suggestedFwdPts,
  suggestedRate: fieldTitles.suggestedRate,
  economicsTabHeader: request.economicsTabHeader,
};
