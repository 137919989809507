import styled from 'styled-components';

const TableHeader = styled.span`
  padding-top: 12px;
  text-transform: uppercase;
  color: ${p => p.theme.colors.white};
  font-size: 12px;
`;

export const TableHeaderCentered = styled(TableHeader)`
  display: block;
  text-align: center;

  button {
    font-size: 12px;
    padding: 8px 48px;
  }
`;

export const TableLabel = styled.span`
  text-transform: uppercase;
  padding: 4px 8px;
`;

export const CountdownWrapper = styled.span`
  color: currentColor;
  font-size: 16px;
  font-weight: bold;

  .seconds {
    font-size: 24px;
  }
`;

export const TableButtons = styled.div`
  text-align: center;
  button {
    margin: 4px auto;
    display: block;
  }
  .reject-button {
    font-size: 0.6rem;
    padding: 8px 16px;
  }
`;

export const RejectionReasons = styled.div`
  & * + * {
    margin-top: 2px;
  }
`;

export const Uppercase = styled.div`
  text-transform: uppercase;
`;
