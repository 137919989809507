import {AmountFieldProps, InputColorState} from './types';
import TooltipWrapper from '../Tooltip/TooltipWrapper';
import {Container, Prefix, Input, Unit} from './styles';

interface Props
  extends Pick<
    AmountFieldProps,
    | 'name'
    | 'tooltip'
    | 'tooltipPosition'
    | 'alwaysShowTooltip'
    | 'warning'
    | 'invalid'
    | 'required'
    | 'disabled'
    | 'disabledLabel'
    | 'highlight'
    | 'unit'
    | 'width'
    | 'flash'
    | 'prefix'
  > {
  value: string;
}

export const FakeAmountField = ({
  name,
  tooltip = 'Please verify this field',
  alwaysShowTooltip = false,
  tooltipPosition,
  warning,
  invalid,
  required,
  value,
  disabled,
  disabledLabel,
  highlight,
  width,
  prefix,
  flash,
  unit,
}: Props) => {
  const valueToShow =
    disabled && disabledLabel ? disabledLabel
    : value ? value
    : '';
  const inputColor: InputColorState = (() => {
    if (warning) return 'warning';
    if (invalid) return 'invalid';
    if (highlight) return 'highlight';
    if (!disabled && required) return 'highlightRequired';
    return 'regular';
  })();

  return (
    <TooltipWrapper
      message={tooltip}
      position={tooltipPosition}
      disabled={disabled || (!alwaysShowTooltip && !invalid && !warning)}
    >
      <Container disabled={disabled} width={width}>
        {prefix && <Prefix>{prefix}</Prefix>}
        <Input
          name={name}
          disabled={disabled}
          type='text'
          value={valueToShow}
          placeholder='--'
          inputColor={inputColor}
          flash={flash}
          readOnly
        />
        {unit && <Unit>{unit}</Unit>}
      </Container>
    </TooltipWrapper>
  );
};
