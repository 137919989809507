import {useMatch} from 'react-router';

import {LayoutPath} from 'types/layout';

import {shouldShowVolumeChart} from 'utils/utils';

import {SCol} from 'ui/Layout/styles';
import {ClobSideBar as Market} from 'containers/ClobSideBar';

import {BalancesList} from 'containers/BalancesList';
import VolumeChart from 'components/VolumeChart/VolumeChart';

export const LeftSideBar = () => {
  const isChartView = useMatch(LayoutPath.ChartView);
  const showVolumeChart = shouldShowVolumeChart();
  const leftComponentColumns = isChartView && showVolumeChart ? 1 / 4 : 1 / 6;
  return (
    <SCol width={[1, leftComponentColumns]} className='market-container'>
      {isChartView ?
        <>
          <Market />
          {showVolumeChart && <VolumeChart />}
        </>
      : <BalancesList />}
    </SCol>
  );
};
