import {useQuery, useQueryClient} from '@tanstack/react-query';
import {Asset} from 'types/api';

const QUERY_KEY = ['CapacityManagerData'];

type CapacityFormData = {
  asset: Asset;
  amount: number;
  fromDate: string;
  toDate: string;
};
interface CapacityStore {
  capacityData?: CapacityFormData;
}

export default function useCapacityManagerStore() {
  const {data} = useQuery<CapacityStore>({
    queryKey: QUERY_KEY,
    initialData: {
      capacityData: undefined,
    },
  });
  const client = useQueryClient();
  const setCapacityData = (newCapacityData: CapacityFormData) => {
    return client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, capacityData: newCapacityData}));
  };
  const removeCapcityData = () => {
    return client.setQueryData<typeof data>(QUERY_KEY, d => ({...d!, capacityData: undefined}));
  };
  return {
    capacityData: data.capacityData,
    setCapacityData,
    removeCapcityData,
  };
}
