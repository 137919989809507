import {useIntl} from 'react-intl';

import {fieldTitles} from 'constants/messages/labels';

import {FormAmountField} from 'ui/AmountField';
import FieldTitle from 'ui/FieldTitle';

import {FormCell, FormRow} from 'components/popups/CreateQuote/styles';

function ValidForField() {
  const {formatMessage} = useIntl();

  return (
    <FormRow>
      <FormCell>
        <FieldTitle>{formatMessage(fieldTitles.quoteValidFor)}</FieldTitle>
      </FormCell>
      <FormCell>
        <FormAmountField data-testid='valid-for-input' name='validFor' unit='min' tooltipPosition='left' />
      </FormCell>
    </FormRow>
  );
}

export default ValidForField;
