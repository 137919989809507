import {createColumnHelper} from '@tanstack/react-table';

import {AggregatedLimit, CombinedLimit, Limit, LimitType} from 'types/api';
import {addAmount, displayAmountWithCode, subtractAmount} from 'utils/AmountUtils';

import {ActionButton} from 'components/Buttons/ActionButton';

const columnHelper = createColumnHelper<AggregatedLimit>();

export const getCreditRiskColumns = (isAdjustingDisabled: boolean, onClick: (limit: Limit) => void) =>
  columnHelper.group({
    header: 'SETTLEMENT RISK LIMIT',
    meta: {
      align: 'left',
    },
    columns: [
      columnHelper.accessor('settlementRiskLimit', {
        id: 'settlementRiskLimit',
        header: 'Remaining available limit / Limit',
        meta: {
          align: 'left',
          alignItems: 'start',
        },
        cell: cell => {
          const limit = cell.getValue() as CombinedLimit;
          return (
            <span data-testid='settlement-risk-limits'>{`
              ${displayAmountWithCode(
                subtractAmount(limit.total, addAmount(limit.exposure, limit.earmark)),
                false,
                true
              )}
               /
              ${displayAmountWithCode(limit.total, false, true)}
            `}</span>
          );
        },
      }),
      columnHelper.accessor(
        row => ({
          counterparty: row.counterparty,
          amount: row.settlementRiskLimit.total,
          limitType: LimitType.Settlement,
          isTotal: true,
        }),
        {
          id: 'adjustSettlementRiskLimitButton',
          header: '',
          meta: {
            alignItems: 'center',
          },
          cell: cell => (
            <ActionButton
              label='Adjust'
              style={{margin: 0}}
              disabled={isAdjustingDisabled}
              onClick={() => onClick(cell.getValue() as Limit)}
              data-testid='adjust-settlement-risk-limits'
            />
          ),
        }
      ),
    ],
  });

export const getMtmLimitColumns = (isAdjustingDisabled: boolean, onClick: (limit: Limit) => void) =>
  columnHelper.group({
    header: 'MTM LIMIT',
    meta: {
      align: 'left',
    },
    columns: [
      columnHelper.accessor('mtmLimit', {
        id: 'mtmRiskLimit',
        header: 'Remaining available limit / Limit',
        meta: {
          align: 'left',
          alignItems: 'start',
        },

        cell: cell => {
          const limit = cell.getValue() as CombinedLimit;
          return (
            <span data-testid='mtm-risk-limits'>{`
              ${displayAmountWithCode(
                subtractAmount(limit.total, addAmount(limit.exposure, limit.earmark)),
                false,
                true
              )}
               /
              ${displayAmountWithCode(limit.total, false, true)}
            `}</span>
          );
        },
      }),
      columnHelper.accessor(
        row => ({
          counterparty: row.counterparty,
          amount: row.mtmLimit.total,
          limitType: LimitType.Mtm,
          isTotal: true,
        }),
        {
          id: 'adjustMtmRiskLimitButton',
          header: '',
          meta: {
            alignItems: 'center',
          },
          cell: cell => (
            <ActionButton
              label='Adjust'
              style={{margin: 0}}
              disabled={isAdjustingDisabled}
              onClick={() => onClick(cell.getValue() as Limit)}
              data-testid='adjust-mtm-risk-limits'
            />
          ),
        }
      ),
    ],
  });

export const getClobMatchesColumns = (isAdjustingDisabled: boolean, onClick: (limit: Limit) => void) =>
  columnHelper.group({
    header: 'CLOB MATCHES LIMIT',
    meta: {
      align: 'left',
    },
    columns: [
      columnHelper.accessor('clobMatchesLimit', {
        id: 'clotMatchesLimit',
        header: 'Remaining available limit / Limit',
        meta: {
          align: 'left',
          alignItems: 'start',
        },
        cell: cell => {
          const limit = cell.getValue() as CombinedLimit;
          return (
            <span data-testid='clob-matches-limits'>{`
              ${displayAmountWithCode(
                subtractAmount(limit.total, addAmount(limit.exposure, limit.earmark)),
                false,
                true
              )}
               /
              ${displayAmountWithCode(limit.total, false, true)}
            `}</span>
          );
        },
      }),
      columnHelper.accessor(
        row => ({
          counterparty: row.counterparty,
          amount: row.clobMatchesLimit.total,
          limitType: LimitType.ClobMatches,
          isTotal: true,
        }),
        {
          id: 'adjustClobMatchesLimitButton',
          header: '',
          meta: {
            alignItems: 'center',
          },
          cell: cell => (
            <ActionButton
              label='Adjust'
              style={{margin: 0}}
              disabled={isAdjustingDisabled}
              onClick={() => onClick(cell.getValue() as Limit)}
              data-testid='adjust-clob-matches-limits'
            />
          ),
        }
      ),
    ],
  });
