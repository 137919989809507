import styled, {css} from 'styled-components';
import {theme, ifNotProp, ifProp, switchProp} from 'styled-tools';

import {pulse} from 'utils/AnimationUtils';

import {color} from 'utils/theme';

import {CheckBoxContainerProps} from './types';

const SContainer = styled.div<CheckBoxContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  border-width: 2px;
  border-style: solid;
  border-radius: 25px;
  input-color: ${switchProp(
    'inputColor',
    {
      false: color('gray470'),
      warning: color('yellow500'),
      invalid: color('red400'),
    },
    'inherit'
  )};
  animation: ${ifProp(
    'flash',
    css`
      ${pulse} 1.5s linear
    `,
    'none'
  )};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:disabled {
    color: ${theme('colors.gray350')};
  }

  width: ${ifProp('width', p => p.width)};
  opacity: ${ifProp('disabled', '0.5')};
  cursor: ${ifProp('disabled', 'not-allowed')};
`;

export const SCheckboxContainer = styled.label`
  display: inline-block;
  cursor: pointer;
  user-select: none;
`;

export const SHiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const SIconContainer = styled(SContainer)`
  position: relative;
  width: 25px;
  height: 25px;
  border: 4px ${theme('colors.gray200')} solid;
  border-radius: 1px;
  cursor: ${ifNotProp('disabled', 'pointer', 'initial')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
