import {useAuth} from 'contexts/auth-context';
import {useState} from 'react';

export const useHeader = () => {
  const {logout} = useAuth();
  const [showHelpPopup, setShowHelpPopup] = useState(false);

  const signOut = () => {
    void logout();
  };

  const tryToSignOut = () => {
    if (window.confirm('Are you sure you wish to log out?')) {
      signOut();
    }
  };

  const showHelp = () => {
    setShowHelpPopup(true);
  };

  const hideHelp = () => {
    setShowHelpPopup(false);
  };

  return {
    tryToSignOut,
    showHelp,
    hideHelp,
    isHelpPopupShown: showHelpPopup,
  };
};
