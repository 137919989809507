import {createIntl, createIntlCache} from 'react-intl';

const cache = createIntlCache();
const locale = process.env.REACT_APP_LOCALE || 'en';

export const intlImperative = createIntl(
  {
    locale,
    messages: {},
  },
  cache
);
