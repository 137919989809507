import {ComponentProps} from 'react';

import NotificationBubble, {Props as BubbleProps} from './components/NotificationBubble';
import {BadgeWrapper} from './styles';

interface Props extends BubbleProps, ComponentProps<typeof BadgeWrapper> {}

export default function Badge({num, ...props}: Props) {
  if (num === 0) return null;
  return (
    <BadgeWrapper {...props}>
      <NotificationBubble num={num} />
    </BadgeWrapper>
  );
}
