import {HistoryType} from 'types/blotter';

import CapacityAdjustmentsTable from './components/CapacityAdjustmentsTable';
import OrdersTable from './components/OrdersTable';
import QuotesTable from './components/QuotesTable';
import RequestsTable from './components/RequestsTable';
import TradesTable from './components/TradesTable';

export const tabs = {
  [HistoryType.Trades]: TradesTable,
  [HistoryType.Orders]: OrdersTable,
  [HistoryType.Quotes]: QuotesTable,
  [HistoryType.Requests]: RequestsTable,
  [HistoryType.Capacity]: CapacityAdjustmentsTable,
} as const;
