import {useIntl} from 'react-intl';
import dayjs from 'dayjs';

import {Amount, AssetPair, RFQRequest} from 'types/api';
import {ConditionalWrapper, displayForwardPoints, displayInterestRate, displayParty, dropUndefined} from 'utils/utils';
import {displayFullLeg} from 'utils/DayjsUtils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';
import {
  displaySpotRangeForRequest,
  shouldShowRequestValidUntil,
  getRfqAmounts,
  displayRfqAmount,
  getSpotRangeBigFigForComputation,
  getInterestRateForComputation,
  getRFQAmountFieldLabel,
} from 'utils/RFQUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {ReferenceData} from 'types/layout';

import {fieldTitles} from 'constants/messages/labels';

import colors from 'constants/colors';
import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';
import Table from 'ui/Table';
import {Row} from 'ui/Table/types';
import Countdown from 'ui/Countdown';

import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import {repoSecuritiesMessage} from 'containers/RFQForm/types';

import {TabContent, TabHeader, CountdownContainer, TableLabel, TableValue} from 'components/popups/CreateQuote/styles';
import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';

type ConfirmationDetailsProps = {
  quoteFormRequest: RFQRequest;
  quoteFormState: CreateQuoteFormValues;
  handleCountdownTimeUp: () => void;
};

const ConfirmationDetails = ({
  quoteFormRequest,
  quoteFormRequest: {requesteesAndQuotes},
  handleCountdownTimeUp,
  quoteFormState: {quoteTradedAmount, validFor, rate},
}: ConfirmationDetailsProps) => {
  const {formatMessage} = useIntl();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({
    base: quoteFormRequest.baseAsset,
    second: quoteFormRequest.secondAsset,
  });

  if (quoteTradedAmount === undefined) {
    return <></>;
  }

  const assetPair: AssetPair = {base: quoteFormRequest.baseAsset, second: quoteFormRequest.secondAsset};
  const isRepo: boolean = isRepoPair(assetPair);
  const tradedCurrency = quoteFormRequest.tradedAmount.asset.currency;

  const quoteAmounts = getRfqAmounts({
    pair: assetPair,
    spotRate: getInterestRateForComputation(quoteFormRequest.requestType),
    spotRangeBigFig: getSpotRangeBigFigForComputation(quoteFormRequest.requestType),
    tradedAmount: quoteTradedAmount,
    tradedCurrency,
    side: quoteFormRequest.requesteeSide,
    inMillions,
  });

  const earmarkAmt: Amount = {
    asset: quoteAmounts.sellingRfqAmount.asset,
    quantity: Math.max(...(quoteAmounts.sellingRfqAmount.quantity.filter(Boolean) as number[])),
  };

  // Rows for table
  const remaining: Row = [
    <TableLabel key={0}>
      <FieldTitle>Remaining</FieldTitle>
    </TableLabel>,
    <CountdownContainer key={1}>
      <FieldValue>
        <Countdown
          onTimeUp={handleCountdownTimeUp}
          timeTarget={dayjs(quoteFormRequest.validUntil)}
          forceExpired={!shouldShowRequestValidUntil(quoteFormRequest)}
        />
      </FieldValue>
    </CountdownContainer>,
  ];

  const counterparty: Row = [
    <TableLabel key={0}>
      <FieldTitle>C&apos;Party</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-counterparty' key={1}>
      <FieldValue uppercase={false}>{displayParty(quoteFormRequest.requestor)}</FieldValue>
    </TableValue>,
  ];

  const yourSide: Row = [
    <TableLabel key={0}>
      <FieldTitle>Your side</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-your-side' key={1}>
      <FieldValue>{displayAssetPairWithSide(assetPair, quoteFormRequest.requesteeSide, true)}</FieldValue>
    </TableValue>,
  ];

  const quoteTradedAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'quote-traded',
    assetPair,
    isRepo,
    tradedCurrency,
    side: quoteFormRequest.requesteeSide,
  });

  const quoteRequestAmtRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(quoteTradedAmountFieldLabel)}</FieldTitle>
    </TableLabel>,
    <TableValue key={1} data-testid='details-quote-traded-amt'>
      <FieldValue>{displayRfqAmount(quoteAmounts.tradedRfqAmount, inMillions)}</FieldValue>
    </TableValue>,
  ];

  const counterAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'counter',
    assetPair,
    isRepo,
    tradedCurrency,
    side: quoteFormRequest.requesteeSide,
  });

  const counterAmtRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(counterAmountFieldLabel)}</FieldTitle>
    </TableLabel>,
    <TableValue key={1} data-testid='details-counter-amt'>
      <ConditionalWrapper
        condition={isRepo}
        wrapper={children => (
          <TooltipWrapper position='left' message={repoSecuritiesMessage}>
            {children}
          </TooltipWrapper>
        )}
      >
        <FieldValue>{displayRfqAmount(quoteAmounts.counterRfqAmount, inMillions)}</FieldValue>
      </ConditionalWrapper>
    </TableValue>,
  ];

  const [youBuyRow, youSellRow] =
    quoteTradedAmountFieldLabel.id === 'youBuy' ?
      [quoteRequestAmtRow, counterAmtRow]
    : [counterAmtRow, quoteRequestAmtRow];

  const interestRateLabel =
    isRepo ? 'Rate'
    : rate?.referenceData === ReferenceData.ForwardPoints ? 'Fwd Pts'
    : 'Imp. Yld';
  const impYldRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{interestRateLabel}</FieldTitle>
    </TableLabel>,
    isRepo || rate?.referenceData === ReferenceData.ImpliedYield ?
      <TableValue data-testid='details-implied-yield' key={1}>
        <FieldValue>{displayInterestRate(rate?.value)}</FieldValue>
      </TableValue>
    : <TableValue data-testid='details-forward-points' key={1}>
        <FieldValue>{rate?.value && displayForwardPoints(rate?.value)}</FieldValue>
      </TableValue>,
  ];

  const quoteValidForRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>Quote valid for</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-valid-for' key={1}>
      <FieldValue>{`${validFor} min`}</FieldValue>
    </TableValue>,
  ];

  const earmarkAmountRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>Earmark amount</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-earmark-amount' key={1}>
      <FieldValue>{displayAmountWithCode(earmarkAmt, false, inMillions)}</FieldValue>
    </TableValue>,
  ];

  const tradedAmountRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>Request Traded AMT</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-request-amount' key={1}>
      <FieldValue>{displayAmountWithCode(quoteFormRequest.tradedAmount, false, inMillions)}</FieldValue>
    </TableValue>,
  ];

  const nearLeg: Row = [
    <TableLabel key={0}>
      <FieldTitle>{isRepo ? formatMessage(fieldTitles.onLeg) : formatMessage(fieldTitles.nearLeg)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-near-leg' key={1}>
      <FieldValue color={quoteFormRequest.nearLegTime ? colors.yellow350 : undefined}>
        {displayFullLeg(quoteFormRequest.nearLegTime, quoteFormRequest.nearLegTimeInput?.transactionDate)}
      </FieldValue>
    </TableValue>,
  ];

  const farLeg: Row = [
    <TableLabel key={0}>
      <FieldTitle>{isRepo ? formatMessage(fieldTitles.offLeg) : formatMessage(fieldTitles.farLeg)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-far-leg' key={1}>
      <FieldValue>
        {displayFullLeg(quoteFormRequest.maturityTime, quoteFormRequest.maturityTimeInput.transactionDate)}
      </FieldValue>
    </TableValue>,
  ];

  const spotRangeRow: Row | undefined =
    isRepo ? undefined : (
      [
        <TableLabel key={0}>
          <FieldTitle>Spot range</FieldTitle>
        </TableLabel>,
        <TableValue data-testid='details-spot-range' key={1}>
          <FieldValue>{displaySpotRangeForRequest(quoteFormRequest.requestType)}</FieldValue>
        </TableValue>,
      ]
    );

  const entity: Row = [
    <TableLabel key={0}>
      <FieldTitle>Entity</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='details-entity' key={1}>
      <FieldValue uppercase={false}>{displayParty(requesteesAndQuotes[0].requestee)}</FieldValue>
    </TableValue>,
  ];

  // Merging all rows
  const rows: Row[] = dropUndefined([
    remaining,
    counterparty,
    yourSide,
    tradedAmountRow,
    impYldRow,
    ...(isRepo ? [quoteRequestAmtRow, counterAmtRow]
    : quoteFormRequest.requesteeSide === 'SellBuy' ? [youSellRow, youBuyRow]
    : [youBuyRow, youSellRow]),
    earmarkAmountRow,
    quoteValidForRow,
    nearLeg,
    farLeg,
    spotRangeRow,
    entity,
  ]);

  return (
    <TabContent>
      <TabHeader>Please, review the details of your quote before confirming.</TabHeader>
      <Table rows={rows} />
    </TabContent>
  );
};

export default ConfirmationDetails;
