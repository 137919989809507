import {FC, HTMLProps} from 'react';

import './Header.scss';
import {SContainer, SRow} from 'ui/Layout/styles';

type HeaderBarProps = HTMLProps<HTMLDivElement>;
const HeaderBar: FC<HeaderBarProps> = ({className, children, height}) => (
  <SContainer
    id='Header'
    className={['Header', className].join(' ')}
    fluid
    display='block'
    position='sticky'
    top='0'
    left='0'
    zIndex={10}
    height={height}
  >
    <SRow justifyContent='space-between'>{children}</SRow>
  </SContainer>
);

export default HeaderBar;
