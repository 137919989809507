import {useEffect, useRef, useState} from 'react';
import {ChartCanvas, Chart} from 'react-stockcharts';
import {BarSeries, CandlestickSeries} from 'react-stockcharts/lib/series';
import {XAxis, YAxis} from 'react-stockcharts/lib/axes';

import {takeWhile, displayForwardPoints} from 'utils/utils';
import {convertQuantity, To} from 'utils/AmountUtils';
import {assetCode, displayAssetPair, getAssetFromQuery, isRepoPair} from 'utils/AssetUtils';
import {appTzShortName} from 'utils/setupDayjs';
import {useQuery} from 'utils/browserUtils';
import {MarketHistory, AssetPair} from 'types/api';
import {LayoutPath, ReferenceData} from 'types/layout';
import {displayDate} from 'utils/DayjsUtils';

import './History.scss';

import {useLocation, useNavigate} from 'react-router-dom';
import useHistoryChartSizeStore from 'stores/useHistoryChartSizeStore';

import useStateAssets from 'utils/hooks/useStateAssets';

import useWatchChartSize from 'utils/hooks/useWatchChartSize';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import dayjs from 'dayjs';

import {Unit} from 'constants/commonEnums';
import {RefSwitch} from 'components/RefSwitch/RefSwitch';

import {useHistoryChartData} from './hooks/useHistoryChartData';
import {candleStickStroke, getGridScale, historyMargin} from './styles';
/**
 * Updates MarketHistory object with data from new MarketHistory object. The assumption here is that they can
 * have at most one common item (item with the same timestamp) in which case the new MH object item should
 * replace the old MH item.
 */
export function updateMarketHistory(oldHistory: MarketHistory, newHistory: MarketHistory | undefined): MarketHistory {
  if (!newHistory || !newHistory.items || newHistory.items.length === 0) {
    return oldHistory;
  }

  const firstNewItem = newHistory.items[0];
  const keepOldItems = takeWhile(oldHistory.items, t => t.startTime < firstNewItem.startTime);

  return {
    assetPair: newHistory.assetPair,
    lastTraded: newHistory.lastTraded ? newHistory.lastTraded : oldHistory.lastTraded,
    items: [...keepOldItems, ...newHistory.items],
  };
}

export const History = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const {search} = useLocation();
  const {baseAsset, secondAsset} = useStateAssets();
  const {referenceData} = useReferenceDataStore();
  const {width: historyChartWidth = 0, height: historyChartHeight = 0} = useHistoryChartSizeStore();

  const ref = useRef<HTMLDivElement>(null);
  useWatchChartSize(ref);

  const [assetPair, setAssetPair] = useState<AssetPair>({base: baseAsset, second: secondAsset});
  const isRepo = isRepoPair(assetPair);
  const {
    historyData: {data, lastTraded, xScale, xExtents, yExtents, xAccessor, displayXAccessor},
    infoMessage,
    showChart,
  } = useHistoryChartData(assetPair, referenceData);

  // Parse selected asset pair from URL
  useEffect(() => {
    try {
      const first = getAssetFromQuery(query, 'first');
      const second = getAssetFromQuery(query, 'second');

      if (first && second) {
        setAssetPair({
          base: first,
          second,
        });
      }
    } catch (error) {
      navigate(LayoutPath.ClobView);
    }
  }, [navigate, JSON.stringify(query), search]);

  const formatVolumeTick = (tick: number): string => {
    const newValue = convertQuantity(tick, To.View).toString() + ' m';
    return newValue;
  };
  const {xGrid, yGrid, yGridVolume} = getGridScale(historyChartWidth, historyChartHeight);

  const chartLoadingErrorMessage = 'Sorry, error occured while loading the Chart. Please refresh the page.';

  return (
    <div className='History' id='history' ref={ref}>
      <div className='header'>
        <div data-testid='asset-pair' className='identifier'>
          {displayAssetPair(assetPair)}
        </div>
        {!isRepo && <RefSwitch />}
        <div className='last-traded'>
          {/* todo fetch from market */}
          <div className='label'>last traded: </div>
          <div data-testid='last-traded-rate' className='last-traded-rate'>
            {lastTraded && (referenceData === ReferenceData.ImpliedYield || isRepo) ?
              <span>
                {lastTraded.impliedYield} <span className='unit'>{Unit.BP}</span>
              </span>
            : <span>{lastTraded && displayForwardPoints(lastTraded.forwardPoints)}</span>}
          </div>
          <div>
            {lastTraded ? convertQuantity(lastTraded.amount.quantity, To.View) : ''} <span className='unit'>m</span>
          </div>
          <div>
            {lastTraded && dayjs(lastTraded.at).isValid() ?
              <span>
                {displayDate(lastTraded.at, 'HH:mm:ss')}
                <span className='unit'>h {appTzShortName}</span>
              </span>
            : ''}
          </div>
        </div>
      </div>
      {showChart ?
        <>
          <div className='filters'>
            <div className='chart-label'>{assetCode(assetPair.base) + ' rate and volume'}</div>
          </div>
          {historyChartHeight && historyChartWidth ?
            <ChartCanvas
              height={historyChartHeight - 50}
              width={historyChartWidth}
              ratio={2}
              margin={historyMargin}
              type='hybrid'
              seriesName='Finteum Market History'
              data={data}
              xScale={xScale}
              xAccessor={xAccessor}
              displayXAccessor={displayXAccessor}
              xExtents={xExtents}
              zoomEvent
            >
              <Chart id={1} yExtents={yExtents} padding={{top: 50, bottom: 150}}>
                <XAxis axisAt='bottom' orient='bottom' {...xGrid} />
                <YAxis axisAt='right' orient='right' ticks={5} {...yGrid} />

                <CandlestickSeries
                  opacity={1}
                  fill={candleStickStroke}
                  stroke={'none'}
                  wickStroke={candleStickStroke}
                />
              </Chart>

              <Chart id={2} yExtents={[(d: any) => [d.volume]]} height={150} origin={(w: any, h: any) => [0, h - 150]}>
                <YAxis axisAt='left' orient='left' ticks={5} tickFormat={formatVolumeTick} {...yGridVolume} />

                <BarSeries opacity={1} yAccessor={(d: any) => d.volume} fill={(d: any) => '#4E5561'} />
              </Chart>
            </ChartCanvas>
          : <div className='nodata'>{chartLoadingErrorMessage}</div>}
        </>
      : <div className='nodata'>{infoMessage}</div>}
    </div>
  );
};
