import {PropsWithChildren} from 'react';

import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import {TooltipPosition} from 'ui/Tooltip/types';

import {TableLabel} from './styles';
import {LabelWithTooltipStyles} from './types';

interface LabelWithTooltipProps {
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  styles?: LabelWithTooltipStyles;
}

export const LabelWithTooltip = ({
  tooltip,
  tooltipPosition,
  children,
  styles,
}: PropsWithChildren<LabelWithTooltipProps>) => (
  <TooltipWrapper message={tooltip} position={tooltipPosition}>
    <TableLabel styles={styles}>{children}</TableLabel>
  </TooltipWrapper>
);
