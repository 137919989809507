import {useCallback, useEffect, useState} from 'react';

import {getRelatedTradesByOriginId} from 'api/api';
import {Trade} from 'types/api';
import {showToast} from 'utils/ToastUtils';

import {SCol} from 'ui/Layout/styles';

import {SRelatedTrades} from './styles';
import {RelatedTrade} from './RelatedTrade';

export type RelatedTradesProps = {
  originId: string;
};

export const RelatedTrades = ({originId}: RelatedTradesProps) => {
  const [trades, setTrades] = useState<Trade[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const retrieveRelatedTrades = useCallback(async (id: string): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await getRelatedTradesByOriginId(id);
      setTrades(response.data);
    } catch (error) {
      console.error(error);
      const message: string = `We were not able to retrieve the trades related to this entry. Please try again later.`;
      showToast(message, 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    void retrieveRelatedTrades(originId);
  }, [originId, retrieveRelatedTrades]);

  return (
    <SRelatedTrades>
      <SCol width={{md: 1}}>
        {isLoading && <h4>Loading related trades...</h4>}
        {!isLoading && trades.length > 0 && (
          <table>
            <thead>
              <tr>
                <td>Finteum Trade ID</td>
                <td>Amount</td>
                <td>Imp Yld / Rate</td>
                <td>Start time</td>
                <td>Counterparty</td>
              </tr>
            </thead>
            <tbody data-testid='related-trades'>
              {trades.map(trade => (
                <RelatedTrade trade={trade} />
              ))}
            </tbody>
          </table>
        )}
        {!isLoading && trades.length <= 0 && <h4>This order has not been matched yet.</h4>}
      </SCol>
    </SRelatedTrades>
  );
};
