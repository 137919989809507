import {useState, useEffect, useMemo} from 'react';

import {ReferenceData} from 'types/layout';
import {currencyPairToAssetPair, isAssetPairEqual, isRepoPair} from 'utils/AssetUtils';

import {useRealTimeExchangeRatesQuery} from 'api/hooks/useRealTimeExchangeRatesQuery';

import useOrderBookQuery from 'api/hooks/useOrderBookQuery';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import useStateAssets from 'utils/hooks/useStateAssets';

import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {SCol, SRow} from 'ui/Layout/styles';

import SpotRate from 'components/SpotRate/SpotRate';

import {ClobSideBarWrapper, SClobHeader} from './styles';
import {OffersSection} from './components/OffersSection';
import {BidsSection} from './components/BidsSection';

export const ClobSideBar = () => {
  const {baseAsset, secondAsset} = useStateAssets();
  const pair = useMemo(() => ({base: baseAsset, second: secondAsset}), [baseAsset, secondAsset]);
  const {data: orderBook, isError: error} = useOrderBookQuery(pair);
  const {referenceData} = useReferenceDataStore();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(pair);

  const isRepo = isRepoPair(pair);
  const {exchangeRates} = useRealTimeExchangeRatesQuery();
  const [spotRate, setSpotRate] = useState<number | undefined>(undefined);

  useEffect((): void => {
    if (isRepoPair(pair)) {
      setSpotRate(undefined);
      return;
    }

    const exchangeRate = exchangeRates.find(er => isAssetPairEqual(currencyPairToAssetPair(er.pair), pair));
    setSpotRate(exchangeRate?.rate.value);
  }, [JSON.stringify(exchangeRates), JSON.stringify(pair)]);

  return (
    <ClobSideBarWrapper height={['100%', '100%', 'calc(100vh - 70px)']}>
      <SClobHeader id='clob-header'>
        <SRow>
          <SCol width={{xs: 1 / 4}} d='flex' justifyContent='start'>
            {referenceData === ReferenceData.ImpliedYield ? 'imp. yld' : 'fwd. pts'}
          </SCol>
          <SCol width={{xs: 1 / 2}}>amount</SCol>
          <SCol width={{xs: 1 / 4}}>cum.</SCol>
        </SRow>
      </SClobHeader>
      <OffersSection isRepo={isRepo} data={orderBook?.sellBuy} inMillions={inMillions} />
      {!isRepo && spotRate && <SpotRate spotRate={spotRate} error={error} />}
      <BidsSection isRepo={isRepo} data={orderBook?.buySell} inMillions={inMillions} />
    </ClobSideBarWrapper>
  );
};
