import React, {PropsWithChildren, memo} from 'react';

import {Row} from 'ui/Table/types';
import FieldTitle from 'ui/FieldTitle';
import {StyledRow, Cell} from 'ui/Table/styles';
import {Label, Cell as RowCell} from 'containers/RFQPopup/styles';

interface TableRowProps {
  title: string;
  rightTestId?: string;
  leftTestId?: string;
  rightClassName?: string;
  leftClassName?: string;
  rightCellColor?: string;
  key: string;
}

const TableRow = memo((props: PropsWithChildren<TableRowProps>) => {
  const {
    title,
    children,
    rightTestId,
    leftTestId,
    rightClassName = 'text-right',
    leftClassName,
    rightCellColor,
  } = props;
  const cellWidth: number = title ? 100 / title.length : 100;

  return (
    <StyledRow className='row'>
      <Cell key={0} width={cellWidth}>
        <Label key={0} className={leftClassName}>
          <FieldTitle data-testid={leftTestId}>{title}</FieldTitle>
        </Label>
      </Cell>
      <Cell key={1} width={cellWidth} color={rightCellColor}>
        <RowCell key={1} data-testid={rightTestId} className={rightClassName}>
          {children}
        </RowCell>
      </Cell>
    </StyledRow>
  );
});
TableRow.displayName = 'TableRow';

export default TableRow;

// Fill table row with empty cells to format it properly
export const fillTableRow = (originalRow: Row, targetLength: number, emptyCell?: string | React.ReactElement): Row => {
  if (originalRow.length >= targetLength) {
    return originalRow;
  }

  const diffLength: number = targetLength - originalRow.length;
  const newTableRow: Row = originalRow;
  for (let i = 0; i < diffLength; i++) {
    newTableRow.push(emptyCell || undefined);
  }

  return newTableRow;
};

// Create row for UI/Table component from an array
export const createRowFromArray =
  <T extends object>(length: number, emptyCell?: string | React.ReactElement) =>
  (
    initialArray: (string | JSX.Element)[],
    originalArray: Array<T>,
    valueFn: (curr: T, i: number) => string | JSX.Element
  ): Row =>
    fillTableRow(
      originalArray.reduce(
        (accumulator: Row, currentItem: T, index: number) => [...accumulator, valueFn(currentItem, index)],
        initialArray
      ),
      length,
      emptyCell
    );
