import React, {useMemo} from 'react';

import {RFQRequestStatusText} from 'types/api';

import {useIntl} from 'react-intl';

import {rfqRequestStatuses} from 'constants/messages/labels';
import {GreenHighlight, RedHighlight} from 'components/RFQRequestReceived/styles';

interface ComponentProps {
  statusText: RFQRequestStatusText;
}

export const RFQRequestStatusNode = ({statusText}: ComponentProps): React.ReactElement => {
  const {formatMessage} = useIntl();

  const humanized = formatMessage(rfqRequestStatuses[statusText]);

  const statusNode = useMemo(() => {
    switch (statusText) {
      case 'Filled': {
        return <GreenHighlight>{humanized}</GreenHighlight>;
      }
      case 'PartialFill':
        return <GreenHighlight>{humanized}</GreenHighlight>;
      case 'Waiting':
        return <span>{humanized}</span>;
      default:
        return <RedHighlight>{humanized}</RedHighlight>;
    }
  }, [statusText, humanized]);

  return statusNode;
};
