import {useUser} from 'contexts/auth-context';
import {useModal} from 'contexts/modal-context';
import dayjs from 'dayjs';
import {useIntl} from 'react-intl';
import {Trade, TradeStatus} from 'types/api';

import {hasPendingReceivedEarlyMaturity, hasPendingSentEarlyMaturity, isUnwindOrEMRPossible} from 'utils/TradeUtils';

import {fieldTitles} from 'constants/messages/labels';
import Button from 'ui/Button';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';

import {EarlyMaturityActionsPopup} from '../EarlyMaturityActionsPopup';

interface Props {
  trade: Trade;
}

export default function TradeEarlyMaturityRequestButton({trade}: Props) {
  const {formatMessage} = useIntl();
  const {openModal} = useModal();
  const userWithMeta = useUser();

  // To request EMR, trade status should be Outstanding and it should be issued before scheduled maturity
  const showRequestEarlyMaturityBtn =
    [TradeStatus.Outstanding].includes(trade.status) &&
    dayjs().isAfter(trade.nearLeg.scheduledTime) &&
    dayjs().isBefore(trade.farLeg.scheduledTime);
  const canRequestEMR = isUnwindOrEMRPossible(trade);
  const isRequestEMRBtnDisabled = !canRequestEMR || trade.tradeUnwind?.status === 'Proposed';

  const showEMRTooltipMessage = () => {
    if (!trade.tradeUnwind || trade.tradeUnwind.status !== 'Proposed') return null;
    const action =
      userWithMeta.legalEntities.map(party => party.legalName).includes(trade.tradeUnwind.recipient) ?
        'reject'
      : 'withdraw';
    return `Please ${action} Unwind request first.`;
  };

  const showEarlyMaturityPopup = (): void => {
    if (!trade) return;

    openModal({modal: EarlyMaturityActionsPopup, props: {tradeId: trade.id}});
  };

  if (hasPendingReceivedEarlyMaturity(trade))
    return (
      <Button
        data-testid='early-maturity-received-button'
        key={fieldTitles.receivedEarlyMaturityButton.id}
        buttonStyle='warning'
        onClick={showEarlyMaturityPopup}
      >
        {formatMessage(fieldTitles.receivedEarlyMaturityButton)}
      </Button>
    );

  if (hasPendingSentEarlyMaturity(trade))
    return (
      <Button key={fieldTitles.withdrawEarlyMaturityButton.id} buttonStyle='warning' onClick={showEarlyMaturityPopup}>
        {formatMessage(fieldTitles.withdrawEarlyMaturityButton)}
      </Button>
    );

  if (showRequestEarlyMaturityBtn)
    return (
      <TooltipWrapper message={showEMRTooltipMessage()} position='top'>
        <Button
          data-testid='request-early-maturity-button'
          key={fieldTitles.requestEarlyMaturityButton.id}
          disabled={isRequestEMRBtnDisabled}
          buttonStyle='secondary'
          onClick={showEarlyMaturityPopup}
        >
          {formatMessage(fieldTitles.requestEarlyMaturityButton)}
        </Button>
      </TooltipWrapper>
    );

  return null;
}
