import styled from 'styled-components';

export const SCurrencyTile = styled.div`
  background-color: ${p => p.theme.colors.blue900};
  min-width: 320px;
`;

export const SCurrencyTileHeader = styled.div`
  background-color: ${p => p.theme.colors.gray500};
  padding: 8px 14px;
`;

export const SCurrencyTilePair = styled.h4`
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
`;

export const SCurrencyTileLastTraded = styled.p`
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  margin-top: 8px;
`;

export const SCurrencyTileContent = styled.div`
  padding: 12px 14px;
`;

export const SCurrencyTileCol = styled.div<{
  /** Same as Bootstrap - 12 is 100% */
  cols: number;
}>`
  flex: 0 0 ${props => `${(props.cols / 12) * 100}%`};
  max-width: ${props => `${(props.cols / 12) * 100}%`};
  font-weight: bold;
`;

export const SCurrencyTileLabel = styled(SCurrencyTileCol)`
  font-size: 12px;
  color: ${p => p.theme.colors.gray300};
  text-transform: uppercase;
`;

export const SCurrencyTileLabelTop = styled(SCurrencyTileLabel)`
  text-align: right;
`;

export const SCurrencyTileValue = styled(SCurrencyTileCol)`
  font-size: 16px;
  text-align: right;
`;

export const SCurrencyTileRow = styled.div`
  display: flex;
  padding: 8px;
`;

export const tileButtonStyles = {
  display: 'block',
  fontSize: 12,
  width: 80,
  lineHeight: '30px',
  height: 30,
  margin: '8px 0 0 auto',
  padding: 0,
};
