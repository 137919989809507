import dayjs, {Dayjs} from 'dayjs';
import sortBy from 'lodash/sortBy';

import {MarketDay, TransactionDate} from 'types/api';

import {TimePickerValue} from 'ui/TimePicker/types';
import {Leg} from 'containers/RFQForm/types';

// Date formats most commonly used in the app
export enum DateFormat {
  Long = 'DD MMM YYYY',
  Short = 'DD MMM YY',
  Standard = 'DD MMM YYYY',
  DateTime = 'DD MMM YYYY HH:mm:ss',
  Time = 'HH:mm',
  TimeWithSeconds = 'HH:mm:ss',
  DateWithDayOfWeek = 'ddd DD MMM YYYY',
  LocalDateTime = 'YYYY-MM-DDTHH:mm:ss.SSS',
  LocalTime = 'HH:mm:ss.SSS',
  MonthYear = 'MMMM YYYY',
  FullWithZone = 'ddd DD MMM HH:mm z',
  StandardWithHours = 'DD MMM YYYY HH',
}

// For better IDE autocompletion
export type DateFormatStr = `${DateFormat}`;

// formatDate is taken by react-intl
export const displayDate = (
  date: Dayjs | string | undefined | null,
  format: DateFormatStr,
  timezone?: string,
  fallback: string = '--'
) => {
  if (!date) return fallback;

  return dayjs(date).tz(timezone).format(format);
};

// Calculate percentage of time passed in a day from a date object
export const showDayPctgPassed = (time: Dayjs) => {
  const totalMinutes = time.tz().hour() * 60 + time.tz().minute();
  const percentage: number = totalMinutes / (24 * 60);
  return percentage * 100;
};

/**
 * Calculate difference between Timestamps to get a transaction Leg: T+1 or T+0
 *
 * @param {Dayjs | string} target - Dayjs object or ISO timestamp
 */
export function calculateDayOffset(target: Dayjs | string): Leg {
  const today = dayjs().tz().startOf('day');
  const targetDay = dayjs(target).tz().startOf('day');
  const days = dayjs.duration(targetDay.diff(today)).days();
  return days > 0 ? 'T+1' : 'T+0';
}

export function displayNearLegAsAsap(displayPrefixAndAsap: boolean = true): string {
  return `${displayPrefixAndAsap ? 'T+0 - ' : ''}ASAP`;
}

/**
  @deprecated use {@link transactionDateToPrefix} instead
 */
export function displayLegPrefix(target?: string): string {
  if (target === 'TPlusOne') {
    return 'T+1';
  }
  return 'T+0';
}

export function displayFullLeg(target: Dayjs | string | undefined | null, legPrefix: TransactionDate | undefined) {
  if (!target) return displayNearLegAsAsap();
  if (legPrefix === TransactionDate.TPlusZero || !legPrefix)
    return displayLegPrefixAndDateTime(target, legPrefix, DateFormat.Time);
  return displayLegPrefixAndDateTime(target, legPrefix, DateFormat.FullWithZone);
}

export function displayLegPrefixAndDateTime(
  target: Dayjs | string,
  legPrefix: TransactionDate | undefined,
  timeFormat: DateFormatStr = 'HH:mm:ss'
): string {
  return displayLegPrefix(legPrefix) + ' - ' + displayDate(target, timeFormat);
}

/**
 * Add one day until datetime is in the future
 *
 * @param specificTime [Dayjs] - Time to transpose to the future
 */
export function addOnedayUntilNotInThePast(specificTime: Dayjs): Dayjs {
  const now = dayjs();
  if (specificTime.isBefore(now)) {
    return addOnedayUntilNotInThePast(specificTime.add(1, 'days'));
  }
  return specificTime;
}

/**
 * Take time picker value and translate it into a Dayjs value for today. Improves testing and reusability.
 *
 * @param timePickerValue [TimePickerValue] - Value returned from time picker
 * @returns Dayjs
 */
export function timePickerValueToDayjs({hours, minutes}: TimePickerValue): Dayjs {
  return dayjs().tz().hour(hours).minute(minutes).second(0).millisecond(0);
}

/**
 * @param shouldBeInTheFuture if true - reflects, whether dateTime should be after current time
 * @param shouldBeInThePast if true - reflects, whether dateTime should be before current time
 * @param shouldBeInTheFuture and shouldBeInThePast are false - dateTime can be before and after current time
 */
interface ValidateDateTimeParameters {
  dateTime: Dayjs;
  shouldBeInTheFuture?: boolean;
  shouldBeInThePast?: boolean;
  pastDateTime?: Dayjs;
  futureDateTime?: Dayjs;
}

/**
 * Function to check if dateTime [Dayjs] is valid and compare it against other date times as well as the present time.
 *
 * @param validateDateTimeParameters [ValidateDateTimeParameters] -
 * Object with dateTime [Dayjs], shouldBeInTheFuture? [boolean],
 * shouldBeInThePast? [boolean], pastDateTime? [Dayjs], futureDateTime? [Dayjs] parameters.
 * @returns [boolean] If the dateTime passed is valid
 */
export const validateDateTime = ({
  dateTime,
  shouldBeInTheFuture = true,
  shouldBeInThePast = false,
  pastDateTime,
  futureDateTime,
}: ValidateDateTimeParameters): boolean => {
  const now = dayjs();
  const isAValidDayjs: boolean = dateTime.isValid();
  const isInTheFuture: boolean = dateTime.isAfter(now);
  // Fallbacks to true in case no pastDateTime or no futureDateTime is provided
  const isAfterPastDateTime: boolean = pastDateTime ? dateTime.isAfter(pastDateTime) : true;
  const isBeforeFutureDateTime: boolean = futureDateTime ? !dateTime.isAfter(futureDateTime) : true;

  if (shouldBeInTheFuture) {
    return isAValidDayjs && isInTheFuture && isAfterPastDateTime && isBeforeFutureDateTime;
  }
  if (shouldBeInThePast) {
    return isAValidDayjs && !isInTheFuture && isAfterPastDateTime && isBeforeFutureDateTime;
  }
  return isAValidDayjs && isAfterPastDateTime && isBeforeFutureDateTime;
};

export const getMinutesBetweenDates = (date1?: string | Dayjs, date2?: string | Dayjs): number =>
  dayjs(date2).diff(dayjs(date1), 'minute');

const getLegDayFromPrefix = (prefix: Leg) => prefix.split('+')[1];

export const applyLegOffset = (date: Dayjs | string | undefined, leg: Leg, toIncrease = true): Dayjs => {
  const offset = Number(getLegDayFromPrefix(leg));
  if (toIncrease) {
    return dayjs(date).add(offset, 'day');
  } else {
    return dayjs(date).subtract(offset, 'day');
  }
};

export const addMillisTimeToDate = (startTime: string | Dayjs, millis: number): Dayjs =>
  dayjs(startTime).add(millis, 'millisecond');

export const roundToInterval = (date: Dayjs, interval = 15) => {
  const remainingMinutes = interval - (date.minute() % interval);
  const shouldRoundUp = remainingMinutes < interval / 2;

  return date.add(shouldRoundUp ? remainingMinutes : -interval + remainingMinutes, 'minutes');
};

export const ceilToInterval = (date: Dayjs, interval = 15, direction: 'add' | 'subtract' = 'add') =>
  date[direction](interval - (date.minute() % interval), 'minutes');

export function generateValidDatesForRange(startDate: Dayjs, endDate: Dayjs, step = 15) {
  const dateList = [];
  let currentDate = startDate.startOf('minute');

  while (currentDate <= endDate) {
    dateList.push(currentDate);
    currentDate = currentDate.add(step, 'minute');
  }

  return dateList;
}

export function ensureFutureDate(date: Dayjs | string, intervalToRoundNow = 15) {
  const maybePastDate = dayjs(date);
  const now = dayjs();

  if (maybePastDate.isBefore(now)) return ceilToInterval(now, intervalToRoundNow);
  return maybePastDate;
}

export const displayDuration = (timeStart: Dayjs, timeEnd: Dayjs) => {
  const timeDifference: number = timeEnd.diff(timeStart);

  const duration = dayjs.duration(timeDifference);

  const days: number = Math.trunc(duration.as('days'));
  const hours: number = duration.get('hours');
  const minutes: number = duration.get('minutes');

  return `
      ${days.toString().padStart(2, '0')}:
      ${hours.toString().padStart(2, '0')}:
      ${minutes.toString().padStart(2, '0')}
    `;
};

export const combineDateAndTimeFromTwoDayjsObjects = (
  dayjsObjectWithDate: Dayjs,
  dayjsObjectWithTime: Dayjs
): Dayjs => {
  const newCombinedDateTime = dayjsObjectWithDate
    .startOf('day')
    .set('hour', dayjsObjectWithTime.hour())
    .set('minute', dayjsObjectWithTime.minute())
    .set('second', dayjsObjectWithTime.second())
    .set('millisecond', dayjsObjectWithTime.millisecond());

  return newCombinedDateTime;
};

export const isDateWithinRange = (date: Dayjs): boolean => {
  const currentTime = dayjs();
  const fourteenDaysAgo = currentTime.subtract(15, 'days');
  return fourteenDaysAgo <= date && date <= currentTime.subtract(1, 'day');
};

export const getValidTimesRange = (dateToCheck: Dayjs) => {
  const start = dayjs().tz().startOf('date');
  const end = dayjs();
  return {start, end: isDateWithinRange(dateToCheck) ? end.tz().endOf('day') : end};
};

export function getUpcomingMarketDays(days: MarketDay[]) {
  const tPlusZero = days.find(marketDay => dayjs(marketDay.startsAt).startOf('day').isSame(dayjs().startOf('day')));
  const sortedDays = sortBy(days, day => new Date(day.startsAt).getTime());
  const tPlusOne = sortedDays.find(day => dayjs(day.startsAt).startOf('day').isAfter(dayjs().startOf('day')));
  return [tPlusZero, tPlusOne];
}

export function truncateTimePrecision(date: Dayjs, precision: 'minute' | 'second' | 'hour' | 'day' = 'minute') {
  return date.startOf(precision);
}
