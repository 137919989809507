import {useState} from 'react';
import {useIntl} from 'react-intl';

import {Trade, AnnulTradeProposalReason} from 'types/api';

import {getReasonText} from 'utils/RFQUtils';

import {showToast} from 'utils/ToastUtils';

import {proposeTradeAnnul} from 'api/api';

import {useModal} from 'contexts/modal-context';

import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {fieldTitles} from 'constants/messages/labels';
import {TRADE_ANNUL_REASONS} from 'constants/trade';
import FieldTitle from 'ui/FieldTitle';
import Button from 'ui/Button';
import {Row} from 'ui/Table/types';
import Popup from 'ui/Popup';
import {CheckBox} from 'ui/CheckBox';

import PopupHelperText from 'ui/PopupHelperText';
import {Bottom, Line, StyledTable} from 'containers/RFQPopup/styles';

import {LabelWithTooltip} from 'components/LabelWithTooltip/LabelWithTooltip';

import {FooterLabel, SButtonsContainer} from './styles';

type TradeAnnulProposalPopupProps = {
  tradeId: Trade['id'];
};

export const ProposeTradeAnnul = ({tradeId}: TradeAnnulProposalPopupProps) => {
  const {formatMessage} = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rejectionReasons, setRejectionReasons] = useState<AnnulTradeProposalReason[]>([]);
  const {selectTradeById} = useRealTimeTradesQuery();
  const toggleReason = (reason: AnnulTradeProposalReason) => {
    if (rejectionReasons.includes(reason)) {
      setRejectionReasons(previousReasons => previousReasons.filter(r => r !== reason));
    } else {
      setRejectionReasons(previousReasons => [...previousReasons, reason]);
    }
  };

  const {goBack} = useModal();

  const trade = selectTradeById(tradeId);

  const onBackClick = () => goBack();

  const onPropose = () => {
    setIsLoading(true);

    proposeTradeAnnul(tradeId, rejectionReasons)
      .then(() => {
        showToast('Trade annul proposed', 'success');
        goBack();
      })
      .catch((e: Error) => {
        showToast('Failed to propose trade annul', 'error');
        console.error(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reasonsTitle: Row = [
    <LabelWithTooltip
      key='rejection-reasons-title'
      tooltip='Your counterparty will receive this information'
      tooltipPosition={'right'}
    >
      <FieldTitle>Send reason(s) (optional):</FieldTitle>
    </LabelWithTooltip>,
  ];

  const optionalReasons = (Object.keys(TRADE_ANNUL_REASONS) as AnnulTradeProposalReason[]).map(reason => [
    <Line key={reason}>
      {trade && getReasonText(trade.side, reason, trade?.baseAmount.asset, trade?.secondAmount.asset)}
      <CheckBox
        data-testid={`checkbox-${reason}`}
        checked={rejectionReasons.includes(reason)}
        value={reason}
        onChange={event => toggleReason(event.target.value as AnnulTradeProposalReason)}
      />
    </Line>,
  ]);

  const rows: Row = [reasonsTitle, ...optionalReasons];

  return (
    <Popup
      title={formatMessage(fieldTitles.tradeAnnulProposalHeader)}
      onClose={onBackClick}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable rows={rows} />
      <PopupHelperText helperText={fieldTitles.reviewTradeAnnulProposalPopUpFooter.defaultMessage} />
      <Bottom>
        <SButtonsContainer>
          <Button data-testid='back-button' buttonStyle='grey' onClick={onBackClick}>
            Back
          </Button>
          <Button data-testid='confirm-button' buttonStyle='primary' onClick={onPropose} disabled={isLoading}>
            Confirm
          </Button>
        </SButtonsContainer>
        <FooterLabel>{formatMessage(fieldTitles.reviewTradeAnnulProposalPopUpFooterConfirmationText)}</FooterLabel>
      </Bottom>
    </Popup>
  );
};
