import {useState} from 'react';
import {useIntl} from 'react-intl';
import {useRealTimeTradesQuery} from 'api/hooks/useRealTimeTradesQuery';

import {showToast} from 'utils/ToastUtils';

import {createOrUpdateOrWithdrawCashflowFlag} from 'api/api';

import {FlagType, LegType, Trade} from 'types/api';

import {displayAmountWithCode} from 'utils/AmountUtils';
import {
  combineDateAndTimeFromTwoDayjsObjects,
  displayDate,
  getValidTimesRange,
  validateDateTime,
} from 'utils/DayjsUtils';

import dayjs, {Dayjs} from 'dayjs';

import {getTradeSettlementTimeWithDeadLine} from 'utils/TradeUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import TableRow from 'utils/TableUtils';

import {useFlaggedTradesIdsQuery} from 'api/hooks/useFlaggedTradesIdsQuery';

import {useModal} from 'contexts/modal-context';

import {isRepoPair} from 'utils/AssetUtils';

import {DetailsTabType} from 'types/layout';

import {Scope} from 'constants/permission-maps';

import {fieldTitles} from 'constants/messages/labels';

import Button from 'ui/Button';
import Popup from 'ui/Popup';
import TimeField from 'ui/TimeField';
import DateTimeField from 'ui/DateTimeField';
import PopupHelperText from 'ui/PopupHelperText';
import {StyledTable, BottomForButtons} from 'containers/RFQPopup/styles';
import PermissionGate from 'components/PermissionGate';
import {FlagAs, CashFlowLegType} from 'components/CashFlowTab/types';

import {provideFlagType} from 'components/CashFlowTab/utils';
import TradePopup from 'components/popups/TradePopup';

export type FlagAsData = {
  legType: LegType;
  selectedDateTime?: Dayjs;
};
export type FlagAsPopupProps = {
  tradeId: Trade['id'];
  legType: LegType;
  flagAsPopupType: FlagAs;
  selectedDateTime?: Dayjs;
  cashflowLegType: CashFlowLegType;
  isTicked: boolean;
};

const FlagAsPopup = ({
  tradeId,
  legType,
  selectedDateTime,
  flagAsPopupType,
  cashflowLegType,
  isTicked,
}: FlagAsPopupProps) => {
  const currentTime = dayjs.tz(dayjs());
  const {formatMessage} = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(selectedDateTime ?? currentTime);
  const [selectedTime, setSelectedTime] = useState<Dayjs>(selectedDateTime ?? currentTime);
  const [combinedDateTimeReceived, setNewDateTimeReceived] = useState<Dayjs>(currentTime);
  const {openModal} = useModal();
  const {refetchFlaggedTradesIds} = useFlaggedTradesIdsQuery(
    [FlagType.NearLegNonreceipt, FlagType.FarLegNonreceipt],
    true
  );
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const {selectTradeById} = useRealTimeTradesQuery();
  const trade = selectTradeById(tradeId);

  if (!trade) return null;

  const inMillions = shouldDisplayInMillions({base: trade.baseAmount.asset, second: trade.secondAmount.asset});

  const goBackToCashFlowsTab = () => {
    openModal({
      modal: TradePopup,
      props: {modelId: trade.id, tradePopupTab: {value: DetailsTabType.CashFlows, label: DetailsTabType.CashFlows}},
    });
  };

  const onTimeReceivedChange = (newTime: Dayjs) => {
    setSelectedTime(newTime);
    setNewDateTimeReceived(dayjs.tz(combineDateAndTimeFromTwoDayjsObjects(selectedDate, newTime)));
  };

  const onDateReceivedChange = (newDate: Dayjs) => {
    setSelectedDate(newDate);
    if (newDate.isSame(currentTime, 'day')) {
      setSelectedTime(dayjs.tz(currentTime));
      setNewDateTimeReceived(dayjs.tz(combineDateAndTimeFromTwoDayjsObjects(selectedDate, currentTime)));
    }
    setNewDateTimeReceived(dayjs.tz(combineDateAndTimeFromTwoDayjsObjects(newDate, combinedDateTimeReceived)));
  };

  const settlementTime = getTradeSettlementTimeWithDeadLine(
    trade,
    legType,
    0,
    'minutes',
    !trade[cashflowLegType].actualSettlementTime
  );

  const displaySettlementTimeWithDeadline = dayjs.tz(settlementTime);

  const selectSettlementTimeWithDeadline = dayjs.tz(settlementTime);

  const filterDate = (date: Dayjs): boolean => {
    if (date.startOf('date').isSameOrBefore(currentTime.startOf('date'))) {
      if (date.endOf('date').isBefore(displaySettlementTimeWithDeadline.endOf('date'))) {
        return false;
      }
      return true;
    }
    return false;
  };

  const providePopupSpecificInfo = () => {
    switch (flagAsPopupType) {
      case FlagAs.Sent:
        return {popupTitle: 'Flag as sent', popupFooterText: fieldTitles.flagAsSentPopupInfo.defaultMessage};
      case FlagAs.Received:
        return {popupTitle: 'Flag as received', popupFooterText: fieldTitles.flagAsReceivedPopupInfo.defaultMessage};
      default:
        return {
          popupTitle: 'Flag as non-receipt',
          popupFooterText: fieldTitles.flagAsNonreceiptPopupInfo.defaultMessage,
        };
    }
  };

  const makeApiCallWithFlag = (action: 'create' | 'edit' | 'withdraw') => {
    setIsLoading(true);

    const record = {
      create: {
        flagValue: true,
        toastSuccess: 'Transaction record has been successfully submitted',
        toastError: 'Failed to submit record for this transaction',
      },
      edit: {
        flagValue: true,
        toastSuccess: 'Transaction record has been successfully edited',
        toastError: 'Failed to edit the record for this transaction',
      },
      withdraw: {
        flagValue: false,
        toastSuccess: 'Transaction record has been successfully withdrawn',
        toastError: 'Failed to withdraw transaction record',
      },
    };
    const flagType = provideFlagType(cashflowLegType, flagAsPopupType);

    createOrUpdateOrWithdrawCashflowFlag(tradeId, {
      markedTimestamp: action === 'withdraw' ? null : combinedDateTimeReceived.toISOString(),
      flagValue: record[action].flagValue,
      flagType,
    })
      .then(() => {
        showToast(record[action].toastSuccess, 'success');
        goBackToCashFlowsTab();
      })
      .catch(e => {
        showToast(record[action].toastError, 'error');
      })
      .finally(async () => {
        setIsLoading(false);
        if ([FlagType.NearLegNonreceipt, FlagType.FarLegNonreceipt].includes(flagType)) refetchFlaggedTradesIds();
      });
  };

  const getCorrectLegName = () => {
    const isRepo = isRepoPair({base: trade.baseAmount.asset, second: trade.secondAmount.asset});

    if (isRepo) {
      return legType === LegType.NearLeg ? formatMessage(fieldTitles.onLeg) : formatMessage(fieldTitles.offLeg);
    }
    return legType === LegType.NearLeg ? formatMessage(fieldTitles.nearLeg) : formatMessage(fieldTitles.farLeg);
  };

  const legRow = <TableRow key='leg' title={formatMessage(fieldTitles.leg)} children={getCorrectLegName()} />;
  const amountRow = (
    <TableRow key='amount' title={flagAsPopupType === FlagAs.Sent ? 'You paid' : 'You are to receive'}>
      {displayAmountWithCode(
        flagAsPopupType === FlagAs.Sent ? trade[cashflowLegType].cashFlowOut : trade[cashflowLegType].cashFlowIn,
        false,
        inMillions
      )}
    </TableRow>
  );

  const scheduledSettlementRow = (
    <TableRow key='settlementDeadline' title='Settlement deadline' rightTestId={'settlement-deadline-date-time'}>
      {displayDate(displaySettlementTimeWithDeadline, 'DD MMM YYYY HH:mm:ss')}
    </TableRow>
  );

  const dateRow = (
    <TableRow key='date' title={flagAsPopupType === FlagAs.Sent ? 'Date sent' : 'As of (date)'}>
      <DateTimeField
        value={selectedDate}
        onChange={value => value && onDateReceivedChange(value)}
        showTimeSelect={false}
        filterDate={date => filterDate(dayjs(date))}
      />
    </TableRow>
  );

  const isTimeValid = validateDateTime({
    dateTime: combinedDateTimeReceived,
    shouldBeInTheFuture: false,
    shouldBeInThePast: true,
    pastDateTime: selectSettlementTimeWithDeadline,
  });

  const timeRow = (
    <TableRow key='time' title={flagAsPopupType === FlagAs.Sent ? 'Time sent' : 'As of (time)'}>
      <TimeField
        key={5}
        prefix=' '
        onChange={onTimeReceivedChange}
        data-testid='time-received-input'
        validTimesRange={getValidTimesRange(selectedDate)}
        invalid={!isTimeValid}
        value={selectedTime}
        step={1}
        timeFieldTooltipPosition='right'
        showSecondsPicker
      />
    </TableRow>
  );

  const rows = [legRow, amountRow, scheduledSettlementRow, dateRow, timeRow];

  const isConfirmDisabled = isLoading || !isTimeValid;
  const footerButtons =
    isTicked ?
      <>
        <PermissionGate scopes={[Scope.CanCreateEditWithdrawFlagAs]}>
          <Button
            key='withdraw'
            buttonStyle='warning'
            onClick={() => makeApiCallWithFlag('withdraw')}
            disabled={isLoading}
            loading={isLoading}
          >
            Withdraw
          </Button>
          <Button
            key='edit'
            buttonStyle='secondary'
            onClick={() => makeApiCallWithFlag('edit')}
            disabled={isConfirmDisabled}
            loading={isLoading}
          >
            Confirm Edit
          </Button>
        </PermissionGate>
      </>
    : <>
        <PermissionGate scopes={[Scope.CanCreateEditWithdrawFlagAs]}>
          <Button
            key='submit'
            buttonStyle='primary'
            onClick={() => makeApiCallWithFlag('create')}
            disabled={isConfirmDisabled}
            loading={isLoading}
          >
            Submit
          </Button>
        </PermissionGate>
      </>;

  return (
    <Popup
      title={providePopupSpecificInfo().popupTitle}
      onClose={goBackToCashFlowsTab}
      width='500px'
      closeButtonType='arrow'
    >
      <StyledTable elements={rows} />
      <PopupHelperText helperText={providePopupSpecificInfo().popupFooterText} />
      <BottomForButtons>{footerButtons}</BottomForButtons>
    </Popup>
  );
};

export default FlagAsPopup;
