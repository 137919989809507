import {MarketDataQuery, MarketDay, QueryParamsType} from 'types/api';
import {RateToShow} from 'types/layout';

import {To, convertQuantity} from './AmountUtils';

export const updateMarketDepth = (rateToShow: RateToShow, rateAmount: number): QueryParamsType => {
  const queryParams: QueryParamsType = {};
  if (rateToShow === RateToShow.CustomAmount && rateAmount > 0) {
    queryParams['marketDepth'] = convertQuantity(rateAmount, To.Store);
  }
  return queryParams;
};

export const formatMarketDataQuery = (queryParams: MarketDataQuery): string =>
  Object.entries(queryParams)
    .filter(([key]) => !!key)
    .map(([key, val]: [key: string, val: number | string]) => `${key}=${val}`)
    .join('&');

export const isTradeSizeInMillions = (marketDay: MarketDay) => {
  const minTradeSize = marketDay?.minTradeSize;
  const maxTradeSize = marketDay?.maxTradeSize;

  return minTradeSize >= 1_000_000 && maxTradeSize >= 1_000_000;
};
