import styled from 'styled-components';

import {color} from 'utils/theme';

import {SRow, SContainer} from 'ui/Layout/styles';

export const SVolumeContainer = styled(SContainer)`
  background-color: ${color('blue650')};
  border-right: 4px solid ${color('gray600')};
  max-width: 60%;
  height: calc(100vh - 70px);
  display: flex;
  flex-flow: column;
`;

export const SVolumeHeader = styled(SRow)`
  background-color: ${color('gray600')};
  color: ${color('blue50')};
  padding: 20px 25px;
  font-size: 0.625rem;
  text-transform: uppercase;
  text-align: center;
`;
export const SVolumeWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
`;
export const SVolumeChart = styled.div`
  padding-right: 12px;
  height: 50%;

  .col {
    padding: 0;
  }

  line {
    stroke: ${color('blue50')};
  }

  text {
    font-size: 12px;
    fill: ${color('blue50')};
  }
`;
