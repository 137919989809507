import {useEffect} from 'react';

import dayjs from 'dayjs';
import {useUser} from 'contexts/auth-context';
import {useModal} from 'contexts/modal-context';

import useNotificationsQuery from 'api/hooks/useNotificationsQuery';

import useNotificationsReadDataStore from 'stores/useNotificationsReadDataStore';

import {storageSetLastRenderedTime} from '../utils';
import NotificationCenterModal from '../components/Modal';

interface UseNotificationCenterModalArgs {
  path?: string;
}

export default function useNotificationCenterModal({path}: UseNotificationCenterModalArgs): {
  show: boolean;
  toggleNotificationCenter(): void;
} {
  const {isOpen: show, closeModal, openModal} = useModal();
  const {markAllAsRead} = useNotificationsQuery();
  const {updateLastNotificationCenterOpenedTime} = useNotificationsReadDataStore();
  const {user} = useUser();

  const closeCallback = () => {
    closeModal();
    updateLastNotificationCenterOpenedTime(dayjs().unix());
  };

  const handleNotificationClick = () =>
    path && window.open(path, '', `width=1000, height=${window.screen.availHeight}`);

  const toggleNotificationCenter = () => {
    updateLastNotificationCenterOpenedTime(dayjs().unix());

    if (show) {
      closeCallback();
    } else {
      openModal({
        modal: NotificationCenterModal,
        props: {closeCallback, handleNotificationClick, markAllAsRead},
      });
    }
  };

  useEffect(() => {
    if (show) {
      storageSetLastRenderedTime(dayjs().unix().toString(), user);
    }
  }, [show, JSON.stringify(user)]);

  return {
    show,
    toggleNotificationCenter,
  };
}
