import {useState} from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import {DateFormat, displayDate} from 'utils/DayjsUtils';

import {SCustomPickerLabel} from './styles';
import {DateTimeFieldProps} from './types';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import {InputColorState} from 'ui/AmountField/types';

// Some hacks needed because react-datepicker library relies on browser timezone
// See https://github.com/Hacker0x01/react-datepicker/issues/1787
const appOffset = dayjs().tz().utcOffset();
const localOffset = -new Date().getTimezoneOffset(); // getTimezoneOffset is negative compared to utcOffset() from dayjs
const offset = localOffset - appOffset;

const DateTimeField = ({
  disabled,
  warning,
  invalid,
  required,
  value,
  onChange,
  showTimeSelect = false,
  showMonthYearPicker = false,
  filterDate,
  maxDate,
}: DateTimeFieldProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const inputColor: InputColorState = (() => {
    if (touched && warning) return 'warning';
    if (invalid) return 'invalid';
    if (required && !touched) return 'highlightRequired';
    return 'regular';
  })();
  return (
    <div data-testid='datetime-picker'>
      <DatePicker
        customInput={
          <SCustomPickerLabel inputColor={inputColor}>
            {<span>{displayDate(value, showMonthYearPicker ? DateFormat.MonthYear : DateFormat.Standard)}</span>}
            {showTimeSelect && <span>{displayDate(value, DateFormat.Time)}</span>}
          </SCustomPickerLabel>
        }
        disabled={disabled}
        onCalendarOpen={() => setTouched(true)}
        onChange={date => {
          if (!date) {
            onChange(null);
            return;
          }
          onChange(dayjs(date).add(offset, 'minutes'));
        }}
        selected={value ? value.add(-offset, 'minutes').toDate() : null}
        showMonthYearPicker={showMonthYearPicker}
        showTimeSelect={showTimeSelect}
        timeFormat='HH:mm'
        timeIntervals={15}
        filterDate={filterDate}
        maxDate={maxDate?.toDate()}
      />
    </div>
  );
};

export default DateTimeField;
