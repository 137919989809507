import {RequestFormState} from 'types/rfqForm';

import {RFQFormValues} from './hooks/useRFQForm';

export type Leg = 'T+0' | 'T+1';

export type Legs = {
  nearLegPrefix: Leg;
  farLegPrefix: Leg;
};

export const legOptions = ['T+0', 'T+1'].map(value => ({
  label: value,
  value,
}));

export type RFQFormProps = {
  disabled?: boolean;
};

export type FormProps = {
  disabled?: boolean;
  onSubmit: (values: RFQFormValues) => void;
};

export interface ConfirmationProps {
  rfqForm: RequestFormState;
  onEdit?: () => void;
  onSuccess?: () => void;
}

export const repoSecuritiesMessage = 'Haircut assumed as 10%, actual haircut may differ.';

export interface ExchangeRate {
  value: number;
  timestamp: number;
}
