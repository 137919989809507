import styled, {css} from 'styled-components';
import {switchProp, ifProp} from 'styled-tools';

import {pulse} from 'utils/AnimationUtils';

import {color} from 'utils/theme';

import {ContainerProps, PickerProps, InputProps} from './types';

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  border-width: 2px;
  border-style: solid;
  border-radius: 25px;
  border-color: ${switchProp(
    'inputColor',
    {
      warning: color('yellow500'),
      invalid: color('red400'),
    },
    color('gray470')
  )};
  animation: ${({flash}) =>
    flash ?
      css`
        ${pulse} 1.5s linear
      `
    : 'none'};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:disabled {
    color: ${p => p.theme.colors.gray350};
  }

  width: ${ifProp('width', p => p.width)};
  opacity: ${ifProp('disabled', '0.5')};
  cursor: ${ifProp('disabled', 'not-allowed')};
`;

export const PickerContainer = styled.div<PickerProps>`
  position: absolute;
  left: ${ifProp('prefix', 'calc(50% - 18px)', '1%')};
  ${ifProp('showOnTheRightSide', 'left: 102%;', 'top: calc(100% + 4px);')};
`;

export const InputWrapper = styled.div`
  width: 75%;
`;
export const Input = styled.input<InputProps>`
  -moz-appearance: textfield;
  appearance: textfield;
  box-sizing: border-box;
  background-color: transparent;
  max-height: 40px;
  outline: none;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: right;
  padding: 10px 50px 10px 20px;
  box-shadow: none;
  font-family: 'LatoBold', sans-serif;
  width: 100%;
  color: ${p => p.theme.colors.white};
  border: none;
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
`;

export const Icon = styled.i`
  position: absolute;
  top: 3px;
  right: 18px;
  bottom: 0;
  height: 18px;
  margin: auto 0;
`;

export const Prefix = styled.span<{clickable?: boolean}>`
  ${({clickable}) => clickable && 'cursor: pointer;'}

  user-select: none;
  text-align: end;
  font-size: 0.75rem;
  line-height: 35px;
  align-items: center;
  opacity: 0.7;
  width: 25%;
  border-radius: 12rem 0px 0px 12rem;
`;
