import {useModal} from 'contexts/modal-context';

import {showToast} from 'utils/ToastUtils';

import {cancelActiveOrders} from 'api/api';

import Button from 'ui/Button';

import CancelOrderModal, {CancelOrderModalProps} from '../CancelOrderModal';
import {ActiveOrdersButtonProps} from '../PauseAllButton';

export const CancelAllButton = ({disabled, isLoading, setLoading}: ActiveOrdersButtonProps) => {
  const {closeModal, openModal} = useModal();

  const openCancelMessage = () => {
    openModal({
      modal: CancelOrderModal,
      props: cancelFormProps,
    });
  };

  const handleCancel = () => {
    setLoading();
    cancelActiveOrders()
      .then(() => {
        showToast('All your orders have been cancelled.', 'success');
      })
      .catch(error => {
        showToast('Orders could not be cancelled. Please try again later.', 'warning');
        console.error(error);
      })
      .finally(() => {
        setLoading();
        closeModal();
      });
  };

  const cancelFormProps: CancelOrderModalProps = {
    title: 'Are you sure you want to cancel all orders?',
    description: 'This cancels only the unfilled portion of each order.',
    onConfirm: handleCancel,
    closeModal,
    isLoading,
  };
  return (
    <Button
      loading={isLoading}
      data-testid='cancel-all-orders-button'
      onClick={openCancelMessage}
      disabled={disabled}
      buttonStyle={'secondary'}
    >
      Cancel All
    </Button>
  );
};
