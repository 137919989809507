import {displayDate, displayNearLegAsAsap} from 'utils/DayjsUtils';

import {IntlShape} from 'react-intl';

import {UserWithMeta, RFQQuoteWithRequest, AssetPair} from 'types/api';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayAssetPairWithSide} from 'utils/AssetUtils';
import {getRFC2253ValueByKey, displayForwardPoints, displayInterestRate} from 'utils/utils';
import {ReferenceData} from 'types/layout';

import {getInitialFXRate} from 'utils/RFQUtils';

import {fieldTitles} from 'constants/messages/labels';
import {ColumnDef} from 'ui/GridTable';

import FieldTitle from 'ui/FieldTitle';
import {DateContainer} from 'containers/Blotter/styles';

export const getBlotterQuotesTableColumns = (
  formatMessage: IntlShape['formatMessage'],
  referenceData: ReferenceData,
  user: UserWithMeta,
  shouldDisplayInMillions: (assetPair: AssetPair) => boolean
): ColumnDef<RFQQuoteWithRequest>[] => {
  const showForwardPoints: boolean = referenceData === ReferenceData.ForwardPoints;
  return [
    {
      accessorKey: 'quote.createdAt',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.dateAndTime)}</FieldTitle>
        </span>
      ),
      cell: info => (
        <DateContainer data-rfq-id={info.row.original.requestId} data-quote-id={info.row.original.quote.id}>
          <span>{displayDate(info.row.original.quote.createdAt, 'DD MMM YY')}</span>
          <span>{displayDate(info.row.original.quote.createdAt, 'HH:mm:ss')}</span>
        </DateContainer>
      ),
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 13.1,
      },
      sortingFn: 'datetime',
      sortDescFirst: true,
    },
    {
      accessorKey: 'sentReceived',
      enableSorting: false,
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.sentReceived)}</FieldTitle>
        </span>
      ),
      cell: info => <span>{info.row.original.isReceived ? 'RECEIVED' : 'SENT'}</span>,
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 10,
      },
    },
    {
      accessorKey: 'marketSide',
      enableSorting: false,
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.marketSide)}</FieldTitle>
        </span>
      ),
      cell: info => (
        <span>
          {displayAssetPairWithSide(
            {base: info.row.original.baseAsset, second: info.row.original.secondAsset},
            info.row.original.isReceived ? info.row.original.requestorSide : info.row.original.requesteeSide,
            true
          )}
        </span>
      ),
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 10,
      },
    },
    {
      accessorKey: 'counterparty',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.counterparty)}</FieldTitle>
        </span>
      ),
      cell: info => (
        <span>
          {user.legalEntities.map(x => x.legalName).includes(info.row.original.requestor) ?
            getRFC2253ValueByKey(info.row.original.requestee, 'O')
          : getRFC2253ValueByKey(info.row.original.requestor, 'O')}
        </span>
      ),
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 6.9,
      },
      sortingFn: (rowA, rowB) => {
        const rowACParty =
          user.legalEntities.map(x => x.legalName).includes(rowA.original.requestor) ?
            getRFC2253ValueByKey(rowA.original.requestee, 'O')
          : getRFC2253ValueByKey(rowA.original.requestor, 'O');
        const rowBCParty =
          user.legalEntities.map(x => x.legalName).includes(rowB.original.requestor) ?
            getRFC2253ValueByKey(rowB.original.requestee, 'O')
          : getRFC2253ValueByKey(rowB.original.requestor, 'O');

        return (rowACParty ?? '')?.localeCompare(rowBCParty ?? '');
      },
    },
    {
      accessorKey: 'tradedAmount.quantity',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.tradedAmt)}</FieldTitle>
        </span>
      ),
      cell: info => {
        const inMillions = shouldDisplayInMillions({
          base: info.row.original.baseAsset,
          second: info.row.original.secondAsset,
        });

        return <span>{displayAmountWithCode(info.row.original.tradedAmount, false, inMillions)}</span>;
      },
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 10,
      },
      sortingFn: 'alphanumeric',
    },
    {
      accessorKey: 'quote.tradedAmount.quantity',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.quoteTradedAmt)}</FieldTitle>
        </span>
      ),
      cell: info => {
        const inMillions = shouldDisplayInMillions({
          base: info.row.original.baseAsset,
          second: info.row.original.secondAsset,
        });

        return (
          <span>
            {info.row.original.quote ?
              displayAmountWithCode(info.row.original.quote.tradedAmount, false, inMillions)
            : ''}
          </span>
        );
      },
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 10,
      },
      sortingFn: 'alphanumeric',
    },
    {
      accessorKey: 'rate',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>
            {showForwardPoints ? formatMessage(fieldTitles.fwdPts) : formatMessage(fieldTitles.impYieldOrRate)}
          </FieldTitle>
        </span>
      ),
      cell: info => (
        <span>
          {showForwardPoints ?
            displayForwardPoints(info.row.original.quote?.forwardPoints, '')
          : displayInterestRate(info.row.original.quote?.interestRate)}
        </span>
      ),
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 6.9,
      },
      sortingFn: (rowA, rowB) => {
        const rowARate =
          showForwardPoints ?
            displayForwardPoints(rowA.original.quote?.forwardPoints, '')
          : displayInterestRate(rowA.original.quote?.interestRate);
        const rowBRate =
          showForwardPoints ?
            displayForwardPoints(rowB.original.quote?.forwardPoints, '')
          : displayInterestRate(rowB.original.quote?.interestRate);

        return rowARate.localeCompare(rowBRate);
      },
    },
    {
      accessorKey: 'nearLegTime',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.startDate)}</FieldTitle>
        </span>
      ),
      cell: info => (
        <span>
          {info.row.original.nearLegTime ?
            <DateContainer>
              <span>{displayDate(info.row.original.nearLegTime, 'DD MMM YY')}</span>
              <span>{displayDate(info.row.original.nearLegTime, 'HH:mm:ss')}</span>
            </DateContainer>
          : <span>{displayNearLegAsAsap(false)}</span>}
        </span>
      ),
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 10,
      },
      sortDescFirst: true,
      sortingFn: 'datetime',
      sortUndefined: 1,
    },
    {
      accessorKey: 'maturityTime',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.endDate)}</FieldTitle>
        </span>
      ),
      cell: info => (
        <DateContainer>
          <span>{displayDate(info.row.original.maturityTime, 'DD MMM YY')}</span>
          <span>{displayDate(info.row.original.maturityTime, 'HH:mm:ss')}</span>
        </DateContainer>
      ),
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 13.1,
      },
      sortDescFirst: true,
      sortingFn: 'datetime',
    },
    {
      accessorKey: 'initialRate',
      header: () => (
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.initialRate)}</FieldTitle>
        </span>
      ),
      cell: info => <span>{getInitialFXRate(info.row.original.requestType)?.toString() ?? '--'}</span>,
      meta: {
        align: 'center',
        alignItems: 'center',
        size: 10,
      },
      sortingFn: (rowA, rowB) => {
        const rowARate = getInitialFXRate(rowA.original.requestType)?.toString() ?? '--';
        const rowBRate = getInitialFXRate(rowB.original.requestType)?.toString() ?? '--';

        return rowARate.localeCompare(rowBRate);
      },
    },
  ];
};
