import {useMemo, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {useIntl} from 'react-intl';

import {getLegDateTimeInput, prefixToTransactionDate, transactionDateToPrefix} from 'utils/RFQUtils';

import {displayDate, ensureFutureDate} from 'utils/DayjsUtils';

import dayjs from 'dayjs';

import {LegType, TransactionDate} from 'types/api';

import {fieldTitles} from 'constants/messages/labels';
import FieldTitle from 'ui/FieldTitle';
import TimeField, {FormTimeField} from 'ui/TimeField';
import {PrefixOption} from 'ui/TimeField/types';
import {Field, FormRow, Label} from 'containers/RFQForm/styles';
import {RFQFormValues} from 'containers/RFQForm/hooks/useRFQForm';
import useLegsConfig from 'containers/RFQForm/hooks/useLegsConfig';
import {Leg, legOptions} from 'containers/RFQForm/types';
import useSelectedMarketDay from 'containers/RFQForm/hooks/useSelectedMarketDay';

import {rfqFarLegFlashId, rfqNearLegFlashId, triggerRfqFarLegFlash, triggerRfqNearLegFlash} from './constants';

interface Props
  extends Pick<
    ReturnType<typeof useSelectedMarketDay>,
    'selectedMarket' | 'selectedNearLegMarketDay' | 'getMarketDayForTransactionDate'
  > {
  isRepo: boolean;
  disabled?: boolean;
}

export default function LegsRows({
  isRepo,
  selectedMarket,
  selectedNearLegMarketDay,
  getMarketDayForTransactionDate,
  disabled,
}: Props) {
  const {formatMessage} = useIntl();
  const methods = useFormContext<RFQFormValues>();
  const [farlegTransactionDate, nearlegTransactionDate, farleg, nearleg] = methods.watch([
    'farlegTransactionDate',
    'nearlegTransactionDate',
    'farleg',
    'nearleg',
  ]);
  const {nearLegInfo, farLegInfo, nearLegOpeningTimes, farLegOpeningTimes} = useLegsConfig({
    selectedMarket,
    selectedLegsMarketDay: selectedNearLegMarketDay,
  });

  const shouldShowASAP = useMemo(
    () => nearlegTransactionDate === TransactionDate.TPlusZero && dayjs(nearLegOpeningTimes.start).isBefore(dayjs()),
    [nearlegTransactionDate]
  );
  useEffect(() => {
    if (!shouldShowASAP && nearleg === 'ASAP' && nearLegOpeningTimes.start) {
      methods.setValue('nearleg', nearLegOpeningTimes.start, {shouldValidate: true});
    }
  }, [shouldShowASAP]);

  const handleLegPrefixChange =
    (leg: LegType) =>
    ({value: prefixToSet}: PrefixOption) => {
      const newSelectedMarketDay = getMarketDayForTransactionDate(prefixToTransactionDate(prefixToSet as Leg));
      if (!newSelectedMarketDay) return;
      methods.setValue('nearlegTransactionDate', prefixToTransactionDate(prefixToSet as Leg));
      methods.setValue(
        'nearleg',
        dayjs(newSelectedMarketDay.startsAt).isBefore(dayjs()) ?
          'ASAP'
        : ensureFutureDate(newSelectedMarketDay.startsAt)
      );
      const farlegOutput = getLegDateTimeInput(dayjs(newSelectedMarketDay.endsAt), prefixToSet as Leg);
      methods.setValue('farlegTransactionDate', farlegOutput.transactionDate);
      methods.setValue('farleg', dayjs(farlegOutput.timestamp));
      if (leg === LegType.NearLeg) triggerRfqFarLegFlash();
      if (leg === LegType.FarLeg) triggerRfqNearLegFlash();
    };

  return (
    <>
      <FormRow>
        <Label>
          <FieldTitle>{isRepo ? formatMessage(fieldTitles.onLeg) : formatMessage(fieldTitles.nearLeg)}</FieldTitle>
        </Label>
        <Field>
          {selectedMarket && nearLegInfo ?
            <FormTimeField
              name='nearleg'
              data-testid='rfqform-nearleg'
              prefix={transactionDateToPrefix(nearlegTransactionDate)}
              prefixOptions={legOptions}
              onPrefixChange={handleLegPrefixChange(LegType.NearLeg)}
              validTimesRange={nearLegOpeningTimes}
              tooltipPosition='left'
              disabled={disabled}
              showASAPOption={shouldShowASAP}
              flashKey={rfqNearLegFlashId}
            />
          : <TimeField
              data-testid='rfqform-timefield'
              validTimesRange={nearLegInfo}
              prefix='T+0'
              placeholder='--'
              disabled
            />
          }
        </Field>
      </FormRow>
      <FormRow>
        <Label>
          <FieldTitle>{isRepo ? formatMessage(fieldTitles.offLeg) : formatMessage(fieldTitles.farLeg)}</FieldTitle>
        </Label>
        <Field>
          {selectedMarket && farLegInfo ?
            <FormTimeField
              name='farleg'
              data-testid='rfqform-farleg'
              prefix={transactionDateToPrefix(farlegTransactionDate)}
              prefixOptions={legOptions}
              onPrefixChange={handleLegPrefixChange(LegType.FarLeg)}
              placeholder={displayDate(farleg, 'HH:mm')}
              validTimesRange={farLegOpeningTimes}
              tooltipPosition='left'
              disabled={disabled}
              flashKey={rfqFarLegFlashId}
            />
          : <TimeField validTimesRange={farLegInfo} prefix='T+0' placeholder='--' disabled />}
        </Field>
      </FormRow>
    </>
  );
}
