import {NotificationType} from 'types/api';

import OrderPopup from 'components/popups/OrderPopup';
import TradePopup from 'components/popups/TradePopup';

// TODO FX-3767: Introduce correct types
export const notificationTypeToRelatedPopup: {[key in NotificationType]?: any} = {
  // Late Fees
  ReceivedLateFeeRequest: TradePopup,
  ReceivedLateFeeRequestWithdrawn: TradePopup,
  ReceivedLateFeeRequestAccepted: TradePopup,
  ReceivedLateFeeRequestRejected: TradePopup,
  SentLateFeeRequestAccepted: TradePopup,
  SentLateFeeRequestRejected: TradePopup,
  // Unwind requests
  ReceivedTradeUnwindProposalCancelled: TradePopup,
  ReceivedTradeUnwindProposal: TradePopup,
  SentTradeUnwindProposalAccepted: TradePopup,
  SentTradeUnwindProposalRejected: TradePopup,
  ReceivedTradeUnwindProposalExpired: TradePopup,
  SentTradeUnwindProposalExpired: TradePopup,
  // Trade annuls
  ReceivedTradeAnnulProposal: TradePopup,
  ReceivedTradeAnnulProposalCancelled: TradePopup,
  ReceivedTradeAnnulProposalExpired: TradePopup,
  SentTradeAnnulProposalAccepted: TradePopup,
  SentTradeAnnulProposalExpired: TradePopup,
  SentTradeAnnulProposalRejected: TradePopup,
  // EMR requests
  ReceivedEarlyMaturityRequest: TradePopup,
  ReceivedEarlyMaturityRequestCancelled: TradePopup,
  SentEarlyMaturityRequestRejected: TradePopup,
  SentEarlyMaturityRequestAccepted: TradePopup,
  ReceivedEarlyMaturityRequestExpired: TradePopup,
  SentEarlyMaturityRequestExpired: TradePopup,

  // Order related
  OrderMatched: OrderPopup,
  OrderExpired: OrderPopup,
  OrderLimitReached: OrderPopup,
  OrderSpotRateOutOfRange: OrderPopup,
  MarketOrderNotFulfilled: OrderPopup,
};
