import {useEffect, useState, useRef} from 'react';

import dayjs, {Dayjs} from 'dayjs';
import {find} from 'lodash/fp';
import {displayDate, truncateTimePrecision} from 'utils/DayjsUtils';

import {useSetupFlash} from 'utils/AnimationUtils';

import {Controller, ControllerProps} from 'react-hook-form';

import {SListItem, SScrollableList, SContainer as TimePickerContainer} from 'ui/TimePicker/styles';

import {InputColorState} from 'ui/AmountField/types';

import TimePicker from '../TimePicker';
import {TimeFieldComponentProps} from './types';
import {Container, PickerContainer, Input, Prefix, Icon, InputWrapper} from './styles';
import TooltipWrapper from '../Tooltip/TooltipWrapper';

const TimeField = ({step = 15, ...props}: TimeFieldComponentProps) => {
  const node = useRef<HTMLDivElement | null>(null);
  const [showPicker, setShowPicker] = useState(false);
  const [showPrefixPicker, setShowPrefixPicker] = useState(false);
  const timeValue = props.value !== 'ASAP' ? dayjs.tz(props.value) : undefined;

  const {
    showASAPOption,
    onASAPClicked,
    prefix,
    prefixOptions,
    onPrefixChange,
    tooltip,
    tooltipPosition,
    invalid,
    warning,
    flashKey,
    placeholder: valueLabel,
    disabled,
    validTimesRange,
    timeFieldTooltipPosition,
    showSecondsPicker,
    width,
  } = props;

  const prefixLabel = prefix && prefixOptions ? find({value: prefix}, prefixOptions)?.label : prefix;

  function onFocus(): void {
    if (!disabled) {
      setShowPicker(true);
    }
  }

  function onSelectASAP(): void {
    onASAPClicked?.();
    setShowPicker(false);
  }

  function onChange(timePickerValue: Dayjs, shouldHide: boolean): void {
    if (!disabled && props.onChange) {
      props.onChange(truncateTimePrecision(timePickerValue, showSecondsPicker ? 'second' : 'minute'));
    }
    setShowPicker(!shouldHide);
  }

  function handleClickOutside(e: MouseEvent): void {
    if (!node.current?.contains(e.target as Node)) {
      setShowPicker(false);
      setShowPrefixPicker(false);
    }
  }

  const handlePrefixClick = (e: React.MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    setShowPrefixPicker(shouldShowPrefixPicker => !shouldShowPrefixPicker);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const {isFlashing} = useSetupFlash(flashKey);
  const inputColor: InputColorState = (() => {
    if (warning) return 'warning';
    if (invalid) return 'invalid';
    return 'regular';
  })();

  return (
    <TooltipWrapper
      message={tooltip}
      position={tooltipPosition}
      disabled={!invalid || !tooltip}
      data-testid={props['data-testid']}
    >
      <Container
        data-testid='timefield-container'
        flash={isFlashing}
        ref={node}
        width={width}
        disabled={disabled}
        inputColor={inputColor}
      >
        {prefixLabel && (
          <Prefix data-testid='timefield-prefix' clickable={!!onPrefixChange} onClick={handlePrefixClick}>
            {prefixLabel}
          </Prefix>
        )}

        {showPrefixPicker && prefixOptions?.length && (
          <PickerContainer data-testid='prefix-options-container'>
            <TimePickerContainer>
              <SScrollableList id='ScrollableList'>
                {prefixOptions.map(prefixOption => (
                  <SListItem
                    key={prefixOption.value}
                    value={prefixOption.value}
                    selected={prefix === prefixOption.value}
                    onClick={() => {
                      setShowPrefixPicker(false);
                      onPrefixChange?.(prefixOption);
                    }}
                  >
                    {prefixOption.label}
                  </SListItem>
                ))}
              </SScrollableList>
            </TimePickerContainer>
          </PickerContainer>
        )}

        {showPicker && validTimesRange !== undefined && (
          <PickerContainer prefix={prefixLabel} showOnTheRightSide={timeFieldTooltipPosition === 'right'}>
            <TimePicker
              value={timeValue}
              validTimesRange={validTimesRange}
              onChange={onChange}
              minutesStep={step}
              maxHeight='174px'
              onASAPClicked={onSelectASAP}
              showASAPOption={showASAPOption}
              tooltipPosition={timeFieldTooltipPosition}
              showSecondsPicker={showSecondsPicker}
            />
          </PickerContainer>
        )}

        <InputWrapper>
          <Input
            data-testid='timefield-input'
            value={
              valueLabel ? valueLabel
              : timeValue ?
                displayDate(timeValue, showSecondsPicker ? 'HH:mm:ss' : 'HH:mm')
              : '--'
            }
            onFocus={onFocus}
            readOnly
            disabled={disabled}
            prefix={prefix}
          />
          <Icon className='la la-clock' />
        </InputWrapper>
      </Container>
    </TooltipWrapper>
  );
};

export const FormTimeField = ({
  name,
  control,
  ...props
}: Omit<ControllerProps, 'render'> & Omit<TimeFieldComponentProps, 'value' | 'onChange'>) => (
  <Controller
    control={control}
    name={name}
    render={({field: {value, onChange}, fieldState: {error}}) => {
      return (
        <TimeField
          value={value}
          onChange={onChange}
          onASAPClicked={() => onChange('ASAP')}
          placeholder={value === 'ASAP' ? 'ASAP' : displayDate(value, 'HH:mm')}
          {...props}
          invalid={!!error}
          tooltip={error?.message}
        />
      );
    }}
  />
);

export default TimeField;
