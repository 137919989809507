import styled, {css} from 'styled-components';

export const Container = styled.div<{disabled?: boolean}>`
  background-color: ${p => p.theme.colors.blue900};
  margin: auto;
  overflow-y: auto;
  padding: 1.8em 0;
  height: 100%;
  grid-area: form;

  ${p =>
    p.disabled &&
    css`
      filter: brightness(50%);
      user-select: none;
      cursor: not-allowed;
    `}
`;

export const Top = styled.div`
  text-align: center;
  padding: 1em 0.2em 0.2em;
  max-width: calc(100% - 20px);
  margin: auto;

  p {
    font-size: 0.9em;
    line-height: 1.4em;
    color: ${p => p.theme.colors.blue50};
  }
`;
