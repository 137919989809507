import {useMemo, useState} from 'react';
import {sortBy} from 'lodash/fp';
import styled from 'styled-components';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {Limit, AggregatedLimit} from 'types/api';
import {useRealTimeLimitsQuery} from 'api/hooks/useRealTimeLimitsQuery';

import {useUser} from 'contexts/auth-context';

import {getRFC2253ValueByKey} from 'utils/utils';

import {Scope} from 'constants/permission-maps';

import GridTable from 'ui/GridTable';
import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';

import {getClobMatchesColumns, getCreditRiskColumns, getMtmLimitColumns} from './columns';
import ChangeLimitPopup from './ChangeLimitPopup';

const columnHelper = createColumnHelper<AggregatedLimit>();

const ManageLimits = () => {
  const [limitUnderEdit, setLimitUnderEdit] = useState<Limit>();
  const userWithMeta = useUser();
  const permissions = getRolePermissions(userWithMeta.user?.role);
  const {limits} = useRealTimeLimitsQuery();
  const isAdjustingDisabled = !hasPermission({permissions, scopes: [Scope.AdjustCreditLimits]});
  const handleAdjustLimit = (limit: Limit) => {
    setLimitUnderEdit(limit);
  };

  const handlePopupClose = () => setLimitUnderEdit(undefined);

  const columns = useMemo<ColumnDef<AggregatedLimit>[]>(
    () => [
      {
        id: 'counterparty',
        columns: [
          columnHelper.accessor('counterparty', {
            cell: info => getRFC2253ValueByKey(info.getValue() as string, 'O'),
            header: '',
            meta: {
              align: 'left',
            },
          }),
        ],
      },
      getCreditRiskColumns(isAdjustingDisabled, handleAdjustLimit),
      getMtmLimitColumns(isAdjustingDisabled, handleAdjustLimit),
      getClobMatchesColumns(isAdjustingDisabled, handleAdjustLimit),
    ],
    [isAdjustingDisabled]
  );

  const sortedLimits = sortBy('counterparty', limits);

  return (
    <SContainer>
      {limitUnderEdit && <ChangeLimitPopup limit={limitUnderEdit} onClose={handlePopupClose} />}
      <BoardHeader title='Manage Limits' />
      <GridTable columns={columns} data={sortedLimits} />
    </SContainer>
  );
};

const SContainer = styled.div`
  max-width: 790px;
  padding: 0 15px;
`;

export default ManageLimits;
