import {useCallback, useEffect, useMemo} from 'react';
import StackTrace from 'stacktrace-js';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import uniqBy from 'lodash/uniqBy';
import {useUser} from 'contexts/auth-context';

export interface ErrorLog {
  message: string;
  errorStack?: StackTrace.StackFrame[];
}

const QUERY_KEY = ['ErrorLogs'];

export default function useErrorLogsStore() {
  const {data: logs} = useQuery<ErrorLog[]>({
    queryKey: QUERY_KEY,
    initialData: [],
  });
  const client = useQueryClient();
  const user = useUser();

  useEffect(() => {
    const actualConsoleError = window.console.error;
    window.console.error = (...args) => {
      let errorStack: StackTrace.StackFrame[];
      void StackTrace.fromError(new Error(args[0]))
        .then(frames => (errorStack = frames))
        .catch(() => {
          /*empty*/
        })
        .finally(() => {
          client.setQueryData<ErrorLog[]>(QUERY_KEY, data =>
            uniqBy([...(data || []), {message: args[0], errorStack}], 'message')
          );
        });
      actualConsoleError(...args);
    };
    return () => {
      window.console.error = actualConsoleError;
    };
  }, []);

  const downloadBlobUrl = useMemo(() => {
    const file = new Blob(
      [
        JSON.stringify({
          logs,
          agent: navigator.userAgent,
          commitTimestamp: process.env.REACT_APP_COMMIT_TIMESTAMP,
          commitSha: process.env.REACT_APP_COMMIT_SHORT_SHA,
          deploymentTimestamp: process.env.REACT_APP_JOB_STARTED_AT,
          date: new Date(),
          entity: user?.legalEntities[0]?.shortName ?? user?.legalEntities[0]?.legalName,
        }),
      ],
      {type: 'text/plain'}
    );
    return URL.createObjectURL(file);
  }, [logs]);

  const clearLogs = useCallback(() => {
    client.setQueryData(QUERY_KEY, []);
  }, [client]);

  return {logs, downloadBlobUrl, clearLogs};
}
