import styled from 'styled-components';

import {ifProp, switchProp} from 'styled-tools';

import {color} from 'utils/theme';

import {DateTimeFieldLabelProps} from './types';

export const SCustomPickerLabel = styled.div<DateTimeFieldLabelProps>`
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  opacity: ${ifProp('disabled', '0.5', '1')};
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  font-size: 12px;
  min-width: 120px;
  padding: 0.8em;
  border-width: 2px;
  border-style: solid;
  border-color: ${switchProp(
    'inputColor',
    {
      highlight: color('green500'),
      warning: color('yellow500'),
      invalid: color('red400'),
      highlightRequired: color('white'),
    },
    color('gray470')
  )};
  border-radius: 25px;
  background-color: ${color('blue900')};
`;
