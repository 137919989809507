import dayjs from 'dayjs';

import {DateFormat, displayDate, generateValidDatesForRange} from 'utils/DayjsUtils';

import {groupBy} from 'lodash';

import {TimePickerProps} from './types';
import {SContainer, SListItem, SScrollableList, SWrapper, SListItemContainer} from './styles';

const TimePicker = ({
  onChange,
  value,
  backgroundColor,
  minutesStep = 1,
  width,
  validTimesRange,
  showASAPOption,
  onASAPClicked,
  tooltipPosition = 'bottom',
  showSecondsPicker,
}: TimePickerProps) => {
  const {start = dayjs().tz().startOf('day'), end = dayjs().tz().endOf('day')} = validTimesRange;
  const validDates = generateValidDatesForRange(start, end, minutesStep);
  const validDatesGroupedByDate = groupBy(validDates, date => displayDate(date, DateFormat.StandardWithHours));
  const validHoursSorted = Object.entries(validDatesGroupedByDate)
    .map(([dateWithHour, dates]) => [dateWithHour, dates[0]] as const)
    .sort(([, firstDate], [, secondDate]) => (firstDate.isBefore(secondDate) ? -1 : 1));

  const isASAPSelected = value === undefined;
  const selectedHour = value ? displayDate(value, DateFormat.StandardWithHours) : undefined;
  const validMinutesGroupedByHour = selectedHour ? validDatesGroupedByDate[selectedHour] : undefined;

  const availableSeconds = Array.from({length: 60}, (_, index) => index);

  const maxHeight = Math.min(20 * (validHoursSorted.length + 1), 220);
  const additionalHeight = showASAPOption || showSecondsPicker ? 25.2 : 0;
  const adjustContainerHeight = (baseHeight: number) => (baseHeight + additionalHeight).toString() + 'px';

  const selectMaxHeight = (containerOrWrapper: 'container' | 'wrapper') => {
    if (containerOrWrapper === 'container') {
      return tooltipPosition === 'right' ? adjustContainerHeight(400) : adjustContainerHeight(maxHeight);
    } else if (containerOrWrapper === 'wrapper') {
      return tooltipPosition === 'right' ? adjustContainerHeight(375) : maxHeight.toString() + 'px';
    }
  };

  const handleHourClick = (date: dayjs.Dayjs) => {
    // We want to keep minutes
    onChange?.(value ? date.set('minutes', value.minute()) : date, false);
  };
  const handleMinutesClick = (date: dayjs.Dayjs) => {
    // We want to keep seconds
    const dateToSet = showSecondsPicker && value ? date.second(value.second()) : date;
    onChange?.(dateToSet, !showSecondsPicker);
  };
  const handleSecondsClick = (second: number) => {
    if (value) onChange?.(value.second(second), true);
  };

  return (
    <SContainer
      id='time-container'
      backgroundColor={backgroundColor}
      width={width}
      maxHeight={selectMaxHeight('container')}
    >
      {showASAPOption && (
        <SListItem key='asap-item' selected={isASAPSelected} value='ASAP' onClick={onASAPClicked}>
          ASAP
        </SListItem>
      )}
      {showSecondsPicker && (
        <SListItemContainer>
          <SListItem key='hours-header' value='H'>
            H
          </SListItem>
          <SListItem key='minutes-header' value='M'>
            M
          </SListItem>
          <SListItem key='seconds-header' value='S'>
            S
          </SListItem>
        </SListItemContainer>
      )}
      <SWrapper data-testid='timepicker-wrapper' maxHeight={selectMaxHeight('wrapper')}>
        <SScrollableList>
          {validHoursSorted.map(([dateWithHour, date]) => {
            const isHourSelected = dateWithHour === displayDate(value, DateFormat.StandardWithHours);
            const hour = dateWithHour.slice(-2); // 16 Apr 2024 22 to 22
            return (
              <SListItem key={dateWithHour} selected={isHourSelected} onClick={() => handleHourClick(date)}>
                {hour}
              </SListItem>
            );
          })}
        </SScrollableList>
        {validMinutesGroupedByHour ?
          <SScrollableList>
            {validMinutesGroupedByHour.map(date => (
              <SListItem
                key={`${selectedHour}-min-${date.minute()}`}
                selected={date.minute() === value?.minute()}
                onClick={() => handleMinutesClick(date)}
              >
                {displayDate(date, 'mm' as DateFormat)}
              </SListItem>
            ))}
          </SScrollableList>
        : null}
        {showSecondsPicker ?
          <SScrollableList>
            {availableSeconds.map(second => (
              <SListItem
                key={`sec-${second}`}
                selected={second === value?.second()}
                onClick={() => handleSecondsClick(second)}
              >
                {displayDate(dayjs().second(second), 'ss' as DateFormat)}
              </SListItem>
            ))}
          </SScrollableList>
        : null}
      </SWrapper>
    </SContainer>
  );
};

export default TimePicker;
