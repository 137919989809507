import {useIntl} from 'react-intl';
import {useFormContext} from 'react-hook-form';

import {currencySymbol} from 'utils/AmountUtils';
import {getRFQAmountFieldLabel, getRfqAmounts} from 'utils/RFQUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {Amount, AssetPair, Side} from 'types/api';

import FieldTitle from 'ui/FieldTitle';
import {FormAmountField} from 'ui/AmountField';
import {FormCell, FormRow} from 'components/popups/CreateQuote/styles';
import useAvailableCapacityWarning from 'components/AvailableCapacityWarning/hooks/useAvailableCapacityWarning';
import {CreateQuoteFormValues} from 'components/popups/CreateQuote/hooks/useCreateQuoteForm';

interface Props {
  isRepo: boolean;
  assetPair: AssetPair;
  requesteeSide: Side;
  initialFXRate: number;
  spotRangeBigFig: number;
  tradedAmount: Amount;
}

function QuoteTradedAmountField({
  isRepo,
  assetPair,
  requesteeSide,
  initialFXRate,
  spotRangeBigFig,
  tradedAmount,
}: Props) {
  const {formatMessage} = useIntl();
  const methods = useFormContext<CreateQuoteFormValues>();
  const [quoteTradedAmount] = methods.watch(['quoteTradedAmount']);
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions(assetPair);

  const getEarmarkAmount = () => {
    const {sellingRfqAmount: sellingRange} = getRfqAmounts({
      pair: assetPair,
      side: requesteeSide,
      spotRangeBigFig,
      spotRate: initialFXRate,
      tradedAmount: quoteTradedAmount ?? 0,
      tradedCurrency: tradedAmount.asset.currency,
      inMillions,
    });

    return {
      asset: sellingRange.asset,
      quantity: Math.max(...(sellingRange.quantity.filter(Boolean) as number[])),
    };
  };

  const {show: showAvailableCapacityWarning} = useAvailableCapacityWarning('Quote', getEarmarkAmount());

  const tradedAmountFieldWarning = requesteeSide === 'SellBuy' && showAvailableCapacityWarning;

  const quoteTradedAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'quote-traded',
    assetPair,
    isRepo,
    tradedCurrency: tradedAmount.asset.currency,
    side: requesteeSide,
  });

  return (
    <FormRow>
      <FormCell>
        <FieldTitle key={1}>{formatMessage(quoteTradedAmountFieldLabel)}</FieldTitle>
      </FormCell>
      <FormCell>
        <FormAmountField
          data-testid='quote-traded-amt-input'
          name='quoteTradedAmount'
          prefix={currencySymbol(tradedAmount.asset.currency)}
          unit={inMillions ? 'm' : undefined}
          warning={tradedAmountFieldWarning}
          tooltipPosition='left'
        />
      </FormCell>
    </FormRow>
  );
}

export default QuoteTradedAmountField;
