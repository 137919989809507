import {Party, RFQQuoteWithRequest, RFQRequest} from 'types/api';

import {useUser} from 'contexts/auth-context';

import {useModal} from 'contexts/modal-context';

import {Scope} from 'constants/permission-maps';

import Button from 'ui/Button';

import {AcceptQuoteConfirmation} from 'components/popups/AcceptQuoteConfirmation';

import {RejectQuoteConfirmation} from 'components/popups/RejectQuoteConfirmation';

import {RejectRequestConfirmation} from 'components/popups/RejectRequestConfirmation';

import {CancelQuoteConfirmation} from 'components/popups/CancelQuoteConfirmation';

import {CancelRequestConfirmation} from 'components/popups/CancelRequestConfirmation';

import CreateQuotePopup from 'components/popups/CreateQuote';

import {getRolePermissions, hasPermission} from '../PermissionGate/utils';

interface PropsRfqActionButtons {
  request: RFQRequest;
  requestAndQuote: RFQQuoteWithRequest;
}

type RfqActionButtonsProps = PropsRfqActionButtons;

export const RFQActionButtons = (props: RfqActionButtonsProps) => {
  const {request, requestAndQuote} = props;
  const isRequestor = !request.isReceived;
  const isActive = !requestAndQuote.expired;
  const stage = requestAndQuote.stage.name;
  const {user} = useUser();
  const {openModal, closeModal} = useModal();
  const disabled = !hasPermission({
    permissions: getRolePermissions(user.role),
    scopes: [Scope.CanUseRfqActions],
  });

  const closeDetails = () => closeModal();
  const showAcceptQuoteConfirmation = (): void => {
    closeDetails();
    openModal({modal: AcceptQuoteConfirmation, props: {quoteId: requestAndQuote.quote.id}});
  };
  const showRejectQuoteConfirmation = (): void => {
    closeDetails();
    openModal({modal: RejectQuoteConfirmation, props: {quoteId: requestAndQuote.quote.id}});
  };
  const showCancelQuoteConfirmation = (): void => {
    closeDetails();
    openModal({modal: CancelQuoteConfirmation, props: {requestId: request.id}});
  };
  const showQuoteForm = (): void => {
    closeDetails();
    openModal({modal: CreateQuotePopup, props: {quoteFormRequest: request}});
  };
  const showCancelRequestConfirmation = (): void => {
    closeDetails();
    const relevantNegotiation = request.requesteesAndQuotes.find(
      r => r.requestee.legalName === requestAndQuote.requestee
    );
    if (relevantNegotiation) {
      const selectedNegotiationId: string = relevantNegotiation?.id;
      const selectedRequestee: Party = relevantNegotiation?.requestee;
      openModal({
        modal: CancelRequestConfirmation,
        props: {requestId: request.id, selectedNegotiationId, selectedRequestee},
      });
    }
  };
  const showRejectRequestConfirmation = (): void => {
    closeDetails();
    openModal({modal: RejectRequestConfirmation, props: {requestId: request.id}});
  };

  if (stage === 'QuoteIssued' && isRequestor && isActive) {
    return (
      <>
        <Button
          disabled={disabled}
          onClick={showAcceptQuoteConfirmation}
          data-testid='accept-quote-button'
          className='accept-button'
        >
          Accept
        </Button>
        <Button disabled={disabled} onClick={showRejectQuoteConfirmation} buttonStyle='grey' className='reject-button'>
          Reject
        </Button>
      </>
    );
  } else if (stage === 'QuoteIssued' && !isRequestor && isActive) {
    return (
      <Button
        data-testid='cancel-quote'
        disabled={disabled}
        onClick={showCancelQuoteConfirmation}
        buttonStyle='secondary'
      >
        Cancel
      </Button>
    );
  } else if (stage === 'Started' && !isRequestor && isActive) {
    return (
      <>
        <Button disabled={disabled} onClick={showQuoteForm} className='accept-button'>
          Reply
        </Button>
        <Button
          disabled={disabled}
          onClick={showRejectRequestConfirmation}
          buttonStyle='grey'
          className='reject-button'
        >
          Reject
        </Button>
      </>
    );
  } else if (stage === 'Started' && isRequestor && isActive) {
    return (
      <>
        <Button
          disabled={disabled}
          onClick={showCancelRequestConfirmation}
          buttonStyle='secondary'
          className='reject-button'
        >
          Cancel
        </Button>
      </>
    );
  }

  return <span />;
};

export default RFQActionButtons;
