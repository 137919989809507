import {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import {appTzShortName} from 'utils/setupDayjs';
import {DateFormat, displayDate} from 'utils/DayjsUtils';

const CurrentDateTime = () => {
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    const timer = window.setInterval(() => {
      setDate(dayjs());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <span>
        <time dateTime={displayDate(date, 'YYYY-MM-DD' as DateFormat)}>
          {displayDate(date, 'ddd DD MMM YYYY' as DateFormat)}
        </time>
      </span>
      <span>
        <time dateTime={displayDate(date, 'HH:mm:ss')}>
          {displayDate(date, 'HH:mm:ss')} <span data-testid='timezone'>{appTzShortName}</span>
        </time>
      </span>
    </>
  );
};

export default CurrentDateTime;
