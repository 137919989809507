import {color} from 'utils/theme';
import styled from 'styled-components';

import {SContainer} from 'ui/Layout/styles';

export const SCancelMessage = styled(SContainer)`
  position: absolute;
  z-index: 100;
  top: 4.5rem;
  right: 0;
  padding: 30px;
  border-radius: 6px;
  background-color: ${color('gray400')};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
`;

export const SCancelMessageText = styled.h5`
  font-size: 120%;
`;

export const SCancelMessageTextItalic = styled.div`
  font-style: italic;
  margin: 1rem 0;
`;
