import {pauseActiveOrders} from 'api/api';

import {showToast} from 'utils/ToastUtils';

import {useModal} from 'contexts/modal-context';

import Button from 'ui/Button';

import CancelOrderModal, {CancelOrderModalProps} from '../CancelOrderModal';

export interface ActiveOrdersButtonProps {
  disabled?: boolean;
  isLoading: boolean;
  setLoading: () => void;
}

export const PauseAllButton = ({disabled, isLoading, setLoading}: ActiveOrdersButtonProps) => {
  const {closeModal, openModal} = useModal();

  const openPauseMessage = () => {
    openModal({
      modal: CancelOrderModal,
      props: pauseFormProps,
    });
  };
  const handlePause = () => {
    setLoading();
    pauseActiveOrders()
      .then(() => {
        showToast('All your orders have been paused.', 'success');
      })
      .catch(error => {
        showToast('Orders could not be paused. Please try again later.', 'warning');
        setLoading();
        console.error(error);
      })
      .finally(() => {
        setLoading();
        closeModal();
      });
  };

  const pauseFormProps: CancelOrderModalProps = {
    title: 'Are you sure you want to pause all orders?',
    description: 'This pauses only the unfilled portion of each order.',
    onConfirm: handlePause,
    closeModal,
    isLoading,
  };

  return (
    <Button
      loading={isLoading}
      data-testid='pause-all-orders-button'
      onClick={openPauseMessage}
      disabled={disabled}
      buttonStyle={'warning'}
    >
      Pause All
    </Button>
  );
};
