import {useCallback, useEffect} from 'react';

import {RFQQuoteWithRequest} from 'types/api';
import dayjs from 'dayjs';
import {showToast} from 'utils/ToastUtils';

import {useRealTimeQuotesQuery} from 'api/hooks/useRealTimeQuotesQuery';

import useNotificationsQuery from 'api/hooks/useNotificationsQuery';

import useNotificationsReadDataStore from 'stores/useNotificationsReadDataStore';

import {NotificationData} from '../types';

export default function useQuoteToastNotifications() {
  const {quotes} = useRealTimeQuotesQuery();
  const {notificationsData} = useNotificationsQuery();
  const {lastRenderedTime, updateLastRenderedTime} = useNotificationsReadDataStore();

  const isNotExpired = (request: RFQQuoteWithRequest) => {
    const quoteUntilTime = dayjs(request.quote.validUntil).unix();
    const nowTime = dayjs().unix();
    return quoteUntilTime > nowTime && !request.expired;
  };

  const getCorrespondingRequestByQuoteId = useCallback(
    (notification: NotificationData<'ReceivedQuoteAccepted'>) =>
      quotes.find(req => req.quote.id === notification.payload.quoteId),
    [quotes]
  );

  const showQuoteAcceptedToast = useCallback(
    (notification: NotificationData<'ReceivedQuoteAccepted'>) => {
      const request = getCorrespondingRequestByQuoteId(notification);
      if (request && isNotExpired(request)) {
        showToast('Your quote has been accepted', 'success');
      }
    },
    [getCorrespondingRequestByQuoteId]
  );

  useEffect(() => {
    if (!quotes.length) return;

    const unrenderedQuoteNotifications = notificationsData
      .filter(n => n.type === 'ReceivedQuoteAccepted')
      .filter(n => dayjs(n.createdAt).unix() > lastRenderedTime);

    if (!unrenderedQuoteNotifications.length) return;

    const latestQuoteNotification = unrenderedQuoteNotifications.sort((a, b) =>
      dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1
    )[0];
    const newRenderedTime = dayjs(latestQuoteNotification?.createdAt).unix();
    unrenderedQuoteNotifications.forEach(notification =>
      showQuoteAcceptedToast(notification as NotificationData<'ReceivedQuoteAccepted'>)
    );
    updateLastRenderedTime(newRenderedTime);
  }, [
    JSON.stringify(notificationsData),
    JSON.stringify(quotes),
    lastRenderedTime,
    showQuoteAcceptedToast,
    updateLastRenderedTime,
  ]);
}
