import React from 'react';

import dayjs from 'dayjs';
import {RequesteeAndQuote, RFQQuote} from 'types/api';
import {displayForwardPoints, displayInterestRate, displayParty} from 'utils/utils';
import {displayLegPrefixAndDateTime} from 'utils/DayjsUtils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';
import {shouldShowQuoteValidUntil} from 'utils/RFQUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {ReferenceData} from 'types/layout';

import {useModal} from 'contexts/modal-context';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {Scope} from 'constants/permission-maps';

import Countdown from 'ui/Countdown';
import Button from 'ui/Button';

import {CancelQuoteConfirmation} from 'components/popups/CancelQuoteConfirmation';

import {QuoteProvidedProps} from './types';
import {Container, Row, Cell, RightCell} from './styles';
import PermissionsGate from '../PermissionGate';

export const QuoteProvided: React.FC<QuoteProvidedProps> = (props: QuoteProvidedProps) => {
  const {
    requestData,
    requestData: {
      requesteeSide,
      requestor,
      baseAsset,
      secondAsset,
      maturityTime: farLegTime,
      maturityTimeInput,
      requesteesAndQuotes,
    },
  } = props;
  const {referenceData} = useReferenceDataStore();
  const {openModal} = useModal();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  const inMillions = shouldDisplayInMillions({base: baseAsset, second: secondAsset});
  const requesteeAndQuote: RequesteeAndQuote = requesteesAndQuotes[0];
  const quote: RFQQuote | undefined = requesteeAndQuote.quote;
  const isRepo = isRepoPair({base: baseAsset, second: secondAsset});

  if (quote === undefined) {
    return <></>;
  }

  function onClickCancel(e: React.MouseEvent<HTMLButtonElement>): void {
    e.stopPropagation();
    openModal({modal: CancelQuoteConfirmation, props: {requestId: requestData.id}});
  }

  return (
    <Container data-testid='quote-provided' data-request-id={requestData.id} data-quote-id={quote?.id}>
      <Row>
        <Cell width={32}>{displayAssetPairWithSide({base: baseAsset, second: secondAsset}, requesteeSide, true)}</Cell>
        <Cell width={24}>{displayParty(requestor)}</Cell>
        <Cell width={22}>{displayAmountWithCode(quote.tradedAmount, false, inMillions)}</Cell>
        <RightCell>
          {
            <Countdown
              timeTarget={dayjs(quote.validUntil)}
              forceExpired={!shouldShowQuoteValidUntil(requesteeAndQuote)}
            />
          }
        </RightCell>
        <Cell width={32}>{displayLegPrefixAndDateTime(farLegTime, maturityTimeInput.transactionDate, 'HH:mm')}</Cell>
        <Cell width={24}>
          {isRepo || referenceData === ReferenceData.ImpliedYield ?
            displayInterestRate(quote.interestRate)
          : displayForwardPoints(quote.forwardPoints)}
        </Cell>
        <RightCell>
          <PermissionsGate scopes={[Scope.CanUseRfqActions]}>
            <Button buttonStyle='secondary' onClick={onClickCancel}>
              Cancel
            </Button>
          </PermissionsGate>
        </RightCell>
      </Row>
    </Container>
  );
};

export default QuoteProvided;
