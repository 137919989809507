import {HTMLAttributes, PropsWithChildren} from 'react';

import {useSetupFlash} from 'utils/AnimationUtils';

import {Container} from './styles';

interface FieldTitleProps extends HTMLAttributes<HTMLSpanElement> {
  uppercase?: boolean;
  flashKey?: string;
  'data-testid'?: string;
}
const FieldTitle = ({uppercase = true, flashKey, children, ...rest}: PropsWithChildren<FieldTitleProps>) => {
  const {isFlashing} = useSetupFlash(flashKey);

  return (
    <Container data-testid={rest['data-testid']} uppercase={uppercase} isFlashing={isFlashing} {...rest}>
      {children}
    </Container>
  );
};
export default FieldTitle;
