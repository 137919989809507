import styled, {css} from 'styled-components';
import {ifProp, switchProp} from 'styled-tools';
import {BlotterPosition} from 'types/layout';
import {color} from 'utils/theme';

export const DateContainer = styled.div`
  & :first-child {
    margin-right: 5px;
  }

  & :last-child {
    color: ${color('blue50')};
  }
`;

export const BlotterContainer = styled.div<{position: BlotterPosition}>`
  background-color: ${color('blue900')};
  display: flex;

  .table-container {
    flex-grow: 1;
    width: calc(100% - 130px);
    display: flex;

    .la {
      font-size: 16px;
    }

    .la-times-circle.remove {
      cursor: pointer;
      margin-right: 4px;
      color: ${color('red400')};
      font-size: 1rem;
      font-weight: bold;

      &:hover {
        color: ${color('red200')};
        text-shadow: 0 0 9px rgba(${color('red200')}, 0.7);
      }
    }
    .la-pause-circle.pause,
    .la-undo-alt {
      cursor: pointer;
      margin-right: 4px;
      color: ${color('blue50')};
      font-size: 1rem;
      font-weight: bold;

      &:hover {
        color: ${color('blue80')};
        text-shadow: 0 0 9px ${color('blue80')};
      }
    }

    .la-play.resume {
      cursor: pointer;
      margin-right: 4px;
      color: ${color('green400')};
      font-size: 0.9rem;
      font-weight: bold;

      &:hover {
        color: ${color('green450')};
        text-shadow: 0 0 9px ${color('green450')};
      }
    }
    .la-spinner {
      animation: spinnerRotation 1s infinite;
    }
    .la-circle-o {
      color: ${color('green400')};
      font-size: 1rem;
      font-weight: bold;
    }
    .la-check-circle {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  height: ${switchProp(
    'position',
    {
      [BlotterPosition.Minified]: '36px',
      [BlotterPosition.Default]: '220px',
      [BlotterPosition.Expanded]: 'calc(100vh - 70px)',
    },
    '220px'
  )};
`;

export const TabSwitcher = styled.div`
  width: 120px;
  box-sizing: border-box;
  border-right: 2px solid ${color('gray900')};
`;

export const Option = styled.div<{isActive: boolean}>`
  color: ${color('blue50')};
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  padding: 12px 0;
  position: relative;
  &:hover {
    cursor: pointer;
    text-shadow: 0 0 9px rgba(${color('green500')}, 0.7);
    color: ${color('green400')};
  }

  ${ifProp(
    'isActive',
    css`
      color: ${color('white')};
      &:after {
        font-family: 'Line Awesome Free';
        content: '\f061';
      }
      &:hover {
        color: ${color('white')};
      }
    `
  )}
`;

export const BlotterControls = styled.div`
  display: flex;
  flex-direction: row;
  .control {
    width: 33.33%;
    box-sizing: border-box;
    color: ${color('blue50')};
    font-size: 0.75rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    line-height: 34px;
    vertical-align: middle;
    border-bottom: 2px solid ${color('gray900')};

    svg {
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
      svg {
        path {
          stroke: ${color('green400')};
        }
        line {
          stroke: ${color('green400')};
        }
      }
    }
    &:nth-of-type(2) {
      border-right: 2px solid ${color('gray900')};
      border-left: 2px solid ${color('gray900')};
    }
  }
`;
