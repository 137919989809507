import {getAssetFromQuery} from 'utils/AssetUtils';

import {useQuery} from 'utils/browserUtils';

import {LayoutPath} from 'types/layout';

import {useMatch} from 'react-router';

import {SCol} from 'ui/Layout/styles';
import {OrderFormContainer} from 'containers/OrderForm/OrderFormContainer';
import ActiveOrders from 'containers/ActiveOrders/ActiveOrders';
import {CapacityForm} from 'containers/CapacityForm';
import {RFQFormContainer} from 'containers/RFQForm';

export const RightSideBar = () => {
  const query = useQuery();
  const isChartView = useMatch(LayoutPath.ChartView);
  const isClobView = useMatch(LayoutPath.ClobView);
  const isCapacityManagerView = useMatch(LayoutPath.CapacityManagerView);
  const isRfqView = useMatch(LayoutPath.RfqView);
  const asset = getAssetFromQuery(query, 'asset');

  const renderContent = () => {
    if (isChartView) {
      return <OrderFormContainer />;
    } else if (isClobView) {
      return <ActiveOrders />;
    } else if (isCapacityManagerView) {
      return <CapacityForm capacity={asset} />;
    } else if (isRfqView) {
      return <RFQFormContainer />;
    } else {
      return null;
    }
  };

  if (!renderContent()) return null;

  return (
    <SCol width={[1, 1 / 4, 1 / 4]} height={'100%'} display={['none', 'block', 'block']}>
      {renderContent()}
    </SCol>
  );
};
