import {AssetPair} from 'types/api';

import {isTradeSizeInMillions} from 'utils/MarketUtils';

import {useRealTimeMarketDaysQuery} from 'api/hooks/useRealTimeMarketDaysQuery';

export default function useDisplayAmountInMillions() {
  const {getMarketDaysForPair} = useRealTimeMarketDaysQuery();

  function shouldDisplayInMillions(assetPair: AssetPair) {
    const marketDays = getMarketDaysForPair(assetPair);

    return marketDays.length == 0 || isTradeSizeInMillions(marketDays[0]);
  }

  return {shouldDisplayInMillions};
}
