import {Trade, TradeUnwindRequestReason} from 'types/api';

import {Item} from 'ui/SelectField';

export type UnwindTradePopupProps = {
  tradeId: Trade['id'];
};

type ItemType = Item<TradeUnwindRequestReason | undefined>;
export const selectorItems: ItemType[] = [
  {
    label: '--',
    value: undefined,
  },
  {
    label: 'Not received',
    value: TradeUnwindRequestReason.NotReceived,
  },
  {
    label: 'Not sent',
    value: TradeUnwindRequestReason.NotSent,
  },
  {
    label: 'Not sent/received',
    value: TradeUnwindRequestReason.NotSentAndNotReceived,
  },
];
