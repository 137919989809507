import {useCallback, useEffect, useRef, useState} from 'react';
import {keyframes} from 'styled-components';

export const pulse = keyframes`
  0% {
    background-color: white;
  }
  10% {
    background-color: white;
  }
  100% {
    background-color: transparent;
  }
`;

/**
 * Used inside component, that would be flashed
 *
 * @param flashId id, that was passed from Props and indicates target of Flash
 * @returns [isFlashing] boolean state, represent whether to flash or not.
 */
export function useSetupFlash(flashId: string | undefined) {
  const [isFlashing, setIsFlashing] = useState(false);
  const timeoutId = useRef<number>();
  const callback = useCallback(() => {
    setIsFlashing(true);
    window.clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(() => {
      setIsFlashing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!flashId) return;
    const eventType = `Flash${flashId}`;

    document.addEventListener(eventType, callback);

    return () => {
      document.removeEventListener(eventType, callback);
    };
  }, [flashId, callback]);

  return {isFlashing};
}

function triggerFlashEvent(flashId: string) {
  const flashEvent = new CustomEvent(`Flash${flashId}`);
  document.dispatchEvent(flashEvent);
}

/**
 * @param id id defines which component should be flashed
 * @returns [id] used to pass inside target to be flashed. [trigger] used in component, from which you would flash target.
 */
export function createFlasher(id: string) {
  const trigger = () => triggerFlashEvent(id);
  return {id, trigger};
}
