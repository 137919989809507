import {QueryClient} from '@tanstack/react-query';

import {CapacityProjectionUpdate} from 'types/api';

import {unifiedEventStream} from '../unifiedEventStream';
import {useSubscriptionQuery} from './utils';

const QUERY_KEY = ['CapacityProjection'];

function subscribeProjections(client: QueryClient) {
  const handler = (data: CapacityProjectionUpdate) => {
    client.setQueryData(QUERY_KEY, () => data);
    return {type: ''}; // Necessary to match type, to remove after rework of unifiedEventStream
  };

  return unifiedEventStream.subscribe('capacity-projection', handler, {lastEventId: '0,0'});
}

export const useRealTimeCapacityProjectionsQuery = () => {
  const {data: capacityProjections} = useSubscriptionQuery<CapacityProjectionUpdate>(
    {
      queryKey: QUERY_KEY,
    },
    subscribeProjections
  );

  return capacityProjections;
};
