import {useIntl} from 'react-intl';

import {ReferenceData} from 'types/layout';
import {MarketType} from 'types/orderForm';
import useReferenceDataStore from 'stores/useReferenceDataStore';

import {useSetupFlash} from 'utils/AnimationUtils';
import {payingOrReceivingInterest} from 'utils/AmountUtils';

import {useFormContext} from 'react-hook-form';

import {fieldTitles} from 'constants/messages/labels';

import FieldTitle from 'ui/FieldTitle';
import {ImplYldMessageProps} from 'ui/Message/ImplYldMessage';

import {Label, Field} from 'containers/RFQForm/styles';
import {OrderFormValues} from 'containers/OrderForm/hooks/useOrderForm';
import {FormRow} from 'components/popups/CreateQuote/styles';
import {FormSuggestedForwardPointsField} from 'components/FormFields/ForwardPointsField';
import {FormSuggestedInterestRateField} from 'components/FormFields/InterestRateField';

interface Props {
  isRepo: boolean;
  flashId?: string;
  disabled?: boolean;
  highlightEmpty?: boolean;
  setIsImpliedYieldFieldTouched?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OrderFormRateRow({
  isRepo,
  flashId = '',
  disabled = false,
  highlightEmpty,
  setIsImpliedYieldFieldTouched,
}: Props) {
  const {formatMessage} = useIntl();
  const {isFlashing} = useSetupFlash(flashId);
  const {referenceData} = useReferenceDataStore();
  const methods = useFormContext<OrderFormValues>();

  const isFwdPts = referenceData === ReferenceData.ForwardPoints;
  const shouldUseFwdPts = !isRepo && referenceData === ReferenceData.ForwardPoints;
  const {rate, side, isNonAggressorOnly, marketType} = methods.watch();
  const implYldMessage: ImplYldMessageProps = {pays: undefined, text: undefined};
  const isMarket = marketType === MarketType.Market;
  const warningImpliedYield =
    !isMarket &&
    isNonAggressorOnly &&
    !!rate?.value &&
    payingOrReceivingInterest(side, rate?.value) === 'Paying interest';

  const getFieldTitle = () => {
    if (isRepo) return formatMessage(fieldTitles.rate);

    return isFwdPts ? formatMessage(fieldTitles.fwdPts) : formatMessage(fieldTitles.impYield);
  };

  return (
    <FormRow>
      <Label>
        <FieldTitle>{getFieldTitle()}</FieldTitle>
      </Label>
      <Field>
        {shouldUseFwdPts ?
          <FormSuggestedForwardPointsField
            flash={isFlashing}
            data-testid='fwd-points-field'
            implYldMessage={implYldMessage}
            disabled={disabled}
            warning={warningImpliedYield}
            highlightEmpty={highlightEmpty && !disabled}
            setIsImpliedYieldFieldTouched={setIsImpliedYieldFieldTouched}
          />
        : <FormSuggestedInterestRateField
            flash={isFlashing}
            data-testid='imp-yield-field'
            disabled={disabled}
            implYldMessage={implYldMessage}
            warning={warningImpliedYield}
            highlightEmpty={highlightEmpty && !disabled}
            setIsImpliedYieldFieldTouched={setIsImpliedYieldFieldTouched}
          />
        }
      </Field>
    </FormRow>
  );
}
