import {color} from '@techstack/styled-system';
import styled from 'styled-components';
import {switchProp} from 'styled-tools';

import Popup from 'ui/Popup';

export const SPopup = styled(Popup).attrs({variant: 'condensed'})`
  gap: 0.5rem;
  padding: 25px 20px;

  #content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledParagraph = styled.p<{variant?: 'warning'}>`
  text-transform: uppercase;
  color: ${switchProp(
    'variant',
    {
      false: color('white'),
      warning: color('yellow300'),
    },
    color('white')
  )};
`;
