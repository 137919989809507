import {useMemo} from 'react';
import {parseReadableNotifications, notificationTypeToStatus} from 'pages/NotificationCenterPage/utils';

import useNotificationsQuery from 'api/hooks/useNotificationsQuery';

import NotificationsTable from 'components/NotificationCenter/components/NotificationsTable';

import getColumns from './columns';

interface ConnectedNotificationTableProps {
  isNotificationClickable?: boolean;
}
const ConnectedNotificationTable = ({isNotificationClickable = true}: ConnectedNotificationTableProps) => {
  const {notificationsData: data, error, isLoading} = useNotificationsQuery();

  const readableNotifications = useMemo(() => parseReadableNotifications(data), [data]);
  const updatingNotifications = useMemo(() => data.filter(n => notificationTypeToStatus[n.type]), [data]);

  return (
    <NotificationsTable
      columns={getColumns(updatingNotifications)}
      data={readableNotifications}
      hasError={!!error}
      isLoading={isLoading}
      isNotificationClickable={isNotificationClickable}
    />
  );
};

export {NotificationsTable};

export default ConnectedNotificationTable;
