import dayjs from 'dayjs';

import {useUser} from 'contexts/auth-context';
import {displayForwardPoints, displayInterestRate, getImplYldMessage, displayParty} from 'utils/utils';
import {displayAmountWithCode} from 'utils/AmountUtils';
import {displayFullLeg} from 'utils/DayjsUtils';
import {createRowFromArray} from 'utils/TableUtils';
import {
  createSingleQuoteWithRequest,
  findPartyFromList,
  getRFQReceivedSent,
  getQuoteStatusText,
  shouldShowRequestValidUntil,
  counterAmountToString,
  shouldShowQuoteValidUntil,
  displayRFQRejectionReasons,
} from 'utils/RFQUtils';
import {AssetPair, RequesteeAndQuote, RequestTypeEnum, Side} from 'types/api';
import {displayAssetPairWithSide} from 'utils/AssetUtils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';
import {ReferenceData} from 'types/layout';
import {useIntl} from 'react-intl';

import {useModal} from 'contexts/modal-context';

import {useRealTimeRequestsQuery} from 'api/hooks/useRealTimeRequestsQuery';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {fieldTitles} from 'constants/messages/labels';

import Table from 'ui/Table/DetailsTable';
import {Row} from 'ui/Table/types';
import Countdown from 'ui/Countdown';
import Button from 'ui/Button';
import FieldTitle from 'ui/FieldTitle';
import Popup from 'ui/Popup';

import RFQActionButtons from 'components/RFQActionButtons/RFQActionButtons';
import {RFQCancelAllButton} from 'components/RFQActionButtons/RFQCancelAllButton';

import {TableLabel, CountdownWrapper, TableButtons, Uppercase, TableHeaderCentered} from './styles';

interface Props {
  requestId: string;
  onShowDetails?: (quoteId?: string) => void;
}

const createDetailsRow = (length: number) => createRowFromArray<RequesteeAndQuote>(length, '-');

export const Comparison = ({requestId, onShowDetails}: Props) => {
  const {selectRfqById} = useRealTimeRequestsQuery();
  const quotesDetailsRequest = selectRfqById(requestId);
  const {referenceData} = useReferenceDataStore();
  const user = useUser();
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();
  // i18n
  const {formatMessage} = useIntl();
  const {closeModal} = useModal();

  if (!quotesDetailsRequest) {
    return null;
  }

  const {requesteesAndQuotes} = quotesDetailsRequest;

  const requestType: RequestTypeEnum = quotesDetailsRequest.requestType.type;
  const isRepo: boolean = requestType === 'RepoRequestType';
  const isFXSwap: boolean = requestType === 'FXSwapRequestType';
  const showFwdPts: boolean = isFXSwap && referenceData === ReferenceData.ForwardPoints;
  const assetPair: AssetPair = {base: quotesDetailsRequest.baseAsset, second: quotesDetailsRequest.secondAsset};
  const inMillions = shouldDisplayInMillions(assetPair);
  const requestTableColums: number = requesteesAndQuotes.length + 2; // amount of requestees + request and labels

  // Rows for table
  // Headers
  const headersRow: Row = createDetailsRow(requestTableColums)(
    [
      '',
      <TableHeaderCentered key='0'>
        <Button data-testid='show-requestor-details' buttonStyle='grey' onClick={() => onShowDetails?.()}>
          {displayParty(quotesDetailsRequest.requestor)}
        </Button>
      </TableHeaderCentered>,
    ],
    requesteesAndQuotes,
    (requesteeAndQuote, index) => (
      <TableHeaderCentered key={index + 1}>
        <Button
          data-testid={`show-requestee-details-${index + 1}`}
          buttonStyle='grey'
          onClick={() => requesteeAndQuote.quote && onShowDetails?.(requesteeAndQuote.quote.id)}
          disabled={!requesteeAndQuote.quote}
        >
          {displayParty(requesteeAndQuote.requestee)}
        </Button>
      </TableHeaderCentered>
    )
  );
  // Valid for
  const validForRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{formatMessage(fieldTitles.validFor)}</FieldTitle>
      </TableLabel>,
      <CountdownWrapper key='1'>
        <Countdown
          timeTarget={dayjs(quotesDetailsRequest.validUntil)}
          forceExpired={!shouldShowRequestValidUntil(quotesDetailsRequest)}
        />
      </CountdownWrapper>,
    ],
    requesteesAndQuotes,
    (requesteeAndQuote, index) =>
      requesteeAndQuote.quote ?
        <CountdownWrapper key={index + 2}>
          <Countdown
            timeTarget={dayjs(requesteeAndQuote.quote.validUntil)}
            forceExpired={!shouldShowQuoteValidUntil(requesteeAndQuote)}
          />
        </CountdownWrapper>
      : '-'
  );

  // Sent / Received
  const sendReceivedRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{formatMessage(fieldTitles.sentReceived)}</FieldTitle>
      </TableLabel>,
      getRFQReceivedSent(quotesDetailsRequest.isReceived, false),
    ],
    requesteesAndQuotes,
    requesteeAndQuote => (requesteeAndQuote.quote ? getRFQReceivedSent(quotesDetailsRequest.isReceived, true) : '-')
  );
  // Traded amount
  const tradedAmountRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{formatMessage(fieldTitles.tradedAmt)}</FieldTitle>
      </TableLabel>,
      displayAmountWithCode(quotesDetailsRequest.tradedAmount, false, inMillions),
    ],
    requesteesAndQuotes,
    requesteeAndQuote =>
      requesteeAndQuote.quote ? displayAmountWithCode(requesteeAndQuote.quote.tradedAmount, false, inMillions) : '-'
  );
  const rateLabel: string = isFXSwap ? formatMessage(fieldTitles.impYield) : formatMessage(fieldTitles.rate);
  // Implied yield
  const rateRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{rateLabel}</FieldTitle>
      </TableLabel>,
      <span key={1}>{displayInterestRate(quotesDetailsRequest.suggestedInterestRate, '-', ' (Suggested)')}</span>,
    ],
    requesteesAndQuotes,
    requesteeAndQuote =>
      requesteeAndQuote.quote ? displayInterestRate(requesteeAndQuote.quote.interestRate, '-') : '-'
  );

  const fwdPtsRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{formatMessage(fieldTitles.fwdPts)}</FieldTitle>
      </TableLabel>,
      <span key={1}>{displayForwardPoints(quotesDetailsRequest.suggestedForwardPoints, ' pts (Suggested)')}</span>,
    ],
    requesteesAndQuotes,
    requesteeAndQuote => (requesteeAndQuote.quote ? displayForwardPoints(requesteeAndQuote.quote.forwardPoints) : '-')
  );

  const counterLabel = `${isRepo ? 'Securities' : 'Counter'} AMT`;
  // Quote second amount
  const counterAmountRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{counterLabel}</FieldTitle>
      </TableLabel>,
      counterAmountToString(
        quotesDetailsRequest.tradedAmount,
        quotesDetailsRequest.requestType,
        quotesDetailsRequest.baseAsset,
        quotesDetailsRequest.secondAsset,
        isRepo,
        inMillions
      ),
    ],
    requesteesAndQuotes,
    requesteeAndQuote =>
      requesteeAndQuote.quote ?
        counterAmountToString(
          requesteeAndQuote.quote.tradedAmount,
          quotesDetailsRequest.requestType,
          quotesDetailsRequest.baseAsset,
          quotesDetailsRequest.secondAsset,
          isRepo,
          inMillions
        )
      : '-'
  );

  // Interest side
  // define our side
  const yourSide =
    quotesDetailsRequest.isReceived ? quotesDetailsRequest.requesteeSide : quotesDetailsRequest.requestorSide;
  // Make an array of current user legal names for requestee identification.
  const userLegalEntitiesLegalNames = user.legalEntities.map(legalEntity => legalEntity.legalName);
  // if request is received - look for entity in requestees
  const myParty = findPartyFromList(quotesDetailsRequest, userLegalEntitiesLegalNames); // TODO: This should never be undefined

  // return interest side requestd on provided input
  const getInterestSide = (entityShortName: string, userSide: Side, impliedYield: number | undefined) =>
    getImplYldMessage(entityShortName, userSide, impliedYield);

  // define interest rate for the request
  const requestInterestSide =
    (myParty && getInterestSide(displayParty(myParty), yourSide, quotesDetailsRequest.suggestedInterestRate).text) ||
    '-';

  const interestSideRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{formatMessage(fieldTitles.interestSide)}</FieldTitle>
      </TableLabel>,
      <Uppercase key='0'>{requestInterestSide}</Uppercase>,
    ],
    requesteesAndQuotes,
    requesteeAndQuote => (
      <Uppercase>
        {(myParty && getInterestSide(displayParty(myParty), yourSide, requesteeAndQuote.quote?.interestRate).text) ||
          '-'}
      </Uppercase>
    )
  );

  // Pair - side
  const marketSide = displayAssetPairWithSide(
    assetPair,
    quotesDetailsRequest.isReceived ? quotesDetailsRequest.requesteeSide : quotesDetailsRequest.requestorSide,
    true
  );
  const marketSideRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{formatMessage(fieldTitles.yourSide)}</FieldTitle>
      </TableLabel>,
      <span data-testid='ourMarketSide' key='1'>
        {marketSide}
      </span>,
    ],
    requesteesAndQuotes,
    requesteeAndQuote => (
      <span data-testid='counterpartyMarketSide'>
        {user.legalEntities.includes(requesteeAndQuote.requestee) ? marketSide : '--'}
      </span>
    )
  );

  // Nearleg
  const nearLegLabel = displayFullLeg(
    quotesDetailsRequest.nearLegTime,
    quotesDetailsRequest.nearLegTimeInput?.transactionDate
  );
  const nearLegRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>
          {isRepo ? formatMessage(fieldTitles.onLegTime) : formatMessage(fieldTitles.nearLegTime)}
        </FieldTitle>
      </TableLabel>,
      nearLegLabel,
    ],
    requesteesAndQuotes,
    () => nearLegLabel
  );
  // Far leg
  const farLegRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>
          {isRepo ? formatMessage(fieldTitles.offLegTime) : formatMessage(fieldTitles.farLegTime)}
        </FieldTitle>
      </TableLabel>,
      displayFullLeg(quotesDetailsRequest.maturityTime, quotesDetailsRequest.maturityTimeInput.transactionDate),
    ],
    requesteesAndQuotes,
    () => displayFullLeg(quotesDetailsRequest.maturityTime, quotesDetailsRequest.maturityTimeInput.transactionDate)
  );
  // Rejection reasons
  const statusRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle>{formatMessage(fieldTitles.status)}</FieldTitle>
      </TableLabel>,
      '',
    ],
    requesteesAndQuotes,
    requesteeAndQuote =>
      getQuoteStatusText(quotesDetailsRequest.isReceived, requesteeAndQuote.expired, requesteeAndQuote.stage.name)
  );

  const rejectionRow: Row = createDetailsRow(requestTableColums)(
    [
      <TableLabel key='0'>
        <FieldTitle data-testid='rejection-reason'>{formatMessage(fieldTitles.rejectionReason)}</FieldTitle>
      </TableLabel>,
      '-',
    ],
    requesteesAndQuotes,
    requesteeAndQuote => displayRFQRejectionReasons(quotesDetailsRequest, requesteeAndQuote)
  );
  // Buttons
  const cancelAllButton = (
    <TableButtons>
      <RFQCancelAllButton request={quotesDetailsRequest} />
    </TableButtons>
  );
  const buttonsRow: Row = createDetailsRow(requestTableColums)(
    ['', cancelAllButton],
    requesteesAndQuotes,
    requesteeAndQuote => (
      <TableButtons>
        <RFQActionButtons
          request={quotesDetailsRequest}
          requestAndQuote={createSingleQuoteWithRequest(requesteeAndQuote, quotesDetailsRequest, user.legalEntities)}
        />
      </TableButtons>
    )
  );

  // Merging them
  const tableRows: Row[] = [
    headersRow,
    validForRow,
    sendReceivedRow,
    tradedAmountRow,
    counterAmountRow,
    showFwdPts ? fwdPtsRow : rateRow,
    interestSideRow,
    marketSideRow,
    nearLegRow,
    farLegRow,
    statusRow,
    rejectionRow,
    buttonsRow,
  ];

  return (
    <Popup title='RFQ Overview' onClose={() => closeModal()}>
      <Table rows={tableRows} />
    </Popup>
  );
};
