import {useMemo} from 'react';

import {displayParty} from 'utils/utils';

import {useQuery} from '@tanstack/react-query';

import {Item} from 'ui/SelectField';

import {getAvailableCounterparties} from '../api';

export function useAvailableCounterpartiesQuery() {
  const {data: availableCounterparties, refetch} = useQuery({
    queryKey: ['AvaliableCounterparties'],
    queryFn: () => getAvailableCounterparties().then(res => res.data),
    staleTime: 10000,
  });

  const partiesAsItems: Item<string>[] =
    availableCounterparties?.map(party => ({
      value: party.legalName,
      label: displayParty(party),
    })) ?? [];

  const shortNamesMap: Map<string, string> = useMemo(
    () =>
      (availableCounterparties || []).reduce(
        (map, party) => map.set(party.legalName, displayParty(party)),
        new Map<string, string>()
      ),
    [availableCounterparties]
  );

  return {availableCounterparties, partiesAsItems, refetch, shortNamesMap};
}
