import {useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Column} from '@tanstack/react-table';

import {FlagType, Trade} from 'types/api';

import {useFlaggedTradesIdsQuery} from 'api/hooks/useFlaggedTradesIdsQuery';

import {fieldTitles} from 'constants/messages/labels';

import FieldTitle from 'ui/FieldTitle';
import {CheckBox} from 'ui/CheckBox';

import {TradesFilters} from '../types';

import {Container, Filter, FiltersContainer, FiltersToggle} from '../styles';

interface Props {
  column: Column<Trade>;
}

export default function StatusTableHeader({column}: Props) {
  const {formatMessage} = useIntl();
  const filtersRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const currentFilters = useMemo(() => column.getFilterValue() as TradesFilters[], [column]);
  const {refetchFlaggedTradesIds} = useFlaggedTradesIdsQuery(
    [FlagType.NearLegNonreceipt, FlagType.FarLegNonreceipt],
    true
  );

  function toggleFilter(filter: TradesFilters) {
    if (!Array.isArray(currentFilters) || !(currentFilters as []).length) {
      column.setFilterValue([filter]);
    } else {
      if (currentFilters.includes(filter)) column.setFilterValue(currentFilters.filter(f => f !== filter));
      else column.setFilterValue([...currentFilters, filter]);
    }
  }

  useEffect(() => {
    function handler(e: MouseEvent) {
      if (filtersRef.current && !filtersRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handler, true);

    return () => {
      document.removeEventListener('mousedown', handler, true);
    };
  }, []);

  return (
    <Container data-testid='status-column' ref={filtersRef}>
      <FiltersToggle
        data-testid='status-filters-toggle'
        open={open}
        filtersApplied={currentFilters?.length > 0}
        onClick={() => setOpen(!open)}
      >
        <span style={{paddingRight: '5px'}}>
          <FieldTitle>{formatMessage(fieldTitles.status)}</FieldTitle>
        </span>
        <span>
          <i className='la la-filter' />
        </span>
      </FiltersToggle>
      {open && (
        <FiltersContainer data-testid='status-filters'>
          <b>Filter Trades</b>
          <Filter>
            <CheckBox
              data-testid='outstanding-trades-filter'
              checked={currentFilters?.includes(TradesFilters.OutstandingStatus)}
              onChange={() => toggleFilter(TradesFilters.OutstandingStatus)}
              value={TradesFilters.OutstandingStatus}
            />
            <p>Outstanding Trades</p>
          </Filter>
          <Filter>
            <CheckBox
              data-testid='flagged-as-non-receipt-filter'
              checked={currentFilters?.includes(TradesFilters.FlaggedAsNonReceipt)}
              onChange={() => {
                void refetchFlaggedTradesIds();
                toggleFilter(TradesFilters.FlaggedAsNonReceipt);
              }}
              value={TradesFilters.FlaggedAsNonReceipt}
            />
            <p>Flagged As Non-Receipt</p>
          </Filter>
          <Filter>
            <CheckBox
              data-testid='late-fee-proposal-sent-filter'
              checked={currentFilters?.includes(TradesFilters.SentLateFeeProposalWithoutResponse)}
              onChange={() => toggleFilter(TradesFilters.SentLateFeeProposalWithoutResponse)}
              value={TradesFilters.SentLateFeeProposalWithoutResponse}
            />
            <p>Late Fees Proposal Sent: No Response</p>
          </Filter>
          <Filter>
            <CheckBox
              data-testid='late-fee-proposal-rejected-filter'
              checked={currentFilters?.includes(TradesFilters.SentAndRejectedLateFeeProposal)}
              onChange={() => toggleFilter(TradesFilters.SentAndRejectedLateFeeProposal)}
              value={TradesFilters.SentAndRejectedLateFeeProposal}
            />
            <p>Late Fees Proposal Sent: Rejected</p>
          </Filter>
          <Filter>
            <CheckBox
              data-testid='late-fee-proposal-received-filter'
              checked={currentFilters?.includes(TradesFilters.ReceivedLateFeeProposalWithoutResponse)}
              onChange={() => toggleFilter(TradesFilters.ReceivedLateFeeProposalWithoutResponse)}
              value={TradesFilters.ReceivedLateFeeProposalWithoutResponse}
            />
            <p>Late Fees Proposal Received: No Response</p>
          </Filter>
        </FiltersContainer>
      )}
    </Container>
  );
}
