import {Trade, FlagType, CashflowBody} from 'types/api';

import {CashFlowLegType, FlagAs} from './types';

export const getLateFeeOperationsAndStatusesOrder = (trade: Trade, legType: CashFlowLegType) => {
  switch (legType) {
    case 'nearLeg':
      return trade.side === 'SellBuy' ? 'receiveThenSend' : 'sendThenReceive';
    case 'farLeg':
      return trade.side === 'SellBuy' ? 'sendThenReceive' : 'receiveThenSend';
    default:
      return trade.side === 'SellBuy' ? 'receiveThenSend' : 'sendThenReceive';
  }
};

// the API provides us FlagType range of values, so we need to do a some conversions here
export const provideFlagType = (legType: CashFlowLegType, flagAsCheckboxType: FlagAs) => {
  switch (true) {
    case legType === 'nearLeg' && flagAsCheckboxType === FlagAs.Sent:
      return FlagType.NearLegSent;
    case legType === 'nearLeg' && flagAsCheckboxType === FlagAs.Received:
      return FlagType.NearLegReceived;
    case legType === 'nearLeg' && flagAsCheckboxType === FlagAs.Nonreceipt:
      return FlagType.NearLegNonreceipt;
    case legType === 'farLeg' && flagAsCheckboxType === FlagAs.Sent:
      return FlagType.FarLegSent;
    case legType === 'farLeg' && flagAsCheckboxType === FlagAs.Received:
      return FlagType.FarLegReceived;
    default:
      return FlagType.FarLegNonreceipt;
  }
};

// for FX-2972, we need to disable the non-receipt checkbox if that leg is marked as received
// this helper function checks if the same leg has been received or not
// for FX-3834 we disable flagging as received, if non-receipt presented
export const isFlagAsBlockedByOtherFlag = (flagType: FlagType, cashflows: CashflowBody[]) => {
  switch (flagType) {
    case FlagType.NearLegNonreceipt:
      return cashflows.find(cashflow => cashflow.flagType === FlagType.NearLegReceived)?.flagValue || false;
    case FlagType.FarLegNonreceipt:
      return cashflows.find(cashflow => cashflow.flagType === FlagType.FarLegReceived)?.flagValue || false;

    case FlagType.NearLegReceived:
      return cashflows.find(cashflow => cashflow.flagType === FlagType.NearLegNonreceipt)?.flagValue || false;
    case FlagType.FarLegReceived:
      return cashflows.find(cashflow => cashflow.flagType === FlagType.FarLegNonreceipt)?.flagValue || false;
    default:
      return false;
  }
};
