import {useMemo} from 'react';
import dayjs from 'dayjs';

import {NegotiationStatusText} from 'utils/RFQUtils';

import {NotificationData} from 'pages/NotificationCenterPage/types';
import {notificationTypeToStatus, getValidFor} from 'pages/NotificationCenterPage/utils';

import {NotificationPayloadMap} from 'types/api';

import Countdown from 'ui/Countdown';

export interface ComponentProps {
  notification: NotificationData;
  updatingNotifications: NotificationData[];
}

const getStatus = (
  notification: NotificationData,
  updatingNotifications: NotificationData[]
): NegotiationStatusText | undefined => {
  if (updatingNotifications.length === 0) {
    return;
  }

  let relevantNotifications: NotificationData[] = [];

  if (notification.type === 'ReceivedRequest') {
    type PayloadType = NotificationPayloadMap['ReceivedRequest'];
    const payload = notification.payload as PayloadType;
    relevantNotifications = updatingNotifications.filter(
      n => (n.payload as PayloadType).requestId === payload.requestId
    );
  } else if (notification.type === 'ReceivedQuote') {
    type PayloadType = NotificationPayloadMap['ReceivedQuote'];
    const payload = notification.payload as PayloadType;
    relevantNotifications = updatingNotifications.filter(n => (n.payload as PayloadType).quoteId === payload.quoteId);
  } else if (notification.type === 'SentQuote') {
    type PayloadType = NotificationPayloadMap['SentQuote'];
    const payload = notification.payload as PayloadType;
    relevantNotifications = updatingNotifications
      .filter(n => n.type.startsWith('SentQuote'))
      .filter(n => (n.payload as PayloadType).quoteId === payload.quoteId);
  } else if (notification.type === 'ReceivedLateFeeRequest') {
    type PayloadType = NotificationPayloadMap['ReceivedLateFeeRequest'];
    const payload = notification.payload as PayloadType;
    relevantNotifications = updatingNotifications.filter(
      n => (n.payload as PayloadType).lateFeeId === payload.lateFeeId
    );
  } else if (notification.type === 'ReceivedEarlyMaturityRequest') {
    relevantNotifications = updatingNotifications
      .filter(n => n.type.startsWith('ReceivedEarlyMaturityRequest'))
      .filter(n => n.aggregateId === notification.aggregateId);
  } else if (notification.type === 'ReceivedTradeUnwindProposal') {
    relevantNotifications = updatingNotifications
      .filter(n => n.type.startsWith('ReceivedTradeUnwindProposal'))
      .filter(n => n.aggregateId === notification.aggregateId);
  } else if (notification.type === 'ReceivedTradeAnnulProposal') {
    relevantNotifications = updatingNotifications
      .filter(n => n.type.startsWith('ReceivedTradeAnnulProposal'))
      .filter(n => n.aggregateId === notification.aggregateId);
  } else if (notification.type === 'MtMLimitBreached') {
    const payload = notification.payload as NotificationPayloadMap['MtMLimitBreached'];
    relevantNotifications = updatingNotifications
      .filter(n => n.type === 'MtMLimitBreachResolved')
      .filter(n => {
        const p = n.payload as NotificationPayloadMap['MtMLimitBreachResolved'];
        return p.counterparty === payload.counterparty && p.resolvedTimestamp > payload.timestamp;
      });
  }

  if (relevantNotifications.length === 0) {
    return;
  }

  const latestRelevantNotification = relevantNotifications[relevantNotifications.length - 1];

  return notificationTypeToStatus[latestRelevantNotification.type];
};

export const NotificationStatusRenderer = ({notification, updatingNotifications}: ComponentProps) => {
  const validFor = useMemo(() => getValidFor(notification), [notification]);

  const notificationStatus = useMemo(
    () => getStatus(notification, updatingNotifications),
    [notification, updatingNotifications]
  );

  return (
    (notificationStatus && <div data-testid='notification-status'>{notificationStatus}</div>) ||
    (validFor && <Countdown timeTarget={dayjs(validFor)} />) || <>--</>
  );
};
