import {Amount, MarketSnapshot, Side} from 'types/api';
import {displayAmountWithUnit} from 'utils/AmountUtils';
import {displayInterestRate} from 'utils/utils';
import useDisplayAmountInMillions from 'utils/hooks/useDisplayAmountInMillions';

import {ClobPricing} from './styles';

export interface BestRateProps {
  marketSnapshot?: MarketSnapshot;
  side: Side;
  isOnlyPartialFill?: boolean;
}
const BestRate = ({marketSnapshot, side, isOnlyPartialFill = false}: BestRateProps) => {
  const {shouldDisplayInMillions} = useDisplayAmountInMillions();

  if (!marketSnapshot) {
    return <>{'--'}</>;
  }

  const asset = side === 'BuySell' ? marketSnapshot.assetPair.second : marketSnapshot.assetPair.base;
  const inMillions = shouldDisplayInMillions(marketSnapshot.assetPair);

  if (side === 'BuySell') {
    if (!marketSnapshot.bestBuySellRate) {
      return <>{'--'}</>;
    }
    const buySellAmount: Amount = {
      quantity: marketSnapshot.bestBuySellRate.depth,
      asset,
    };
    return (
      <span>
        <span>{`${displayInterestRate(marketSnapshot.bestBuySellRate.interestRate)} `}</span>
        <ClobPricing data-testid='clob-pricing-amount' warnAboutPartialCovering={isOnlyPartialFill} className='ml-1'>
          {displayAmountWithUnit(buySellAmount, inMillions)}
        </ClobPricing>
      </span>
    );
  }

  if (!marketSnapshot.bestSellBuyRate) {
    return <>{'--'}</>;
  }
  const sellBuyAmount: Amount = {
    quantity: marketSnapshot.bestSellBuyRate.depth,
    asset,
  };

  return (
    <span>
      <span>{`${displayInterestRate(marketSnapshot.bestSellBuyRate.interestRate)} `}</span>
      <ClobPricing warnAboutPartialCovering={isOnlyPartialFill}>
        {displayAmountWithUnit(sellBuyAmount, inMillions)}
      </ClobPricing>
    </span>
  );
};

export default BestRate;
