import {useIntl} from 'react-intl';

import {SButtonsContainer} from 'pages/AnalyticsPage/components/ManageLimits/ChangeLimitPopup/styles';
import {displayDate} from 'utils/DayjsUtils';
import {To, convertQuantityToAmount, displayAmountWithCode} from 'utils/AmountUtils';
import {displayAsset} from 'utils/AssetUtils';

import {fieldTitles} from 'constants/messages/labels';

import Button from 'ui/Button';
import FieldTitle from 'ui/FieldTitle';
import Popup from 'ui/Popup';

import {Bottom, Cell} from 'containers/RFQPopup/styles';

import {CapacityAdjustmentPopupProps} from './types';
import {InfoContainer, InfoLine} from './styles';

const CapacityAdjustmentPopup = ({adjustedCapacity, onAdjust, onClose}: CapacityAdjustmentPopupProps) => {
  const {formatMessage} = useIntl();

  const onConfirm = () => {
    onAdjust();
    onClose();
  };

  const fromTime = adjustedCapacity.fromTime === undefined ? 'ASAP' : displayDate(adjustedCapacity.fromTime, 'HH:mm');

  return (
    <Popup title={formatMessage(fieldTitles.confirmCapacityAdjustment)} onClose={onClose} width='500px'>
      <Bottom>
        <InfoContainer>
          <InfoLine data-testid='capacity-row'>
            <FieldTitle data-testid='capacity-field-title'>Capacity</FieldTitle>
            <Cell data-testid='capacity-value'>{displayAsset(adjustedCapacity.asset)}</Cell>
          </InfoLine>
          <InfoLine data-testid='from-row'>
            <FieldTitle data-testid='from-field-title'>From</FieldTitle>
            <Cell data-testid='from-value'>
              {adjustedCapacity.fromLeg} - {fromTime}
            </Cell>
          </InfoLine>
          <InfoLine data-testid='to-row'>
            <FieldTitle data-testid='to-field-title'>To</FieldTitle>
            <Cell data-testid='to-value'>
              {adjustedCapacity.toLeg} - {displayDate(adjustedCapacity.toTime, 'HH:mm')}
            </Cell>
          </InfoLine>
          <InfoLine data-testid='amount-row'>
            <FieldTitle data-testid='amount-field-title'>Amount</FieldTitle>
            <Cell data-testid='amount-value'>
              {displayAmountWithCode(
                convertQuantityToAmount(adjustedCapacity.amount, To.Store, adjustedCapacity.asset, undefined, true),
                false,
                true
              )}
            </Cell>
          </InfoLine>
        </InfoContainer>
        <>
          <SButtonsContainer>
            <Button data-testid='edit-button' width='45%' buttonStyle='grey' onClick={onClose}>
              Edit
            </Button>
            <Button data-testid='confirm-button' width='45%' onClick={onConfirm}>
              Confirm
            </Button>
          </SButtonsContainer>
        </>
      </Bottom>
    </Popup>
  );
};

export default CapacityAdjustmentPopup;
