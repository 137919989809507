import {parse} from 'rfc2253';
import {CSSProperties} from 'styled-components';
import dayjs from 'dayjs';
import {AxiosResponse} from 'axios';

import {LastTraded, Side, UserWithMeta, Party} from 'types/api';

import {forwardPointsDecimals} from 'constants/orderForm';
import {Unit} from 'constants/commonEnums';

import {displayAmountWithUnit} from './AmountUtils';
import {displayDate} from './DayjsUtils';

export function shouldShowVolumeChart() {
  return window.REACT_APP_SHOW_VOLUME_CHART === 'true';
}

export function shouldHideRfqTab() {
  return window.REACT_APP_HIDE_RFQ_TAB === 'true';
}

/**
 * Function to hack Application timezone offset, basicaly adds an hour offset to the local time.
 *
 * @param d [Date] - date to set offset to
 * @param appOffset [number] - application timazone offset in hours.
 * Example user tz GTM+2, App tz GTM+0, provided time 16:45 GTM+2 -> returned time with offset 18:45 GTM+2
 * (when will be processed on the app server will become 16:45 GTM+0).
 */
export const setTimeDifference: (d: Date, appOffset: number) => Date = (d: Date, appOffset: number): Date => {
  // To set correct date/time figure out difference between user time and application time
  const userOffset: number = d.getTimezoneOffset() / 60;
  const offsetToApply: number = appOffset + userOffset;
  // return UTC time - calculated offset to force Date function show application date/time.
  return new Date(d.getTime() - d.getTimezoneOffset() + offsetToApply * 1000 * 60 * 60);
};

// Facke Auth
export interface LoginData {
  email: string;
  password: string;
}

export interface AuthResponse {
  status: boolean;
  payload: string;
}

/**
 * Return true if response status 4** or 5**
 *
 * @param status api response status
 */
export const isErrorResponse: (status: number) => boolean = (status: number): boolean => 399 < status && status < 600;

// Format spot rates
export const formatRate: (rate: number) => [string, string, string] = (rate: number = 1) => {
  const rateString: string = rate.toFixed(6);
  const rateFirst: string = rateString.slice(0, 4);
  const rateMiddle: string = rateString.slice(4, 6);
  const rateLast: string = rateString.slice(6);

  return [rateFirst, rateMiddle, rateLast];
};

// Display forward points
export const displayForwardPoints = (
  fp: number | undefined,
  suffix: ' pts' | ' pts (Suggested)' | '' | undefined = ' pts'
): string =>
  fp === undefined || fp === null ? '-' : (fp > 0 ? '+' : '') + `${fp.toFixed(forwardPointsDecimals)}` + suffix;

export interface SpotRange {
  min: number;
  max: number;
}

export const actualSpotRange: (rate: number, spotRange: number) => SpotRange = (rate: number, spotRange: number) => ({
  min: rate - spotRange,
  max: rate + spotRange,
});

// Display duration from milliseconds
export const convertMills = (mills: number): string => dayjs.duration(mills).format('DD[D] HH:mm:ss');

const fold = <T, V, A extends V, B extends V>(value: T | undefined | null, onDefined: (_: T) => A, onEmpty: B): V =>
  value !== undefined && value !== null ? onDefined(value) : onEmpty;

export const map = <T, V>(value: T | undefined | null, onDefined: (_: T) => V): V | undefined =>
  fold(value, onDefined, undefined);

export const isDefined = <T,>(maybeDefined: T | undefined): maybeDefined is T => maybeDefined !== undefined;

export const dropUndefined = <T,>(arr: (T | undefined)[]) => arr.filter(isDefined);

export const displayInterestRate = (
  impliedYield: number | undefined | null,
  fallbackValue: string = '--',
  suffix: string = ''
): string => fold(impliedYield, t => `${t ?? ''} ${Unit.BP}${suffix}`, fallbackValue);

export const getRFC2253ValueByKey = (value: string, key: string) => parse(value).get(key)?.toString();

export const displayParty = (party: Party) =>
  party.shortName ?? getRFC2253ValueByKey(party.legalName, 'O') ?? party.legalName;

// Display list of entities from rfc2253 array
export const displayEntities = (entities: Party[]): string => entities.map(p => displayParty(p)).join(', ');

/**
 * @deprecated Use {@link displayEntities} instead. */
export const oldDisplayEntities = (entities: string[]): string =>
  entities
    .reduce((accumulator: string[], entity: string) => {
      if (entity && entity !== '') {
        const parsedEntity = getRFC2253ValueByKey(entity, 'O');
        return [...accumulator, ...(parsedEntity ? [parsedEntity] : [])];
      }
      return accumulator;
    }, [])
    .join(', ');

// Aggregate entities to send it to the API
export const aggregateEntities = (...entities: string[]): string[] =>
  entities.filter((entity: string) => entity && entity !== '');

export const takeWhile = <T,>(arr: T[], pred: (elem: T) => boolean): T[] =>
  arr.reduce<T[]>((acc, el) => (pred(el) ? [...acc, el] : acc), []);

export const displayLastTraded = (lastTraded: LastTraded | undefined, inMillions: boolean): string => {
  if (!lastTraded) {
    return '--';
  }

  return [
    displayInterestRate(lastTraded.impliedYield),
    displayAmountWithUnit(lastTraded.amount, inMillions),
    displayDate(lastTraded.at, 'HH:mm'),
  ].join(' ');
};

// implYld message
// Returns receives/pays message string for the implYld field.
export const getImplYldMessage = (
  entityName: string,
  entitySide: Side,
  appliedImplYld?: number
): {text?: string; pays?: boolean} => {
  if (!appliedImplYld) {
    return {};
  }

  const pays = entitySide === 'SellBuy' ? appliedImplYld < 0 : appliedImplYld > 0;

  return {text: `${entityName} ${pays ? 'pays' : 'receives'}`, pays};
};

/**
 * Accepts userData and selected entity name.
 * Returns sort name for selected legal entity.
 * defaults to -- in case no entity name found
 */
export const getSelectedEntityShortName = (user: UserWithMeta, selectedEntityLegalName: string): string => {
  const userEntity = user.legalEntities.find(entity => entity.legalName.includes(selectedEntityLegalName));
  return userEntity?.shortName ?? (userEntity && getRFC2253ValueByKey(userEntity?.legalName, 'O')) ?? '--';
};

export const setInlineRules = (rulesObj: CSSProperties) =>
  Object.entries(rulesObj)
    .map(([property, value]) => `${property}: ${value as string | number};`) // CSSProperties values are typed as any
    .join('');

/**
 * Tests if a string contains leading zeroes.
 * Decimals with one leading zero and leading positive/negative signs are allowed.
 */
export const containsLeadingZeroes = (input: string) => input.length > 1 && !!input.match(/^[+-]?0[^.]/);

export const hasDecimalPointWithoutLeadingZero = (input: string) => input.length > 1 && !!input.match(/^[+-]?\.\d*/);

export const ConditionalWrapper = (props: {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}): JSX.Element => (props.condition ? props.wrapper(props.children) : props.children);

export const downloadFile = (response: AxiosResponse, filename?: string) => {
  if (response && response.data) {
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const name = filename ?? response.headers['content-disposition']?.match(/filename=([^;]+)/)?.[1] ?? 'report';

    a.href = url;
    a.download = name;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }
};

export const downloadFileByUrl = (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const isValidDecimal = (value: number, decimalPlaces: number): boolean =>
  value % 1 === 0 || new RegExp(`^\-?\\d*\\.?\\d{0,${decimalPlaces}}$`).test(value.toString());
