import {useReactTable, getCoreRowModel, flexRender, getSortedRowModel, TableOptions} from '@tanstack/react-table';
import {NotificationData} from 'pages/NotificationCenterPage/types';

import {useModal} from 'contexts/modal-context';

import {notificationTypeToRelatedPopup} from './types';

import {Container, MessageTd, Tr} from './styles';

interface Props<T extends NotificationData> {
  data: TableOptions<T>['data'];
  columns: TableOptions<T>['columns'];
  hasError?: boolean;
  isLoading?: boolean;
  isNotificationClickable: boolean;
}

export default function NotificationsTable<T extends NotificationData>({
  data,
  columns,
  hasError,
  isLoading,
  isNotificationClickable,
}: Props<T>) {
  const {openModal} = useModal();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: [{id: 'date-column', desc: true}],
    },
  });
  const isWaitingForNotifications = isLoading || table.getRowModel().rows.length === 0;

  return (
    <Container>
      <table data-testid='notifications-table'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {hasError && (
            <tr>
              <MessageTd colSpan={columns.length}>
                There was an error connecting to the server. Reconnecting...
              </MessageTd>
            </tr>
          )}
          {!hasError && isWaitingForNotifications && (
            <tr>
              <MessageTd colSpan={columns.length}>Waiting for notifications...</MessageTd>
            </tr>
          )}
          {table.getRowModel().rows.map((row, index) => {
            const modelId = row.original.aggregateId;
            const targetPopup = notificationTypeToRelatedPopup[row.original.type];
            const isClickable = isNotificationClickable && modelId && targetPopup;
            const handleClick = () => {
              if (!isClickable) return;
              openModal({modal: targetPopup, props: {modelId}});
            };

            return (
              <Tr
                isFlashing={row.original.isUnseen}
                isUnread={row.original.isUnread}
                isOdd={index % 2 === 0}
                isClickable={!!isClickable}
                onClick={handleClick}
                key={row.id}
                role='row'
              >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </Tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
