import styled from 'styled-components';
import {color} from 'utils/theme';

export const Bubble = styled.div`
  width: 18px;
  height: 18px;
  color: ${color('white')};
  border-radius: 50%;
  text-align: center;
  background-color: ${color('red700')};
  font-size: 10px;
  line-height: 18px;
`;
