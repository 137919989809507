import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';

import {useIntl} from 'react-intl';

import {
  aggregateEntities,
  displayInterestRate,
  displayForwardPoints,
  displayParty,
  displayEntities,
  ConditionalWrapper,
} from 'utils/utils';
import {displaySpotRangeBigfig} from 'utils/AmountUtils';
import {ConfirmationStepLabels as labels} from 'types/rfqForm';

import {displayAssetPairWithSide, isRepoPair} from 'utils/AssetUtils';
import {ReferenceData} from 'types/layout';
import {displayRfqAmount, getRFQAmountFieldLabel, getRfqAmounts} from 'utils/RFQUtils';

import {useUser} from 'contexts/auth-context';

import {useAvailableCounterpartiesQuery} from 'api/hooks/useAvailableCounterpartiesQuery';

import {appTzShortName} from 'utils/setupDayjs';

import useReferenceDataStore from 'stores/useReferenceDataStore';

import {useDisplayRate} from 'utils/hooks/useDisplayRate';

import useDisplayLegPrefixWithDate from 'utils/hooks/useDisplayLegPrefixWithDate';

import {fieldTitles} from 'constants/messages/labels';
import colors from 'constants/colors';
import TooltipWrapper from 'ui/Tooltip/TooltipWrapper';
import {Row} from 'ui/Table/types';
import Table from 'ui/Table';
import FieldTitle from 'ui/FieldTitle';
import FieldValue from 'ui/FieldValue';

import useSelectedMarketDay from 'containers/RFQForm/hooks/useSelectedMarketDay';
import {TabContent, TabHeader, TableLabel, TableValue} from 'containers/RFQForm/styles';
import {ConfirmationProps, repoSecuritiesMessage} from 'containers/RFQForm/types';

export const Details: React.FC<ConfirmationProps> = (props: ConfirmationProps) => {
  const {pair, suggestedRate, side, tradedCurrency, nearLeg, farLeg, nearLegInstant, farLegInstant} = props.rfqForm;
  const {formatMessage} = useIntl();
  const {legalEntities: userLegalEntities} = useUser();
  const {referenceData} = useReferenceDataStore();
  const displayLegPrefixWithDate = useDisplayLegPrefixWithDate(pair);
  const {inMillions} = useSelectedMarketDay();
  const [initialEntity, setInitialEntity] = useState<string>('');
  const rfqEntity = userLegalEntities.find(legalEntity => legalEntity.legalName === initialEntity) ?? {
    legalName: initialEntity,
    shortName: null,
  };
  const {availableCounterparties} = useAvailableCounterpartiesQuery();
  const rfqCounterparties = aggregateEntities(
    props.rfqForm.counterparty1,
    props.rfqForm.counterparty2,
    props.rfqForm.counterparty3
  ).map(
    party =>
      availableCounterparties?.find(availableParty => party === availableParty.legalName) ?? {
        legalName: party,
        shortName: null,
      }
  );

  useEffect(() => {
    const newEntity = userLegalEntities[0];
    setInitialEntity(newEntity?.legalName);
  }, [JSON.stringify(userLegalEntities)]);

  const isRepo: boolean = isRepoPair(pair);

  const parseRate = useDisplayRate(pair, nearLegInstant || dayjs(), farLegInstant || dayjs());

  if (!pair || !farLeg || !farLegInstant || !props.rfqForm.tradedAmount || !props.rfqForm.initialFXRate) {
    return <></>;
  }

  const initialFXRate: number = props.rfqForm.initialFXRate;

  // Rows for table
  const requestSide: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.type)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-side' key={1}>
      <FieldValue>{displayAssetPairWithSide(pair, props.rfqForm.side)}</FieldValue>
    </TableValue>,
  ];

  const {tradedRfqAmount, counterRfqAmount} = getRfqAmounts({
    pair,
    spotRate: props.rfqForm.initialFXRate,
    spotRangeBigFig: props.rfqForm.spotRangeBigFig,
    tradedAmount: props.rfqForm.tradedAmount,
    tradedCurrency: props.rfqForm.tradedCurrency,
    side: props.rfqForm.side,
    inMillions,
  });

  const tradedAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'traded',
    assetPair: pair,
    isRepo,
    tradedCurrency,
    side,
  });
  const counterAmountFieldLabel = getRFQAmountFieldLabel({
    labelFor: 'counter',
    assetPair: pair,
    isRepo,
    tradedCurrency,
    side,
  });

  const requestAmtRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(tradedAmountFieldLabel)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-amt' key={1}>
      <FieldValue>{displayRfqAmount(tradedRfqAmount, inMillions)}</FieldValue>
    </TableValue>,
  ];

  const counterAmtRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(counterAmountFieldLabel)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-counter-amt' key={1}>
      <ConditionalWrapper
        condition={isRepo}
        wrapper={children => (
          <TooltipWrapper position='left' message={repoSecuritiesMessage}>
            {children}
          </TooltipWrapper>
        )}
      >
        <FieldValue>{displayRfqAmount(counterRfqAmount, inMillions)}</FieldValue>
      </ConditionalWrapper>
    </TableValue>,
  ];

  const [youBuyRow, youSellRow] =
    tradedAmountFieldLabel.id === 'youBuy' ? [requestAmtRow, counterAmtRow] : [counterAmtRow, requestAmtRow];

  const initialRate: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.initialRate)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-initial-rate' key={1}>
      <FieldValue>{initialFXRate.toString()}</FieldValue>
    </TableValue>,
  ];

  const spotRange: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.spotRange)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-spot-range' key={1}>
      <FieldValue>{`${displaySpotRangeBigfig(props.rfqForm.spotRangeBigFig)} bigfig`}</FieldValue>
    </TableValue>,
  ];

  const validForRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.validFor)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-valid-for' key={1}>
      <FieldValue>{`${props.rfqForm.validFor} min`}</FieldValue>
    </TableValue>,
  ];

  const entityRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.entity)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-entity' key={1}>
      <FieldValue uppercase={false}>{displayParty(rfqEntity)}</FieldValue>
    </TableValue>,
  ];

  const counterPartiesRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{formatMessage(labels.counterparty)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-counterparties' key={1}>
      <FieldValue uppercase={false}>{displayEntities(rfqCounterparties)}</FieldValue>
    </TableValue>,
  ];

  const isNearLegASAP = nearLeg === undefined;

  const nearLegRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{isRepo ? formatMessage(labels.onLeg) : formatMessage(labels.nearLeg)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-nearleg' key={1}>
      <FieldValue color={!isNearLegASAP ? colors.yellow350 : undefined}>
        {isNearLegASAP ?
          'ASAP'
        : `${displayLegPrefixWithDate(nearLegInstant, nearLeg.transactionDate)} ${appTzShortName}`}
      </FieldValue>
    </TableValue>,
  ];

  const farLegRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{isRepo ? formatMessage(labels.offLeg) : formatMessage(labels.farLeg)}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-farleg' key={1}>
      <FieldValue>
        {displayLegPrefixWithDate(farLegInstant, farLeg.transactionDate)} {appTzShortName}
      </FieldValue>
    </TableValue>,
  ];

  const interestRateLabel =
    isRepo ? formatMessage(fieldTitles.suggestedRate)
    : referenceData === ReferenceData.ForwardPoints ? formatMessage(labels.suggestedForwardPoints)
    : formatMessage(labels.suggestedImpliedYield);
  const interestRateRow: Row = [
    <TableLabel key={0}>
      <FieldTitle>{interestRateLabel}</FieldTitle>
    </TableLabel>,
    <TableValue data-testid='request-interest-rate' key={1}>
      <FieldValue>
        {isRepo || referenceData === ReferenceData.ImpliedYield ?
          displayInterestRate(parseRate(suggestedRate))
        : displayForwardPoints(parseRate(suggestedRate))}
      </FieldValue>
    </TableValue>,
  ];

  const rows: Row[] = [
    requestSide,
    ...(isRepo ? [requestAmtRow, counterAmtRow]
    : side === 'SellBuy' ? [youSellRow, youBuyRow]
    : [youBuyRow, youSellRow]),
    ...(isRepo ? [] : [initialRate, spotRange]),
    validForRow,
    entityRow,
    counterPartiesRow,
    nearLegRow,
    farLegRow,
    interestRateRow,
  ];

  return (
    <TabContent>
      <TabHeader>Please, review the details of your request before confirming.</TabHeader>
      <Table rows={rows} />
    </TabContent>
  );
};

export default Details;
