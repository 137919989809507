import {Asset, AssetPair} from 'types/api';
import {LayoutPath} from 'types/layout';

interface ManageCapacityQueryParams {
  asset: Asset;
  comparedAsset?: Asset;
}

interface ChartViewQueryParams {
  assetPair: AssetPair;
}

export const MANAGE_CAPACITY = {
  relative: 'manage-capacity',
  absolute: LayoutPath.CapacityManagerView,
  build: (path: string, {asset, comparedAsset}: ManageCapacityQueryParams) => {
    const params = new URLSearchParams();

    params.set('asset', [asset.type, asset.currency, asset.holder].join('@'));
    if (comparedAsset) {
      params.set('comparedAsset', [comparedAsset.type, comparedAsset.currency, comparedAsset.holder].join('@'));
    }

    return `${path}?${decodeURIComponent(params.toString())}`;
  },
  buildRelative: (query: ManageCapacityQueryParams) => MANAGE_CAPACITY.build(MANAGE_CAPACITY.relative, query),
  buildAbsolute: (query: ManageCapacityQueryParams) => MANAGE_CAPACITY.build(MANAGE_CAPACITY.absolute, query),
};

export const CHART_VIEW = {
  relative: 'chart-view/:first/:second',
  absolute: LayoutPath.ChartView,
  build: (path: string, {assetPair}: ChartViewQueryParams) => {
    return path
      .replace(
        ':first',
        [assetPair.base.type, assetPair.base.currency, assetPair.base.holder].filter(Boolean).join('@')
      )
      .replace(
        ':second',
        [assetPair.second.type, assetPair.second.currency, assetPair.second.holder].filter(Boolean).join('@')
      );
  },
  buildRelative: (query: ChartViewQueryParams) => CHART_VIEW.build(CHART_VIEW.relative, query),
  buildAbsolute: (query: ChartViewQueryParams) => CHART_VIEW.build(CHART_VIEW.absolute, query),
};

export const ANALYTICS = {
  relative: '/analytics',
  absolute: LayoutPath.AnalyticsView,
};

export const RT_CREDIT_RISK = {
  relative: 'rt-credit-risk',
  absolute: `${ANALYTICS.relative}/rt-credit-risk`,
};

export const HIST_CREDIT_RISK = {
  relative: 'hist-credit-risk',
  absolute: `${ANALYTICS.relative}/hist-credit-risk`,
};

export const RT_CLOB_EXPOSURE = {
  relative: 'rt-clob-exposure',
  absolute: `${ANALYTICS.relative}/rt-clob-exposure`,
};

export const HIST_CLOB_EXPOSURE = {
  relative: 'hist-clob-exposure',
  absolute: `${ANALYTICS.relative}/hist-clob-exposure`,
};

export const MANAGE_LIMITS = {
  relative: 'manage-limits',
  absolute: `${ANALYTICS.relative}/manage-limits`,
};

export const TIMING_BY_CPARTY = {
  relative: 'timing-by-cparty',
  absolute: `${ANALYTICS.relative}/timing-by-cparty`,
};

export const AGGRESSOR_BY_CPARTY = {
  relative: 'aggressor-by-cparty',
  absolute: `${ANALYTICS.relative}/aggressor-by-cparty`,
};

export const FO_ACTIVITY_REPORTS = {
  relative: 'fo-activity-reports',
  absolute: `${ANALYTICS.relative}/fo-activity-reports`,
};

export const TRANSACTION_FEES_REPORTS = {
  relative: 'transaction-fees-reports',
  absolute: `${ANALYTICS.relative}/transaction-fees-reports`,
};
