import {SRow, SCol} from 'ui/Layout/styles';

interface TotalRowProps {
  label: string;
  amount: string;
}

export const TotalRow = ({label, amount}: TotalRowProps) => {
  return (
    <SRow>
      <SCol width={{xs: 1, sm: 1 / 3}} d='flex' justifyContent='start'>
        {label}
      </SCol>
      <SCol width={{xs: 1, sm: 1 / 3}} />
      <SCol width={{xs: 1, sm: 1 / 3}} d='flex' justifyContent='end'>
        {amount}
      </SCol>
    </SRow>
  );
};
