import {useMemo} from 'react';

import {useLocation} from 'react-router-dom';
import {LayoutPath} from 'types/layout';

export function useQuery(): URLSearchParams {
  const {search} = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return query;
}

export const layoutPathToPageTitle = (path: LayoutPath): string => {
  switch (path) {
    case LayoutPath.ChartView:
      // TODO: dynamic currency?
      return 'CLOB Chart';
    case LayoutPath.ClobView:
      return 'CLOB Overview';
    case LayoutPath.CapacityView:
      return 'Capacity';
    case LayoutPath.CapacityManagerView:
      // TODO: dynamic currency?
      return 'Manage Capacity';
    case LayoutPath.RfqView:
      return 'RFQ';
    case LayoutPath.AnalyticsView:
      return 'Analytics';
    default:
      return '';
  }
};
