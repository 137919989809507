import {ToastContainer} from 'react-toastify';

const GlobalDialogs = () => {
  return (
    <ToastContainer
      position='bottom-right'
      autoClose={5000}
      hideProgressBar
      closeOnClick
      rtl={false}
      draggable={false}
      pauseOnHover
      icon={false}
      stacked
    />
  );
};

export default GlobalDialogs;
