import {DateFormat, displayDate} from 'utils/DayjsUtils';
import {displaySmallAmount} from 'utils/AmountUtils';

import useTradeEventsQuery from 'api/hooks/useTradeEventsQuery';

import {uniqueId} from 'lodash';

import Loader from 'ui/Loader';
import {MessageTd} from 'components/NotificationCenter/components/NotificationsTable/styles';

import {Cell, HeaderCell, HeaderRow, Row, Table, TradeEventsContainer} from './styles';

interface Props {
  tradeId: string;
}

const TABLE_HEADERS = ['date', 'event', 'leg', 'value'];

const displayTradeEventsMessage = (message: string, colSpan = TABLE_HEADERS.length): JSX.Element => (
  <tr>
    <MessageTd colSpan={colSpan}>{message}</MessageTd>
  </tr>
);

const getLoadingRow = (colSpan = TABLE_HEADERS.length) => (
  <tr style={{position: 'relative'}}>
    <MessageTd colSpan={colSpan}>
      <Loader />
    </MessageTd>
  </tr>
);

export default function TradeEvents({tradeId}: Props) {
  const {tradeEvents, isLoading, error} = useTradeEventsQuery(tradeId);
  const isTradeEventsEmpty = !isLoading && (!tradeEvents || tradeEvents.length === 0);

  return (
    <TradeEventsContainer>
      <Table>
        <thead>
          <HeaderRow>
            {TABLE_HEADERS.map(header => (
              <HeaderCell key={header}>{header}</HeaderCell>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {error && displayTradeEventsMessage('Error occurred when loading trade events.')}
          {isTradeEventsEmpty && displayTradeEventsMessage('No events found.')}
          {isLoading && getLoadingRow()}

          {(tradeEvents || []).map((row, index) => (
            <Row key={uniqueId(row.id)} index={index} data-testid='trade-event'>
              <HeaderCell data-testid='trade-event-datetime'>{displayDate(row.date, DateFormat.DateTime)}</HeaderCell>
              <Cell data-testid='trade-event-message'>{row.event}</Cell>
              <Cell data-testid='trade-event-leg'>{row.leg ?? '--'}</Cell>
              <Cell data-testid='trade-event-amount'>{displaySmallAmount(row.amount)}</Cell>
            </Row>
          ))}
        </tbody>
      </Table>
    </TradeEventsContainer>
  );
}
