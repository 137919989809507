/* eslint-disable @typescript-eslint/member-ordering */
import ReconnectingEventSource from 'reconnecting-eventsource';
import {without, find, uniqBy} from 'lodash/fp';
import {
  RFQQuoteWithRequest,
  RFQRequest,
  Balance,
  Order,
  Trade,
  CapacityAdjustment,
  CapacityProjectionUpdate,
  AggregatedLimit,
  TradeAnnul,
  TradeUnwindRequest,
} from 'types/api';

import {CreditRiskData} from 'types/analytics';

import {constructApiUrl} from 'constants/app_defaults';

type EntityEventMap = {
  capacity: Balance[];
  'capacity-projection': CapacityProjectionUpdate;
  'capacity-adjustment': CapacityAdjustment;
  order: Order;
  quote: RFQQuoteWithRequest;
  request: RFQRequest;
  trade: Trade;
  'trade-annul-proposal': TradeAnnul;
  'trade-unwind-proposal': TradeUnwindRequest;
  limit: AggregatedLimit[];
  'rt-credit-risk': CreditRiskData[];
};
type EntityType = keyof EntityEventMap;

interface Subscription {
  entityType: EntityType;
  lastEventId: string;
  handler: (message: MessageEvent<string>) => void;
}

interface Options {
  lastEventId: string;
}

const parseEventIds = (val: string): {[entityType: string]: string} =>
  val.split(';').reduce((acc, current) => {
    const [entityType, lastEventId] = current.split(':');

    return {...acc, [entityType]: lastEventId};
  }, {});

export class MultiplexedEventStream {
  private es: ReconnectingEventSource | null = null;
  private subscriptions: Subscription[] = [];

  get lastEventId() {
    return this.es?._lastEventId;
  }

  subscribe<T extends EntityType>(entityType: T, handler: (entity: EntityEventMap[T]) => void, opts: Options) {
    const subscription: Subscription = {
      entityType,
      lastEventId: opts.lastEventId,
      handler: (e: MessageEvent<string>) => {
        const parsedEventIds = parseEventIds(e.lastEventId);
        const existingSubscription = find({entityType}, this.subscriptions);

        if (existingSubscription) {
          subscription.lastEventId = parsedEventIds[entityType];
        }
        const entity = JSON.parse(e.data) as EntityEventMap[T];

        handler(entity);
      },
    };

    const existingSubscription = find({entityType}, this.subscriptions);
    if (existingSubscription) this.subscriptions = without([existingSubscription], this.subscriptions);

    this.subscriptions.push(subscription);

    this.reconnect();

    // Return unsubscribe function
    return () => {
      this.subscriptions = without([subscription], this.subscriptions);
      this.reconnect();
    };
  }

  close() {
    this.es?.close();
    this.subscriptions = [];
    this.es = null;
  }

  private reconnect() {
    if (this.es) {
      this.es.close();
    }

    if (!this.subscriptions.length) {
      return;
    }

    const lastEventId = uniqBy('entityType', this.subscriptions)
      .map(s => `${s.entityType}:${s.lastEventId ?? '0,0'}`)
      .join(';');

    this.es = new ReconnectingEventSource(constructApiUrl('/v2/events'), {
      lastEventId,
      withCredentials: true,
    });

    this.subscriptions.forEach(subscription => {
      this.es?.addEventListener(subscription.entityType, subscription.handler);
    });
  }
}

export const unifiedEventStream = new MultiplexedEventStream();
