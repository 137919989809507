export enum HistoryType {
  Trades = 'trades',
  Orders = 'orders',
  Quotes = 'quotes',
  Requests = 'requests',
  Capacity = 'capacity',
}

export enum CancelReason {
  OrderExpired = 'OrderExpired',
  InsufficientMatchingOrdersOnTheBook = 'InsufficientMatchingOrdersOnTheBook',
  UnableToEarmarkFunds = 'UnableToEarmarkFunds',
  UserRequest = 'UserRequest',
  ExchangeRateOutOfRange = 'ExchangeRateOutOfRange',
  CannotMatchWithSameEntity = 'CannotMatchWithSameEntity',
  NonAggressorOrderWouldBeMatched = 'NonAggressorOrderWouldBeMatched',
  UnhandledMatchingError = 'UnhandledMatchingError',
  NotEnoughRemainingAmount = 'NotEnoughRemainingAmount',
  LimitReached = 'LimitReached',
}

export const cancelMessage: {[key: string]: string} = {};
cancelMessage[CancelReason.OrderExpired] = 'This order has expired.';
cancelMessage[CancelReason.InsufficientMatchingOrdersOnTheBook] = 'Unable to fulfill market order.';
cancelMessage[CancelReason.UnableToEarmarkFunds] = 'Unable to earmark required amount.';
cancelMessage[CancelReason.UserRequest] = 'Requested by user.';
cancelMessage[CancelReason.NotEnoughRemainingAmount] = 'Remaining amount <1m minimum.';
cancelMessage[CancelReason.ExchangeRateOutOfRange] = 'Exchange rate moved out of range.';
cancelMessage[CancelReason.CannotMatchWithSameEntity] = 'Cannot match an order with the same entity.';
cancelMessage[CancelReason.NonAggressorOrderWouldBeMatched] = 'Non aggressor order would be matched.';
cancelMessage[CancelReason.UnhandledMatchingError] = 'Failed to execute order due to an internal server error.';
cancelMessage[CancelReason.LimitReached] = 'Remaining CLOB limit was insufficient to match this order.';
