import {ColumnDef, SortingState} from '@tanstack/react-table';

export type {ColumnDef} from '@tanstack/react-table';

export enum GridTableVariant {
  Regular = 'Regular',
  Minimal = 'Minimal',
}

export type GridTableProps<T> = {
  columns: ColumnDef<T>[];
  data: T[];
  defaultSorted?: SortingState;
  fullWidth?: boolean;
  getRowId?: (data: T) => string;
  onRowClick?: (data: T) => void;
  sortable?: boolean;
  filterable?: boolean;
  variant?: GridTableVariant;
  isLoading?: boolean;
};

export type GridTableWrapperProps = {
  fullWidth?: boolean;
  gridTemplateColumns: string;
  variant: GridTableVariant;
};

export type GridTableHeaderGroupProps = {
  gridTemplateColumns: string;
  variant: GridTableVariant;
};

export type GridTableHeaderProps = {
  align?: 'left' | 'center' | 'right';
  isRoot?: boolean;
  hasLeftSpacing?: boolean;
  hasRightSpacing?: boolean;
  sortable: boolean;
  start: number;
  span: number;
  variant: GridTableVariant;
};

export type EmptyGridTableHeaderProps = Omit<
  GridTableHeaderProps,
  'hasLeftSpacing' | 'hasRightSpacing' | 'start' | 'span' | 'sortable' | 'variant'
>;

export type GridTableRowProps = {
  clickable?: boolean;
  gridTemplateColumns: string;
  variant: GridTableVariant;
};

export type SharedTableCellProps = {
  align?: 'left' | 'center' | 'right';
  alignItems?: 'start' | 'center' | 'end';
};

export type GridTableCellProps = SharedTableCellProps & {
  isInsideEvenRow?: boolean;
  hasLeftSpacing?: boolean;
  hasRightSpacing?: boolean;
  variant: GridTableVariant;
};

export type EmptyGridTableCellProps = Omit<GridTableCellProps, 'hasLeftSpacing' | 'hasRightSpacing' | 'variant'>;
