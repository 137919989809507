import {Route, Routes} from 'react-router';
import {BlotterPosition, EquivCurrency} from 'types/layout';
import {UserWithMeta} from 'types/api';

import {useUser} from 'contexts/auth-context';

import {Navigate} from 'react-router-dom';

import useEquivCurrencyStore from 'stores/useEquivCurrencyStore';

import useBlotterPositionStore from 'stores/useBlotterPositionStore';

import {RT_CREDIT_RISK} from 'constants/paths';

import Screen from 'ui/Screen';

import {Container as SideContainer, StyledSwitcher} from 'containers/BalancesList/styles';
import {Blotter} from 'containers/Blotter';

import {getRolePermissions, hasPermission} from 'components/PermissionGate/utils';
import {BoardHeader} from 'components/BoardHeader/BoardHeader';

import {
  AnalyticsPage as StyledAnalyticsPage,
  AnalyticsPageContainer,
  AnaylticsPageContent,
  getAnalyticsBoardHeaderStyles,
  getAnalyticsSwitcherStyles,
} from './styles';
import {collapsableAnalyticsSideBarMenu} from './constants';
import AnalyticsSideBar from './components/AnalyticsSideBar';

const getAnalyticsRoutes = (userWithMeta: UserWithMeta) => {
  const permissions = getRolePermissions(userWithMeta.user?.role);

  return (
    <Routes>
      {collapsableAnalyticsSideBarMenu
        .flatMap(({subItems, requiredScope}) => {
          const isAllowedToView = !requiredScope || hasPermission({permissions, scopes: [requiredScope]});
          return isAllowedToView ?
              subItems.flatMap(i => ({
                key: i.path.relative,
                path: i.path.relative,
                component: i.component,
              }))
            : [];
        })
        .map(({key, path, component}) => (
          <Route key={key} path={path} element={component} />
        ))}

      <Route path='*' element={<Navigate to={RT_CREDIT_RISK.absolute} replace />} />
    </Routes>
  );
};

const AnalyticsPage = () => {
  const userWithMeta = useUser();
  const [blotterPosition] = useBlotterPositionStore();
  const isBlotterExpanded = blotterPosition === BlotterPosition.Expanded;
  const [equivCurrency, setEquivCurrency] = useEquivCurrencyStore();

  return (
    <Screen>
      <AnalyticsPageContainer>
        <SideContainer>
          <BoardHeader style={getAnalyticsBoardHeaderStyles()}>
            <StyledSwitcher
              leftLabel='USD EQUIV'
              leftValue={EquivCurrency.UsdEquiv}
              rightLabel='ORIG CCY'
              rightValue={EquivCurrency.OrigCcy}
              onChange={setEquivCurrency}
              value={equivCurrency}
              styles={getAnalyticsSwitcherStyles()}
            />
          </BoardHeader>
          <AnalyticsSideBar />
        </SideContainer>
        <StyledAnalyticsPage display='flex' flexDirection='column' data-testid='analytics-page'>
          <AnaylticsPageContent isBlotterExpanded={isBlotterExpanded}>
            {userWithMeta && getAnalyticsRoutes(userWithMeta)}
          </AnaylticsPageContent>
          <Blotter />
        </StyledAnalyticsPage>
      </AnalyticsPageContainer>
    </Screen>
  );
};

export default AnalyticsPage;
