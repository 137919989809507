import {includes, without} from 'lodash';
import {useCallback, useState} from 'react';

interface State<T> {
  loading: boolean;
  rejectionReasons: T[];
}

export default function useRejectConfirmationReasons<T>(): State<T> & {
  handleCheckbox(reason: T): void;
  setLoading: (loading: boolean) => void;
} {
  const [loading, setLoading] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState<T[]>([]);

  const handleCheckbox = useCallback((reason: T) => {
    setRejectionReasons(reasons => {
      if (includes(reasons, reason)) {
        return without(reasons, reason);
      } else {
        return [...reasons, reason];
      }
    });
  }, []);

  return {
    loading,
    rejectionReasons,
    handleCheckbox,
    setLoading,
  };
}
