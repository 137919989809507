import finteumLogoUrl from 'assets/images/finteum-logo.svg';

import {Link} from 'react-router-dom';

import Button from 'ui/Button';

import Box from 'ui/Box';

export default function ForOfor() {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' py={5} height='100%' justifyContent='space-evenly'>
      <Box display='flex' alignItems='center' flexDirection='column' gap={4}>
        <img src={finteumLogoUrl} width='265px' />
        <h1>
          We cannot find page you are looking for (404). Please try to navigate using button below to get back to
          application.
        </h1>
      </Box>
      <Link to='/login'>
        <Button>Navigate to home page</Button>
      </Link>
    </Box>
  );
}
