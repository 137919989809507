import {Link} from 'react-router-dom';

import finteumLogoUrl from 'assets/images/finteum-logo.svg';
import styled from 'styled-components';

import {SCol} from 'ui/Layout/styles';

const SFinteumLogo = styled.img`
  width: 100%;
  height: 100%;
`;

const SImageWrapper = styled.div`
  width: 180px;
  height: 43px;
`;

const Logo = ({width}: {width?: number[]}) => (
  <SCol width={width || [1, 1 / 6, 1 / 6]} height='100%' p='15px 20px'>
    <SImageWrapper>
      <Link to='/'>
        <SFinteumLogo src={finteumLogoUrl} data-testid='finteum-logo' />
      </Link>
    </SImageWrapper>
  </SCol>
);

export default Logo;
