import styled from 'styled-components';
import {ifProp} from 'styled-tools';

export const STableContainer = styled.div<{isBlotterMinified?: boolean}>`
  display: flex;
  overflow: scroll;
  max-height: ${ifProp('isBlotterMinified', 'calc(100vh - 236px)', 'calc(100vh - 290px - 136px)')};
`;

export const SFormLayout = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  column-gap: 3rem;
`;

export const SFormItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const SFormLabel = styled.span`
  width: 100px;
  text-align: left;
  font-size: 0.75rem;
`;

export const SFormField = styled.span`
  width: 200px;
  text-align: center;
`;

export const SDownloadButton = styled.i`
  cursor: pointer;
  font-size: 24px;
`;
